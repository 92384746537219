import React from 'react'
import { DataChangeModalContainer } from '../../style/components/profile/DataChangeModal.styled'
import CloseIcon from '../../assets/CloseIcon'
import Button from '../ui/Button'

const DataChangeModal = ({ closeDataChange }) => {
  return (
    <DataChangeModalContainer>
      <div className='datachange__close' onClick={closeDataChange}>
        <CloseIcon width='18.47px' height='17px' fill='#748596' />
      </div>
      <div className='datachange__header'>
        <h5 className='datachange__header__text'>Cambios guardados</h5>
      </div>
      <p className='datachange__content'>Los cambios se han guardado de manera exitosa.</p>
      <div className='datachange__understood'>
        <Button className='understood__button' onClick={closeDataChange}>
          Entendido
        </Button>
      </div>
    </DataChangeModalContainer>
  )
}

export default DataChangeModal
