import styled from 'styled-components'

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 1;
  background: #000;
  height: 56px;
  width: 100%;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .sidebar {
    display: flex;
    justify-content: center;
    align-items: center;
    &-nav {
      display: flex;
      justify-content: space-around;
      align-items: center;
      width: 220px;
      .active {
        background: #00aaab;
      }
      .sidebar-navlink {
        display: flex;
        padding: 8px;
        border-radius: 5px;
        .sidebar-icon {
          width: 22px;
          height: 22px;
          svg {
            fill: white;
          }
        }
      }
    }
  }
`
