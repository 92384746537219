import styled from 'styled-components'
import { colors } from '../../variables'

export const PaymentContainer = styled.div`
  display: flex;
  width: ${props => (props.collapsed ? 'calc(100vw - 68px)' : 'calc(100vw - 222px)')};
  padding-top: 40px;
  margin-left: calc(100vw * -0.025);
  transition: all 0.25s linear;

  .payment__main__title {
    margin-bottom: 30px;
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
    color: ${colors.richBlack};
  }

  .shadow {
    z-index: 1;
  }

  section {
    display: flex;
    flex-direction: column;
    width: calc(100% / 3);
    height: calc(100vh - 110px);
    padding: 30px 16px 0;
    border-right: 1px solid ${colors.lightGray};
    overflow-y: scroll;
  }

  .payment-methods {
    margin-bottom: 20px;
  }

  .add-method {
    text-decoration: underline;
  }

  .edit-billing-form {
    display: flex;
    flex-direction: column;

    label {
      margin-bottom: 10px;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
    }

    input {
      height: 50px;
      padding-left: 10px;
      margin-bottom: 15px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      background: transparent;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .section__billinfo {
      border-right: none;
    }
    .payment-methods {
      width: 255px;
    }
  }

  @media (min-width: 1440px) {
    section {
      padding: 30px 40px 0;
    }
  }

  @media (min-width: 2560px) {
    section {
      padding: 30px 40px 0;
    }
    .section__billinfo {
      border-right: none;
    }
  }
`

export const Card = styled.article`
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  .checkbox-indicator {
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    outline: none;
    margin-right: 5px;
    .checked {
      width: 23px;
      height: 23px;
      fill: ${colors.lightseaGreen};
    }
    .not-checked {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
      cursor: pointer;
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    width: 210px;
    svg {
      width: 25px;
      height: 25px;
      fill: ${colors.light};
      margin-right: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-right: 15px;
    }
  }
  .hover-button {
    width: 70px;
    border: none;
    background: none;
    outline: none;
    display: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &.default {
      color: ${colors.lightseaGreen};
    }
    &.delete {
      color: ${colors.red};
      cursor: pointer;
    }
  }
  &:hover {
    background: ${colors.lightGray};
    .hover-button {
      display: block;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .checkbox-indicator {
      margin-left: 1px;
      svg {
        margin-right: 15px;
        padding-left: -2px;
      }
    }
    .card-info {
      width: 450px;
    }
  }
`

export const Invoice = styled.div`
  width: 100%;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background-color: ${colors.white};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;

  .invoice-header {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #aaafbe;
    padding: 17px 15px 8px;

    .invoice-title {
      font-size: 16px;
      font-weight: 600;
      margin-bottom: 5px;
      line-height: 19.5px;
      color: ${colors.richBlack};
    }

    .invoice-price {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .invoice-qty {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 5px;
        line-height: 19.5px;
        color: ${colors.richBlack};
      }
    }
  }
  .invoice-footer {
    display: flex;
    justify-content: space-between;
    padding: 10px 15px 17px;

    .invoice__period__container {
      display: flex;
      flex-direction: column;

      .invoice-period {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        color: #748596;
      }
    }

    .invoice__more {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.lightseaGreen};
      text-decoration: underline;
    }
  }
`

export const BillInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: auto;
  font-family: Montserrat;
  margin-bottom: 30px;

  .billinfo__header {
    margin-bottom: 25px;
    color: ${colors.richBlack};
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
  }

  .billinfo__input__title {
    margin-bottom: 8px;
    color: ${colors.richBlack};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  .billinfo__label {
    display: none;
  }

  .billinfo__input {
    width: 100%;
    height: 48px;
    padding: 15px 9.72px 15px 13.31px;
    border: 1px solid #748596;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    ::placeholder {
      color: ${colors.darkGray};
    }
    :disabled {
      cursor: not-allowed;
      background: #f8f8f8;
      ::placeholder {
        color: #a9a9a9;
      }
    }
  }

  .billinfo__select {
    width: 100%;
    height: 48px;
    padding: 15px 9.72px 15px 13.31px;
    border: 1px solid #748596;
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    :disabled {
      cursor: not-allowed;
      background: #f8f8f8;
      ::placeholder {
        color: #a9a9a9;
      }
    }
  }

  .billinfo__select:required:invalid {
    color: #748596;
  }

  .billinfo__select__placeholder[value=''][disabled] {
    display: none;
  }

  .switch__component {
    display: flex;
  }

  .billinfo__switch {
    display: flex;
    margin-bottom: 10px;

    .billinfo__switch__sinfactura {
      width: 80px;
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      margin-bottom: 10px;
      &.bold {
        font-weight: 700;
        color: ${colors.darkGray};
      }
    }
  }

  .billinfo__switch__factura {
    width: 150px;
    font-size: 14px;
    font-weight: 400;
    color: ${colors.darkGray};
    margin-bottom: 10px;

    &.bold {
      font-weight: 700;
      color: ${colors.darkGray};
    }
  }

  .billinfo__error {
    height: 20px;
    width: 100%;
    margin-top: 5px;

    .billinfo__error__text {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }

    .billinfo__error__transparent {
      color: transparent;
      font-size: 10px;
      margin: 0;
    }
  }

  .billinfo__numeros {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .billinfo__numero {
      display: flex;
      flex-direction: column;
      width: 80%;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .billinfo__header {
      width: 237px;
    }

    .billinfo__switch {
      .billinfo__switch__sinfactura {
        width: 85px;
      }
    }
  }
`
