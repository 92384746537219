import React, { useContext } from 'react'
import { useHistory } from 'react-router-dom'
import { LogoutButton } from '../../style/components/user/Logout.styled'
import { clearCache } from '../../config/apollo'
import { FiLogOut } from 'react-icons/fi'
import { isMobile } from 'react-device-detect'
import { MyContext } from '../../context'

const Logout = () => {
  const context = useContext(MyContext)

  const history = useHistory()

  const logout = () => {
    localStorage.removeItem('token')
    clearCache()
    context.doLogOut()
    history.push('/login')
  }

  return <LogoutButton onClick={() => logout()}>{isMobile && <FiLogOut />} Cerrar sesión</LogoutButton>
}

export default Logout
