import React, { useContext, useEffect, useState } from 'react'
import { Switch } from '../../style/components'
import { UIContext } from '../../contexts/UIContext'
import { useQuery, useMutation } from '@apollo/client'
import { Spinner, Shadow } from '../../style/components'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { BsSquare, BsCheckBox } from 'react-icons/bs'
import NewPaymentMethod from './NewPaymentMethod'
import moment from 'moment'
// import BillInfo from '../auth/BillInfo'

import TrashIcon from '../../assets/IconTrash'

import { PaymentContainer, Card, Invoice, BillInfoForm } from '../../style/components/workspace/PaymentContainer.styled'

import { GET_PAYMENT_METHODS, GET_BILL_INFO, INVOICES_QUERY } from '../../services/queries'
import {
  MAKE_DEFAULT_PAYMENT_METHOD,
  DETACH_PAYMENT_METHOD,
  UPDATE_BILL_INFO,
  GET_ADRESS_MUTATION,
  UPDATE_BILL_REQUIRED
} from '../../services/mutations'
import { MyContext } from '../../context'
import Button from '../ui/Button'

const Payment = () => {
  const [billRequired, setBillRequired] = useState(false)
  const [billName, setBillName] = useState('')
  const [billNameError, setBillNameError] = useState(false)
  const [rfc, setRfc] = useState('')
  const [rfcError, setRfcError] = useState(false)
  const [billEmail, setBillEmail] = useState('')
  const [billEmailError, setBillEmailError] = useState(false)
  const [street, setStreet] = useState('')
  const [streetError, setStreetError] = useState(false)
  const [exterior, setExterior] = useState('')
  const [exteriorError, setExteriorError] = useState(false)
  const [interior, setInterior] = useState('')
  const [interiorError, setInteriorError] = useState(false)
  const [cp, setCp] = useState('')
  const [cpError, setCpError] = useState(false)
  const [city, setCity] = useState('')
  const [cityError, setCityError] = useState(false)
  const [alcMun, setAlcMun] = useState('')
  const [alcMunError, setAlcMunError] = useState(false)
  const [colonia, setColonia] = useState('')
  const [coloniaError, setColoniaError] = useState(false)
  const [colArr, setColArr] = useState([''])
  const [changeInState, setchangeInState] = useState(false)
  const context = useContext(MyContext)

  const uiCTX = useContext(UIContext)
  const { collapsed } = uiCTX.state

  const [newCard, setNewCard] = useState(false)

  const [updateBillInfo] = useMutation(UPDATE_BILL_INFO)
  const [updateBillRequired] = useMutation(UPDATE_BILL_REQUIRED)
  const [makeDefaultPaymentMethod] = useMutation(MAKE_DEFAULT_PAYMENT_METHOD)
  const [detachPaymentMethod] = useMutation(DETACH_PAYMENT_METHOD)
  const [adress] = useMutation(GET_ADRESS_MUTATION)
  const { data, loading, startPolling, stopPolling, error } = useQuery(GET_BILL_INFO)
  const handleDefaultPaymentMethod = async paymentMethodId => {
    await makeDefaultPaymentMethod({ variables: { paymentMethodId } })
  }

  const handleDeletePaymentMethod = async paymentMethodId => {
    await detachPaymentMethod({ variables: { paymentMethodId } })
  }

  const {
    data: paymentMethodsData,
    loading: paymentMethodsLoading,
    startPolling: paymentMethodsStartPolling,
    stopPolling: paymentMethodsStoptPolling,
    paymentError
  } = useQuery(GET_PAYMENT_METHODS)
  const { data: invoicesData, loading: invoicesLoading } = useQuery(INVOICES_QUERY)

  const findAdress = async () => {
    if (cp.length === 5) {
      try {
        const {
          data: { getAdress }
        } = await adress({ variables: { cp } })
        setCity(getAdress.estado)
        setAlcMun(getAdress.municipio)
        setColArr(['Colonia *', ...getAdress.asentamiento])
      } catch (error) {
        setCpError('CP inválido')
      }
    }
    if (cp.length < 5) {
      setCity('')
      setAlcMun('')
      setColonia('')
      setColArr(['Colonia *'])
    }
  }

  useEffect(() => {
    startPolling(2000)
    paymentMethodsStartPolling(2000)
    return () => {
      stopPolling()
      paymentMethodsStoptPolling()
    }
  }, [startPolling, stopPolling, paymentMethodsStartPolling, paymentMethodsStoptPolling])

  useEffect(() => {
    if (data) {
      const { alcMun, billEmail, billName, colonia, cp, exterior, interior, rfc, state, street, required } =
        data.getBillInfo
      setAlcMun(alcMun)
      setBillEmail(billEmail)
      setBillName(billName)
      setColonia(colonia)
      setCp(cp)
      setExterior(exterior)
      setInterior(interior)
      setRfc(rfc)
      setCity(state)
      setStreet(street)
      setColArr([colonia])
      setBillRequired(required)
    }
  }, [data, loading])

  if (error || paymentError) context.handleErrors(error || paymentError)
  if (paymentMethodsLoading || invoicesLoading || loading) return <Spinner />

  const cards = paymentMethodsData.getPaymentMethods
  const invoices = JSON.parse(invoicesData.invoices)

  const handleSubmit = async e => {
    e.preventDefault()

    try {
      await updateBillInfo({
        variables: {
          input: {
            billName,
            rfc,
            billEmail,
            street,
            exterior,
            interior,
            cp,
            state: city,
            alcMun,
            colonia,
            required: billRequired
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  const formatNumber = number => {
    return (number.total / 100).toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  const checkIfInvoiceIsComplete = () => {
    if (
      changeInState === true &&
      billName !== '' &&
      rfc !== '' &&
      rfc.length === 15 &&
      billEmail !== '' &&
      street !== '' &&
      street.length >= 3 &&
      exterior !== '' &&
      interior !== '' &&
      cp !== '' &&
      city !== '' &&
      alcMun !== '' &&
      colonia !== ''
    )
      return true
    return false
  }

  return (
    <PaymentContainer collapsed={collapsed}>
      <section>
        <h2 className='payment__main__title'>Métodos de pago</h2>
        <div className='payment-methods'>
          {cards.map(card => (
            <Card key={card.id}>
              {card.default ? (
                <button className='checkbox-indicator'>
                  <BsCheckBox className='checked' />
                </button>
              ) : (
                <button className='checkbox-indicator' onClick={() => handleDefaultPaymentMethod(card.id)}>
                  <BsSquare className='not-checked' />
                </button>
              )}

              <div className='card-info'>
                {card.brand === 'visa' && <FaCcVisa />}
                {card.brand === 'mastercard' && <FaCcMastercard />}
                <p>•••• •••• •••• {card.last4}</p>
                <p>
                  {card.month < 10 ? '0' + card.month : card.month} / {card.year - 2000}
                </p>
              </div>

              {card.default ? (
                <button className='hover-button default'>
                  <TrashIcon stroke={'#fe596f'} />
                </button>
              ) : (
                <button className='hover-button delete' onClick={() => handleDeletePaymentMethod(card.id)}>
                  <TrashIcon stroke={'#fe596f'} />
                </button>
              )}
            </Card>
          ))}
        </div>
        <Button
          variant={'link'}
          weight={500}
          width={'fit-content'}
          height={'fit-content'}
          className='add-method'
          onClick={() => setNewCard(true)}
        >
          Añadir método de pago
        </Button>
        {newCard && (
          <>
            <Shadow className='shadow' onClick={() => setNewCard(false)} />
            <NewPaymentMethod setNewCard={setNewCard} />
          </>
        )}
      </section>

      <section className='section__bills'>
        <h2 className='payment__main__title'>Facturas</h2>
        {invoices.map((invoice, index) => (
          <Invoice key={invoice.id}>
            <div className='invoice-header'>
              <div className='invoice__title__container'>
                <a
                  target='_blank'
                  rel='noopener noreferrer'
                  className='invoice-title'
                  href={invoice.hosted_invoice_url}
                >
                  Recibo | {invoice.number}
                </a>
              </div>
              <div className='invoice-price'>
                <p className='invoice-qty'>${formatNumber(invoice)}</p>
              </div>
            </div>
            <div className='invoice-footer'>
              <div className='invoice__period__container'>
                <p className='invoice-period'>
                  {`Fecha: ${moment(invoice.created * 1000)
                    .locale('es')
                    .format('DD MMMM YYYY')}`}
                </p>
                <p className='invoice-period'>No de factura: {Number(index) * -1 + invoices.length}</p>
              </div>
              <a className='invoice__more' href={invoice.invoice_pdf}>
                Ver más
              </a>
            </div>
          </Invoice>
        ))}
      </section>

      <section className='section__billinfo'>
        <BillInfoForm>
          <h2 className='billinfo__header'>Datos de facturación</h2>
          <div className='billinfo__switch'>
            <p className={`billinfo__switch__sinfactura ${!billRequired ? 'bold' : 'normal'}`}>Sin factura</p>
            <Switch className='switch__component'>
              <input
                type='checkbox'
                value={billRequired}
                checked={billRequired}
                onChange={async () => {
                  setBillRequired(!billRequired)
                  await updateBillRequired({
                    variables: {
                      flagInvoice: !billRequired
                    }
                  })
                }}
              />
              <span className='slider round' />
            </Switch>
            <p className={`billinfo__switch__factura ${billRequired ? 'bold' : 'normal'}`}>Necesito factura</p>
          </div>

          {billRequired && (
            <>
              <p className='billinfo__input__title'>Razón social*</p>
              <label className='billinfo__label' htmlFor='bill-name'>
                Razón social*
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-name'
                type='text'
                placeholder='Empresa'
                value={billName}
                onChange={e => {
                  setBillName(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (billName === '' ? setBillNameError(true) : setBillNameError(false))}
                onKeyUp={() => (billName === '' ? setBillNameError(true) : setBillNameError(false))}
              />
              <div className='billinfo__error'>
                <p className={billNameError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <p className='billinfo__input__title'>RFC*</p>
              <label className='billinfo__label' htmlFor='bill-rfc'>
                RFC*
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-rfc'
                type='text'
                placeholder='15 digitos'
                value={rfc}
                onChange={e => {
                  setRfc(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (rfc === '' || rfc.length !== 15 ? setRfcError(true) : setRfcError(false))}
                onKeyUp={() => (rfc === '' || rfc.length !== 15 ? setRfcError(true) : setRfcError(false))}
              />
              <div className='billinfo__error'>
                <p className={rfcError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  {rfc.length ? 'Debe tener 15 dígitos' : 'Campo obligatorio'}
                </p>
              </div>

              <p className='billinfo__input__title'>Correo electrónico*</p>
              <label className='billinfo__label' htmlFor='bill-mail'>
                Correo electrónico*
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-mail'
                type='email'
                placeholder='correo@myoblek.com'
                value={billEmail}
                onChange={e => {
                  setBillEmail(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (billEmail === '' ? setBillEmailError(true) : setBillEmailError(false))}
                onKeyUp={() => (billEmail === '' ? setBillEmailError(true) : setBillEmailError(false))}
              />
              <div className='billinfo__error'>
                <p className={billEmailError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <p className='billinfo__input__title'>Calle*</p>
              <label className='billinfo__label' htmlFor='bill-street'>
                Calle*
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-street'
                type='text'
                placeholder='Nombre de la calle'
                value={street}
                onChange={e => {
                  setStreet(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (street === '' ? setStreetError(true) : setStreetError(false))}
                onKeyUp={() => (street === '' ? setStreetError(true) : setStreetError(false))}
              />
              <div className='billinfo__error'>
                <p className={streetError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <div className='billinfo__numeros'>
                <div className='billinfo__numero'>
                  <p className='billinfo__input__title'>Número exterior*</p>
                  <label className='billinfo__label' htmlFor='bill-exterior'>
                    Numero exterior*
                  </label>
                  <input
                    className='billinfo__input billinfo__numero'
                    disabled={billRequired ? false : true}
                    id='bill-exterior'
                    type='text'
                    placeholder='0'
                    value={exterior}
                    onChange={e => {
                      setExterior(e.target.value)
                      setchangeInState(true)
                    }}
                    onBlur={() => (exterior === '' ? setExteriorError(true) : setExteriorError(false))}
                    onKeyUp={() => (exterior === '' ? setExteriorError(true) : setExteriorError(false))}
                  />
                  <div className='billinfo__error'>
                    <p className={exteriorError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                      Campo obligatorio
                    </p>
                  </div>
                </div>

                <div className='billinfo__numero'>
                  <p className='billinfo__input__title'>Número interior*</p>
                  <label className='billinfo__label' htmlFor='bill-exterior'>
                    Numero interior*
                  </label>
                  <input
                    className='billinfo__input'
                    disabled={billRequired ? false : true}
                    id='bill-interior'
                    type='text'
                    placeholder='0'
                    value={interior}
                    onChange={e => {
                      setInterior(e.target.value)
                      setchangeInState(true)
                    }}
                    onBlur={() => (interior === '' ? setInteriorError(true) : setInteriorError(false))}
                    onKeyUp={() => (interior === '' ? setInteriorError(true) : setInteriorError(false))}
                  />
                  <div className='billinfo__error'>
                    <p className={interiorError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                      Campo obligatorio
                    </p>
                  </div>
                </div>
              </div>

              <p className='billinfo__input__title'>Código Postal*</p>
              <label className='billinfo__label' htmlFor='bill-cp'>
                Código Postal
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-cp'
                type='text'
                placeholder='00000'
                value={cp}
                onChange={e => {
                  setCp(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (cp === '' ? setCpError(true) : setCpError(false))}
                onKeyUp={() => {
                  cp === '' ? setCpError(true) : setCpError(false)
                  findAdress()
                }}
              />
              <div className='billinfo__error'>
                <p className={cpError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>Campo obligatorio</p>
              </div>

              <p className='billinfo__input__title'>Ciudad*</p>
              <label className='billinfo__label' htmlFor='bill-city'>
                Ciudad*
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-city'
                type='text'
                placeholder='Ingresa tu Ciudad.'
                value={city}
                onChange={e => {
                  setCity(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (city === '' ? setCityError(true) : setCityError(false))}
                onKeyUp={() => (city === '' ? setCityError(true) : setCityError(false))}
              />
              <div className='billinfo__error'>
                <p className={cityError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <p className='billinfo__input__title'>Alcaldía/Municipio*</p>
              <label className='billinfo__label' htmlFor='bill-alc-mun'>
                Alcaldía/Municipio
              </label>
              <input
                className='billinfo__input'
                disabled={billRequired ? false : true}
                id='bill-alc-mun'
                type='text'
                placeholder='Ingresa tu Alcaldía/Municipio'
                value={alcMun}
                onChange={e => {
                  setAlcMun(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (alcMun === '' ? setAlcMunError(true) : setAlcMunError(false))}
                onKeyUp={() => (alcMun === '' ? setAlcMunError(true) : setAlcMunError(false))}
              />
              <div className='billinfo__error'>
                <p className={alcMunError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <p className='billinfo__input__title'>Colonia*</p>
              <label className='billinfo__label' htmlFor='bill-col'>
                Colonia
              </label>
              <select
                className='billinfo__select'
                required
                name='bill-col'
                id='bill-col'
                disabled={(colArr.includes('Colonia *') && colArr.length === 1) || !billRequired ? true : false}
                onChange={e => {
                  setColonia(e.target.value)
                  setchangeInState(true)
                }}
                onBlur={() => (colonia === '' ? setColoniaError(true) : setColoniaError(false))}
                onKeyUp={() => (colonia === '' ? setColoniaError(true) : setColoniaError(false))}
              >
                <option className={'billinfo__select__placeholder'} value='' defaultValue='Nombre de la colonia'>
                  {colonia === '' ? 'Nombre de la colonia' : colonia}
                </option>
                {colArr.map((col, idx) => {
                  return col === 'Colonia *' ? (
                    <option key={idx} value='' disabled hidden>
                      {col}
                    </option>
                  ) : (
                    <option key={idx} value={col}>
                      {col}
                    </option>
                  )
                })}
              </select>
              <div className='billinfo__error'>
                <p className={coloniaError ? 'billinfo__error__text' : 'billinfo__error__transparent'}>
                  Campo obligatorio
                </p>
              </div>

              <Button
                size={'full'}
                disabled={!checkIfInvoiceIsComplete()}
                type='submit'
                value='Guardar'
                onClick={e => {
                  handleSubmit(e)
                  setchangeInState(false)
                }}
              >
                Guardar
              </Button>
            </>
          )}
        </BillInfoForm>
      </section>
    </PaymentContainer>
  )
}

export default Payment
