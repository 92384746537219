export const estados = [
  { id: 9, name: 'Ciudad de México' },
  { id: 12, name: 'Estado de México' },
  { id: 13, name: 'Aguascalientes' },
  { id: 14, name: 'Zacatecas' },
  { id: 15, name: 'Sinaloa' },
  { id: 16, name: 'Michoacán' },
  { id: 17, name: 'Jalisco' },
  { id: 18, name: 'Baja California' },
  { id: 19, name: 'Baja California Sur' },
  { id: 20, name: 'Campeche' },
  { id: 21, name: 'Coahuila' },
  { id: 22, name: 'Colima' },
  { id: 23, name: 'Chiapas' },
  { id: 24, name: 'Chihuahua' },
  { id: 25, name: 'Durango' },
  { id: 26, name: 'Guanajuato' },
  { id: 27, name: 'Guerrero' },
  { id: 28, name: 'Hidalgo' },
  { id: 29, name: 'Morelos' },
  { id: 30, name: 'Nayarit' },
  { id: 31, name: 'Nuevo León' },
  { id: 32, name: 'Oaxaca' },
  { id: 33, name: 'Puebla' },
  { id: 34, name: 'Querétaro' },
  { id: 35, name: 'Quintana Roo' },
  { id: 36, name: 'San Luis Potosí' },
  { id: 37, name: 'Sonora' },
  { id: 38, name: 'Tabasco' },
  { id: 39, name: 'Tamaulipas' },
  { id: 40, name: 'Tlaxcala' },
  { id: 41, name: 'Veracruz' },
  { id: 42, name: 'Yucatán' }
]

export const generos = [
  { id: 1, name: 'Información' },
  { id: 2, name: 'Opinión' },
  { id: 3, name: 'Entrevista' },
  { id: 4, name: 'Reportaje' },
  { id: 5, name: 'Comentario' },
  { id: 6, name: 'Mesa de Debates' },
  { id: 7, name: 'Crónica' },
  { id: 8, name: 'Ensayo' },
  { id: 9, name: 'Cartones' },
  { id: 10, name: 'Fotografias' },
  { id: 11, name: 'Correspondencia' },
  { id: 12, name: 'Desplegados' },
  { id: 13, name: 'Portada Revista' },
  { id: 7612, name: 'Información/ Comentario' },
  { id: 6305, name: 'Ocho Columnas' },
  { id: 7604, name: 'Resumen' },
  { id: 6307, name: 'Publicidad' },
  { id: 6309, name: 'Columnas Políticas' },
  { id: 6310, name: 'Columnas Económicas' },
  { id: 6311, name: 'Comentarios Políticos' },
  { id: 6312, name: 'Comentarios Económicos' },
  { id: 6308, name: 'Carteleras' },
  { id: 55185, name: 'Infografías' },
  { id: 54988, name: 'Columnas Culturales' },
  { id: 54989, name: 'Columnas Tecnologícas' },
  { id: 54990, name: 'Columnas Espectáculos' },
  { id: 54991, name: 'Columnas Negocios' },
  { id: 23324, name: 'Teaser' },
  { id: 7643, name: 'Actas.' },
  { id: 7644, name: 'Acuerdos.' },
  { id: 7645, name: 'Iniciativas' },
  { id: 7646, name: 'Informes' },
  { id: 7893, name: 'Primeras Planas (Sólo imagen)' },
  { id: 7963, name: 'Encabezados ocho cols' },
  { id: 8086, name: 'Esquelas' },
  { id: 8303, name: 'Publirreportaje' },
  { id: 8304, name: 'Epigrama' },
  { id: 8305, name: 'Encuesta' },
  { id: 8306, name: 'Pie de Foto' },
  { id: 9814, name: 'Deportes' },
  { id: 8719, name: 'Convocatorias' },
  { id: 9371, name: 'Editorial' },
  { id: 38330, name: 'Programa Completo' },
  { id: 9707, name: 'Licitaciones' },
  { id: 53974, name: 'PP Cultura' },
  { id: 23284, name: 'Edicto' },
  { id: 23306, name: 'Internacional' },
  { id: 23305, name: 'Espectáculos' },
  { id: 23304, name: 'Clima' },
  { id: 11074, name: 'Las Breves' },
  { id: 43072, name: 'PP Ciudad' },
  { id: 43492, name: 'Columnas Deportivas' },
  { id: 43612, name: 'PP Negocios' },
  { id: 52563, name: 'Contraportada' },
  { id: 54020, name: 'PP Deportivas' },
  { id: 54021, name: 'PP Espectáculos' }
]
