import React, { useState } from 'react'

import CloseModal from '../../workspace/Modals/CloseModal'
import { DuplicateWidgetContainer } from '../../../style/components/dashboard/dashboard/DuplicateWidget.styled'
import Button from '../../ui/Button'

const DuplicateWidget = ({ widget, closeModal, handleDuplicateWidget }) => {
  return (
    <DuplicateWidgetContainer>
      <CloseModal onClick={closeModal} />
      <h3 className='widgetinfo__title'>Duplicar gráfico</h3>
      <p>
        Duplicar un gráfico (widget) creará una nueva versión de todos los parámetros y búsquedas asignadas al widget
        original.
      </p>
      <div className='widgetinfo__buttons'>
        <Button
          weight={500}
          variant={'link'}
          theme={'dark'}
          className='widgetinfo__button widgetinfo__cancel'
          onClick={closeModal}
        >
          Cancelar
        </Button>
        <Button onClick={() => handleDuplicateWidget(widget)}>Duplicar</Button>
      </div>
    </DuplicateWidgetContainer>
  )
}

export default DuplicateWidget
