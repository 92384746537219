import React from 'react'
import { SearchHourContainer } from '../../style/components/reports/SearchHour.styled'

const SearchHour = ({ hour, handleHour, setHour }) => {
  return (
    <SearchHourContainer>
      <h2>Hora de la búsqueda</h2>
      <div className='hour-inputs'>
        <label htmlFor='from-hour'>
          Desde:
          <input type='time' id='from-hour' value={hour[0]} onChange={e => handleHour('from', e)} />
        </label>
        <label htmlFor='to-hour'>
          Hasta:
          <input type='time' id='to-hour' value={hour[1]} onChange={e => handleHour('to', e)} />
        </label>
        <label className='input-checkbox' htmlFor='all-day'>
          <input
            type='checkbox'
            id='all-day'
            value='all-day'
            checked={hour[0] === '00:00' && hour[1] === '23:59' ? true : false}
            onChange={() => setHour(['00:00', '23:59'])}
          />
          Todo el día
        </label>
      </div>
    </SearchHourContainer>
  )
}

export default SearchHour
