import styled from 'styled-components'
import { colors } from '../../../variables'

export const InvoiceContainer = styled.div`
  display: flex;
  ${'' /* align-items: center; */}
  margin-left: 100px;
  width: 70vw;
  justify-content: space-between;
  .left-information {
    max-width: 525px;
    width: 50%;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: ${colors.darkGray};
      line-height: 41px;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 30px;
      width: 90%;
    }
    .text-note {
      color: ${colors.richBlack};
      font-size: 17px;
      font-weight: 600;
      width: 100%;
    }
    button {
      max-width: 433px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      margin-bottom: 39px;
    }
    .support {
      display: flex;
      flex-direction: column;
      width: 100%;
      p {
        font-size: 14px;
        margin-bottom: 10px;
        line-height: 14px;
      }
      a {
        font-size: 14px;
        color: ${colors.darkGray};
        font-weight: 600;
        text-decoration: underline;
      }
    }
  }
  .right-information {
    display: flex;
    flex-direction: column;
    width: 679px;
    padding: 50px;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    background: ${colors.white};
    margin-left: 100px;
    h2 {
      margin-bottom: 50px;
      font-size: 34px;
      font-weight: 800;
    }
    p {
      color: ${colors.darkGray};
      font-size: 17px;
      font-weight: 500;
    }
    .row-info {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .purple-text {
        color: ${colors.purple};
        font-size: 34px;
        font-weight: 400;
      }
    }
    hr {
      margin-top: 20px;
      margin-bottom: 35px;
    }
    .row-info-purple {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      background: ${colors.purple};
      height: 53px;
      border-radius: 4px;
      padding: 0 20px;
      p {
        color: ${colors.white};
      }
      .bold-text {
        font-size: 34px;
        font-weight: 800;
      }
    }
    .next-payment {
      margin-top: 25px;
      text-align: center;
      font-weight: 600;
      color: ${colors.richBlack};
    }
  }
  @media (min-width: 1024px) and (max-width: 1439px) {
    margin-left: 0;
    width: 85vw;
    .left-information {
      width: 900px;
      padding-top: 70px;
      margin-left: 45px;
      margin-right: 40px;
      h1 {
        font-size: 40px;
      }
      p {
        line-height: 29px;
        font-size: 20px;
        margin-bottom: 15px;
      }
    }
    .right-information {
      margin-left: 0;
      .row-info {
        width: 350px;
      }
      .next-payment {
        margin-top: 0;
      }
    }
  }
`
