import styled from 'styled-components'
import { colors } from '../../variables'

const InviteForm = styled.form`
  display: flex;
  flex-direction: column;
  .top-form-container {
    display: flex;
    justify-content: space-between;
    & .email {
      width: 65%;
    }
    & .dropdown {
      width: 30%;
    }
  }
  .align-in-column {
    display: flex;
    flex-direction: column;
  }
  .inviteform__label {
    margin-bottom: 8px;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    color: ${colors.richBlack};
  }
  .radio-option {
    display: flex;
    align-items: center;
  }
  .radio-input {
    width: 25px;
    height: 25px;
    margin-right: 15px;
    margin-bottom: 15px;
  }

  .inviteform__input {
    background: transparent;
    height: 48px;
    padding: 15px 0 15px 16px;
    border: 1px solid #748596;
    border-radius: 4px;
    margin-bottom: 20px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
  }

  select {
    background: transparent;
    height: 50px;
    padding-left: 10px;
    border: 1px solid ${colors.lightGray};
    border-radius: 4px;
    margin-bottom: 25px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
  }
  .submit-button {
    width: 100%;
    height: 50px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 50px;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }

  ${
    '' /* @media (min-width: 1440px) {
    width: 70%;
  } */
  }
`

export default InviteForm
