import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const ReportDurationContainer = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .date-inputs {
    display: flex;
    label {
      display: flex;
      align-items: center;
      width: 250px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 40px;
      margin-right: 25px;
      cursor: pointer;
      input {
        height: 40px;
        margin-left: 10px;
        border: 1px solid ${colors.darkGray};
        border-radius: 4px;
        padding: 0 10px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .hour-inputs {
    display: flex;
    label {
      display: flex;
      align-items: center;
      width: 175px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 40px;
      margin-right: 25px;
      cursor: pointer;
      input {
        height: 40px;
        margin-left: 10px;
        border: 1px solid ${colors.darkGray};
        border-radius: 4px;
        padding: 0 10px;
        outline: none;
        font-size: 16px;
        font-weight: 400;
      }
    }
    .input-checkbox {
      display: flex;
      align-items: center;
      width: 200px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 40px;
      cursor: pointer;
      input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
`
