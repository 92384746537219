import React from 'react'

const IconEdit = ({ stroke, fill }) => {
  return (
    <svg width='23px' height='23px' viewBox='0 0 23 23' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon edit</title>
      <g id='Symbols' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='icons/general/edit/primary'
          transform='translate(0.000000, -1.000000)'
          stroke={stroke ? stroke : '#00AAAB'}
        >
          <g id='icon-edit' transform='translate(1.000000, 1.000000)'>
            <path
              d='M12.2016807,12.7439776 L9.76134454,10.3036415 L19.1159664,0.949019608 C19.6582633,0.406722689 20.4717087,0.406722689 21.0140056,0.949019608 L21.4207283,1.3557423 C21.9630252,1.89803922 21.9630252,2.71148459 21.4207283,3.25378151 L12.2016807,12.7439776 Z'
              id='Path'
              fill={fill ? fill : '#00AAAB'}
            ></path>
            <path
              d='M9.08347339,10.9815126 L11.5238095,13.4218487 L8.13445378,15.1843137 C7.72773109,15.1843137 7.45658263,14.9131653 7.45658263,14.5064426 L9.08347339,10.9815126 Z'
              id='Path'
              fill={fill ? fill : '#00AAAB'}
            ></path>
            <path
              d='M18.5736695,10.1680672 L18.5736695,20.4717087 C18.5736695,21.2851541 18.0313725,21.827451 17.2179272,21.827451 L1.76246499,21.827451 C0.949019608,21.827451 0.406722689,21.2851541 0.406722689,20.4717087 L0.406722689,5.0162465 C0.406722689,4.20280112 0.949019608,3.6605042 1.76246499,3.6605042 L12.8795518,3.6605042'
              id='Path'
              stroke={stroke ? stroke : '#00AAAB'}
              strokeWidth='1.04761905'
              strokeLinecap='round'
              strokeLinejoin='round'
            ></path>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default IconEdit
