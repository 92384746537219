import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const WidgetsMenuContainer = styled.div`
  width: 700px;
  padding: 50px 75px;
  hr {
    margin: 50px 0;
  }
  .widgets-menu--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    h4 {
      font-size: 30px;
      font-weight: 700;
      color: ${colors.white};
    }
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        path {
          stroke: ${colors.white};
        }
      }
    }
  }
  .widgets-menu--description {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    color: ${colors.white};
    padding-bottom: 40px;
    border-bottom: 2px solid ${colors.white};
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  .widgets-menu--content {
    height: calc(100vh - 225px);
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-content: flex-start;
    padding: 40px 0;
    .titulo-seccion {
      color: #ffffff;
      margin-bottom: 20px;
      width: 100%;
      font-size: 14px;
      font-weight: 400;
      strong {
        font-size: 16px;
        font-weight: 600;
      }
    }
  }
`

export const WidgetButton = styled.article`
  width: 100px;
  height: 145px;
  border-radius: 4px;
  margin: 0 7.5px 40px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  img {
    width: 100px;
    height: 100px;
  }
  p {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.white};
    text-align: center;
  }
`
