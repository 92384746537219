import React from 'react'

const IconUsers = ({ stroke, fill }) => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon users</title>
      <g
        id='Symbols'
        stroke={stroke ? stroke : '#00AAAB'}
        strokeWidth='1'
        fill={fill ? fill : '#00AAAB'}
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g id='icons/general/users/primary' stroke={stroke ? stroke : '#00AAAB'}>
          <path
            d='M23,17.9 L23,21.7 C23,22.6 22.2,23.3 21.2,23.3 L10.8,23.3 C9.8,23.3 9,22.6 9,21.7 L9,17.9 C9,14.8 11.8,12.3 15.3,12.3 L16.7,12.3 C20.2,12.3 23,14.8 23,17.9 L23,17.9 Z M5,3.2 C6.5,3.2 7.8,4.4 7.8,6 C7.8,7.6 6.5,8.7 5,8.7 C3.5,8.7 2.2,7.5 2.2,6 C2.2,4.5 3.5,3.2 5,3.2 Z M6,12 C8,12 9.7,13.4 9.7,15.2 C9.3,16 9,17 9,18.1 L9,18.1 L9,22 C8.9,22 8.9,22 8.8,22 L8.6,22 C8.6,22 8.5,22 8.5,22 L2.6,22 C1.7,22 1,21.4 1,20.6 L1,15.3 C1,13.5 2.7,12 4.8,12 L6,12 Z M16.4,1 C18.8,1 20.8,2.9 20.8,5.3 C20.8,7.7 18.9,9.6 16.4,9.6 C13.9,9.6 12,7.8 12,5.3 C12,2.8 13.9,1 16.4,1 Z'
            id='icon-users'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default IconUsers
