import React, { useState } from 'react'
import { PaymentFormContainer, CheckoutFormContainer } from '../../style/components/auth/PaymentForm/PaymentForm.styled'
import { colors } from '../../style/variables'
import { ButtonSpinner } from '../../style/components'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import Button from '../ui/Button'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) console.error('Stripe publishable key enviroment variable not set')

const CheckoutForm = ({
  productSelected,
  customer,
  handleCreateSubscription,
  handleCreateWorkspace,
  setStep,
  showCodeModal,
  validCode,
  showErrorModal
}) => {
  const stripe = useStripe()
  const elements = useElements()
  const [subscribing, setSubscribing] = useState(false)
  // eslint-disable-next-line
  const [ accountInformation, setAccountInformation ] = useState(null)
  const [cardName, setCardName] = useState('')
  const [cardError, setCardError] = useState(false)

  const handleCustomerActionRequired = (subscription, paymentMethodId, priceId) => {
    const paymentIntent = subscription.latest_invoice.payment_intent

    return stripe
      .confirmCardPayment(paymentIntent.client_secret, {
        payment_method: paymentMethodId
      })
      .then(result => {
        if (result.error) {
          // TODO: Handle errors
        } else if (result.paymentIntent.status === 'succeeded') {
          // TODO: IDK what to do... in theory everything is ok...
        }
      })
  }

  const handlePaymentMethodRequired = ({ subscription, paymentMethodId, priceId }) => {
    if (subscription.status === 'active') {
      // subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId }
    } else if (subscription.latest_invoice.payment_intent.status === 'requires_payment_method') {
      // Using localStorage to store the state of the retry here
      // (feel free to replace with what you prefer)
      // Store the latest invoice ID and status
      localStorage.setItem('latestInvoiceId', subscription.latest_invoice.id)
      localStorage.setItem('latestInvoicePaymentIntentStatus', subscription.latest_invoice.payment_intent.status)
      throw new Error({ error: { message: 'Your card was declined.' } })
    } else {
      return { subscription, priceId, paymentMethodId }
    }
  }

  const onSubscriptionComplete = (subscription, paymentMethodId, priceId) => {
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    setAccountInformation({
      subscription: subscription,
      paymentMethodId: paymentMethodId,
      priceId: priceId
    })
    handleCreateWorkspace(priceId, subscription.id)
    setStep(5)
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  const onSubscriptionIncomplete = (subscription, paymentMethodId, priceId) => {
    const {
      latest_invoice: {
        status,
        payment_intent: { status: errorPayment }
      }
    } = subscription

    if (status === 'open') {
      switch (errorPayment) {
        case 'requires_action':
          handleCustomerActionRequired(subscription, paymentMethodId, priceId)
          break
        case 'requires_payment_method':
          handlePaymentMethodRequired(subscription, paymentMethodId, priceId)
          break
        default:
          console.log('{NOT MAPPED - Payment Error}', errorPayment)
          break
      }
    } else {
      console.log('{NOT MAPPED - Payment Status}', status)
    }
  }

  const onSubscriptionTrial = (subscription, paymentMethodId, priceId) => {
    // Should have its own flow
    // Payment was successful. Provision access to your service.
    // Remove invoice from localstorage because payment is now complete.
    // clearCache();
    setAccountInformation({
      subscription: subscription,
      paymentMethodId: paymentMethodId,
      priceId: priceId
    })
    handleCreateWorkspace(priceId, subscription.id)
    setStep(5)
    // Change your UI to show a success message to your customer.
    // onSubscriptionSampleDemoComplete(result);
    // Call your backend to grant access to your service based on
    // the product your customer subscribed to.
    // Get the product by using result.subscription.price.product
  }

  const createSubscription = async ({ paymentMethodId }) => {
    const priceId = productSelected.name.toUpperCase()

    const subscription = await handleCreateSubscription(customer.id, paymentMethodId, priceId, validCode)

    // using SUBSCRIPTION response

    const { status } = subscription

    switch (status) {
      case 'active':
        onSubscriptionComplete(subscription, paymentMethodId, priceId)
        break
      case 'incomplete':
        onSubscriptionIncomplete(subscription, paymentMethodId, priceId)
        break
      case 'trialing':
        onSubscriptionTrial(subscription, paymentMethodId, priceId)
        break
      case 'error':
        setSubscribing(false)
        showErrorModal(true)
        break
      default:
        console.log('{NOT MAPPED - Subscription Status}', status)
        break
    }
  }

  const handleSubmit = async e => {
    e.preventDefault()

    if (cardName === '') {
      return setCardError(true)
    } else {
      setCardError(false)
    }

    setSubscribing(true)

    if (!stripe || !elements) {
      // TODO: Stripe has not loaded yet, disable
      // form submission until Stripe has loaded
      return
    }

    const cardElement = elements.getElement(CardElement)

    // NOTE: Create Stripe payment method
    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
      billing_details: {
        name: cardName
      }
    })
    if (error) {
      setSubscribing(false)
    } else {
      await createSubscription({ paymentMethodId: paymentMethod.id })
    }
  }

  return (
    <PaymentFormContainer>
      <section className='left-information'>
        <h1>Datos de pago</h1>
        <p>¡Estás a un paso de tener el poder de la información en tus manos! Completa tu pago para poder comenzar.</p>
        <Button size={'full'} weight={500} theme={'secondary'} shadow disabled={subscribing} onClick={handleSubmit}>
          {subscribing ? <ButtonSpinner /> : 'Siguiente: obtén tu recibo de pago'}
        </Button>
      </section>

      <CheckoutFormContainer>
        <h2>Ingresa los datos de tu tarjeta</h2>
        <div className='payment-total'>
          <p>Total a pagar:</p>
          <p className='purple-total'>${validCode ? 0 : productSelected.price}.00 MXN</p>
        </div>
        <div className='payment-plan'>
          <p>Plan seleccionado:</p>
          <p className='purple-plan'>{productSelected.name}</p>
        </div>
        <div className='payment-plan'>
          <p>Tipo de plan:</p>
          <p className='purple-plan'>{productSelected.billed}</p>
        </div>
        <div className='promotion-message-container'>
          {validCode && (
            <p className='promotion-message'>
              Al concluir tu periodo de prueba se realizará un cobro automático por ${productSelected.price}.00 MXN
            </p>
          )}
        </div>

        <hr />

        <label>Nombre completo</label>
        <input
          id='name'
          type='text'
          placeholder='Nombre completo'
          required
          value={cardName}
          onChange={e => setCardName(e.target.value)}
        />
        {cardError && <p>Ingresa el nombre de la tarjeta</p>}
        <div className='card-element'>
          <CardElement
            options={{
              hidePostalCode: true,
              style: {
                base: {
                  fontSize: '14px',
                  color: '#32325d',
                  fontFamily: 'Montserrat',
                  '::placeholder': {
                    color: `${colors.darkGray}`
                  }
                },
                invalid: {
                  color: `${colors.red}`
                }
              }
            }}
          />
        </div>
        <div className='discount-code'>
          <p className='discount__code__message'>¿Tienes un código de descuento?</p>
          <Button weight={500} theme={'primary'} variant={'link'} onClick={() => showCodeModal(true)}>
            Ingrésalo aquí
          </Button>
        </div>
      </CheckoutFormContainer>
    </PaymentFormContainer>
  )
}

const PaymentForm = props => (
  <Elements stripe={stripePromise}>
    <CheckoutForm {...props} />
  </Elements>
)

export default PaymentForm
