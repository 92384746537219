import React from 'react'
import { SearchBarContainer } from '../../../style/components/search/new-serches/SearchBar.styled'
import { Tag } from '../../../style/components'
import CloseIcon from '../../../assets/CloseIcon'

const SearchBar = ({ width, queryParams, inputWord, handleInput, addParam, deleteParam, placeholder }) => {
  let input

  return (
    <SearchBarContainer width={width} onClick={() => input.focus()}>
      {queryParams.length > 0 ? null : <p className='searchbar-placeholder'>{placeholder}</p>}
      {queryParams.map((param, idx) => (
        <Tag key={idx} id={idx}>
          {param}
          <span onClick={() => deleteParam(idx)}>
            <CloseIcon />
          </span>
        </Tag>
      ))}
      <input type='text' value={inputWord} onChange={handleInput} onKeyDown={addParam} ref={i => (input = i)} />
    </SearchBarContainer>
  )
}

export default SearchBar
