import React, { useEffect, useRef, useState } from 'react'
import { DataDropdownContainer } from '../../style/components/ui/DataDropdown.styled'

import { FiCheck } from 'react-icons/fi'
import { MdKeyboardArrowDown, MdKeyboardArrowUp } from 'react-icons/md'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const DataDropdown = ({ width, title, items, selection, setSelection, multiSelect }) => {
  const [open, setOpen] = useState(false)

  const dropdown = useRef()

  useOutsideClick(dropdown, () => {
    setOpen(false)
  })

  const handleOnClick = item => {
    if (!selection.some(current => current === item)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current !== item)
      setSelection([...selectionAfterRemoval])
    }
    if (!multiSelect) {
      setOpen(false)
    }
  }

  const isItemInSelection = item => {
    return selection.find(current => current === item) ? true : false
  }

  return (
    <DataDropdownContainer width={width} ref={dropdown}>
      <div
        tabIndex={0}
        className='dd-header'
        role='button'
        onKeyPress={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        <div className='dd-header-title'>
          {selection.length === 0 || selection[0] === '' ? (
            <p>{title}</p>
          ) : (
            selection.map(item => <p key={item}>{item}</p>)
          )}
        </div>
        <div className='dd-header-action'>
          <p>{open ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</p>
        </div>
      </div>
      {open && (
        <ul className='dd-list'>
          {items.map(item => (
            <li key={item} className='dd-list-item'>
              <button onClick={() => handleOnClick(item)}>
                <p>{item}</p>
                <span className='dd-list-item-check'>{isItemInSelection(item) && <FiCheck />}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </DataDropdownContainer>
  )
}

export default DataDropdown
