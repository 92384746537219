import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const EditBoardContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 711px;
  padding: 30px 40px 50px;
  border-radius: 4px;
  background: ${colors.white};
  .boardinfo__title {
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.26px;
    color: ${colors.richBlack};
  }
  .boardinfo__form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    margin-bottom: 20px;
    .boardinfo__text {
      width: 100%;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .boardinfo__input {
      width: 100%;
      height: 48px;
      padding: 15px 0 15px 26px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .boardinfo__description__header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      .text__align--right {
        text-align: right;
      }
    }
    .boardinfo__textarea {
      width: 100%;
      height: 100px;
      padding: 15px 0 0 23px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 20px;
      resize: none;
    }
  }
  .boardinfo__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    .boardinfo__button {
      margin-left: 10px;
    }
  }
`
