import styled from 'styled-components'
import { colors } from '../../variables'

export const NewDashboardContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 70px);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 40px;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 50%;
    padding-bottom: 30px;
    .new-dashboard-title {
      margin-bottom: 40px;
      color: #0b0c10;
      font-size: 30px;
      font-weight: 700;
    }
    label {
      width: 100%;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 400;
    }
    input {
      width: 100%;
      height: 47px;
      padding: 0 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    textarea {
      width: 100%;
      height: 75px;
      padding: 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 20px;
    }
    .members-header {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
      svg {
        margin-left: 5px;
      }
    }
    .members-section {
      min-height: 60px;
      border: 1px solid ${colors.darkGray};
      background: ${colors.white};
      border-radius: 4px;
      width: 100%;
      overflow-y: scroll;
      margin-bottom: 10px;
    }
    .add-member-btn {
      width: 100%;
      font-size: 14px;
      font-weight: 500;
      color: ${colors.lightseaGreen};
      margin-bottom: 10px;
      cursor: pointer;
    }
    .invite-section {
      width: 100%;
      margin-bottom: 20px;
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      .share-form {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        width: 100%;
        height: 80px;
        .left-input {
          width: 52.5%;
        }
        .right-input {
          width: 42.5%;
        }
      }
      .invite-button {
        width: 210px;
        height: 50px;
        border: none;
        border-radius: 4px;
        outline: none;
        font-size: 16px;
        font-weight: 600;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }
    }
  }
`

export const Member = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 25px 10px 20px;
  .member-left {
    display: flex;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .member-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .member-name {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
      }
      .member-email {
        font-size: 12px;
        font-weight: 500;
        color: ${colors.richBlack};
      }
    }
  }
  .permit-buttons {
    display: flex;
    .member-owner {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 4px;
      background: ${colors.lightGray};
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 500;
    }
    .delete-permit-button {
      border: none;
      outline: none;
      margin-right: 20px;
      background: none;
      color: ${colors.red};
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .m-button-editor {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 4px;
      outline: none;
      background: ${colors.purple};
      color: ${colors.white};
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
    .m-button-viewer {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100px;
      height: 30px;
      border: none;
      border-radius: 4px;
      outline: none;
      background: ${colors.lightseaGreen};
      color: ${colors.white};
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  &:hover {
    background: ${colors.lightGray};
    .delete-permit-button {
      display: block;
    }
  }
`
