import styled from 'styled-components'
import { colors } from '../../../variables'

const UserRolesContainer = styled.div`
  position: absolute;
  width: 319px;
  height: 200px;
  border-top: 1px solid ${colors.lightseaGreen};
  border-radius: 0 0 4px 4px;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  right: 25px;
  top: 88%;
  z-index: 2;

  .dropdown-option {
    width: 100%;
    height: 100px;
    padding: 20px;
    border: none;
    border-radius: 0 0 4px 4px;
    outline: none;
    background: none;
    cursor: pointer;

    .user__role__header {
      display: flex;
      flex-direction: row;

      .user__role {
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        padding-bottom: 5px;
        line-height: 18px;
        margin-right: 10px;
      }

      .user__role__selected {
        color: ${colors.richBlack};
      }
    }

    .user__description {
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      line-height: 18px;
    }
    &:hover {
      background: ${colors.lightseaGreen};
    }
  }
`

export default UserRolesContainer
