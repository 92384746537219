import React, { useContext } from 'react'
import { Redirect } from 'react-router-dom'
import SearchContainer from '../../style/pages/searchMobile/Search.styled'
import { MainContainer, Topbar, Spinner } from '../../style/components'
import { HiArrowLeft } from 'react-icons/hi'
import { useQuery } from '@apollo/client'
import News from '../../components/search/search/NewsM'
import Tweets from '../../components/search/search/TweetsM'

import { SEARCH_QUERY } from '../../services/queries'
import { MyContext } from '../../context'

const Search = props => {
  const context = useContext(MyContext)

  const { data, loading, error } = useQuery(SEARCH_QUERY, {
    variables: {
      id: props.match.params.id
    }
  })

  if (loading) return <Spinner />
  if (error) {
    console.log(error)
    if (error.message.includes('uniqueSession')) context.handleErrors(error)
    else return <Redirect to='/searches' />
  }
  const search = data.search

  return (
    <MainContainer padding='56px 2.5%'>
      <SearchContainer>
        <Topbar className='topbarMobile'>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => props.history.push('/searches')}>
              <HiArrowLeft className='back-icon' />
            </button>
            <p>{search.name}</p>
          </div>
        </Topbar>

        {search.source.includes('medios') && <News search={search} />}

        {search.source.includes('twitter') && <Tweets search={search} />}
      </SearchContainer>
    </MainContainer>
  )
}

export default Search
