import React, { useState } from 'react'

import { TweetContainer } from '../../../style/components/search/new-serches/Tweet.styled'

import { FiTwitter } from 'react-icons/fi'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'
// import { BsCheckBox, BsSquare } from 'react-icons/bs'

const Tweet = ({ tweet }) => {
  const [source, setSource] = useState(tweet.profile_image)
  // const [selected, setSelected] = useState(false)

  const onError = () =>
    setSource('https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg')

  return (
    <TweetContainer>
      <div className='tweet-card-header'>
        {/* <div className='tweet-checkbox'>
          {selected ?
            <button
              className='checkbox-indicator'
              onClick={() => setSelected(false)}
            >
              <BsCheckBox className='checked' />
            </button>
            :
            <button
              className='checkbox-indicator'
              onClick={() => setSelected(true)}
            >
              <BsSquare className='not-checked' />
            </button>
          }
        </div> */}
        <div className='tc-header-info'>
          <img src={source} onError={onError} alt='twitter avatar' />
          <div className='header-text'>
            <h5>@{tweet.user}</h5>
            <h6>{tweet.location}</h6>
          </div>
        </div>
        <div className='tc-header-icon'>
          <FiTwitter />
        </div>
      </div>
      <div className='tweet-text'>
        <p className='tweet-content'>{tweet.text}</p>
      </div>
      <a href={tweet.url} target='_blank' rel='noopener noreferrer'>
        Abrir tweet
      </a>
      <div className='tweet-date-section'>
        <div className='tweet-date'>
          <AiOutlineCalendar />
          <p>{tweet.date.split('').splice(0, 10).join('')}</p>
        </div>
        <div className='tweet-time'>
          <AiOutlineClockCircle />
          <p>{tweet.date.split('').splice(11, 10).join('')} hrs</p>
        </div>
      </div>
    </TweetContainer>
  )
}

export default Tweet
