import React, { useContext, useState } from 'react'
import { Link, useHistory } from 'react-router-dom'
import { TicketDetailContainer, EditButton } from '../../style/pages/report/TicketDetail.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Spinner, Shadow } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'

import { useMutation, useQuery } from '@apollo/client'
import {
  ExposicionMedios,
  InfluencersTwitter,
  MapaCalor,
  MediosGanados,
  MencionesTwitter,
  NubeHashtags,
  NumeroNotas,
  PublicadoresVolumen,
  ShareVoice,
  TemasMomento,
  TipoMedios,
  TonalidadMedios,
  TonalidadTwitter
} from '../../assets/Widgets'
import ShareModal from '../../components/report/reports/ShareModalReport'
import { DeleteModal } from '../../components/report/reports/DeleteModalReport'
import { RowReport } from '../../components/report/reports/RowReport'

import { GET_TICKET } from '../../services/queries'
import { DELETE_REPORT } from '../../services/mutations'

const TicketDetail = props => {
  const history = useHistory()

  const size = {
    width: '30px',
    height: '30px'
  }

  const uiCTX = useContext(UIContext)

  const { mainClass, topbarClass } = uiCTX

  const { data, loading, refetch } = useQuery(GET_TICKET, {
    variables: { id: props.match.params.id }
  })

  const [fullWidgets, setFullWidgets] = useState(false)
  const [openShare, setOpenShare] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [reportName, setReportName] = useState('')
  const [reportId, setReportId] = useState('')

  const [deleteReport] = useMutation(DELETE_REPORT)

  const openDetailReport = id => {
    const win = window.open(`/reports/report/${id}`, '_blank')
    win.focus()
  }

  const openShareModal = id => {
    setOpenShare(true)
    setReportId(id)
  }

  const openDeleteModal = (id, name) => {
    setOpenDelete(true)
    setReportId(id)
    setReportName(name)
  }

  const handleDeleteReport = async id => {
    await deleteReport({ variables: { id } })
    setOpenDelete(false)
    refetch()
  }

  if (loading) return <Spinner />
  return (
    <MainContainer className={mainClass()}>
      <TicketDetailContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <Link to='/reports' className='back-link'>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </Link>
            <h1>Reporte</h1>
          </div>
        </Topbar>
        <section className='left-ticket'>
          <h2>Detalles del reporte</h2>
          <div className='ticket-details'>
            <p>
              <span>Nombre del reporte: </span>
              {data.getTicket.name}
            </p>
            <p>
              <span>Tablero: </span>
              {data.getTicket.dashboard.name}
            </p>
            <div className='widget-title'>
              <p>
                <span>Widgets: </span>
              </p>
              <button
                className={data.getTicket.widgets.length < 4 ? 'inactive' : 'active'}
                disabled={data.getTicket.widgets.length < 4}
                onClick={() => setFullWidgets(!fullWidgets)}
              >
                {fullWidgets ? 'Ver menos' : 'Ver todos'}
              </button>
            </div>
            {fullWidgets
              ? data.getTicket.widgets.map((e, i) => (
                  <div className='widget-item' key={i}>
                    {/* Twiiter */}
                    {e.kind === 'MencionesTwitter' && <MencionesTwitter {...size} />}
                    {e.kind === 'TonalidadTwitter' && <TonalidadTwitter {...size} />}
                    {e.kind === 'PublicadoresVolumen' && <PublicadoresVolumen {...size} />}
                    {e.kind === 'InfluencersTwitter' && <InfluencersTwitter {...size} />}
                    {e.kind === 'NubeHashtags' && <NubeHashtags {...size} />}
                    {/* Medios */}
                    {e.kind === 'TonalidadMedios' && <TonalidadMedios {...size} />}
                    {e.kind === 'NumeroNotas' && <NumeroNotas {...size} />}
                    {e.kind === 'MediosGanados' && <MediosGanados {...size} />}
                    {e.kind === 'TipoMedios' && <TipoMedios {...size} />}
                    {e.kind === 'TemasMomento' && <TemasMomento {...size} />}
                    {e.kind === 'MapaCalor' && <MapaCalor {...size} />}
                    {e.kind === 'ExposicionMedios' && <ExposicionMedios {...size} />}
                    {e.kind === 'ShareVoice' && <ShareVoice {...size} />}
                    <p>{e.name}</p>
                  </div>
                ))
              : data.getTicket.widgets.slice(0, 3).map((e, i) => (
                  <div className='widget-item' key={i}>
                    {/* Twiiter */}
                    {e.kind === 'MencionesTwitter' && <MencionesTwitter {...size} />}
                    {e.kind === 'TonalidadTwitter' && <TonalidadTwitter {...size} />}
                    {e.kind === 'PublicadoresVolumen' && <PublicadoresVolumen {...size} />}
                    {e.kind === 'InfluencersTwitter' && <InfluencersTwitter {...size} />}
                    {e.kind === 'NubeHashtags' && <NubeHashtags {...size} />}
                    {/* Medios */}
                    {e.kind === 'TonalidadMedios' && <TonalidadMedios {...size} />}
                    {e.kind === 'NumeroNotas' && <NumeroNotas {...size} />}
                    {e.kind === 'MediosGanados' && <MediosGanados {...size} />}
                    {e.kind === 'TipoMedios' && <TipoMedios {...size} />}
                    {e.kind === 'TemasMomento' && <TemasMomento {...size} />}
                    {e.kind === 'MapaCalor' && <MapaCalor {...size} />}
                    {e.kind === 'ExposicionMedios' && <ExposicionMedios {...size} />}
                    {e.kind === 'ShareVoice' && <ShareVoice {...size} />}
                    <p>{e.name}</p>
                  </div>
                ))}
            <div>
              <p>
                <span>Tipo de reporte: </span>
                {data.getTicket.type === 'scheduled' ? 'Programado' : 'Único'}
              </p>
              <p>
                <span>Periódo de búsqueda: </span>
                {data.getTicket.period}
              </p>
              <p>
                <span>Rango de búsqueda: </span>
                {data.getTicket.hour[0]} - {data.getTicket.hour[1]}
              </p>
            </div>
            <div>
              <p>
                <span>Recibir: </span>
                {data.getTicket.schedule}
              </p>
              <p>
                <span>Inicia el: </span>
                {data.getTicket.duration[0]}
              </p>
              <p>
                <span>Termina el: </span>
                {data.getTicket.duration[1] === 'endless' ? 'Sin finalizar' : data.getTicket.duration[1]}
              </p>
              <p>
                <span>Hora de envío: </span>
                {data.getTicket.reportHour}
              </p>
            </div>
            <div>
              <p>
                <span>Creado por: </span>
              </p>
              <p>{data.getTicket.createdBy.name}</p>
              <p>
                <span>Compartido con: </span>
              </p>
              <p>
                {data.getTicket.users.map(user => (
                  <p key={user.id}>{user.name}</p>
                ))}
              </p>
            </div>
            <EditButton>
              <button
                disabled={!data.getTicket.editable}
                onClick={() => history.push(`/reports/scheduled/edit/${props.match.params.id}`)}
              >
                Editar Reporte
              </button>
            </EditButton>
          </div>
        </section>
        <section className='right-ticket'>
          <h2>Reportes generados</h2>

          <div className='reports-table-header'>
            <h3 className='number'>No.</h3>
            <h3>Nombre</h3>
            <h3>Fecha</h3>
            <h3>Hora</h3>
          </div>

          <div className='reports-table-body'>
            {data.getTicket.reports.map(report => {
              const date = new Date(Number(report.createdAt))
              const createdDate = date.toString().split('').slice(4, 15).join('')
              const createdHour = date.toString().split('').slice(16, 21).join('')
              return (
                <RowReport
                  key={report.id}
                  report={report}
                  createdDate={createdDate}
                  createdHour={createdHour}
                  openDeleteModal={openDeleteModal}
                  openShareModal={openShareModal}
                  openDetailReport={openDetailReport}
                />
              )
            })}
          </div>
        </section>
      </TicketDetailContainer>

      {openShare && (
        <>
          <Shadow onClick={() => setOpenShare(false)} />
          <ShareModal reportId={reportId} />
        </>
      )}

      {openDelete && (
        <>
          <Shadow onClick={() => setOpenDelete(false)} />
          <DeleteModal
            reportId={reportId}
            reportName={reportName}
            origin={0}
            setOpenDelete={setOpenDelete}
            deleteReport={handleDeleteReport}
          />
        </>
      )}
    </MainContainer>
  )
}

export default TicketDetail
