import React from 'react'
import { useHistory } from 'react-router-dom'
import { NoDashboardsContainer } from '../../../style/components/dashboard/dashboards/NoDashboards.styled'

import { Dashboard } from '../../../assets/Flows'
import Button from '../../ui/Button'

const NoDashboards = ({ filtersOpen, shared = false, nobutton }) => {
  const history = useHistory()

  return (
    <NoDashboardsContainer filtersOpen={filtersOpen}>
      <div className='no-searches--icon'>
        <Dashboard />
      </div>
      {!shared ? (
        <>
          <p>{nobutton ? 'No tienes tableros.' : '¡Crea un tablero ahora mismo!'}</p>
          {!nobutton && (
            <Button
              width={'560px'}
              height='40px'
              theme='primary'
              variant='solid'
              onClick={() => history.push('/dashboards/new-dashboard')}
            >
              Nuevo tablero
            </Button>
          )}
        </>
      ) : (
        <p>Aún no tienes tableros compartidos.</p>
      )}
    </NoDashboardsContainer>
  )
}

export default NoDashboards
