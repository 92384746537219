import { useMutation } from '@apollo/client'
import React, { useState } from 'react'
import FormContainer from '../../../style/components/workspace/Modals/DowngradeModal.styled'
import Dropdown from '../../ui/Dropdown'
import { InfoModal } from './InfoModal'
import { GrClose } from 'react-icons/gr'

import { CREATE_CONTACT } from '../../../services/mutations'
import Button from '../../ui/Button'

export const DowngradeModal = ({ setShowDowngradeModal, actualPlan, desiredProduct, products }) => {
  const options = products.map((e, i) => {
    return { id: i, name: `${e.name} ${e.billed}` }
  })

  const [desiredPlan, setDesiredPlan] = useState(
    options.filter(e => e.name === `${desiredProduct.name} ${desiredProduct.billed}`)
  )
  const [description, setDescription] = useState('')
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showMessage, setShowMessage] = useState(false)
  const [message, setMessage] = useState('')

  const [createContactRequest] = useMutation(CREATE_CONTACT)

  const handleSubmit = async () => {
    const contact = {
      desiredPlan: desiredPlan[0].name,
      description
    }

    try {
      await createContactRequest({
        variables: { input: contact }
      })
    } catch ({ message }) {
      setShowMessage(true)
      setMessage(message)
    }

    setShowInfoModal(true)
  }

  const validateForm = () => {
    if (desiredPlan[0] !== undefined && description.length <= 300) return false
    else return true
  }

  if (showInfoModal) return <InfoModal setShowInfoModal={setShowDowngradeModal} />

  return (
    <FormContainer>
      <div className='modal__close'>
        <div className='close__icon'>
          <GrClose onClick={() => setShowDowngradeModal(false)} />
        </div>
      </div>
      <h5 className='changeplan__title'>Cambio de plan</h5>
      <div className='changeplan__message__container'>
        <p className='changeplan__text'>
          Cambiar a un plan inferior requiere que te pongas en contacto con nuestro equipo de ventas. Por favor completa
          los siguientes campos:
        </p>
      </div>
      <div className='changeplan__form'>
        <label className='select__plan__title'>Plan deseado</label>
        <div className='dropdownContainer'>
          <Dropdown
            placeholder='Elige tu nuevo plan'
            options={options}
            selection={desiredPlan}
            setSelection={setDesiredPlan}
            multiSelect={false}
          />
        </div>
        <div className='textAreaLabel'>
          <label>¿Por que quieres cambiar de plan?</label>
          <label>{description.length}/300</label>
        </div>
        <textarea
          name='description'
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder='Nos interesa saber tu opinión y poder mejorar nuestro servicio.'
        />
        {showMessage && <p className='error-message'>{message}</p>}
        <div className='button-container'>
          <Button
            weight={500}
            variant={'link'}
            theme={'dark'}
            className='button-cancel'
            onClick={() => setShowDowngradeModal(false)}
          >
            Cancelar
          </Button>
          <Button
            weight={500}
            className={validateForm() ? 'button-submit-inactive' : 'button-submit'}
            disabled={validateForm()}
            onClick={() => handleSubmit()}
          >
            Enviar formulario
          </Button>
        </div>
      </div>
    </FormContainer>
  )
}
