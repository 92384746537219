import React from 'react'
import { ReportTypeContainer } from '../../style/components/reports/ReportType.styled'

const ReportType = ({ type, setType }) => {
  return (
    <ReportTypeContainer>
      <h2>Tipo de reporte</h2>
      <div className='report-type-checkboxes'>
        <label htmlFor='only-once'>
          <input
            type='checkbox'
            id='only-once'
            value='once'
            checked={type === 'once' ? true : false}
            onChange={() => setType('once')}
          />
          Única vez
        </label>
        <label htmlFor='scheduled'>
          <input
            type='checkbox'
            id='scheduled'
            value='scheduled'
            checked={type === 'scheduled' ? true : false}
            onChange={() => setType('scheduled')}
          />
          Programado
        </label>
      </div>
    </ReportTypeContainer>
  )
}

export default ReportType
