import { ApolloClient, ApolloLink, createHttpLink, from, InMemoryCache, split } from '@apollo/client'
import { getMainDefinition } from '@apollo/client/utilities'
import { setContext } from 'apollo-link-context'
import { onError } from 'apollo-link-error'
import fetch from 'node-fetch'
import { GraphQLWsLink } from '@apollo/client/link/subscriptions'
import { createClient } from 'graphql-ws'
import { saveGraphQLOperation } from '../services/analytics'

const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        searches: {
          merge(existing, incoming) {
            return incoming
          }
        }
      }
    }
  }
})

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_SERVER_ENDPOINT,
  fetch
})

const authLink = setContext((_, { headers }) => {
  const token = localStorage.getItem('token')
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : ''
    }
  }
})

const wsClient = createClient({
  url: process.env.REACT_APP_WS_ENDPOINT,
  reconnect: true,
  connectionParams: () => ({
    authToken: localStorage.getItem('token')
  })
})

const wsLink = new GraphQLWsLink(wsClient)

const errorLink = onError(({ networkError, graphQLErrors }) => {
  if (graphQLErrors) {
    graphQLErrors.map(({ message, locations, path }) => {
      if (message === 'Token expired') {
        localStorage.removeItem('token')
        window.location.href = '/login?token-expired'
      }
      console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`)
    })
  }
  if (networkError) console.error(`[Network error]: ${networkError}`)
})

const analyticsLink = new ApolloLink((operation, forward) => {
  return forward(operation).map(data => {
    if (data) {
      saveGraphQLOperation(operation, data)
    }

    return data
  })
})

const splitLink = split(
  ({ query }) => {
    const { kind, operation } = getMainDefinition(query)
    return kind === 'OperationDefinition' && operation === 'subscription'
  },
  wsLink,
  from([errorLink, analyticsLink, authLink, httpLink])
)

const client = new ApolloClient({
  connectToDevTools: true,
  link: splitLink,
  cache
})

export const clearCache = () => {
  client.clearStore()
}

export default client
