import styled from 'styled-components'
import { colors } from '../../variables'

const ReactivateSubscriptionContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 750px;
  padding: 60px;
  border-radius: 4px;
  background: ${colors.white};
  h3 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }
  .modal-copy {
    width: 100%;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    span {
      color: ${colors.red};
      font-size: 16px;
      font-weight: 400;
    }
  }
  button {
    height: 50px;
    padding: 0 20px;
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    background: ${colors.lightseaGreen};
    color: ${colors.white};
  }
`

export default ReactivateSubscriptionContainer
