import React from 'react'
import ButtonContainer from '../../style/components/ui/Button.styled'
import PropTypes from 'prop-types'

const Button = ({ children, ...props }) => {
  // todo: add menu button theme
  // todo: add icon button
  const theme = props.theme || 'primary'

  let variant = props.variant || 'solid'
  const width = () => {
    if (props.size === 'min') return 'fit-content'
    if (props.size === 'small') return '120px'
    if (props.size === 'medium') return '210px'
    if (props.size === 'full') return '100%'
    return props.width || '210px'
  }

  return (
    <ButtonContainer {...props} variant={variant} theme={theme} width={width()}>
      {children}
    </ButtonContainer>
  )
}

Button.propTypes = {
  theme: PropTypes.oneOf(['primary', 'secondary', 'danger', 'dark']),
  variant: PropTypes.oneOf(['solid', 'outline', 'link']),
  size: PropTypes.oneOf(['min', 'small', 'medium', 'full']),
  width: PropTypes.string,
  weight: PropTypes.oneOf([100, 200, 300, 400, 500, 600, 700, 800, 900]),
  height: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  shadow: PropTypes.bool
}
export default Button
