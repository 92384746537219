import React from 'react'
import { PlanCardContainer } from '../../style/components/auth/PlanCard/PlanCard.styled'
import Button from '../ui/Button'

const PlanCard = ({ product, monthlyPlans, productSelected, setProductSelected }) => {
  return (
    <PlanCardContainer>
      <section className='left-card-section'>
        <div className='lcs-header'>
          <h4>{product.name}</h4>
          {product.users === 1 ? <p>{product.users} Usuario</p> : <p>{product.users} Usuarios</p>}
        </div>
        <div className='lcs-content'>
          <div className='lcs-content-price'>
            <div className='lcs-content-price-line'>
              <p
                className={`lcs-content-price-qty lcs-content-price-qty-${
                  product.price === '8,900'
                    ? 'm-e'
                    : product.price === '14,240'
                    ? 'm-c'
                    : product.price === '23,400'
                    ? 'a-in'
                    : product.price === '53,400'
                    ? 'a-e'
                    : product.price === '85,440'
                    ? 'a-c'
                    : ''
                }`}
              >
                {product.price}
              </p>
              {product.name !== 'Gratuito' && <p className='lcs-content-price-cents'>.00</p>}
            </div>
            <div>
              <p className='lcs-content-price-coin'>$MXN</p>
            </div>
          </div>
          <p className='lcs-content-description'>{product.description}</p>
        </div>
        {!monthlyPlans && product.name !== 'Gratuito' && <p className='discount-tag'>50% DE DESCUENTO</p>}
      </section>
      <section className='right-card-section'>
        <div className='rcs-benefits'>
          {product.users === 1 ? <p>{product.users} Usuario</p> : <p>{product.users} Usuarios</p>}
        </div>
        <div className='rcs-benefits'>
          <p>{product.dashboards} Tableros</p>
        </div>
        <div className='rcs-benefits'>
          <p>{product.searches} Búsquedas</p>
        </div>
        {productSelected && productSelected.key === product.key ? (
          <Button weight={700} theme={'secondary'} size={'full'}>
            Plan {product.name} seleccionado
          </Button>
        ) : (
          <Button theme={'dark'} weight={500} size={'full'} onClick={() => setProductSelected(product)}>
            Cambiar a plan {product.name}
          </Button>
        )}
      </section>
    </PlanCardContainer>
  )
}

export default PlanCard
