import React, { useState } from 'react'
import { CreateBoardContainer, CreateBoardBox } from '../../style/components/search/CreateBoardModal.styled'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CloseIcon from '../../assets/CloseIcon'

import { CREATE_DASHBOARD, MODIFY_ONBOARDING } from '../../services/mutations'
import { GET_USER } from '../../services/queries'
import Button from '../ui/Button'

const CreateBoardModal = ({ name: busqueda, query }) => {
  let history = useHistory()

  const [showDescription, setShowDescription] = useState(false)
  const [name, setNombre] = useState(busqueda)
  const [description, setDescripcion] = useState('')
  // eslint-disable-next-line
    const [title, settitle] = useState(busqueda)

  const [newDashboard] = useMutation(CREATE_DASHBOARD)
  const [onboarding] = useMutation(MODIFY_ONBOARDING, {
    update(cache, { data: { modifyOnboarding } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: modifyOnboarding
        }
      })
    }
  })

  const getOnboarding = async () => {
    await onboarding({
      variables: { flag: false }
    })
    history.push('/searches')
  }

  const crearTableroPorTutorial = async () => {
    try {
      const { data } = await newDashboard({
        variables: {
          input: {
            name,
            description
          }
        }
      })
      history.push(`/dashboards/dashboard/${data.newDashboard.id}`)
    } catch (error) {
      console.log(error)
    }
  }

  const callGetOnboardingAndCrearTablero = () => {
    crearTableroPorTutorial()
    getOnboarding()
  }

  return (
    <CreateBoardContainer>
      <CreateBoardBox>
        <div className='onboarding__close' onClick={getOnboarding}>
          <h3>Crea un tablero</h3>
          <CloseIcon width='25.33px' height='24px' fill='#748596' />
        </div>
        <p>
          ¡Tu búsqueda <b>{`${title}`}</b> se guardó con éxito! Es necesario que la vincules a uno o varios{' '}
          <b>gráficos</b> dentro de tus <b>tableros</b> para comenzar con tu análisis ¡Crea un tablero para esta
          búsqueda!
        </p>
        <div className='form'>
          <label>Nombre del tablero</label>
          <div className='input-button'>
            <input placeholder='Nombra tu tablero' value={name} onChange={e => setNombre(e.target.value)} />
          </div>
          <p className='description-button' onClick={() => setShowDescription(!showDescription)}>
            Agregar descripción del tablero
          </p>
          <textarea onChange={e => setDescripcion(e.target.value)} />
          <div className='save-buttons-container'>
            <Button
              variant={'link'}
              weight={500}
              theme={'dark'}
              className='cancel-button'
              onClick={async () => {
                await onboarding({
                  variables: { flag: false }
                })
                history.push('/searches')
              }}
            >
              Omitir
            </Button>
            <Button theme={'secondary'} weight={500} onClick={callGetOnboardingAndCrearTablero}>
              Crear tablero
            </Button>
          </div>
        </div>
      </CreateBoardBox>
    </CreateBoardContainer>
  )
}

export default CreateBoardModal
