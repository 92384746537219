import React from 'react'

const IconCheck = ({ stroke }) => {
  return (
    <svg width='21px' height='15px' viewBox='0 0 21 15' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon check</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='icons/general/check/primary'
          transform='translate(-1.000000, -5.000000)'
          stroke={stroke ? stroke : '#00AAAB'}
        >
          <path d='M2,14.3 L7.2,19.8 M21.5,5.5 L7.2,19.8' id='icon-check'></path>
        </g>
      </g>
    </svg>
  )
}

export default IconCheck
