export const genders = ['Masculino', 'Femenino', 'No Binario', 'Otro']

export const occupations = [
  'Estudiante',
  'Mercadotecnia',
  'Analista',
  'Politica',
  'Periodismo',
  'Comunicación',
  'Administración de empresas',
  'Community manager',
  'Project manager',
  'Sector privado',
  'Sector público',
  'Social',
  'Investigación',
  'Otro'
]

export const degrees = ['Primaria', 'Secundaria', 'Preparatoria', 'Universidad/Superior', 'Postgrado']
