import styled from 'styled-components'
import { colors } from '../../../variables'

const NotificationContainer = styled.div`
  position: absolute;
  width: 408px;
  height: 158px;
  top: calc(100vh - 220px);
  right: calc(100vw - 408px);
  z-index: 20;
  border-right: 5px solid #6873e5;
  border-radius: 0 4px 4px 0;
  background: ${colors.white};
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  transform: translateX(-408px);
  transition: transform 0.3s ease-in-out;
  &.open {
    /* Aparece, con la animación */
    transform: translateX(0);
  }
  .close__modal {
    margin: 10px 11.53px 0 0;

    svg {
      width: 18.47px;
      height: 17px;
    }
  }

  .notification__content {
    padding-left: 16px;
    padding-right: 55px;

    .notification__text {
      text-align: left;
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${colors.richBlack};
    }
  }
`

export default NotificationContainer
