import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const Card = styled.article`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 400px;
  height: 140px;
  padding: 15px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  margin-bottom: 10px;
  margin-right: 25px;
  cursor: pointer;
  &:hover {
    border: 1px solid ${colors.lightseaGreen};
  }
  .report-up {
    display: flex;
    justify-content: space-between;
    h5 {
      margin-bottom: 10px;
      color: ${colors.richBlack};
      font-size: 16px;
      font-weight: 600;
    }
    .report-schedule {
      display: flex;
      p {
        width: 120px;
        display: flex;
        align-items: center;
        color: ${colors.darkGray};
        font-size: 12px;
        font-weight: 500;
        svg {
          width: 15px;
          height: 15px;
          margin-right: 5px;
        }
        &.pauseMessage {
          color: ${colors.lightseaGreen};
        }
      }
    }
    svg {
      width: 20px;
      height: 20px;
    }
    .report-menu {
      z-index: 5;
      top: 5px;
      right: 10px;
      button {
        border: none;
        background: none;
        outline: none;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
    }
    .report-options {
      .full_option {
        height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
        padding-left: 20px;
        &:hover {
          background: ${colors.lightGray};
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .delete_button {
        color: ${colors.red};
      }
      .option_button {
        color: ${colors.silverSand};
        &:hover {
          color: ${colors.gunMetal};
        }
      }
    }
  }
  .report-down {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .report-board {
      color: ${colors.darkGray};
      font-size: 12px;
      font-weight: 500;
    }
    .report-info {
      display: flex;
      align-items: center;
      svg {
        width: 15px;
        height: 15px;
        margin: 0 5px 0 10px;
        fill: ${colors.darkGray};
      }
      p {
        color: ${colors.richBlack};
        font-size: 12px;
        font-weight: 500;
      }
    }
  }
`

export const ReportOptions = styled.div`
  position: absolute;
  z-index: 6;
  width: 190px;
  height: ${props => props.size}px;
  background: ${colors.white};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`
