import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const AllReportsContainer = styled.div`
  padding-left: ${props => (props.filtersOpen ? '400px' : '0px')};
  padding-top: 30px;
  .error-message {
    color: ${colors.red};
    margin: 15px;
    font-weight: bold;
  }
  .reports-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px 0px 7.5px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      padding-left: 2%;
      &.number {
        width: 7%;
      }
      &.name {
        width: 20%;
      }
      &.date {
        width: 13%;
      }
      &.hour {
        width: 10%;
      }
      &.type {
        width: 13%;
      }
      &.creator {
        width: 17%;
      }
      &.options {
        width: 20%;
      }
    }
  }
  .reports-table-body {
    height: calc(100vh - 190px);
    overflow-y: scroll;
    padding: 7.5px 20px 7.5px 7.5px;
    .reports-table-row {
      display: flex;
      height: 50px;
      border-radius: 4px;
      margin-bottom: 10px;
      background: ${colors.white};
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding-left: 2%;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        &.number {
          width: 7%;
        }
        &.name {
          width: 20%;
        }
        &.date {
          width: 13%;
        }
        &.hour {
          width: 10%;
        }
        &.type {
          width: 13%;
        }
      }
      .creator {
        width: 17%;
        display: flex;
        align-items: center;
        .creator-photo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: ${colors.lightGray};
          border-radius: 50%;
        }
      }
      .options {
        width: 20%;
        padding: 0 2%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        button {
          border: none;
          outline: none;
          background: none;
          &.share {
            svg {
              width: 20px;
              height: 20px;
              stroke: ${colors.darkGray};
            }
          }
          &.delete {
            svg {
              width: 20px;
              height: 20px;
              fill: ${colors.darkGray};
            }
          }
        }
      }
    }
  }
`
