import styled from 'styled-components'
import { colors } from '../../../variables'

export const DashboardCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 2.5%30px 0;
  position: relative;
  transition: ease-in-out 0.3s;
  bottom: 0;
  min-width: 400px;
  width: 30%;
  height: 140px;
  &:hover {
    bottom: 10px;
  }
  p {
    font-size: 12px;
    font-weight: 500;
    color: ${colors.darkGray};
  }
  .dashboard-menu {
    position: absolute;
    top: 5px;
    right: 10px;
    button {
      border: none;
      background: none;
      outline: none;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
      }
    }
  }
  .dashboard-options {
    z-index: 1;
    position: absolute;
    top: 25px;
    right: -5px;
    width: 175px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .full_option {
      height: 50px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: ${colors.darkGray};
      padding-left: 15px;
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .delete_button {
      color: ${colors.red};
    }
  }
  .cardCont {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 17px;
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    background: ${colors.white};
    height: 100%;
    cursor: pointer;
    .card-header {
      display: flex;
      justify-content: space-between;
      h2 {
        font-size: 16px;
        font-weight: 600;
        margin-bottom: 10px;
      }
    }
    .card-content {
      display: flex;
      justify-content: space-between;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 7px;
      }
      div {
        display: flex;
        align-items: center;
        svg {
          width: 15px;
          height: 15px;
          margin: 0 7px;
          fill: ${colors.darkGray};
        }
      }
    }
  }
  @media (max-width: 1646px) {
    min-width: 400px;
    width: 47%;
  }
  @media (max-width: 1166px) {
    min-width: 300px;
    width: 98%;
  }
`
