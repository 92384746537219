import React, { useState } from 'react'
import {
  TraditionalSearchContainer,
  FiltersContainer
} from '../../../style/components/search/new-serches/TraditionalSearch.styled'
import moment from 'moment'
import { Shadow, Spinner } from '../../../style/components'
import { AiOutlineCalendar } from 'react-icons/ai'
import SearchBar from './SearchBar'
import Note from './Note'
import TraditionalFilters from './TraditionalFilters'
import SaveSearch from './SaveSearch'
import { useMutation } from '@apollo/client'
import { useHistory } from 'react-router-dom'
import ReportTestigo from '../search/ReportTestigo'

import { NEW_TESTIGO_REPORT_MUTATION, MODIFY_ONBOARDING } from '../../../services/mutations'
import { GET_USER } from '../../../services/queries'
import Button from '../../ui/Button'

const TraditionalSearch = props => {
  const {
    andParams,
    orParams,
    notParams,
    andWord,
    orWord,
    notWord,
    setStartDate,
    setEndDate,
    handleAndInput,
    handleOrInput,
    handleNotInput,
    addAndWord,
    addOrWord,
    addNotWord,
    deleteAndParam,
    deleteOrParam,
    deleteNotParam,
    coverage,
    states,
    type,
    genders,
    startDate,
    endDate,
    participation,
    handleCoverage,
    handleStates,
    handleType,
    handleGenders,
    handleParticipation,
    makeTraditionalSearch,
    resetFilters,
    data,
    loading,
    qtyData,
    qtyLoading,
    error,
    query,
    media,
    fetchMoreData,
    loadingNews,
    user
  } = props

  let history = useHistory()

  const [showFilters, setShowFilters] = useState(false)
  const [showSaveSearch, setShowSaveSearch] = useState(false)
  const [testigo, setTestigo] = useState(null)
  const [motive, setMotive] = useState('')
  const [thanks, setThanks] = useState(false)

  const [startDateInput, setStartDateInput] = useState(startDate)
  const [endDateInput, setEndDateInput] = useState(endDate)
  const [verifiedDates, setVerifiedDate] = useState(true)

  const [newTestigoReport] = useMutation(NEW_TESTIGO_REPORT_MUTATION)
  const [onboarding] = useMutation(MODIFY_ONBOARDING, {
    update(cache, { data: { modifyOnboarding } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: modifyOnboarding
        }
      })
    }
  })

  const filtersAreCollapsed = () => (showFilters ? 'filters-not-collapsed' : 'filters-collapsed')

  const handleTestigoReport = async () => {
    await newTestigoReport({
      variables: {
        input: {
          id: testigo.id,
          motivo: motive,
          testigo: testigo.testigo,
          ambito: testigo.ambito,
          autor: testigo.autor,
          clasificacion: testigo.clasificacion,
          estado: testigo.estado,
          fecha: testigo.fecha,
          foda: testigo.foda,
          medio: testigo.medio,
          nombre_medio: testigo.nombre_medio,
          participacion: testigo.participacion,
          texto: testigo.texto,
          tipo_medio: testigo.tipo_medio,
          titulo: testigo.titulo
        }
      }
    })
    setThanks(true)
  }

  const verifyDates = (name, value) => {
    if (name === 'startDate') {
      setStartDateInput(value)
      if (moment(endDateInput).isBetween(value, moment(Date.now()), null, '[]')) {
        // moment(value).isBefore(endDateInput) &&
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    } else if (name === 'endDate') {
      setEndDateInput(value)
      if (moment(value).isBetween(startDateInput, moment(Date.now()), null, '[]')) {
        // moment(startDateInput).isBefore(value) &&
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    }
  }

  const submitDateSearch = () => {
    // moment(startDateInput).isBefore(endDateInput) &&
    if (moment(endDateInput).isBetween(startDateInput, moment(Date.now()), null, '[]')) {
      setStartDate(startDateInput)
      setEndDate(endDateInput)
    }
  }

  const handleCloseModal = () => {
    setTestigo(null)
    setMotive('')
    setThanks(false)
  }

  return (
    <TraditionalSearchContainer>
      <div className='advanced-search-bar'>
        <SearchBar
          width='calc(100% - 300px)'
          queryParams={orParams}
          inputWord={orWord}
          handleInput={handleOrInput}
          addParam={addOrWord}
          deleteParam={deleteOrParam}
          placeholder='Buscar palabras clave'
        />
        <Button
          size={'medium'}
          disabled={!orParams.length}
          className='advanced-search-button'
          theme='secondary'
          onClick={() => setShowSaveSearch(true)}
        >
          Guardar búsqueda
        </Button>
        <Button
          theme={'secondary'}
          size={'small'}
          className='filter-search-button'
          onClick={() => setShowFilters(true)}
          variant={'outline'}
        >
          Filtros
        </Button>
      </div>

      {(loading || qtyLoading || loadingNews) && <Spinner />}

      {error && (
        <div className='no-results'>
          <p>Hubo un problema</p>
          <p>Modifica tu búsqueda</p>
        </div>
      )}

      {data && data.news && qtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-articles'>
              {qtyData.newsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>
            <div className='results-period'>
              <AiOutlineCalendar />
              <p>Periodo de búsqueda:</p>
              <input
                type='date'
                name='startDate'
                className='date-input'
                onKeyDown={e => e.preventDefault()}
                max={endDateInput}
                min={moment().subtract(1, 'months').format('YYYY-MM-DD')}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
                value={startDateInput}
              />
              a
              <input
                type='date'
                name='endDate'
                className='date-input'
                min={startDateInput}
                max={moment(Date.now()).add(1, 'days').format('YYYY-MM-DD')}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
                value={endDateInput}
              />
              <Button
                width={'auto'}
                height={'auto'}
                theme={'secondary'}
                className={'date-button'}
                variant='outline'
                disabled={!verifiedDates}
                onClick={verifiedDates && submitDateSearch}
              >
                Buscar
              </Button>
            </div>
          </div>

          {data.news.length === 0 ? (
            <div className='no-results'>
              <p>Tu búsqueda no ha generado resultados</p>
              <p>Modifica tu búsqueda</p>
            </div>
          ) : (
            <div className='search-results' onScroll={fetchMoreData}>
              {data.news.map((article, idx) => (
                <Note key={idx} note={article} reportTestigo={setTestigo} />
              ))}
            </div>
          )}
        </>
      )}

      {showFilters && <Shadow onClick={() => setShowFilters(false)} />}
      <FiltersContainer id='filters' className={filtersAreCollapsed()}>
        <TraditionalFilters
          closeFilters={() => setShowFilters(false)}
          andParams={andParams}
          notParams={notParams}
          andWord={andWord}
          notWord={notWord}
          handleAndInput={handleAndInput}
          handleNotInput={handleNotInput}
          addAndWord={addAndWord}
          addNotWord={addNotWord}
          deleteAndParam={deleteAndParam}
          deleteNotParam={deleteNotParam}
          coverage={coverage}
          states={states}
          media={media}
          type={type}
          genders={genders}
          participation={participation}
          handleCoverage={handleCoverage}
          handleStates={handleStates}
          handleType={handleType}
          handleGenders={handleGenders}
          handleParticipation={handleParticipation}
          resetFilters={resetFilters}
          makeTraditionalSearch={makeTraditionalSearch}
        />
      </FiltersContainer>

      {showSaveSearch && (
        <>
          <Shadow
            onClick={async () => {
              await onboarding({
                variables: { flag: false }
              })
              setShowSaveSearch(false)
              history.push('/searches')
            }}
          />
          <SaveSearch
            closeSaveSearch={() => setShowSaveSearch(false)}
            states={states}
            types={type}
            media={media}
            query={query}
            userInfo={user}
          />
        </>
      )}

      {testigo && (
        <>
          <Shadow onClick={() => handleCloseModal()} />
          <ReportTestigo
            closeModal={() => handleCloseModal()}
            motive={motive}
            handleMotive={setMotive}
            reportTestigo={handleTestigoReport}
            thanks={thanks}
          />
        </>
      )}
    </TraditionalSearchContainer>
  )
}

export default TraditionalSearch
