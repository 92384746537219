import React from 'react'
import { RegistrContainer } from '../../style/components/faqs/Registro.styled'

// Imagenes
import Paso1 from '../../assets/faqs/registrarse/paso1.png'
import Paso2 from '../../assets/faqs/registrarse/paso2.png'
import Paso3 from '../../assets/faqs/registrarse/paso3.png'
import Paso4 from '../../assets/faqs/registrarse/paso4.png'
import Paso5 from '../../assets/faqs/registrarse/paso5.png'

const Registro = ({ registroRef }) => {
  return (
    <RegistrContainer id='registro' ref={registroRef}>
      <h4>¿Cómo registrarse?</h4>

      <p>
        <strong>Paso 1: </strong>Crea tu cuenta introduciendo tus datos.
      </p>
      <img className='paso1' src={Paso1} alt='Paso 1' />

      <p>
        <strong>Paso 2: </strong>Selecciona el plan que se acople a tus necesidades.
      </p>
      <img src={Paso2} alt='Paso 2' />

      <p>
        <strong>Paso 3: </strong>Necesitas factura? Introduce tus datos y nosotros te la enviamos por correo.
      </p>
      <img src={Paso3} alt='Paso 3' />

      <p>
        <strong>Paso 4: </strong>Introduce los datos de tu tarjeta para completar tu registro.
      </p>
      <img src={Paso4} alt='Paso 4' />

      <p>
        <strong>Paso 5: </strong>¡Listo! Puedes ver el recibo de tu compra en tu correo.
      </p>
      <img src={Paso5} alt='Paso 5' />
    </RegistrContainer>
  )
}

export default Registro
