import React from 'react'
import { PDFInfo } from '../../../style/components/reports/reportDetails/ModlalPDF.styled'
import { Spinner } from '../../../style/components'
import Button from '../../ui/Button'

const ModalPDF = ({ setCreatingPDF, loader, errorPDF, returnSizeParams }) => {
  if (loader)
    return (
      <PDFInfo>
        <Spinner />
      </PDFInfo>
    )

  return (
    <PDFInfo>
      {errorPDF ? (
        <>
          <h3>Ocurrio un error</h3>
          <p>No hemos podido generar tu reporte, por favor intenta mas tarde</p>
        </>
      ) : (
        <>
          <h3>Generado Correctamente</h3>
          <p>
            Tu reporte se ha generado de manera correcta, lo podras encontrar en la barra de descargas de tu navegador
          </p>
        </>
      )}
      <Button
        size={'full'}
        weight={500}
        variant={'outline'}
        theme={'secondary'}
        onClick={() => {
          setCreatingPDF(false)
          returnSizeParams()
        }}
        /*loader={loader} TODO: What is this?*/
      >
        ¡Entendido!
      </Button>
    </PDFInfo>
  )
}

export default ModalPDF
