import React, { useEffect, useState } from 'react'
import { AllMediaContainer } from '../../style/components/media/Allmedia.styled'

const AllMedia = ({ media, filtersOpen, filters, order }) => {
  const [filtersOfStates, setfiltersOfStates] = useState([])
  const [sorted, setSorted] = useState(media)

  useEffect(() => {
    if (order.AtoZ) {
      const sortedArr = media.slice().sort((a, b) => {
        const nameA = a.descripcion.toUpperCase()
        const nameB = b.descripcion.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = 1
        } else if (nameA < nameB) {
          comparison = -1
        }
        return comparison
      })
      setSorted(sortedArr)
    } else if (order.ZtoA) {
      const sortedArr = media.slice().sort((a, b) => {
        const nameA = a.descripcion.toUpperCase()
        const nameB = b.descripcion.toUpperCase()
        let comparison = 0
        if (nameA > nameB) {
          comparison = -1
        } else if (nameA < nameB) {
          comparison = 1
        }
        return comparison
      })
      setSorted(sortedArr)
    }
    // eslint-disable-next-line
  }, [media, order.AtoZ, order.ZtoA])

  useEffect(() => {
    setfiltersOfStates(filters.states)
  }, [filters])

  return (
    <AllMediaContainer filtersOpen={filtersOpen}>
      <div className='searches-table-header'>
        <h3 className='name'>Nombre</h3>
        <h3 className='scope'>Cobertura</h3>
        <h3 className='place'>Ubicación</h3>
        <h3 className='type'>Tipo</h3>
        <h3 className='subtype'>Subtipo</h3>
        <h3 className='status'>Estatus</h3>
      </div>
      <div className='searches-table-body'>
        {sorted
          .filter(m => m.descripcion.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(m =>
            filters.cobertura === ''
              ? m
              : filters.cobertura === 'Estatal' && filtersOfStates.map(el => el.name).includes(m.estado)
              ? m
              : m.cobertura.toLowerCase() === filters.cobertura.toLowerCase()
          )
          .filter(m => (filters.subtype.length === 0 ? true : filters.subtype.includes(m.subtipo)))
          .filter(m => (filters.status.type === '' ? true : m.activo === filters.status.active))
          .map(medium => (
            <div className='searches-table-row' key={medium.clave}>
              <div className='searchCont'>
                <p className='name'>
                  {medium.descripcion.indexOf('/') === -1
                    ? medium.descripcion
                    : medium.descripcion.substring(0, medium.descripcion.indexOf('/'))}
                </p>
                <p className='scope'>{medium.cobertura}</p>
                <p className='place'>{medium.estado === 'Paises' ? 'Países' : medium.estado}</p>
                <p className='type'>{medium.medio === 8 ? 'Digital' : 'Tradicional'}</p>
                <p className='subtype'>{medium.subtipo}</p>
                <p className='status'>
                  <span className={medium.activo ? 'activo' : 'inactivo'} />
                </p>
              </div>
            </div>
          ))}
      </div>
    </AllMediaContainer>
  )
}

export default AllMedia
