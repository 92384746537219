import React, { useContext, useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { Spinner, Shadow } from '../../style/components'
import { UIContext } from '../../contexts/UIContext'
import { BsTrash } from 'react-icons/bs'
import DeleteWorkspace from './DeleteWorkspace'
import { useQuery, useMutation } from '@apollo/client'
import SearchCard from './SearchCard'
import DashboardCard from './DashboardCard'
import DeleteSearch from '../search/searches/DeleteSearch'
import DeleteDashboard from '../dashboard/dashboards/DeleteDashboard'
import { GeneralContainer } from '../../style/components/workspace/GeneralContainer.styled'

import { GET_WORKSPACE } from '../../services/queries'
import { UPDATE_WORKSPACE, DELETE_SEARCH_MUTATION, DELETE_DASHBOARD_MUTATION } from '../../services/mutations'

import { MyContext } from '../../context'
import Button from '../ui/Button'

const General = () => {
  const uiCTX = useContext(UIContext)
  const { collapsed } = uiCTX.state
  const context = useContext(MyContext)

  const { data, loading, startPolling, stopPolling, error } = useQuery(GET_WORKSPACE, { fetchPolicy: 'network-only' })

  const validationSchema = Yup.object({
    name: Yup.string()
  })

  const [updateWorkspace] = useMutation(UPDATE_WORKSPACE)
  const [showDeleteWorkspace, setShowDeleteWorkspace] = useState(false)

  const [searchToDelete, setSearchToDelete] = useState(null)
  const [deletedSearch] = useMutation(DELETE_SEARCH_MUTATION)
  const handleDeleteSearch = async id => {
    await deletedSearch({ variables: { id } })
    setSearchToDelete(null)
  }

  const [dashboardToDelete, setDashboardToDelete] = useState(null)
  const [deletedDashboard] = useMutation(DELETE_DASHBOARD_MUTATION)
  const handleDeleteDashboard = async id => {
    await deletedDashboard({ variables: { id } })
    setDashboardToDelete(null)
  }

  useEffect(() => {
    startPolling(2000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  if (error) context.handleErrors(error)
  if (loading) return <Spinner />
  if (data === []) return <Spinner />

  const searches = error ? [] : [...data.getWorkspace.searches]
  const dashboards = error ? [] : [...data.getWorkspace.dashboards]

  const isSubmitButtonDisabled = props => {
    if (
      data !== undefined &&
      (props.values.name !== data.getWorkspace.name || props.values.description !== data.getWorkspace.description)
    )
      return false
    return true
  }

  return (
    <GeneralContainer collapsed={collapsed}>
      <section className='section__generalinfo'>
        <div>
          <h2 className='info-title'>Información general</h2>
          <Formik
            validationSchema={validationSchema}
            enableReinitialize
            initialValues={{
              name: data !== undefined && data.getWorkspace.name,
              description: data !== undefined && data.getWorkspace.description
            }}
            onSubmit={async values => {
              const { name, description } = values
              await updateWorkspace({
                variables: {
                  input: {
                    name,
                    description
                  }
                }
              })
            }}
          >
            {props => (
              <form className='edit-workspace-form' onSubmit={props.handleSubmit}>
                <label className='workspace__form__title'>Nombre del workspace</label>
                <input
                  className='workspace__form__input'
                  id='name'
                  value={props.values.name}
                  onChange={props.handleChange}
                />
                <label className='workspace__form__title'>Descripción</label>
                <textarea
                  className={`workspace__form__textarea ${
                    props.values.description !== '' ? 'workspace__form__description' : ''
                  }`}
                  id='description'
                  value={props.values.description}
                  onChange={props.handleChange}
                />
                <div className='generalinfo__savebutton_container'>
                  <input
                    className={`submit-button ${
                      isSubmitButtonDisabled(props) ? 'submit__button__inactive' : 'submit__button__active'
                    }`}
                    type='submit'
                    value='Guardar'
                    disabled={isSubmitButtonDisabled(props)}
                  />
                </div>
              </form>
            )}
          </Formik>
        </div>
        <div className='delete-account'>
          <p className='delete__account__text'>
            Una vez que elimines tu cuenta perderás toda tu información, tableros y búsquedas. Esta acción no se puede
            deshacer, tómalo en cuenta.
          </p>
          <Button variant={'link'} theme={'danger'} onClick={() => setShowDeleteWorkspace(true)}>
            <BsTrash />
            Eliminar cuenta
          </Button>
        </div>
      </section>

      <section className='section__searches'>
        <div className='header-w-info'>
          <h2 className='general__section__header'>Tus búsquedas</h2>
          <p className='general__section__quantity'>
            {' '}
            {data !== undefined && data.getWorkspace.searches.length} de{' '}
            {data !== undefined && data.getWorkspace.plan.searchesQty} búsquedas
          </p>
        </div>
        <Link className='general-link' to='/searches/new-search'>
          Crear nueva búsqueda
        </Link>
        <div className='general-searches-container'>
          {searches.length === 0 ? (
            <p className='searches__container___nosearches'>Aún no tienes búsquedas</p>
          ) : (
            searches
              .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt))
              .map(search => <SearchCard key={search.id} search={search} setSearchToDelete={setSearchToDelete} />)
          )}
        </div>
      </section>
      <section className='section__rightborder'>
        <div className='header-w-info'>
          <h2 className='general__section__header'>Tableros</h2>
          <p className='general__section__quantity'>
            {' '}
            {data !== undefined && data.getWorkspace.dashboards.length} de{' '}
            {data !== undefined && data.getWorkspace.plan.dashboardsQty} tableros
          </p>
        </div>
        <Link className='general-link' to='/dashboards/new-dashboard'>
          Crea nuevo tablero
        </Link>
        <div className='general-dashboards-container'>
          {dashboards.length === 0 ? (
            <p className='dashboard__container__nosearches'>Aún no tienes tableros</p>
          ) : (
            dashboards
              .sort((a, b) => Number(b.updatedAt) - Number(a.updatedAt))
              .map(dashboard => (
                <DashboardCard key={dashboard.id} dashboard={dashboard} setDashboardToDelete={setDashboardToDelete} />
              ))
          )}
        </div>
      </section>

      {showDeleteWorkspace && (
        <>
          <Shadow onClick={() => setShowDeleteWorkspace(false)} />
          <DeleteWorkspace onClick={() => setShowDeleteWorkspace(false)} />
        </>
      )}

      {searchToDelete && (
        <>
          <Shadow onClick={() => setSearchToDelete(null)} />
          <DeleteSearch
            search={searchToDelete}
            setSearchToDelete={setSearchToDelete}
            deleteSearch={handleDeleteSearch}
          />
        </>
      )}

      {dashboardToDelete && (
        <>
          <Shadow />
          <DeleteDashboard
            dashboard={dashboardToDelete}
            setDashboardToDelete={setDashboardToDelete}
            deleteDashboard={handleDeleteDashboard}
          />
        </>
      )}
    </GeneralContainer>
  )
}

export default General
