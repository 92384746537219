import styled from 'styled-components'
import { colors } from '../../variables'

const NewMediaContainer = styled.div`
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        .back-icon {
          width: 20px;
          height: 20px;
        }
      }
      h1 {
        width: 315px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .content {
    display: flex;
    section {
      height: calc(100vh - 70px);
      flex-direction: column;
      display: flex;
    }
    .requested {
      width: 40%;
      padding: 40px 40px 40px 0;
      border-right: 1px solid ${colors.lightGray};
      &-copy {
        margin-bottom: 30px;
      }
      .media-table-body {
        overflow-y: scroll;
        padding: 7.5px 20px 7.5px 7.5px;
        .media-table-row {
          display: flex;
          flex-direction: column;
          transition: ease-in-out 0.2s;
          transform: translate(0, 0px);
          &:hover {
            transform: translate(0, -3px);
          }
          .searchCont {
            display: flex;
            height: 50px;
            width: 100%;
            border-radius: 4px;
            margin-bottom: 20px;
            background: ${colors.white};
            box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
            transition: ease-in-out 0.3s;
            &:hover {
              background: ${colors.lightGray};
            }
            justify-content: space-between;
            padding: 0 20px;
            p {
              display: flex;
              justify-content: center;
              align-items: center;
              color: ${colors.darkGray};
              font-size: 14px;
              font-weight: 500;
            }
          }
        }
      }
    }
    .request {
      width: 60%;
      padding: 40px 0 40px 40px;
      &-copy {
        margin-bottom: 23px;
      }
      &-boxes {
        margin-bottom: 20px;
        label {
          width: 100px;
          margin-right: 20px;
          input {
            margin-right: 5px;
          }
        }
      }
      .margin {
        margin-bottom: 40px;
      }
      &-form {
        display: flex;
        flex-direction: column;
        .name__error {
          height: 20px;
          width: 100%;
          margin-top: 5px;

          .name__error__text {
            color: ${colors.red};
            font-size: 10px;
            font-weight: 500;
            margin: 0;
          }

          .name__error__transparent {
            color: transparent;
            font-size: 10px;
            margin: 0;
          }
        }
        label {
          margin-bottom: 10px;
          font-size: 14px;
        }
        input,
        textarea {
          margin-bottom: 5px;
          border-radius: 4px;
          outline: none;
          border: 1px solid ${colors.darkGray};
          padding: 10px;
        }
        input {
          height: 47px;
        }
        textarea {
          height: 100px;
        }
      }
    }
    p {
      font-family: Montserrat;
      font-weight: 400;
      font-size: 16px;
      color: ${colors.richBlack};
      line-height: 24px;
    }
    .info {
      margin-bottom: 40px;
    }
    h2 {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 24px;
      color: ${colors.richBlack};
      margin-bottom: 20px;
    }
    h3 {
      font-family: Montserrat;
      font-weight: 600;
      font-size: 20px;
      color: ${colors.richBlack};
      margin-bottom: 15px;
    }
  }
`

export default NewMediaContainer
