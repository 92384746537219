import React, { useState } from 'react'
import { UserContainer, ProfileMenu } from '../../style/components/user/user.styled'
import Logout from './Logout'

const User = ({ photo }) => {
  const [menu, setMenu] = useState(false)
  const handleMenu = () => setMenu(!menu)

  return (
    <UserContainer>
      <img src={photo} alt='User avatar' onClick={() => handleMenu()} />
      {menu && (
        <ProfileMenu>
          <Logout />
        </ProfileMenu>
      )}
    </UserContainer>
  )
}

export default User
