export const monthlyProducts = [
  {
    key: 1,
    price: '0.00',
    name: 'Gratuito',
    interval: 'Mes',
    billed: 'Mensual',
    users: 1,
    dashboards: 1,
    searches: 3,
    description: `El plan para empezar a conocer MyOblek y realizar tus búsquedas de menciones en los medios 
    de comunicación. Construye tu búsqueda y consulta las novedades cada día.`
  },
  {
    key: 2,
    price: '3,900',
    name: 'Individual',
    interval: 'Mes',
    billed: 'Mensual',
    users: 1,
    dashboards: 5,
    searches: 5,
    description: `Ideal para emprendedores y figuras públicas que están en el proceso de construcción 
      y posicionamiento de su marca.`
  },
  {
    key: 3,
    price: '8,900',
    name: 'Equipo',
    interval: 'Mes',
    billed: 'Mensual',
    users: 5,
    dashboards: 15,
    searches: 25,
    description: 'La herramienta perfecta para equipos de trabajo pequeños con un alto nivel de productividad.'
  },
  {
    key: 4,
    price: '14,240',
    name: 'Corporativo',
    interval: 'Mes',
    billed: 'Mensual',
    users: 10,
    dashboards: 30,
    searches: 50,
    description: `El plan especializado para grandes equipos de trabajo con actividades avanzadas que requieren 
      de un mayor control y precisión de los datos.`
  }
]

export const yearlyProducts = [
  {
    key: 5,
    price: '0.00',
    name: 'Gratuito',
    interval: 'Año',
    billed: 'Anual',
    users: 1,
    dashboards: 1,
    searches: 3,
    description: `El plan para empezar a conocer MyOblek y realizar tus búsquedas de menciones en los medios de 
    comunicación. Construye tu búsqueda y consulta las novedades cada día.`
  },
  {
    key: 6,
    price: '23,400',
    name: 'Individual',
    interval: 'Año',
    billed: 'Anual',
    users: 1,
    dashboards: 5,
    searches: 5,
    description: `Ideal para emprendedores y figuras públicas que están en el proceso de construcción y 
      posicionamiento de su marca.`
  },
  {
    key: 7,
    price: '53,400',
    name: 'Equipo',
    interval: 'Año',
    billed: 'Anual',
    users: 5,
    dashboards: 15,
    searches: 25,
    description: 'La herramienta perfecta para equipos de trabajo pequeños con un alto nivel de productividad.'
  },
  {
    key: 8,
    price: '85,440',
    name: 'Corporativo',
    interval: 'Año',
    billed: 'Anual',
    users: 10,
    dashboards: 30,
    searches: 50,
    description: `El plan especializado para grandes equipos de trabajo con actividades avanzadas 
      que requieren de un mayor control y precisión de los datos.`
  }
]
