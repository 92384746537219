import styled from 'styled-components'
import { colors } from '../../variables'

export const DropdownContainer = styled.div`
  position: relative;
  width: 100%;
  .dropdown--area {
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
  }
  .dropdown--content {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    overflow-y: scroll;
    /* Hides scrollbar in IE, Edge and Firefox */
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    .content__placeholder {
      font-size: 14px;
      font-weight: 500;
      color: #748596;
    }
  }
  /* Hides scrollbar in Chrome, Safari and Opera */
  .dropdown--content::-webkit-scrollbar {
    display: none;
  }
  .dropdown--button {
    width: 50px;
    display: flex;
    align-items: center;
    justify-content: center;
    pointer-events: none;
    svg {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
    }
  }
  .dropdown--list {
    position: absolute;
    top: 50px;
    width: 100%;
    max-height: 152px;
    border: 1px solid ${colors.darkGray};
    border-radius: 0 0 4px 4px;
    border-top: 2px solid ${colors.lightseaGreen};
    overflow-y: scroll;
    background: ${colors.white};
    li {
      height: 50px;
      display: flex;
      button {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border: none;
        background: none;
        outline: none;
        :hover {
          background: ${colors.lightGray};
        }
        p {
          font-size: 14px;
          font-weight: 500;
          color: ${colors.richBlack};
          padding-left: 10px;
        }
        span {
          height: 50px;
          width: 50px;
          display: flex;
          justify-content: center;
          align-items: center;
          svg {
            width: 20px;
            height: 20px;
            stroke: ${colors.lightseaGreen};
          }
        }
      }
    }
  }
`

export const Tag = styled.div`
  height: 30px;
  padding: 0 6px 0 10px;
  border-radius: 4px;
  background: ${colors.lightGray};
  display: flex;
  align-items: center;
  margin-right: 8px;
  p {
    white-space: nowrap;
    color: ${colors.richBlack};
    font-size: 15px;
    font-weight: 500;
  }
  span {
    padding: 0px 3px;
    margin-left: 6px;
    vertical-align: 2px;
    cursor: pointer;
  }
`
