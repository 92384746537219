import React, { useState } from 'react'
import {
  TraditionalFiltersContainer,
  SelectButton
} from '../../../style/components/search/new-serches/TraditionalFilters.styled'

import SearchBar from './SearchBar'

import { RiArrowDownSLine, RiArrowUpSLine } from 'react-icons/ri'
import { GrClose } from 'react-icons/gr'

import { International, National, State, Newspaper, Magazine, Radio, Tv, Web } from '../../../assets/Icons'
import Dropdown from '../../ui/Dropdown'

import { estados } from '../../../data/Filters'
import Button from '../../ui/Button'

const TraditionalFilters = props => {
  const {
    closeFilters,
    andParams,
    notParams,
    andWord,
    notWord,
    handleAndInput,
    handleNotInput,
    addAndWord,
    addNotWord,
    deleteAndParam,
    deleteNotParam,
    coverage: covFil,
    states,
    // media,
    type,
    //genders,
    //participation: parFil,
    handleCoverage,
    handleStates,
    handleType,
    //handleGenders,
    //handleParticipation,
    resetFilters,
    makeTraditionalSearch,
    resetMessage
  } = props

  const resetText = resetMessage || 'Limpiar filtros'

  const [coverage, setCoverage] = useState(true)
  const [catalogue, setCatalogue] = useState(true)
  //const [ moreCatFil, setMoreCatFil ] = useState(false)
  //const [ participation, setParticipation ] = useState(false)

  //const [ stateSelection, setStateSelection ] = useState([])
  //const [ nameSelection, setNameSelection ] = useState([])
  //const [ authorSelection, setAuthorSelection ] = useState([])

  const coverageIsOpen = () => (coverage ? 'coverage-content' : 'coverage-closed')
  const catalogueIsOpen = () => (catalogue ? 'catalogue-content' : 'catalogue-closed')
  //const participationIsOpen = () => participation ? 'participation-content': 'participation-closed'

  return (
    <TraditionalFiltersContainer>
      <div className='filters-header'>
        <h2>FILTROS</h2>
        <button onClick={closeFilters}>
          <GrClose />
        </button>
      </div>

      <div className='filters-container'>
        <div className='refine-search'>
          <h3>REFINAR BÚSQUEDA</h3>
          <div className='or-search'>
            <p>También busca {/*<button>i</button>*/}</p>
            <SearchBar
              width='100%'
              queryParams={andParams}
              inputWord={andWord}
              handleInput={handleAndInput}
              addParam={addAndWord}
              deleteParam={deleteAndParam}
              placeholder='Palabras de contexto'
            />
          </div>
          <div className='not-search'>
            <p>Excluir de la búsqueda {/*<button>i</button>*/}</p>
            <SearchBar
              width='100%'
              queryParams={notParams}
              inputWord={notWord}
              handleInput={handleNotInput}
              addParam={addNotWord}
              deleteParam={deleteNotParam}
              placeholder='Palabras excluyentes'
            />
          </div>
        </div>

        <div className='coverage-filters'>
          <div className='filters-subheader' onClick={() => setCoverage(!coverage)}>
            <div className='filters-title'>
              <h3>COBERTURA</h3>
              <p>Lugar donde se encuentra el medio</p>
            </div>
            <button>{coverage ? <RiArrowDownSLine /> : <RiArrowUpSLine />}</button>
          </div>
          <div className={coverageIsOpen()}>
            <div className='scope-buttons'>
              <SelectButton
                className={covFil === 'internacional' && 'cat-type-button-active'}
                onClick={() => handleCoverage('internacional')}
              >
                <div className='button-icon international'>
                  <International />
                </div>
                <p className='button-text'>Internacional</p>
              </SelectButton>
              <SelectButton
                className={covFil === 'nacional' && 'cat-type-button-active'}
                onClick={() => handleCoverage('nacional')}
              >
                <div className='button-icon'>
                  <National />
                </div>
                <p className='button-text'>Nacional</p>
              </SelectButton>
              <SelectButton
                className={covFil === 'estatal' && 'cat-type-button-active'}
                onClick={() => handleCoverage('estatal')}
              >
                <div className='button-icon'>
                  <State />
                </div>
                <p className='button-text'>Estatal</p>
              </SelectButton>
            </div>
            {covFil === 'estatal' && (
              <Dropdown
                options={estados}
                selection={states}
                setSelection={handleStates}
                multiSelect={true}
                placeholder='Entidad'
              />
            )}
          </div>
        </div>

        <div className='catalogue-filters'>
          <div className='filters-subheader' onClick={() => setCatalogue(!catalogue)}>
            <div className='filters-title'>
              <h3>CATÁLOGO</h3>
              <p>Tipo de medio</p>
            </div>
            <button>{catalogue ? <RiArrowDownSLine /> : <RiArrowUpSLine />}</button>
          </div>
          <div className={catalogueIsOpen()}>
            <div className='catalogue-type-section'>
              <SelectButton className={type.includes(1) && 'cat-type-button-active'} onClick={() => handleType(1)}>
                <div className='button-icon'>
                  <Newspaper />
                </div>
                <p className='button-text'>Prensa</p>
              </SelectButton>

              <SelectButton className={type.includes(2) && 'cat-type-button-active'} onClick={() => handleType(2)}>
                <div className='button-icon'>
                  <Magazine />
                </div>
                <p className='button-text'>Revistas</p>
              </SelectButton>

              <SelectButton className={type.includes(3) && 'cat-type-button-active'} onClick={() => handleType(3)}>
                <div className='button-icon'>
                  <Radio />
                </div>
                <p className='button-text'>Radio</p>
              </SelectButton>

              <SelectButton className={type.includes(4) && 'cat-type-button-active'} onClick={() => handleType(4)}>
                <div className='button-icon'>
                  <Tv />
                </div>
                <p className='button-text'>TV</p>
              </SelectButton>

              <SelectButton className={type.includes(8) && 'cat-type-button-active'} onClick={() => handleType(8)}>
                <div className='button-icon'>
                  <Web />
                </div>
                <p className='button-text'>Web</p>
              </SelectButton>

              {/* <label htmlFor='all-category-types' className='all-category-types'>
                <input
                  type='checkbox'
                  id='all-category-types'
                  value='todos'
                  checked={type.includes('prensa') &&
                          type.includes('revistas') &&
                          type.includes('radio') &&
                          type.includes('tv') &&
                          type.includes('web')
                          ? true : false}
                  onChange={() => handleType('todos')}/>
                  Todos
              </label> */}
            </div>
            {/* <div className='more-filters-by-catalogue'>
              <button
                className='more-cat-fil-button'
                onClick={() => setMoreCatFil(!moreCatFil)}>
                {moreCatFil ? '- menos filtros por catálogo' : '+ más filtros por catálogo'}
              </button>
              {moreCatFil &&
              <div className='more-cat-fil-container'>
                <p>Diario, canal, frecuencia, portal <button>i</button></p>
                <div style={{
                  position: 'relative',
                  zIndex: '5'
                }}>
                  <Dropdown
                    width='600px'
                    title='Medio'
                    options={[]}
                    selection={nameSelection}
                    setSelection={setNameSelection}
                    multiSelect={true}/>
                </div>
                <p style={{marginTop: '77px'}}>Tipo de género <button>i</button></p>
                <div style={{
                  position: 'relative',
                  zIndex: '4'
                }}>
                  <Dropdown
                    width='600px'
                    title='Clasificación'
                    options={generos}
                    selection={genders}
                    setSelection={handleGenders}
                    multiSelect={true}/>
                </div>
                <p style={{marginTop: '77px'}}>Nombre del autor <button>i</button></p>
                <div style={{
                  position: 'relative',
                  zIndex: '3'
                }}>
                  <Dropdown
                    width='600px'
                    title='Autor'
                    options={[]}
                    selection={authorSelection}
                    setSelection={setAuthorSelection}
                    multiSelect={true}/>
                </div>
                <p style={{marginTop: '77px'}}>Nombre de la sección <button>i</button></p>
                <div style={{
                  position: 'relative',
                  zIndex: '2'
                }}>
                  <Dropdown
                    width='600px'
                    title='Secciones'
                    options={[]}
                    selection={stateSelection}
                    setSelection={setStateSelection}
                    multiSelect={true}/>
                </div>
                <p style={{marginTop: '77px'}}>Nombre del programa <button>i</button></p>
                <div style={{
                  position: 'relative',
                  zIndex: '1',

                }}>
                  <Dropdown
                    width='600px'
                    title='Programa'
                    options={[]}
                    selection={stateSelection}
                    setSelection={setStateSelection}
                    multiSelect={true}/>
                </div>
              </div>}
            </div> */}
          </div>
        </div>

        {/* <div className='participation-filters'>
          <div className='filters-subheader' onClick={() => setParticipation(!participation)}>
            <div className='filters-title'>
              <h3>PARTICIPACIÓN</h3>
              <p>Intervención de actores en la información</p>
            </div>
            <button>
              {participation ? <RiArrowDownSLine/> : <RiArrowUpSLine/>}
            </button>
          </div>
          <div className={participationIsOpen()}>
            <label htmlFor='declarant'>
              <input
                type='checkbox'
                id='declarant'
                value='declarante'
                checked={parFil.includes('declarante') ? true : false}
                onChange={() => handleParticipation('declarante')}/>
              Declarante
            </label>
            <label htmlFor='referrer'>
              <input
                type='checkbox'
                id='referrer'
                value='referente'
                checked={parFil.includes('referente') ? true : false}
                onChange={() => handleParticipation('referente')}/>
              Referente
            </label>
            <label htmlFor='circumstantial'>
              <input
                type='checkbox'
                id='circumstantial'
                value='circunstancial'
                checked={parFil.includes('circunstancial') ? true : false}
                onChange={() => handleParticipation('circunstancial')}/>
              Circunstancial
            </label>
          </div>
        </div> */}
      </div>

      <div className='filters-buttons'>
        <Button
          size={'medium'}
          height={'50px'}
          theme={'secondary'}
          variant='outline'
          className='clean-filters'
          onClick={() => resetFilters()}
        >
          {resetText}
        </Button>
        <Button
          size={'medium'}
          height={'50px'}
          theme={'secondary'}
          className='apply-filters'
          onClick={() => {
            makeTraditionalSearch()
            closeFilters()
          }}
        >
          Aplicar filtros
        </Button>
      </div>
    </TraditionalFiltersContainer>
  )
}

export default TraditionalFilters
