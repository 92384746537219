import styled from 'styled-components'
import { colors } from '../../../variables'

export const OnboardingContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 1200px;
  padding: 50px;
  border-radius: 4px;
  background: ${colors.white};
  .onboarding__close {
    align-self: flex-end;
  }
  .onboarding__header {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    padding-bottom: 50px;
    h3 {
      font-size: 42px;
      font-weight: 800;
      width: 618;
      height: 52;
      margin-bottom: 28px;
    }
    p {
      font-size: 18px;
      font-weight: 600;
      width: 783px;
      height: 52px;
      text-align: center;
    }
  }
  .onboarding__description {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    &__card {
      display: flex;
      flex-direction: column;
      align-content: center;
      align-items: center;
      svg {
        width: 125px;
        height: 125px;
      }
      h6 {
        font-size: 22px;
        font-weight: 600;
        width: 251;
        height: 35;
        color: #0b0c10;
        span {
          font-size: 73px;
          font-weight: 800;
          width: 30;
          height: 90;
          color: #6873e5;
          margin-right: 20px;
        }
      }
      p {
        font-size: 16px;
        font-weight: 400px;
        width: 355px;
        height: 192px;
        color: #0b0c10;
      }
    }
  }
  .onboarding__confirm {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`
