import React from 'react'
import { AddSearchContainer } from '../../../style/components/dashboard/widget/AddSerch.styled'
import SearchesDropdown from '../../ui/SearchesDropdown'
import Button from '../../ui/Button'

const AddSearch = ({
  widget,
  widgetType,
  searchesData,
  searchesLoading,
  searchesSelection,
  setSearchesSelection,
  closeModal,
  addSearches
}) => {
  const widgetSearchesArr = widget.searches.map(ws => ws.id)
  const validSearches = searchesData.searches.filter(search => !widgetSearchesArr.includes(search.id))

  return (
    <AddSearchContainer>
      <h3>Agregar búsquedas</h3>
      <p className='modal-copy'>
        Puedes agregar una búsqueda que hayas creado previamente para hacer cruces de los datos que más te importan.
      </p>
      <div className='select-widget--dropdown'>
        {!searchesLoading && (
          <SearchesDropdown
            searches={validSearches}
            widget={widgetType}
            selection={searchesSelection}
            setSelection={setSearchesSelection}
            multiSelect={true}
            placeholder='Selecciona tus búsquedas'
          />
        )}
      </div>
      <div className='modal-buttons'>
        <Button weight={500} variant={'link'} theme={'dark'} onClick={closeModal}>
          Cancelar
        </Button>
        <Button disabled={searchesSelection.length < 1} onClick={() => addSearches()}>
          Agregar búsqueda
        </Button>
      </div>
    </AddSearchContainer>
  )
}

export default AddSearch
