import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const DetailWidget = styled.div`
  /* height: 210mm;
width: 297mm; */
  background: ${colors.white};
  hr {
    width: 90%;
    margin: auto;
    border: 1px solid ${colors.lightGray};
  }
`

export const TittleBar = styled.div`
  height: 20%;
  display: grid;
  grid-template-columns: 0.5fr 2.5fr 1fr;
`

export const IconWidget = styled.div`
  width: 100px;
  height: 100px;
  margin: 40px 0px 40px 60px;
`

export const WidgetInfo = styled.div`
  margin-top: 40px;
  margin-left: 20px;
  h2 {
    margin-bottom: 15px;
    font-size: 30px;
  }
  p {
    font-size: 19px;
  }
`

export const WidgetButton = styled.div`
  margin: 40px 15px 40px 15px;
`

export const WidgetMedia = styled.div`
  display: flex;
  margin: 40px 0px 40px 60px;
  p {
    font-size: 20px;
    margin-left: 5px;
  }
`

export const WidgetContainer = styled.div`
  width: 80%;
  margin-left: 10%;
`

export const FooterWidget = styled.div`
  margin: 40px 50% 5px 60px;
  height: 15%;
  width: 90%;
  display: flex;
  div {
    &.userNotes {
      width: 75%;
      height: 125px;
      h2 {
        margin-bottom: 15px;
        font-size: 24px;
      }
      p {
        font-size: 18px;
      }
    }
    &.iconBlack {
      margin: 40px;
      margin-left: 90px;
    }
  }
`
