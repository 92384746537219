import React from 'react'
import { SearchFiltersContainer } from '../../../style/components/search/searches/SearchFilters.styled'
import { GrClose } from 'react-icons/gr'

import Button from '../../ui/Button'

const SearchFilters = ({ sidebar, showFilters, resetFilters, filters }) => {
  return (
    <SearchFiltersContainer sidebar={sidebar}>
      <div className='filters--header'>
        <h4>Filtros</h4>
        <GrClose onClick={() => showFilters(false)} />
      </div>

      <div className='filters--content'>
        <label>Nombre de la búsqueda</label>
        <input type='text' value={filters.name} onChange={e => filters.setName(e.target.value)} />

        <label>Autor de la búsqueda</label>
        <input type='text' value={filters.creator} onChange={e => filters.setCreator(e.target.value)} />

        <h5>Fecha de la búsqueda</h5>
        <div className='date'>
          <label>Desde</label>
          <input type='date' value={filters.from} onChange={e => filters.setFrom(e.target.value)} />
        </div>
        <div className='date'>
          <label>Hasta</label>
          <input type='date' value={filters.to} onChange={e => filters.setTo(e.target.value)} />
        </div>

        <h5>Fuente de la búsqueda</h5>
        <div className='source'>
          <div className='checkbox' onClick={() => filters.setSource('medios')}>
            <input type='checkbox' checked={filters.source.includes('medios')} readOnly />
            <label>Medios</label>
          </div>
          <div className='checkbox' onClick={() => filters.setSource('twitter')}>
            <input type='checkbox' checked={filters.source.includes('twitter')} readOnly />
            <label>Twitter</label>
          </div>
        </div>

        <div className='reset'>
          <Button
            size='medium'
            height='47px'
            theme='secondary'
            variant='outline'
            disabled={false}
            onClick={() => resetFilters()}
          >
            Borrar filtros
          </Button>
        </div>
      </div>
    </SearchFiltersContainer>
  )
}

export default SearchFilters
