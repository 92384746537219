import styled from 'styled-components'
import { colors } from '../../variables'

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: ${porps => porps.width};
  outline: none;
  .iconStyle {
    pointer-events: none;
  }
  .dd-header {
    height: 50px;
    background: white;
    outline: none;
    border-color: gray;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    .dd-header-title {
      p {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
      }
    }
    .dd-header-action {
      svg {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
      }
    }
  }
  .dd-list {
    position: absolute;
    transform: translateY(50px);
    border-radius: 0 0 4px 4px;
    border-top: 2px solid ${colors.lightseaGreen};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    width: ${props => props.width};
    height: 142px;
    overflow-y: scroll;
    background: ${colors.white};
    .dd-list-item {
      width: 100%;
      height: 50px;
      button {
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: 100%;
        padding: 0 15px;
        border: none;
        outline: none;
        background: none;
        p {
          text-align: left;
          font-size: 14px;
          font-weight: 500;
          color: ${colors.richBlack};
        }

        .dd-list-item-check {
          height: 45px;
          width: 30px;

          svg {
            margin-top: 7px;
            width: 75%;
            height: 75%;
            stroke: ${colors.lightseaGreen};
          }
        }
      }
    }
  }
`
