import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const InitialSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 60px;
  .welcome-message {
    margin-bottom: 20px;
    color: ${colors.richBlack};
    font-size: 30px;
    font-weight: 700;
    text-align: center;
  }
  .media-instruction {
    margin-bottom: 40px;
    color: ${colors.richBlack};
    font-size: 18px;
    font-weight: 500;
    line-height: 24px;
    text-align: center;
  }
  .comingSoon {
    width: 90px;
    height: 16px;
    transform: translate(0, 40px);
    background-color: #66fcf1;
    position: absolute;
    margin-top: -16px;
    p {
      font-weight: 700;
      font-size: 8px;
      text-align: center;
      margin-top: 3px;
      color: ${colors.richBlack};
    }
  }
  .media-filter-buttons {
    display: flex;
    margin-bottom: 40px;
    .media-button {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 135px;
      border: none;
      outline: none;
      background: transparent;
      transition: ease-in-out 0.2s;
      .media-button-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 60px;
        height: 60px;
        border: none;
        border-radius: 4px;
        margin-bottom: 10px;
        background: ${colors.lightGray};
        transition: ease-in-out 0.2s;
        cursor: pointer;
        svg {
          width: 35px;
          height: 35px;
          path {
            fill: ${colors.darkGray};
          }
        }
        .twitter-button-icon {
          width: 35px;
          height: 35px;
          stroke-width: 1;
          stroke: ${colors.darkGray};
          path {
            fill: none;
          }
        }
      }
      .media-button-text {
        color: ${colors.darkGray};
        font-size: 18px;
        font-weight: 500;
      }
    }
    .or-media-divider {
      padding-top: 20px;
      color: ${colors.darkGray};
      font-size: 18px;
      font-weight: 500;
    }
    .media-button-active > .media-button-icon {
      background: ${colors.purple};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      svg > path {
        fill: ${colors.white};
      }
    }
    .media-button-active-twitter > .media-button-icon {
      background: ${colors.purple};
      box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.5);
      .twitter-button-icon {
        stroke: ${colors.white};
      }
    }
  }
  .search-bar {
    display: flex;
    direction: column;
    width: 80%;
    .animation-medios {
      display: flex;
      width: 100%;
      transform: translate(0, 100%);
      opacity: 0;
      animation: animateUp 0.2s forwards;
      @keyframes animateUp {
        to {
          transform: translate(0, 0);
          opacity: 1;
        }
      }
      Button {
        :enabled {
          cursor: pointer;
        }
        :disabled {
          cursor: not-allowed;
        }
      }
    }
    .search-button {
      margin-left: 20px;
    }
  }
  .label {
    width: 80%;
    text-align: left;

    margin: 9px 0;

    font-family: Montserrat;
    font-size: 14px;
    font-weight: 400;
    color: #748596;

    transform: translate(0, 100%);
    opacity: 0;
    animation: animateUp 0.2s forwards;
    @keyframes animateUp {
      to {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
`

export const TwitterSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 70%;
  height: 100%;
  .animation-twitter {
    display: flex;
    flex-direction: column;
    transform: translate(0, 100%);
    opacity: 0;
    animation: animateUp 0.4s forwards;
    @keyframes animateUp {
      to {
        transform: translate(0, 0);
        opacity: 1;
      }
    }
  }
  .twitter-or-search {
    width: 100%;
    margin-bottom: 40px;
    .t-s-info {
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid ${colors.darkGray};
        outline: none;
        margin-left: 5px;
        color: ${colors.darkGray};
      }
    }
  }
  .twitter-and-not-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-start;
    .and-words,
    .not-words {
      width: 40%;
      p {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkGray};
        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid ${colors.darkGray};
          outline: none;
          margin-left: 5px;
          color: ${colors.darkGray};
        }
      }
    }
    .search-button {
      margin-top: auto;
      height: 50px;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`
