import React from 'react'
import { BoardSelectionContainer } from '../../style/components/reports/BoardSecction.syled'
import BoardDropdown from '../ui/BoardDropdown'

const BoardSelection = ({ dashboards, board, setBoard, setAllWidgets, setWidgets }) => {
  return (
    <BoardSelectionContainer>
      <h2>Selecciona uno de tus tableros</h2>
      <BoardDropdown
        width='100%'
        title='Selecciona un tablero'
        items={dashboards}
        selection={board}
        setSelection={setBoard}
        setWidgets={setWidgets}
        setAllWidgets={setAllWidgets}
        multiSelect={false}
      />
    </BoardSelectionContainer>
  )
}

export default BoardSelection
