import React, { useEffect, useState } from 'react'
import SearchBar from '../new-search/SearchBar'
import { Shadow } from '../../../style/components'
import EditTraditionalSearchContainer from '../../../style/components/search/search/EditTraditionalSearch.styled'
import { FiltersContainer } from '../../../style/components/search/new-serches/TraditionalSearch.styled'
import TraditionalFilters from '../new-search/TraditionalFilters'
import { estados } from '../../../data/Filters'
import Button from '../../ui/Button'

const getParamsFromQuery = query => {
  let andParams = []
  let notParams = []
  const orParams = query.split(';')[0].split(':')[0].split(',')
  if (query.includes(':')) {
    const firstIndex = query.indexOf(':') + 1
    const lastIndex = query.indexOf(';') >= 0 ? query.indexOf(';') : undefined

    andParams = query.slice(firstIndex, lastIndex).split(':')
  }

  if (query.includes(';')) {
    const firstIndex = query.indexOf(';') + 1

    notParams = query.slice(firstIndex).split(';')
  }

  return {
    or: orParams,
    and: andParams,
    not: notParams
  }
}

const getQueryFromParameters = ({ or, and, not }) => {
  const orQuery = or.join(',')
  const andQuery = and.length > 0 ? ':' + and.join(':') : ''
  const notQuery = not.length > 0 ? ';' + not.join(';') : ''
  return orQuery + andQuery + notQuery
}

const getCoverageFromSearch = search => {
  if (search.filters.states.length === 1 && search.filters.states[0].id === 1) {
    return 'internacional'
  } else if (search.filters.states.length === estados.length) {
    return 'nacional'
  }

  return 'estatal'
}

const EditTraditionalSearch = ({ setSearch, search, saveSearch, loading }) => {
  const query = search.query
  const [parameters, setParameters] = useState(getParamsFromQuery(query))

  const [words, setWords] = useState({
    or: '',
    and: '',
    not: ''
  })

  const handleInput = (e, filter) => {
    setWords({ ...words, [filter]: e.target.value })
  }

  const handleAddParam = (e, filter) => {
    if (e.keyCode === 13 && words[filter].trim() !== '') {
      setParameters(prev => ({ ...prev, [filter]: [...prev[filter], words[filter].trim()] }))
      setWords({ ...words, [filter]: '' })
    }
  }

  const handleDeleteParam = (paramIndex, filter) =>
    setParameters(prev => ({
      ...prev,
      [filter]: [...prev[filter].slice(0, paramIndex), ...prev[filter].slice(paramIndex + 1)]
    }))

  const [showFilters, setShowFilters] = useState(false)

  const [coverage, setCoverage] = useState(getCoverageFromSearch(search))
  const [states, setStates] = useState(search.filters.states)
  const [type, setType] = useState(search.filters.media)

  const makeTraditionalSearch = () => {
    setSearch(search => ({
      ...search,
      query: getQueryFromParameters(parameters),
      filters: {
        media: type,
        states
      }
    }))
  }

  useEffect(() => {
    makeTraditionalSearch()
  }, [parameters.or])

  const handleCatalogueTypesButtons = media => {
    let catalogueTypesArr = [...type]
    if (catalogueTypesArr.includes(media)) {
      catalogueTypesArr.splice(catalogueTypesArr.indexOf(media), 1)
    } else {
      catalogueTypesArr.push(media)
    }
    setType(catalogueTypesArr)
  }

  const handleCoverage = scope => {
    if (scope === coverage) {
      setCoverage('')
      setStates([])
      return
    }

    setCoverage(scope)

    if (scope === 'internacional') {
      setStates([{ id: 1, name: 'Países' }])
    } else if (scope === 'nacional') {
      setStates(estados)
    } else if (scope === 'estatal') {
      setStates([])
    }
  }

  return (
    <EditTraditionalSearchContainer>
      <div className='advanced-search-bar'>
        <SearchBar
          width='calc(100% - 300px)'
          queryParams={parameters.or}
          inputWord={words.or}
          handleInput={e => handleInput(e, 'or')}
          addParam={e => handleAddParam(e, 'or')}
          deleteParam={paramIndex => handleDeleteParam(paramIndex, 'or')}
          placeholder='Buscar palabras clave'
        />
        <Button
          disabled={loading || !parameters.or.length}
          theme={'secondary'}
          className='advanced-search-button'
          onClick={saveSearch}
        >
          Guardar cambios
        </Button>
        <Button
          variant='outline'
          theme={'secondary'}
          size={'small'}
          className='filter-search-button'
          onClick={() => setShowFilters(true)}
        >
          Filtros
        </Button>
      </div>
      {showFilters && <Shadow onClick={() => setShowFilters(false)} />}
      <FiltersContainer id='filters' className={showFilters ? 'filters-not-collapsed' : 'filters-collapsed'}>
        {showFilters && (
          <TraditionalFilters
            closeFilters={() => setShowFilters(false)}
            andParams={parameters.and}
            andWord={words.and}
            notParams={parameters.not}
            notWord={words.not}
            handleAndInput={e => handleInput(e, 'and')}
            handleNotInput={e => handleInput(e, 'not')}
            addAndWord={e => handleAddParam(e, 'and')}
            addNotWord={e => handleAddParam(e, 'not')}
            deleteAndParam={paramIndex => handleDeleteParam(paramIndex, 'and')}
            deleteNotParam={paramIndex => handleDeleteParam(paramIndex, 'not')}
            coverage={coverage}
            handleCoverage={handleCoverage}
            states={states}
            handleStates={setStates}
            handleType={handleCatalogueTypesButtons}
            type={type}
            resetFilters={() => {
              setCoverage(getCoverageFromSearch(search))
              setParameters({ ...getParamsFromQuery(search.query), or: parameters.or })
              setType(search.filters.media)
              setStates(search.filters.states)
              setShowFilters(false)
            }}
            resetMessage={'Cancelar cambios'}
            makeTraditionalSearch={makeTraditionalSearch}
          />
        )}
      </FiltersContainer>
    </EditTraditionalSearchContainer>
  )
}

export default EditTraditionalSearch
