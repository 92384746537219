import styled from 'styled-components'

export const ShareReportContainer = styled.div`
  margin-bottom: 50px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .select-p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 15px;
  }
`
