import styled from 'styled-components'
import { colors } from '../../variables'

export const SidebarContainer = styled.div`
  position: fixed;
  z-index: 1;
  .sidebar {
    height: 100vh;
    background: #1f2833;
    transition: all 0.25s linear;
    .sidebar-header {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      width: 100%;
      height: 70px;
      margin-bottom: 30px;
      .sidebar-logo {
        display: flex;
        margin-top: -5px;
        transition: all 0.25s linear;
        img {
          width: 100%;
        }
      }
      .sidebar-burger {
        width: 22px;
        height: 18px;
        border: none;
        outline: none;
        background: transparent;
        cursor: pointer;
        & > .burger-line {
          width: 100%;
          height: 1px;
          margin-bottom: 7.5px;
          background: #ffffff;
        }
        & > .burger-line:last-child {
          margin-bottom: 0;
        }
      }
    }
    .sidebar-nav {
      display: flex;
      flex-direction: column;
      width: 100%;
      .sidebar-navlink {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: 100%;
        height: 50px;
        margin-bottom: 30px;
        transition: all 0.25s linear;
        .sidebar-icon {
          width: 24px;
          height: 24px;
          transition: all 0.25s linear;
          svg {
            width: 100%;
            height: 100%;
            g {
              fill: #878787;
              transition: all 0.5s linear;
            }
            .settings,
            #report {
              fill: none;
              stroke: #878787;
            }
          }
          #catalogue {
            g {
              fill: transparent;
              stroke: #878787;
            }
          }
        }
        .sidebar-item-name {
          width: 0;
          color: #878787;
          font-size: 12px;
          font-weight: 600;
          transition: all 0.5s linear;
        }
        &:hover {
          border-left: 1px solid #00aaab;
          .sidebar-icon {
            svg {
              g {
                fill: #ffffff;
              }
              .settings,
              #report {
                fill: none;
                stroke: #ffffff;
              }
            }
            #catalogue {
              g {
                fill: transparent;
                stroke: #ffffff;
              }
            }
          }
          .sidebar-item-name {
            color: #ffffff;
          }
        }
      }
    }
  }
  #sidebar-not-collapsed {
    width: 222px;
    .sidebar-header {
      padding: 30px 30px 0;
      .sidebar-logo {
        width: 100px;
        transform: translateX(0px);
      }
    }
    .sidebar-nav > .sidebar-navlink {
      padding-left: 40px;
      .sidebar-icon {
        margin-right: 20px;
      }
      .sidebar-item-name {
        opacity: 1;
        transition: all 0.6s linear;
      }
      &.active {
        border-left: 1px solid #00aaab;
        .sidebar-icon {
          svg > g {
            fill: ${colors.white};
          }
          .settings,
          #report {
            fill: none;
            stroke: ${colors.white};
          }
          #catalogue {
            g {
              fill: transparent;
              stroke: #ffffff;
            }
          }
        }
        .sidebar-item-name {
          color: ${colors.white};
        }
      }
    }
  }
  #sidebar-collapsed {
    width: 68px;
    .sidebar-header {
      padding: 30px 23px 0;
      .sidebar-logo {
        width: 0;
        transform: translateX(-120px);
      }
    }
    .sidebar-nav > .sidebar-navlink {
      padding-left: 23px;
      .sidebar-icon {
        margin-right: 0;
      }
      .sidebar-item-name {
        opacity: 0;
        transition: all 0.2s linear;
      }
      &.active {
        padding-left: 12px;
        .sidebar-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 45px;
          height: 45px;
          border-radius: 4px;
          background: ${colors.lightseaGreen};
          svg {
            width: 24px;
            height: 24px;
            g {
              fill: ${colors.white};
            }
            .settings,
            #report {
              fill: none;
              stroke: ${colors.white};
            }
          }
          #catalogue {
            g {
              fill: transparent;
              stroke: #ffffff;
            }
          }
        }
      }
    }
  }
`
