import React, { useEffect } from 'react'
import * as serviceWorker from '../serviceWorker'

const CacheBuster = ({ children }) => {
  const fetchVersion = async () => {
    const data = await fetch('/meta.json').then(response => response.json())

    if (semverGreaterThan(data.appVersion, global.appVersion)) {
      serviceWorker.unregister()
      console.log('Clearing cache and hard reloading...')
      if (caches) {
        // Service worker cache should be cleared with caches.delete()
        caches.keys().then(function (names) {
          for (let name of names) caches.delete(name)
        })
      }
      // delete browser cache and hard reload
      window.location.reload(true)
    }
  }

  useEffect(() => {
    fetchVersion()
  }, [])

  const semverGreaterThan = (versionA, versionB) => {
    const versionsA = versionA.split(/\./g)

    const versionsB = versionB.split(/\./g)
    while (versionsA.length || versionsB.length) {
      const a = Number(versionsA.shift())

      const b = Number(versionsB.shift())
      // eslint-disable-next-line no-continue
      if (a === b) continue
      // eslint-disable-next-line no-restricted-globals
      return a > b || isNaN(b)
    }
    return false
  }

  return <React.Fragment>{children}</React.Fragment>
}
export default CacheBuster
