import React from 'react'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import DeleteWorkspaceContainer from '../../style/components/workspace/DeleteWorkspace.styled'
import { useMutation } from '@apollo/client'

import CloseModal from './Modals/CloseModal'

import { DELETE_WORKSPACE } from '../../services/mutations'
import Button from '../ui/Button'

const DeleteWorkspace = ({ setDelete, onClick }) => {
  const history = useHistory()
  const [deleteWorkspace] = useMutation(DELETE_WORKSPACE)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string(),
      password: Yup.string()
    }),
    onSubmit: async values => {
      const { email, password } = values
      try {
        await deleteWorkspace({
          variables: {
            input: {
              email,
              password
            }
          }
        })
        localStorage.removeItem('token')
        history.push('/login')
      } catch (error) {
        console.log(error)
      }
    }
  })
  return (
    <DeleteWorkspaceContainer>
      <CloseModal onClick={onClick} />
      <div className='filters--header'>
        <h5 className='deleteaccount__headline'>Eliminar cuenta</h5>
      </div>
      <p className='deleteaccount__description'>
        ¡Atención! Si eliminas tu cuenta perderás toda tu información, tableros, búsquedas y se eliminarán tus
        colaboradores. Recuerda que esta acción no se puede deshacer
      </p>
      <p className='deleteaccount__description'>¿Estás seguro de que quieres eliminar tu cuenta My Oblek?</p>
      <form onSubmit={formik.handleSubmit}>
        <label className='deleteaccount__label'>Escribe tu correo</label>
        <input id='email' value={formik.values.email} onChange={formik.handleChange} />
        <label className='deleteaccount__label'>Escribe tu contraseña</label>
        <input id='password' type='password' value={formik.values.password} onChange={formik.handleChange} />
        <div className='deletewarning__buttons'>
          <Button variant={'link'} theme={'dark'} onClick={onClick}>
            Cancelar
          </Button>
          <Button
            variant={'outline'}
            theme={'danger'}
            type='submit'
            disabled={
              formik.values.email && formik.values.password && !formik.errors.email && !formik.errors.password
                ? false
                : true
            }
          >
            Eliminar cuenta
          </Button>
        </div>
      </form>
    </DeleteWorkspaceContainer>
  )
}

export default DeleteWorkspace
