import React, { useEffect, useRef } from 'react'
import {
  SharedDashboardOrderContainer,
  ButtonDiv
} from '../../../style/components/dashboard/dashboards/SharedDashboardOrder.styled'
import { BiCheck } from 'react-icons/bi'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const SharedDashboardOrder = ({ ref, resetOrder, orderStates }) => {
  const sharedOrderMenu = useRef()

  useOutsideClick(sharedOrderMenu, () => {
    orderStates.setShowSharedOrder(false)
  })

  const handleOrder = type => {
    if (type === 'az') {
      if (orderStates.nameAtoZ) {
        return resetOrder()
      } else {
        orderStates.setNameAZOrder(true)
        orderStates.setNameZAOrder(false)
        orderStates.setRecentDateOrder(false)
        orderStates.setOldDateOrder(false)
      }
    } else if (type === 'za') {
      if (orderStates.nameZtoA) {
        return resetOrder()
      } else {
        orderStates.setNameZAOrder(true)
        orderStates.setNameAZOrder(false)
        orderStates.setRecentDateOrder(false)
        orderStates.setOldDateOrder(false)
      }
    } else if (type === 'recent') {
      if (orderStates.recentDate) {
        return resetOrder()
      } else {
        orderStates.setRecentDateOrder(true)
        orderStates.setOldDateOrder(false)
        orderStates.setNameZAOrder(false)
        orderStates.setNameAZOrder(false)
      }
    } else if (type === 'old') {
      if (orderStates.oldDate) {
        return resetOrder()
      } else {
        orderStates.setOldDateOrder(true)
        orderStates.setRecentDateOrder(false)
        orderStates.setNameZAOrder(false)
        orderStates.setNameAZOrder(false)
      }
    }
  }

  return (
    <SharedDashboardOrderContainer ref={sharedOrderMenu}>
      <div className='order--options'>
        <div className='sub_options'>
          <button className='size-button' onClick={() => handleOrder('az')}>
            <ButtonDiv selected={orderStates.nameAtoZ}>
              {orderStates.nameAtoZ ? (
                <>
                  <p>A - Z </p>
                  <BiCheck />
                </>
              ) : (
                <p>A - Z</p>
              )}
            </ButtonDiv>
          </button>

          <button className='size-button' onClick={() => handleOrder('za')}>
            <ButtonDiv selected={orderStates.nameZtoA}>
              {orderStates.nameZtoA ? (
                <>
                  <p>Z - A </p>
                  <BiCheck />
                </>
              ) : (
                <p>Z - A</p>
              )}
            </ButtonDiv>
          </button>

          <button className='size-button' onClick={() => handleOrder('recent')}>
            <ButtonDiv selected={orderStates.recentDate}>
              {orderStates.recentDate ? (
                <>
                  <p>Más reciente </p>
                  <BiCheck />
                </>
              ) : (
                <p>Más reciente</p>
              )}
            </ButtonDiv>
          </button>

          <button className='size-button' onClick={() => handleOrder('old')}>
            <ButtonDiv selected={orderStates.oldDate}>
              {orderStates.oldDate ? (
                <>
                  <p>Más antiguo</p>
                  <BiCheck />
                </>
              ) : (
                <p>Más antiguo</p>
              )}
            </ButtonDiv>
          </button>
        </div>
      </div>
    </SharedDashboardOrderContainer>
  )
}

export default SharedDashboardOrder
