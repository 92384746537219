import React from 'react'
import {
  InitialSearchContainer,
  TwitterSearchContainer
} from '../../../style/components/search/new-serches/InitialSearch.styled'

import SearchBar from './SearchBar'
import TwitterSearchBar from './TwitterSearchBar'

import { Internet } from '../../../assets/Media' // Newspaper
import { FiTwitter } from 'react-icons/fi'
import Button from '../../ui/Button'
// import { AiOutlineInfoCircle } from "react-icons/ai";

const InitialSearch = props => {
  const {
    media,
    setMedia,
    setStep,
    orParams,
    twOrParams,
    twAndParams,
    twNotParams,
    orWord,
    twOrWord,
    twAndWord,
    twNotWord,
    handleOrInput,
    handleTwitterOrInput,
    handleTwitterAndInput,
    handleTwitterNotInput,
    addOrWord,
    addTwitterOrWord,
    addTwitterAndWord,
    addTwitterNotWord,
    deleteOrParam,
    deleteTwitterOrParam,
    deleteTwitterAndParam,
    deleteTwitterNotParam,
    setFrom,
    setTo,
    setHashtag,
    makeTwitterSearch,
    makeTraditionalSearch
  } = props

  return (
    <InitialSearchContainer>
      <h1 className='welcome-message'>¡Bienvenido! ¿Qué vamos a buscar hoy?</h1>
      <p className='media-instruction'>
        MyOblek te permite buscar por medios (prensa, revistas, radio, tv y web).
        <br />
        (Próximamente Twitter, Facebook e Instagram).
      </p>

      <div className='media-filter-buttons'>
        <button
          className={media.includes('medios') ? 'media-button media-button-active' : 'media-button'}
          onClick={() => setMedia('medios')}
        >
          <div className='media-button-icon'>
            <Internet />
          </div>
          <p className='media-button-text'>Medios</p>
        </button>
        <span className='or-media-divider'>O</span>
        {/*<button*/}
        {/*  className={media.includes('twitter') ? 'media-button media-button-active-twitter' : 'media-button'}*/}
        {/*  onClick={() => {*/}
        {/*    setMedia('twitter')*/}
        {/*  }}*/}
        {/*>*/}
        <button
          className={media.includes('twitter') ? 'media-button media-button-active-twitter' : 'media-button'}
          onClick={() => {
            setMedia('medios')
          }}
          disabled={true}
        >
          <div className='comingSoon'>
            <p>PRÓXIMAMENTE</p>
          </div>
          <div className='media-button-icon'>
            <FiTwitter className='twitter-button-icon' />
          </div>
          <p className='media-button-text'>Twitter</p>
        </button>
      </div>

      {/* {(media.includes('medios') || media.includes('digital')) &&
        <div className="label">
          <p>Ingresa tus palabras clave <AiOutlineInfoCircle /></p>
          
        </div>} */}

      {(media.includes('medios') || media.includes('digital')) && (
        <>
          <div className='search-bar'>
            <div className='animation-medios'>
              <SearchBar
                width='100%'
                queryParams={orParams}
                inputWord={orWord}
                handleInput={handleOrInput}
                addParam={addOrWord}
                deleteParam={deleteOrParam}
                placeholder='Buscar palabras clave'
              />
              <Button
                size={'small'}
                className='search-button'
                disabled={media && orParams.length > 0 ? false : true}
                onClick={() => {
                  makeTraditionalSearch()
                  setStep('medios')
                }}
              >
                Buscar
              </Button>
            </div>
          </div>
          <div className='label'>
            <p>Presiona “Enter” para generar cada palabra</p>
          </div>
        </>
      )}
      {media.includes('twitter') && (
        <TwitterSearchContainer>
          <div className='animation-twitter'>
            <div className='twitter-or-search'>
              <p className='t-s-info'>
                Búsqueda principal: cuentas de Twitter, #hashtags y/o palabras {/* TODO: INFO <button>i</button>*/}
              </p>
              <TwitterSearchBar
                width='100%'
                name='twOrWord'
                queryParams={twOrParams}
                inputWord={twOrWord}
                handleInput={handleTwitterOrInput}
                addParam={addTwitterOrWord}
                deleteParam={deleteTwitterOrParam}
                placeholder='from: @cuenta, to: @cuenta, #hashtags y/o palabras'
                setFrom={setFrom}
                setTo={setTo}
                setHashtag={setHashtag}
              />
            </div>
            <div className='twitter-and-not-container'>
              <div className='and-words'>
                <p>También busca en Twitter {/* TODO: INFO <button>i</button>*/}</p>
                <TwitterSearchBar
                  width='100%'
                  name='twAndWord'
                  border='none'
                  queryParams={twAndParams}
                  inputWord={twAndWord}
                  handleInput={handleTwitterAndInput}
                  addParam={addTwitterAndWord}
                  deleteParam={deleteTwitterAndParam}
                  placeholder='@cuentas, #hashtags y/o palabras'
                  setFrom={setFrom}
                  setTo={setTo}
                  setHashtag={setHashtag}
                />
              </div>
              <div className='not-words'>
                <p>Excluir de la búsqueda {/* TODO: INFO <button>i</button>*/}</p>
                <TwitterSearchBar
                  width='100%'
                  name='twNotWord'
                  queryParams={twNotParams}
                  inputWord={twNotWord}
                  handleInput={handleTwitterNotInput}
                  addParam={addTwitterNotWord}
                  deleteParam={deleteTwitterNotParam}
                  placeholder='@cuentas, #hashtags y/o palabras'
                  setFrom={setFrom}
                  setTo={setTo}
                  setHashtag={setHashtag}
                />
              </div>
              <Button
                size={'small'}
                className='search-button'
                disabled={media && twOrParams.length > 0 ? false : true}
                onClick={() => {
                  makeTwitterSearch()
                  setStep('twitter')
                }}
              >
                Buscar
              </Button>
            </div>
          </div>
        </TwitterSearchContainer>
      )}
    </InitialSearchContainer>
  )
}

export default InitialSearch
