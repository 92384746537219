import React from 'react'
import { GrClose } from 'react-icons/gr'

import { MediaFilter } from '../../style/components/media/MediaFilters.styled'

import { estados } from '../../data/Filters'
import Dropdown from '../ui/Dropdown'
import Button from '../ui/Button'

let fixedStates = [...estados]
fixedStates.shift()
fixedStates.sort((a, b) => {
  let fa = a.name.toLowerCase(),
    fb = b.name.toLowerCase()
  if (fa < fb) {
    return -1
  }
  if (fa > fb) {
    return 1
  }
  return 0
})

const MediaFilters = ({ sidebar, showFilters, filters, resetFilters, digCheck }) => {
  const handleCoberturaNacional = () => {
    filters.setCheckboxCoberturaNacional(!filters.checkboxCoberturaNacional)
    if (filters.checkboxCoberturaNacional) {
      filters.setCobertura('')
    } else {
      filters.setCheckboxCoberturaInternacional(false)
      filters.setCheckboxCoberturaEstatal(false)
      filters.setCobertura('Nacional')
    }
  }

  const handleCoberturaInternacional = () => {
    filters.setCheckboxCoberturaInternacional(!filters.checkboxCoberturaInternacional)
    if (filters.checkboxCoberturaInternacional) {
      filters.setCobertura('')
    } else {
      filters.setCheckboxCoberturaNacional(false)
      filters.setCheckboxCoberturaEstatal(false)
      filters.setCobertura('Internacional')
    }
  }

  const handleCoberturaEstatal = () => {
    filters.setCheckboxCoberturaEstatal(!filters.checkboxCoberturaEstatal)
    if (filters.checkboxCoberturaEstatal) {
      filters.setCobertura('')
    } else {
      filters.setCheckboxCoberturaNacional(false)
      filters.setCheckboxCoberturaInternacional(false)
      filters.setCobertura('Estatal')
    }
  }

  const handleSubtype = checkbox => {
    filters.setSubtypeCheckbox(prev => ({ ...prev, [checkbox]: !prev[checkbox] }))
    const subtype = checkbox.slice(0, 1).toUpperCase() + checkbox.slice(1)
    const subtypeArray = filters.subtype
    const indexOf = subtypeArray.indexOf(subtype)
    if (!filters.subtypeCheckbox[checkbox]) {
      filters.setSubtype([...subtypeArray, subtype])
    } else {
      subtypeArray.splice(indexOf, 1)
      filters.setSubtype(subtypeArray)
    }
  }
  const handleStatusActive = () => {
    filters.setCheckboxStatusActive(!filters.checkboxStatusActive)
    if (filters.checkboxStatusActive) {
      filters.setStatus({ type: '', active: true })
    } else {
      filters.setCheckboxStatusInactive(false)
      filters.setStatus({ type: 'A', active: true })
    }
  }
  const handleStatusInactive = () => {
    filters.setCheckboxStatusInactive(!filters.checkboxStatusInactive)
    if (filters.checkboxStatusInactive) {
      filters.setStatus({ type: '', active: true })
    } else {
      filters.setCheckboxStatusActive(false)
      filters.setStatus({ type: 'I', active: false })
    }
  }
  const resetFields = () => {
    resetFilters()
    filters.setCheckboxCoberturaNacional(false)
    filters.setCheckboxCoberturaInternacional(false)
    filters.setCheckboxCoberturaEstatal(false)
    filters.setCheckboxStatusActive(false)
    filters.setCheckboxStatusInactive(false)
  }

  return (
    <MediaFilter sidebar={sidebar}>
      <div className='filters--header'>
        <h4>Filtros</h4>
        <GrClose onClick={() => showFilters(false)} />
      </div>

      <div className='filters--content'>
        <label>Nombre del medio</label>
        <input type='text' value={filters.name} onChange={e => filters.setName(e.target.value)} />

        <h5>Cobertura</h5>

        <div className='scope'>
          <label className='option'>
            <input
              type='checkbox'
              checked={filters.checkboxCoberturaInternacional}
              onChange={() => handleCoberturaInternacional()}
            />
            Internacional
          </label>
          <label className='option'>
            <input
              type='checkbox'
              checked={filters.checkboxCoberturaNacional}
              onChange={() => handleCoberturaNacional()}
            />
            Nacional
          </label>
          <label className='option'>
            <input
              type='checkbox'
              checked={filters.checkboxCoberturaEstatal}
              onChange={() => handleCoberturaEstatal()}
            />
            Estatal
          </label>
        </div>
        {filters.checkboxCoberturaEstatal && (
          <div className='states-dropdown'>
            <Dropdown
              options={fixedStates}
              selection={filters.states}
              setSelection={filters.setStates}
              multiSelect={true}
              placeholder='Entidad'
            />
          </div>
        )}

        {filters.subtypeCheckbox && (
          <>
            <h5>Subtipo</h5>
            <div className='subtype'>
              <label className='option'>
                <input
                  type='checkbox'
                  checked={filters.subtypeCheckbox.periódicos}
                  onChange={() => handleSubtype('periódicos')}
                />
                Periódicos
              </label>
              <label className='option'>
                <input
                  type='checkbox'
                  checked={filters.subtypeCheckbox.revistas}
                  onChange={() => handleSubtype('revistas')}
                />
                Revistas
              </label>
              <label className='option'>
                <input
                  type='checkbox'
                  checked={filters.subtypeCheckbox.radio}
                  onChange={() => handleSubtype('radio')}
                />
                Radio
              </label>
              <label className='option'>
                <input
                  type='checkbox'
                  checked={filters.subtypeCheckbox.televisión}
                  onChange={() => handleSubtype('televisión')}
                />
                Televisión
              </label>
              {digCheck && (
                <>
                  <label className='option'>
                    <input
                      type='checkbox'
                      checked={filters.subtypeCheckbox.internet}
                      onChange={() => handleSubtype('internet')}
                    />
                    Internet
                  </label>
                </>
              )}
            </div>
          </>
        )}

        <h5>Estatus</h5>

        <div className='status'>
          <label className='option'>
            <input type='checkbox' checked={filters.checkboxStatusActive} onChange={() => handleStatusActive()} />
            Activo
          </label>
          <label className='option'>
            <input type='checkbox' checked={filters.checkboxStatusInactive} onChange={() => handleStatusInactive()} />
            Inactivo
          </label>
        </div>

        <div className='reset'>
          <Button size='medium' height='47px' theme='secondary' variant='outline' onClick={() => resetFields()}>
            Borrar filtros
          </Button>
        </div>
      </div>
    </MediaFilter>
  )
}

export default MediaFilters
