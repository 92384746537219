import React, { useState, useEffect, useRef } from 'react'
import { useMutation } from '@apollo/client'
import { WidgetContainer } from '../../../style/components/dashboard/dashboard/OpenWidget.styled'
import { BsThreeDots, BsArrowLeft, BsArrowRight, BsPencilSquare } from 'react-icons/bs'
import {
  SmallWidget,
  SmallWidgetAccent,
  MediumWidget,
  MediumWidgetAccent,
  LargeWidget,
  LargeWidgetAccent,
  ExtraWidget,
  ExtraWidgetAccent
} from '../../../assets/Icons'

import TonalidadMedios from '../../widgets/TonalidadMedios'
import NumeroNotas from '../../widgets/NumeroNotas'
import TipoMedios from '../../widgets/TipoMedios'
import TemasMomento from '../../widgets/TemasMomento'
import MapaCalor from '../../widgets/MapaCalor'
import ExposicionMedios from '../../widgets/ExposicionMedios'
import ShareVoice from '../../widgets/ShareVoice'
import MediosGanados from '../../widgets/MediosGandos'
import TonalidadTwitter from '../../widgets/TonalidadTwitter'
import NubeHashtagsTwitter from '../../widgets/NubeHashtagsTwitter'
import InfluencersTwitter from '../../widgets/InfluencersTwitter'
import MencionesTiwtter from '../../widgets/MencionesTwitter'
import PublicadoresVolumenTwitter from '../../widgets/PublicadoresVolumenTwitter'

import { DASHBOARD_WIDGETS_QUERY } from '../../../services/queries'
import { RESIZE_WIDGET, MOVE_WIDGET, EDIT_WIDGET } from '../../../services/mutations'
import Button from '../../ui/Button'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Widget = ({ widget, dashboard }) => {
  const [resizeWidget] = useMutation(RESIZE_WIDGET)

  const [moveWidget] = useMutation(MOVE_WIDGET, {
    update(cache, { data: { moveWidget } }) {
      const { dashboardWidgets } = cache.readQuery({
        query: DASHBOARD_WIDGETS_QUERY,
        variables: { dashboardID: dashboard }
      })
      const widgets = [...dashboardWidgets]
      const id = moveWidget.split(' ')[0]
      const direction = moveWidget.split(' ')[1]
      const idx = widgets.map(widget => widget.id).indexOf(id)
      if (direction === 'right' && idx + 1 !== widgets.length) {
        const widget = widgets.splice(idx, 1)[0]
        widgets.splice(idx + 1, 0, widget)
      }
      if (direction === 'left' && idx !== 0) {
        const widget = widgets.splice(idx, 1)[0]
        widgets.splice(idx - 1, 0, widget)
      }
      cache.writeQuery({
        query: DASHBOARD_WIDGETS_QUERY,
        variables: { dashboardID: dashboard },
        data: {
          dashboardWidgets: widgets
        }
      })
    }
  })

  const [renameWidget] = useMutation(EDIT_WIDGET)

  const [showWidgetMenu, setShowWidgetMenu] = useState(false)
  const [name, setName] = useState(widget.name)
  const [editName, setEditName] = useState(false)

  const menuOptions = useRef()

  useOutsideClick(menuOptions, () => {
    setShowWidgetMenu(false)
  })

  const handleResizeWidget = async width => {
    await resizeWidget({
      variables: {
        id: widget.id,
        width
      }
    })
  }

  const handleWidgetPosition = async direction => {
    await moveWidget({
      variables: {
        id: widget.id,
        dashboardID: dashboard,
        direction
      }
    })
  }

  const handleEditName = async () => {
    await renameWidget({
      variables: {
        id: widget.id,
        input: name
      }
    })
    setEditName(false)
  }

  return (
    <WidgetContainer width={widget.styleParams.width}>
      <div className='widget--header'>
        <div className='widget-title'>
          {editName ? (
            <>
              <input
                className='change-name'
                name='name'
                value={name}
                onChange={({ target: { value } }) => setName(value)}
              />
              <Button height={'100%'} theme={'secondary'} className='save-name' onClick={handleEditName}>
                Guardar
              </Button>
            </>
          ) : (
            <>
              <h1>{widget.name}</h1>
              <BsPencilSquare className='edit-icon' />
            </>
          )}
        </div>

        <div className='widget--menu' ref={menuOptions}>
          <button>
            <BsThreeDots />
          </button>
          {showWidgetMenu && (
            <div className='widget--options'>
              {/* <button className='full_option'><RiEditBoxLine/>Editar</button> */}
              <div className='sub_options'>
                <button onClick={() => handleResizeWidget('31.33%')} className='size-button'>
                  {widget.styleParams.width === '31.33%' ? <SmallWidgetAccent /> : <SmallWidget />}
                </button>
                <button onClick={() => handleResizeWidget('48%')} className='size-button'>
                  {widget.styleParams.width === '48%' ? <MediumWidgetAccent /> : <MediumWidget />}
                </button>
                <button onClick={() => handleResizeWidget('64.66%')} className='size-button'>
                  {widget.styleParams.width === '64.66%' ? <LargeWidgetAccent /> : <LargeWidget />}
                </button>
                <button onClick={() => handleResizeWidget('98%')} className='size-button'>
                  {widget.styleParams.width === '98%' ? <ExtraWidgetAccent /> : <ExtraWidget />}
                </button>
              </div>
              <button className='full_option' onClick={() => handleWidgetPosition('left')}>
                <BsArrowLeft /> Izquierda
              </button>
              <button className='full_option' onClick={() => handleWidgetPosition('right')}>
                <BsArrowRight /> Derecha
              </button>
            </div>
          )}
        </div>
      </div>

      {(() => {
        switch (widget.kind) {
          case 'TonalidadMedios':
            return <TonalidadMedios widget={widget} />
          case 'NumeroNotas':
            return <NumeroNotas widget={widget} />
          case 'MediosGanados':
            return <MediosGanados widget={widget} />
          case 'TipoMedios':
            return <TipoMedios widget={widget} />
          case 'TemasMomento':
            return <TemasMomento widget={widget} />
          case 'MapaCalor':
            return <MapaCalor widget={widget} />
          case 'ExposicionMedios':
            return <ExposicionMedios widget={widget} />
          case 'ShareVoice':
            return <ShareVoice widget={widget} />
          case 'TonalidadTwitter':
            return <TonalidadTwitter widget={widget} />
          case 'NubeHashtags':
            return <NubeHashtagsTwitter widget={widget} />
          case 'InfluencersTwitter':
            return <InfluencersTwitter widget={widget} />
          case 'MencionesTwitter':
            return <MencionesTiwtter widget={widget} />
          case 'PublicadoresVolumen':
            return <PublicadoresVolumenTwitter widget={widget} />
          default:
            return null
        }
      })()}
    </WidgetContainer>
  )
}
export default Widget
