import styled from 'styled-components'
import { colors } from '../../variables'

export const PublicadoresContainer = styled.div`
  .widget--period_buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    button {
      height: 25px;
      margin: 0 10px;
      border: none;
      background: none;
      outline: none;
      font-size: 12px;
      font-weight: 400;
      color: ${colors.darkGray};
      cursor: pointer;
    }
    .period--active {
      color: ${colors.purple};
      border-bottom: 1px solid ${colors.purple};
    }
  }
  .widget--period {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${colors.darkGray};
    }
  }
`

export const RowContainer = styled.div`
  margin: 15px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  width: 80%;
  ${props => !props.noBar && 'border-bottom: 1px solid #C5C9D4'};
  p {
    font-size: ${props => (props.width < 450 ? '12px' : '15px')};
    color: #0b0c10;
  }
  div:first-child {
    width: 20%;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 5px;
    img {
      height: 40px;
      border-radius: 50%;
    }
  }
  div:nth-child(2) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 5px;
    flex-grow: ${props => (props.width < 450 ? '1' : '3')};
    & p:first-child {
      font-size: ${props => (props.width < 450 ? '11px' : '13px')};
      color: #565978;
    }
  }
  div:nth-child(3) {
    flex-grow: 1;
  }
`

export const ChartContainer = styled.div`
  width: '100%';
  .recharts-legend-wrapper {
    padding-left: 10px;
    li {
      margin-bottom: 10px;
    }
  }
  .listRow {
    display: flex;
    flex-flow: row;
    justify-content: space-around;
    width: ${props => props.width};
  }
  .no-scroll::-webkit-scrollbar {
    display: none;
  }
  .no-scroll {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
  .list-labels {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    h4 {
      font-size: 13px;
    }
  }
`

export const Placeholder = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`
