import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { TweetsContainer } from '../../../style/components/search/search/TweetsM.styled'
import { useLazyQuery } from '@apollo/client'
import { Spinner } from '../../../style/components'
import Tweet from '../new-search/TweetM'
import { AiOutlineCalendar } from 'react-icons/ai'

import { TWEETS_QTY_QUERY, TWEETS_QUERY } from '../../../services/queries'

const Tweets = ({ search }) => {
  const [page, setPage] = useState(1)
  const [loadingTweets, setLoadingTweets] = useState(false)

  const startDate = moment().subtract(7, 'days').format('YYYY-MM-DD')
  const endDate = moment().format('YYYY-MM-DD')

  const [getTweets, { data, loading, error, fetchMore: fetchMoreTweets }] = useLazyQuery(TWEETS_QUERY)
  const [getTweetsQty, { data: tweetsQtyData, loading: tweetsQtyLoading }] = useLazyQuery(TWEETS_QTY_QUERY)

  const fetchData = () => {
    getTweets({
      variables: {
        input: {
          query: search.query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
    getTweetsQty({
      variables: {
        input: {
          query: search.query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
  }

  //Todo: max date for input showing one day ahead

  useEffect(() => {
    //Todo: Rethink component because useEffect was not build to use this way, if the component is going to change but you don't care then why have it? Yu know! jiji
    fetchData()
    //eslint-disable-next-line
  }, [])

  const fetchMoreData = e => {
    const target = e.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (data.tweets.length === tweetsQtyData.tweetsQty) {
        return
      }
      setLoadingTweets(true)
      fetchMoreTweets({
        variables: {
          input: {
            query: search.query,
            startDate: moment(startDate).format('DD-MM-YYYY'),
            endDate: moment(endDate).format('DD-MM-YYYY'),
            pagina: page
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingTweets(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            tweets: [...prev.tweets, ...fetchMoreResult.tweets]
          })
        }
      })
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  if (tweetsQtyLoading || loading) return <Spinner />
  if (error) return <p>Hubo un error</p>

  return (
    <TweetsContainer>
      {data && tweetsQtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-articles'>
              {tweetsQtyData.tweetsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>
            <div className='results-period'>
              <AiOutlineCalendar />
              <p>
                Periodo de búsqueda: {moment(startDate).format('DD MMM')} a {moment(endDate).format('DD MMM')}
              </p>
            </div>
          </div>
          <div className='search-results' onScroll={fetchMoreData}>
            {data.tweets.map((tweet, idx) => (
              <Tweet key={idx} tweet={tweet} />
            ))}
            {loadingTweets && <Spinner />}
          </div>
        </>
      )}
    </TweetsContainer>
  )
}

export default Tweets
