import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { NewsContainer } from '../../../style/components/search/search/NewsM.styled'
import { useLazyQuery } from '@apollo/client'
import { Spinner } from '../../../style/components'
import Note from '../new-search/NoteM'
import { AiOutlineCalendar } from 'react-icons/ai'

import { NEWS_QTY_QUERY, NEWS_QUERY } from '../../../services/queries'

const News = ({ search }) => {
  const [page, setPage] = useState(2)
  const [loadingNews, setLoadingNews] = useState(false)

  const startDate = moment().format('YYYY-MM-DD')
  const endDate = moment().format('YYYY-MM-DD')

  const [getNewsQty, { data: newsQtyData, loading: newsQtyLoading }] = useLazyQuery(NEWS_QTY_QUERY)
  const [getNews, { data, loading, error, fetchMore }] = useLazyQuery(NEWS_QUERY)

  const fetchData = () => {
    getNewsQty({
      variables: {
        input: {
          query: search.query,
          startDate,
          endDate,
          medio: search.filters.media,
          cobertura: search.filters.state
        }
      }
    })
    getNews({
      variables: {
        input: {
          query: search.query,
          startDate,
          endDate,
          medio: search.filters.media,
          cobertura: search.filters.state,
          pagina: 1
        }
      }
    })
  }

  useEffect(() => {
    //eslint-disable-next-line
    fetchData()
    //eslint-disable-next-line
  }, []);


  const fetchMoreData = e => {
    const target = e.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (data.news.length === newsQtyData.newsQty) return
      setLoadingNews(true)
      fetchMore({
        variables: {
          input: {
            query: search.query,
            startDate,
            endDate,
            pagina: page
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingNews(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            news: [...prev.news, ...fetchMoreResult.news]
          })
        }
      })
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  if (newsQtyLoading || loading) return <Spinner />
  if (error) return <p>Hubo un error</p>

  return (
    <NewsContainer>
      {data && newsQtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-articles'>
              {newsQtyData.newsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>
            <div className='results-period'>
              <AiOutlineCalendar />
              <p>
                Periodo de búsqueda: {moment(startDate).format('DD MMM')} a {moment(endDate).format('DD MMM')}
              </p>
            </div>
          </div>
          <div className='search-results' onScroll={fetchMoreData}>
            {data.news.map((article, idx) => (
              <Note key={idx} note={article} />
            ))}
            {loadingNews && <Spinner />}
          </div>
        </>
      )}
    </NewsContainer>
  )
}

export default News
