import styled from 'styled-components'
import coverimage from '../../../../assets/reports/images/report_coverpage.png'

export const CoverPage = styled.div`
  background-image: url(${coverimage});
  background-size: cover;
  /* height: 210mm;
width: 297mm; */
`

export const TittleCoverPage = styled.div`
  position: relative;
  left: 25%;
  top: 20%;
  height: 50%;
  width: 75%;
  h1 {
    text-align: right;
    margin-right: 80px;
    color: white;
    margin-bottom: 20px;
    font-size: 45px;
  }
  p {
    text-align: right;
    margin-right: 80px;
    color: white;
    margin-bottom: 20px;
    margin-left: 200px;
    font-size: 22px;
  }
  h3 {
    text-align: right;
    margin-right: 80px;
    color: white;
    margin-bottom: 20px;
    font-size: 30px;
    font-weight: 400;
  }
`

export const IconCoverPage = styled.div`
  position: relative;
  left: 75%;
  width: 27%;
  top: 30%;
`
