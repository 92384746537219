import styled from 'styled-components'
import { colors } from '../../../variables'

export const TraditionalFiltersContainer = styled.div`
  .filters-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px;
    h2 {
      color: #0b0c10;
      font-size: 20px;
      font-weight: 600;
    }
    button {
      width: 25px;
      height: 25px;
      border: none;
      outline: none;
      background: none;
      svg {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
      }
    }
  }
  .refine-search {
    border-bottom: 1px solid #c5c9d4;
    h3 {
      color: #0b0c10;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 20px;
    }
    .or-search,
    .not-search {
      margin-bottom: 30px;
      p {
        color: #565978;
        font-size: 14px;
        font-weight: 400;
        margin-bottom: 15px;
        button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid #565978;
          outline: none;
          margin-left: 5px;
          color: #565978;
        }
      }
    }
  }
  .filters-container {
    width: 620px;
    margin-left: -10px;
    padding-left: 10px;
    padding-right: 10px;
    height: calc(100vh - 250px);
    overflow: scroll;
  }
  .coverage-filters,
  .catalogue-filters,
  .valoration-filters,
  .participation-filters {
    padding-top: 20px;
    border-bottom: 1px solid #c5c9d4;
    .filters-subheader {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      cursor: pointer;
      .filters-title {
        display: flex;
        h3 {
          color: #0b0c10;
          font-size: 16px;
          font-weight: 500;
        }
        p {
          margin-left: 10px;
          color: #565978;
          font-size: 14px;
          font-weight: 400;
        }
      }
      button {
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        svg {
          width: 30px;
          height: 30px;
          margin-top: -5px;
          fill: ${colors.darkGray};
        }
      }
    }
    .coverage-content,
    .catalogue-content,
    .valoration-content,
    .participation-content {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      label {
        display: flex;
        align-items: center;
        width: 150px;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        input {
          width: 15px;
          height: 15px;
          margin-right: 10px;
        }
      }
      .catalogue-type-section {
        display: flex;
        align-items: center;
        margin-bottom: 20px;
      }
      .all-category-types {
        width: 90px;
        margin: -25px 0 0 10px;
      }
    }
    .catalogue-content {
      flex-direction: column;
      align-items: flex-start;
      .more-filters-by-catalogue {
        width: 100%;
        .more-cat-fil-button {
          border: none;
          background: none;
          color: ${colors.lightseaGreen};
          outline: none;
          cursor: pointer;
          font-size: 14px;
          font-weight: 500;
        }
        .more-cat-fil-container {
          margin-left: -10px;
          padding-left: 10px;
          height: 460px;
          margin-top: 20px;
          width: 100%;
          p {
            color: ${colors.darkGray};
            font-size: 14px;
            font-weight: 400;
            margin-bottom: 7px;
            button {
              width: 15px;
              height: 15px;
              border-radius: 50%;
              border: 1px solid #565978;
              outline: none;
              margin-left: 5px;
              color: #565978;
            }
          }
          .fake-dropdown {
            width: 100%;
            height: 50px;
            border: 1px solid ${colors.darkGray};
            border-radius: 4px;
            margin-bottom: 20px;
          }
        }
      }
    }
    .coverage-content {
      flex-direction: column;
      align-items: flex-start;
      .scope-buttons {
        display: flex;
        margin-bottom: 21px;
      }
    }
    .valoration-content,
    .participation-content {
      padding-left: 25px;
    }
    .coverage-closed,
    .catalogue-closed,
    .valoration-closed,
    .participation-closed {
      display: none;
    }
  }
  .cat-type-button-active {
    .button-icon {
      background: ${colors.darkGray};
      svg {
        fill: ${colors.white};
      }
      #international {
        stroke: ${colors.white};
      }
    }
  }
  .apply-filters {
    width: 210px;
    height: 50px;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
  }
  .filters-buttons {
    display: flex;
    justify-content: flex-end;
    padding-top: 45px;
    .apply-filters {
      margin-left: 30px;
    }
  }
`

export const SelectButton = styled.button`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100px;
  border: none;
  outline: none;
  background: transparent;
  cursor: pointer;
  .button-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50px;
    height: 50px;
    border: none;
    border-radius: 4px;
    margin-bottom: 10px;
    background: ${colors.lightGray};
    svg {
      width: 35px;
      height: 35px;
      fill: ${colors.darkGray};
    }
    #international {
      fill: none;
      stroke: ${colors.darkGray};
      path {
        transform: translate(1px, 1px);
      }
    }
  }
  .button-text {
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 500;
  }
`
