import React from 'react'

import { Switch } from '../../style/components'
import { BillInfoCoantainer, BillInfoForm } from '../../style/components/auth/BillInfo/BillInfo.styled'
import Button from '../ui/Button'

const BillInfo = ({ billRequired, setBillRequired, bill, findAdress, billValidation, setStep }) => {
  return (
    <BillInfoCoantainer>
      <section className='left-information'>
        <h1>Datos de Facturación</h1>
        <p className='required'>Los campos marcados con * son campos obligatorios</p>
        <div className='plan-switch'>
          <p className={!billRequired ? 'bold' : 'normal'}>No necesito Factura</p>
          <Switch>
            <input
              type='checkbox'
              value={billRequired}
              checked={billRequired}
              onChange={() => setBillRequired(!billRequired)}
            />
            <span className='slider round' />
          </Switch>
          <p className={billRequired ? 'bold' : 'normal'}>Necesito Factura</p>
        </div>
        <Button
          size={'full'}
          weight={500}
          theme={'secondary'}
          shadow
          onClick={() => setStep(4)}
          disabled={billValidation() || !billRequired ? false : true}
        >
          Siguiente: Ingresa tu método de pago
        </Button>
      </section>
      <BillInfoForm>
        <h2>Datos de Facturación</h2>
        <label htmlFor='bill-name'>Razón Social</label>
        <input
          disabled={billRequired ? false : true}
          id='bill-name'
          type='text'
          placeholder='Razón Social *'
          value={bill.billName}
          onChange={e => bill.setBillName(e.target.value)}
          onBlur={() =>
            bill.billName === '' ? bill.setBillNameError('Campo obligatorio') : bill.setBillNameError(null)
          }
          onKeyUp={() =>
            bill.billName === '' ? bill.setBillNameError('Campo obligatorio') : bill.setBillNameError(null)
          }
        />
        <div className='form-error'>{bill.billNameError && <p>{bill.billNameError}</p>}</div>

        <label htmlFor='bill-rfc'>RFC</label>
        <input
          disabled={billRequired ? false : true}
          id='bill-rfc'
          type='text'
          placeholder='RFC *'
          value={bill.rfc}
          onChange={e => bill.setRfc(e.target.value)}
          onBlur={() =>
            bill.rfc === '' || bill.rfc.length !== 15
              ? bill.setRfcError(bill.rfc.length ? 'Debe tener 15 dígitos' : 'Campo obligatorio')
              : bill.setRfcError(null)
          }
          onKeyUp={() =>
            bill.rfc === '' || bill.rfc.length !== 15
              ? bill.setRfcError(bill.rfc.length ? 'Debe tener 15 dígitos' : 'Campo obligatorio')
              : bill.setRfcError(null)
          }
        />
        <div className='form-error'>{bill.rfcError && <p>{bill.rfcError}</p>}</div>

        <label htmlFor='bill-mail'>Correo</label>
        <input
          disabled={billRequired ? false : true}
          id='bill-mail'
          type='email'
          placeholder='Correo'
          value={bill.billEmail}
          onChange={e => bill.setBillEmail(e.target.value)}
          onBlur={() =>
            bill.billEmail === '' ? bill.setBillEmailError('Campo obligatorio') : bill.setBillEmailError(null)
          }
          onKeyUp={() =>
            bill.billEmail === '' ? bill.setBillEmailError('Campo obligatorio') : bill.setBillEmailError(null)
          }
        />
        <div className='form-error'>{bill.billEmailError && <p>{bill.billEmailError}</p>}</div>

        <label htmlFor='bill-street'>Calle</label>
        <input
          disabled={billRequired ? false : true}
          id='bill-street'
          type='text'
          placeholder='Calle *'
          value={bill.street}
          onChange={e => bill.setStreet(e.target.value)}
          onBlur={() => (bill.street === '' ? bill.setStreetError('Campo obligatorio') : bill.setStreetError(null))}
          onKeyUp={() => (bill.street === '' ? bill.setStreetError('Campo obligatorio') : bill.setStreetError(null))}
        />
        <div className='form-error'>{bill.streetError && <p>{bill.streetError}</p>}</div>

        <div className='three-inputs'>
          <div>
            <label htmlFor='bill-exterior'>Numero exterior</label>
            <input
              disabled={billRequired ? false : true}
              id='bill-exterior'
              type='text'
              placeholder='# ext'
              value={bill.exterior}
              onChange={e => bill.setExterior(e.target.value)}
              onBlur={() =>
                bill.exterior === '' ? bill.setExteriorError('Campo obligatorio') : bill.setExteriorError(null)
              }
              onKeyUp={() =>
                bill.exterior === '' ? bill.setExteriorError('Campo obligatorio') : bill.setExteriorError(null)
              }
            />
            <div className='form-error'>{bill.exteriorError && <p>{bill.exteriorError}</p>}</div>
          </div>

          <div>
            <label htmlFor='bill-interior'>Número interior</label>
            <input
              disabled={billRequired ? false : true}
              id='bill-interior'
              type='text'
              placeholder='# int'
              value={bill.interior}
              onChange={e => bill.setInterior(e.target.value)}
            />
            <div className='form-error'>{false && <p>Error</p>}</div>
          </div>

          <div>
            <label htmlFor='bill-cp'>Código Postal</label>
            <input
              disabled={billRequired ? false : true}
              id='bill-cp'
              type='text'
              placeholder='Código Postal *'
              value={bill.cp}
              onChange={e => bill.setCp(e.target.value)}
              onBlur={() => (bill.cp === '' ? bill.setCpError('Campo obligatorio') : bill.setCpError(null))}
              onKeyUp={() => {
                bill.cp === '' ? bill.setCpError('Campo obligatorio') : bill.setCpError(null)
                findAdress()
              }}
            />
            <div className='form-error'>{bill.cpError && <p>{bill.cpError}</p>}</div>
          </div>
        </div>

        <label htmlFor='bill-city'>Estado</label>
        <input id='bill-city' type='text' placeholder='Estado *' value={bill.city} disabled />
        <div className='form-error'>{false && <p>Error</p>}</div>

        <label htmlFor='bill-alc-mun'>Alcaldía / Municipio</label>
        <input id='bill-alc-mun' type='text' placeholder='Alcaldía / Municipio *' value={bill.alcMun} disabled />
        <div className='form-error'>{false && <p>Error</p>}</div>

        <label htmlFor='bill-col'>Colonia</label>
        <select
          name='bill-col'
          id='bill-col'
          disabled={(bill.colArr.includes('Colonia *') && bill.colArr.length === 1) || !billRequired ? true : false}
          onChange={e => bill.setColonia(e.target.value)}
        >
          {bill.colArr.map((col, idx) => {
            return col === 'Colonia *' ? (
              <option key={idx} value='' disabled hidden>
                {col}
              </option>
            ) : (
              <option key={idx} value={col}>
                {col}
              </option>
            )
          })}
        </select>
        <div className='form-error'>{false && <p>Error</p>}</div>
      </BillInfoForm>
    </BillInfoCoantainer>
  )
}

export default BillInfo
