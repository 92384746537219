import styled, { css } from 'styled-components'
import { buttonColors, colors } from '../../variables'

const ButtonContainer = styled.button`
  transition: ease-in-out 0.2s;
  width: ${props => props.width};
  height: ${props => props.height || '50px'};
  outline: none;
  border-width: 2px;
  font-size: 15px;
  font-weight: ${props => props.weight || 600};
  border-radius: 4px;
  border-color: transparent;
  cursor: pointer;
  ${props =>
    props.shadow &&
    css`
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    `}

  &:disabled {
    cursor: not-allowed;
  }

  ${props =>
    props.variant === 'outline' &&
    css`
      background: transparent;
      border-style: solid;
      border-color: ${props =>
        props.disabled
          ? buttonColors.disabled
          : buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].default};
      color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].default};

      &:disabled {
        border-color: ${buttonColors.disabled};
        color: ${colors.darkGray};
      }

      &:hover:enabled {
        border-color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].hover};
        color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].hover};
      }

      &:active:enabled {
        border-color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].press};
        color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].press};
      }
    `}
  ${props =>
    props.variant === 'solid' &&
    css`
      background: ${props => buttonColors[props.theme].default};
      color: ${props => buttonColors[props.theme].text};

      &:disabled {
        background: ${buttonColors.disabled};
        color: ${colors.darkGray};
      }

      &:hover:enabled {
        background: ${props => buttonColors[props.theme].hover};
      }

      &:active:enabled {
        background: ${props => buttonColors[props.theme].press};
      }
    `}
  ${props =>
    props.variant === 'link' &&
    css`
      background: transparent;
      color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].default};

      &:hover {
        color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].hover};
      }

      &:active {
        color: ${props => buttonColors[props.theme === 'primary' ? 'primaryOutline' : props.theme].press};
      }
    `}
`

export default ButtonContainer
