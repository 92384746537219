import styled from 'styled-components'
import { colors } from '../../variables'

const CardFormContainer = styled.div`
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  border-radius: 4px;
  width: 632px;
  position: absolute;
  background: ${colors.white};
  z-index: 32;
  padding: 50px;
  display: flex;
  flex-direction: column;

  .filters--header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: ${colors.richBlack};
    line-height: 29.26px;
  }

  .newpayment__subtitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .newpaymentsubtitle__text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      color: ${colors.richBlack};
    }
  }

  label {
    margin-bottom: 10px;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 400;
  }
  input {
    height: 50px;
    padding-left: 10px;
    margin-bottom: 15px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    background: transparent;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 400;
  }
  p {
    color: ${colors.red};
    margin: 15px;
    font-weight: bold;
  }
  .card-element {
    width: 100%;
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    padding: 15px 10px 15px 20px;
    margin-bottom: 15px;
  }

  .submit-button {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

export default CardFormContainer
