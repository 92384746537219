import { gql, useMutation } from '@apollo/client'
import React, { useRef, useState } from 'react'
import { ModalContainer, ShareURLContainer } from '../../../style/components/reports/reports/ShareModalReport.styled'
import { Spinner } from '../../../style/components'
import PeriodDropdown from '../../ui/PeriodDropdown'
import Button from '../../ui/Button'

const periods = [
  {
    id: '0',
    name: '1 día'
  },
  {
    id: '1',
    name: '7 días'
  },
  {
    id: '2',
    name: '15 días'
  },
  {
    id: '3',
    name: '30 días'
  },
  {
    id: '4',
    name: 'Sin vigencia'
  }
]

const SCHEDULE_SHAREREPORT = gql`
  mutation createSharedTime($idReport: ID, $sharedTimeId: String) {
    createSharedTime(idReport: $idReport, sharedTimeId: $sharedTimeId) {
      id
    }
  }
`

const ShareModal = ({ loading, reportId }) => {
  const [period, setPeriod] = useState([])
  const [copyMsg, setCopyMsg] = useState(false)
  const [error, setError] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  const url = `${window.location.host}/reports/report/${reportId}`

  const [createSharedTime] = useMutation(SCHEDULE_SHAREREPORT)

  const urlToCopy = useRef()

  const copyToClipboard = async () => {
    const range = document.createRange()
    range.selectNode(urlToCopy.current)
    window.getSelection().addRange(range)
    try {
      await createSharedTime({ variables: { idReport: reportId, sharedTimeId: period[0].id } })
    } catch ({ message }) {
      setErrorMessage(message)
      setError(true)
    }
    navigator.clipboard.writeText(url)
    setCopyMsg(true)
  }

  if (loading)
    return (
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    )

  return (
    <ModalContainer>
      <h3>Compartir reporte</h3>
      <p>Comparte este reporte mediante el siguiente enlace con personas externas a MyOblek:</p>
      <br />
      <p>Elige la vigencia del enlace</p>
      <br />
      <PeriodDropdown
        width='80%'
        title='Vigencia'
        items={periods}
        selection={period}
        setSelection={setPeriod}
        multiSelect={false}
      />
      <ShareURLContainer>
        <input ref={urlToCopy} type='text' value={url} disabled={true} readOnly={true} />
      </ShareURLContainer>
      <Button
        size={'full'}
        weight={500}
        className={period[0] ? 'button-active' : 'button-inactive'}
        onClick={() => copyToClipboard()}
        disabled={period[0] ? false : true}
      >
        {copyMsg ? 'Copiado' : 'Copiar link'}
      </Button>
      {error && <p className='error-message'>{errorMessage}</p>}
    </ModalContainer>
  )
}

export default ShareModal
