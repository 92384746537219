import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const NoteContainer = styled.article`
  width: 330px;
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 0 20px 20px 0;
  padding: 15px;
  position: relative;
  @media (max-width: 580px) {
    margin: 0 0px 20px 0;
  }
  .note--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.lightGray};
    padding-bottom: 5px;
  }
  .note-checkbox {
    .checkbox-indicator {
      width: 40px;
      height: 40px;
      background: none;
      border: none;
      outline: none;
      margin-right: 5px;
      .checked {
        width: 23px;
        height: 23px;
        fill: ${colors.lightseaGreen};
      }
      .not-checked {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
        cursor: pointer;
      }
    }
  }
  .note--title {
    width: 225px;
    h3 {
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 5px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 8px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .note--icon {
    width: 27.5px;
    height: 27.5px;
    margin-left: 20px;
    svg {
      width: 27.5px;
      height: 100%;
      path {
        fill: #748596;
      }
    }
  }
  .note--content {
    margin-bottom: 80px;
    h3 {
      margin: 9px 0;

      font-size: 16px;
      font-weight: 600;
      font-family: Montserrat;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 14px;
      font-weight: 500;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
      color: #748596;
    }
    a {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.lightseaGreen};
    }
  }
  .note--date {
    display: flex;
    padding: 5px 15px;
    width: 100%;
    justify-content: space-between;
    position: absolute;
    bottom: 50px;
    top: auto;
    left: 0;
    right: 0;
    height: 30px;
    .califi {
      display: flex;
      align-items: center;
      p {
        font-size: 14px;
        font-weight: 500;
      }
      .circle {
        height: 12px;
        width: 12px;
        border-radius: 50%;
        margin-right: 5px;
        &--negativa {
          background: ${colors.red};
        }
        &--neutra {
          background: ${colors.purple};
        }
        &--positiva {
          background: ${colors.lightseaGreen};
        }
      }
    }
  }
  .date {
    color: #748596;
    display: flex;
    p {
      font-size: 12px;
      font-weight: 500;
    }
    .vLine {
      margin: 0 5px;
    }
  }
  .note--footer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #aaafbe;
    position: absolute;
    top: auto;
    bottom: 0;
    left: 0;
    right: 0;
    height: 50px;
    p {
      display: flex;
      align-items: center;
      svg {
        width: 15px;
        height: 15px;
        stroke: ${colors.lightGray};
        margin-right: 3px;
      }
      font-size: 12px;
      font-weight: 400;
    }
    .note--actions {
      display: flex;
      justify-content: space-between;
      width: 100%;
      a {
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.lightseaGreen};
        outline: none;
        cursor: pointer;
        padding: 15px;
      }
      button {
        color: #748596;
        text-align: right;
      }
    }
  }
`
