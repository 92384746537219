import styled from 'styled-components'
import { colors } from '../../variables'

export const DashboardContainer = styled.div`
  .topbarMobile {
    justify-content: space-between;
    background: #000;
    height: 56px;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 10px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 40px;
          height: 28px;
          color: white;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
      p {
        width: 210px;
        font-size: 22px;
        color: white;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      button {
        cursor: pointer;
      }
    }
  }
  .toolbar {
    justify-content: flex-end;
    .toolbar-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 40px;
      margin-left: 10px;
      border: none;
      outline: none;
      background: none;
      .toolbar-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: ${colors.darkGray};
        &.gray-icon {
          fill: ${colors.darkGray};
        }
      }
      p {
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
      }
    }
    .dashboard-actions {
      position: relative;
      .options-menu {
        z-index: 5;
        position: absolute;
        top: 40px;
        right: 0;
        width: 200px;
        height: 150px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
        button {
          border: none;
          background: none;
          outline: none;
        }
        .full_option {
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: ${colors.darkGray};
          padding-left: 20px;
          border-radius: 0 0 4px 4px;
          :hover {
            background: ${colors.lightGray};
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        .delete_button {
          color: ${colors.red};
        }
      }
    }
  }
`

export const WidgetsContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 110px);
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
`
