import styled from 'styled-components'
import { colors } from '../../../variables'

export const AllSearchesContainer = styled.div`
  padding-left: ${props => (props.filtersOpen || props.ordersOpen ? '400px' : '0px')};
  .searches-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px 0px 7.5px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      padding-left: 2%;
      &.number {
        width: 7%;
      }
      &.name {
        width: 28%;
      }
      &.source {
        width: 20%;
      }
      &.date {
        width: 13%;
      }
      &.creator {
        width: 17%;
      }
      &.options {
        width: 15%;
      }
    }
  }
  .searches-table-body {
    height: calc(100vh - 112px);
    overflow-y: scroll;
    padding: 7px;
    .searches-table-row {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: ease-in-out 0.2s;
      transform: translate(0, 0px);
      &:hover {
        transform: translate(0, -3px);
      }
      .options {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 16%;
        right: 80px;
        button {
          border: none;
          outline: none;
          background: none;
          padding: 3px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: ease-in-out 0.3s;
          &:hover {
            background: ${colors.red};
            svg {
              fill: ${colors.white} !important;
            }
          }
          &.open {
            color: ${colors.lightseaGreen};
            font-size: 14px;
            font-weight: 500;
          }
          &.copy {
            svg {
              width: 20px;
              height: 20px;
              fill: ${colors.darkGray};
            }
          }
          &.delete {
            svg {
              width: 20px;
              height: 20px;
              fill: ${colors.darkGray};
            }
          }
        }
      }
      .searchCont {
        display: flex;
        min-height: 150px;
        flex-direction: column;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        transition: ease-in-out 0.3s;
        justify-content: space-between;
        &:hover {
          background: ${colors.lightGray};
        }
        &-header {
          width: 100%;
          display: flex;
          padding: 16px 16px 0 16px;
          p {
            font-size: 18px;
            text-align: left;
            padding: 0;
            color: #0b0c10;
            font-weight: bold;
          }
          .number {
            margin-right: 7px;
          }
        }
        &-body {
          display: flex;
          width: 100%;
          padding: 0 16px;
          justify-content: space-between;
          .creatorCont {
            display: flex;
            width: 100%;
            margin-right: 10px;
            .creator {
              display: flex;
              align-items: center;
              .creator-photo {
                width: 30px;
                height: 30px;
                margin-right: 10px;
                background: ${colors.lightGray};
                border-radius: 50%;
              }
            }
          }
          .sourceCont {
            display: flex;
            justify-content: center;
            align-items: center;
            svg {
              height: 28px;
              width: 28px;
              fill: ${colors.darkGray};
              margin-right: 10px;
            }
            .twitter {
              svg {
                fill: ${colors.white};
                stroke-width: 1;
                height: 28px;
                width: 28px;
              }
            }
            .source {
              text-transform: capitalize;
            }
          }
        }
        &-footer {
          height: 38px;
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
          border-top: 0.1px solid ${colors.darkGray};
          p {
            color: #00aaab;
            font-size: 14;
            font-weight: bold;
          }
        }
      }
    }
  }
`
