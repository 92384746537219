import styled from 'styled-components'
import { colors } from '../../../variables'

const EditTraditionalSearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-bottom: -30px;
  .advanced-search-bar {
    display: flex;
    justify-content: center;
    width: 100%;
    .advanced-search-button {
      margin-left: 20px;
    }
    .filter-search-button {
      margin-left: 20px;
    }
  }
`

export default EditTraditionalSearchContainer
