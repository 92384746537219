import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import DashboardsContainer from '../../style/pages/Dashboard/Dashboards.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar, Spinner, Shadow } from '../../style/components'

import { FiFilter } from 'react-icons/fi'
import { BsArrowUpDown } from 'react-icons/bs'

import MineDashboards from '../../components/dashboard/dashboards/MineDashboards'
import SharedDashboards from '../../components/dashboard/dashboards/SharedDashboards'
import DashboardFilters from '../../components/dashboard/dashboards/DashboardFilters'
import DeleteDashboard from '../../components/dashboard/dashboards/DeleteDashboard'
import MineDashboardOrder from '../../components/dashboard/dashboards/MineDashboardOrder'
import SharedDashboardOrder from '../../components/dashboard/dashboards/SharedDashboardOrder'

import { DASHBOARDS_QUERY, SHARED_DASHBOARDS_QUERY } from '../../services/queries'
import { DELETE_DASHBOARD_MUTATION, DUPLICATE_DASHBOARD_MUTATION } from '../../services/mutations'
import { MyContext } from '../../context'
import DuplicateBoard from '../../components/dashboard/dashboard/DuplicateBoard'
import Button from '../../components/ui/Button'
import DashboardWarning from '../../components/dashboard/new-dashboard/DashboardWarning'

const Dashboards = props => {
  const { showNotifications, setNotificationText, ...context } = useContext(MyContext)

  const uiCTX = useContext(UIContext)
  const {
    mainClass,
    topbarClass,
    toolbarClass,
    state: { collapsed }
  } = uiCTX

  const {
    data: dashboardsData,
    loading: dashboardsLoading,
    error: dashboardsError
  } = useQuery(DASHBOARDS_QUERY, { fetchPolicy: 'network-only' })
  const {
    data: sharedDashboardsData,
    loading: sharedDashLoading,
    error: sharedDashboardError
  } = useQuery(SHARED_DASHBOARDS_QUERY, { fetchPolicy: 'network-only' })

  const [deletedDashboard, { error: errorDeleteDashboard, loading: deleteDashboardLoading }] = useMutation(
    DELETE_DASHBOARD_MUTATION,
    {
      update(cache, { data: { deletedDashboard } }) {
        const { dashboards } = cache.readQuery({ query: DASHBOARDS_QUERY })
        cache.writeQuery({
          query: DASHBOARDS_QUERY,
          data: {
            dashboards: dashboards.filter(dashboard => dashboard.id !== deletedDashboard.id)
          }
        })
      }
    }
  )

  if (errorDeleteDashboard) context.handleErrors(errorDeleteDashboard)

  const [section, setSection] = useState('mine')

  const [dashboardToDelete, setDashboardToDelete] = useState(null)
  const [dashboardToDuplicate, setDashboardToDuplicate] = useState(null)
  const [limitWarning, setLimitWarning] = useState(null)

  const [duplicatedDashboard] = useMutation(DUPLICATE_DASHBOARD_MUTATION, {
    update(cache, { data: { duplicatedDashboard } }) {
      const { dashboards } = cache.readQuery({ query: DASHBOARDS_QUERY })
      cache.writeQuery({
        query: DASHBOARDS_QUERY,
        data: {
          dashboards: [...dashboards, duplicatedDashboard]
        }
      })
    },
    onError: error => {
      if (error.message.includes('límite')) {
        setLimitWarning({ error: true, plan: error.message.split(' ')[0], quantity: error.message.split(' ')[1] })
      }
    }
  })
  const handleDuplicateDashboard = async dashboard => {
    await duplicatedDashboard({
      variables: {
        input: {
          id: dashboard.id,
          name: dashboard.name,
          description: dashboard.description
        }
      }
    })
    setDashboardToDuplicate(null)
  }

  const [nameFilter, setNameFilter] = useState('')
  const [creatorFilter, setCreatorFilter] = useState('')
  const [fromFilter, setFromFilter] = useState('')
  const [toFilter, setToFilter] = useState('')

  const [showMineFilters, setShowMineFilters] = useState(false)
  const [showMineOrder, setShowMineOrder] = useState(false)
  const [showSharedOrder, setShowSharedOrder] = useState(false)
  const [showSharedFilters, setShowSharedFilters] = useState(false)
  const [nameAZOrder, setNameAZOrder] = useState(false)
  const [nameZAOrder, setNameZAOrder] = useState(false)
  const [recentDateOrder, setRecentDateOrder] = useState(true)
  const [oldDateOrder, setOldDateOrder] = useState(false)

  const resetFilters = () => {
    setNameFilter('')
    setCreatorFilter('')
    setFromFilter('')
    setToFilter('')
  }

  const resetOrder = () => {
    setNameAZOrder(false)
    setNameZAOrder(false)
    setRecentDateOrder(true)
    setOldDateOrder(false)
  }

  const setMineSection = () => (section === 'mine' ? 'section-button section-button-active' : 'section-button')
  const setSharedSection = () => (section === 'shared' ? 'section-button section-button-active' : 'section-button')

  const handleShowFilters = () => {
    if (section === 'mine') setShowMineFilters(!showMineFilters)
    if (section === 'shared') setShowSharedFilters(!showSharedFilters)
  }

  const handleShowOrder = () => {
    if (section === 'mine') setShowMineOrder(!showMineOrder)
    if (section === 'shared') setShowSharedOrder(!showSharedOrder)
  }

  const handleDeleteDashboard = async id => {
    await deletedDashboard({ variables: { id } })
    setDashboardToDelete(null)
    showNotifications()
    setNotificationText('El tablero fue eliminado con éxito.')
  }

  if (dashboardsError) context.handleErrors(dashboardsError)
  if (sharedDashboardError) context.handleErrors(sharedDashboardError)

  if (dashboardsLoading || sharedDashLoading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <DashboardsContainer>
        {showMineFilters && section === 'mine' && (
          <DashboardFilters
            sidebar={collapsed}
            showFilters={setShowMineFilters}
            filters={{
              name: nameFilter,
              setName: setNameFilter,
              from: fromFilter,
              setFrom: setFromFilter,
              to: toFilter,
              setTo: setToFilter
            }}
            section={'mine'}
            resetFilters={resetFilters}
          />
        )}

        {showSharedFilters && section === 'shared' && (
          <DashboardFilters
            sidebar={collapsed}
            showFilters={setShowSharedFilters}
            filters={{
              name: nameFilter,
              setName: setNameFilter,
              creator: creatorFilter,
              setCreator: setCreatorFilter,
              from: fromFilter,
              setFrom: setFromFilter,
              to: toFilter,
              setTo: setToFilter
            }}
            section={'shared'}
            resetFilters={resetFilters}
          />
        )}

        {showMineOrder && section === 'mine' && (
          <MineDashboardOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShowMineOrder
            }}
          />
        )}

        {showSharedOrder && section === 'shared' && (
          <SharedDashboardOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShowSharedOrder
            }}
          />
        )}

        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <h1>Tableros</h1>
            <Button
              size='medium'
              height='40px'
              theme='secondary'
              variant='outline'
              onClick={() => props.history.push('/dashboards/new-dashboard')}
            >
              Nuevo tablero
            </Button>
          </div>
        </Topbar>

        <Toolbar className={toolbarClass()}>
          <div className='left-toolbar'>
            <button className={setMineSection()} onClick={() => setSection('mine')}>
              Mis tableros
            </button>
            <button className={setSharedSection()} onClick={() => setSection('shared')}>
              Compartidos
            </button>
          </div>

          <div className='right-toolbar'>
            <button className='toolbar-button' onClick={() => handleShowFilters()}>
              <FiFilter className='toolbar-icon' />
              <p>Filtrar</p>
            </button>
            <button className='toolbar-button' onClick={() => handleShowOrder()}>
              <BsArrowUpDown className='toolbar-icon gray-icon' />
              <p>Ordenar</p>
            </button>
          </div>
        </Toolbar>

        {section === 'mine' && (
          <MineDashboards
            dashboards={dashboardsError ? [] : dashboardsData.dashboards}
            filtersOpen={showMineFilters}
            filters={{
              name: nameFilter,
              creator: creatorFilter,
              from: fromFilter,
              to: toFilter
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder,
              recentDate: recentDateOrder,
              oldDate: oldDateOrder
            }}
            setDashboardToDuplicate={setDashboardToDuplicate}
            setDashboardToDelete={setDashboardToDelete}
          />
        )}

        {section === 'shared' && (
          <SharedDashboards
            dashboards={sharedDashboardError ? [] : sharedDashboardsData.sharedDashboards}
            filtersOpen={showSharedFilters}
            filters={{
              name: nameFilter,
              creator: creatorFilter,
              from: fromFilter,
              to: toFilter
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder,
              recentDate: recentDateOrder,
              oldDate: oldDateOrder
            }}
            setDashboardToDuplicate={setDashboardToDuplicate}
            setDashboardToDelete={setDashboardToDelete}
          />
        )}

        {dashboardToDelete && (
          <>
            <Shadow />
            <DeleteDashboard
              loading={deleteDashboardLoading}
              dashboard={dashboardToDelete}
              setDashboardToDelete={setDashboardToDelete}
              deleteDashboard={handleDeleteDashboard}
            />
          </>
        )}

        {dashboardToDuplicate && (
          <>
            <Shadow onClick={() => setDashboardToDuplicate(null)} />
            <DuplicateBoard
              dashboard={dashboardToDuplicate}
              closeModal={() => setDashboardToDuplicate(null)}
              handleDuplicateDashboard={handleDuplicateDashboard}
            />
          </>
        )}
        {limitWarning && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <DashboardWarning info={limitWarning} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </DashboardsContainer>
    </MainContainer>
  )
}

export default Dashboards
