import React, { useEffect } from 'react'
import BirthdayInputContainer from '../../style/components/profile/BirthdayInput.styled'
import AutoComplete from '../ui/AutoComplete'

const BirthdayInput = ({ day, setDay, month, setMonth, year, setYear, dateError, setDateError }) => {
  useEffect(() => {
    if (day && month && year) {
      const date = new Date(year, month - 1, day)

      if (date.getFullYear() !== year || date.getMonth() !== month - 1 || date.getDate() !== day) {
        return setDateError(true)
      }

      const actualYear = new Date().getFullYear()
      if (actualYear - year < 18 || year < actualYear - 100) {
        return setDateError(true)
      }
    }

    setDateError(false)
  }, [day, month, year])

  const getArrayFromNumbers = (min, max) => {
    const numbers = []

    for (let i = min; i <= max; i++) {
      numbers.push(i)
    }

    return numbers
  }

  return (
    <BirthdayInputContainer className='profileCont-body-data-happyday'>
      <label>Tu cumpleaños</label>
      <div className='date'>
        <AutoComplete
          onChange={setDay}
          value={day}
          type={'number'}
          placeholder={'Día'}
          id={'day'}
          options={getArrayFromNumbers(1, 31)}
        />
        <AutoComplete
          onChange={setMonth}
          value={month}
          type={'number'}
          placeholder={'Mes'}
          id={'month'}
          options={getArrayFromNumbers(1, 12)}
        />
        <AutoComplete
          onChange={setYear}
          value={year}
          type={'number'}
          placeholder={'Año'}
          id={'year'}
          options={getArrayFromNumbers(1900, new Date().getFullYear()).reverse()}
        />
      </div>
      <div className='date__feedback'>
        {dateError ? <p className={'incorrect__date'}>Fecha de nacimiento incorrecta</p> : null}
      </div>
    </BirthdayInputContainer>
  )
}

export default BirthdayInput
