import React, { useState } from 'react'
import { SignupContainer, SignupForm } from '../../style/pages/auth/Join.styled'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'

import { CREATE_USER } from '../../services/mutations'
import DataDropdown from '../../components/ui/DataDropdown'
import { degrees, genders, occupations } from '../../data/Data'
import Button from '../../components/ui/Button'

const Signup = props => {
  const [createUser] = useMutation(CREATE_USER)

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      emailConfirmation: '',
      password: '',
      passwordConfirmation: '',
      age: null,
      degree: [''],
      occupation: [''],
      gender: ['']
    },
    validationSchema: Yup.object({
      name: Yup.string().required('El nombre es obligatorio'),
      email: Yup.string().required('El correo es obligatorio').email('Ingresa un correo válido'),
      emailConfirmation: Yup.string()
        .required('Debes validar tu correo')
        .oneOf([Yup.ref('email'), null], 'Los correos no coinciden'),
      password: Yup.string()
        .required('La contraseña es obligatoria')
        .min(6, 'La contraseña debe ser de al menos 6 caracteres.'),
      passwordConfirmation: Yup.string()
        .required('Debes validar tu contraseña')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden'),
      age: Yup.number('La edad es obligatoria').required('La edad es obligatoria'),
      degree: Yup.array()
        .min(1, 'El nivel de estudios es obligatorio')
        .of(Yup.string().required())
        .required('El nivel de estudios es obligatorio'),
      occupation: Yup.array()
        .min(1, 'La ocupación es obligatoria')
        .of(Yup.string().required())
        .required('La ocupación es obligatoria'),
      gender: Yup.array()
        .min(1, 'El género es obligatorio')
        .of(Yup.string().required())
        .required('El género es obligatorio')
    }),
    onSubmit: async values => {
      const { name, email, password, age, degree, occupation, gender } = values
      const code = props.match.params.code
      try {
        const { data } = await createUser({
          variables: {
            input: {
              code,
              name,
              email,
              password,
              age: parseInt(age),
              degree: degree[0],
              occupation: occupation[0],
              gender: gender[0]
            }
          }
        })

        const { token } = data.createUser
        localStorage.setItem('token', token)

        setTimeout(() => {
          props.history.push('/')
        }, 1000)
      } catch (error) {
        setMessage(error.message)
        setTimeout(() => {
          setMessage(null)
        }, 3000)
        setStep(1)
      }
    }
  })

  const wsname = props.match.params.wsname.split('-').join(' ')

  const [message, setMessage] = useState(null)
  const [step, setStep] = useState(1)

  const validateStepOne = () => {
    return !(
      formik.values.name &&
      formik.values.email &&
      formik.values.emailConfirmation &&
      formik.values.password &&
      formik.values.passwordConfirmation &&
      !formik.errors.name &&
      !formik.errors.email &&
      !formik.errors.emailConfirmation &&
      !formik.errors.password &&
      !formik.errors.passwordConfirmation
    )
  }

  const validateSecondStep = () => {
    return !(
      formik.values.age &&
      formik.values.degree &&
      formik.values.occupation &&
      formik.values.gender &&
      !formik.errors.age &&
      !formik.errors.degree &&
      !formik.errors.occupation &&
      !formik.errors.gender
    )
  }

  return (
    <SignupContainer>
      <section className='left-information'>
        {step === 1 ? (
          <div>
            <h1>Crea una nueva cuenta</h1>
            <p>
              El equipo <span style={{ fontWeight: 'bold' }}>{wsname}</span> te ha invitado a que te unas a ellos en
              MyOblek.
              <br />
              Crea tu cuenta y accede a todo el poder de la información.
            </p>
          </div>
        ) : (
          <div>
            <h1>Gracias por elegirnos</h1>
            <p>Los siguientes datos nos ayudan a crecer y brindarte un mejor servicio.</p>
          </div>
        )}
        <p className='privacy-note'>
          Tu información está segura, si tienes dudas puedes consultar nuestro{' '}
          <a href='https://www.myoblek.com/aviso' target='_blank' rel='noopener noreferrer'>
            Aviso de Privacidad
          </a>
        </p>

        <Button
          weight={500}
          shadow
          theme={'secondary'}
          form='join-form'
          className={(step === 1 ? validateStepOne() : validateSecondStep()) ? 'button-inactive' : 'button-active'}
          onClick={step === 1 ? () => setStep(2) : formik.handleSubmit}
          disabled={step === 1 ? validateStepOne() : validateSecondStep()}
        >
          {step === 1 ? 'Continuar' : 'Crear mi cuenta'}
        </Button>
      </section>

      <SignupForm id='join-form'>
        <h2>Tus Datos</h2>
        <p>Ingresa tus datos para comenzar. Los campos marcados con * son campos obligatorios</p>

        {step === 1 && (
          <div>
            <label htmlFor='name'>Nombre</label>
            <input
              id='name'
              placeholder='Tu nombre *'
              value={formik.values.name}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.name && formik.errors.name ? <p>{formik.errors.name}</p> : null}
            </div>

            <label htmlFor='email'>Email</label>
            <input
              id='email'
              placeholder='Correo *'
              value={formik.values.email}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.email && formik.errors.email ? <p>{formik.errors.email}</p> : null}
            </div>

            <label htmlFor='emailConfirmation'>Confirma tu email</label>
            <input
              id='emailConfirmation'
              placeholder='Verifica tu correo *'
              value={formik.values.emailConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.emailConfirmation && formik.errors.emailConfirmation ? (
                <p>{formik.errors.emailConfirmation}</p>
              ) : null}
            </div>

            <label htmlFor='password'>Contraseña</label>
            <input
              id='password'
              type='password'
              placeholder='Contraseña *'
              value={formik.values.password}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.password && formik.errors.password ? <p>{formik.errors.password}</p> : null}
            </div>

            <label htmlFor='passwordConfirmation'>Contraseña</label>
            <input
              id='passwordConfirmation'
              type='password'
              placeholder='Verifica la contraseña *'
              value={formik.values.passwordConfirmation}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.passwordConfirmation && formik.errors.passwordConfirmation ? (
                <p>{formik.errors.passwordConfirmation}</p>
              ) : null}
            </div>

            <div className='form-error relative'>{message && <p>{message}</p>}</div>
          </div>
        )}

        {step === 2 && (
          <div>
            <label htmlFor='age'>Edad</label>
            <input
              id='age'
              type='number'
              placeholder='Edad*'
              min='1'
              max='100'
              value={formik.values.age}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
            />
            <div className='form-error'>
              {formik.touched.age && formik.errors.age ? <p>{formik.errors.age}</p> : null}
            </div>

            <DataDropdown
              width='100%'
              title='Nivel de estudios*'
              items={degrees}
              selection={formik.values.degree}
              setSelection={async degree => {
                await formik.setFieldValue('degree', degree)
                await formik.setFieldTouched('degree')
              }}
              multiSelect={false}
            />
            <div className='form-error'>
              {formik.touched.degree && formik.errors.degree ? <p>{formik.errors.degree}</p> : null}
            </div>

            <DataDropdown
              width='100%'
              title='Ocupación*'
              items={occupations}
              selection={formik.values.occupation}
              setSelection={async occupation => {
                await formik.setFieldValue('occupation', occupation)
                await formik.setFieldTouched('occupation')
              }}
              multiSelect={false}
            />
            <div className='form-error'>
              {formik.touched.occupation && formik.errors.occupation ? <p>{formik.errors.occupation}</p> : null}
            </div>

            <DataDropdown
              width='100%'
              title='Género*'
              items={genders}
              selection={formik.values.gender}
              setSelection={async gender => {
                await formik.setFieldValue('gender', gender)
                await formik.setFieldTouched('gender')
              }}
              multiSelect={false}
            />
            <div className='form-error'>
              {formik.touched.gender && formik.errors.gender ? <p>{formik.errors.gender}</p> : null}
            </div>
          </div>
        )}
      </SignupForm>
    </SignupContainer>
  )
}

export default Signup
