import React, { useState, useEffect, useContext } from 'react'
import { Shadow } from '../../style/components'
import Invite from './Invite'
import UserWarning from '../../components/workspace/UserWarning'
import { useQuery, useMutation } from '@apollo/client'

import { TeamContainer } from '../../style/components/workspace/TeamContainer.styled'
// import UserRolesMenu from "./Modals/UserRolesMenu";
import TeamMember from './TeamMember'
import MemberInvited from './MemberInvited'
// import DeleteAdminWarning from "./Modals/DeleteAdminWarning";
// import DeleteUserWarning from "./Modals/DeleteUserWarning";
// import Notification from "./Modals/Notification";
// import Arrow from "../../assets/arrow";
// import TrashIcon from "../../assets/IconTrash";
// import { colors } from "../../style/variables";

import { GET_WORKSPACE, GET_WORKSPACE_USERS, GET_WORKSPACE_INVITES } from '../../services/queries'
import { DELETE_INVITE, CHANGE_ROLE } from '../../services/mutations'
import { MyContext } from '../../context'

const Team = () => {
  const [deleteInvite] = useMutation(DELETE_INVITE, {
    update(cache, _result, { variables: { id } }) {
      const { getWorkspaceInvites } = cache.readQuery({
        query: GET_WORKSPACE_INVITES
      })
      cache.writeQuery({
        query: GET_WORKSPACE_INVITES,
        data: {
          getWorkspaceInvites: getWorkspaceInvites.filter(invite => invite.id !== id)
        }
      })
    }
  })
  const [limitWarning, setLimitWarning] = useState(null)
  const context = useContext(MyContext)
  // const [dropdownOpen, setDropDownOpen] = useState(false);
  // const [buttonBg, setButtonBg] = useState(false);
  // const [ closeWarning, setClosewarning ] = useState(false);

  const { data: workspaceData, loading: workspaceLoading } = useQuery(GET_WORKSPACE)

  const {
    data: usersData,
    loading: usersLoading,
    startPolling: usersStartPolling,
    stopPolling: usersStopPolling,
    usersError
  } = useQuery(GET_WORKSPACE_USERS)

  const { data: invitesData, loading: invitesLoading, error } = useQuery(GET_WORKSPACE_INVITES)

  const [changeRole, { error: roleError }] = useMutation(CHANGE_ROLE)

  useEffect(() => {
    usersStartPolling(2000)
    return () => {
      usersStopPolling()
    }
  }, [usersStartPolling, usersStopPolling])

  if (error || usersError) context.handleErrors(error || usersError)
  if (workspaceLoading || usersLoading || invitesLoading) return null

  const users = usersData.getWorkspaceUsers
  const invites = invitesData.getWorkspaceInvites

  const filterInvites = invites.filter(invite => !users.find(user => user.email === invite.email))
  return (
    <>
      {/* <Shadow onClick={() => setCloseWarning(false)}/>
    <DeleteUserWarning onClick={() => setCloseWarning(false)}/> */}
      {/* 
        closeWarning && (
        <Shadow onClick={() => setCloseWarning(false)}/>
        <DeleteUserWarning onClick={() => setCloseWarning(false)}/>
        )*/}
      {/* 
        closeWarning && (
        <Shadow onClick={() => setCloseWarning(false)}/>
        <DeleteAdminWarning onClick={() => setCloseWarning(false)}/>
        )*/}
      <TeamContainer>
        <section className='members__section'>
          <h6 className='members__section__title'>Miembros del equipo</h6>
          <p className='members__section__text'>
            {' '}
            {usersData.getWorkspaceUsers.length} de {workspaceData.getWorkspace.plan.usersQty} miembros
          </p>
          <div className='team-container'>
            {usersData.getWorkspaceUsers.map((user, idx) => (
              <TeamMember key={idx} user={user} idx={idx} changeRole={changeRole} />
            ))}
            {roleError && <p>{roleError.message}</p>}
            {filterInvites.map((invite, idx) => (
              <MemberInvited key={idx} invite={invite} deleteInvite={deleteInvite} />
            ))}
          </div>
        </section>

        <section className='invite__section'>
          <h6 className='invite__headline'>Invitar miembro</h6>
          <Invite setLimitWarning={setLimitWarning} />
        </section>

        {limitWarning && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <UserWarning info={limitWarning} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </TeamContainer>
    </>
  )
}

export default Team
