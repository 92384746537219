import React from 'react'
import { CodeModalContainer } from '../../style/components/auth/ErrorModal/ErrorModal.styled'
import { Spinner } from '../../style/components'

import CloseModal from '../workspace/Modals/CloseModal'

const ErrorModal = ({ loading, message, closeErrorPayment, onClick }) => {
  const dictionary = {
    'Error: Your card was declined.':
      'Lo sentimos, la tarjeta que intentas registrar fue declinada. Revisa que todo esté en orden con tu institución financiera o intenta registrando otra tarjeta.',
    "Error: Your card's security code is incorrect.":
      'Lo sentimos, el código de seguridad de la tarjeta es incorrecto. Revisa que lo hayas registrado correctamente o intenta registrando otra tarjeta.',
    'Error: Your card has insufficient funds.':
      'Lo sentimos, los fondos de la tarjeta son insuficientes. Revisa que todo esté en orden con tu institución financiera o  intenta registrando otra tarjeta.',
    'Error: Your card has expired.':
      'Lo sentimos, la tarjeta que intentas registrar está expirada. Por favor intenta registrando otra tarjeta.',
    'Error: An error occurred while processing your card. Try again in a little bit.':
      'Lo sentimos, ocurrió un error inesperado. Por favor intenta hacer tu registro mas tarde.'
  }

  if (loading)
    return (
      <CodeModalContainer>
        <Spinner />
      </CodeModalContainer>
    )

  return (
    <CodeModalContainer>
      <CloseModal onClick={onClick} />
      <h3>Ocurrió un error</h3>
      <p>{dictionary[message] ? dictionary[message] : message}</p>
      <button onClick={() => closeErrorPayment()} className='button-active'>
        Intentar nuevamente
      </button>
    </CodeModalContainer>
  )
}

export default ErrorModal
