import styled from 'styled-components'
import { colors } from '../../variables'

export const CreateBoardContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 800px;
  padding: 50px;
  border-radius: 4px;
  background: ${colors.white};
`
export const CreateBoardBox = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border-radius: 4px;
  background: ${colors.white};
  h3 {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    b {
      font-size: 16px;
    }
  }
  .onboarding__close {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .form {
    display: flex;
    flex-direction: column;
    width: 100%;
    label {
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 400;
    }
    .input-button {
      display: flex;
      height: 50px;
      margin-bottom: 30px;
      input {
        width: 100%;
        padding-left: 10px;
        border: 1px solid ${colors.darkGray};
        border-radius: 4px;
        outline: none;
        background: ${colors.lightGray};
        font-size: 14px;
        font-weight: 400;
      }
      button {
        margin-left: 20px;
      }
    }
    .description-button {
      margin-bottom: 15px;
      color: ${colors.lightseaGreen};
      font-size: 14px;
      font-weight: 500;
    }
    textarea {
      width: 100%;
      height: 100px;
      padding: 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      background: ${colors.lightGray};
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 40px;
    }
    .save-buttons-container {
      display: flex;
      justify-content: flex-end;
      width: 100%;
      button {
        margin-left: 10px;
      }
    }
  }
`
