import styled from 'styled-components'
import { colors } from '../../variables'

const SearchContainer = styled.div`
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 300px;
        font-size: 22px;
        font-weight: 600;
      }
      button {
        cursor: pointer;
      }
      .edit-filters-button {
        margin-left: 20px;
      }
    }
  }
  .toolbar {
    justify-content: space-between;
    .left-toolbar {
      height: 100%;
      .section-button {
        width: 100px;
        height: 100%;
        border: none;
        outline: none;
        margin-right: 30px;
        background: transparent;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .section-button-active {
        color: ${colors.purple};
        border-bottom: 2px solid ${colors.purple};
      }
    }
  }
  .clippingMain {
    width: 100%;
    .clippingSelector {
      margin-top: 56px;
      width: 238px;
    }
  }
`

export default SearchContainer
