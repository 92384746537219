import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { PasswordContainer, PasswordForm } from '../../style/pages/auth/Password.styled'
import { colors } from '../../style/variables'
import LoginBack from '../../assets/LoginBack.svg'
import Linkedin from '../../assets/icons/blinkedin.svg'
import Instagram from '../../assets/icons/binstagram.svg'
import Facebook from '../../assets/icons/bfacebook.svg'
import Twitter from '../../assets/icons/btwitter.svg'

import { CHANGE_USER_PASSWORD } from '../../services/mutations'

const Password = props => {
  const [recoverPassword] = useMutation(CHANGE_USER_PASSWORD)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('La contraseña es obligatoria')
        .min(6, 'La contraseña debe ser de al menos 6 caracteres'),
      confirmation: Yup.string()
        .required('Debes confirmar tu contraseña')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    }),
    onSubmit: async values => {
      const code = props.location.pathname.replace('/recover/', '')
      const { password } = values

      try {
        const { data } = await recoverPassword({
          variables: {
            input: {
              code,
              password
            }
          }
        })

        setMessage(data.recoverPassword)
        setTimeout(() => {
          setMessage(null)
          props.history.push('/login')
        }, 3000)
      } catch (error) {
        setMessage(error.message)
        setTimeout(() => {
          setMessage(null)
        }, 3000)
      }
    }
  })

  const [message, setMessage] = useState(null)

  return (
    <PasswordContainer>
      <section className='app-welcome'>
        {/* <h1>Recupera tu contraseña</h1> */}
        <div className='copy-login'>
          <h1>Bienvenido a MyOblek</h1>
          <p>Ahora podrás tener el poder de la información a tu alcance.</p>
          <div>
            <a href='https://www.linkedin.com/company/myoblek/about/' target='_blank' rel='noopener noreferrer'>
              <img src={Linkedin} alt='Linkedin icon' />
            </a>
            <a href='https://www.instagram.com/myoblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Instagram} alt='Instagram icon' />
            </a>
            <a href='https://www.facebook.com/MyOblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Facebook} alt='Facebook icon' />
            </a>
            <a href='https://twitter.com/myoblek' target='_blank' rel='noopener noreferrer'>
              <img src={Twitter} alt='Twitter icon' />
            </a>
          </div>
        </div>
      </section>
      <section className='form-newpassword'>
        <img className='img-newpassword' src={LoginBack} alt='trama' />
        <PasswordForm onSubmit={formik.handleSubmit}>
          <h2>Nueva contraseña</h2>
          <p>Para cambiar tu contraseña, escribe la nueva contraseña debajo.</p>

          <label htmlFor='password'>Contraseña</label>
          <input
            id='password'
            type='password'
            placeholder='Escribe tu nueva contraseña'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className='form-error'>
            {formik.touched.password && formik.errors.password ? <p>{formik.errors.password}</p> : null}
          </div>

          <label htmlFor='confirmation'>Confirmación</label>
          <input
            id='confirmation'
            type='password'
            placeholder='Verifica tu nueva contraseña'
            value={formik.values.confirmation}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className='form-error'>
            {formik.touched.confirmation && formik.errors.confirmation ? <p>{formik.errors.confirmation}</p> : null}
          </div>

          <div className='form-error relative'>{message && <p>{message}</p>}</div>

          <input
            className='submit-button'
            type='submit'
            value='Enviar'
            disabled={
              formik.values.password &&
              formik.values.confirmation &&
              !formik.errors.password &&
              !formik.errors.confirmation
                ? false
                : true
            }
            style={
              formik.values.password &&
              formik.values.confirmation &&
              !formik.errors.password &&
              !formik.errors.confirmation
                ? { background: `${colors.richBlack}`, color: '#FFFFFF' }
                : { background: '#E7E8EA' }
            }
          />
        </PasswordForm>
      </section>
    </PasswordContainer>
  )
}

export default Password
