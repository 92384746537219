import styled from 'styled-components'
import { colors } from '../../../variables'

const AllSearchesContainer = styled.div`
  padding-left: ${props => (props.filtersOpen || props.ordersOpen ? '400px' : '0px')};
  padding-top: 30px;
  .searches-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px 0px 7.5px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      padding-left: 2%;
      &.number {
        width: 7%;
      }
      &.name {
        width: 28%;
      }
      &.source {
        width: 20%;
      }
      &.date {
        width: 13%;
      }
      &.creator {
        width: 17%;
      }
      &.options {
        width: 15%;
      }
    }
  }
  .searches-table-body {
    height: calc(100vh - 190px);
    overflow-y: scroll;
    padding: 7.5px 20px 7.5px 7.5px;
  }
`

export default AllSearchesContainer
