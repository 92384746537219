import React, { useState } from 'react'
import {
  TwitterSearchContainer,
  ChangeContainer
} from '../../../style/components/search/new-serches/TwitterSearch.styled'
import moment from 'moment'
import { Shadow, Spinner } from '../../../style/components'
import { AiOutlineCalendar } from 'react-icons/ai'
import TwitterSearchBar from './TwitterSearchBar'
import Tweet from './Tweet'
import ChangeSearch from './ChangeSearch'
import SaveSearch from './SaveSearch'
import { MODIFY_ONBOARDING } from '../../../services/mutations'
import { GET_USER } from '../../../services/queries'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import Button from '../../ui/Button'

const TwitterSearch = props => {
  const {
    twOrParams,
    twAndParams,
    twNotParams,
    twOrWord,
    twAndWord,
    twNotWord,
    setStartDate,
    setEndDate,
    startDate,
    endDate,
    handleTwitterOrInput,
    handleTwitterAndInput,
    handleTwitterNotInput,
    addTwitterOrWord,
    addTwitterAndWord,
    addTwitterNotWord,
    deleteTwitterOrParam,
    deleteTwitterAndParam,
    deleteTwitterNotParam,
    setFrom,
    setTo,
    setHashtag,
    makeTwitterSearch,
    data,
    loading,
    qtyData,
    qtyLoading,
    error,
    query,
    media,
    fetchMoreData,
    loadingTweets,
    user
  } = props

  const history = useHistory()

  const [showChangeSearch, setShowChangeSearch] = useState(false)
  const [showSaveSearch, setShowSaveSearch] = useState(false)

  const [startDateInput, setStartDateInput] = useState(startDate)
  const [endDateInput, setEndDateInput] = useState(endDate)
  const [verifiedDates, setVerifiedDate] = useState(true)

  const [onboarding] = useMutation(MODIFY_ONBOARDING, {
    update(cache, { data: { modifyOnboarding } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: modifyOnboarding
        }
      })
    }
  })

  const cancel = () => {
    setShowChangeSearch(false)
  }

  const changeIsCollapsed = () => (showChangeSearch ? 'change-search-not-collapsed' : 'change-search-collpased')

  const verifyDates = (name, value) => {
    if (name === 'startDate') {
      setStartDateInput(value)
      if (moment(value).isBefore(endDateInput) && moment(endDateInput).isBetween(value, moment(Date.now()))) {
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    } else if (name === 'endDate') {
      setEndDateInput(value)
      if (moment(startDateInput).isBefore(value) && moment(value).isBetween(startDateInput, moment(Date.now()))) {
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    }
  }

  const submitDateSearch = () => {
    if (
      moment(startDateInput).isBefore(endDateInput) &&
      moment(endDateInput).isBetween(startDateInput, moment(Date.now()))
    ) {
      setStartDate(startDateInput)
      setEndDate(endDateInput)
    }
  }

  return (
    <TwitterSearchContainer>
      <div className='twitter-search-bar'>
        <TwitterSearchBar
          disabled
          width='calc(100% - 370px)'
          name='twOrWord'
          queryParams={twOrParams}
          inputWord={twOrWord}
          handleInput={handleTwitterOrInput}
          addParam={addTwitterOrWord}
          deleteParam={deleteTwitterOrParam}
          setFrom={setFrom}
          setTo={setTo}
          setHashtag={setHashtag}
          placeholder='@cuentas, #hashtags y/o palabras'
        />
        <Button
          size='medium'
          className='twitter-search-button'
          disabled={twOrParams.length > 0 ? false : true}
          theme={'secondary'}
          onClick={() => setShowSaveSearch(true)}
        >
          Guardar búsqueda
        </Button>
        <Button
          size='small'
          variant='outline'
          theme={'secondary'}
          className='modified-search-button'
          onClick={() => setShowChangeSearch(true)}
        >
          Modificar
        </Button>
      </div>

      {(loading || qtyLoading || loadingTweets) && <Spinner />}

      {error && (
        <div className='no-results'>
          <p>Hubo un problema</p>
          <Button weight={500} variant={'link'} onClick={() => setShowChangeSearch(true)}>
            Modifica tu búsqueda
          </Button>
        </div>
      )}

      {data && data.tweets && qtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-tweets'>
              {qtyData.tweetsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>
            <div className='results-period'>
              <AiOutlineCalendar />
              <p>Periodo de búsqueda:</p>
              <input
                type='date'
                name='startDate'
                className='date-input'
                max={endDateInput}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
                value={startDateInput}
              />
              a
              <input
                type='date'
                name='endDate'
                className='date-input'
                min={startDateInput}
                max={moment(Date.now()).add(1, 'days').format('YYYY-MM-DD')}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
                value={endDateInput}
              />
              <Button
                width={'auto'}
                height={'auto'}
                theme={'secondary'}
                variant='outline'
                className='date-button'
                disabled={!verifiedDates}
                onClick={verifiedDates && submitDateSearch}
              >
                Buscar
              </Button>
            </div>
          </div>

          {data.tweets.length === 0 ? (
            <div className='no-results'>
              <p>Tu búsqueda no ha generado resultados</p>
              <Button
                size={'full'}
                height={'fit-content'}
                weight={500}
                variant={'link'}
                onClick={() => setShowChangeSearch(true)}
              >
                Modifica tu búsqueda
              </Button>
            </div>
          ) : (
            <div className='twitter-search-results' onScroll={fetchMoreData}>
              {data.tweets.map((tweet, idx) => (
                <Tweet key={idx} tweet={tweet} />
              ))}
            </div>
          )}
        </>
      )}

      {showChangeSearch && <Shadow onClick={() => setShowChangeSearch(false)} />}
      <ChangeContainer id='change' className={changeIsCollapsed()}>
        <ChangeSearch
          closeChangeSearch={() => setShowChangeSearch(false)}
          cancelChanges={cancel}
          twOrParams={twOrParams}
          twAndParams={twAndParams}
          twNotParams={twNotParams}
          twOrWord={twOrWord}
          twAndWord={twAndWord}
          twNotWord={twNotWord}
          handleTwitterOrInput={handleTwitterOrInput}
          handleTwitterAndInput={handleTwitterAndInput}
          handleTwitterNotInput={handleTwitterNotInput}
          addTwitterOrWord={addTwitterOrWord}
          addTwitterAndWord={addTwitterAndWord}
          addTwitterNotWord={addTwitterNotWord}
          deleteTwitterOrParam={deleteTwitterOrParam}
          deleteTwitterAndParam={deleteTwitterAndParam}
          deleteTwitterNotParam={deleteTwitterNotParam}
          setFrom={setFrom}
          setTo={setTo}
          setHashtag={setHashtag}
          makeTwitterSearch={makeTwitterSearch}
        />
      </ChangeContainer>

      {showSaveSearch && (
        <>
          <Shadow
            onClick={async () => {
              await onboarding({ variables: { flag: false } })
              setShowSaveSearch(false)
              history.push('/searches')
            }}
          />
          <SaveSearch closeSaveSearch={() => setShowSaveSearch(false)} query={query} media={media} userInfo={user} />
        </>
      )}
    </TwitterSearchContainer>
  )
}

export default TwitterSearch
