import React, { useState } from 'react'
import StripeContainer from '../../style/pages/auth/Signup.styled'
import { useMutation, useLazyQuery } from '@apollo/client'
import { monthlyProducts, yearlyProducts } from '../../data/Plans'

import Indicators from '../../components/auth/Indicators'
import UserData from '../../components/auth/UserData'
import PlanSelection from '../../components/auth/PlanSelection'
import BillInfo from '../../components/auth/BillInfo'
import PaymentForm from '../../components/auth/PaymentForm'
import Invoice from '../../components/auth/Invoice'
import CodeModal from '../../components/auth/CodeModal'
import { Shadow } from '../../style/components'
import ErrorModal from '../../components/auth/ErrorModal'

import {
  CREATE_STRIPE_CUSTOMER,
  CREATE_STRIPE_SUBSCRIPTION,
  CREATE_WORKSPACE,
  GET_ADRESS_MUTATION
} from '../../services/mutations'

import { VALID_CODE_QUERY } from '../../services/queries'
import { EVENTS, sendGoogleAnalyticsEvent, setUserProperties } from '../../services/analytics'

const Stripe = () => {
  const [step, setStep] = useState(1)
  const [loading, setLoading] = useState(false)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(null)
  const [workspace, setWorkspace] = useState('')
  const [workspaceError, setWorkspaceError] = useState(null)
  const [email, setEmail] = useState('')
  const [emailError, setEmailError] = useState(null)
  const [emailConfirmation, setEmailConfirmation] = useState('')
  const [emailConfirmationError, setEmailConfirmationError] = useState(null)
  const [password, setPassword] = useState('')
  const [passwordError, setPasswordError] = useState(null)
  const [passwordConfirmation, setPasswordConfirmation] = useState('')
  const [passwordConfirmationError, setPasswordConfirmationError] = useState(null)
  const [age, setAge] = useState('')
  const [ageError, setAgeError] = useState(null)
  const [degree, setDegree] = useState('')
  const [degreeError, setDegreeError] = useState(null)
  const [occupation, setOccupation] = useState('')
  const [occupationError, setOccupationError] = useState(null)
  const [gender, setGender] = useState('')
  const [genderError, setGenderError] = useState(null)

  const handleEmailConfirmation = () => {
    if (email === emailConfirmation) {
      setEmailConfirmationError(null)
    } else {
      setEmailConfirmationError('Los correos no coinciden')
    }
  }

  const handlePasswordConfirmation = () => {
    if (password === passwordConfirmation) {
      setPasswordConfirmationError(null)
    } else {
      setPasswordConfirmationError('Las contraseñas no coinciden')
    }
  }

  const user = {
    name,
    setName,
    nameError,
    setNameError,
    workspace,
    setWorkspace,
    workspaceError,
    setWorkspaceError,
    email,
    setEmail,
    emailError,
    setEmailError,
    emailConfirmation,
    setEmailConfirmation,
    emailConfirmationError,
    setEmailConfirmationError,
    handleEmailConfirmation,
    password,
    setPassword,
    passwordError,
    setPasswordError,
    passwordConfirmation,
    setPasswordConfirmation,
    passwordConfirmationError,
    setPasswordConfirmationError,
    handlePasswordConfirmation,
    age,
    setAge,
    ageError,
    setAgeError,
    degree,
    setDegree,
    degreeError,
    setDegreeError,
    occupation,
    setOccupation,
    occupationError,
    setOccupationError,
    gender,
    setGender,
    genderError,
    setGenderError
  }

  const userValidation = () => {
    if (
      name === '' ||
      nameError ||
      workspace === '' ||
      workspaceError ||
      email === '' ||
      emailError ||
      emailConfirmation === '' ||
      emailConfirmationError ||
      password === '' ||
      passwordError ||
      passwordConfirmation === '' ||
      passwordConfirmationError
    )
      return true
    return false
  }

  const userValidationStep2 = () => {
    if (
      age === '' ||
      ageError ||
      degree === '' ||
      degreeError ||
      occupation === '' ||
      occupationError ||
      gender === '' ||
      genderError
    )
      return true
    return false
  }

  const [customer, setCustomer] = useState(null)
  const [subscription, setSubscription] = useState(null)
  const [monthlyPlans, setMonthlyPlans] = useState(true)
  const [productSelected, setProductSelected] = useState(monthlyProducts[0])
  const [billRequired, setBillRequired] = useState(true)
  const [billName, setBillName] = useState('')
  const [billNameError, setBillNameError] = useState(null)
  const [rfc, setRfc] = useState('')
  const [rfcError, setRfcError] = useState(null)
  const [billEmail, setBillEmail] = useState('')
  const [billEmailError, setBillEmailError] = useState(null)
  const [street, setStreet] = useState('')
  const [streetError, setStreetError] = useState(null)
  const [exterior, setExterior] = useState('')
  const [exteriorError, setExteriorError] = useState(null)
  const [interior, setInterior] = useState('')
  const [cp, setCp] = useState('')
  const [cpError, setCpError] = useState(null)
  const [city, setCity] = useState('')
  const [alcMun, setAlcMun] = useState('')
  const [colonia, setColonia] = useState('')
  const [colArr, setColArr] = useState(['Colonia *'])

  const bill = {
    billName,
    setBillName,
    billNameError,
    setBillNameError,
    rfc,
    setRfc,
    rfcError,
    setRfcError,
    billEmail,
    setBillEmail,
    billEmailError,
    setBillEmailError,
    street,
    setStreet,
    streetError,
    setStreetError,
    exterior,
    setExterior,
    exteriorError,
    setExteriorError,
    interior,
    setInterior,
    cp,
    setCp,
    cpError,
    setCpError,
    city,
    setCity,
    alcMun,
    setAlcMun,
    colonia,
    setColonia,
    colArr,
    setColArr
  }

  const findAdress = async () => {
    if (cp.length === 5) {
      try {
        const {
          data: { getAdress }
        } = await adress({ variables: { cp } })
        setCity(getAdress.estado)
        setAlcMun(getAdress.municipio)
        setColArr(['Colonia *', ...getAdress.asentamiento])
      } catch (error) {
        setCpError('CP inválido')
      }
    }
    if (cp.length < 5) {
      setCity('')
      setAlcMun('')
      setColonia('')
      setColArr(['Colonia *'])
    }
  }

  const billValidation = () => {
    if (
      billName === '' ||
      billNameError ||
      rfc === '' ||
      rfcError ||
      billEmail === '' ||
      billEmailError ||
      street === '' ||
      streetError ||
      exterior === '' ||
      exteriorError ||
      cp === '' ||
      cp.length < 5 ||
      cpError ||
      city === '' ||
      alcMun === '' ||
      colonia === ''
    )
      return false
    return true
  }

  const [errorMessage, setErrorMessage] = useState(null)

  const [showCodeModal, setShowCodeModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [code, setCode] = useState('')
  const [validCode, setValidCode] = useState(false)
  const [promotion, setPromotion] = useState(null)
  const [codeError, setCodeError] = useState(false)

  const [isValidCode, { loading: codeLoading }] = useLazyQuery(VALID_CODE_QUERY, {
    onCompleted: ({ validCode }) => {
      setValidCode(true)
      setPromotion(validCode)
    },
    onError: () => {
      setCodeError(true)
    }
  })

  const handleCodeValidation = () => {
    isValidCode({ variables: { code } })
  }

  const closeError = () => {
    setCode('')
    setValidCode(false)
    setPromotion(null)
    setCodeError(false)
    setShowCodeModal(false)
  }

  const closeErrorPaymentModal = () => {
    setShowErrorModal(false)
  }

  const closeCodeModal = () => {
    if (validCode) {
      setCode('')
      setShowCodeModal(false)
    } else {
      setCode('')
      setValidCode(false)
      setPromotion(null)
      setCodeError(false)
      setShowCodeModal(false)
    }
  }

  const [createStripeCustomer] = useMutation(CREATE_STRIPE_CUSTOMER)
  const [createStripeSubscription] = useMutation(CREATE_STRIPE_SUBSCRIPTION)
  const [createWorkspace, { loading: workspaceLoading }] = useMutation(CREATE_WORKSPACE)
  const [adress] = useMutation(GET_ADRESS_MUTATION)

  const handleCreateCustomer = async () => {
    setLoading(true)
    try {
      const { data } = await createStripeCustomer({
        variables: {
          input: {
            email,
            workspace
          }
        }
      })
      setCustomer(JSON.parse(data.createStripeCustomer))
      setLoading(false)
      setStep(2)
    } catch (error) {
      setLoading(false)
      if (error.message.includes('espacio')) setWorkspaceError('El nombre del espacio de trabajo no está disponible')
      if (error.message.includes('correo')) setEmailError('El correo no está disponible')
    }
  }

  const handleCreateSubscription = async (customerId, paymentMethodId, priceId, validCode) => {
    try {
      if (promotion !== null) {
        const { data } = await createStripeSubscription({
          variables: {
            input: {
              customerId,
              paymentMethodId,
              priceId,
              monthlyPlans,
              validCode,
              promotion: {
                value: promotion.value,
                benefit: promotion.benefit,
                duration: {
                  value: promotion.duration.value,
                  unit: promotion.duration.unit
                }
              }
            }
          }
        })
        const subscription = JSON.parse(data.createStripeSubscription)
        setSubscription(subscription)
        return subscription
      } else {
        const { data } = await createStripeSubscription({
          variables: {
            input: {
              customerId,
              paymentMethodId,
              priceId,
              monthlyPlans,
              validCode
            }
          }
        })
        const subscription = JSON.parse(data.createStripeSubscription)
        setSubscription(subscription)
        return subscription
      }
    } catch (error) {
      const { message } = error
      setErrorMessage(message)
      return {
        status: 'error',
        message
      }
    }
  }

  const handleCreateWorkspace = async (plan, subscriptionId) => {
    const { data } = await createWorkspace({
      variables: {
        input: {
          monthlyPlans,
          plan,
          name,
          email,
          password,
          workspace,
          billName,
          rfc,
          billEmail,
          street,
          exterior,
          interior,
          cp,
          state: city,
          alcMun,
          colonia,
          subscriptionId,
          customerId: customer.id,
          required: billRequired,
          age: parseInt(age),
          degree,
          occupation,
          gender
        }
      }
    })

    const { token } = data.createWorkspace
    localStorage.setItem('token', token)
  }

  return (
    <StripeContainer>
      <Indicators step={step} setStep={setStep} productSelected={productSelected} />

      {step === 1 && (
        <UserData
          user={user}
          userValidation={userValidation}
          handleCreateCustomer={handleCreateCustomer}
          loading={loading}
          userValidationStep2={userValidationStep2}
        />
      )}

      {step === 2 && (
        <PlanSelection
          products={monthlyPlans ? monthlyProducts : yearlyProducts}
          productSelected={productSelected}
          setProductSelected={setProductSelected}
          monthlyPlans={monthlyPlans}
          setMonthlyPlans={setMonthlyPlans}
          setStep={setStep}
          handleCreateWorkspace={handleCreateWorkspace}
          loading={workspaceLoading}
        />
      )}

      {step === 3 && (
        <BillInfo
          billRequired={billRequired}
          setBillRequired={setBillRequired}
          bill={bill}
          findAdress={findAdress}
          billValidation={billValidation}
          setStep={setStep}
        />
      )}

      {step === 4 && (
        <PaymentForm
          productSelected={productSelected}
          customer={customer}
          handleCreateSubscription={handleCreateSubscription}
          handleCreateWorkspace={handleCreateWorkspace}
          setStep={setStep}
          showCodeModal={setShowCodeModal}
          validCode={validCode}
          showErrorModal={setShowErrorModal}
        />
      )}

      {step === 5 && <Invoice productSelected={productSelected} subscription={subscription} validCode={validCode} />}

      {showCodeModal && (
        <>
          <Shadow onClick={() => closeCodeModal()} />
          <CodeModal
            code={code}
            handleCode={setCode}
            handleCodeValidation={handleCodeValidation}
            codeError={codeError}
            closeError={closeError}
            loading={codeLoading}
            validCode={validCode}
            promotion={promotion}
            closeModal={closeCodeModal}
          />
        </>
      )}

      {showErrorModal && (
        <>
          <Shadow onClick={() => setShowErrorModal(false)} />
          <ErrorModal
            loading={false}
            message={errorMessage}
            closeErrorPayment={closeErrorPaymentModal}
            onClick={closeErrorPaymentModal}
          />
        </>
      )}
    </StripeContainer>
  )
}

export default Stripe
