import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const MediaFilter = styled.div`
  width: 400px;
  height: 100vh;
  background: ${colors.background};
  border-right: 1px solid ${colors.lightGray};
  position: absolute;
  top: 0;
  left: ${props => (props.sidebar ? '68px' : '222px')};
  transition: all 0.25s linear;
  padding: 150px 2.5%;
  .filters--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      font-size: 24px;
      font-weight: 500;
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      path {
        stroke: ${colors.darkGray};
      }
    }
  }
  .filters--content {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      margin-bottom: 10px;
    }
    input {
      height: 47px;
      border-radius: 4px;
      border: 1px solid ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      padding: 10px;
      margin-bottom: 15px;
    }
    .scope {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 10px;
    }
    .states-dropdown {
      margin-bottom: 20px;
    }
    .subtype {
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      flex-wrap: wrap;
      margin-bottom: 10px;
      .option {
        margin-right: 10px;
        width: 95px;
      }
    }
    .status {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin-bottom: 10px;
      .option {
        margin-right: 10px;
        width: 95px;
      }
    }
    .reset {
      display: flex;
      justify-content: flex-end;
    }
    .option {
      display: flex;
      align-items: center;
      cursor: pointer;
      margin: 0 0 10px 0;
      input[type='checkbox'] {
        height: 20px;
        margin: 0 5px 0 0;
      }
    }
  }
`
