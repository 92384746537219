import React, { Component } from 'react'
import { WidgetsMenuContainer, WidgetButton } from '../../../style/components/dashboard/dashboard/WidgetsMenu.styled'
import { GrClose } from 'react-icons/gr'
import {
  TonalidadMedios,
  TonalidadMediosActive,
  NumeroNotas,
  NumeroNotasActive,
  MediosGanados,
  MediosGanadosActive,
  TipoMedios,
  TipoMediosActive,
  TemasMomento,
  TemasMomentoActive,
  MapaCalor,
  MapaCalorActive,
  ExposicionMedios,
  ExposicionMediosActive,
  ShareVoice,
  ShareVoiceActive,
  TonalidadTwitter,
  TonalidadTwitterActive,
  NubeHashtags,
  NubeHashtagsActive,
  InfluencersTwitter,
  InfluencersTwitterActive,
  MencionesTwitter,
  MencionesTwitterActive,
  PublicadoresVolumen,
  PublicadoresVolumenActive
} from '../../../assets/Widgets'

class WidgetIcon extends Component {
  components = {
    TonalidadMedios: TonalidadMedios,
    NumeroNotas: NumeroNotas,
    MediosGanados: MediosGanados,
    TipoMedios: TipoMedios,
    TemasMomento: TemasMomento,
    MapaCalor: MapaCalor,
    ExposicionMedios: ExposicionMedios,
    ShareVoice: ShareVoice,
    TonalidadTwitter: TonalidadTwitter,
    NubeHashtags: NubeHashtags,
    InfluencersTwitter: InfluencersTwitter,
    MencionesTwitter: MencionesTwitter,
    PublicadoresVolumen: PublicadoresVolumen
  }
  render() {
    const Component = this.components[this.props.component]
    return <Component />
  }
}
class WidgetIconActive extends Component {
  components = {
    TonalidadMedios: TonalidadMediosActive,
    NumeroNotas: NumeroNotasActive,
    MediosGanados: MediosGanadosActive,
    TipoMedios: TipoMediosActive,
    TemasMomento: TemasMomentoActive,
    MapaCalor: MapaCalorActive,
    ExposicionMedios: ExposicionMediosActive,
    ShareVoice: ShareVoiceActive,
    TonalidadTwitter: TonalidadTwitterActive,
    NubeHashtags: NubeHashtagsActive,
    InfluencersTwitter: InfluencersTwitterActive,
    MencionesTwitter: MencionesTwitterActive,
    PublicadoresVolumen: PublicadoresVolumenActive
  }
  render() {
    const Component = this.components[this.props.component]
    return <Component width='50px' />
  }
}

const WidgetsMenu = ({ widgets, closeWidgetsMenu, selectWidget, showWidgetDetail, selectedWidget }) => {
  return (
    <WidgetsMenuContainer>
      <div className='widgets-menu--header'>
        <h4>Gráficos</h4>
        {!showWidgetDetail && (
          <button onClick={() => closeWidgetsMenu()}>
            <GrClose />
          </button>
        )}
      </div>
      <p className='widgets-menu--description'>
        Los <span>gráficos</span> son herramientas que te permiten visualizar los datos de tus <span>búsquedas</span> de
        manera estratégica. Explora dentro de todas las posibilidades y elige los que más te convengan.
      </p>
      <section className='widgets-menu--content'>
        <p className='titulo-seccion'>
          <strong>Medios </strong>Gráficos para medios tradicionales y digitales
        </p>

        {selectedWidget &&
          widgets
            .filter(widget => widget.type === 'Medios')
            .map(widget => (
              <WidgetButton key={widget.id} onClick={() => selectWidget(widget)}>
                {widget.id !== selectedWidget.id ? (
                  <WidgetIcon component={widget.componentName} />
                ) : (
                  <WidgetIconActive component={widget.componentName} />
                )}
                <p>{widget.displayName}</p>
              </WidgetButton>
            ))}

        {!selectedWidget &&
          widgets
            .filter(widget => widget.type === 'Medios')
            .map(widget => (
              <WidgetButton key={widget.id} onClick={() => selectWidget(widget)}>
                <WidgetIcon component={widget.componentName} />

                <p>{widget.displayName}</p>
              </WidgetButton>
            ))}

        <p className='titulo-seccion'>
          <strong>Redes </strong>Gráficos para redes sociales (Twitter)
        </p>

        {selectedWidget &&
          widgets
            .filter(widget => widget.type === 'Twitter')
            .map(widget => (
              <WidgetButton key={widget.id} onClick={() => selectWidget(widget)}>
                {widget.id !== selectedWidget.id ? (
                  <WidgetIcon component={widget.componentName} />
                ) : (
                  <WidgetIconActive component={widget.componentName} />
                )}
                <p>{widget.displayName}</p>
              </WidgetButton>
            ))}

        {!selectedWidget &&
          widgets
            .filter(widget => widget.type === 'Twitter')
            .map(widget => (
              <WidgetButton key={widget.id} onClick={() => selectWidget(widget)}>
                <WidgetIcon component={widget.componentName} />

                <p>{widget.displayName}</p>
              </WidgetButton>
            ))}
      </section>
    </WidgetsMenuContainer>
  )
}

export default WidgetsMenu
