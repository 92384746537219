import styled from 'styled-components'
import { colors } from '../../variables'

export const UserContainer = styled.div`
  position: absolute;
  top: 15px;
  right: 2.5%;
  z-index: 1;
  cursor: pointer;
  &:disabled {
    cursor: not-allowed;
  }
  img {
    width: 40px;
    height: 40px;
    border-radius: 50%;
  }
  @media (max-width: 580px) {
    position: absolute;
    top: 15px;
    right: 4%;
    z-index: 1;
    img {
      width: 26px;
      height: 26px;
      border-radius: 50%;
    }
  }
`

export const ProfileMenu = styled.div`
  z-index: 1;
  position: absolute;
  display: flex;
  top: 60px;
  right: 10px;
  width: 205px;
  height: 55px;
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 6px 10px 0 rgba(0, 0, 0, 0.15);
`
