import React, { useState, useRef, useEffect } from 'react'
import {
  ExposicionMediosContainer,
  ChartContainer,
  TooltipText,
  Placeholder
} from '../../style/components/widgets/ExposicionMedios.styled'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { ButtonSpinner } from '../../style/components'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import { EXPOSICION_QUERY } from '../../services/queries'

const lineColors = ['#80DEEA', '#EF9A9A', '#9FA8DA', '#42A5F5', '#FFCC80']

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  const [width, setWidth] = useState()

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const info = JSON.parse(data.exposicion)

  const tooltipContent = ({ active, label, payload }) => {
    if (active) {
      return (
        <TooltipText>
          <p>{label}</p>
          <p style={{ color: payload[0].stroke }}>
            {payload[0].dataKey} : {payload[0].value.toFixed(2)}
          </p>
          <p style={{ color: payload[1].stroke }}>
            {payload[1].dataKey}:: {payload[1].value.toFixed(2)}
          </p>
        </TooltipText>
      )
    }
  }

  const lines = []
  for (const key in info[0]) {
    if (key !== 'name') lines.push(key)
  }

  return (
    <ChartContainer ref={container}>
      <AreaChart width={width} height={300} data={info}>
        <defs>
          {lines.map((_, idx) => (
            <linearGradient key={idx} id={`gradient${idx}`} x1='0' y1='0' x2='0' y2='1'>
              <stop offset='5%' stopColor={lineColors[idx]} stopOpacity={0.75} />
              <stop offset='95%' stopColor={lineColors[idx]} stopOpacity={0} />
            </linearGradient>
          ))}
        </defs>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis width={70} />
        <Tooltip content={tooltipContent} />
        <Legend layout='vertical' align='right' verticalAlign='middle' iconType='square' />
        {lines.map((line, idx) => (
          <Area
            key={idx}
            type='monotone'
            dataKey={line}
            stroke={lineColors[idx]}
            fillOpacity={1}
            fill={`url(#${'gradient' + idx})`}
          />
        ))}
      </AreaChart>
    </ChartContainer>
  )
}

const ExposicionMedios = ({ widget, report, date }) => {
  const searchID = widget.searches.length > 0 ? widget.searches[0].id : ''
  const [period, setPeriod] = useState('week')

  const { data, loading, error } = useQuery(EXPOSICION_QUERY, {
    variables: {
      period,
      searchID,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }
    return (
      <ExposicionMediosContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>

        <Chart data={data} loading={loading} error={error} />
      </ExposicionMediosContainer>
    )
  } else {
    return (
      <ExposicionMediosContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>

        <Chart data={data} loading={loading} error={error} />

        <div className='widget--period'>
          {period === 'week' && <p>Periodo en días</p>}
          {period === 'month' && <p>Periodo en semanas</p>}
          {period === 'year' && <p>Periodo en meses</p>}
        </div>
      </ExposicionMediosContainer>
    )
  }
}

export default ExposicionMedios
