import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import SearchCardContainer from '../../style/components/workspace/SearchCard.styled'
import { BsThreeDots, BsTrash } from 'react-icons/bs'
import { Internet } from '../../assets/Media'
import { FiTwitter } from 'react-icons/fi'
// import IconEdit from "../../assets/icons/IconEdit";

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const SearchCard = ({ search, setSearchToDelete }) => {
  const history = useHistory()
  const searchOptions = useRef()

  const [showSearchMenu, setShowSearchMenu] = useState(false)

  useOutsideClick(searchOptions, () => setShowSearchMenu(false))

  let name = search.name
  // Conditional that limits the title given to the search.
  // if (name.split("").length > 35) {
  //   name = name.split("").slice(0, 35).join("") + "...";
  // }
  let date = new Date(Number(search.updatedAt))
  date = date.toString().split('').slice(4, 15).join('')

  return (
    <SearchCardContainer>
      <div className='search-menu' ref={searchOptions}>
        <button onClick={() => setShowSearchMenu(!showSearchMenu)}>
          <BsThreeDots />
        </button>
        {showSearchMenu && (
          <div className='search-options'>
            {/* <button className="edit__option">
              <IconEdit stroke="#748596" fill="#748596"/>
              Editar búsqueda
            </button> */}
            <button className='delete__option' onClick={() => setSearchToDelete(search)}>
              <BsTrash />
              Eliminar
            </button>
          </div>
        )}
      </div>
      <div className='card-content' onClick={() => history.push(`/searches/search/${search.id}`)}>
        <div className='search-header'>
          <div className='search__header__container'>
            <h5 className='search__name'>{name}</h5>
            <p className='search__lastmodify'>Última vez modificado: {date}.</p>
          </div>
        </div>
        <div className='search-content'>
          <div className='searchby__user'>
            <img className='user__profile__img' src={search.createdBy.photo} alt='user avatar' />
            <p className='user__profile__name'>{search.createdBy.name}</p>
          </div>
          <div className='search-source'>
            {search.source.includes('medios') && <Internet />}
            {search.source.includes('twitter') && <FiTwitter className='twitter-icon' />}
          </div>
        </div>
      </div>
    </SearchCardContainer>
  )
}

export default SearchCard
