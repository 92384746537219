import { useMutation } from '@apollo/client'
import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { ScheduledReportsContainer } from '../../../style/components/reports/reports/SchedueledReports.styled'
import { Shadow } from '../../../style/components'
import { DeleteModal } from './DeleteModalReport'
import { InfoModal } from './InfoModal'
import NoReports from './NoReports'
import { ReportCard } from './ReportCard'

import { DELETE_TICKET, STARTSTOP_TICKET } from '../../../services/mutations'
import { MyContext } from '../../../context'

const ScheduledReports = ({ reports, filtersOpen, filters, order, refetchTickets, refetchReports }) => {
  const context = useContext(MyContext)

  const history = useHistory()

  const [deleteTicket, { error: errorDeleteTicket }] = useMutation(DELETE_TICKET)
  const [startstopTicket, { error: errorStartStopTicket }] = useMutation(STARTSTOP_TICKET)

  if (errorDeleteTicket) context.handleErrors(errorDeleteTicket)
  if (errorStartStopTicket) context.handleErrors(errorStartStopTicket)

  const [ticketName, setTicketName] = useState('')
  const [ticketId, setTicketId] = useState('')

  const [deleteAll, setDeleteAll] = useState(true)
  const [openDelete, setOpenDelete] = useState(false)
  const [openNoDashboardWarning, setOpenNoDashboardWarning] = useState(false)
  const [openStopPlayInfo, setOpenStopPlayInfo] = useState(false)
  const [pausedStatus, setPausedStatus] = useState(true)

  let sorted = [...reports]

  const handleDeleteWarning = (id, name) => {
    setTicketId(id)
    setTicketName(name)
    setOpenDelete(true)
  }

  const handleDelete = async id => {
    await deleteTicket({ variables: { id, deleteAll } })
    setOpenDelete(false)
    refetchReports()
    refetchTickets()
  }

  const handleOpenDetails = (id, tab) => {
    if (tab !== null) {
      history.push(`/reports/scheduled/${id}`)
    } else {
      setOpenNoDashboardWarning(true)
    }
  }

  const handleStopPlayTicket = async (id, status, name) => {
    let order
    setTicketName(name)
    if (status) {
      // Ticket Paused
      order = 1
      await startstopTicket({ variables: { id, order } })
      setPausedStatus(true)
      setOpenStopPlayInfo(true)
    } else {
      // Ticket Start
      order = 0
      await startstopTicket({ variables: { id, order } })
      setPausedStatus(false)
      setOpenStopPlayInfo(true)
    }
  }

  if (order.AtoZ) {
    sorted = reports.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.ZtoA) {
    sorted = reports.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = -1
      } else if (nameA < nameB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.recentDate) {
    sorted = reports.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = -1
      } else if (dateA < dateB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.oldDate) {
    sorted = reports.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = 1
      } else if (dateA < dateB) {
        comparison = -1
      }
      return comparison
    })
  }
  if (reports.length === 0) return <NoReports filtersOpen={filtersOpen} />

  return (
    <ScheduledReportsContainer filtersOpen={filtersOpen}>
      <h2>Programados</h2>
      <section>
        {sorted
          .filter(report => report.name.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(report => report.createdBy.name.toLowerCase().includes(filters.user.toLowerCase()))
          .filter(report => report.schedule.includes(filters.frecuency))
          .filter(report => {
            if (filters.active === 'active') return report.active === true
            if (filters.active === 'inactive') return report.active === false
            return report
          })
          .filter(report => {
            if (filters.paused === 'paused') return report.paused === true
            if (filters.paused === 'play') return report.paused === false
            return report
          })
          .map(report => (
            <ReportCard
              key={report.id}
              report={report}
              handleDeleteWarning={handleDeleteWarning}
              handleOpenDetails={handleOpenDetails}
              handleStopPlayTicket={handleStopPlayTicket}
            />
          ))}
      </section>

      {openDelete && (
        <>
          <Shadow onClick={() => setOpenDelete(false)} />
          <DeleteModal
            reportId={ticketId}
            reportName={ticketName}
            origin={2}
            setOpenDelete={setOpenDelete}
            deleteReport={handleDelete}
            setDeleteAll={setDeleteAll}
            deleteAll={deleteAll}
          />
        </>
      )}

      {openNoDashboardWarning && (
        <>
          <Shadow onClick={() => setOpenNoDashboardWarning(false)} />
          <InfoModal setOpenInfo={setOpenNoDashboardWarning}>
            <h3>¡Advertencia!</h3>
            <p>El reporte que estas intentando consultar, ya no cuenta con un dashboard activo!!</p>
          </InfoModal>
        </>
      )}

      {openStopPlayInfo && (
        <>
          <Shadow onClick={() => setOpenStopPlayInfo(false)} />
          <InfoModal setOpenInfo={setOpenStopPlayInfo}>
            <h3>Reporte {pausedStatus ? 'iniciado' : 'pausado'} </h3>
            <p>
              El reporte <span> {ticketName} </span> se ha {pausedStatus ? 'inciado' : 'pausado'} correctamente!
            </p>
          </InfoModal>
        </>
      )}
    </ScheduledReportsContainer>
  )
}

export default ScheduledReports
