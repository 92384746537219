import React, { useState, useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'
import DashboardCardContainer from '../../style/components/workspace/DashboardCard.styled'
import { BsThreeDots, BsTrash } from 'react-icons/bs'
import { MdWidgets } from 'react-icons/md'
// import { AiOutlineUser } from "react-icons/ai";
import IconEdit from '../../assets/icons/IconEdit'
import IconUsers from '../../assets/icons/IconUsers'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const DashboardCard = ({ dashboard, setDashboardToDelete }) => {
  const history = useHistory()
  const dashboardOptions = useRef()

  const [showDashboardMenu, setShowDashboardMenu] = useState(false)

  useOutsideClick(dashboardOptions, () => setShowDashboardMenu(false))

  let date = new Date(Number(dashboard.updatedAt))
  date = date.toString().split('').slice(4, 15).join('')

  const dashboardToDelete = () => {
    setDashboardToDelete(dashboard)
    setShowDashboardMenu(false)
  }

  return (
    <DashboardCardContainer>
      <div className='dashboard-menu' ref={dashboardOptions}>
        <button onClick={() => setShowDashboardMenu(!showDashboardMenu)}>
          <BsThreeDots />
        </button>
        {showDashboardMenu && (
          <div className='dashboard-options'>
            <button
              className='edit__option'
              onClick={() => history.push({ pathname: `/dashboards/dashboard/${dashboard.id}`, search: '?editboard' })}
            >
              <IconEdit stroke='#748596' fill='#748596' />
              Editar tablero
            </button>
            <button
              className='edit__option'
              onClick={() =>
                history.push({ pathname: `/dashboards/dashboard/${dashboard.id}`, search: '?editmembers' })
              }
            >
              <IconUsers stroke='#748596' fill='#fff' />
              Editar miembros
            </button>
            <button className='delete__option' onClick={dashboardToDelete}>
              <BsTrash />
              Eliminar
            </button>
          </div>
        )}
      </div>
      <div className='card-content' onClick={() => history.push(`/dashboards/dashboard/${dashboard.id}`)}>
        <div className='dashboard-header'>
          <div className='dashboard__header__container'>
            <h5 className='board__name'>{dashboard.name}</h5>
            <p className='board__lastmodify'>Última vez modificado: {date}.</p>
          </div>
        </div>
        <div className='dashboard-content'>
          <div className='searchby__user'>
            <img className='user__profile__img' src={dashboard.createdBy.photo} alt='user avatar' />
            <p className='user__profile__name'>{dashboard.createdBy.name}</p>
          </div>
          <div className='board-info'>
            <MdWidgets />
            <p>{dashboard.widgets.length}</p>
          </div>
        </div>
      </div>
    </DashboardCardContainer>
  )
}

export default DashboardCard
