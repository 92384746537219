export const colors = {
  richBlack: '#0B0C10',
  gunMetal: '#1F2833',
  silverSand: '#C5C6C8',
  torquioseBlue: '#66FCF1',
  lightseaGreen: '#00AAAB',

  white: '#FFFFFF',
  darkGray: '#565978',
  lightGray: '#DAE1F2',
  purple: '#6773E5',
  background: '#FAFDFF',
  red: '#FE596F'
}

export const buttonColors = {
  disabled: '#EEF2FB',
  primaryOutline: {
    default: colors.lightseaGreen,
    hover: '#009091',
    press: colors.lightseaGreen
  },
  primary: {
    text: colors.richBlack,
    default: colors.torquioseBlue,
    hover: colors.lightseaGreen,
    press: '#009091'
  },
  secondary: {
    text: colors.white,
    default: colors.purple,
    hover: '#91A1F6',
    press: '#4852B5'
  },
  danger: {
    text: colors.white,
    default: colors.red,
    hover: '#FCA3A3',
    press: '#EB3049'
  },
  dark: {
    text: colors.white,
    default: colors.richBlack,
    hover: colors.darkGray,
    press: colors.gunMetal
  }
}
