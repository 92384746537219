import React, { useState, useEffect, useRef } from 'react'
import { FixedSizeList as List } from 'react-window'
import { useQuery } from '@apollo/client'
import {
  PublicadoresContainer,
  RowContainer,
  ChartContainer,
  Placeholder
} from '../../style/components/widgets/PublicadoresVolumenTwitter.styled'
import { ButtonSpinner } from '../../style/components'
import moment from 'moment'

import { PUBLICADORES_TWITTER } from '../../services/queries'

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  const [width, setWidth] = useState()

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const info = JSON.parse(data.publicadoresVolumen)
  const secondInfo = info.slice(5)

  const FirstList = ({ index, style }) => {
    //Displays users by authority
    const infoObj = info[index]
    const user = infoObj.publicador
    const userName = infoObj.user.name
    const userTweets = infoObj.user.tweets
    const [avatar, setAvatar] = useState(
      infoObj.user.image
        ? infoObj.user.image
        : 'https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg'
    )

    return (
      <>
        <RowContainer style={style} width={width} className='no-scroll' noBar={index === 4 && true}>
          <div>
            <img
              src={avatar}
              alt='avatar'
              className='image'
              onError={() =>
                setAvatar('https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg')
              }
            />
          </div>
          <div>
            <p>{userName}</p>
            <p>@{user}</p>
          </div>
          <div>
            <p>{userTweets}</p>
          </div>
        </RowContainer>
      </>
    )
  }

  const SecondList = ({ index, style }) => {
    //Display users by followers
    const infoObj = secondInfo[index]
    const user = infoObj.publicador
    const userName = infoObj.user.name
    const userTweets = infoObj.user.tweets
    // const userFollowers = infoObj.user.followers
    //used to show followers with K, not being used but might be used later. Dont know yet
    // const followersK = Math.abs(userFollowers) > 999 ? Math.sign(userFollowers)*((Math.abs(userFollowers)/1000).toFixed(1)) + 'k' : Math.sign(userFollowers)*Math.abs(userFollowers)
    const [avatar, setAvatar] = useState(
      infoObj.user.image
        ? infoObj.user.image
        : 'https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg'
    )

    return (
      <>
        <RowContainer style={style} width={width} className='no-scroll' noBar={index === secondInfo.length - 1 && true}>
          <div>
            <img
              src={avatar}
              alt='avatar'
              className='image'
              onError={() =>
                setAvatar('https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg')
              }
            />
          </div>
          <div>
            <p>{userName}</p>
            <p>@{user}</p>
          </div>
          <div>
            <p>{userTweets}</p>
          </div>
        </RowContainer>
      </>
    )
  }

  return (
    <ChartContainer ref={container}>
      <div className='list-labels'>
        <h4>Usuarios</h4>
        <h4>Tweets</h4>
      </div>
      <div className='listRow'>
        <List height={320} itemCount={5} itemSize={60} width={width} className='no-scroll'>
          {FirstList}
        </List>
        <List height={320} itemCount={secondInfo.length} itemSize={60} width={width} className='no-scroll'>
          {SecondList}
        </List>
      </div>
    </ChartContainer>
  )
}

const PublicadoresVolumenTwitter = ({ widget, report, date }) => {
  const searchID = widget.searches.length > 0 ? widget.searches[0].id : ''
  const [period, setPeriod] = useState('week')

  const { data, loading, error } = useQuery(PUBLICADORES_TWITTER, {
    variables: {
      period,
      searchID,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }

    return (
      <PublicadoresContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>
        <Chart data={data} loading={loading} error={error} />
      </PublicadoresContainer>
    )
  } else {
    return (
      <PublicadoresContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'day' && <p>{moment().format('D MMM YY')}</p>}
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>
        <Chart data={data} loading={loading} error={error} />
      </PublicadoresContainer>
    )
  }
}

export default PublicadoresVolumenTwitter
