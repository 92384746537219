import styled from 'styled-components'
import { colors } from '../../../variables'

export const PlanCardContainer = styled.article`
  background: ${colors.white};
  max-width: 681px;
  width: 100%;
  height: 190px;
  display: flex;
  justify-content: space-between;
  padding: 20px 25px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
  position: relative;
  margin-bottom: 20px;
  section {
    min-width: 100px;
  }
  .left-card-section {
    margin-right: 20px;
    width: 57%;
    .lcs-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-bottom: 2px solid ${colors.lightGray};
      height: 35px;
      h4 {
        font-size: 17.5px;
        font-weight: 500;
        color: ${colors.richBlack};
      }
      p {
        font-size: 14px;
        font-weight: 600;
        color: ${colors.darkGray};
      }
    }
    .lcs-content {
      display: flex;
      &-price {
        height: 60px;
        width: 40%;
        border-right: 2px solid ${colors.lightGray};
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 15px;
        &-line {
          display: flex;
          flex-direction: row;
          align-items: center;
        }
        &-qty {
          width: 65px;
          text-align: center;
          font-size: 23.1px;
          font-weight: 600;
          color: ${colors.richBlack};
          &-m-e {
            margin-right: 2px;
          }
          &-m-c {
            margin-right: 10px;
          }
          &-a-in {
            margin-right: 14px;
          }
          &-a-e {
            margin-right: 15px;
          }
          &-a-c {
            margin-right: 16px;
          }
        }
        &-cents {
          font-size: 9px;
          margin-right: 16px;
        }
        &-coin {
          width: 60px;
          text-align: right;
          font-size: 7.7px;
          font-weight: 600;
          color: ${colors.darkGray};
        }
      }
      &-description {
        width: 60%;
        padding: 15px;
        font-size: 9.8px;
        font-weight: 500;
        color: ${colors.darkGray};
        max-height: 125px;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .discount-tag {
      width: 22%;
      height: 16px;
      background: #66fcf1;
      font-size: 8px;
      font-weight: 700;
      color: ${colors.richBlack};
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;
      top: auto;
      bottom: 20px;
      left: 0;
    }
  }
  .right-card-section {
    width: 43%;
    .rcs-benefits {
      height: 25px;
      border-bottom: 2px solid ${colors.lightGray};
      margin-bottom: 10px;
      p {
        font-size: 10px;
        font-weight: 500;
        color: ${colors.darkGray};
      }
    }
    button {
      margin-top: 6px;
      height: 37px;
      font-size: 10px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .left-card-section {
      .lcs-header {
        width: 191px;
      }
      .lcs-content {
        .lcs-content-price {
          .lcs-content-price-line {
            .lcs-content-price-cents {
              margin-right: 25px;
            }
          }
        }
        .lcs-content-description {
          padding: 10px 5px 5px 10px;
        }
      }
    }
  }
`
