import styled from 'styled-components'
import { colors } from '../../variables'

export const TicketDetailContainer = styled.div`
  display: flex;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        text-decoration: none;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
      button {
        cursor: pointer;
      }
    }
  }
  section {
    height: calc(100vh - 70px);
  }
  .left-ticket {
    padding: 40px 40px 40px 0;
    border-right: 1px solid ${colors.lightGray};
    width: 35%;
    .ticket-details {
      height: calc(100vh - 170px);
      overflow-y: scroll;
      /* Hides scrollbar in IE, Edge and Firefox */
      -ms-overflow-style: none; /* IE and Edge */
      scrollbar-width: none; /* Firefox */
    }
    /* Hides scrollbar in Chrome, Safari and Opera */
    .ticket-details::-webkit-scrollbar {
      display: none;
    }
    h2 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px;
    }
    div {
      margin-bottom: 30px;
      p {
        font-size: 16px;
        font-weight: 400;
        line-height: 26px;
        span {
          font-size: 16px;
          font-weight: 600;
        }
      }
    }
    .widget-item {
      display: flex;
      margin: 5px;
      p {
        margin-left: 10px;
      }
    }
    .widget-title {
      display: flex;
      justify-content: space-between;
      button {
        border: none;
        outline: none;
        background: none;
        &.active {
          color: ${colors.lightseaGreen};
          font-size: 14px;
          font-weight: 500;
          &:hover {
            color: ${colors.torquioseBlue};
          }
        }
        &.inactive {
          color: ${colors.silverSand};
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
  .right-ticket {
    padding: 40px 0 40px 40px;
    width: 65%;
    h2 {
      font-size: 24px;
      font-weight: 500;
      margin-bottom: 30px;
    }
    .reports-table-header {
      display: flex;
      height: 30px;
      padding: 7.5px;
      h3 {
        width: calc(89% / 3);
        font-size: 16px;
        font-weight: 600;
        padding-left: 2%;
        &.number {
          width: 7.5%;
        }
      }
    }
  }
  .reports-table-body {
    overflow: scroll;
    padding: 7.5px;
    .reports-table-row {
      display: flex;
      height: 50px;
      border-radius: 4px;
      margin-bottom: 10px;
      background: ${colors.white};
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      p {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        width: calc(89% / 3);
        padding: 0 2%;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        &.number {
          width: 7.5%;
        }
        &.source {
          text-transform: capitalize;
        }
      }
      .creator {
        display: flex;
        align-items: center;
        .creator-photo {
          width: 30px;
          height: 30px;
          margin-right: 10px;
          background: ${colors.lightGray};
          border-radius: 50%;
        }
      }
      .dots-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 45px;
        svg {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
        }
      }
    }
    .report-menu {
      z-index: 5;
      top: 5px;
      right: 10px;
      button {
        border: none;
        background: none;
        outline: none;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        cursor: pointer;
        svg {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
        }
      }
    }
    .report-options {
      position: absolute;
      z-index: 6;
      width: 190px;
      height: 126px;
      background: ${colors.white};
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      border-radius: 4px;
      .full_option {
        height: 42px;
        width: 100%;
        display: flex;
        align-items: center;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
        padding-left: 20px;
        &:hover {
          background: ${colors.lightGray};
        }
        svg {
          width: 20px;
          height: 20px;
          margin-right: 10px;
        }
      }
      .delete_button {
        color: ${colors.red};
      }
      .option_button {
        color: ${colors.silverSand};
        &:hover {
          color: ${colors.gunMetal};
        }
      }
    }
  }
`

export const EditButton = styled.div`
  height: 5%;
  text-align: center;
  width: 100%;
  button {
    width: 100%;
    height: 50px;
    border-radius: 4px;
    outline: none;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    border: 1px solid ${colors.purple};
    background: none;
    color: ${colors.purple};
    &:hover {
      border: none;
      background: ${colors.purple};
      color: ${colors.white};
    }
    &:disabled {
      cursor: not-allowed;
      background: ${colors.silverSand};
      border: none;
      color: ${colors.white};
    }
  }
`
