import styled from 'styled-components'
import { colors } from '../../../variables'

export const DashboardFiltersContainer = styled.div`
  width: 400px;
  height: 100vh;
  background: ${colors.background};
  border-right: 1px solid ${colors.lightGray};
  position: absolute;
  top: 0;
  left: ${props => (props.sidebar ? '68px' : '222px')};
  transition: all 0.25s linear;
  padding: 150px 2.5%;
  .filters--header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 30px;
    h4 {
      font-size: 30px;
      font-weight: 700;
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      path {
        stroke: ${colors.darkGray};
      }
    }
  }
  .filters--content {
    display: flex;
    flex-direction: column;
    h5 {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 10px;
    }
    label {
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      margin-bottom: 10px;
    }
    input {
      height: 47px;
      border-radius: 4px;
      border: 1px solid ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      padding: 10px;
      margin-bottom: 15px;
    }
    .date {
      display: flex;
      flex-direction: column;
    }
    .reset {
      display: flex;
      justify-content: flex-end;
    }
  }
`
