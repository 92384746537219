import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment'
// import { AiOutlineUser, AiOutlineSearch } from 'react-icons/ai'
// import { RiDashboardLine } from 'react-icons/ri'
// import { GiCancel } from 'react-icons/gi'
// import { FaRegCheckCircle } from 'react-icons/fa'
import { useQuery, useMutation } from '@apollo/client'
import { Spinner, Shadow } from '../../style/components'
import { Switch } from '../../style/components'
import CancelSubscription from './CancelSubscription'
import ReactivateSubscription from './ReactivateSubscription'
import { monthlyProducts, yearlyProducts } from '../../data/Plans'
import { DowngradeModal } from './Modals/DowngradeModal'
import ChangeModal from './Modals/ChangeModal'
import PaymenthSelector from './Modals/PaymenthSelector'
import NewPaymentMethod from './NewPaymentMethod'
import { InfoModal } from './Modals/InfoModal'

import {
  PlanContainer,
  PlanResume,
  PlanCard,
  ChangePlanInfo
} from '../../style/components/workspace/PlanContainer.styled'

import { SUBSCRIPTION_QUERY, GET_CURRENT_PLAN, GET_PAYMENT_METHODS } from '../../services/queries'
import {
  FUTURE_INVOICE,
  RETRIVE_SUBSCRIPTION,
  DOTHEPLAN_CHANGE,
  DOTHEPLAN_CHANGE_ANUALTOMONTH,
  CREATE_STRIPE_SUBSCRIPTION,
  CREATE_STRIPE_SUBSCRIPTION_USER_FREE
} from '../../services/mutations'
import { MyContext } from '../../context'
import CreateSuscriptionModal from './Modals/CreateSuscriptionModal'
import ErrorModal from '../auth/ErrorModal'
import Button from '../ui/Button'

const Plan = () => {
  const context = useContext(MyContext)
  const [monthlyPlans, setMonthly] = useState(true)
  const [showCancelSubscription, setShowCancelSubscription] = useState(false)
  const [showReactivateSubscription, setShowReactivateSubscription] = useState(false)

  const { data: subscriptionData, loading: subscriptionLoading } = useQuery(SUBSCRIPTION_QUERY)
  const { data, loading, startPolling, stopPolling, error } = useQuery(GET_CURRENT_PLAN)

  const [showDowngradeModal, setShowDowngradeModal] = useState(false)
  const [showChangeModal, setShowChangeModal] = useState(false)

  const [desiredPlan, setDesiredPlan] = useState('')
  const [desiredPlanPrice, setDesiredPlanPrice] = useState('')
  const [currentPlan, setCurrentPlan] = useState('')
  const [currentPlanPrice, setCurrentPlanPrice] = useState('')
  const [dataFutureInvoice, setDataFutureInvoice] = useState(null)
  const [desiredProduct, setDesiredProduct] = useState(null)
  const [message, setMessage] = useState('')
  const [billingCycle, setBillingCycle] = useState('')
  const [nextCharge, setNextCharge] = useState(null)
  const [prorationsData, setProrationsData] = useState(null)

  const [showPaymenthSelector, setShowPaymenthSelector] = useState(false)
  const [showAddPaymenthMethod, setShowAddPaymenthMethod] = useState(false)
  const [showInfoModal, setShowInfoModal] = useState(false)
  const [showPaymethDetails, setShowPaymethDetails] = useState(false)

  const [anualToMonthFlag, setAnualToMonthFlag] = useState(false)
  const [errorPaymenthFlag, setErrorPaymenthFlag] = useState(false)
  const [errorFlag, setErrorFlag] = useState(false)
  const [showCreateSuscriptionModal, setShowCreateSuscriptionModal] = useState(false)
  const [showErrorModal, setShowErrorModal] = useState(false)
  const [errorMessage, setErrorMessage] = useState(null)

  const { data: paymentMethods, loading: paymentMethodsLoading, refetch: refetchPM } = useQuery(GET_PAYMENT_METHODS)
  const [futureInvoice, { loading: loadingFI }] = useMutation(FUTURE_INVOICE)
  const [retriveSubscription, { loading: loadingRS }] = useMutation(RETRIVE_SUBSCRIPTION)
  const [updatePlan, { loading: loadingUP }] = useMutation(DOTHEPLAN_CHANGE)
  const [updatePlanAnualToMonth, { loading: loadingUPAM }] = useMutation(DOTHEPLAN_CHANGE_ANUALTOMONTH)
  const [createStripeSubscriptionUserFree] = useMutation(CREATE_STRIPE_SUBSCRIPTION_USER_FREE, {
    update(cache, { data: { createStripeSubscriptionUserFree } }) {
      cache.writeQuery({
        query: SUBSCRIPTION_QUERY,
        data: { subscription: JSON.parse(createStripeSubscriptionUserFree) }
      })
    }
  })

  useEffect(() => {
    startPolling(2000)
    return () => {
      stopPolling()
    }
  }, [startPolling, stopPolling])

  useEffect(() => {
    if (data && JSON.parse(data.getCurrentPlan).planType.includes('YEARLY')) {
      setMonthly(false)
    } else {
      setMonthly(true)
    }
  }, [data])

  if (error) context.handleErrors(error)
  if (loading || subscriptionLoading) return <Spinner />

  const planData = JSON.parse(data.getCurrentPlan)
  const nextPlan =
    planData.nextSubscriptionPlan === undefined
      ? ''
      : planData.nextSubscriptionPlan.replace('_', ' ').toLowerCase().split(' ')
  const nextProduct = nextPlan[1] === 'monthly' ? monthlyProducts : yearlyProducts
  const actualPlan = JSON.parse(data.getCurrentPlan).planType.replace('_', ' ').toLowerCase().split(' ')
  const actualSearchesQty = JSON.parse(data.getCurrentPlan).searchesQty
  const actualProduct = actualPlan[1] === 'monthly' ? monthlyProducts : yearlyProducts
  const products = monthlyPlans ? monthlyProducts : yearlyProducts

  monthlyProducts.forEach(product => {
    let valid
    valid = product.searches >= actualSearchesQty
    return (product['valid'] = valid)
  })

  yearlyProducts.forEach(product => {
    let valid
    valid = product.searches >= actualSearchesQty
    return (product['valid'] = valid)
  })

  const handleDowngradePlan = product => {
    setShowDowngradeModal(true)
    setDesiredProduct(product)
  }

  const handleUpdatePlan = async () => {
    const dictionary = {
      Mes: 'MONTHLY',
      Año: 'YEARLY',
      monthly: 'Mensual',
      yearly: 'Anual'
    }

    if (dictionary[actualPlan[1]] === 'Anual' && desiredProduct.billed === 'Mensual') {
      try {
        await updatePlanAnualToMonth({
          variables: {
            planId: `${desiredProduct.name.toUpperCase()}_${dictionary[desiredProduct.interval]}`,
            nextCharge: nextCharge.toString()
          }
        })
      } catch ({ message: errorMessage }) {
        setErrorFlag(true)
        setMessage(errorMessage)
      }
      setShowChangeModal(false)
      setShowInfoModal(true)
    } else {
      try {
        const subUPD = await updatePlan({
          variables: {
            planId: `${desiredProduct.name.toUpperCase()}_${dictionary[desiredProduct.interval]}`
          }
        })
        const { pending_update } = JSON.parse(subUPD.data.updatePlan)
        if (pending_update !== null) {
          // The try of paymeth has an error and is asking for a retry on paymenth
          setErrorPaymenthFlag(true)
          setShowChangeModal(false)
          setShowPaymenthSelector(true)
        } else {
          setShowChangeModal(false)
          setShowInfoModal(true)
        }
      } catch ({ message }) {
        setErrorFlag(true)
        setMessage(message)
      }
    }
  }

  const handleChangeSelection = async product => {
    const dictionary = {
      monthly: 'Mensual',
      yearly: 'Anual',
      individual: 'Individual',
      equipo: 'Equipo',
      corporativo: 'Corporativo',
      gratuito: 'Gratuito',

      Mes: 'MONTHLY',
      Año: 'YEARLY'
    }

    setDesiredProduct(product)
    setDesiredPlan(`${product.name} ${product.billed}`)
    setDesiredPlanPrice(product.price)
    setCurrentPlan(`${dictionary[actualPlan[0]]} ${dictionary[actualPlan[1]]}`)
    // setBillingCycle(dictionary[actualPlan[1]])
    setCurrentPlanPrice(actualProduct.filter(e => e.name.toUpperCase() === actualPlan[0].toUpperCase())[0].price)

    if (actualPlan[0] === 'gratuito') {
      return showModalcreateSuscription(product)
    } else {
      const invoice = await futureInvoice({
        variables: {
          planId: `${product.name.toUpperCase()}_${dictionary[product.interval]}`
        }
      })

      const { lines, subtotal, total, next_payment_attempt } = JSON.parse(invoice.data.futureInvoice)

      setProrationsData(lines)

      if (dictionary[actualPlan[1]] === product.billed && product.billed === 'Mensual') setBillingCycle('month')
      if (dictionary[actualPlan[1]] === product.billed && product.billed === 'Anual') setBillingCycle('year')
      if (dictionary[actualPlan[1]] === 'Mensual' && product.billed === 'Anual') setBillingCycle('year')

      if (dictionary[actualPlan[1]] === 'Anual' && product.billed === 'Mensual') {
        setAnualToMonthFlag(true)

        const currentSubscription = await retriveSubscription()
        const currentSubscriptionData = JSON.parse(currentSubscription.data.retriveSubscription)

        setNextCharge(currentSubscriptionData.current_period_end)
      } else {
        setAnualToMonthFlag(false)
        setNextCharge(next_payment_attempt)
      }

      setDataFutureInvoice({
        next_payment_attempt,
        unusedTimeCredit: {
          amount: lines.data[0].amount,
          currency: lines.data[0].currency,
          description: lines.data[0].description
        },
        costForTimeSpend: {
          amount: lines.data[1] ? lines.data[1].amount : 0,
          currency: lines.data[1] ? lines.data[1].currency : 'MXN',
          description: lines.data[1] ? lines.data[1].description : 'NANAIS'
        },
        subAndTotal: {
          subtotal: subtotal,
          total: total
        }
      })
      setShowChangeModal(true)
    }
  }

  const showModalcreateSuscription = product => {
    setDesiredPlanPrice(product.price)
    setShowCreateSuscriptionModal(true)
  }

  const handleSubmit = async () => {
    const subscription = await handleCreateStripeSubscription(desiredProduct)
    setShowCreateSuscriptionModal(false)
  }

  const handleCreateStripeSubscription = async desiredProduct => {
    try {
      const { data } = await createStripeSubscriptionUserFree({
        variables: {
          input: {
            desiredProduct
          }
        }
      })
      const subscription = JSON.parse(data.createStripeSubscriptionUserFree)

      const { status } = subscription

      let failure_message
      switch (status) {
        case 'active':
        case 'trialing':
          setShowInfoModal(true)
          setNextCharge(subscription.current_period_end)
          break
        case 'incomplete':
          failure_message = subscription.latest_invoice.payment_intent.last_payment_error.message
          setErrorMessage(`Error: ${failure_message}`)
          setShowErrorModal(true)
          break
        case 'error':
          failure_message = subscription.latest_invoice.payment_intent.last_payment_error.message
          setErrorMessage(`Error: ${failure_message}`)
          setShowErrorModal(true)
          break
        default:
          console.log('{NOT MAPPED - Subscription Status}', status)
          break
      }

      return subscription
    } catch (error) {
      const { message } = error
      setErrorMessage(message)
      return {
        status: 'error',
        message
      }
    }
  }

  const closeErrorPaymentModal = () => {
    setShowErrorModal(false)
  }

  const handleBackActionPaymenthDetails = () => {
    setShowPaymethDetails(false)
    setShowChangeModal(true)
  }

  const handlePaymenthDetails = () => {
    setShowChangeModal(false)
    setShowPaymethDetails(true)
  }

  const handlePaymenthSelector = () => {
    setShowPaymenthSelector(true)
  }

  const handleNewPaymenthMethod = () => {
    setShowPaymenthSelector(false)
    setShowChangeModal(true)
    setErrorPaymenthFlag(false)
  }

  const handleAddPaymenthMethod = () => {
    setShowAddPaymenthMethod(true)
    setShowPaymenthSelector(false)
  }

  const handleBackEventPM = () => {
    setShowPaymenthSelector(true)
    refetchPM()
  }

  return (
    <PlanContainer>
      <section className='actual-plan'>
        <h2 className='plan__section__title'>Resumen de tu plan</h2>
        <div className='plan__resume__container'>
          {actualProduct
            .filter(product => product.name.toUpperCase() === actualPlan[0].toUpperCase())
            .map(product => (
              <PlanResume key={product.key}>
                <div className='card-header'>
                  <h3 className='plan__resume__title'>
                    Plan {product.name !== 'Gratuito' && `${product.billed}`} {`${product.name}`}
                  </h3>
                </div>
                <div className='plan__resume__benefits'>
                  <div className='plan__resume__benefit'>
                    <p className='benefit__text'>Usuarios</p>
                    <p className='benefit__detail'>{product.users}</p>
                  </div>
                  <div className='plan__resume__benefit'>
                    <p className='benefit__text'>Tableros</p>
                    <p className='benefit__detail'>{product.dashboards}</p>
                  </div>
                  <div className='plan__resume__benefit benefits__end__border'>
                    <p className='benefit__text'>Búsquedas</p>
                    <p className='benefit__detail'>{product.searches}</p>
                  </div>
                  <div className='plan__resume__benefit benefit__total'>
                    <p className='benefit__text'>Total:</p>
                    <p className='benefit__detail'>
                      ${product.price} <span>MXN</span>
                    </p>
                  </div>
                </div>
                {product.name !== 'Gratuito' && (
                  <div className='plan__next__payment'>
                    <p className='next__payment__text'>
                      Próximo pago:{' '}
                      {moment(subscriptionData.subscription.current_period_end * 1000).format('DD MMM YYYY')}
                    </p>
                  </div>
                )}

                <div className='plan-switch'>
                  <div className='plan__switch__content'>
                    <p className={`switch__plantype ${monthlyPlans ? 'bold' : 'normal'}`}>Mensual</p>
                    <Switch>
                      <input type='checkbox' checked={!monthlyPlans} onChange={() => setMonthly(!monthlyPlans)} />
                      <span className='slider round' />
                    </Switch>
                    <p className={`switch__plantype ${!monthlyPlans ? 'bold' : 'normal'}`}>Anual</p>
                  </div>
                </div>
              </PlanResume>
            ))}
          <br />
          {planData.nextSubscriptionPlan !== '' &&
            nextPlan !== '' &&
            nextProduct
              .filter(product => product.name.toUpperCase() === nextPlan[0].toUpperCase())
              .map(product => (
                <ChangePlanInfo key={product.key}>
                  <div className='changeplaninfo__header'>
                    <h3>Cambio de plan</h3>
                  </div>
                  <p className='changeplaninfo__text'>
                    Recientemente solicitaste un cambio de plan anual a plan mensual. Aquí los detalles:
                  </p>
                  <div className='changeplaninfo__description'>
                    <div className='description__plan__cost'>
                      <h3 className='plan__type'>Plan: {product.name}</h3>
                      <p>${product.price} MXN</p>
                    </div>
                    <p className='next__payment'>
                      {moment(parseInt(planData.nextCharge) * 1000).format('DD [de] MMMM [del] YYYY')}
                    </p>
                  </div>
                </ChangePlanInfo>
              ))}
        </div>
        {/* {subscriptionData.subscription.cancel_at_period_end ?
          <div className='reactivate-subscription'>
            <button onClick={() => setShowReactivateSubscription(true)}>
              <FaRegCheckCircle />
            Reactiva tu suscripción
          </button>
            <p>
              Tu suscripción termina el {moment(subscriptionData.subscription.cancel_at * 1000).locale('es').format('D MMMM YYYY')}.
          </p>
          </div> :
          <div className='cancel-subscription'>
            <button onClick={() => setShowCancelSubscription(true)}>
              <GiCancel />
            Cancelar suscripción
          </button>
            <p>
              Una vez que canceles tu suscripción, perderás acceso a todas las funciones
              de MyOblek a partir de la siguiente mensualidad.
          </p>
          </div>} */}
      </section>

      <section className='change-plan'>
        <h2 className='plan__section__title'>Cambia tu plan</h2>
        <p className='plan__section__text'>
          Crece tu plan cuando lo necesites y aprovecha al máximo de todos los beneficios de nuestra plataforma.
        </p>

        <div className='products'>
          {products.map(product => (
            <PlanCard key={product.key}>
              <div className={product.billed === 'Anual' ? 'product__discount' : 'no__discount'}>
                {product.name !== 'Gratuito' && <p className='discount__text'>50% de descuento</p>}
              </div>
              <div className='card-header'>
                <h6 className='product__name'>{product.name}</h6>
                <div className='product__price'>
                  <h5 className='price__text'>
                    ${product.price} <span>MXN</span>
                  </h5>
                </div>
              </div>
              <div className='card-content'>
                <div className='product__description__container'>
                  <p className='product__description'>{product.description}</p>
                </div>
                <div className='benefits'>
                  <p className='benefits__description'>{product.users} Usuarios</p>
                  <p className='benefits__description'>{product.dashboards} Tableros</p>
                  <p className='benefits__description'>{product.searches} Búsquedas</p>
                </div>
              </div>
              {product.name.toUpperCase() === actualPlan[0].toUpperCase() &&
              (product.name.toUpperCase() === 'GRATUITO' ||
                (product.billed === 'Mensual' && actualPlan[1] === 'monthly') ||
                (product.billed === 'Anual' && actualPlan[1] === 'yearly')) ? (
                <Button theme={'dark'} className='selected__plan__button' disabled>
                  Plan seleccionado
                </Button>
              ) : product.valid ? (
                <Button theme={'dark'} className='change__plan__button' onClick={() => handleChangeSelection(product)}>
                  Cambiar a este plan
                </Button> // Handle downgrade product
              ) : (
                <Button theme={'dark'} className='change__plan__button' onClick={() => handleDowngradePlan(product)}>
                  Cambiar a este plan
                </Button>
              )}
            </PlanCard>
          ))}
        </div>
      </section>

      {/* MODALS */}

      {showCancelSubscription && (
        <>
          <Shadow onClick={() => setShowCancelSubscription(false)} />
          <CancelSubscription
            closeModal={() => setShowCancelSubscription(false)}
            endDate={subscriptionData.subscription.current_period_end}
          />
        </>
      )}

      {showReactivateSubscription && (
        <>
          <Shadow onClick={() => setShowReactivateSubscription(false)} />
          <ReactivateSubscription closeModal={() => setShowReactivateSubscription(false)} />
        </>
      )}

      {showDowngradeModal && ( // This shows the form modal
        <>
          <Shadow onClick={() => setShowDowngradeModal(false)} />
          <DowngradeModal
            setShowDowngradeModal={setShowDowngradeModal}
            actualPlan={actualPlan}
            desiredProduct={desiredProduct}
            products={[...monthlyProducts, ...yearlyProducts]}
          />
        </>
      )}

      {showChangeModal && (
        <>
          <Shadow onClick={() => setShowChangeModal(false)} />
          <ChangeModal
            loading={loadingFI || paymentMethodsLoading || loadingRS}
            setShowChangeModal={setShowChangeModal}
            anualToMonthFlag={anualToMonthFlag}
            futureInvoice={dataFutureInvoice}
            nextCharge={nextCharge}
            billingCycle={billingCycle}
            currentPricePlan={currentPlanPrice}
            desiredPlan={desiredPlan}
            desiredPricePlan={desiredPlanPrice}
            handlePaymenthSelector={handlePaymenthSelector}
            paymentMethods={paymentMethods}
            handleUpdatePlan={handleUpdatePlan}
            handlePaymenthDetails={handlePaymenthDetails}
          >
            {anualToMonthFlag ? (
              <p>
                Tu <strong>Plan {currentPlan}</strong> se mantendrá hasta el{' '}
                <strong>{moment(nextCharge * 1000).format('DD [de] MMMM [del] YYYY')}</strong>. Posterior a esta fecha
                se cambiará automáticamente al <strong>Plan {desiredPlan}</strong>.
              </p>
            ) : (
              <p className='changemodal__description'>
                Estás a punto de cambiar de un{' '}
                <strong className='changemodal__description__plan'>Plan {currentPlan}</strong> a un{' '}
                <strong className='changemodal__description__plan'>Plan {desiredPlan}</strong>. El cobro se verá
                reflejado de manera inmediata, por lo que te sugerimos te asegures de contar con los fondos suficientes
                para la operación:
              </p>
            )}
          </ChangeModal>
        </>
      )}

      {showCreateSuscriptionModal && (
        <>
          <Shadow onClick={() => setShowCreateSuscriptionModal(false)} />
          <CreateSuscriptionModal
            loading={loadingFI || paymentMethodsLoading || loadingRS}
            setShowCreateSuscriptionModal={setShowCreateSuscriptionModal}
            desiredPlanPrice={desiredPlanPrice}
            handleAddPaymenthMethod={handleAddPaymenthMethod}
            paymentMethods={paymentMethods}
            handleUpdatePlan={handleUpdatePlan}
            handleSubmit={handleSubmit}
            handlePaymenthSelector={handlePaymenthSelector}
          >
            <p className='changemodal__description'>
              Estás a punto de cambiar de un{' '}
              <strong className='changemodal__description__plan'>Plan {currentPlan.split(' ')[0]}</strong> a un{' '}
              <strong className='changemodal__description__plan'>Plan {desiredPlan}</strong>. El cobro se verá reflejado
              de manera inmediata, por lo que te sugerimos te asegures de contar con los fondos suficientes para la
              operación:
            </p>
          </CreateSuscriptionModal>
        </>
      )}

      {showPaymenthSelector && (
        <>
          <Shadow zIndex={32} onClick={() => setShowPaymenthSelector(false)} />
          <PaymenthSelector
            loading={paymentMethodsLoading}
            errorPaymenth={errorPaymenthFlag}
            setShowPaymenthSelector={setShowPaymenthSelector}
            refetchPM={refetchPM}
            cards={paymentMethods.getPaymentMethods}
            handleNewPaymenthMethod={handleNewPaymenthMethod}
            handleAddPaymenthMethod={handleAddPaymenthMethod}
          />
        </>
      )}

      {showAddPaymenthMethod && (
        <>
          <Shadow zIndex={31} onClick={() => setShowAddPaymenthMethod(false)} />
          <NewPaymentMethod setNewCard={setShowAddPaymenthMethod} backEvent={handleBackEventPM} />
        </>
      )}

      {showInfoModal && (
        <>
          <Shadow onClick={() => setShowInfoModal(false)} />
          <InfoModal
            loading={loadingUP || loadingUPAM}
            setShowInfoModal={setShowInfoModal}
            successInfo={errorFlag ? false : true}
            billingCycle={billingCycle}
            nextCharge={nextCharge}
            errorInfo={errorFlag ? true : false}
            message={message}
            anualToMonthFlag={anualToMonthFlag}
            // handleShowContactForm={handleShowContactForm}
          />
        </>
      )}

      {showPaymethDetails && (
        <>
          <Shadow onClick={() => setShowPaymethDetails(false)} />
          <InfoModal
            paymenthDetail
            dataDetailPaymeth={prorationsData}
            dataFutureInvoice={dataFutureInvoice}
            handleBackAction={handleBackActionPaymenthDetails}
          />
        </>
      )}

      {showErrorModal && (
        <>
          <Shadow onClick={() => setShowErrorModal(false)} />
          <ErrorModal
            loading={false}
            message={errorMessage}
            closeErrorPayment={closeErrorPaymentModal}
            onClick={closeErrorPaymentModal}
          />
        </>
      )}
    </PlanContainer>
  )
}

export default Plan
