import React from 'react'
import { useHistory } from 'react-router-dom'

import { LimitWarningContainer } from '../../../style/components/search/new-serches/LimitWarning.styled'
import { colors } from '../../../style/variables'
import CloseModal from '../../workspace/Modals/CloseModal'
import Button from '../../ui/Button'

const LimitWarning = ({ info, closeModal }) => {
  const history = useHistory()

  let plan = info.plan.split('_')[0].toLowerCase()
  plan = plan.charAt(0).toUpperCase() + plan.slice(1)

  return (
    <LimitWarningContainer>
      {plan === 'Gratuito' && (
        <div>
          <CloseModal onClick={closeModal} />
          <h3>¡Atención!</h3>
          <p>
            Tu plan <span>{plan}</span> solo te permite generar <span>{info.quantity}</span> búsquedas. Te sugerimos
            actualizar a un plan de pago para contar con mayores beneficios y aprovechar el potencial de la plataforma.
          </p>
          <div>
            <Button theme={'primary'} size={'full'} onClick={() => history.push('/workspace?plans')}>
              Actualizar plan
            </Button>
          </div>
        </div>
      )}
      {plan !== 'Gratuito' && (
        <div>
          <h3>Límite de búsquedas</h3>
          <p>
            Has alcanzado el número máximo de <span>{info.quantity}</span> búsquedas para tu plan <span>{plan}</span> y
            no podrás guardar esta búsqueda.
          </p>
          <div className='modal-buttons'>
            <Button weight={500} theme={'dark'} variant={'link'} onClick={closeModal}>
              Continuar
            </Button>
            <Button weight={500} theme={'danger'} onClick={() => history.push('/searches')}>
              Eliminar búsquedas
            </Button>
            <Button weight={500} onClick={() => history.push('/workspace?plans')}>
              Subir de plan
            </Button>
          </div>
        </div>
      )}
    </LimitWarningContainer>
  )
}

export default LimitWarning
