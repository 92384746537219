import React from 'react'
import { DeleteSearchContainer } from '../../../style/components/search/searches/DeleteSearch.styled'

import { GrClose } from 'react-icons/gr'
import Button from '../../ui/Button'

const DeleteSearch = ({ search, setSearchToDelete, deleteSearch }) => {
  return (
    <DeleteSearchContainer>
      <div className='filters--header'>
        <h3>¡Eliminar búsqueda!</h3>
        <GrClose onClick={() => setSearchToDelete(false)} />
      </div>
      <p>
        ¡Atención! Estás a punto de eliminar la búsqueda <span>{search.name}</span>, si lo haces perderás todas las
        propiedades asignadas a las misma. Tus <span>gráficos </span>
        vinculados no desaparecerán pero no podrás utilizar la información.
      </p>
      <div className='modal-buttons'>
        <Button variant={'link'} theme={'dark'} onClick={() => setSearchToDelete(null)}>
          Cancelar
        </Button>
        <Button variant={'outline'} theme={'danger'} onClick={() => deleteSearch(search.id)}>
          Eliminar
        </Button>
      </div>
    </DeleteSearchContainer>
  )
}

export default DeleteSearch
