import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const SelectedWidgetContainer = styled.div`
  width: 500px;
  border-radius: 10px 0 0 10px;
  padding: 50px 40px;
  background: ${colors.white};
  .selected-widget--header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 26px;
    h5 {
      font-size: 24px;
      font-weight: 700;
      color: ${colors.richBlack};
    }
    button {
      background: none;
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        width: 24px;
        height: 24px;
        cursor: pointer;
        path {
          stroke: ${colors.darkGray};
        }
      }
    }
  }
  .selected-widget--graph {
    width: 100%;
    height: 268px;
    background: ${colors.white};
    border-radius: 4px;
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    margin-bottom: 26px;
    img {
      width: 100%;
    }
  }
  .selected-widget--description {
    margin-bottom: 26px;
    .selected-widget--type {
      display: flex;
      margin-bottom: 26px;
      p {
        display: flex;
        align-items: center;
        margin-right: 30px;
        svg {
          width: 24px;
          height: 24px;
          margin-right: 8px;
          path {
            fill: ${colors.darkGray};
          }
        }
        .twitter-icon {
          stroke-width: 1;
          stroke: ${colors.darkGray};
          path {
            fill: none;
          }
        }
      }
    }
    p {
      font-size: 16px;
      font-weight: 400;
    }
  }
  .select-widget--dropdown {
    margin-bottom: 40px;
  }
  .select-widget--button {
    display: flex;
    justify-content: flex-end;
  }
`
