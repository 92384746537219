import React, { useContext, useState, useRef } from 'react'
import axios from 'axios'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Shadow } from '../../style/components'
import { ProfileContainer } from '../../style/pages/profile/ProfileContainer.styled'
import moment from 'moment'
import { useMutation } from '@apollo/client'

import DataChangeModal from '../../components/profile/DataChangeModal'

import { GET_USER } from '../../services/queries'

import { MODIFY_ONBOARDING, UPLOAD_PHOTO, EDIT_PROFILE, CHANGE_PASSWORD } from '../../services/mutations'
import Button from '../../components/ui/Button'
import BirthdayInput from '../../components/profile/BirthdayInput'
import Onboarding from '../../components/search/searches/OnboardingModal'
import { degrees, genders, occupations } from '../../data/Data'
import DataDropdown from '../../components/ui/DataDropdown'

const Profile = props => {
  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const [changePassword] = useMutation(CHANGE_PASSWORD)

  const [onboarding] = useMutation(MODIFY_ONBOARDING, {
    update(cache, { data: { modifyOnboarding } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: modifyOnboarding
        }
      })
    }
  })

  const getOnboarding = async () => {
    await onboarding({
      variables: { flag: false }
    })
  }

  const [uploadPhoto] = useMutation(UPLOAD_PHOTO, {
    update(cache, { data: { uploadPhoto } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: uploadPhoto
        }
      })
    }
  })

  const [editProfile] = useMutation(EDIT_PROFILE, {
    update(cache, { data: { editProfile } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: editProfile
        }
      })
    }
  })

  const [photo] = useState(props.user.photo)

  const [name, setName] = useState(props.user.name)
  const [day, setDay] = useState(props.user.birthday ? Number(moment(Number(props.user.birthday)).format('DD')) : null)
  const [month, setMonth] = useState(
    props.user.birthday ? Number(moment(Number(props.user.birthday)).format('MM')) : null
  )
  const [year, setYear] = useState(
    props.user.birthday ? Number(moment(Number(props.user.birthday)).format('YYYY')) : null
  )
  const [age, setAge] = useState(props.user.age ? [props.user.age] : null)
  const [gender, setGender] = useState(props.user.gender ? [props.user.gender] : [''])
  const [occupation, setOccupation] = useState(props.user.occupation ? [props.user.occupation] : [''])
  const [degree, setDegree] = useState(props.user.degree ? [props.user.degree] : [''])

  // DataHasChanged Modal state
  const [dataHasChangedModal, setDataHasChangedModal] = useState(false)

  // Password states
  const [showChangePassword, setShowChangePassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [isPasswordCorrect, setIsPasswordCorrect] = useState(true)
  const [newPassword, setNewPassword] = useState('')
  const [passwordConfirmation, setPasswordConfirmation] = useState('')

  // Birthday validation state
  const [dateError, setDateError] = useState(false)

  const handleUserSubmit = async e => {
    e.preventDefault()

    try {
      await editProfile({
        variables: {
          profile: {
            name,
            day,
            month,
            year,
            age: parseInt(age),
            gender: gender[0],
            occupation: occupation[0],
            degree: degree[0]
          }
        }
      })
    } catch (error) {
      console.log(error)
    }
  }

  // Lines up to 590 handle Password change

  const passwordRegex = () => {
    return '^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\\$%\\^&\\.*])(?=.{8,})'
  }

  const isNewPasswordStrong = () => {
    const strongPasswordRequisites = new RegExp(passwordRegex())

    if (strongPasswordRequisites.test(newPassword)) return true
    return false
  }

  const passwordChangeReady = () => {
    const strongPasswordRequisites = new RegExp(passwordRegex())

    if (
      currentPassword !== '' &&
      strongPasswordRequisites.test(newPassword) &&
      strongPasswordRequisites.test(passwordConfirmation) &&
      newPassword === passwordConfirmation
    )
      return true
    return false
  }

  const onClickPassword = () => {
    setShowChangePassword(prev => !prev)

    if (currentPassword !== '' || newPassword !== '' || passwordConfirmation !== '') {
      setCurrentPassword('')
      setNewPassword('')
      setPasswordConfirmation('')
    }
  }

  const handlePasswordSubmit = async e => {
    e.preventDefault()

    try {
      await changePassword({
        variables: {
          input: {
            password: currentPassword,
            newPassword
          }
        }
      })
      setCurrentPassword('')
      setNewPassword('')
      setPasswordConfirmation('')
      setIsPasswordCorrect(true)
      setDataHasChangedModal(true)
    } catch (error) {
      console.log(error)
      setIsPasswordCorrect(false)
      setTimeout(() => {
        setIsPasswordCorrect(true)
      }, 3000)
      setCurrentPassword('')
      setNewPassword('')
      setPasswordConfirmation('')
    }
  }

  const dataHasChanged = () => {
    let dateHasChanged = false

    if (props.user.birthday) {
      dateHasChanged = Number(moment(Number(props.user.birthday)).format('DD')) !== day || dateHasChanged
      dateHasChanged = Number(moment(Number(props.user.birthday)).format('MM')) !== month || dateHasChanged
      dateHasChanged = Number(moment(Number(props.user.birthday)).format('YYYY')) !== year || dateHasChanged
    } else {
      dateHasChanged = day || month || year
    }

    if (
      props.user.name !== name ||
      (props.user.age != age && age.length) ||
      (props.user.gender !== gender[0] && gender.length) ||
      (props.user.occupation !== occupation[0] && occupation.length) ||
      (props.user.degree !== degree[0] && degree.length) ||
      dateHasChanged
    ) {
      if (dateError) return false
      if ((day || month || year) && (day === null || month === null || year === null)) {
        return false
      }

      return true
    }
    return false
  }

  const showDataHasChangedModal = () => {
    setShowChangePassword(prev => !prev)
    setDataHasChangedModal(prev => !prev)
  }

  const coverImg = useRef(null)

  const [imgFile, setImgFile] = useState(photo)

  const uploadImage = async e => {
    const file = e.target.files[0]
    const formData = new FormData()
    formData.append('upload_preset', process.env.REACT_APP_UPLOAD_PRESET)
    formData.append('file', file)
    const response = await axios.post(
      `https://api.cloudinary.com/v1_1/${process.env.REACT_APP_CLOUDINARY_NAME}/image/upload`,
      formData
    )
    uploadPhoto({
      variables: {
        url: response.data.secure_url
      }
    })
  }

  const readURL = () => {
    if (coverImg.current.files && coverImg.current.files[0]) {
      var reader = new FileReader()
      reader.onload = function (e) {
        setImgFile(e.target.result)
      }
      reader.readAsDataURL(coverImg.current.files[0])
    }
  }

  const redirect = flag => {
    flag === true && props.history.push('/searches')
  }

  return (
    <MainContainer className={mainClass()}>
      <ProfileContainer onSubmit={e => handleUserSubmit(e)} encType='multipart/form-data' profileImg={imgFile}>
        <Topbar className={topbarClass()}>
          <h1 className='topbar__title'>Ajustes de perfil</h1>
          <Button
            height='35px'
            size='medium'
            theme={'secondary'}
            variant='outline'
            onClick={async () => {
              await onboarding({
                variables: { flag: !props.user.onboarding.tutorial }
              })
            }}
          >
            Ver tutorial
          </Button>
        </Topbar>
        <div className='profileCont'>
          <div className='profileCont-body'>
            <div className='profileCont-body-user'>
              <div>
                <div className='profileCont-body-user-avatar'>
                  <div className='profileCont-body-user-avatar-img'>
                    <img src={photo} alt='profile' />
                  </div>
                  <div className='profileCont-body-user-avatar-button'>
                    <span className='upload'>Cambiar foto de perfil</span>
                    <input
                      ref={coverImg}
                      name='imageCover'
                      id='test1'
                      type='file'
                      onChange={e => {
                        readURL()
                        uploadImage(e)
                      }}
                      accept='image/png, image/jpeg'
                    />
                  </div>
                </div>

                {!showChangePassword && (
                  <>
                    <div className='profileCont-body-user-input'>
                      <label>Tu nombre</label>
                      <input
                        type='text'
                        name='name'
                        value={name}
                        placeholder={'Nombre(s)'}
                        onChange={e => setName(e.target.value)}
                      />
                    </div>
                    <div className='profileCont-body-user-input'>
                      <label>Tu correo</label>
                      <input
                        readOnly
                        type='email'
                        name='email'
                        value={props.user.email}
                        placeholder={'correo@myoblek.com'}
                      />
                    </div>
                    <div className='profileCont-body-user-button'>
                      <span className='password__change' onClick={onClickPassword}>
                        Cambiar contraseña
                      </span>
                    </div>
                  </>
                )}

                {showChangePassword && (
                  <>
                    <div className='profileCont-body-user-input'>
                      <label>Contraseña actual</label>
                      <input
                        type='password'
                        name='currentPassword'
                        value={currentPassword}
                        placeholder='*****************'
                        pattern={passwordRegex()}
                        onChange={e => setCurrentPassword(e.target.value)}
                      />
                      <div className='password__feedback'>
                        {!isPasswordCorrect && <p className='incorrect__password'>Contraseña incorrecta.</p>}
                      </div>
                    </div>
                    <div className='profileCont-body-user-input'>
                      <label>Nueva contraseña</label>
                      <input
                        type='password'
                        name='password'
                        placeholder='Mínimo 8 caracteres.'
                        value={newPassword}
                        pattern={passwordRegex()}
                        onChange={e => setNewPassword(e.target.value)}
                      />
                      <div className='password__feedback'>
                        <p
                          className={
                            newPassword === ''
                              ? 'empty__newpassword'
                              : isNewPasswordStrong()
                              ? 'correct__newpassword'
                              : 'incorrect__newpassword'
                          }
                        >
                          Debe de contener una letra mayúscula, una minúscula, un número y un símbolo.
                        </p>
                      </div>
                    </div>
                    <div className='profileCont-body-user-input'>
                      <label>Confirmar nueva contraseña</label>
                      <input
                        type='password'
                        name='password2'
                        placeholder='Mínimo 8 caracteres'
                        value={passwordConfirmation}
                        onChange={e => setPasswordConfirmation(e.target.value)}
                      />
                      <div className='password__feedback'>
                        {passwordConfirmation.length >= 8 && passwordConfirmation !== newPassword && (
                          <p className='incorrect__newpassword'>
                            Los campos Nueva contraseña y Confirmar nueva contraseña no coinciden.
                          </p>
                        )}
                        {passwordConfirmation.length >= 8 && passwordConfirmation === newPassword && (
                          <p className='correct__newpassword'>
                            Los campos Nueva contraseña y Confirmar nueva contraseña coinciden.
                          </p>
                        )}
                      </div>
                    </div>
                    <div className='changepassword__buttons'>
                      <Button size='full' variant={'link'} theme={'dark'} onClick={onClickPassword}>
                        Cancelar
                      </Button>
                      <Button size={'full'} disabled={!passwordChangeReady()} onClick={handlePasswordSubmit}>
                        Guardar
                      </Button>
                    </div>
                  </>
                )}
              </div>

              {/* <div className="user__delete">
                <TrashIcon stroke={"#fe596f"}/>
                <button className="delete__text" onClick={() => console.log("Eliminar usuario.")}>Eliminar usuario</button>
              </div> */}
            </div>

            <div className='profileCont-body-data'>
              <BirthdayInput
                day={day}
                month={month}
                year={year}
                setDay={setDay}
                setMonth={setMonth}
                setYear={setYear}
                setDateError={setDateError}
                dateError={dateError}
              />
              <div className='profileCont-body-data-input input__gender_age'>
                <div className='profileCont-body-data-input input__gender'>
                  <label>Género</label>
                  <DataDropdown
                    width='100%'
                    title='Masculino, femenino, etc...'
                    items={genders}
                    selection={gender}
                    setSelection={setGender}
                    multiSelect={false}
                  />
                </div>
                <div className='profileCont-body-data-input input__age'>
                  <label htmlFor='user-data-age'>Edad</label>
                  <input
                    id='user-data-age'
                    type='number'
                    placeholder='Edad*'
                    min='1'
                    max='100'
                    value={age}
                    onChange={e => setAge(e.target.value)}
                  />
                </div>
              </div>

              <div className='profileCont-body-data-input'>
                <label>Ocupación</label>
                <DataDropdown
                  width='100%'
                  title='Marketing, comunicación, etc...'
                  items={occupations}
                  selection={occupation}
                  setSelection={setOccupation}
                  multiSelect={false}
                />
              </div>

              <div className='profileCont-body-data-input'>
                <label>Nivel de estudios</label>
                <DataDropdown
                  width='100%'
                  title='Licenciatura, ingeniería, maestría, etc...'
                  items={degrees}
                  selection={degree}
                  setSelection={setDegree}
                  multiSelect={false}
                />
              </div>

              {/* {(responseState.editSuccess || responseState.editError) && (
                <p className="error">{responseState.editMsg}</p>
              )} */}

              <div className='profileCont-body-data-button'>
                <Button
                  size={'full'}
                  className={'data__button'}
                  disabled={!dataHasChanged()}
                  onClick={e => handleUserSubmit(e)}
                >
                  Guardar cambios
                </Button>
              </div>
            </div>
          </div>
        </div>
      </ProfileContainer>

      {dataHasChangedModal && (
        <>
          <Shadow onClick={() => showDataHasChangedModal()} />
          <DataChangeModal closeDataChange={() => showDataHasChangedModal()} />
        </>
      )}
      {props.user.onboarding && (
        <>
          <Shadow onClick={getOnboarding} />
          <Onboarding onboardingChange={getOnboarding} navigation={props.history} />
        </>
      )}
    </MainContainer>
  )
}

export default Profile
