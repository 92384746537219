import React from 'react'
import { TablerosContainer } from '../../style/components/faqs/Tableros.styled'

// Imagenes
import Tablero1 from '../../assets/faqs/tableros/tableros1.png'
import Tablero2 from '../../assets/faqs/tableros/tableros2.png'
import Tablero3 from '../../assets/faqs/tableros/tableros3.png'

function Tableros({ tablerosRef, herramientasRef }) {
  return (
    <TablerosContainer id='tableros' ref={tablerosRef}>
      <h4>Página de tableros</h4>

      <p>
        También puedes cambiar el diseño de tu visualización de los tableros dándole clic a los tres puntos que se
        encuentran en la parte superior derecha de cada gráfica.
      </p>
      <img src={Tablero1} alt='Tablero 1' />

      <p>Puedes cambiar el nombre de cada gráfico dándole clic al botón de editar.</p>
      <img src={Tablero2} alt='Tablero 2' />

      <section id='herramientas' ref={herramientasRef}>
        <h5>Herramientas</h5>

        <p>
          Con el botón de herramientas puedes editar tu tablero cambiandole el nombre o la descripción o también puedes
          editar el acceso de los miembros invitados.
        </p>
        <img src={Tablero3} alt='Tablero 3' />
      </section>
    </TablerosContainer>
  )
}

export default Tableros
