import styled from 'styled-components'
import { colors } from '../../variables'

export const DropdownContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  outline: none;
  .dd-header {
    height: 50px;
    background: white;
    outline: none;
    border-color: gray;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 15px;
    .dd-header-title {
      p {
        text-align: left;
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
      }
    }
    .dd-header-action {
      svg {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
      }
    }
  }
  .dd-list {
    position: absolute;
    transform: translateY(50px);
    border-radius: 0 0 4px 4px;
    border-top: 2px solid ${colors.lightseaGreen};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    width: 225px;
    height: 142px;
    overflow-y: scroll;
    background: ${colors.white};
    .dd-list-item {
      width: 100%;
      height: 70px;
      button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 10px;
        border: none;
        outline: none;
        background: none;
        .dd-list-item-info {
          padding-right: 10px;
          display: flex;
          width: 100%;
          .dd-list-item-info-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            background: black;
            margin-right: 10px;
          }
          .dd-list-item-info-text {
            p {
              text-align: left;
              font-size: 12px;
              font-weight: 500;
              color: ${colors.richBlack};
              margin-bottom: 3px;
            }
            .dd-list-item-description {
              text-align: left;
              font-size: 12px;
              font-weight: 400;
              color: ${colors.darkGray};
            }
          }
        }
        .dd-list-item-check {
          height: 45px;
          width: 30px;
          svg {
            margin-top: 5px;
            width: 75%;
            height: 75%;
            stroke: ${colors.lightseaGreen};
          }
        }
      }
    }
  }
`
