import React, { useContext, useState } from 'react'
import { useQuery } from '@apollo/client'

import MediaContainer from '../../style/pages/media/Media.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar, Spinner } from '../../style/components'

import { FiFilter } from 'react-icons/fi'
import { BsArrowUpDown } from 'react-icons/bs'

import AllMedia from '../../components/media/AllMedia'
import MediaFilters from '../../components/media/MediaFilters'
import MediaOrder from '../../components/media/MediaOrder'

import { MEDIA_QUERY } from '../../services/queries'
import { MyContext } from '../../context'
import Button from '../../components/ui/Button'

const Media = props => {
  const uiCTX = useContext(UIContext)
  const {
    mainClass,
    topbarClass,
    toolbarClass,
    state: { collapsed }
  } = uiCTX

  const context = useContext(MyContext)

  const { data, loading, error } = useQuery(MEDIA_QUERY, { fetchPolicy: 'network-only' })

  const [section, setSection] = useState('all')
  const setAllSection = () => (section === 'all' ? 'section-button section-button-active' : 'section-button')
  const setPrintSection = () => (section === 'print' ? 'section-button section-button-active' : 'section-button')
  const setElectronicSection = () =>
    section === 'electronic' ? 'section-button section-button-active' : 'section-button'

  const [showOrder, setShowOrder] = useState(false)

  const [nameFilter, setNameFilter] = useState('')
  const [cobertura, setCoberturaFilter] = useState('')
  const [subtype, setSubtypeFilter] = useState([])
  const [status, setStatusFilter] = useState({ type: '', active: true }) //Default value is true - activo = true | inactivo = false
  const [states, setStates] = useState([])
  const [checkboxCoberturaEstatal, setCheckboxCoberturaEstatal] = useState(false)
  const [checkboxCoberturaNacional, setCheckboxCoberturaNacional] = useState(false)
  const [checkboxCoberturaInternacional, setCheckboxCoberturaInternacional] = useState(false)
  const [checkboxStatusActive, setCheckboxStatusActive] = useState(false)
  const [checkboxStatusInactive, setCheckboxStatusInactive] = useState(false)
  const [subtypeCheckbox, setSubtypeCheckbox] = useState({
    periódicos: false,
    revistas: false,
    radio: false,
    televisión: false,
    internet: false
  })

  const [namePF, setNamePF] = useState('')
  const [coberturaPF, setCoberturaPF] = useState('')
  const [subtypePF, setSubtypePF] = useState([])
  const [statusPF, setStatusPF] = useState({ type: '', active: true }) //Default value is true - activo = true | inactivo = false
  const [statesPF, setStatesPF] = useState([])
  const [checkboxCoberturaEstatalPF, setCheckboxCoberturaEstatalPF] = useState(false)
  const [checkboxCoberturaNacionalPF, setCheckboxCoberturaNacionalPF] = useState(false)
  const [checkboxCoberturaInternacionalPF, setCheckboxCoberturaInternacionalPF] = useState(false)
  const [checkboxStatusActivePF, setCheckboxStatusActivePF] = useState(false)
  const [checkboxStatusInactivePF, setCheckboxStatusInactivePF] = useState(false)
  const [subtypeCheckboxPF, setSubtypeCheckboxPF] = useState({
    periódicos: false,
    revistas: false,
    radio: false,
    televisión: false
  })

  const [nameEF, setNameEF] = useState('')
  const [coberturaEF, setCoberturaEF] = useState('')
  const [subtypeEF, setSubtypeEF] = useState([])
  const [statusEF, setStatusEF] = useState({ type: '', active: true }) //Default value is true - activo = true | inactivo = false
  const [statesEF, setStatesEF] = useState([])
  const [checkboxCoberturaEstatalEF, setCheckboxCoberturaEstatalEF] = useState(false)
  const [checkboxCoberturaNacionalEF, setCheckboxCoberturaNacionalEF] = useState(false)
  const [checkboxCoberturaInternacionalEF, setCheckboxCoberturaInternacionalEF] = useState(false)
  const [checkboxStatusActiveEF, setCheckboxStatusActiveEF] = useState(false)
  const [checkboxStatusInactiveEF, setCheckboxStatusInactiveEF] = useState(false)

  const [showAllFilters, setShowAllFilters] = useState(false)
  const [showPrintFilters, setShowPrintFilters] = useState(false)
  const [showElectronicFilters, setShowElectronicFilters] = useState(false)

  const [nameAZOrder, setNameAZOrder] = useState(false)
  const [nameZAOrder, setNameZAOrder] = useState(false)

  const resetFilters = () => {
    setNameFilter('')
    setCoberturaFilter('')
    setSubtypeFilter([])
    setStatusFilter({ type: '', active: true })
    setSubtypeCheckbox({
      periódicos: false,
      revistas: false,
      radio: false,
      televisión: false,
      internet: false
    })
  }

  const resetPF = () => {
    setNamePF('')
    setCoberturaPF('')
    setSubtypePF([])
    setStatusPF({ type: '', active: true })
    setSubtypeCheckboxPF({
      periódicos: false,
      revistas: false,
      radio: false,
      televisión: false
    })
  }

  const resetEF = () => {
    setNameEF('')
    setCoberturaEF('')
    setSubtypeEF([])
    setStatusEF({ type: '', active: true })
  }

  const resetOrder = () => {
    setNameAZOrder(false)
    setNameZAOrder(false)
  }

  const handleShowFilters = () => {
    if (section === 'all') setShowAllFilters(!showAllFilters)
    if (section === 'print') setShowPrintFilters(!showPrintFilters)
    if (section === 'electronic') setShowElectronicFilters(!showElectronicFilters)
  }

  if (error) context.handleErrors(error)

  if (loading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <MediaContainer>
        {showAllFilters && section === 'all' && (
          <MediaFilters
            sidebar={collapsed}
            showFilters={handleShowFilters}
            filters={{
              name: nameFilter,
              setName: setNameFilter,
              cobertura: cobertura,
              setCobertura: setCoberturaFilter,
              subtype: subtype,
              setSubtype: setSubtypeFilter,
              status: status,
              setStatus: setStatusFilter,
              states: states,
              setStates: setStates,
              checkboxCoberturaEstatal: checkboxCoberturaEstatal,
              setCheckboxCoberturaEstatal: setCheckboxCoberturaEstatal,
              checkboxCoberturaNacional: checkboxCoberturaNacional,
              setCheckboxCoberturaNacional: setCheckboxCoberturaNacional,
              checkboxCoberturaInternacional: checkboxCoberturaInternacional,
              setCheckboxCoberturaInternacional: setCheckboxCoberturaInternacional,
              checkboxStatusActive: checkboxStatusActive,
              setCheckboxStatusActive: setCheckboxStatusActive,
              checkboxStatusInactive: checkboxStatusInactive,
              setCheckboxStatusInactive: setCheckboxStatusInactive,
              subtypeCheckbox: subtypeCheckbox,
              setSubtypeCheckbox: setSubtypeCheckbox
            }}
            resetFilters={resetFilters}
            digCheck={true}
          />
        )}

        {showPrintFilters && section === 'print' && (
          <MediaFilters
            sidebar={collapsed}
            showFilters={handleShowFilters}
            filters={{
              test: 'hola',
              name: namePF,
              setName: setNamePF,
              cobertura: coberturaPF,
              setCobertura: setCoberturaPF,
              subtype: subtypePF,
              setSubtype: setSubtypePF,
              status: statusPF,
              setStatus: setStatusPF,
              states: statesPF,
              setStates: setStatesPF,
              checkboxCoberturaEstatal: checkboxCoberturaEstatalPF,
              setCheckboxCoberturaEstatal: setCheckboxCoberturaEstatalPF,
              checkboxCoberturaNacional: checkboxCoberturaNacionalPF,
              setCheckboxCoberturaNacional: setCheckboxCoberturaNacionalPF,
              checkboxCoberturaInternacional: checkboxCoberturaInternacionalPF,
              setCheckboxCoberturaInternacional: setCheckboxCoberturaInternacionalPF,
              checkboxStatusActive: checkboxStatusActivePF,
              setCheckboxStatusActive: setCheckboxStatusActivePF,
              checkboxStatusInactive: checkboxStatusInactivePF,
              setCheckboxStatusInactive: setCheckboxStatusInactivePF,
              subtypeCheckbox: subtypeCheckboxPF,
              setSubtypeCheckbox: setSubtypeCheckboxPF
            }}
            resetFilters={resetPF}
            digCheck={false}
          />
        )}

        {showElectronicFilters && section === 'electronic' && (
          <MediaFilters
            sidebar={collapsed}
            showFilters={handleShowFilters}
            filters={{
              name: nameEF,
              setName: setNameEF,
              cobertura: coberturaEF,
              setCobertura: setCoberturaEF,
              subtype: subtypeEF,
              setSubtype: setSubtypeEF,
              status: statusEF,
              setStatus: setStatusEF,
              states: statesEF,
              setStates: setStatesEF,
              checkboxCoberturaEstatal: checkboxCoberturaEstatalEF,
              setCheckboxCoberturaEstatal: setCheckboxCoberturaEstatalEF,
              checkboxCoberturaNacional: checkboxCoberturaNacionalEF,
              setCheckboxCoberturaNacional: setCheckboxCoberturaNacionalEF,
              checkboxCoberturaInternacional: checkboxCoberturaInternacionalEF,
              setCheckboxCoberturaInternacional: setCheckboxCoberturaInternacionalEF,
              checkboxStatusActive: checkboxStatusActiveEF,
              setCheckboxStatusActive: setCheckboxStatusActiveEF,
              checkboxStatusInactive: checkboxStatusInactiveEF,
              setCheckboxStatusInactive: setCheckboxStatusInactiveEF
            }}
            resetFilters={resetEF}
            digCheck={false}
          />
        )}

        {showOrder && (
          <MediaOrder
            showOrder={setShowOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder
            }}
            resetOrder={resetOrder}
          />
        )}

        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <h1>Catálogo de medios</h1>
            <Button
              size='medium'
              height='40px'
              theme={'secondary'}
              variant='outline'
              onClick={() => props.history.push('/media-catalogue/request-media')}
            >
              Solicitar medio
            </Button>
          </div>
        </Topbar>

        <Toolbar className={toolbarClass()}>
          <div className='left-toolbar'>
            <button className={setAllSection()} onClick={() => setSection('all')}>
              Todos
            </button>
            <button className={setPrintSection()} onClick={() => setSection('print')}>
              Tradicionales
            </button>
            <button className={setElectronicSection()} onClick={() => setSection('electronic')}>
              Digitales
            </button>
          </div>

          <div className='right-toolbar'>
            <button
              className='toolbar-button'
              onClick={() => {
                handleShowFilters()
              }}
            >
              <FiFilter className='toolbar-icon' />
              <p>Filtrar</p>
            </button>
            <button className='toolbar-button' onClick={() => setShowOrder(true)}>
              <BsArrowUpDown className='toolbar-icon gray-icon' />
              <p>Ordenar</p>
            </button>
          </div>
        </Toolbar>

        {section === 'all' && (
          <AllMedia
            filtersOpen={showAllFilters}
            media={error ? [] : data.media}
            filters={{
              name: nameFilter,
              cobertura: cobertura,
              subtype: subtype,
              status: status,
              states: states,
              setStates: setStates
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder
            }}
          />
        )}

        {section === 'print' && (
          <AllMedia
            filtersOpen={showPrintFilters}
            media={error ? [] : data.media.filter(m => m.medio !== 8)}
            filters={{
              name: namePF,
              cobertura: coberturaPF,
              subtype: subtypePF,
              status: statusPF,
              states: statesPF,
              setStates: setStatesPF
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder
            }}
          />
        )}

        {section === 'electronic' && (
          <AllMedia
            filtersOpen={showElectronicFilters}
            media={error ? [] : data.media.filter(m => m.medio === 8)}
            filters={{
              name: nameEF,
              cobertura: coberturaEF,
              subtype: subtypeEF,
              status: statusEF,
              states: statesEF,
              setStates: setStatesEF
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder
            }}
          />
        )}
      </MediaContainer>
    </MainContainer>
  )
}

export default Media
