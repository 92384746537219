import { useQuery } from '@apollo/client'
import React from 'react'
import { useParams } from 'react-router'
import { GET_CLIPPING } from '../../services/queries'
import { Spinner } from '../../style/components'

const Clipping = () => {
  const { id } = useParams()

  const { data, loading } = useQuery(GET_CLIPPING, { variables: { id } })

  if (loading) return <Spinner />

  const notes = JSON.parse(data.getClipping.notes)

  return (
    <>
      <p>Esta es la informacion</p>
      <br />
      {notes.notes.map((e, i) => (
        <div key={i}>
          <p>Titulo: {e.titulo}</p>
          <p>Ambito: {e.ambito}</p>
          <p>Clasificación: {e.clasificacion}</p>
          <p>Estado: {e.estado}</p>
          <p>Autor: {e.autor}</p>
          <p>Texto: {e.texto}</p>
          <br />
        </div>
      ))}
    </>
  )
}

export default Clipping
