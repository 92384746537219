import React, { useState } from 'react'
import CardFormContainer from '../../style/components/workspace/NewPaymentMethod.styled'
import { colors } from '../../style/variables'
import { loadStripe } from '@stripe/stripe-js'
import { CardElement, Elements, useStripe, useElements } from '@stripe/react-stripe-js'
import { useMutation } from '@apollo/client'

import CloseModal from './Modals/CloseModal'

import { ATTACH_PAYMENT_METHOD } from '../../services/mutations'
import { ButtonSpinner } from '../../style/components'
import Button from '../ui/Button'

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY)
if (!process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY) console.error('Stripe publishable key enviroment variable not set')

const CardForm = ({ setNewCard, backEvent }) => {
  const stripe = useStripe()
  const elements = useElements()

  const [attachPaymentMethod, { loading: attachPaymentMethodLoading }] = useMutation(ATTACH_PAYMENT_METHOD)

  const [cardName, setCardName] = useState('')
  const [errorMessage, setErrorMessage] = useState('')

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [cardComplete, setCardComplete] = useState(false)

  const errorDictionary = {
    'Error: Your card was declined.':
      'Lo sentimos, la tarjeta que intentas registrar fue declinada. Revisa que todo esté en orden con tu institución financiera o intenta registrando otra tarjeta.',
    "Error: Your card's security code is incorrect.":
      'Lo sentimos, el código de seguridad de la tarjeta es incorrecto. Revisa que lo hayas registrado correctamente o intenta registrando otra tarjeta.',
    'Error: Your card has insufficient funds.':
      'Lo sentimos, los fondos de la tarjeta son insuficientes. Revisa que todo esté en orden con tu institución financiera o  intenta registrando otra tarjeta.',
    'Error: Your card has expired.':
      'Lo sentimos, la tarjeta que intentas registrar está expirada. Por favor intenta registrando otra tarjeta.',
    'Error: An error occurred while processing your card. Try again in a little bit.':
      'Lo sentimos, ocurrió un error inesperado. Por favor intenta hacer tu registro mas tarde.'
  }

  const handleSubmit = async e => {
    const cardElement = elements.getElement(CardElement)

    const { error, paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      billing_details: {
        name: cardName
      },
      card: cardElement
    })

    if (error) {
      setErrorMessage(error)
      setShowErrorMessage(true)
    } else {
      try {
        await attachPaymentMethod({
          variables: { paymentMethodId: paymentMethod.id }
        })
        setNewCard(false)
        backEvent && backEvent()
      } catch ({ message }) {
        setErrorMessage(message)
        setShowErrorMessage(true)
      }
    }
  }

  return (
    <CardFormContainer>
      <CloseModal onClick={() => setNewCard(false)} />
      <div className='filters--header'>
        <h3>Nuevo método de pago</h3>
      </div>
      <div className='newpayment__subtitle'>
        <p className='newpaymentsubtitle__text'>Tarjeta de crédito o débito</p>
        {/* <p className="newpaymentsubtitle__img">
          placeholder para imagenes de tipo tarjetas.
        </p> */}
      </div>

      <input required onChange={e => setCardName(e.target.value)} placeholder='Nombre en la tarjeta' />

      <div className='card-element'>
        <CardElement
          options={{
            hidePostalCode: true,
            style: {
              base: {
                fontSize: '14px',
                color: '#32325d',
                fontFamily: 'Montserrat',
                '::placeholder': {
                  color: `${colors.darkGray}`
                }
              },
              invalid: {
                color: `${colors.red}`
              }
            }
          }}
          onChange={e => setCardComplete(e.complete)}
        />
      </div>
      {showErrorMessage && <p>{errorDictionary[errorMessage] ? errorDictionary[errorMessage] : errorMessage}</p>}
      <Button
        theme={'primary'}
        width={'250px'}
        size={'full'}
        className={'submit-button'}
        disabled={cardComplete && cardName.length > 5 ? false : true || attachPaymentMethodLoading}
        onClick={handleSubmit}
      >
        {attachPaymentMethodLoading ? <ButtonSpinner /> : 'Añadir'}
      </Button>
    </CardFormContainer>
  )
}

const NewPaymentMethod = props => (
  <Elements stripe={stripePromise}>
    <CardForm {...props} />
  </Elements>
)

export default NewPaymentMethod
