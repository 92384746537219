import styled from 'styled-components'
import { colors } from '../../../variables'

export const CodeModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  padding: 40px;
  border-radius: 20px;
  background: ${colors.white};

  h3 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 116px;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px !important;
    font-weight: 400;
  }
  button {
    width: 100%;
    height: 50px;
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    &.button-inactive {
      background: ${colors.white};
      border: 2px solid ${colors.purple};
      color: ${colors.purple};
      cursor: not-allowed;
    }
    &.button-active {
      background: ${colors.purple};
      border: none;
      color: ${colors.white};
      cursor: pointer;
    }
  }
`
