import styled from 'styled-components'
import { colors } from '../../../variables'

export const InfoContainer = styled.div`
  width: 632px;
  height: 391px;
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  background: ${colors.white};

  .modal__close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;

      path {
        stroke: ${colors.darkGray};
      }
    }
  }

  h3 {
    margin-bottom: 15px;
    font-size: 24px;
    font-weight: 800;
  }

  .messageInfo {
    color: ${colors.purple};
    margin-bottom: 5px;
  }

  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .changeplan__request__paragraph {
    margin-bottom: 45px;
  }

  .rememberInfo {
    margin: 0;
  }

  .next__payment__container {
    margin-bottom: 66px;

    .next__payment__text {
      font-size: 22px;
    }
  }
`
export const DetailContainer = styled.div`
  width: 650px;
  height: 50%;
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  background: ${colors.white};

  h3 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .statusAcount {
    width: 100%;
  }
  #scrollable {
    overflow: auto;
    height: 40%;
  }
  .statusAcount .acoutnItem {
    width: 100%;
    display: flex;
    justify-content: space-between;
  }
  .statusAcount .acoutnItem .leftContainer {
    width: 55%;
  }
  .statusAcount .acoutnItem .rightContainer p {
    font-size: 22px;
  }
  .statusAcount .acoutnItem .leftContainer span {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 22px;
    color: ${colors.richBlack};
  }
  .statusAcount .acoutnItem .rightContainer span {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 14px;
    color: #748596;
  }
  .statusAcount .acoutnItem .rightContainer.card {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .statusAcount .borderDecoration {
    border-top: 1px solid ${colors.silverSand};
    border-bottom: 1px solid ${colors.silverSand};
    padding-top: 19px;
  }
  .statusAcount .acoutnMetod {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
  .statusAcount .acoutnMetod .leftContainer {
    width: 50%;
  }
  .statusAcount .acoutnMetod .rightContainer {
    width: 50%;
    display: flex;
  }
  .statusAcount .acoutnMetod .rightContainer p {
    margin-left: 3px;
    text-align: right;
  }
  .statusAcount .acoutnMetod .leftContainer span.metodoPago {
    color: ${colors.lightseaGreen};
  }
`
