import React from 'react'
import { useHistory } from 'react-router-dom'

import { ReportLimitWarningContainer } from '../../../style/components/reports/reports/ReportLimitWarning.styled'
import CloseModal from '../../workspace/Modals/CloseModal'
import Button from '../../ui/Button'

const ReportLimitWarning = ({ reportType, closeModal }) => {
  const history = useHistory()

  return (
    <ReportLimitWarningContainer>
      <CloseModal onClick={closeModal} />
      <h3>¡Atención!</h3>
      {reportType === 'once' && (
        <p>
          Tu plan <span>Gratuito</span> solo te permite generar <span>1</span> reporte al día. Te sugerimos actualizar a
          un plan de pago para contar con mayores beneficios y aprovechar el potencial de la plataforma.
        </p>
      )}
      {reportType === 'scheduled' && (
        <p>
          Tu plan <span>Gratuito</span> solo te permite generar <span>1</span> reporte programado. Te sugerimos
          actualizar a un plan de pago para contar con mayores beneficios y aprovechar el potencial de la plataforma.
        </p>
      )}
      <div className='modal-buttons'>
        <Button theme={'primary'} size={'full'} onClick={() => history.push('/workspace?plans')}>
          Actualizar plan
        </Button>
      </div>
    </ReportLimitWarningContainer>
  )
}

export default ReportLimitWarning
