import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const RegistrContainer = styled.div`
  width: 100%;
  h4 {
    padding-top: 41px;

    font-family: Montserrat;
    font-size: 22px;
    color: ${colors.lightseaGreen};
  }
  p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.richBlack};
    text-align: left;
  }
  strong {
    font-family: Montserrat;
    font-weight: bold 400;
    font-size: 16px;
    color: ${colors.richBlack};
    text-align: left;
  }
  img {
    /* border: 1px solid blue; */
    display: block;
    margin: 32px auto;

    max-width: 650px;
    min-height: 357px;
    max-height: 357px;
  }
`
