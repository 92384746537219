import React, { useState } from 'react'
import EditSearchContainer from '../../../style/components/search/searches/EditSearch.styled'
import CloseModal from '../../workspace/Modals/CloseModal'
import { useHistory } from 'react-router-dom'
import DuplicateSearchContainer from '../../../style/components/search/searches/DuplicateSearch.styled'
import Button from '../../ui/Button'

const DuplicateSearch = ({ search, closeModal, duplicateSearch }) => {
  const [name, setName] = useState(search.name)

  return (
    <DuplicateSearchContainer>
      <CloseModal onClick={closeModal} />
      <h3 className='boardinfo__title'>Duplicar búsqueda</h3>
      <p>
        Duplicar una búsqueda creará una nueva versión de todos los parámetros. El duplicado no se vinculará a los
        gráficos y tableros de la búsqueda original.
      </p>
      <div className='boardinfo__form'>
        <label className='boardinfo__text'>Nombre del duplicado</label>
        <input
          className='boardinfo__input'
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder={name !== '' ? name : 'Nombre de la búsqueda'}
        />
      </div>
      <div className='boardinfo__buttons'>
        <Button
          size={'medium'}
          height={'50px'}
          variant={'link'}
          theme={'dark'}
          className='boardinfo__button'
          onClick={closeModal}
        >
          Cancelar
        </Button>
        <Button
          theme={'primary'}
          size={'medium'}
          height={'50px'}
          className={'boardinfo__button'}
          disabled={!name}
          onClick={() => duplicateSearch(search.id, name)}
        >
          Duplicar
        </Button>
      </div>
    </DuplicateSearchContainer>
  )
}

export default DuplicateSearch
