import React from 'react'

const IconTrash = ({ stroke }) => {
  return (
    <svg width='21px' height='24px' viewBox='0 0 21 24' version='1.1' xmlns='http://www.w3.org/2000/svg'>
      <title>icon trash</title>
      <g
        id='Symbols'
        stroke='none'
        strokeWidth='1'
        fill='none'
        fillRule='evenodd'
        strokeLinecap='round'
        strokeLinejoin='round'
      >
        <g
          id='icons/general/trash/primary'
          transform='translate(-2.000000, -1.000000)'
          stroke={stroke ? stroke : '#00AAAB'}
        >
          <path
            d='M20.7135417,7.97285068 L4.28645833,7.97285068 C3.69270833,7.97285068 3,7.47511312 3,6.67873303 L3,4.58823529 C3,3.99095023 3.49479167,3.29411765 4.28645833,3.29411765 L20.7135417,3.29411765 C21.3072917,3.29411765 22,3.7918552 22,4.58823529 L22,6.77828054 C22,7.37556561 21.5052083,7.97285068 20.7135417,7.97285068 Z M19.4270833,7.97285068 L19.4270833,22.7058824 C19.4270833,23.3031674 18.9322917,24 18.140625,24 L6.859375,24 C6.265625,24 5.57291667,23.5022624 5.57291667,22.7058824 L5.57291667,7.97285068 M10.0260417,2 L15.0729167,2 L15.0729167,3.29411765 L10.0260417,3.29411765 L10.0260417,2 Z M8.24479167,10.1628959 L8.24479167,21.6108597 M12.5,10.1628959 L12.5,21.6108597 M16.8541667,10.1628959 L16.8541667,21.6108597'
            id='icon-trash'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default IconTrash
