import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const ModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 700px;
  padding: 60px;
  border-radius: 4px;
  background: ${colors.white};
  height: 520px;
  .error-message {
    color: ${colors.red};
    margin: 15px;
    font-weight: bold;
  }
  h3 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
`

export const ShareURLContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  input {
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 0 10px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
  }
`
