import React from 'react'
import { GrClose } from 'react-icons/gr'

import CloseModalContainer from '../../../style/components/workspace/Modals/CloseModal.styled'

const CloseModal = ({ onClick }) => {
  return (
    <CloseModalContainer>
      <div className='close__icon'>
        <GrClose onClick={onClick} />
      </div>
    </CloseModalContainer>
  )
}

export default CloseModal
