import React from 'react'
import { ScheduleReportContainer } from '../../style/components/reports/ScheduleReport.styled'
import PeriodDropdown from '../ui/PeriodDropdown'

const periods = [
  {
    id: '0',
    name: 'Personalizado'
  },
  {
    id: '1',
    name: 'Diario'
  },
  {
    id: '2',
    name: 'Semanal'
  },
  {
    id: '3',
    name: 'Quincenal'
  },
  {
    id: '4',
    name: 'Mensual'
  }
]

const daysArr = [
  {
    name: 'Lunes',
    value: 1
  },
  {
    name: 'Martes',
    value: 2
  },
  {
    name: 'Miércoles',
    value: 3
  },
  {
    name: 'Jueves',
    value: 4
  },
  {
    name: 'Viernes',
    value: 5
  },
  {
    name: 'Sábado',
    value: 6
  },
  {
    name: 'Domingo',
    value: 7
  }
]

const ScheduleReport = ({ schedule, setSchedule, days, handleDays }) => {
  return (
    <ScheduleReportContainer>
      <h2>Recibir el reporte</h2>
      <PeriodDropdown
        width='100%'
        title='Elige cuándo recibirás tu reporte'
        items={periods}
        selection={schedule}
        setSelection={setSchedule}
        multiSelect={false}
      />
      {schedule[0] && schedule[0].name === 'Personalizado' && (
        <div className='schedule-checkboxes'>
          {daysArr.map((day, idx) => (
            <label key={idx} htmlFor={day.name}>
              <input
                type='checkbox'
                id={day.name}
                value={day.value}
                checked={days.includes(day.value) ? true : false}
                onChange={() => handleDays(day.value)}
              />
              {day.name}
            </label>
          ))}
        </div>
      )}
    </ScheduleReportContainer>
  )
}

export default ScheduleReport
