import styled from 'styled-components'
import { colors } from '../../style/variables'
import React, { useEffect, useRef, useState } from 'react'
import { MdKeyboardArrowDown } from 'react-icons/all'
import { isNumber } from 'recharts/lib/util/DataUtils'
import AutoCompleteContainer from '../../style/components/ui/AutoComplete.styled'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const AutoComplete = ({ options, type, id, placeholder, value, onChange }) => {
  const [showList, setShowList] = useState(false)
  const ref = useRef(null)
  useOutsideClick(ref, () => setShowList(false))

  const filteredOptions = options.filter(option => option.toString().includes(value ? value.toString() : ''))

  return (
    <AutoCompleteContainer>
      <div ref={ref}>
        <div className={'input-container'}>
          <input
            autoComplete={'off'}
            onFocus={() => setShowList(true)}
            value={value || ''}
            onChange={e => onChange(parseInt(e.target.value))}
            placeholder={placeholder}
            type={type}
            id={id}
          />
          <div
            onClick={e => {
              setShowList(prev => !prev)
              e.stopPropagation()
            }}
            className={'icon-container'}
          >
            <MdKeyboardArrowDown />
          </div>
        </div>
        {showList && !!filteredOptions.length && (
          <div className={'options-container'}>
            {filteredOptions.map(option => (
              <div
                className={'option'}
                key={option}
                onClick={() => {
                  onChange(option)
                  setShowList(false)
                }}
              >
                {option}
              </div>
            ))}
          </div>
        )}
      </div>
    </AutoCompleteContainer>
  )
}

export default AutoComplete
