import React, { useContext, useState } from 'react'
import InviteForm from '../../style/components/workspace/Invite.styled'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import Dropdown from './Dropdown'
import { CREATE_INVITE } from '../../services/mutations'
import Notification from './Modals/Notification'
import { MyContext } from '../../context'
import { GET_WORKSPACE_INVITES, REQUESTED_MEDIA_QUERY } from '../../services/queries'

const Invite = ({ setLimitWarning }) => {
  const [createInvite, { loading }] = useMutation(CREATE_INVITE, {
    update(cache, { data: { createInvite } }) {
      const { getWorkspaceInvites } = cache.readQuery({
        query: GET_WORKSPACE_INVITES
      })
      cache.writeQuery({
        query: GET_WORKSPACE_INVITES,
        data: {
          getWorkspaceInvites: [...getWorkspaceInvites, createInvite]
        }
      })
    }
  })

  const validationSchema = Yup.object({
    email: Yup.string().required('El email es obligatorio.').email('El email no se válido')
  })

  const [message, setMessage] = useState(null)
  const [inviteRole, setInviteRole] = useState('Selecciona un rol')
  const { showNotifications, setNotificationText } = useContext(MyContext)

  const showMessage = () => (
    <div>
      <p>{message}</p>
    </div>
  )

  return (
    <>
      <Formik
        validationSchema={validationSchema}
        enableReinitialize
        initialValues={{
          email: ''
        }}
        onSubmit={async (values, { resetForm }) => {
          const { email } = values
          const role = inviteRole
          try {
            await createInvite({
              variables: {
                input: {
                  email,
                  role
                }
              }
            })
            setInviteRole('Selecciona un rol')
            resetForm()
            showNotifications()
            setNotificationText(
              `Se envió la invitación de colaboración a ${email}. El usuario debe confirmar en su correo para ingresar.`
            )
          } catch (error) {
            if (error.message.includes('límite')) {
              const plan = error.message.split(' ')[0].toLowerCase()
              setLimitWarning({
                error: true,
                plan: plan.charAt(0).toUpperCase() + plan.slice(1),
                quantity: error.message.split(' ')[1]
              })
            } else {
              setMessage(error.message)
              setTimeout(() => {
                setMessage(null)
              }, 3000)
            }
          }
        }}
      >
        {props => {
          return (
            <InviteForm onSubmit={props.handleSubmit}>
              <label className='inviteform__label' htmlFor='email'>
                Dirección de correo
              </label>
              <input
                className='inviteform__input'
                id='email'
                placeholder='usuario@myoblek.com'
                value={props.values.email}
                onChange={props.handleChange}
                onBlur={props.handleBlur}
              />
              <label className='inviteform__label' htmlFor='role'>
                Cargo
              </label>
              <Dropdown inviteRole={inviteRole} setInviteRole={setInviteRole} />
              <input
                className='submit-button'
                type='submit'
                value='Invitar colaborador'
                disabled={
                  props.values.email && !props.errors.email && inviteRole !== 'Selecciona un rol' && !loading
                    ? false
                    : true
                }
                style={
                  props.values.email && !props.errors.email && inviteRole !== 'Selecciona un rol'
                    ? { background: '#66fcf1', color: 'black' }
                    : { background: '#E7E8EA', color: 'black' }
                }
              />
              <div>{props.touched.email && props.errors.email ? <p>{props.errors.email}</p> : null}</div>
              {message && showMessage()}
            </InviteForm>
          )
        }}
      </Formik>
    </>
  )
}

export default Invite
