import React, { useEffect, useState } from 'react'
import { UserDataContainer, UserDataForm, UserMoreDataForm } from '../../style/components/auth/UserData/UserData.syled'
import { ButtonSpinner } from '../../style/components'
import DataDropdown from '../ui/DataDropdown'
import { degrees, genders, occupations } from '../../data/Data'
import Button from '../ui/Button'

const UserData = ({ user, userValidation, handleCreateCustomer, loading, userValidationStep2 }) => {
  // TODO:
  // 1. Validación sólo letras ?
  // 2. Validación de correo
  // 3. Validación de contraseña

  const [step, setStep] = useState(1)
  useEffect(() => {
    setStep(1)
  }, [user.workspaceError, user.emailError])

  const setDegree = degree => {
    if (degree.length === 0) {
      user.setDegree('')
      user.setDegreeError('El nivel de estudios es obligatorio.')
    } else {
      user.setDegree(degree[0])
      user.setDegreeError(null)
    }
  }
  const setOccupation = occupation => {
    if (occupation.length === 0) {
      user.setOccupation('')
      user.setOccupationError('La ocupación es obligatoria.')
    } else {
      user.setOccupation(occupation[0])
      user.setOccupationError(null)
    }
  }
  const setGender = gender => {
    if (gender.length === 0) {
      user.setGender('')
      user.setGenderError('El género es obligatorio.')
    } else {
      user.setGender(gender[0])
      user.setGenderError(null)
    }
  }

  const handleEmailConfirmationBlur = () => {
    if (user.emailConfirmation === '') {
      user.setEmailConfirmationError('Debes validar tu correo')
    } else {
      if (!user.emailConfirmationError === 'Los correos no coinciden') user.setEmailConfirmationError(null)
    }
  }

  const handlePasswordConfirmationBlur = () => {
    if (user.passwordConfirmation === '') {
      user.setPasswordConfirmationError('Debes validar tu contraseña')
    } else {
      if (!user.passwordConfirmationError === 'Las contraseñas no coinciden') user.setPasswordConfirmationError(null)
    }
  }

  return (
    <UserDataContainer>
      <section className='left-information'>
        {step === 1 ? (
          <div>
            <h1>Crea una nueva cuenta</h1>
            <p>Para comenzar necesitamos que nos proporciones los datos a continuación.</p>
          </div>
        ) : (
          <div>
            <h1>Gracias por elegirnos</h1>
            <p>Los siguientes datos nos ayudan a crecer y brindarte un mejor servicio.</p>
          </div>
        )}
        <p className='privacy-note'>
          Tu información está segura, si tienes dudas puedes consultar nuestro{' '}
          <a href='https://www.myoblek.com/aviso' target='_blank' rel='noopener noreferrer'>
            Aviso de Privacidad
          </a>
        </p>

        <Button
          size={'full'}
          weight={500}
          theme={'secondary'}
          onClick={step === 1 ? () => setStep(2) : handleCreateCustomer}
          disabled={(step === 1 ? userValidation() : userValidationStep2()) || loading}
        >
          {loading ? <ButtonSpinner /> : step === 1 ? 'Continuar' : 'Siguiente: elige tu plan'}
        </Button>
      </section>

      <UserDataForm>
        {step === 1 && (
          <div className='step-one'>
            <h2>Tus datos</h2>
            <p className={'subtittle'}>
              Ingresa tus datos para comenzar. Los campos marcados con * son campos obligatorios
            </p>

            <label htmlFor='user-data-name'>Tu nombre</label>
            <input
              id='user-data-name'
              type='text'
              placeholder='Tu nombre *'
              value={user.name}
              onChange={e => user.setName(e.target.value)}
              onBlur={() =>
                user.name === '' ? user.setNameError('El nombre es obligatorio') : user.setNameError(null)
              }
              onKeyUp={() =>
                user.name === '' ? user.setNameError('El nombre es obligatorio') : user.setNameError(null)
              }
            />
            <div className='form-error'>{user.nameError && <p>{user.nameError}</p>}</div>

            <label htmlFor='user-data-workspace'>Nombre de tu espacio de trabajo</label>
            <input
              id='user-data-workspace'
              type='text'
              placeholder='Nombre de tu espacio de trabajo *'
              value={user.workspace}
              onChange={e => user.setWorkspace(e.target.value)}
              onBlur={() =>
                user.workspace === ''
                  ? user.setWorkspaceError('El nombre del espacio de trabajo es obligatorio')
                  : user.setWorkspaceError(null)
              }
              onKeyUp={() =>
                user.workspace === ''
                  ? user.setWorkspaceError('El nombre del espacio de trabajo es obligatorio')
                  : user.setWorkspaceError(null)
              }
            />
            <div className='form-error'>{user.workspaceError && <p>{user.workspaceError}</p>}</div>

            <label htmlFor='user-data-email'>Correo</label>
            <input
              id='user-data-email'
              type='email'
              placeholder='Correo *'
              value={user.email}
              onChange={e => user.setEmail(e.target.value)}
              onBlur={() =>
                user.email === '' ? user.setEmailError('El correo es obligatorio') : user.setEmailError(null)
              }
              onKeyUp={() => {
                user.email === '' ? user.setEmailError('El correo es obligatorio') : user.setEmailError(null)
                user.handleEmailConfirmation()
              }}
            />
            <div className='form-error'>{user.emailError && <p>{user.emailError}</p>}</div>

            <label htmlFor='user-data-email-confirmation'>Verifica tu correo</label>
            <input
              id='user-data-email-confirmation'
              type='email'
              placeholder='Verifica tu correo *'
              value={user.emailConfirmation}
              onChange={e => user.setEmailConfirmation(e.target.value)}
              onBlur={() => handleEmailConfirmationBlur()}
              onKeyUp={() => user.handleEmailConfirmation()}
            />
            <div className='form-error'>{user.emailConfirmationError && <p>{user.emailConfirmationError}</p>}</div>

            <label htmlFor='user-data-password'>Contraseña</label>
            <input
              id='user-data-password'
              type='password'
              placeholder='Contraseña *'
              value={user.password}
              onChange={e => user.setPassword(e.target.value)}
              onBlur={() =>
                user.password === ''
                  ? user.setPasswordError('La contraseña es obligatoria')
                  : user.setPasswordError(null)
              }
              onKeyUp={() => {
                user.password === ''
                  ? user.setPasswordError('La contraseña es obligatoria')
                  : user.setPasswordError(null)
                user.handlePasswordConfirmation()
              }}
            />
            <div className='form-error'>{user.passwordError && <p>{user.passwordError}</p>}</div>

            <label htmlFor='user-data-password-confirmation'>Verifica la contraseña</label>
            <input
              id='user-data-password-confirmation'
              type='password'
              placeholder='Verifica la contraseña *'
              value={user.passwordConfirmation}
              onChange={e => user.setPasswordConfirmation(e.target.value)}
              onBlur={() => handlePasswordConfirmationBlur()}
              onKeyUp={() => user.handlePasswordConfirmation()}
            />
            <div className='form-error'>
              {user.passwordConfirmationError && <p>{user.passwordConfirmationError}</p>}
            </div>
          </div>
        )}

        {step === 2 && (
          <div>
            <h2>Tus datos</h2>
            <p className={'subtittle'}>
              Ingresa tus datos para comenzar. Los campos marcados con * son campos obligatorios
            </p>

            <label htmlFor='user-data-age'>Edad</label>
            <input
              id='user-data-age'
              type='number'
              placeholder='Edad*'
              min='1'
              max='100'
              value={user.age}
              onChange={e => user.setAge(e.target.value)}
              onBlur={() => (user.age === '' ? user.setAgeError('La edad es obligatoria') : user.setAgeError(null))}
              onKeyUp={() => (user.age === '' ? user.setAgeError('La edad es obligatoria') : user.setAgeError(null))}
            />
            <div className='form-error'>{user.ageError && <p>{user.ageError}</p>}</div>

            <DataDropdown
              width='100%'
              title='Nivel de estudios*'
              items={degrees}
              selection={[user.degree]}
              setSelection={setDegree}
              multiSelect={false}
            />
            <div className='form-error'>{user.degreeError && <p>{user.degreeError}</p>}</div>

            <DataDropdown
              width='100%'
              title='Ocupación*'
              items={occupations}
              selection={[user.occupation]}
              setSelection={setOccupation}
              multiSelect={false}
            />
            <div className='form-error'>{user.occupationError && <p>{user.occupationError}</p>}</div>

            <DataDropdown
              width='100%'
              title='Género*'
              items={genders}
              selection={[user.gender]}
              setSelection={setGender}
              multiSelect={false}
            />
            <div className='form-error'>{user.genderError && <p>{user.genderError}</p>}</div>
          </div>
        )}
      </UserDataForm>
    </UserDataContainer>
  )
}

export default UserData
