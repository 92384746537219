import React from 'react'
import { ModalContainer, Card } from '../../../style/components/workspace/Modals/PaymenthSelector.styled'

import CloseModal from './CloseModal'

// Iconos
import { FaCcVisa, FaCcMastercard } from 'react-icons/fa'
import { BsCheckBox, BsSquare } from 'react-icons/bs'
import { useMutation } from '@apollo/client'
import { Spinner } from '../../../style/components'

import { MAKE_DEFAULT_PAYMENT_METHOD } from '../../../services/mutations'
import Button from '../../ui/Button'

const PaymenthSelector = ({
  loading,
  errorPaymenth,
  setShowPaymenthSelector,
  refetchPM,
  cards,
  handleNewPaymenthMethod,
  handleAddPaymenthMethod
}) => {
  const [makeDefaultPaymentMethod] = useMutation(MAKE_DEFAULT_PAYMENT_METHOD)

  const handleDefaultPaymentMethod = async paymentMethodId => {
    await makeDefaultPaymentMethod({ variables: { paymentMethodId } })
    refetchPM()
  }

  if (loading)
    return (
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    )

  return (
    <ModalContainer zIndex={33}>
      <CloseModal onClick={() => setShowPaymenthSelector(false)} />
      <div className='modal__header'>
        {errorPaymenth ? (
          <h3 className='header__text'>Ocurrió un error</h3>
        ) : (
          <h3 className='header__text'>Selecciona el nuevo método de pago</h3>
        )}
      </div>
      <div className='modal__content'>
        {errorPaymenth && (
          <p className='content__text'>
            ¡Ups! Parece que tu pago no pudo ser procesado. Verifíca si el método de pago que tienes registrado funciona
            correctamente o cuenta con fondos suficientes para realizar la operación.
          </p>
        )}
        <p className='content__text registered__payments'>Estos son tus métodos de pago registrados:</p>
        <div className='payment-methods'>
          {cards.map(card => (
            <Card key={card.id}>
              {card.default ? (
                <button className='checkbox-indicator'>
                  <BsCheckBox className='checked' />
                </button>
              ) : (
                <button className='checkbox-indicator' onClick={() => handleDefaultPaymentMethod(card.id)}>
                  <BsSquare className='not-checked' />
                </button>
              )}

              <div className='card-info'>
                {card.brand === 'visa' && <FaCcVisa />}
                {card.brand === 'mastercard' && <FaCcMastercard />}
                <p>•••• •••• •••• {card.last4}</p>
              </div>
            </Card>
          ))}
        </div>

        <div className='cumplimiento pad'>
          <div className='left'>
            <p className='add' onClick={() => handleAddPaymenthMethod()}>
              Añadir nuevo método de pago
            </p>
          </div>
        </div>

        {errorPaymenth ? (
          <div className='buttonContainer'>
            <div className='buttonRightError'>
              <button onClick={() => handleNewPaymenthMethod()}>Intentar nuevamente</button>
            </div>
          </div>
        ) : (
          <div className='buttonContainer'>
            <div>
              <Button
                theme={'secondary'}
                variant={'outline'}
                width={'260px'}
                onClick={() => setShowPaymenthSelector(false)}
              >
                Continuar con el plan actual
              </Button>
            </div>
            <div>
              <Button theme={'primary'} width={'260px'} onClick={() => setShowPaymenthSelector(false)}>
                Seleccionar método de pago
              </Button>
            </div>
          </div>
        )}
      </div>
    </ModalContainer>
  )
}

export default PaymenthSelector
