import React, { useState } from 'react'
import { Tag, SearchBarContainer } from '../../../style/components/search/new-serches/TwitterSearchBar.styled'

import CloseIcon from '../../../assets/CloseIcon'

const TwitterSearchBar = ({
  width,
  name,
  queryParams,
  inputWord,
  handleInput,
  addParam,
  deleteParam,
  placeholder,
  setFrom,
  setTo,
  setHashtag
}) => {
  const [dropdown, setDropdown] = useState(false)

  let input
  const getWordSize = () => {
    const size = 1 + inputWord.length * 13
    return `${size}px`
  }

  return (
    <SearchBarContainer
      width={width}
      iwidth={getWordSize}
      onClick={() => {
        input.focus()
        setDropdown(true)
      }}
    >
      {queryParams.length > 0 || inputWord !== '' ? null : <p className='searchbar-placeholder'>{placeholder}</p>}
      {queryParams.map((param, idx) => (
        <Tag key={idx} id={idx}>
          {param}
          <span onClick={() => deleteParam(idx)}>
            <CloseIcon />
          </span>
        </Tag>
      ))}

      <div>
        <input
          type='text'
          value={inputWord}
          onChange={handleInput}
          onKeyDown={e => {
            addParam(e)
            setDropdown(true)
          }}
          ref={i => (input = i)}
          onFocus={() => setDropdown(true)}
          onInput={() => setDropdown(false)}
        />
        <div className={dropdown ? 'dropdown-visible' : 'dropdown-closed'}>
          <button onClick={setFrom} id={name}>
            from:
          </button>
          <button onClick={setTo} id={name}>
            to:
          </button>
          <button onClick={setHashtag} id={name}>
            #:
          </button>
        </div>
      </div>
    </SearchBarContainer>
  )
}

export default TwitterSearchBar
