import React, { useState, useContext, useEffect } from 'react'
import {
  DashboardContainer,
  WidgetsMenuContainer,
  Shadow,
  WidgetsContainer
} from '../../style/pages/Dashboard/Dashboard.styled'

import { widgets } from '../../data/Widgets'

import ShareBoard from '../../components/dashboard/dashboard/ShareBoard'
import Widget from '../../components/dashboard/dashboard/Widget'
import NoWidgets from '../../components/dashboard/dashboard/NoWidgets'
import WidgetsMenu from '../../components/dashboard/dashboard/WidgetsMenu'
import SelectedWidget from '../../components/dashboard/dashboard/SelectedWidget'
import DeleteDashboard from '../../components/dashboard/dashboards/DeleteDashboard'

import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar, Spinner } from '../../style/components'
import { AiOutlineUser } from 'react-icons/ai'
import { BsTrash, BsPencilSquare } from 'react-icons/bs'
import { IoIosArrowBack } from 'react-icons/io'

import { useQuery, useMutation } from '@apollo/client'

import { FiSettings } from 'react-icons/fi'
import { MdWidgets } from 'react-icons/md'
import EditBoard from '../../components/dashboard/dashboard/EditBoard'
import { MyContext } from '../../context'

import {
  GET_ELEGIBLE_USERS,
  GET_DASHBOARD,
  DASHBOARD_WIDGETS_QUERY,
  SEARCHES_QUERY,
  DASHBOARDS_QUERY
} from '../../services/queries'

import { UPDATE_DASHBOARD_MUTATION, NEW_WIDGET_MUTATION, DELETE_DASHBOARD_MUTATION } from '../../services/mutations'

import { DASHBOARD_WIDGETS_SUBSCRIPTION, WIDGETS_MOVE_SUBSCRIPTION } from '../../services/subscriptions'
import Button from '../../components/ui/Button'

const Dashboard = props => {
  const userSelectEditBoard = () => (props.location.search === '?editboard' ? true : false)
  const userSelectEditMembers = () => (props.location.search === '?editmembers' ? true : false)

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass, toolbarClass } = uiCTX

  const { showNotifications, setNotificationText, ...context } = useContext(MyContext)

  const [showWidgetsMenu, setShowWidgetsMenu] = useState(false)
  const [showWidgetDetail, setShowWidgetDetail] = useState(false)
  const [selectedWidget, setSelectedWidget] = useState(null)
  const [searchesSelection, setSearchesSelection] = useState([])
  const [showShareBoard, setShowShareBoard] = useState(userSelectEditMembers())
  const [showDashboardActions, setShowDashboardActions] = useState(false)
  const [showEditDashboard, setShowEditDashboard] = useState(userSelectEditBoard())

  const [updatedDashboard] = useMutation(UPDATE_DASHBOARD_MUTATION)
  const handleEditDashboard = async (name, description) => {
    await updatedDashboard({
      variables: {
        id: props.match.params.id,
        input: {
          name,
          description
        }
      }
    })
    setShowEditDashboard(false)
  }

  const [dashboardToDelete, setDashboardToDelete] = useState(null)
  const [deletedDashboard, { loading: deleteLoading }] = useMutation(DELETE_DASHBOARD_MUTATION, {
    update(cache, { data: { deletedDashboard } }) {
      const query = cache.readQuery({ query: DASHBOARDS_QUERY })
      if (query) {
        cache.writeQuery({
          query: DASHBOARDS_QUERY,
          data: {
            dashboards: query.dashboards.filter(dashboard => dashboard.id !== deletedDashboard.id)
          }
        })
      }
    }
  })

  const handleDeleteDashboard = async id => {
    await deletedDashboard({ variables: { id } })
    showNotifications()
    setNotificationText('El tablero fue eliminado con éxito.')
    props.history.push('/dashboards')
  }

  const getWidgetsMenuClass = () => {
    if (showWidgetDetail) return 'widgets-menu__extended'
    if (showWidgetsMenu) return 'widgets-menu__visible'
    if (!showWidgetsMenu) return 'widgets-menu__hidden'
  }

  const closeWidgetsMenu = () => {
    setShowWidgetsMenu(false)
    setShowWidgetDetail(false)
    setSelectedWidget(null)
  }

  const selectWidget = widget => {
    setSelectedWidget(widget)
    setShowWidgetDetail(true)
    setSearchesSelection([])
  }

  const [newWidget] = useMutation(NEW_WIDGET_MUTATION, {
    update(cache, { data: { newWidget } }) {
      const { dashboardWidgets } = cache.readQuery({
        query: DASHBOARD_WIDGETS_QUERY,
        variables: { dashboardID: props.match.params.id }
      })
      cache.writeQuery({
        query: DASHBOARD_WIDGETS_QUERY,
        variables: { dashboardID: props.match.params.id },
        data: {
          dashboardWidgets: [...dashboardWidgets, newWidget]
        }
      })
    }
  })

  const addWidget = async () => {
    const searches = []
    searchesSelection.forEach(search => searches.push(search.id))
    if (searches.length === 0) {
      showNotifications()
      setNotificationText(
        'No haz vinculado ninguna búsqueda a éste gráfico. Por favor elige una de tus búsquedas para agregar el gráfico al tablero'
      )
    } else {
      try {
        await newWidget({
          variables: {
            input: {
              name: selectedWidget.displayName,
              kind: selectedWidget.componentName,
              searches,
              dashboardId: props.match.params.id
            }
          }
        })

        closeWidgetsMenu()
        showNotifications()
        setNotificationText(
          `El gráfico <span><b>${selectedWidget.displayName}</b></span> se agregó al tablero con éxito.`
        )
      } catch (error) {
        console.log(error)
      }
    }
  }

  const {
    data: dashboardData,
    loading: dashboardLoading,
    startPolling: dashboardStartPolling,
    stopPolling: dashboardStopPolling,
    error: errorGetDashboard
  } = useQuery(GET_DASHBOARD, {
    variables: { id: props.match.params.id }
  })

  if (errorGetDashboard) context.handleErrors(errorGetDashboard)

  const {
    data: dashboardWidgetsData,
    loading: dashboardWidgetsLoading,
    subscribeToMore: suscribeToDashboardWidgets,
    error: errorDashWidgetsData
  } = useQuery(DASHBOARD_WIDGETS_QUERY, {
    variables: {
      dashboardID: props.match.params.id
    }
  })

  if (errorDashWidgetsData) context.handleErrors(errorDashWidgetsData)

  useEffect(() => {
    suscribeToDashboardWidgets({
      document: DASHBOARD_WIDGETS_SUBSCRIPTION,
      variables: { dashboardID: props.match.params.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        let newWidget = subscriptionData.data.widgetChanged
        if (newWidget.operation === 'created') {
          if (newWidget.createdBy.id === props.user.id) return prev
          return Object.assign({}, prev, {
            dashboardWidgets: [...prev.dashboardWidgets, newWidget]
          })
        } else if (newWidget.operation === 'removed') {
          return Object.assign({}, prev, {
            dashboardWidgets: prev.dashboardWidgets.filter(dashboard => dashboard.id !== newWidget.id)
          })
        } else if (newWidget.operation === 'updated') {
          console.log('Widget updated!')
        }
      }
    })
  }, [suscribeToDashboardWidgets, props.match.params.id, props.user.id])

  useEffect(() => {
    suscribeToDashboardWidgets({
      document: WIDGETS_MOVE_SUBSCRIPTION,
      variables: { dashboardID: props.match.params.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const userID = subscriptionData.data.widgetMoved.split(' ')[1]
        if (userID === props.user.id) return prev
        const widgets = [...prev.dashboardWidgets]
        const widgetID = subscriptionData.data.widgetMoved.split(' ')[2]
        const direction = subscriptionData.data.widgetMoved.split(' ')[3]
        const idx = widgets.map(widget => widget.id).indexOf(widgetID)
        if (direction === 'right' && idx + 1 !== widgets.length) {
          const widget = widgets.splice(idx, 1)[0]
          widgets.splice(idx + 1, 0, widget)
        }
        if (direction === 'left' && idx !== 0) {
          const widget = widgets.splice(idx, 1)[0]
          widgets.splice(idx - 1, 0, widget)
        }
        return Object.assign({}, prev, {
          dashboardWidgets: widgets
        })
      }
    })
  }, [suscribeToDashboardWidgets, props.match.params.id, props.user.id])

  const { data: searchesData, loading: searchesLoading, error: errorSearchQuery } = useQuery(SEARCHES_QUERY)

  if (errorSearchQuery) context.handleErrors(errorSearchQuery)

  const {
    data: usersData,
    loading: usersLoading,
    startPolling: userStartPolling,
    stopPolling: userStopPolling,
    error: errorUsersData
  } = useQuery(GET_ELEGIBLE_USERS, {
    variables: { dashboardId: props.match.params.id }
  })

  if (errorUsersData) context.handleErrors(errorUsersData)

  useEffect(() => {
    dashboardStartPolling(2000)
    userStartPolling(2000)
    return () => {
      dashboardStopPolling()
      userStopPolling()
    }
  }, [dashboardStartPolling, dashboardStopPolling, userStartPolling, userStopPolling])

  const [userHasPermission, setUserHasPermission] = useState(true)

  useEffect(() => {
    const dashboard = dashboardData && dashboardData.getDashboard
    setUserHasPermission(
      dashboard && context.dataUser && !dashboard.viewers.find(viewer => viewer.id === context.dataUser.id)
    )
  }, [dashboardData, context.dataUser])

  if (dashboardLoading || dashboardWidgetsLoading || usersLoading) return <Spinner />

  // if (userSelectedModal() === "editBoard") {
  //   setModal("editBoard");
  // } else if (userSelectedModal() === "editMembers") {
  //   setModal("editMembers");
  // }

  // if (modal === "editBoard") {
  //   setShowEditDashboard(true);
  // } else if (modal === "editMembers") {
  //   setShowShareBoard(true);
  // };

  return (
    <MainContainer className={mainClass()}>
      <DashboardContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <Button
              width={'min'}
              height={'fit-content'}
              theme={'dark'}
              variant={'link'}
              className='back-link'
              onClick={() => props.history.push('/dashboards')}
            >
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </Button>
            <h1 className='topbar-title'>{dashboardData.getDashboard.name}</h1>

            <p className='members-qty'>
              <AiOutlineUser />
              {dashboardData.getDashboard.editors.length + dashboardData.getDashboard.viewers.length + 1} miembros
            </p>
          </div>
          <div className='right-topbar'></div>
        </Topbar>

        {userHasPermission && (
          <Toolbar className={toolbarClass()}>
            <button className='toolbar-button' onClick={() => setShowWidgetsMenu(true)}>
              <MdWidgets className='toolbar-icon gray-icon' />
              <p>Gráficos</p>
            </button>
            <div className='dashboard-actions'>
              <button
                className='toolbar-button dashboard-actions'
                onClick={() => {
                  setShowDashboardActions(!showDashboardActions)
                }}
              >
                <FiSettings className='toolbar-icon' />
                <p>Herramientas</p>
              </button>
              {showDashboardActions && (
                <div className='options-menu'>
                  <button
                    className='full_option'
                    onClick={() => {
                      setShowEditDashboard(true)
                      setShowDashboardActions(false)
                    }}
                  >
                    <BsPencilSquare />
                    Editar tablero
                  </button>
                  <button
                    className='full_option'
                    onClick={() => {
                      setShowShareBoard(true)
                      setShowDashboardActions(false)
                    }}
                  >
                    <AiOutlineUser />
                    Editar miembros
                  </button>
                  <button
                    className='full_option delete_button'
                    onClick={() => {
                      setDashboardToDelete(dashboardData.getDashboard)
                      setShowDashboardActions(false)
                    }}
                  >
                    <BsTrash />
                    Eliminar tablero
                  </button>
                </div>
              )}
            </div>
          </Toolbar>
        )}

        {dashboardWidgetsData.dashboardWidgets.length > 0 ? (
          <WidgetsContainer>
            {dashboardWidgetsData.dashboardWidgets.map(widget => (
              <Widget
                key={widget.id}
                widget={widget}
                dashboard={props.match.params.id}
                dashboardData={dashboardData.getDashboard}
              />
            ))}
          </WidgetsContainer>
        ) : (
          <NoWidgets setShowWidgetsMenu={setShowWidgetsMenu} nobutton={!userHasPermission} />
        )}

        {showWidgetsMenu && <Shadow onClick={() => closeWidgetsMenu()} />}
        <WidgetsMenuContainer className={getWidgetsMenuClass()}>
          <WidgetsMenu
            widgets={widgets}
            closeWidgetsMenu={closeWidgetsMenu}
            selectWidget={selectWidget}
            selectedWidget={selectedWidget}
            showWidgetDetail={showWidgetDetail}
          />
          <SelectedWidget
            widget={selectedWidget}
            closeWidgetsMenu={closeWidgetsMenu}
            searchesData={searchesData}
            searchesLoading={searchesLoading}
            searchesSelection={searchesSelection}
            setSearchesSelection={setSearchesSelection}
            addWidget={addWidget}
          />
        </WidgetsMenuContainer>

        {showEditDashboard && userHasPermission && (
          <>
            <Shadow onClick={() => setShowEditDashboard(false)} />
            <EditBoard
              dashboard={dashboardData.getDashboard}
              closeModal={() => setShowEditDashboard(false)}
              handleEditDashboard={handleEditDashboard}
            />
          </>
        )}

        {showShareBoard && userHasPermission && (
          <>
            <Shadow onClick={() => setShowShareBoard(false)} />
            <ShareBoard
              board={dashboardData.getDashboard}
              users={usersData.getElegibleUsers}
              setShowShareBoard={setShowShareBoard}
            />
          </>
        )}

        {dashboardToDelete && (
          <>
            <Shadow onClick={() => setDashboardToDelete(null)} />
            <DeleteDashboard
              dashboard={dashboardToDelete}
              setDashboardToDelete={setDashboardToDelete}
              deleteDashboard={handleDeleteDashboard}
              loading={deleteLoading}
            />
          </>
        )}
      </DashboardContainer>
    </MainContainer>
  )
}

export default Dashboard
