import { createGlobalStyle } from 'styled-components'
import { colors } from './variables'

export default createGlobalStyle`
  * {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    // color: ${colors.richBlack};
    font-family: 'Montserrat', sans-serif;
  }
  body {
    overflow: hidden;
    width: 100vw;
    height: 100vh;
  }
  a {
    text-decoration: none;
  }

  main.is-not-collapsed { margin-left: 222px; }
  main.is-collapsed { margin-left: 68px; }

  div.is-not-collapsed {
    margin-left: 222px;
    width: calc(100% - 222px)
  }
  div.is-collapsed {
    margin-left: 68px;
    width: calc(100% - 68px)
  }

  #shadow-filters.filters-collapsed {
   display: none;
  }
  #shadow-filters.filters-not-collapsed {
    display: block;
  }
  #filters.filters-collapsed {
    transform: translateX(700px);
    transition: transform 0.5s ease-in-out;
  }
  #filters.filters-not-collapsed {
    transform: translateX(0);
    transition: transform 0.5s ease-in-out;
  }
  #shadow-change.change-search-collpased {
   display: none;
  }
  #shadow-change.change-search-not-collapsed {
    display: block;
  }
  #change.change-search-collpased {
    transform: translateY(-450px);
    transition: transform 0.5s ease-in-out;
  }
  #change.change-search-not-collapsed {
    transform: translateY(0);
    transition: transform 0.5s ease-in-out;
  }
  #save-search.save-search-closed {
    display: none;
  }
  #save-search.save-search-open {
    display: block;
  }
  #create-board.create-board-closed {
    display: none;
  }
  #create-board.crate-board-open {
    display: block;
  }
`
