import styled from 'styled-components'
import { colors } from '../../variables'

export const ProfileContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  .topbar {
    &__title {
      color: ${colors.richBlack};
      font-size: 22px;
      font-weight: 600;
      margin-right: 40px;
    }
  }

  .profileCont {
    display: flex;
    flex-direction: column;
    width: 100%;
    &-title {
      display: flex;
      height: 90px;
      align-items: center;
      justify-content: center;
      h2 {
        font-size: 30px;
      }
    }
    .error {
      color: red;
      font-size: 14px;
      margin-top: 20px;
    }
    .success {
      color: red;
      font-size: 14px;
      margin-top: 20px;
    }
    &-body {
      display: flex;
      overflow: auto;
      height: 100%;

      &-user {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 50%;
        padding: 50px 40px 0 0;
        margin-bottom: 40px;

        &-button {
          .password__change {
            font-size: 14px;
            color: #00aaab;
            cursor: pointer;
            font-weight: 500;
          }
        }

        &-avatar {
          display: flex;
          flex-direction: row;
          align-items: center;
          &-button {
            width: 170px;
            height: 25px;
            margin-left: 20px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            cursor: pointer;
            .upload {
              position: absolute;
              font-size: 14px;
              color: #00aaab;
              cursor: pointer;
              font-weight: 500;
            }

            input[type='file'] {
              opacity: 0;
              height: 100%;
              width: 100%;
              cursor: pointer !important;
            }
          }
          &-img {
            display: flex;
            flex-direction: column;
            width: 80px;
            height: 80px;
            justify-content: center;
            align-items: center;
            overflow: hidden;
            border-radius: 50%;
            img {
              height: 100%;
            }
          }
        }
        &-button {
          margin-top: 20px;
        }
        &-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 20px;

          label {
            color: #0b0c10;
            font-weight: 500;
            font-size: 14px;
            margin-bottom: 10px;
          }
          input {
            border-radius: 4px;
            border: 1px solid #748596;
            font-size: 14px;
            padding: 14px;
            background: transparent;
            &:focus {
              outline: none;
              background: transparent;
            }
          }

          & .password__feedback {
            height: 10px;
            width: 100%;
            margin-top: 3px;

            & .incorrect__password {
              color: ${colors.red};
              font-size: 10px;
              font-weight: 500;
            }

            & .empty__newpassword {
              color: ${colors.darkGray};
              font-size: 10px;
              font-weight: 500;
            }

            & .correct__newpassword {
              color: #00aaab;
              font-size: 10px;
              font-weight: 500;
            }

            & .incorrect__newpassword {
              color: ${colors.red};
              font-size: 10px;
              font-weight: 500;
            }
          }
        }

        &-delete {
          margin-top: 20px;
          display: flex;
          border-top: 1px solid #c5c9d4;
          padding-top: 20px;
          align-items: center;
          color: #fe596f;
          font-size: 22px;
          cursor: pointer;
          width: auto;
          height: 55px;
          label {
            font-size: 15px;
            font-weight: 600;
            margin-left: 10px;
          }
        }

        & .user__delete {
          display: flex;
          flex-direction: row;
          align-items: center;
          border-top: 1px solid #aaafbe;
          padding-top: 15px;

          & .delete__text {
            color: #fe596f;
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 15px;
            line-height: 18px;
            border: none;
            background: transparent;
            margin-left: 11px;
          }
        }

        & .changepassword__buttons {
          display: flex;
          flex-direction: row;
          margin-top: 50px;
        }
      }
      &-data {
        display: flex;
        flex-direction: column;
        width: 50%;
        padding: 50px 40px 0;
        border-left: 1px solid #c5c9d4;
        &-button {
          display: flex;
          flex-direction: column;
          margin-top: 50px;
        }
        &-input {
          display: flex;
          flex-direction: column;
          width: 100%;
          margin-top: 20px;
          label {
            color: #0b0c10;
            font-size: 14px;
            font-weight: 500;
            margin-bottom: 10px;
          }
          input {
            border-radius: 4px;
            border: 1px solid ${colors.darkGray};
            font-size: 14px;
            padding: 14px;
            background: transparent;
            &:focus {
              outline: none;
              background: transparent;
            }
          }
        }

        & .input__genre {
          margin-top: 7px;
        }
        & .input__gender_age {
          flex-direction: row;
          width: 100%;
          justify-content: space-between;
        }
        & .input__gender {
          width: 45%;
        }
        & .input__age {
          width: 45%;
          input {
            height: 50px;
          }
        }
      }
    }
  }
  section {
    width: calc(100% / 2);
    height: calc(100vh - 110px);
    border: 1px solid purple;
  }
  .left-profile {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 40px 40px 40px 0;
    border-right: 1px solid ${colors.lightGray};
    .profile-avatar {
      width: 65px;
      height: 65px;
      border-radius: 50%;
      background: pink;
    }
  }

  .switch {
    display: flex;
  }
  .plan-switch {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 3%;
    height: 50%;
    p {
      width: 150px;
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      margin-bottom: 10px;
      &.bold {
        font-weight: 700;
        color: ${colors.darkGray};
      }
      label {
        display: flex;
      }
    }
  }
  .right-profile {
    padding: 40px 0 40px 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .profileCont-body-user-delete {
    svg {
      width: 20px;
      height: 20px;
      fill: ${colors.red};
    }
  }
`
