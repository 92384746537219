import React, { Component, createContext } from 'react'
import { withRouter } from 'react-router-dom'

export const UIContext = createContext()

class UIProvider extends Component {
  state = {
    collapsed: false
  }

  /* ----- FUNCTION TO HANDLE SIDEBAR ----- */

  handleSidebar = () => {
    this.setState(prevState => ({
      ...prevState,
      collapsed: !this.state.collapsed
    }))
  }

  /* ----- FUNCTIONS TO ADAPT CONTENT ----- */

  mainClass = () => (this.state.collapsed ? 'main-container is-collapsed' : 'main-container is-not-collapsed')

  topbarClass = () => (this.state.collapsed ? 'topbar is-collapsed' : 'topbar is-not-collapsed')

  toolbarClass = () => (this.state.collapsed ? 'toolbar is-collapsed' : 'toolbar is-not-collapsed')

  render() {
    const { state, handleSidebar, mainClass, topbarClass, toolbarClass } = this

    return (
      <UIContext.Provider
        value={{
          state,
          handleSidebar,
          mainClass,
          topbarClass,
          toolbarClass
        }}
      >
        {this.props.children}
      </UIContext.Provider>
    )
  }
}

export default withRouter(UIProvider)
