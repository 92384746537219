import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const WidgetsSelectionContainer = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  p {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 30px;
    color: ${colors.darkGray};
  }
  label {
    display: flex;
    align-items: center;
    width: 200px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 30px;
    cursor: pointer;
    input {
      width: 15px;
      height: 15px;
      margin-right: 10px;
    }
  }
`

export const WidgetCard = styled.article`
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  .widget-card {
    display: flex;
  }
  .widget-card-disabled {
    h6,
    .button-disabled {
      color: ${colors.lightGray};
    }
  }
  .widget-icon {
    width: 60px;
    height: 60px;
    margin-right: 10px;
    cursor: pointer;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  .widget-content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    h6 {
      font-size: 16px;
      font-weight: 400;
      margin-bottom: 7px;
    }
    button {
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: ${colors.darkGray};
      border: none;
      outline: none;
      cursor: pointer;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 7px;
      }
      &.comment-exists {
        color: ${colors.lightseaGreen};
      }
    }
  }
  textarea {
    height: 75px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    margin: 20px 0;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
  }
  .comment-buttons {
    display: flex;
    justify-content: flex-end;
    button {
      margin-left: 10px;
      border-radius: 4px;
    }
  }
`
