import React from 'react'
import {
  ChangeSearchContainer,
  TwitterSearchContainer
} from '../../../style/components/search/new-serches/ChangeSearch.styled'

import TwitterSearchBar from './TwitterSearchBar'

import { GrClose } from 'react-icons/gr'
import Button from '../../ui/Button'

const ChangeSearch = props => {
  const {
    cancelChanges,
    closeChangeSearch,
    twOrParams,
    twAndParams,
    twNotParams,
    twOrWord,
    twAndWord,
    twNotWord,
    handleTwitterOrInput,
    handleTwitterAndInput,
    handleTwitterNotInput,
    addTwitterOrWord,
    addTwitterAndWord,
    addTwitterNotWord,
    deleteTwitterOrParam,
    deleteTwitterAndParam,
    deleteTwitterNotParam,
    setFrom,
    setTo,
    setHashtag,
    makeTwitterSearch
  } = props

  return (
    <ChangeSearchContainer>
      <button className='close-change-button' onClick={cancelChanges}>
        <GrClose className='close-icon' />
      </button>
      <TwitterSearchContainer>
        <div className='twitter-or-container'>
          <p>Búsqueda principal: cuentas de Twitter, #hashtags y/o palabras {/* TODO: INFO <button>i</button>*/}</p>
          <TwitterSearchBar
            width='100%'
            name='twOrWord'
            queryParams={twOrParams}
            inputWord={twOrWord}
            handleInput={handleTwitterOrInput}
            addParam={addTwitterOrWord}
            deleteParam={deleteTwitterOrParam}
            placeholder='@cuentas, #hashtags y/o palabras'
            setFrom={setFrom}
            setTo={setTo}
            setHashtag={setHashtag}
          />
        </div>
        <div className='and-not-container'>
          <div className='and-container'>
            <p>También busca en Twitter {/* TODO: INFO <button>i</button>*/}</p>
            <TwitterSearchBar
              width='100%'
              name='twAndWord'
              queryParams={twAndParams}
              inputWord={twAndWord}
              handleInput={handleTwitterAndInput}
              addParam={addTwitterAndWord}
              deleteParam={deleteTwitterAndParam}
              placeholder='@cuentas, #hashtags y/o palabras'
              setFrom={setFrom}
              setTo={setTo}
              setHashtag={setHashtag}
            />
          </div>
          <div className='not-container'>
            <p>Excluir de la búsqueda {/* TODO: INFO <button>i</button>*/}</p>
            <TwitterSearchBar
              width='100%'
              name='twNotWord'
              queryParams={twNotParams}
              inputWord={twNotWord}
              handleInput={handleTwitterNotInput}
              addParam={addTwitterNotWord}
              deleteParam={deleteTwitterNotParam}
              placeholder='@cuentas, #hashtags y/o palabras'
              setFrom={setFrom}
              setTo={setTo}
              setHashtag={setHashtag}
            />
          </div>
        </div>
        <div className='buttons-container'>
          <Button size={'medium'} variant={'link'} theme={'dark'} className='cancel-button' onClick={cancelChanges}>
            Cancelar
          </Button>
          <Button
            size={'medium'}
            disabled={twOrParams.length > 0 ? false : true}
            onClick={() => {
              makeTwitterSearch()
              closeChangeSearch()
            }}
          >
            Buscar
          </Button>
        </div>
      </TwitterSearchContainer>
    </ChangeSearchContainer>
  )
}

export default ChangeSearch
