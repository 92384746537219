import styled from 'styled-components'
import { colors } from '../../variables'

export const DashboardContainer = styled.div`
  .back-link {
    margin-right: 30px;
    display: flex;
    .back-icon {
      width: 20px;
      height: 20px;
    }
  }
  padding-top: 40px;
  .topbar {
    justify-content: space-between;
    .left-topbar,
    .right-topbar {
      display: flex;
      align-items: center;
    }
    .topbar-title {
      width: fit-content;
      font-size: 22px;
      font-weight: 600;
      margin-right: 20px;
      max-width: 500px;
    }
    .topbar-button {
      width: 210px;
      height: 40px;
      border: none;
      border-radius: 4px;
      outline: none;
      background: ${colors.lightseaGreen};
      color: ${colors.white};
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
    .members-qty {
      display: flex;
      align-items: center;
      color: ${colors.darkGray};
      font-size: 16px;
      font-weight: 500;
      svg {
        width: 16px;
        height: 16px;
        margin: 0 5px 0 30px;
      }
    }
  }
  .toolbar {
    justify-content: flex-end;
    .toolbar-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 40px;
      margin-left: 10px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
      .toolbar-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: ${colors.darkGray};
        &.gray-icon {
          fill: ${colors.darkGray};
        }
      }
      p {
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
      }
    }
    .dashboard-actions {
      position: relative;
      .options-menu {
        z-index: 5;
        position: absolute;
        top: 40px;
        right: 0;
        width: 200px;
        height: 150px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        border-radius: 0 0 4px 4px;
        button {
          border: none;
          background: none;
          outline: none;
        }
        .full_option {
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: ${colors.darkGray};
          padding-left: 20px;
          border-radius: 0 0 4px 4px;
          :hover {
            background: ${colors.lightGray};
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        .delete_button {
          color: ${colors.red};
        }
      }
    }
  }
`

export const WidgetsMenuContainer = styled.aside`
  z-index: 20;
  position: absolute;
  top: 0;
  right: 0;
  width: 1200px;
  height: 100vh;
  border-radius: 10px 0 0 10px;
  background: #1f2833;
  display: flex;
  &.widgets-menu {
    &__hidden {
      transform: translateX(1200px);
      transition: transform 0.5s ease-in-out;
    }
    &__visible {
      transform: translateX(500px);
      transition: transform 0.5s ease-in-out;
    }
    &__extended {
      transform: translateX(0px);
      transition: transform 0.5s ease-in-out;
    }
  }
`

export const Shadow = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  z-index: 19;
`

export const WidgetsContainer = styled.div`
  overflow-y: scroll;
  height: calc(100vh - 110px);
  display: flex;
  flex-wrap: wrap;
  padding-top: 25px;
`
