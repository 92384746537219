import styled from 'styled-components'
import { colors } from '../../variables'

const BirthdayInputContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 20px;
  .date {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    & > div {
      width: 26%;
    }
  }

  & .date__feedback {
    height: 10px;
    width: 100%;
    margin-top: 3px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    & .incorrect__date {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
    }

    & .transparent__date {
      color: transparent;
    }
  }
  & > label {
    color: #0b0c10;
    font-size: 14px;
    font-weight: 500;
    margin-bottom: 10px;
  }
`

export default BirthdayInputContainer
