import React, { useContext, useState } from 'react'
import { AllReportsContainer } from '../../../style/components/reports/reports/AllReports.styled'
import { FiShare2 } from 'react-icons/fi'
import { BsTrash } from 'react-icons/bs'
import NoReports from './NoReports'
import { Shadow } from '../../../style/components'
import ShareModal from './ShareModalReport'
import { DeleteModal } from './DeleteModalReport'
import { useMutation } from '@apollo/client'

import { DELETE_REPORT } from '../../../services/mutations'
import { MyContext } from '../../../context'
import Notification from '../../workspace/Modals/Notification'
import Button from '../../ui/Button'

const AllReports = ({ reports, filtersOpen, filters, order, refetchReports }) => {
  const { showNotifications, setNotificationText, ...context } = useContext(MyContext)

  const [openShare, setOpenShare] = useState(false)
  const [openDelete, setOpenDelete] = useState(false)
  const [deleteError, setDeleteError] = useState(false)
  const [message, setMessage] = useState('')
  const [reportName, setReportName] = useState('')
  const [reportId, setReportId] = useState('')

  let sorted = [...reports]

  const [deleteReport, { error: errorDeleteReport, loading: deleteLoading }] = useMutation(DELETE_REPORT)

  if (errorDeleteReport) context.handleErrors(errorDeleteReport)

  const openDetailReport = id => {
    const win = window.open(`/reports/report/${id}`, '_blank')
    win.focus()
  }

  const openShareModal = id => {
    setOpenShare(true)
    setReportId(id)
  }

  const openDeleteModal = (id, name) => {
    setOpenDelete(true)
    setReportId(id)
    setReportName(name)
  }

  const handleDeleteReport = async id => {
    try {
      await deleteReport({ variables: { id } })
      setOpenDelete(false)
      refetchReports()
      showNotifications()
      setNotificationText(`El reporte <span><b>${reportName}</b></span> fue eliminado con éxito .`)
    } catch ({ message }) {
      setMessage(message)
      setDeleteError(true)
    }
  }

  if (order.AtoZ) {
    sorted = reports.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.ZtoA) {
    sorted = reports.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = -1
      } else if (nameA < nameB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.recentDate) {
    sorted = reports.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = -1
      } else if (dateA < dateB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.oldDate) {
    sorted = reports.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = 1
      } else if (dateA < dateB) {
        comparison = -1
      }
      return comparison
    })
  }

  if (reports.length === 0) return <NoReports filtersOpen={filtersOpen} />

  return (
    <AllReportsContainer filtersOpen={filtersOpen}>
      <div className='reports-table-header'>
        <h3 className='number'>No.</h3>
        <h3 className='name'>Nombre</h3>
        <h3 className='date'>Fecha</h3>
        <h3 className='hour'>Hora</h3>
        <h3 className='type'>Tipo</h3>
        <h3 className='creator'>Autor</h3>
        <h3 className='options'>Opciones</h3>
      </div>

      <div className='reports-table-body'>
        {sorted
          .filter(report => report.name.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(report => report.createdBy.name.toLowerCase().includes(filters.user.toLowerCase()))
          .filter(report => {
            if (filters.from !== '') {
              const year = filters.from.split('').splice(0, 4).join('')
              const month = filters.from.split('').splice(5, 2).join('') - 1
              const day = filters.from.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return report.createdAt > date
            }
            return report
          })
          .filter(report => {
            if (filters.to !== '') {
              const year = filters.to.split('').splice(0, 4).join('')
              const month = filters.to.split('').splice(5, 2).join('') - 1
              const day = filters.to.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return report.createdAt < date
            }
            return report
          })
          .filter(report => report.type.includes(filters.type))
          .map(report => {
            const date = new Date(Number(report.createdAt))
            const createdDate = date.toString().split('').slice(4, 15).join('')
            const createdHour = date.toString().split('').slice(16, 21).join('')
            let type = report.type
            if (type === 'once') {
              type = 'Única vez'
            } else if (type === 'scheduled') {
              type = 'Programado'
            }
            return (
              <div className='reports-table-row' key={report.id}>
                <p className='number'>{report.number < 10 ? '0' + report.number : report.number}</p>
                <p className='name'>{report.name}</p>
                <p className='date'>{createdDate}</p>
                <p className='hour'>{createdHour}</p>
                <p className='type'>{type}</p>
                <p className='creator'>
                  <img src={report.createdBy.photo} className='creator-photo' alt='user avatar' />
                  {report.createdBy.name}
                </p>
                <div className='options'>
                  <Button
                    weight={500}
                    size={'small'}
                    variant={'link'}
                    className='open'
                    onClick={() => openDetailReport(report.id)}
                  >
                    Ver reporte
                  </Button>
                  <button className='share' onClick={() => openShareModal(report.id)}>
                    <FiShare2 />
                  </button>
                  <button className='delete' onClick={() => openDeleteModal(report.id, report.name)}>
                    <BsTrash />
                  </button>
                </div>
              </div>
            )
          })}
      </div>

      {openShare && (
        <>
          <Shadow onClick={() => setOpenShare(false)} />
          <ShareModal reportId={reportId} setOpenShare={setOpenShare} />
        </>
      )}

      {openDelete && (
        <>
          <Shadow onClick={() => setOpenDelete(false)} />
          <DeleteModal
            reportId={reportId}
            reportName={reportName}
            origin={0}
            setOpenDelete={setOpenDelete}
            deleteReport={handleDeleteReport}
            loading={deleteLoading}
          />
          {deleteError && <p className='error-message'>{message}</p>}
        </>
      )}
    </AllReportsContainer>
  )
}

export default AllReports
