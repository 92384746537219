import styled from 'styled-components'
import { colors } from '../../../variables'

export const PlanSelectionContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
  width: 70vw;
  justify-content: space-between;
  height: 100%;
  padding: 30px 0;
  .left-information {
    max-width: 525px;
    width: 50%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    .plan-switch {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      p {
        width: 65px;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkGray};
        &.bold {
          font-weight: 700;
          color: ${colors.darkGray};
        }
      }
    }
    button {
      max-width: 433px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  .right-information {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: 679px;
    height: 670px;
    margin-left: 100px;
    overflow-y: scroll;
    padding: 9px;
  }
  @media (max-width: 1366px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 60px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 50px;
      }
      p {
        line-height: 30px;
        font-size: 20px;
      }
    }
  }
`
