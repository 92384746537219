import React from 'react'
import { useMutation } from '@apollo/client'
import ReactivateSubscriptionContainer from '../../style/components/workspace/ReactivateSubscrition.styled'

import { SUBSCRIPTION_QUERY } from '../../services/queries'
import { REACTIVATE_SUBSCRIPTION_MUTATION } from '../../services/mutations'

const ReactivateSubscription = ({ closeModal }) => {
  const [reactivateSubscription] = useMutation(REACTIVATE_SUBSCRIPTION_MUTATION, {
    update(cache, { data: { reactivateSubscription } }) {
      cache.writeQuery({
        query: SUBSCRIPTION_QUERY,
        data: {
          subscription: reactivateSubscription
        }
      })
    }
  })

  const handleReactivateSubscription = async () => {
    await reactivateSubscription()
    closeModal()
  }

  return (
    <ReactivateSubscriptionContainer>
      <h3>Reactivar suscripción</h3>
      <p className='modal-copy'>
        Confirma si deseas reactivar tu suscripción. Tus pagos y plan seguirán corriendo de manera normal.
      </p>
      <button onClick={() => handleReactivateSubscription()}>Confirmar reactivar suscripción</button>
    </ReactivateSubscriptionContainer>
  )
}

export default ReactivateSubscription
