import React, { useState } from 'react'
import { useMutation } from '@apollo/client'
import moment from 'moment'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import CancelSubscriptionContainer from '../../style/components/workspace/CancelSubscription.styled'
import { colors } from '../../style/variables'

import { GrClose } from 'react-icons/gr'

import { SUBSCRIPTION_QUERY } from '../../services/queries'
import { CANCEL_SUBSCRIPTION_MUTATION } from '../../services/mutations'

const CancelSubscription = ({ closeModal, endDate }) => {
  const [cancelSubscription] = useMutation(CANCEL_SUBSCRIPTION_MUTATION, {
    update(cache, { data: { cancelSubscription } }) {
      cache.writeQuery({
        query: SUBSCRIPTION_QUERY,
        data: {
          subscription: cancelSubscription
        }
      })
    }
  })
  const [emailError, setEmailError] = useState(null)
  const [passwordError, setPasswordError] = useState(null)
  const [subscriptionEnd, setSubscriptionEnd] = useState(null)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string(),
      password: Yup.string()
    }),
    onSubmit: async values => {
      const { email, password } = values
      try {
        const { data } = await cancelSubscription({
          variables: {
            input: {
              email,
              password
            }
          }
        })
        setSubscriptionEnd(data.cancelSubscription.cancel_at)
      } catch (error) {
        if (error.message.includes('Correo')) setEmailError(error.message)
        if (error.message.includes('Contraseña')) setPasswordError(error.message)
        setTimeout(() => {
          setEmailError(null)
          setPasswordError(null)
        }, 3000)
      }
    }
  })

  return (
    <CancelSubscriptionContainer>
      {subscriptionEnd ? (
        <>
          <h3>Suscripción cancelada</h3>

          <p className='modal-copy'>
            Tienes hasta el{' '}
            {moment(subscriptionEnd * 1000)
              .locale('es')
              .format('D MMMM YYYY')}{' '}
            para disfrutar los servicios de MyOblek.
          </p>
          <button onClick={closeModal}>Cerrar</button>
        </>
      ) : (
        <>
          <div className='filters--header'>
            <h3 style={{ color: `${colors.red}` }}>Cancelar suscripción</h3>
            <GrClose onClick={() => closeModal(false)} />
          </div>
          <p className='modal-copy'>
            Si cancelas tu suscripción perderás acceso a todas las funciones de MyOblek a partir del día{' '}
            {moment(endDate * 1000)
              .locale('es')
              .format('D MMMM YYYY')}
            .
          </p>
          <p className='modal-copy'>Si deseas cancelar confirma con tu correo y contraseña:</p>

          <form onSubmit={formik.handleSubmit}>
            <label>Escribe tu correo</label>
            <input id='email' value={formik.values.email} onChange={formik.handleChange} />
            <div className='errors-space'>{emailError && <p className='error-message'>{emailError}</p>}</div>

            <label>Escribe tu contraseña</label>
            <input id='password' type='password' value={formik.values.password} onChange={formik.handleChange} />
            <div className='errors-space'>{passwordError && <p className='error-message'>{passwordError}</p>}</div>

            <input
              className='submit-button'
              type='submit'
              value='Confirmar cancelación'
              disabled={
                formik.values.email && formik.values.password && !formik.errors.email && !formik.errors.password
                  ? false
                  : true
              }
              style={
                formik.values.email && formik.values.password && !formik.errors.email && !formik.errors.password
                  ? { background: `${colors.red}`, color: '#FFFFFF' }
                  : { background: '#E7E8EA' }
              }
            />
          </form>
        </>
      )}
    </CancelSubscriptionContainer>
  )
}

export default CancelSubscription
