import React, { useContext, useState } from 'react'

import TeamMemberContainer from '../../style/components/workspace/TeamMemberContainer.styled'
import { UserCard } from '../../style/components/workspace/TeamContainer.styled'
import UserRolesMenu from './Modals/UserRolesMenu'
import Arrow from '../../assets/arrow'
import TrashIcon from '../../assets/IconTrash'
import { colors } from '../../style/variables'
import Notification from './Modals/Notification'
import { MyContext } from '../../context'

const TeamMember = ({ user, idx, changeRole }) => {
  const [isDropdownOpen, setDropDown] = useState(false)
  const [trashcanVisible, setTrashcanVisible] = useState(false)
  const { showNotifications, setNotificationText } = useContext(MyContext)

  const handleDropdownChange = () => setDropDown(prevState => !prevState)

  const handleTrashcanVisibility = event => {
    return event.type === 'mouseover'
      ? setTrashcanVisible(true)
      : event.type === 'mouseout'
      ? setTrashcanVisible(false)
      : ''
  }

  const handleChangeRole = async id => {
    try {
      await changeRole({
        variables: {
          userID: id
        }
      })
      showNotifications()
      setNotificationText('Todos los cambios en el equipo se guardaron de manera correcta.')
    } catch (e) {
      console.log(e)
    }
  }

  if (idx === 0) {
    return (
      <div>
        <TeamMemberContainer>
          <div className='user__container'>
            <UserCard>
              <div className='user-info'>
                <img className='user__avatar' src={user.photo} alt='User avatar' />
                <div>
                  <h6 className='user__name'>{user.name}</h6>
                  <p className='user__email'>{user.email}</p>
                </div>
              </div>
              <div className='user__options'>
                <button className='user-role'>Administrador</button>
              </div>
            </UserCard>
          </div>
        </TeamMemberContainer>
      </div>
    )
  } else {
    return (
      <div>
        <TeamMemberContainer>
          <div className='user__container'>
            <UserCard>
              <div className='user-info'>
                <img className='user__avatar' src={user.photo} alt='User avatar' />
                <div>
                  <h6 className='user__name'>{user.name}</h6>
                  <p className='user__email'>{user.email}</p>
                </div>
              </div>
              <div
                className='user__options nonadmin__user__options'
                onMouseOver={handleTrashcanVisibility}
                onMouseOut={handleTrashcanVisibility}
              >
                <div
                  className={`user__trashcan ${false && trashcanVisible ? 'trashcan__visible' : 'trashcan__hidden'}`}
                >
                  <TrashIcon stroke={colors.red} />
                </div>
                <button
                  className={`user-role ${user.role === 'admin' ? 'admin__user__role' : 'nonadmin__user__role'}`}
                  onClick={handleDropdownChange}
                >
                  {user.role === 'admin' ? 'Administrador' : 'Colaborador'}
                  <div
                    className={`${user.role === 'admin' ? 'arrow__admin' : 'arrow'} ${
                      isDropdownOpen ? 'arrow__rotate' : ''
                    }`}
                  >
                    <Arrow fill='#748596' />
                  </div>
                </button>
              </div>
            </UserCard>
            {isDropdownOpen && (
              <UserRolesMenu
                userRole={user.role}
                onClick={() => handleChangeRole(user.id, idx)}
                setDropdown={setDropDown}
              />
            )}
          </div>
        </TeamMemberContainer>
      </div>
    )
  }
}

export default TeamMember
