import React from 'react'
import { DeleteDashboardContainer } from '../../../style/components/reports/reports/DeleteModalReport.styled'
import Button from '../../ui/Button'

export const DeleteModal = ({
  reportId,
  reportName,
  origin,
  setOpenDelete,
  deleteReport,
  setDeleteAll,
  deleteAll,
  loading
}) => {
  const handleMensage = () => {
    switch (origin) {
      case 0:
        return (
          <p>
            ¡Atención! Estás a punto de elimar el reporte <span>{reportName}</span>, si lo haces perderás todas las
            propiedades asignadas al mismo.
          </p>
        )
      case 1:
        return (
          <p>
            ¡Atención! Estás a punto de elimar el reporte <span>{reportName}</span>, si lo haces perderás todas las
            propiedades asignadas al mismo.
          </p>
        )
      case 2:
        return (
          <>
            <p>
              ¡Atención! Estás a punto de elimar el reporte <span>{reportName}</span>, si lo haces perderás todas las
              propiedades asignadas al mismo y tú y tus compañeros dejarán de recibirlo en la fecha y horario
              establecido.
            </p>
            <div onClick={() => setDeleteAll(!deleteAll)}>
              <input type='checkbox' checked={deleteAll} readOnly />
              <label> Eliminar reportes asociados</label>
            </div>
            <br />
          </>
        )
      default:
        break
    }
  }

  return (
    <DeleteDashboardContainer>
      <h3>¡Eliminar reporte!</h3>
      {handleMensage()}
      <div className='modal-buttons'>
        <Button
          weight={500}
          variant={'link'}
          theme={'dark'}
          className='cancel-button'
          onClick={() => setOpenDelete(false)}
        >
          Cancelar
        </Button>
        <Button
          variant={'outline'}
          theme={'danger'}
          weight={500}
          className='delete-button'
          disabled={loading}
          onClick={() => deleteReport(reportId)}
        >
          Eliminar
        </Button>
      </div>
    </DeleteDashboardContainer>
  )
}
