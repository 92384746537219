import styled from 'styled-components'
import { colors } from '../../variables'
import LoginBack from '../../../assets/LoginBack.svg'

export const RecoverContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  background: #f8f8f8;
  flex-direction: column;
  background: #f8f8f8 url('${LoginBack}') center center no-repeat;
  background-size: cover;
  overflow: auto;
  .app-welcome {
    display: flex;
    flex-direction: column;
    justify-content: center;
    z-index: 1;
    width: 460px;
    height: 500px;
    margin-left: -100px;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      width: 90%;
      font-size: 26px;
      font-weight: 500;
      line-height: 41px;
      margin-bottom: 47px;
    }
  }
  div {
    margin-top: 40px;
    display: flex;
    justify-content: space-between;
    width: auto;
    height: 32px;
    img {
      height: 32px;
      margin-right: 15px;
    }
  }
`

export const RecoverForm = styled.form`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 90%;
  max-width: 480px;
  height: 500px;
  padding: 20px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  position: relative;
  justify-content: center;
  h2 {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
  }
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
  }
  .form-error {
    height: 35px;
    width: 100%;
    p {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }
  .relative {
    position: relative;
    top: 144px;
  }
  .submit-button {
    position: absolute;
    bottom: -21px;
    left: 0;
    right: 0;
    width: 80%;
    height: 42px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    margin: auto;
    cursor: pointer;
    background: #000000;
    :disabled {
      cursor: not-allowed;
      background: #000000;
      color: white;
    }
  }
  .signup-link {
    position: relative;
    top: 224px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p,
    a {
      color: ${colors.purple};
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }
`
