import styled from 'styled-components'
import { colors } from '../../variables'

export const LoginContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100vh;
  background: #f8f8f8;
  position: relative;
  .app-welcome {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 1;
    width: 50%;
    height: 100%;

    .copy-login {
      display: flex;
      flex-direction: column;
      margin: 0 80px 0 0;
      max-width: 460px;
      max-height: 500px;
      h1 {
        font-size: 73px;
        font-weight: 700;
        margin-bottom: 20px;
      }
      p {
        width: 90%;
        font-size: 26px;
        font-weight: 500;
        line-height: 41px;
        margin-bottom: 47px;
      }
      div {
        display: flex;
        justify-content: space-between;
        width: 165px;
        height: 30px;
        img {
          height: 30px;
        }
      }
    }
  }
  .form-login {
    width: 50%;
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-start;

    .img-login {
      position: absolute;
      margin: auto;
      top: 0;
      bottom: 0;
      left: 0;
      right: auto;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .app-welcome {
      .copy-login {
        margin-right: 1%;
      }
    }
    .form-login {
      .img-login {
        max-width: 100%;
      }
    }
  }
`

export const LoginForm = styled.form`
  z-index: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 420px;
  height: 500px;
  padding: 45px 50px 0;
  border-radius: 4px;
  margin-left: 130px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  position: relative;
  h2 {
    font-size: 34px;
    font-weight: 700;
    margin-bottom: 20px;
  }
  p {
    margin-bottom: 30px;
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
  }
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 400;
  }
  .form-error {
    height: 35px;
    width: 100%;
    p {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }
  .forgot-link {
    color: ${colors.lightseaGreen};
    font-size: 15px;
    font-weight: 500;
    margin-bottom: 0;
    align-self: center;
  }
  .relative {
    position: relative;
    top: 40px;
  }
  .submit-button {
    position: absolute;
    bottom: -25px;
    width: 322px;
    height: 50px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .signup-link {
    position: relative;
    top: 120px;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    p,
    a {
      color: ${colors.purple};
      font-size: 14px;
      font-weight: 500;
      margin: 0;
    }
    a {
      text-decoration: underline;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    width: 389px;
    margin-left: 20%;
    .signup-link {
      width: 318px;
      top: 98px;
      right: 16px;
      p {
        width: 208px;
      }
      a {
        width: 141px;
      }
    }
  }
`

export const LoginBackground = styled.img`
  position: absolute;
  transform: translate(265px, 10px);
`
