import styled from 'styled-components'
import { colors } from '../../../variables'

export const Tag = styled.span`
  height: 30px;
  padding: 0 6px 0 10px;
  border-radius: 4px;
  margin: 0 8px 8px 0;
  background-color: ${colors.white};
  color: ${colors.richBlack};
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  span {
    padding: 0px 3px;
    margin-left: 6px;
    vertical-align: 2px;
    cursor: pointer;
  }
`

export const SearchBarContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  width: ${props => props.width};
  min-height: 50px;
  max-height: 130px;
  padding: 10px 10px 0 10px;
  border: 1px solid ${colors.darkGray};
  border-radius: 4px;
  background: ${colors.lightGray};
  overflow: auto;
  :focus-within {
    .searchbar-placeholder {
      display: none;
    }
    .dropdown-visible {
      display: block;
    }
  }
  :not(:focus-within) {
    .dropdown-visible {
      display: none;
    }
  }
  input {
    width: ${props => props.iwidth};
    height: 30px;
    margin-bottom: 7px;
    border: none;
    outline: none;
    caret-color: ${colors.darkGray};
    background: ${colors.lightGray};
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
  }
  .searchbar-placeholder {
    padding-top: 5px;
    display: inline;
    font-size: 14px;
    font-weight: 400;
  }
  .dropdown-closed {
    display: none;
  }
  .dropdown-visible {
    position: absolute;
    display: flex;
    flex-direction: column;
    width: 100px;
    height: 150px;
    border-top: 1px solid ${colors.lightseaGreen};
    border-radius: 0 0 4px 4px;
    background-color: ${colors.white};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.5);
    button {
      width: 100%;
      height: 50px;
      border: none;
      outline: none;
      background-color: transparent;
      font-size: 14px;
      font-weight: 500;
      text-align: left;
      padding-left: 30px;
    }
  }
`
