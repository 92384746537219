import styled from 'styled-components'
import { colors } from '../../variables'

const DashboardCardContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 26px;
  position: relative;
  width: 98%;
  padding: 0 5px 0 16px;
  .dashboard-menu {
    position: absolute;
    z-index: 5;
    top: 15px;
    right: 15px;
    button {
      border: none;
      background: none;
      outline: none;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        color: #748596;
      }
    }
  }
  .dashboard-options {
    display: flex;
    flex-direction: column;
    z-index: 6;
    position: absolute;
    top: 25px;
    right: -5px;
    left: -168px;
    width: 203px;
    height: 149px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .edit__option {
      height: 49.5px;
      width: 100%;
      padding-left: 17.5px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #748596;
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 18.17px;
        height: 18.17px;
        margin-right: 17px;
      }
    }
    .delete__option {
      height: 49.5px;
      width: 100%;
      padding-left: 17.5px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.red};
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 19px;
        height: 22px;
        margin-right: 17px;
        color: ${colors.red};
      }
    }
  }
  .card-content {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    border-radius: 4px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    cursor: pointer;
    .dashboard-header {
      display: flex;
      justify-content: space-between;
      padding-bottom: 8.5px;
      border-bottom: 1px solid #aaafbe;
      .dashboard__header__container {
        width: 91%;
        .board__name {
          margin-bottom: 8px;
          padding: 17px 0 0 15px;
          color: ${colors.richBlack};
          font-size: 16px;
          font-weight: 600;
          line-height: 19.5px;
        }
        .board__lastmodify {
          color: ${colors.darkGray};
          font-size: 12px;
          font-weight: 500;
          line-height: 14.63px;
          padding-left: 15px;
        }
      }
    }
    .dashboard-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .searchby__user {
        display: flex;
        flex-direction: row;
        padding: 13px 0 15px 15px;
        .user__profile__img {
          width: 30px;
          height: 30px;
          border-radius: 50%;
        }
        .user__profile__name {
          align-self: center;
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
          color: ${colors.darkGray};
          margin-left: 7px;
        }
      }
      .board-info {
        width: 25px;
        height: 22px;
        align-self: center;
        margin-right: 19px;
        display: flex;
        flex-direction: row;
        svg {
          width: 18.25px;
          height: 17.39px;
          margin-right: 2px;
          path {
            fill: ${colors.darkGray};
          }
        }
        p {
          color: ${colors.richBlack};
          font-size: 14px;
          font-weight: 500;
          line-height: 18px;
        }
      }
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .card-content {
      .dashboard-header {
        .dashboard__header__container {
          width: 85%;
          .board__lastmodify {
            padding: 0 15px;
          }
        }
      }
    }
  }

  @media (min-width: 2560px) {
    .card-content {
      .dashboard-header {
        .dashboard__header__container {
          width: 100%;
        }
      }
    }
  }
`

export default DashboardCardContainer
