import moment from 'moment'
import React, { useState } from 'react'
import { Row } from '../../../style/components/search/searches/Clipping.styled'
import { NoteContent, RowContent, TableRow } from '../../../style/pages/clippings/clippingDetail/ClippingDetail.styled'
import Points from '../../../assets/icons/points.svg'

const ClippingTableRow = ({ note }) => {
  const [openRow, setOpenRow] = useState(false)

  return (
    <RowContent open={openRow ? 'auto' : 'auto'}>
      <TableRow onClick={() => setOpenRow(!openRow)}>
        <Row>{note.medio}</Row>
        <Row>{note.titulo}</Row>
        <Row>{note.medio}</Row>
        <Row>
          <button onClick={() => window.open(note.testigo, '_blank')}>Abrir Testigo</button>
        </Row>
        <Row>{note.tendencia}</Row>
        <Row>{note.estado}</Row>
      </TableRow>
      {openRow && (
        <NoteContent>
          <img src={Points} alt='points' width='35px' height='35px' style={{ marginRight: '5px' }} />
          <p className='infoText'>{`${note.estado} | ${moment(note.fecha).format(
            'DD MMM YYYY [|] HH:MM [hrs]'
          )} | Clasificación`}</p>
          <div className='resumeContainer'>
            <p className='resume'>{note.texto}</p>
          </div>
          <div className='reportContainer'>
            <button>Reportar</button>
          </div>
        </NoteContent>
      )}
    </RowContent>
  )
}

export default ClippingTableRow
