import React from 'react'
import { SharedDashboardsContainer } from '../../../style/components/dashboard/dashboards/SharedDashboards.styled'
import NoDashboards from './NoDashboards'
import DashboardCard from './DashboardCard'

const SharedDashboards = ({
  dashboards,
  filtersOpen,
  filters,
  order,
  setDashboardToDelete,
  setDashboardToDuplicate
}) => {
  if (dashboards.length === 0) return <NoDashboards filtersOpen={filtersOpen} shared={true} />

  let sorted = [...dashboards]

  if (order.AtoZ) {
    sorted = dashboards.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.ZtoA) {
    sorted = dashboards.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = -1
      } else if (nameA < nameB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.recentDate) {
    sorted = dashboards.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = 1
      } else if (dateA < dateB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.oldDate) {
    sorted = dashboards.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = -1
      } else if (dateA < dateB) {
        comparison = 1
      }
      return comparison
    })
  }

  return (
    <SharedDashboardsContainer filtersOpen={filtersOpen}>
      <h2>Tableros Compartidos</h2>
      <section>
        {sorted
          .filter(dashboard => dashboard.name.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(dashboard => dashboard.createdBy.name.toLowerCase().includes(filters.creator.toLowerCase()))
          .filter(dashboard => {
            if (filters.from !== '') {
              const year = filters.from.split('').splice(0, 4).join('')
              const month = filters.from.split('').splice(5, 2).join('') - 1
              const day = filters.from.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return dashboard.createdAt > date
            }
            return dashboard
          })
          .filter(dashboard => {
            if (filters.to !== '') {
              const year = filters.to.split('').splice(0, 4).join('')
              const month = filters.to.split('').splice(5, 2).join('') - 1
              const day = filters.to.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return dashboard.createdAt < date
            }
            return dashboard
          })
          .map(dashboard => (
            <DashboardCard
              key={dashboard.id}
              dashboard={dashboard}
              setDashboardToDelete={setDashboardToDelete}
              setDashboardToDuplicate={setDashboardToDuplicate}
            />
          ))}
      </section>
    </SharedDashboardsContainer>
  )
}

export default SharedDashboards
