import styled from 'styled-components'
import { colors } from '../../../variables'

const EditSearchContainer = styled.div`
  z-index: 30;
  width: 632px;
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 47px 35px 47px;
  border-radius: 10px;
  background: ${colors.white};
  font-family: Montserrat;
  border-radius: 10px;

  .editsearch__header__container {
    align-self: center;

    .editsearch__header {
      font-size: 24px;
      font-weight: 800;
      line-height: 29.26px;
      color: ${colors.richBlack};
    }
  }

  .editsearch__content {
    margin: 23px 0 40px;
    width: 100%;

    .editsearch__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${colors.richBlack};
    }

    .editsearch__rename {
      margin-top: 50px;

      .renamesearch__text {
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
      }

      .renamesearch__input {
        width: 100%;
        border: 1px solid #748596;
        border-radius: 4px;
        color: ${colors.richBlack};
        margin: 8px 0 23px 0;
        padding: 15px 0 15px 26px;
      }
    }
  }

  .editsearch__buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  @media (min-width: 1440px) {
    width: 900px;
  }

  @media (min-width: 2560px) {
    width: 1200px;
  }
`

export default EditSearchContainer
