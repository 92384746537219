import { useQuery } from '@apollo/client'
import React, { useContext } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useParams } from 'react-router'
import ClippingTableRow from '../../../components/clipping/clippingTableRow'
import { UIContext } from '../../../contexts/UIContext'
import { GET_CLIPPING } from '../../../services/queries'
import { MainContainer, Spinner, Toolbar, Topbar } from '../../../style/components'
import {
  ClippingDetailContainer,
  TableHead,
  Head
} from '../../../style/pages/clippings/clippingDetail/ClippingDetail.styled'

const ClippingDetail = ({ history }) => {
  const { id } = useParams()

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass, toolbarClass } = uiCTX

  const { data, loading } = useQuery(GET_CLIPPING, { variables: { id } })

  const headers = ['Medio', 'Titular', 'Nombre de medio', 'Testigo', 'Tendencia', 'Cobertura']

  if (loading) return <Spinner />

  const notes = JSON.parse(data.getClipping.notes)

  return (
    <MainContainer className={mainClass()}>
      <ClippingDetailContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => history.goBack()}>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </button>
            <h1>{data.getClipping.name}</h1>
          </div>
          <div className='right-topbar'>
            <button onClick={() => console.log('Todo Export CSV')}>Exportar Clipping</button>
          </div>
        </Topbar>
        <Toolbar className={toolbarClass()}>
          <div className='left-toolbar'>{notes.notes.length} resultados</div>
        </Toolbar>
        <br />
        <hr />
        <TableHead>
          {headers.map((e, i) => (
            <Head key={i}>{e}</Head>
          ))}
        </TableHead>
        <div style={{ overflowY: 'scroll', maxHeight: '80%' }}>
          {notes.notes.map((e, i) => (
            <ClippingTableRow key={i} note={e} />
          ))}
        </div>
      </ClippingDetailContainer>
    </MainContainer>
  )
}

export default ClippingDetail
