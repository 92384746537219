import React from 'react'
import { PlanSelectionContainer } from '../../style/components/auth/PlanSelection/PlanSelection.styled'
import PlanCard from './PlanCard'
import { ButtonSpinner, Switch } from '../../style/components'
import Button from '../ui/Button'

const PlanSelection = ({
  products,
  productSelected,
  setProductSelected,
  monthlyPlans,
  setMonthlyPlans,
  setStep,
  handleCreateWorkspace,
  loading
}) => {
  const handleNext = async productSelected => {
    if (productSelected.name === 'Gratuito') {
      await handleCreateWorkspace(productSelected.name.toUpperCase(), '')
      setStep(5)
    }
  }

  return (
    <PlanSelectionContainer>
      <section className='left-information'>
        <h1>Selecciona un plan</h1>
        <div className='plan-switch'>
          <p className={monthlyPlans ? 'bold' : 'normal'}>Mensual</p>
          <Switch>
            <input
              type='checkbox'
              value={monthlyPlans}
              checked={!monthlyPlans}
              onChange={() => setMonthlyPlans(!monthlyPlans)}
              disabled={productSelected.name === 'Gratuito'}
            />
            <span className='slider round' />
          </Switch>
          <p className={!monthlyPlans ? 'bold' : 'normal'}>Anual</p>
        </div>
        <Button
          size={'full'}
          weight={500}
          theme={'secondary'}
          onClick={productSelected.name === 'Gratuito' ? () => handleNext(productSelected) : () => setStep(3)}
        >
          {loading ? (
            <ButtonSpinner />
          ) : productSelected.name === 'Gratuito' ? (
            'Continuar'
          ) : (
            'Siguiente: datos de facturación'
          )}
        </Button>
      </section>
      <section className='right-information'>
        {products.map(product => (
          <PlanCard
            key={product.key}
            product={product}
            monthlyPlans={monthlyPlans}
            productSelected={productSelected}
            setProductSelected={setProductSelected}
          />
        ))}
      </section>
    </PlanSelectionContainer>
  )
}

export default PlanSelection
