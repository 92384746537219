import React, { useEffect, useRef, useState } from 'react'
import { AiOutlineCalendar, AiOutlineUser } from 'react-icons/ai'
import { BsClock, BsPause, BsPencilSquare, BsPlay, BsThreeDots, BsTrash } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { Card } from '../../../style/components/reports/reports/ReportCard.styled'
import { colors } from '../../../style/variables'

const ReportOptions = styled.div`
  position: absolute;
  z-index: 6;
  width: 190px;
  height: ${props => props.size}px;
  background: ${colors.white};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  border-radius: 4px;
`

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const StatusInfo = ({ report }) => {
  if (report.paused && report.editable)
    return (
      <div className='report-schedule'>
        <p className='pauseMessage'>Reporte en pausa</p>
      </div>
    )
  if (report.paused && !report.editable)
    return (
      <div className='report-schedule'>
        <p className='pauseMessage'>Tablero eliminado</p>
      </div>
    )
  if (report.counter === 1)
    return (
      <div className='report-schedule'>
        <p className='pauseMessage'>Reporte a punto de expirar</p>
      </div>
    )
  if (report.counter === 0)
    return (
      <div className='report-schedule'>
        <p className='pauseMessage'>Reporte expirado</p>
      </div>
    )
  return (
    <div className='report-schedule'>
      <p>
        <AiOutlineCalendar /> {report.schedule}
      </p>
      <p>
        <BsClock /> {report.reportHour}
      </p>
    </div>
  )
}

export const ReportCard = ({ report, handleDeleteWarning, handleOpenDetails, handleStopPlayTicket }) => {
  const reportCardRef = useRef()

  const history = useHistory()

  const [showReportMenu, setShowReportMenu] = useState(false)

  useOutsideClick(reportCardRef, () => setShowReportMenu(false))

  return (
    <Card ref={reportCardRef} onClick={() => handleOpenDetails(report.id, report.dashboard)}>
      <div className='report-up'>
        <div>
          <h5>{report.name}</h5>
          <StatusInfo report={report} />
        </div>
        <div className='report-menu'>
          <button
            onClick={e => {
              e.cancelBubble = true
              e.stopPropagation()
              setShowReportMenu(!showReportMenu)
            }}
          >
            <BsThreeDots />
          </button>
          {showReportMenu && (
            <ReportOptions className='report-options' size={report.editable ? 126 : 42}>
              {report.editable && (
                <>
                  <button
                    className='full_option option_button'
                    disabled={report.dashboard ? false : true}
                    onClick={e => {
                      setShowReportMenu(!showReportMenu)
                      e.cancelBubble = true
                      e.stopPropagation()
                      history.push(`/reports/scheduled/edit/${report.id}`)
                    }}
                  >
                    <BsPencilSquare /> Editar Reporte
                  </button>
                  <button
                    hidden={report.editable}
                    className='full_option option_button'
                    disabled={report.dashboard ? false : true}
                    onClick={e => {
                      setShowReportMenu(!showReportMenu)
                      e.cancelBubble = true
                      e.stopPropagation()
                      handleStopPlayTicket(report.id, report.paused, report.name)
                    }}
                  >
                    {report.paused ? (
                      <>
                        <BsPlay /> Reiniciar Reporte
                      </>
                    ) : (
                      <>
                        <BsPause />
                        Pausar Reporte
                      </>
                    )}
                  </button>
                </>
              )}
              <button
                className='full_option delete_button'
                onClick={e => {
                  setShowReportMenu(!showReportMenu)
                  e.cancelBubble = true
                  e.stopPropagation()
                  handleDeleteWarning(report.id, report.name)
                }}
              >
                <BsTrash />
                Eliminar reporte
              </button>
            </ReportOptions>
          )}
        </div>
      </div>
      <div className='report-down'>
        <p className='report-board'>
          Tablero: {report.dashboard ? report.dashboard.name : 'No hay información del tablero'}
        </p>
        <div className='report-info'>
          <AiOutlineUser />
          <p>{report.users.length + 1}</p>
        </div>
      </div>
    </Card>
  )
}
