import React from 'react'
import { NavLink } from 'react-router-dom'
import { SidebarContainer } from '../../style/components/sidebar/SidebarM.styled'
// import { UIContext } from '../../contexts/UIContext';
import SearchIcon from '../../assets/SearchIcon'
import DashboardIcon from '../../assets/DashboardIcon'
// import SettingsIcon from '../../assets/icons/SettingsIcon'

const Sidebar = ({ userRole }) => {
  return (
    <SidebarContainer className='sidebar-container'>
      <div className='sidebar'>
        <nav className='sidebar-nav'>
          <NavLink to='/searches' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <SearchIcon />
            </div>
          </NavLink>

          <NavLink to='/dashboards' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <DashboardIcon />
            </div>
          </NavLink>
          {/* {userRole === 'admin' &&
                <NavLink to='/workspace' className='sidebar-navlink'>
                  <div className='sidebar-icon'>
                    <SettingsIcon />
                  </div>
                </NavLink>} */}
        </nav>
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
