import React, { useContext, useEffect } from 'react'
import { useQuery } from '@apollo/client'

import SearchesContainer from '../../style/pages/searchMobile/Seaches.styled'
import { MainContainer, Topbar, Spinner } from '../../style/components'
import AllSearches from '../../components/search/searches/AllSearchesM'

import { SEARCHES_QUERY } from '../../services/queries'
import { SEARCHES_SUBSCRIPTION } from '../../services/subscriptions'
import { MyContext } from '../../context'

const Searches = () => {
  const context = useContext(MyContext)

  const { data, loading, subscribeToMore, refetch, error } = useQuery(SEARCHES_QUERY, { fetchPolicy: 'network-only' })

  if (error) context.handleErrors(error)

  useEffect(() => {
    refetch()
  }, [refetch])

  useEffect(() => {
    subscribeToMore({
      document: SEARCHES_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        let newSearch = subscriptionData.data.searchChanged
        if (newSearch.operation === 'created') {
          return Object.assign({}, prev, {
            searches: [newSearch, ...prev.searches]
          })
        } else if (newSearch.operation === 'removed') {
          return Object.assign({}, prev, {
            searches: prev.searches.filter(search => search.id !== newSearch.id)
          })
        }
      }
    })
  }, [subscribeToMore])

  if (loading) return <Spinner />

  return (
    <MainContainer>
      <SearchesContainer>
        <Topbar className='topbarMobile'>
          <div className='topbarMobile-title'>
            <p>Búsquedas</p>
          </div>
        </Topbar>

        <AllSearches
          searches={data ? data.searches : []}
          filtersOpen={false}
          ordersOpen={false}
          filters={{
            name: '',
            creator: '',
            from: '',
            to: '',
            source: ''
          }}
          order={{
            AtoZ: '',
            ZtoA: '',
            recentDate: '',
            oldDate: ''
          }}
          setSearchToDelete={null}
        />
      </SearchesContainer>
    </MainContainer>
  )
}

export default Searches
