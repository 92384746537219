import styled from 'styled-components'
import { colors } from '../../variables'

const CancelSubscriptionContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 750px;
  padding: 60px;
  border-radius: 4px;
  background: ${colors.white};
  .filters--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 30px;
    h3 {
      font-size: 30px;
      font-weight: 700;
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      path {
        stroke: ${colors.darkGray};
      }
    }
  }
  .modal-copy {
    width: 100%;
    margin-bottom: 25px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    span {
      color: ${colors.red};
      font-size: 16px;
      font-weight: 400;
    }
  }
  button {
    width: 210px;
    height: 50px;
    margin-top: 25px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    background: ${colors.lightseaGreen};
    color: ${colors.white};
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    label {
      width: 100%;
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 400;
    }
    input {
      width: 100%;
      height: 50px;
      padding: 0 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 400;
    }
    .errors-space {
      width: 100%;
      height: 30px;
      .error-message {
        font-size: 12px;
        padding-top: 5px;
        color: ${colors.red};
      }
    }
    .submit-button {
      width: 210px;
      height: 50px;
      margin-top: 25px;
      border: none;
      border-radius: 4px;
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`

export default CancelSubscriptionContainer
