import React, { useEffect, useRef } from 'react'
import { WidgetContainer } from '../../../style/components/dashboard/dashboard/WidgetM.syled'

import TonalidadMedios from '../../widgets/TonalidadMedios'
import NumeroNotas from '../../widgets/NumeroNotas'
import TipoMedios from '../../widgets/TipoMedios'
import TemasMomento from '../../widgets/TemasMomento'
import MapaCalor from '../../widgets/MapaCalor'
import ExposicionMedios from '../../widgets/ExposicionMedios'
import ShareVoice from '../../widgets/ShareVoice'
import MediosGanados from '../../widgets/MediosGandos'
import TonalidadTwitter from '../../widgets/TonalidadTwitter'
import NubeHashtagsTwitter from '../../widgets/NubeHashtagsTwitter'
import InfluencersTwitter from '../../widgets/InfluencersTwitter'
import MencionesTiwtter from '../../widgets/MencionesTwitter'
import PublicadoresVolumenTwitter from '../../widgets/PublicadoresVolumenTwitter'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Widget = ({ widget, dashboard, mobile }) => {
  const setShowWidgetMenu = false

  const menuOptions = useRef()

  useOutsideClick(menuOptions, () => {
    setShowWidgetMenu(false)
  })

  return (
    <WidgetContainer width={mobile ? '98%' : widget.styleParams.width}>
      <div className='widget--header'>
        <div className='widget-title'>
          <h1>{widget.name}</h1>
        </div>
      </div>

      {(() => {
        switch (widget.kind) {
          case 'TonalidadMedios':
            return <TonalidadMedios widget={widget} />
          case 'NumeroNotas':
            return <NumeroNotas widget={widget} />
          case 'MediosGanados':
            return <MediosGanados widget={widget} />
          case 'TipoMedios':
            return <TipoMedios widget={widget} />
          case 'TemasMomento':
            return <TemasMomento widget={widget} />
          case 'MapaCalor':
            return <MapaCalor widget={widget} />
          case 'ExposicionMedios':
            return <ExposicionMedios widget={widget} />
          case 'ShareVoice':
            return <ShareVoice widget={widget} />
          case 'TonalidadTwitter':
            return <TonalidadTwitter widget={widget} />
          case 'NubeHashtags':
            return <NubeHashtagsTwitter widget={widget} />
          case 'InfluencersTwitter':
            return <InfluencersTwitter widget={widget} />
          case 'MencionesTwitter':
            return <MencionesTiwtter widget={widget} />
          case 'PublicadoresVolumen':
            return <PublicadoresVolumenTwitter widget={widget} />
          default:
            return null
        }
      })()}
    </WidgetContainer>
  )
}
export default Widget
