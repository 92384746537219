import styled from 'styled-components'

const DashboardsContainer = styled.div`
  padding-top: 40px;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .toolbarMobile {
    top: 56px;
    display: flex;
    padding: 0;
    .left-toolbar {
      display: flex;
      width: 100%;
      height: 100%;
      .section-button {
        width: 50%;
        height: 100%;
        background: #ffffff;
        border: none;
        border-bottom: 2px solid #fff;
        color: #000;
        &:focus {
          outline: none;
        }
      }
      .section-button-active {
        width: 50%;
        height: 100%;
        background: #ffffff;
        border: none;
        border-bottom: 2px solid #6873e5;
        color: #6873e5;
      }
    }
  }
  .topbarMobile {
    justify-content: center;
    background: #000;
    height: 56px;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 10px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 40px;
          height: 28px;
          color: white;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
      p {
        width: auto;
        font-size: 18px;
        color: white;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      button {
        cursor: pointer;
      }
    }
  }
`

export default DashboardsContainer
