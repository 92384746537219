import styled from 'styled-components'
import { colors } from '../../variables'

export const WorkspaceContainer = styled.div`
  .topbar {
    justify-content: space-between;
    h1 {
      color: ${colors.richBlack};
      font-size: 22px;
      font-weight: 600;
    }
  }
  .section-button {
    width: 60px;
    height: 100%;
    border: none;
    outline: none;
    margin-right: 30px;
    background: transparent;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .section-button-active {
    color: ${colors.purple};
    border-bottom: 2px solid ${colors.purple};
    cursor: pointer;
  }
`
