import styled from 'styled-components'
import { colors } from '../../../variables'

export const Container = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 632px;
  height: ${props => props.size};
  padding: 50px;
  border-radius: 4px;
  background: ${colors.white};
  h3 {
    margin-bottom: 30px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    color: ${colors.richBlack};
  }
  h4 {
    margin-bottom: 10px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 22px;
    color: ${colors.richBlack};
  }
  p {
    font-family: Montserrat;
    font-style: normal;
    font-size: 12px;
    font-weight: 500;
    line-height: 15px;
    color: ${colors.richBlack};
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }
  input {
    width: 100%;
    height: 48px;
    border-radius: 5px;
    padding: 5px;
    &.nameClipping {
      margin-bottom: 15px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      width: 210px;
      height: 50px;
      border-radius: 4px;
      outline: none;
      font-size: 16px;
      font-weight: 500;
      margin-left: 10px;
      cursor: pointer;
      &.ok-button {
        border: none;
        background: ${colors.silverSand};
      }
      &:hover {
        border: none;
        background: ${colors.purple};
        color: ${colors.white};
      }
    }
  }
  .toolbar {
    justify-content: space-between;
    width: 100%;
    margin: 3% 0px 0px 0px;
    .left-toolbar {
      height: 100%;
      .section-button {
        width: 100px;
        height: 100%;
        border: none;
        outline: none;
        margin-right: 30px;
        background: transparent;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .section-button-active {
        color: ${colors.purple};
        border-bottom: 2px solid ${colors.purple};
      }
    }
  }
`

export const InmediatoSection = styled.div`
  margin-top: 7%;
  width: 100%;
  .selection {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 50px;
    .exportSelection {
      display: flex;
      justify-content: center;
      justify-content: center;
      height: 35px;
      text-align: center;
      align-items: center;
      margin-right: 50px;
      .checkbox-indicator {
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
        margin-right: 5px;
        .checked {
          width: 23px;
          height: 23px;
          fill: ${colors.lightseaGreen};
        }
        .not-checked {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
          cursor: pointer;
        }
      }
    }
  }
`

export const ProgramadoSection = styled.div`
  margin-top: 7%;
  width: 100%;
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 18px;
  }
  .period {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    .periodSelect {
      width: 47%;
    }
    .periodItem {
      width: 23%;
    }
  }
  .creation {
    margin-bottom: 20px;
    .periodCreation {
      display: flex;
      width: 100%;
      justify-content: space-around;
    }
  }
  .selection {
    display: flex;
    justify-content: flex-start;
    margin-bottom: 5px;
    .exportSelection {
      display: flex;
      justify-content: center;
      justify-content: center;
      height: 35px;
      text-align: center;
      align-items: center;
      margin-right: 50px;
      .checkbox-indicator {
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
        margin-right: 5px;
        .checked {
          width: 23px;
          height: 23px;
          fill: ${colors.lightseaGreen};
        }
        .not-checked {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
          cursor: pointer;
        }
      }
    }
  }
`

export const ButtonsSection = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.jt ? props.jt : 'flex-end')};
  margin-top: ${props => props.mt};
  button {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 18.29px;
    height: 47px;
    width: 212px;
    color: ${colors.richBlack};
    border-radius: 5px;
    cursor: pointer;
    border: none;
    &.exportButton {
      background: ${colors.lightGray};
      :enabled {
        :hover {
          background: ${colors.purple};
          color: ${colors.white};
        }
      }
      :disabled {
        cursor: not-allowed;
      }
    }
    &.cancelButton {
      background: none;
    }
    &.moreButton {
      background: none;
      border: 2px solid ${colors.purple};
      color: ${colors.purple};
    }
  }
`
