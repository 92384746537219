import React, { useEffect, useRef } from 'react'
import { MediaOrderContainer, ButtonDiv } from '../../style/components/media/MediaOrder.styled'
import { BiCheck } from 'react-icons/bi'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const MediaOrder = ({ showOrder, resetOrder, orderStates }) => {
  const orderMenu = useRef()

  useOutsideClick(orderMenu, () => {
    showOrder(false)
  })

  const handleOrder = type => {
    if (type === 'az') {
      if (orderStates.nameAtoZ) {
        return resetOrder()
      } else {
        orderStates.setNameAZOrder(true)
        orderStates.setNameZAOrder(false)
      }
    } else if (type === 'za') {
      if (orderStates.nameZtoA) {
        return resetOrder()
      } else {
        orderStates.setNameZAOrder(true)
        orderStates.setNameAZOrder(false)
      }
    }
  }

  return (
    <MediaOrderContainer ref={orderMenu}>
      <div className='order--options'>
        <div className='sub_options'>
          <button className='size-button' onClick={() => handleOrder('az')}>
            <ButtonDiv selected={orderStates.nameAtoZ}>
              {orderStates.nameAtoZ ? (
                <>
                  <p>A - Z </p>
                  <BiCheck />
                </>
              ) : (
                <p>A - Z</p>
              )}
            </ButtonDiv>
          </button>

          <button className='size-button' onClick={() => handleOrder('za')}>
            <ButtonDiv selected={orderStates.nameZtoA}>
              {orderStates.nameZtoA ? (
                <>
                  <p>Z - A </p>
                  <BiCheck />
                </>
              ) : (
                <p>Z - A</p>
              )}
            </ButtonDiv>
          </button>
        </div>
      </div>
    </MediaOrderContainer>
  )
}

export default MediaOrder
