import styled from 'styled-components'
import { colors } from '../../../variables'

export const ModalContainer = styled.div`
  z-index: ${props => props.zIndex || 30};
  width: 45%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 47px 35px 47px;
  border-radius: 10px;
  background: ${colors.white};
  font-family: Montserrat;
  border-radius: 10px;

  .modal__header {
    width: 100%;
    text-align: center;
    margin-bottom: 27px;

    .header__text {
      font-size: 30px;
      font-weight: 800;
    }
  }

  .modal__content {
    width: 100%;

    .content__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      margin-bottom: 30px;
      padding-left: 8px;
      color: ${colors.richBlack};
    }

    .registered__payments {
      margin-bottom: 20px;
    }
  }

  .cumplimiento {
    .add {
      cursor: pointer;
      color: ${colors.lightseaGreen};
      font-size: 14px;
      line-height: 18px;
      padding-left: 45px;
      margin-bottom: 50px;
    }
    .add:hover {
      text-decoration: underline;
    }
  }

  .contenido .checkbox .left .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .contenido .checkbox .left .container .icon {
    margin-right: 10px;
  }
  .contenido .checkbox .left .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .contenido .checkbox .left .container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  .contenido .checkbox .left .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .contenido .checkbox .left .container input:checked ~ .checkmark {
    background-color: ${colors.lightseaGreen};
  }
  .contenido .checkbox .left .container .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .contenido .checkbox .left .container input:checked ~ .checkmark:after {
    display: block;
  }
  .contenido .checkbox .left .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }

  .contenido .herramientas {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .contenido .herramientas div {
    justify-self: center;
    align-self: center;
    display: flex;
    height: 30px;
  }
  .contenido .herramientas div .icon {
    font-size: 30px;
    color: ${colors.silverSand};
    margin-right: 11px;
  }
  .contenido .herramientas div p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.richBlack};
  }

  .contenido .payment-methods {
    margin-bottom: 20px;
  }
  .buttonContainer {
    width: 100%;
    display: flex;
    justify-content: space-between;
    gap: 5px;
    flex-wrap: wrap;
  }
  .buttonContainer .buttonLeft button,
  .buttonContainer .buttonRight button {
    /* width: 292px; */
    height: 47px;
  }

  .buttonContainer .buttonLeft button {
    border: 1px solid ${colors.purple};
    background-color: transparent;
    color: ${colors.purple};
    width: 292px;

    font-size: 15px;
    font-weight: 600;
  }
  .buttonContainer .buttonRight button {
    border: 1px solid ${colors.torquioseBlue};
    background-color: ${colors.torquioseBlue};
    color: ${colors.richBlack};
    width: 292px;

    font-size: 15px;
    font-weight: 600;
  }
  .buttonContainer .buttonRightError {
    width: 100%;
    height: 47px;
    button {
      border: 1px solid ${colors.torquioseBlue};
      background-color: ${colors.torquioseBlue};
      color: ${colors.richBlack};
      font-size: 15px;
      font-weight: 600;
      width: 100%;
      height: 47px;
    }
  }
`

export const Card = styled.article`
  display: flex;
  align-items: center;

  .checkbox-indicator {
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    outline: none;
    margin-right: 5px;
    .checked {
      width: 23px;
      height: 23px;
      fill: ${colors.lightseaGreen};
    }
    .not-checked {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
      cursor: pointer;
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    width: 210px;
    svg {
      width: 25px;
      height: 25px;
      fill: ${colors.light};
      margin-right: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-right: 15px;
    }
  }
  .hover-button {
    width: 70px;
    border: none;
    background: none;
    outline: none;
    display: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &.default {
      color: ${colors.lightseaGreen};
    }
    &.delete {
      color: ${colors.red};
      cursor: pointer;
    }
  }
  &:hover {
    background: ${colors.lightGray};
    .hover-button {
      display: block;
    }
  }
`
