import React from 'react'

export const Search = () => (
  <svg width='100px' height='100px' viewBox='0 0 100 100'>
    <g id='icons/onboard/search' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Group-2'>
        <rect id='Rectangle' x='0' y='0' width='100' height='100'></rect>
        <g id='Icono_Realiza_Busqueda' transform='translate(1.000000, 5.000000)'>
          <path
            d='M88.8039818,83.5388669 C88.5240619,83.6182953 88.2341522,83.65695 87.9432001,83.653834 L3.19923876,83.653834 C1.43561226,83.6457819 0.00785591408,82.2180256 5.09685171e-15,80.4543991 C0.00901359604,80.169055 0.0474746202,79.885405 0.114770897,79.6079637 L8.7225882,48.3185478 L8.7225882,3.35704875 C8.71875234,2.50358283 9.0560936,1.68396428 9.65959123,1.08046665 C10.2630889,0.476969018 11.0827074,0.139627762 11.9361733,0.143431263 L79.3640755,0.143431263 C80.2175415,0.139627762 81.03716,0.476969018 81.6406576,1.08046665 C82.2441553,1.68396428 82.5814965,2.50358283 82.5776607,3.35704875 L82.5776607,48.3185478 L91.0993998,79.6079637 C91.3258422,80.4339822 91.2100457,81.3163916 90.7781473,82.0560176 C90.3462489,82.7956436 89.6346542,83.3301417 88.8039818,83.5388669 Z'
            id='Path'
            fill='#CCF1F1'
            fillRule='nonzero'
          ></path>
          <path
            d='M94.356024,88.8039818 L9.61206266,88.8039818 C7.84283614,88.8039995 6.40637586,87.373952 6.39847753,85.6047431 C6.39946684,85.3140679 6.43804375,85.0247411 6.51324843,84.7439614 L15.0493339,53.4688918 L88.9474455,53.4688918 L97.5552628,84.7439614 C98.0135692,86.4542163 97.0086415,88.2144398 95.3028839,88.689211 C95.0234413,88.7709178 94.7331905,88.8096179 94.4421022,88.8039818 L94.356024,88.8039818 Z'
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <path
            d='M64.013468,5.27946128 L85.719514,5.27946128 C87.4966637,5.27946128 88.939547,6.71591423 88.9474455,8.49304641 L88.9474455,53.4688918 L15.0493339,53.4688918 L15.0493339,8.49304641 C15.0571901,6.7214967 16.4913693,5.28731749 18.262919,5.27946128 L42.0204948,5.27946128'
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='19.8697116 58.2892695 26.2968819 58.2892695 26.2968819 61.5028546 19.8697116 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='29.510467 58.2892695 35.8659054 58.2892695 35.8659054 61.5028546 29.510467 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='39.136876 58.2892695 45.5640463 58.2892695 45.5640463 61.5028546 39.136876 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='58.4183868 58.2892695 64.845557 58.2892695 64.845557 61.5028546 58.4183868 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='68.0591421 58.2892695 74.471966 58.2892695 74.471966 61.5028546 68.0591421 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='77.6855512 58.2892695 84.1127214 58.2892695 84.1127214 61.5028546 77.6855512 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='48.7776314 58.2892695 55.2048016 58.2892695 55.2048016 61.5028546 48.7776314 61.5028546'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='19.8697116 64.7164398 26.2968819 64.7164398 26.2968819 67.9300249 19.8697116 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='29.510467 64.7164398 35.8659054 64.7164398 35.8659054 67.9300249 29.510467 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='39.136876 64.7164398 45.5640463 64.7164398 45.5640463 67.9300249 39.136876 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='58.4183868 64.7164398 64.845557 64.7164398 64.845557 67.9300249 58.4183868 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='68.0591421 64.7164398 74.471966 64.7164398 74.471966 67.9300249 68.0591421 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='77.6855512 64.7164398 84.1127214 64.7164398 84.1127214 67.9300249 77.6855512 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='48.7776314 64.7164398 55.2048016 64.7164398 55.2048016 67.9300249 48.7776314 67.9300249'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='64.845557 76.0357195 66.4523496 82.4485434 37.5300834 82.4485434 39.136876 76.0357195'
          ></polygon>
          <polygon
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='43.9572537 45.434929 60.0251793 45.434929 60.0251793 48.6485141 43.9572537 48.6485141'
          ></polygon>
          <circle
            id='Oval'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            cx='53.1676182'
            cy='17.7894891'
            r='11.9361733'
          ></circle>
          <circle
            id='Oval'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            cx='53.1676182'
            cy='17.7894891'
            r='16.5557019'
          ></circle>
          <polyline
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
            points='61.6319719 32.006734 64.845557 35.2346655 69.4507393 30.6294832 70.5984483 29.4817743 67.3848631 26.2681891'
          ></polyline>
          <line
            x1='72.9082126'
            y1='34.0869565'
            x2='69.4507393'
            y2='30.6294832'
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></line>
          <line
            x1='69.5655102'
            y1='37.4153125'
            x2='66.1223833'
            y2='33.9721856'
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></line>
          <path
            d='M67.7865613,39.1942615 L79.0914947,50.4991948 C80.8187691,52.2264692 83.6192342,52.2264692 85.3465086,50.4991948 C87.073783,48.7719205 87.073783,45.9714553 85.3465086,44.2441809 L74.0272288,32.996633 L67.7865613,39.1942615 Z'
            id='Path'
            stroke='#1F2833'
            strokeWidth='1.43463622'
            strokeLinecap='round'
            strokeLinejoin='round'
          ></path>
        </g>
      </g>
      <rect
        id='container-home-icons-copy-4'
        stroke='#67FDF4'
        strokeWidth='3'
        x='-38'
        y='-41'
        width='314'
        height='319'
        rx='3'
      ></rect>
    </g>
  </svg>
)

export const Dashboard = () => (
  <svg width='100px' height='100px' viewBox='0 0 100 100'>
    <g id='icons/onboard/dashboard' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
      <g id='Crea-Tablero' transform='translate(1.000000, 5.000000)'>
        <path
          d='M75.619675,13.6864295 L2.74015517,13.6864295 C1.12754638,13.8088957 -0.0905844742,15.199223 1.98978854e-13,16.8139365 L1.98978854e-13,69.8093983 C-0.0900512818,71.4345194 1.14479345,72.8293182 2.7688479,72.9369053 L75.6483677,72.9369053 C77.2724222,72.8293182 78.5072669,71.4345194 78.4172156,69.8093983 L78.4172156,16.8139365 C78.5068786,15.1782899 77.2551632,13.7789362 75.619675,13.6864295 Z'
          id='Path'
          fill='#CCF1F1'
          fillRule='nonzero'
        ></path>
        <path
          d='M51.9051383,66.8540477 L9.46859903,66.8540477 C7.7888643,66.8540477 6.42717025,65.4923537 6.42717025,63.8126189 L6.42717025,12.2948324 C6.42717025,10.6150976 7.7888643,9.2534036 9.46859903,9.2534036 L79.0628019,9.2534036'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <line
          x1='88.2301274'
          y1='18.420729'
          x2='88.2301274'
          y2='48.3042014'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <rect
          id='Rectangle'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          x='12.0079051'
          y='48.7202459'
          width='6.80017567'
          height='12.4526424'
          rx='1.11901625'
        ></rect>
        <rect
          id='Rectangle'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          x='24.1736203'
          y='51.6612502'
          width='6.80017567'
          height='9.51163812'
          rx='0.975552628'
        ></rect>
        <rect
          id='Rectangle'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          x='36.3393354'
          y='39.3377251'
          width='6.80017567'
          height='21.8351632'
          rx='1.4776753'
        ></rect>
        <path
          d='M52.565071,61.1728883 L48.7776314,61.1728883 C48.0883063,61.1728883 47.5294979,60.6140799 47.5294979,59.9247548 L47.5294979,46.8982579 C47.5294979,46.2089328 48.0883063,45.6501244 48.7776314,45.6501244 L53.08154,45.6501244 C53.7708651,45.6501244 54.3296735,46.2089328 54.3296735,46.8982579 L54.3296735,56.5963988'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='88.2301274'
          cy='9.2534036'
          r='9.16732543'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='74.7158542'
          cy='66.8827404'
          r='22.8681013'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='74.7158542'
          cy='66.8827404'
          r='15.3506075'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='74.7158542'
          cy='66.8827404'
          r='9.15297907'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='68.6329966'
          cy='27.3298199'
          r='3.48616601'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='59.5087103'
          cy='18.2055336'
          r='3.48616601'
        ></circle>
        <circle
          id='Oval'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
          cx='45.8222808'
          cy='31.8919631'
          r='3.48616601'
        ></circle>
        <line
          x1='48.3185478'
          y1='29.4530815'
          x2='57.0698287'
          y2='20.7018006'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='61.9906309'
          y1='20.6587615'
          x2='66.2228078'
          y2='24.7905138'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='37.2288098'
          y1='15.1641048'
          x2='17.8325282'
          y2='15.1641048'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='13.3277705'
          y1='15.1641048'
          x2='11.448397'
          y2='15.1641048'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='13.3277705'
          y1='21.2469624'
          x2='11.448397'
          y2='21.2469624'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='13.3277705'
          y1='27.3298199'
          x2='11.448397'
          y2='27.3298199'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='13.3277705'
          y1='33.4126775'
          x2='11.448397'
          y2='33.4126775'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='37.2288098'
          y1='21.2469624'
          x2='17.8325282'
          y2='21.2469624'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='37.2288098'
          y1='27.3298199'
          x2='17.8325282'
          y2='27.3298199'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='37.2288098'
          y1='33.4126775'
          x2='17.8325282'
          y2='33.4126775'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='74.7158542'
          y1='51.5177866'
          x2='74.7158542'
          y2='44.0146391'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='87.8714683'
          y1='74.4576197'
          x2='94.513834'
          y2='78.3311375'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='54.8604889'
          y1='78.2450593'
          x2='61.5602401'
          y2='74.4576197'
          id='Path'
          stroke='#1F2833'
          strokeWidth='1.43463622'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
      </g>
    </g>
  </svg>
)

export const Widget = () => {
  const cls2 = {
    fill: 'none',
    stroke: '#1f2833',
    strokeLinecap: 'round',
    strokeLinejoin: 'round'
  }

  return (
    <svg width='100px' height='100px' xmlns='http://www.w3.org/2000/svg' viewBox='0 0 100 100'>
      <g id='Icono_Line_' data-name='Icono (Line)'>
        <path
          fill='#ccf1f1'
          d='M47.54,24.72H25.23c-.47,0-.85.57-.85,1.27V47.4c0,.7.38,1.27.85,1.27H47.54c.47,0,.85-.57.85-1.27V26C48.39,25.29,48,24.72,47.54,24.72Z'
        />
        <path
          fill='#ccf1f1'
          d='M77.5,24.72H55.19c-.47,0-.85.57-.85,1.27V47.4c0,.7.38,1.27.85,1.27H77.5c.47,0,.85-.57.85-1.27V26C78.35,25.29,78,24.72,77.5,24.72Z'
        />
        <path
          fill='#ccf1f1'
          d='M47.54,55.35H25.23c-.47,0-.85.57-.85,1.26V78c0,.7.38,1.26.85,1.26H47.54c.47,0,.85-.56.85-1.26V56.61C48.39,55.92,48,55.35,47.54,55.35Z'
        />
        <rect style={cls2} x='21.65' y='20.71' width='23.74' height='24.18' rx='1.99' ry='1.99' />
        <rect style={cls2} x='21.65' y='50.33' width='23.74' height='24.18' rx='1.99' ry='1.99' />
        <rect style={cls2} x='50.82' y='20.71' width='23.74' height='24.18' rx='1.99' ry='1.99' />
        <line style={cls2} x1='62.69' y1='49.62' x2='62.69' y2='74.51' />
        <line style={cls2} x1='51.34' y1='62.07' x2='75.08' y2='62.07' />
      </g>
    </svg>
  )
}

export const Report = () => {
  const cls2 = {
    fill: 'none',
    stroke: '#1f2833',
    strokeMiterlimit: 10
  }

  return (
    <svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 45.92 61.4'>
      <g id='Capa_2' data-name='Capa 2'>
        <g id='Icono_Line_' data-name='Icono (Line)'>
          <path
            style={{
              fill: '#ccf1f1'
            }}
            d='M39.7,8.16H1.45C.65,8.16,0,9.42,0,11V58.58c0,1.56.65,2.82,1.45,2.82H39.7c.8,0,1.46-1.26,1.46-2.82V11C41.16,9.42,40.5,8.16,39.7,8.16Z'
          />
          <path
            style={cls2}
            d='M34.17,4.52h8.74A2.51,2.51,0,0,1,45.42,7V54.68a2.51,2.51,0,0,1-2.51,2.51H7.42a2.51,2.51,0,0,1-2.51-2.51V7A2.51,2.51,0,0,1,7.42,4.52h8.74'
          />
          <path
            style={cls2}
            d='M34.07,8.84h6.29a1,1,0,0,1,1,1V52.39a1,1,0,0,1-1,1H9.86a1,1,0,0,1-1-1V9.8a1,1,0,0,1,1-1h6.36'
          />
          <path
            style={cls2}
            d='M34.17,4.29v4A1.57,1.57,0,0,1,32.6,9.86H17.73a1.57,1.57,0,0,1-1.58-1.57v-4a1.57,1.57,0,0,1,1.58-1.57h3.81A3.74,3.74,0,0,1,25.16.5a3.74,3.74,0,0,1,3.63,2.22H32.6A1.57,1.57,0,0,1,34.17,4.29Z'
          />
          <rect style={cls2} x='18.18' y='17.67' width='18.91' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='18.18' y='22.81' width='18.91' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='18.18' y='27.95' width='18.91' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='18.18' y='32.79' width='5.84' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='18.18' y='37.78' width='4.79' height='2.16' rx='0.98' ry='0.98' />
          <rect style={cls2} x='18.18' y='42.98' width='5.84' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='17.67' width='3' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='22.81' width='3' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='27.8' width='3' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='32.79' width='3' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='37.78' width='3' height='2.16' rx='1.08' ry='1.08' />
          <rect style={cls2} x='12.96' y='42.77' width='3' height='2.16' rx='1.08' ry='1.08' />
          <circle style={cls2} cx='30.93' cy='38.86' r='4.99' />
          <polyline style={cls2} points='30.93 33.87 30.93 38.86 34.03 42.77' />
          <line style={cls2} x1='25.94' y1='38.86' x2='30.93' y2='38.86' />
        </g>
      </g>
    </svg>
  )
}
