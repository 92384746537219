import React from 'react'
import { OnboardingContainer } from '../../../style/components/search/searches/OnboardingModal.styled'

import CloseIcon from '../../../assets/CloseIcon'
import { Search, Dashboard, Widget } from '../../../assets/Flows'
import Button from '../../ui/Button'
// import { Button as div } from '../../../style/components'

const Onboarding = ({ onboardingChange, navigation }) => {
  const redirect = () => {
    navigation.push('/searches/new-search')
  }

  return (
    <OnboardingContainer>
      <div className='onboarding__close' onClick={onboardingChange}>
        <CloseIcon width='25.33px' height='24px' fill='#748596' />
      </div>
      <div className='onboarding__header'>
        <h3>¿Cómo funciona?</h3>
        <p>
          Considera los siguientes pasos para comenzar con tus primeras búsquedas y análisis. ¡Pronto descubrirás lo
          fácil que es navegar en MyOblek!
        </p>
      </div>
      <div className='onboarding__description'>
        <div className='onboarding__description__card'>
          <Search />
          <h6>
            <span>1</span> Realiza una búsqueda
          </h6>
          <p>
            Tus búsquedas son aquellas palabras clave que te ayudarán a encontrar información valiosa para ti.
            <br></br>
            <br></br>
            ¡Crea tu búsqueda tan detallada como lo necesites! No tienes límite de palabras y podrás consultar prensa,
            radio, TV, revistas, portales web y redes sociales.
          </p>
        </div>
        <div className='onboarding__description__card'>
          <Dashboard />
          <h6>
            <span>2</span> Crea un tablero
          </h6>
          <p>
            Tus tableros serán tu principal zona de trabajo. En ellos verás el poder de nuestros gráficos vinculados a
            una o varias de tus búsquedas.
            <br></br>
            <br></br>
            Crea cuantos tableros necesites, invita colaboradores y organiza eficientemente tus análisis.
          </p>
        </div>
        <div className='onboarding__description__card'>
          <Widget />
          <h6>
            <span>3</span> Añade gráficos
          </h6>
          <p>
            Los gráficos son herramientras que te permitirán visualizar los datos de tus búsquedas de manera gráfica.
            <br></br>
            <br></br>
            Revisa nuestra amplia variedas de gráficos especializados en medios y redes sociales.
          </p>
        </div>
      </div>
      <div className='onboarding__confirm'>
        <Button onClick={redirect} height={'60px'} width={'512px'}>
          Entiendo ¡Comenzar una búsqueda!
        </Button>
      </div>
    </OnboardingContainer>
  )
}

export default Onboarding
