import React from 'react'
import { useQuery } from '@apollo/client'

import { DashboardContainer, WidgetsContainer } from '../../style/pages/Dashboard/OpenDashboard.styled'
import { MainContainer, Topbar, Toolbar, Spinner } from '../../style/components'
import { FiSettings } from 'react-icons/fi'
import { MdWidgets } from 'react-icons/md'

import OpenWidget from '../../components/dashboard/dashboard/OpenWidget'
import NoWidgets from '../../components/dashboard/dashboard/NoWidgets'

import { GET_DASHBOARD, DASHBOARD_WIDGETS_QUERY } from '../../services/queries'

const Dashboard = props => {
  const { data: dashboardData, loading: dashboardLoading } = useQuery(GET_DASHBOARD, {
    variables: { id: process.env.REACT_APP_DASHBOARD_ID }
  })

  const { data: dashboardWidgetsData, loading: dashboardWidgetsLoading } = useQuery(DASHBOARD_WIDGETS_QUERY, {
    variables: {
      dashboardID: process.env.REACT_APP_DASHBOARD_ID
    }
  })

  if (dashboardLoading || dashboardWidgetsLoading) return <Spinner />

  return (
    <MainContainer>
      <DashboardContainer>
        <Topbar className='topbar'>
          <div className='left-topbar'>
            <h1 className='topbar-title'>{dashboardData.getDashboard.name}</h1>
          </div>
          <div className='right-topbar'></div>
        </Topbar>

        <Toolbar className='toolbar'>
          <button className='toolbar-button'>
            <MdWidgets className='toolbar-icon gray-icon' />
            <p>Gráficos</p>
          </button>
          <div className='dashboard-actions'>
            <button className='toolbar-button dashboard-actions'>
              <FiSettings className='toolbar-icon' />
              <p>Herramientas</p>
            </button>
          </div>
        </Toolbar>

        {dashboardWidgetsData.dashboardWidgets.length > 0 ? (
          <WidgetsContainer>
            {dashboardWidgetsData.dashboardWidgets.map(widget => (
              <OpenWidget key={widget.id} widget={widget} dashboard={dashboardData.getDashboard.id} />
            ))}
          </WidgetsContainer>
        ) : (
          <NoWidgets nobutton={true} />
        )}
      </DashboardContainer>
    </MainContainer>
  )
}

export default Dashboard
