import React from 'react'

const ReportIcon = () => {
  return (
    <svg width='24px' height='24px' viewBox='0 0 24 24'>
      <g id='report' transform='translate(2.000000, 1.000000)' stroke='#FFFFFF' strokeWidth='1.22222222'>
        <line x1='4.4' y1='11' x2='15.4' y2='11' id='Path' strokeLinecap='round' strokeLinejoin='round'></line>
        <line
          x1='4.4'
          y1='16.5'
          x2='13.5666667'
          y2='16.5'
          id='Path'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <line
          x1='4.15555556'
          y1='13.8111111'
          x2='12.5888889'
          y2='13.8111111'
          id='Path'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></line>
        <path
          d='M19.4333333,4.4 L19.4333333,21.1444444 C19.4333333,21.6333333 19.0666667,22 18.5777778,22 L0.977777778,22 C0.488888889,22 0.122222222,21.6333333 0.122222222,21.1444444 L0.122222222,0.855555556 C0.122222222,0.366666667 0.488888889,0 0.977777778,0 L14.1777778,0'
          id='Path'
          strokeLinecap='round'
          strokeLinejoin='round'
        ></path>
        <path
          d='M19.4333333,4.4 L15.1555556,4.4 C14.6666667,4.4 14.3,4.03333333 14.3,3.54444444 L14.3,0'
          id='Path'
        ></path>
        <line x1='14.1777778' y1='0' x2='19.4333333' y2='4.4' id='Path'></line>
      </g>
    </svg>
  )
}

export default ReportIcon
