import React from 'react'
import { IndicatorsContainer } from '../../style/components/auth/Indicators/indicators.syled'
import {
  UserIconActive,
  PlanIconInactive,
  PlanIconActive,
  BillIconInactive,
  BillIconActive,
  PaymentIconInactive,
  PaymentIconActive,
  InvoiceIconInactive,
  InvoiceIconActive
} from '../../assets/SignupIcons'

const Indicators = ({ step, setStep, productSelected }) => {
  return (
    <IndicatorsContainer>
      <UserIconActive />
      <p className='title-active'>Datos</p>
      <div className={step >= 2 ? 'line-purple' : 'line'} />
      {step >= 2 ? <PlanIconActive /> : <PlanIconInactive />}
      <p className={step >= 2 ? 'title-active' : 'title-inactive'}>Planes</p>
      <div className={step >= 3 && productSelected.name !== 'Gratuito' ? 'line-purple' : 'line'} />
      {step >= 3 && productSelected.name !== 'Gratuito' ? <BillIconActive /> : <BillIconInactive />}
      <p className={step >= 3 && productSelected.name !== 'Gratuito' ? 'title-active' : 'title-inactive'}>Factura</p>
      <div className={step >= 4 && productSelected.name !== 'Gratuito' ? 'line-purple' : 'line'} />
      {step >= 4 && productSelected.name !== 'Gratuito' ? <PaymentIconActive /> : <PaymentIconInactive />}
      <p className={step >= 4 && productSelected.name !== 'Gratuito' ? 'title-active' : 'title-inactive'}>Pagos</p>
      <div className={step >= 5 && productSelected.name !== 'Gratuito' ? 'line-purple' : 'line'} />
      {step >= 5 ? <InvoiceIconActive /> : <InvoiceIconInactive />}
      <p className={step >= 5 ? 'title-active' : 'title-inactive'}>Recibo</p>
    </IndicatorsContainer>
  )
}

export default Indicators
