import styled from 'styled-components'
import { colors } from '../../../variables'

const CloseModalContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  margin-bottom: 4px;

  svg {
    width: 24px;
    height: 24px;
    cursor: pointer;

    path {
      stroke: ${colors.darkGray};
    }
  }
`

export default CloseModalContainer
