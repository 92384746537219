import React, { useState } from 'react'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { PasswordContainer, PasswordForm } from '../../style/pages/authMobile/Password.styled'
import { colors } from '../../style/variables'

import { CHANGE_USER_PASSWORD } from '../../services/mutations'

// const LoginBackground = styled.img`
//   position: absolute;
//   transform: translate(265px, 10px);
// `

const Password = props => {
  const [recoverPassword] = useMutation(CHANGE_USER_PASSWORD)

  const formik = useFormik({
    initialValues: {
      password: '',
      confirmation: ''
    },
    validationSchema: Yup.object({
      password: Yup.string()
        .required('La contraseña es obligatoria')
        .min(6, 'La contraseña debe ser de al menos 6 caracteres'),
      confirmation: Yup.string()
        .required('Debes confirmar tu contraseña')
        .oneOf([Yup.ref('password'), null], 'Las contraseñas no coinciden')
    }),
    onSubmit: async values => {
      const code = props.location.pathname.replace('/recover/', '')
      const { password } = values

      try {
        const { data } = await recoverPassword({
          variables: {
            input: {
              code,
              password
            }
          }
        })

        setMessage(data.recoverPassword)
        setTimeout(() => {
          setMessage(null)
          props.history.push('/login')
        }, 3000)
      } catch (error) {
        setMessage(error.message)
        setTimeout(() => {
          setMessage(null)
        }, 3000)
      }
    }
  })

  const [message, setMessage] = useState(null)

  return (
    <PasswordContainer>
      <PasswordForm onSubmit={formik.handleSubmit}>
        <h2>Nueva contraseña</h2>
        <p>Para cambiar tu contraseña, escribe la nueva contraseña debajo.</p>

        <label htmlFor='password'>Contraseña</label>
        <input
          id='password'
          type='password'
          placeholder='Escribe tu nueva contraseña'
          value={formik.values.password}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className='form-error'>
          {formik.touched.password && formik.errors.password ? <p>{formik.errors.password}</p> : null}
        </div>

        <label htmlFor='confirmation'>Confirmación</label>
        <input
          id='confirmation'
          type='password'
          placeholder='Verifica tu nueva contraseña'
          value={formik.values.confirmation}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        <div className='form-error'>
          {formik.touched.confirmation && formik.errors.confirmation ? <p>{formik.errors.confirmation}</p> : null}
        </div>

        <div className='form-error relative'>{message && <p>{message}</p>}</div>

        <input
          className='submit-button'
          type='submit'
          value='Enviar'
          disabled={
            formik.values.password &&
            formik.values.confirmation &&
            !formik.errors.password &&
            !formik.errors.confirmation
              ? false
              : true
          }
          style={
            formik.values.password &&
            formik.values.confirmation &&
            !formik.errors.password &&
            !formik.errors.confirmation
              ? { background: `${colors.richBlack}`, color: '#FFFFFF' }
              : { background: '#E7E8EA' }
          }
        />
      </PasswordForm>
    </PasswordContainer>
  )
}

export default Password
