import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const AllMediaContainer = styled.div`
  padding-left: ${props => (props.filtersOpen || props.ordersOpen ? '400px' : '0px')};
  padding-top: 30px;
  .searches-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px 0px 7.5px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      text-align: center;
      &.name {
        width: 20%;
      }
      &.scope {
        width: 17.5%;
      }
      &.place {
        width: 17.5%;
      }
      &.type {
        width: 17.5%;
      }
      &.subtype {
        width: 17.5%;
      }
      &.status {
        width: 10%;
      }
    }
  }
  .searches-table-body {
    height: calc(100vh - 190px);
    overflow-y: scroll;
    padding: 7.5px 20px 7.5px 7.5px;
    .searches-table-row {
      display: flex;
      flex-direction: column;
      transition: ease-in-out 0.2s;
      transform: translate(0, 0px);
      &:hover {
        transform: translate(0, -3px);
      }
      .searchCont {
        display: flex;
        height: 50px;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        transition: ease-in-out 0.3s;
        &:hover {
          background: ${colors.lightGray};
        }
        p {
          display: flex;
          justify-content: center;
          align-items: center;
          color: ${colors.darkGray};
          font-size: 14px;
          font-weight: 500;
          &.name {
            justify-content: flex-start;
            padding-left: 2%;
            width: 20%;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
          }
          &.scope {
            width: 17.5%;
          }
          &.place {
            width: 17.5%;
          }
          &.type {
            width: 17.5%;
          }
          &.subtype {
            width: 17.5%;
          }
          &.status {
            width: 10%;
            span {
              border-radius: 50%;
              min-width: 10px;
              min-height: 10px;
            }
            .activo {
              background: ${colors.lightseaGreen};
            }
            .inactivo {
              background: ${colors.red};
            }
          }
        }
      }
    }
  }
`
