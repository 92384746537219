import styled from 'styled-components'
import { colors } from '../../variables'

export const WidgetContainer = styled.div`
  margin-top: 40px;
  padding-top: 40px;
  height: calc(100vh - 110px);
  overflow-y: scroll;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        .back-icon {
          width: 20px;
          height: 20px;
        }
      }
      h1 {
        width: 300px;
        font-size: 22px;
        font-weight: 600;
      }
      button {
        cursor: pointer;
      }
    }
  }
  .toolbar {
    justify-content: flex-end;
    .toolbar-button {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 130px;
      height: 40px;
      margin-left: 10px;
      border: none;
      outline: none;
      background: none;
      cursor: pointer;
      .toolbar-icon {
        width: 20px;
        height: 20px;
        margin-right: 10px;
        stroke: ${colors.darkGray};
        &.gray-icon {
          fill: ${colors.darkGray};
        }
      }
      p {
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
      }
    }
  }
  .widget-canvas {
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    padding: 50px;
    height: 500px;
  }
  .options-menu {
    z-index: 5;
    position: absolute;
    top: 40px;
    right: 350px;
    width: 70px;
    height: 150px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 0 0 4px 4px;
    button {
      border: none;
      background: none;
      outline: none;
    }
  }
  .full_option {
    height: 50px;
    width: 100%;
    display: flex;
    align-items: center;
    font-size: 14px;
    font-weight: 500;
    color: ${colors.darkGray};
    padding-left: 20px;
    border-radius: 0 0 4px 4px;
    :hover {
      background: ${colors.lightGray};
    }
    svg {
      width: 20px;
      height: 20px;
      margin-right: 10px;
    }
  }
`

export const SearchesContainer = styled.div`
  padding-left: ${props => (props.filtersOpen ? '400px' : '0px')};
  padding-top: 30px;
  .searches-head {
    display: flex;
    margin-bottom: 30px;
    button {
      background: none;
      border: none;
      outline: none;
      margin-left: 20px;
      color: ${colors.lightseaGreen};
      font-weight: 500;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .searches-table-header {
    display: flex;
    align-items: center;
    height: 50px;
    padding: 0 20px 0px 7.5px;
    h3 {
      font-size: 16px;
      font-weight: 600;
      padding-left: 2%;
      &.number {
        width: 7%;
      }
      &.name {
        width: 28%;
      }
      &.source {
        width: 20%;
      }
      &.date {
        width: 13%;
      }
      &.creator {
        width: 17%;
      }
      &.options {
        width: 15%;
      }
    }
  }
  .searches-table-body {
    overflow-y: scroll;
    padding: 7.5px 20px 7.5px 7.5px;
    .searches-table-row {
      display: flex;
      flex-direction: column;
      cursor: pointer;
      transition: ease-in-out 0.2s;
      transform: translate(0, 0px);
      &:hover {
        transform: translate(0, -3px);
      }
      .options {
        display: flex;
        justify-content: center;
        align-items: center;
        position: absolute;
        top: 16%;
        right: 80px;
        button {
          border: none;
          outline: none;
          background: none;
          padding: 3px;
          border-radius: 3px;
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;
          transition: ease-in-out 0.3s;
          &:hover {
            background: ${colors.red};
            svg {
              fill: ${colors.white} !important;
            }
          }
          &.open {
            color: ${colors.lightseaGreen};
            font-size: 14px;
            font-weight: 500;
          }
          &.copy {
            svg {
              width: 20px;
              height: 20px;
              fill: ${colors.darkGray};
            }
          }
          &.delete {
            svg {
              width: 20px;
              height: 20px;
              fill: ${colors.darkGray};
            }
          }
        }
      }
      .searchCont {
        display: flex;
        height: 50px;
        width: 100%;
        border-radius: 4px;
        margin-bottom: 20px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        transition: ease-in-out 0.3s;
        &:hover {
          background: ${colors.lightGray};
        }
        p {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          padding-left: 2%;
          color: ${colors.darkGray};
          font-size: 14px;
          font-weight: 500;
          &.number {
            width: 7%;
          }
          &.name {
            width: 28%;
          }
          &.source {
            width: 20%;
            text-transform: capitalize;
          }
          &.date {
            width: 13%;
          }
        }
        .creator {
          width: 17%;
          display: flex;
          align-items: center;
          .creator-photo {
            width: 30px;
            height: 30px;
            margin-right: 10px;
            background: ${colors.lightGray};
            border-radius: 50%;
          }
        }
      }
    }
  }
`
