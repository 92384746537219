import React from 'react'
import ModalContainer from '../../../style/components/workspace/Modals/ChangeModal.styled'
import { Spinner } from '../../../style/components'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import moment from 'moment'
import Button from '../../ui/Button'
moment.locale('es')

const CreateSuscriptionModal = ({
  loading,
  setShowCreateSuscriptionModal,
  children,
  desiredPlanPrice,
  handleAddPaymenthMethod,
  paymentMethods,
  handleSubmit,
  handlePaymenthSelector
}) => {
  if (loading)
    return (
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    )

  return (
    <ModalContainer>
      <div className='modal__close'>
        <div className='close__icon'>
          <AiOutlineClose size={25} onClick={() => setShowCreateSuscriptionModal(false)} />
        </div>
      </div>
      <h5 className='changeplan__title'>Cambio de plan</h5>
      {children}
      <div className='changemodal__description'>
        <div className='changemodal__price__details'>
          <div className='changemodal__price__detail'>
            <p className='price__text'>Costo del nuevo plan:</p>
            <p className='price__number'>${desiredPlanPrice}</p>
          </div>
          <div className='changemodal__price__detail price__detail__total'>
            <p className='price__text'>Total:</p>
            <p className='price__number'>${desiredPlanPrice} MXN</p>
          </div>
        </div>
      </div>
      <div className='account__payment__method payment__method__border'>
        <p className='payment__method__text'>Método de pago</p>
        {paymentMethods.getPaymentMethods
          .filter(e => e.default === true)
          .map((pm, i) => (
            <div key={i}>
              {pm.brand === 'visa' ? (
                <p className='payment__method__card'>
                  <FaCcVisa /> Visa ················· {pm.last4}
                </p>
              ) : (
                <p className='payment__method__card'>
                  <FaCcMastercard /> Mastercard ················· {pm.last4}
                </p>
              )}
            </div>
          ))}
      </div>
      <div className='account__changepayment'>
        <p
          className='changepayment__text'
          onClick={paymentMethods.getPaymentMethods.length ? handlePaymenthSelector : handleAddPaymenthMethod}
        >
          {paymentMethods.getPaymentMethods.length ? 'Cambiar método de Pago' : 'Añadir método de Pago'}
        </p>
      </div>

      <div className='changepayment__buttons'>
        <div>
          <Button
            theme={'secondary'}
            variant={'outline'}
            width={'250px'}
            onClick={() => setShowCreateSuscriptionModal(false)}
          >
            Continuar con el plan actual
          </Button>
        </div>
        <div>
          <Button
            theme={'primary'}
            width={'250px'}
            onClick={handleSubmit}
            disabled={!paymentMethods.getPaymentMethods.length}
          >
            Cambiar plan
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default CreateSuscriptionModal
