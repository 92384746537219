import React from 'react'
import ModalContainer from '../../../style/components/workspace/Modals/ChangeModal.styled'
import { Spinner } from '../../../style/components'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import { AiOutlineClose } from 'react-icons/ai'
import moment from 'moment'
import Button from '../../ui/Button'
moment.locale('es')

const ChangeModal = ({
  loading,
  anualToMonthFlag,
  setShowChangeModal,
  futureInvoice,
  nextCharge,
  billingCycle,
  children,
  desiredPlan,
  desiredPricePlan,
  handlePaymenthSelector,
  paymentMethods,
  handleUpdatePlan,
  handlePaymenthDetails
}) => {
  const moveDecimal = n => {
    const v = Math.abs(n) / Math.pow(10, 2)
    return v.toFixed(2)
  }

  const formatNumber = n => {
    const number = new Intl.NumberFormat({ minimumFractionDigits: 2 }).format(n)
    return number
  }

  // const daysOfUse = () => {
  //     const a = moment(futureInvoice.next_payment_attempt * 1000)
  //     const b = moment()
  //     return a.diff(b, 'days')
  // }

  if (loading)
    return (
      <ModalContainer>
        <Spinner />
      </ModalContainer>
    )

  return (
    <ModalContainer>
      <div className='modal__close'>
        <div className='close__icon'>
          <AiOutlineClose size={25} onClick={() => setShowChangeModal(false)} />
        </div>
      </div>
      <h5 className='changeplan__title'>Cambio de plan</h5>
      {children}
      {anualToMonthFlag ? (
        <>
          <div className='changemodal__description'>
            <div className='changemodal__price__details'>
              <div className='changemodal__price__detail price__detail__last'>
                <p className='price__text'>Costo del nuevo plan:</p>
                <p className='price__number'>${`${desiredPricePlan}.00`}</p>
              </div>
              <div className='changemodal__price__detail price__detail__total'>
                <p className='price__text'>Total:</p>
                <p className='price__number'>${`${desiredPricePlan}.00`} MXN</p>
              </div>
            </div>
          </div>
          {paymentMethods.getPaymentMethods
            .filter(e => e.default === true)
            .map((pm, i) => (
              <div className='account__payment__method payment__method__border' key={i}>
                <p className='payment__method__text'>Método de pago</p>
                {pm.brand === 'visa' ? (
                  <p className='payment__method__card'>
                    <FaCcVisa /> Visa ················· {pm.last4}
                  </p>
                ) : (
                  <p className='payment__method__card'>
                    <FaCcMastercard /> Mastercard ················· {pm.last4}
                  </p>
                )}
              </div>
            ))}
          <div className='account__changepayment'>
            <p className='changepayment__text' onClick={() => handlePaymenthSelector()}>
              Cambiar método de pago
            </p>
          </div>
        </>
      ) : (
        <>
          <div className='changemodal__description'>
            <div className='changemodal__price__details'>
              <div className='changemodal__price__detail'>
                <p className='price__text'>Costo del nuevo plan:</p>
                <p className='price__number'>${formatNumber(moveDecimal(futureInvoice.costForTimeSpend.amount))}</p>
              </div>
              <div className='changemodal__price__detail price__detail__last'>
                <p className='price__text'>Saldo a favor:</p>
                <p className='price__number'>${formatNumber(moveDecimal(futureInvoice.unusedTimeCredit.amount))}</p>
              </div>
              <div className='changemodal__price__detail price__detail__total'>
                <p className='price__text'>Total:</p>
                <p className='price__number'>${formatNumber(moveDecimal(futureInvoice.subAndTotal.total))} MXN</p>
              </div>
            </div>
            <div className='plan__next__payment'>
              <p className='next__payment__text'>
                Próximo pago programado: {moment(futureInvoice.next_payment_attempt * 1000).format('DD MMM YYYY')}
              </p>
            </div>
          </div>
          {paymentMethods.getPaymentMethods
            .filter(e => e.default === true)
            .map((pm, i) => (
              <div className='account__payment__method payment__method__border' key={i}>
                <p className='payment__method__text'>Método de pago</p>
                {pm.brand === 'visa' ? (
                  <p className='payment__method__card'>
                    <FaCcVisa /> Visa ················· {pm.last4}
                  </p>
                ) : (
                  <p className='payment__method__card'>
                    <FaCcMastercard /> Mastercard ················· {pm.last4}
                  </p>
                )}
              </div>
            ))}
          <div className='account__changepayment'>
            <p className='changepayment__text' onClick={() => handlePaymenthSelector()}>
              Cambiar método de pago
            </p>
          </div>
        </>
      )}
      <div className='changepayment__buttons'>
        <div>
          <Button
            theme={'secondary'}
            variant={'outline'}
            className='continue__actualplan__button'
            onClick={() => setShowChangeModal(false)}
          >
            Continuar con el plan actual
          </Button>
        </div>
        <div>
          <Button className='change__actualplan__button' onClick={() => handleUpdatePlan()}>
            Cambiar plan
          </Button>
        </div>
      </div>
    </ModalContainer>
  )
}

export default ChangeModal
