import React, { useState, useContext, useEffect } from 'react'
import { useMutation, useQuery } from '@apollo/client'
import { NewDashboardContainer, Member } from '../../style/pages/Dashboard/NewDashboard.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Shadow } from '../../style/components'
import { AiOutlineUser } from 'react-icons/ai'
import { IoIosArrowBack } from 'react-icons/io'

import DashboardWarning from '../../components/dashboard/new-dashboard/DashboardWarning'
import UserDropdown from '../../components/ui/UserDropdown'
import PermitDropdown from '../../components/ui/PermitDropdown'

import { USER_QUERY, DASHBOARDS_QUERY, GET_ELEGIBLE_WORKSPACE_USERS } from '../../services/queries'

import { NEW_DASHBOARD } from '../../services/mutations'
import { MyContext } from '../../context'
import Button from '../../components/ui/Button'

const NewDashboard = props => {
  const roles = [
    {
      id: '1',
      name: 'Editor',
      description: 'Puede editar todas las características del tablero'
    },
    {
      id: '2',
      name: 'Vista',
      description: 'Acceso limitado. Sólo puede ver el tablero'
    }
  ]

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const context = useContext(MyContext)

  const { data: usersData, loading: usersLoading } = useQuery(GET_ELEGIBLE_WORKSPACE_USERS)
  const { data: loggedUser, loading: loadingUser } = useQuery(USER_QUERY)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [limitWarning, setLimitWarning] = useState(null)
  const [showAddMembers, setShowAddMembers] = useState(false)
  const [userSelection, setUserSelection] = useState([])
  const [roleSelection, setRoleSelection] = useState([roles[0]])
  const [owners, setOwners] = useState([])
  const [users, setUsers] = useState([])
  const [edit, setEdit] = useState([])
  const [view, setView] = useState([])

  useEffect(() => {
    !usersLoading && setUsers(usersData.getElegibleWorkspaceUsers)
    !loadingUser && setOwners([{ ...loggedUser.getUser, permit: 'Propietario' }])
  }, [loadingUser, loggedUser, usersLoading, usersData])

  const [newDashboard, { error: errorNewDashboard }] = useMutation(NEW_DASHBOARD, {
    update(cache, { data: { newDashboard } }) {
      const result = cache.readQuery({ query: DASHBOARDS_QUERY })

      if (result) {
        cache.writeQuery({
          query: DASHBOARDS_QUERY,
          data: {
            dashboards: [...result.dashboards, newDashboard]
          }
        })
      }
    }
  })

  if (errorNewDashboard) context.handleErrors(errorNewDashboard)

  const handleInvite = () => {
    const { name, email, id, photo } = userSelection[0]
    //Push id to permit array
    roleSelection[0].name === 'Editor' ? setEdit(prev => [...prev, id]) : setView(prev => [...prev, id])
    //Delete user from elegible users array
    if (users.length === 1) {
      //If array length 1 splicing it causes error
      setUsers([])
    } else {
      //If more than one id in array, delete only userSelection id from array
      users.map((user, index) => {
        if (user.id === userSelection[0].id) {
          const userArray = [...users]
          userArray.splice(index, 1)
          setUsers(userArray)
        }
        return user
      })
    }
    //Create obj to store in owners, to then show in screen
    const obj = {
      name,
      email,
      id,
      photo,
      permit: roleSelection[0].name
    }
    setOwners(prev => [...prev, obj])
    //Remove users selection in UserDropdown
    setUserSelection([])
  }

  const changePermit = (owner, ind) => {
    //Brings ind of owners array and the owner to get id
    const editedOwners = owners.map((owner, index) => {
      if (index === ind) {
        if (owner.permit === 'Editor') {
          //If Editor then this will change to View, delete id from edit array and add id to view array
          edit.map((userId, index) => {
            if (userId === owner.id) {
              edit.splice(index, 1)
              setEdit(edit)
              setView(prev => [...prev, userId])
            }
            return userId
          })
        } else {
          //If Vista then this will change to Edit, delete id from view array and add id to edit array
          view.map((userId, index) => {
            if (userId === owner.id) {
              view.splice(index, 1)
              setView(view)
              setEdit(prev => [...prev, userId])
            }
            return userId
          })
        }
        return {
          ...owner,
          permit: `${owner.permit === 'Editor' ? 'Vista' : 'Editor'}`
        }
      }
      return owner
    })
    setOwners(editedOwners)
  }

  const handleDeleteInvite = (owner, ind) => {
    //Delete owner from owners array and add it to users array, delete also from edit or view array depending on permit value
    owners.map((user, index) => {
      if (index === ind) {
        //delete user from owners array
        owners.splice(index, 1)
        setOwners(owners)
        if (user.permit === 'Editor') {
          edit.map((id, spliceInd) => {
            if (id === owner.id) {
              //delete only that user from edit arr
              edit.splice(spliceInd, 1)
              setEdit(edit)
            }
            return id
          })
        } else {
          //delete user from view arr
          view.map((id, spliceInd) => {
            if (id === owner.id) {
              view.splice(spliceInd, 1)
              setView(view)
            }
            return id
          })
        }
        //Delete property permit from user and add it to users Arr
        delete user.permit
        setUsers(prev => [...prev, user])
      }
      return user
    })
  }

  const handleDashboardCreation = async () => {
    try {
      const { data } = await newDashboard({
        variables: {
          input: {
            name,
            description,
            members: {
              editors: edit,
              viewers: view
            }
          }
        }
      })

      props.history.push(`/dashboards/dashboard/${data.newDashboard.id}`)
    } catch (error) {
      if (error.message.includes('límite')) {
        const plan = error.message.split(' ')[0].toLowerCase()
        setLimitWarning({
          error: true,
          plan: plan.charAt(0).toUpperCase() + plan.slice(1),
          quantity: error.message.split(' ')[1]
        })
      }
    }
  }

  return (
    <MainContainer className={mainClass()}>
      <NewDashboardContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => props.history.push('/dashboards')}>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </button>
            <h1>Nuevo tablero</h1>
          </div>
        </Topbar>

        <div className='form'>
          {/* <h1 className='new-dashboard-title'>Crea un nuevo tablero</h1> */}
          <label>Nombre del tablero</label>
          <input placeholder='Nombre' onChange={e => setName(e.target.value)} />
          <label>Descripción del tablero</label>
          <textarea placeholder='Descripción' onChange={e => setDescription(e.target.value)} />

          <div className='members-header'>
            <h3>Miembros del tablero</h3>
            <p>
              <AiOutlineUser /> {owners.length === 1 ? `${owners.length} miembro` : `${owners.length} miembros`}
            </p>
          </div>

          <div className='members-section'>
            {owners.map((owner, idx) => (
              <Member key={idx}>
                <div className='member-left'>
                  <img src={owner.photo} alt='User avatar' />
                  <div className='member-info'>
                    <p className='member-name'>{owner.name}</p>
                    <p className='member-email'>{owner.email}</p>
                  </div>
                </div>
                <div className='permit-buttons'>
                  {owner.permit === 'Propietario' ? (
                    <p className='member-owner'>Propietario</p>
                  ) : (
                    <>
                      <button className='delete-permit-button' onClick={() => handleDeleteInvite(owner, idx)}>
                        Eliminar
                      </button>
                      <button
                        className={owner.permit === 'Editor' ? 'm-button-editor' : 'm-button-viewer'}
                        onClick={() => changePermit(owner, idx)}
                      >
                        {owner.permit}
                      </button>
                    </>
                  )}
                </div>
              </Member>
            ))}
          </div>

          <p className='add-member-btn' onClick={() => setShowAddMembers(!showAddMembers)}>
            Invitar miembro al tablero
          </p>
          {showAddMembers && (
            <div className='invite-section'>
              <div className='share-form'>
                <div className='left-input'>
                  <label>Usuario</label>
                  <UserDropdown
                    title={users.length === 0 ? 'No hay usuarios' : 'Selecciona un usuario'}
                    items={users}
                    selection={userSelection}
                    setSelection={setUserSelection}
                  />
                </div>
                <div className='right-input'>
                  <label>Rol</label>
                  <PermitDropdown
                    title='Selecciona un rol'
                    items={roles}
                    selection={roleSelection}
                    setSelection={setRoleSelection}
                  />
                </div>
              </div>
              <button
                className='invite-button'
                disabled={userSelection.length === 0 || roleSelection.length === 0 ? true : false}
                style={
                  userSelection.length === 0 || roleSelection.length === 0
                    ? { background: '#E7E8EA' }
                    : { background: '#00AAAB', color: '#FFFFFF' }
                }
                onClick={handleInvite}
              >
                Invitar
              </button>
            </div>
          )}

          <Button
            className='crear-tablero'
            width='100%'
            height='46px'
            theme='primary'
            variant='solid'
            disabled={name.length === 0}
            onClick={e => handleDashboardCreation(e)}
          >
            Crear tablero
          </Button>
        </div>

        {limitWarning && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <DashboardWarning info={limitWarning} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </NewDashboardContainer>
    </MainContainer>
  )
}

export default NewDashboard
