import React from 'react'
import { useHistory } from 'react-router-dom'

import UserWarningContainer from '../../style/components/workspace/UserWarning.styled'
import { colors } from '../../style/variables'
import Button from '../ui/Button'

const UserWarning = ({ info, closeModal }) => {
  const history = useHistory()

  return (
    <UserWarningContainer>
      <h3>Límite de usuarios</h3>
      <p>
        Has alcanzado el número máximo de <span>{info.quantity}</span> usuarios para tu plan <span>{info.plan}</span>.
      </p>
      <div className='modal-buttons'>
        <Button theme={'dark'} variant={'link'} weight={500} onClick={closeModal}>
          Continuar
        </Button>
        <Button weight={500} onClick={() => history.push('/workspace?plans')}>
          Subir de plan
        </Button>
      </div>
    </UserWarningContainer>
  )
}

export default UserWarning
