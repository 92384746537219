import React from 'react'
import { ShareReportContainer } from '../../style/components/reports/ShareReport.styled'
import UserDropdown from '../ui/UserDropdown'
import { useQuery } from '@apollo/client'
import { Spinner } from '../../style/components'

import { GET_REPORT_USERS } from '../../services/queries'

const ShareReport = ({ board, users, setUsers }) => {
  const { data, loading } = useQuery(GET_REPORT_USERS)

  if (loading) return <Spinner />

  return (
    <ShareReportContainer>
      <h2>Compartir el reporte</h2>
      <p className='select-p'>Selecciona usuarios de MyOblek</p>
      <UserDropdown
        title={data.getReportUsers.length === 0 ? 'No hay usuarios' : 'Selecciona un usuario'}
        items={data.getReportUsers}
        selection={users}
        setSelection={setUsers}
        multiSelect={true}
      />
    </ShareReportContainer>
  )
}

export default ShareReport
