import styled from 'styled-components'
import { colors } from '../../../variables'

export const TweetContainer = styled.article`
  min-width: 260px;
  width: 22.5%;
  padding: 15px;
  margin: 0 2.5%30px 0;
  border-radius: 4px;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.1);
  background-color: ${colors.white};
  .tweet-card-header {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin-bottom: 10px;
    .tweet-checkbox {
      .checkbox-indicator {
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
        margin-right: 5px;
        .checked {
          width: 23px;
          height: 23px;
          fill: ${colors.lightseaGreen};
        }
        .not-checked {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
          cursor: pointer;
        }
      }
    }
    .tc-header-info {
      display: flex;
      img {
        width: 40px;
        height: 40px;
        border-radius: 50%;
        margin-right: 5px;
      }
      .header-text {
        h5 {
          margin-bottom: 5px;
          font-size: 14px;
          font-weight: 500;
        }
        h6 {
          font-size: 12px;
          font-weight: 500;
        }
      }
    }
    .tc-header-icon {
      width: 25px;
      height: 25px;
      svg {
        width: 100%;
        height: 100%;
        stroke: ${colors.lightseaGreen};
        stroke-width: 1;
      }
    }
  }
  .tweet-text {
    height: 120px;
  }
  .tweet-content {
    border-top: 1px solid ${colors.lightGray};
    padding-top: 10px;
    margin-bottom: 15px;
    font-size: 14px;
    font-weight: 400;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 5;
    -webkit-box-orient: vertical;
  }
  a {
    font-size: 14px;
    font-weight: 600;
    color: ${colors.lightseaGreen};
  }
  .tweet-date-section {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
    .tweet-date,
    .tweet-time {
      display: flex;
      align-items: center;
      svg {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
        margin-right: 5px;
      }
      p {
        font-size: 12px;
        font-weight: 400;
      }
    }
  }
  @media (max-width: 1628px) {
    width: 30.5%;
  }
  @media (max-width: 1263px) {
    width: 47%;
  }
  @media (max-width: 917px) {
    width: 98%;
  }
`
