import React, { useEffect, useRef, useState } from 'react'
import { DropdownContainer } from '../../style/components/ui/PeriodDropdown.styled'
// eslint-disable-next-line
import onClickOutside from 'react-onclickoutside'
import { FiCheck } from 'react-icons/fi'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Dropdown = ({ width, title, items, selection, setSelection, multiSelect }) => {
  const [open, setOpen] = useState(false)

  const dropdown = useRef()

  useOutsideClick(dropdown, () => setOpen(false))

  const handleOnClick = item => {
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id)
      setSelection([...selectionAfterRemoval])
    }
  }

  const isItemInSelection = item => {
    return selection.find(current => current.id === item.id) ? true : false
  }

  return (
    <DropdownContainer width={width} ref={dropdown}>
      <div
        tabIndex={0}
        className='dd-header'
        role='button'
        onKeyPress={() => setOpen(!open)}
        onClick={() => setOpen(!open)}
      >
        <div className='dd-header-title'>
          {selection.length === 0 ? <p>{title}</p> : selection.map(item => <p key={item.id}>{item.name}</p>)}
        </div>
        <div className='dd-header-action'>
          <p>{open ? <AiFillCaretUp /> : <AiFillCaretDown />}</p>
        </div>
      </div>
      {open && (
        <ul className='dd-list'>
          {items.map(item => (
            <li key={item.id} className='dd-list-item'>
              <button onClick={() => handleOnClick(item)}>
                <p>{item.name}</p>
                <span className='dd-list-item-check'>{isItemInSelection(item) && <FiCheck />}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
