import React from 'react'
import { NoWidgetsContainer } from '../../../style/components/dashboard/dashboard/NoWidgets.syled'

import { Dashboard } from '../../../assets/Flows'
import Button from '../../ui/Button'

const NoWidgets = ({ setShowWidgetsMenu, nobutton }) => {
  return (
    <NoWidgetsContainer>
      <div className='no-searches--icon'>
        <Dashboard />
      </div>
      <p>{nobutton ? '¡No tienes gráficos ahora mismo!' : '¡Agrega gráficos a tu tablero ahora mismo!'}</p>
      {!nobutton && (
        <Button height='40px' width='560px' theme='primary' variant='solid' onClick={() => setShowWidgetsMenu(true)}>
          Agregar gráficos
        </Button>
      )}
    </NoWidgetsContainer>
  )
}

export default NoWidgets
