import styled from 'styled-components'
import { buttonColors, colors } from './variables'

export const MainContainer = styled.main`
  height: 100vh;
  padding: ${props => (props.padding ? props.padding : '70px 2.5% 0')};
  background: ${colors.background};
  transition: all 0.25s linear;
`
export const TopSearch = styled.div`
  display: flex;
  align-items: center;
  .search-icon {
    position: relative;
    left: 35px;
    width: 25px;
    height: 25px;
    fill: ${colors.darkGray};
  }
  .topbar-searchbar {
    width: 350px;
    height: 40px;
    padding: 0 10px 0 40px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 15px;
  }
`
export const Topbar = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 70px;
  padding: 0 2.5%;
  background: #ffffff;
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s linear;
`

export const Toolbar = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  display: flex;
  align-items: center;
  width: 100%;
  height: 40px;
  padding: 0 2.5%;
  border-top: 1px solid ${colors.lightGray};
  background: #ffffff;
  box-shadow: 0 4px 3px 0 rgba(0, 0, 0, 0.1);
  transition: all 0.25s linear;
  cursor: pointer;
  button {
  }
`

export const Tag = styled.span`
  display: inline-block;
  padding: 0 6px 0 10px;
  border-radius: 4px;
  margin: 0 5px 0px 0;
  background-color: ${colors.white};
  color: ${colors.richBlack};
  font-size: 15px;
  font-weight: 500;
  line-height: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  span {
    padding: 0px 3px;
    margin-left: 6px;
    vertical-align: 2px;
    display: flex;
    cursor: pointer;
  }
`

export const Button = styled.button`
  width: ${props => props.width};
  max-height: 50px;
  border: ${props => props.border};
  outline: none;
  border-radius: 4px;
  background: #e7e8ea;
  color: #b6bbbe;
  font-size: 15px;
  font-weight: 600;
`

export const StyledInput = styled.input`
  width: ${props => props.width};
  height: 30px;
  padding: 9px;
  border: 1px solid #aaacc5;
  border-radius: 4px;
  background: #ebeffa;
  :focus-within {
    border: 1px solid #00aaab;
  }
  margin-top: 0.5vh;
  margin-bottom: 0.5vh;
  input {
    width: 100px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    outline: none;
    caret-color: #00aaab;
    background: #ebeffa;
  }
`

export const Spinner = styled.div`
  position: absolute;
  top: 50%;
  right: 45%;
  height: 100px;
  width: 100px;
  border: 3px solid transparent;
  border-top-color: ${colors.purple};
  margin: -30px;
  border-radius: 50%;
  animation: spin 2s linear infinite;
  &:before,
  &:after {
    content: '';
    position: absolute;
    border: 3px solid transparent;
    border-radius: 50%;
  }
  &:before {
    border-top-color: ${colors.lightseaGreen};
    top: -14px;
    left: -14px;
    right: -14px;
    bottom: -14px;
    animation: spin 3s linear infinite;
  }
  &:after {
    border-top-color: ${colors.silverSand};
    top: 7px;
    left: 7px;
    right: 7px;
    bottom: 7px;
    animation: spin 4s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const ButtonSpinner = styled.div`
  height: 25px;
  width: 25px;
  border: 2px solid transparent;
  border-top-color: ${colors.purple};
  border-radius: 50%;
  animation: spin 2s linear infinite;
  &:before,
  &:after {
    content: '';
    position: absolute;
    border: 2px solid transparent;
    border-radius: 50%;
  }
  &:before {
    border-top-color: ${colors.lightseaGreen};
    top: -8px;
    left: -8px;
    right: -8px;
    bottom: -8px;
    animation: spin 3s linear infinite;
  }
  &:after {
    border-top-color: ${colors.silverSand};
    top: 4px;
    left: 4px;
    right: 4px;
    bottom: 4px;
    animation: spin 4s linear infinite;
  }
  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`

export const Shadow = styled.div`
  z-index: ${props => props.zIndex || 1};
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 110%;
  background: rgba(0, 0, 0, 0.5);
  opacity: 0;
  animation: animateShadow 0.3s forwards;
  @keyframes animateShadow {
    to {
      opacity: 1;
    }
  }
`

export const Switch = styled.label`
  margin: 0 10px;
  position: relative;
  display: inline-block;
  width: 48px;
  height: 24px;
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: ${colors.purple};
    transition: 0.4s;
  }
  .slider::before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    left: 3px;
    bottom: 3px;
    background-color: white;
    transition: 0.4s;
  }
  input:checked + .slider {
    background-color: #66fcf1;
  }
  input:focus + .slider {
    box-shadow: 0 0 1px #66fcf1;
  }
  input:checked + .slider:before {
    transform: translateX(24px);
  }
  input:disabled + .slider {
    background-color: #aaafbe;
  }
  .slider.round {
    border-radius: 24px;
  }
  .slider.round:before {
    border-radius: 50%;
  }
`
