import styled from 'styled-components'
import { colors } from '../../variables'

const ReportView = styled.div`
  width: 100vw;
  height: 100vh;
  overflow: scroll;
  display: flex;
  justify-content: center;
  background: ${colors.lightGray};
`
export default ReportView
