import React, { useEffect, useState } from 'react'
import { DeleteDashboardContainer } from '../../../style/components/dashboard/dashboards/DeleteDashboard.syled'

import { GrClose } from 'react-icons/gr'
import { gql, useLazyQuery } from '@apollo/client'
import { Spinner } from '../../../style/components'
import Button from '../../ui/Button'

const GET_REPORTS = gql`
  query retriveReports($dashboardID: String) {
    retriveReports(dashboardID: $dashboardID)
  }
`

const DeleteDashboard = ({ loading, dashboard, setDashboardToDelete, deleteDashboard }) => {
  const [retriveReports, { data: reportsData }] = useLazyQuery(GET_REPORTS)
  const [dashboardID] = useState(dashboard.id)

  const [reports, setReports] = useState([])

  useEffect(() => {
    retriveReports({ variables: { dashboardID } })
    if (reportsData !== undefined) setReports(JSON.parse(reportsData.retriveReports))
  }, [setReports, retriveReports, dashboardID, reportsData])

  return (
    <DeleteDashboardContainer columns={reports.length > 3 ? 2 : 1}>
      <div className='filters--header'>
        <h3>¡Eliminar tablero!</h3>
        <GrClose onClick={() => setDashboardToDelete(null)} />
      </div>
      <p>
        ¡Atención! Estás a punto de eliminar el tablero <span>{dashboard.name}</span>, si lo haces perderás todas las
        propiedades asignados al mismo y los miembros del tablero ya no podrán acceder.
      </p>
      {reports.length > 0 && (
        <>
          <p>
            Los <strong>reportes programados</strong> vinculados a este tablero serán interrumpidos de forma inmediata:
          </p>
          <div className='reportsList'>
            <ul>
              {reports.map((e, i) => (
                <li key={i}>{e.name}</li>
              ))}
            </ul>
          </div>
        </>
      )}
      <p>¿Estás seguro que deseas eliminar el tablero?</p>
      <div className='modal-buttons'>
        <Button variant={'link'} theme={'dark'} className='cancel-button' onClick={() => setDashboardToDelete(null)}>
          Cancelar
        </Button>
        <Button variant={'outline'} theme={'danger'} disabled={loading} onClick={() => deleteDashboard(dashboard.id)}>
          Eliminar
        </Button>
      </div>
    </DeleteDashboardContainer>
  )
}

export default DeleteDashboard
