import React, { useContext, useState } from 'react'
import { BsCheckBox, BsSquare } from 'react-icons/bs'
import { UIContext } from '../../../contexts/UIContext'
import { Toolbar } from '../../../style/components'
import {
  ButtonsSection,
  Container,
  InmediatoSection,
  ProgramadoSection
} from '../../../style/components/search/searches/ClippinModal.styled'
import PeriodDropdown from '../../../components/ui/PeriodDropdown'

const ClippingModal = ({ closeModal, openContact, createClipping, createClippingTicket }) => {
  const uiCTX = useContext(UIContext)
  const { toolbarClass } = uiCTX

  const [section, setSection] = useState('inmediato')
  const [mail, setMail] = useState('')
  const [period, setPeriod] = useState([])
  const [periodClipping, setPeriodClipping] = useState([])
  const [hour, setHour] = useState(['00:00', '23:59'])
  const [hourClipping, setHourClipping] = useState(['00:00', '23:59'])

  const [textSelect, setTextSelect] = useState(false)
  const [resumeSelect, setResumeSelect] = useState(false)

  const exportType = textSelect ? 'Texto' : 'Resumen'

  const [nameClipping, setNameClipping] = useState('')

  const periods = [
    {
      id: '0',
      name: '1 día atrás',
      days: 1
    },
    {
      id: '1',
      name: '2 días atrás',
      days: 2
    },
    {
      id: '2',
      name: '3 días atrás',
      days: 3
    },
    {
      id: '3',
      name: '4 días atrás',
      days: 4
    },
    {
      id: '4',
      name: '4 días atrás',
      days: 4
    },
    {
      id: '5',
      name: '5 días atrás',
      days: 5
    },
    {
      id: '6',
      name: '6 días atrás',
      days: 6
    },
    {
      id: '7',
      name: '7 días atrás',
      days: 7
    }
  ]

  const periodCreateClipping = [
    // {
    //     id: '0',
    //     name: 'Personalizado',
    // },
    {
      id: '1',
      name: 'Diario'
    },
    {
      id: '2',
      name: 'Semanal'
    },
    {
      id: '3',
      name: 'Quincenal'
    },
    {
      id: '4',
      name: 'Mensual'
    }
  ]

  const handleSelect = (type, option) => {
    if (type === 'text') {
      setTextSelect(true)
      setResumeSelect(false)
    } else {
      setTextSelect(false)
      setResumeSelect(true)
    }
  }

  const setInmediatoSection = () =>
    section === 'inmediato' ? 'section-button section-button-active' : 'section-button'
  const setProgramadoSection = () =>
    section === 'programado' ? 'section-button section-button-active' : 'section-button'

  const handleHour = (source, e) => {
    const hourArr = [...hour]
    if (source === 'from') {
      hourArr[0] = e.target.value
    } else if (source === 'to') {
      hourArr[1] = e.target.value
    }
    setHour(hourArr)
  }

  const handleHourClipping = (source, e) => {
    const hourArr = [...hourClipping]
    if (source === 'from') {
      hourArr[0] = e.target.value
    } else if (source === 'to') {
      hourArr[1] = e.target.value
    }
    setHourClipping(hourArr)
  }

  return (
    <Container size={section === 'inmediato' ? '530px' : '800px'}>
      <h3>{section === 'inmediato' ? 'Exportar clipping' : 'Exportar notas'}</h3>
      <Toolbar className={toolbarClass()} style={{ border: 'none', boxShadow: 'none' }}>
        <div className='left-toolbar'>
          <button className={setInmediatoSection()} onClick={() => setSection('inmediato')}>
            Inmediato
          </button>
          <button className={setProgramadoSection()} onClick={() => setSection('programado')}>
            Programado
          </button>
        </div>
      </Toolbar>

      {section === 'inmediato' && (
        <>
          <InmediatoSection>
            <p style={{ marginBottom: '20px' }}>Podrás consultar todas tus descargas en la pestaña de Clippings.</p>
            <input
              className='nameClipping'
              placeholder='Nombre del Clipping'
              value={nameClipping}
              onChange={e => setNameClipping(e.target.value)}
            />
            <h4>Tipo de archivo exportado</h4>
            <section className='selection'>
              <div className='exportSelection'>
                {textSelect ? (
                  <button className='checkbox-indicator' onClick={() => handleSelect('text', true)}>
                    <BsCheckBox className='checked' />
                  </button>
                ) : (
                  <button className='checkbox-indicator' onClick={() => handleSelect('text', false)}>
                    <BsSquare className='not-checked' />
                  </button>
                )}
                <p>Texto</p>
              </div>

              <div className='exportSelection'>
                {resumeSelect ? (
                  <button className='checkbox-indicator' onClick={() => handleSelect('resume', true)}>
                    <BsCheckBox className='checked' />
                  </button>
                ) : (
                  <button className='checkbox-indicator' onClick={() => handleSelect('resume', false)}>
                    <BsSquare className='not-checked' />
                  </button>
                )}
                <p>Resumen</p>
              </div>
            </section>
            <p style={{ marginBottom: '10px' }}>Correo al que deseas exportar</p>
            <input
              placeholder='Dirección de correo electrónico'
              value={mail}
              onChange={e => setMail(e.target.value)}
              type='email'
              required
            />
            <ButtonsSection mt='4%'>
              <button className='cancelButton' onClick={() => closeModal(false)}>
                Cancelar
              </button>
              <button
                disabled={!mail.includes('@')}
                className='exportButton'
                onClick={() => createClipping(mail, exportType, nameClipping)}
              >
                Exportar
              </button>
            </ButtonsSection>
          </InmediatoSection>
        </>
      )}

      {section === 'programado' && (
        <>
          <ProgramadoSection>
            <p>
              Recuerda que en el caso de los periódos programados sólo podrás solicitar hasta 7 días atrás de la
              información con el límite de 100 notas. Podrás consultar todas tus descargas en la pestaña de Clippings.
            </p>
            <input
              className='nameClipping'
              placeholder='Nombre del Clipping'
              value={nameClipping}
              onChange={e => setNameClipping(e.target.value)}
            />
            <section className='period'>
              <div className='periodSelect'>
                <label>Periodo</label>
                <PeriodDropdown
                  width='100%'
                  title='Elige un periodo'
                  items={periods}
                  selection={period}
                  setSelection={setPeriod}
                />
              </div>
              <div className='periodItem'>
                <label>A partir: </label>
                <input type='time' id='to-hour' value={hour[0]} onChange={e => handleHour('from', e)} />
              </div>
              <div className='periodItem'>
                <label>Hasta: </label>
                <input type='time' id='to-hour' value={hour[1]} onChange={e => handleHour('to', e)} />
              </div>
            </section>

            <h4>Horario de creación</h4>
            <section className='creation'>
              <div className='periodSelect'>
                <PeriodDropdown
                  width='100%'
                  title='Elige cuándo recibiras tu reporte'
                  items={periodCreateClipping}
                  selection={periodClipping}
                  setSelection={setPeriodClipping}
                />
              </div>
              <div className='periodCreation'>
                <div className='periodItem'>
                  <label>A partir: </label>
                  <input
                    type='time'
                    id='to-hour'
                    value={hourClipping[0]}
                    onChange={e => handleHourClipping('from', e)}
                  />
                </div>
                <div className='periodItem'>
                  <label>Hasta: </label>
                  <input type='time' id='to-hour' value={hourClipping[1]} onChange={e => handleHourClipping('to', e)} />
                </div>
              </div>
            </section>

            <h4>Tipo de archivo exportado</h4>
            <section className='selection'>
              <div className='exportSelection'>
                {textSelect ? (
                  <button className='checkbox-indicator' onClick={() => handleSelect('text', true)}>
                    <BsCheckBox className='checked' />
                  </button>
                ) : (
                  <button className='checkbox-indicator' onClick={() => handleSelect('text', false)}>
                    <BsSquare className='not-checked' />
                  </button>
                )}
                <p>Texto</p>
              </div>

              <div className='exportSelection'>
                {resumeSelect ? (
                  <button className='checkbox-indicator' onClick={() => handleSelect('resume', true)}>
                    <BsCheckBox className='checked' />
                  </button>
                ) : (
                  <button className='checkbox-indicator' onClick={() => handleSelect('resume', false)}>
                    <BsSquare className='not-checked' />
                  </button>
                )}
                <p>Resumen</p>
              </div>
            </section>
            <p style={{ marginBottom: '8px' }}>Correo al que deseas exportar</p>
            <input
              placeholder='Dirección de correo electrónico'
              value={mail}
              onChange={e => setMail(e.target.value)}
              type='email'
              required
            />
            <ButtonsSection mt='3%' jt='space-evenly    '>
              <button className='moreButton' onClick={() => openContact()}>
                Solicitar más de 100 notas
              </button>
              <button
                disabled={!mail.includes('@')}
                className='exportButton'
                onClick={() => createClippingTicket(mail, period, hour, exportType, nameClipping)}
              >
                Exportar
              </button>
            </ButtonsSection>
          </ProgramadoSection>
        </>
      )}
    </Container>
  )
}

export default ClippingModal
