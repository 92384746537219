import React, { useContext, useState, useRef, useCallback } from 'react'
import FaqContainer from '../../style/pages/faq/Faq.styled'

import { Topbar, MainContainer } from '../../style/components'
import { UIContext } from '../../contexts/UIContext'

import QueEs from '../../components/faqs/QueEs'
import Registro from '../../components/faqs/Registro'
import ComoFunciona from '../../components/faqs/ComoFunciona'
import Graficos from '../../components/faqs/Graficos'
import Tableros from '../../components/faqs/Tableros'

const Faq = () => {
  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX
  const [visible, setVisible] = useState('que')

  const queEs = useRef()
  const setQue = useCallback(node => {
    if (node !== null) {
      queEs.current = node
    }
  }, [])

  const registro = useRef()
  const setRegistro = useCallback(node => {
    if (node !== null) {
      registro.current = node
    }
  }, [])

  const funciona = useRef(null)
  const setFunciona = useCallback(node => {
    if (node !== null) {
      funciona.current = node
    }
  }, [])

  const busqueda = useRef()
  const traditional = useRef()
  const twitter = useRef()

  const graficos = useRef()
  const setGraficos = useCallback(node => {
    if (node !== null) {
      graficos.current = node
    }
  }, [])

  const diferentes = useRef()
  const tablero = useRef()

  const tableros = useRef()
  const setTableros = useCallback(node => {
    if (node !== null) {
      tableros.current = node
    }
  }, [])

  const herramientas = useRef()

  return (
    <>
      <MainContainer className={mainClass()}>
        <Topbar className={topbarClass()} />

        <FaqContainer>
          <div className='menu stycky'>
            <h2>FAQ</h2>
            <ul>
              <li>
                <button
                  className={visible === 'que' ? 'activo' : ''}
                  onClick={async () => {
                    await setVisible('que')
                    queEs.current.scrollIntoView()
                  }}
                >
                  ¿Qué es?
                </button>
              </li>
              <li>
                <button
                  className={visible === 'registro' ? 'activo' : ''}
                  onClick={async () => {
                    await setVisible('registro')
                    registro.current.scrollIntoView()
                  }}
                >
                  ¿Cómo registrarse?
                </button>
              </li>
              <li>
                <button
                  className={visible === 'funciona' ? 'activo' : ''}
                  onClick={async () => {
                    await setVisible('funciona')
                    funciona.current.scrollIntoView()
                  }}
                >
                  ¿Cómo funciona MyOblek?
                </button>
                {visible === 'funciona' && (
                  <ul className={visible === 'funciona' ? 'pad' : ''}>
                    <li>
                      <button onClick={() => busqueda.current.scrollIntoView()}>Búsquedas</button>
                    </li>
                    <li>
                      <button onClick={() => traditional.current.scrollIntoView()}>Medios tradicionales</button>
                    </li>
                    <li>
                      <button onClick={() => twitter.current.scrollIntoView()}>Twitter</button>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <button
                  className={visible === 'graficos' ? 'activo' : ''}
                  onClick={async () => {
                    await setVisible('graficos')
                    graficos.current.scrollIntoView()
                  }}
                >
                  ¿Qué son los gráficos?
                </button>
                {visible === 'graficos' && (
                  <ul className={visible === 'graficos' ? 'pad' : ''}>
                    <li>
                      <button onClick={() => diferentes.current.scrollIntoView()}>
                        ¿Por qué tienen colores diferentes?
                      </button>
                    </li>
                    <li>
                      <button onClick={() => tablero.current.scrollIntoView()}>
                        ¿Cómo agrego un gráfico al tablero?
                      </button>
                    </li>
                  </ul>
                )}
              </li>
              <li>
                <button
                  className={visible === 'tableros' ? 'activo' : ''}
                  onClick={async () => {
                    await setVisible('tableros')
                    tableros.current.scrollIntoView()
                  }}
                >
                  Página de tableros
                </button>
                {visible === 'tableros' && (
                  <ul className={visible === 'tableros' ? 'pad' : ''}>
                    <li>
                      <button onClick={() => herramientas.current.scrollIntoView()}>Herramientas</button>
                    </li>
                  </ul>
                )}
              </li>
            </ul>
          </div>
          <div className='contenido'>
            {visible === 'que' && (
              <section id='que' ref={setQue}>
                <QueEs queEsRef={queEs} />
              </section>
            )}
            {visible === 'registro' && (
              <section id='registro' ref={setRegistro}>
                <Registro />
              </section>
            )}
            {visible === 'funciona' && (
              <section id='funciona' ref={setFunciona}>
                <ComoFunciona busquedaRef={busqueda} traditionalRef={traditional} twitterRef={twitter} />
              </section>
            )}
            {visible === 'graficos' && (
              <section id='graficos' ref={setGraficos}>
                <Graficos diferentesRef={diferentes} tableroRef={tablero} />
              </section>
            )}
            {visible === 'tableros' && (
              <section id='tableros' ref={setTableros}>
                <Tableros herramientasRef={herramientas} />
              </section>
            )}
          </div>
        </FaqContainer>
      </MainContainer>
    </>
  )
}

export default Faq
