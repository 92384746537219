import styled from 'styled-components'
import { colors } from '../../variables'

const MediaContainer = styled.div`
  padding-top: 40px;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      h1 {
        width: 300px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .toolbar {
    justify-content: space-between;
    .left-toolbar {
      height: 100%;
      .section-button {
        width: 100px;
        height: 100%;
        border: none;
        outline: none;
        margin-right: 30px;
        background: transparent;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
      }
      .section-button-active {
        color: ${colors.purple};
        border-bottom: 2px solid ${colors.purple};
      }
    }
    .right-toolbar {
      display: flex;
      .toolbar-button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100px;
        height: 40px;
        margin-left: 10px;
        border: none;
        outline: none;
        background: none;
        cursor: pointer;
        .toolbar-icon {
          width: 20px;
          height: 20px;
          margin-right: 10px;
          stroke: ${colors.darkGray};
          &.gray-icon {
            fill: ${colors.darkGray};
          }
        }
        p {
          color: ${colors.darkGray};
          font-size: 14px;
          font-weight: 500;
        }
      }
    }
  }
`

export default MediaContainer
