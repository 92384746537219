import React, { useEffect } from 'react'
import EditTwitterSearch from './EditTwitterSearch'
import EditTraditionalSearch from './EditTraditionalSearch'
import { useMutation } from '@apollo/client'
import { UPDATE_SEARCH_MUTATION } from '../../../services/mutations'
import { SEARCH_QUERY } from '../../../services/queries'
import EditFiltersContainer from '../../../style/components/search/search/EditFilters.styled'

const EditFilters = ({ search, setSearch, closeEdition, open }) => {
  const [updateSearch, { loading }] = useMutation(UPDATE_SEARCH_MUTATION, {
    update(cache, { data: { updateSearch } }) {
      cache.writeQuery({
        query: SEARCH_QUERY,
        variables: { id: search.id },
        data: {
          search: updateSearch
        }
      })
    }
  })

  const saveSearch = async () => {
    await updateSearch({
      variables: {
        id: search.id,
        input: {
          query: search.query,
          filters: {
            ...search.filters,
            __typename: undefined
          }
        }
      }
    })
    closeEdition()
  }

  return (
    <EditFiltersContainer className={open ? ' opened' : ' closed'}>
      {search.source.includes('medios') ? (
        <EditTraditionalSearch loading={loading} setSearch={setSearch} search={search} saveSearch={saveSearch} />
      ) : (
        <EditTwitterSearch loading={loading} setSearch={setSearch} saveSearch={saveSearch} search={search} />
      )}
    </EditFiltersContainer>
  )
}

export default EditFilters
