import styled from 'styled-components'
import { colors } from '../../../variables'
import iconChecked from '../../../../assets/icons/icon_checkingbox.svg'
import iconUnChecked from '../../../../assets/icons/icon_box.svg'

export const ModalExportCont = styled.div`
  .backgroundCont {
    background: rgba(0, 0, 0, 0.65);
    position: fixed;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0;
    z-index: 100;
    animation: modalCont 0.4s forwards;
  }
  .check-box {
    margin-right: auto;
    height: 18px;
    width: 18px;
    position: relative;
    &:before {
      content: '';
      height: 18px;
      width: 18px;
      display: inline-block;
      background: white url('${iconUnChecked}') center center no-repeat;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 2;
    }
    &:after {
      content: '';
      height: 20px;
      width: 20px;
      display: inline-block;
      background: white;
      background-size: cover;
      position: absolute;
      top: 0;
      left: 0;
      transform: translate(-2px, -2px);
      z-index: 1;
    }
    &:checked:before {
      content: '';
      height: 18px;
      width: 18px;
      background: white url('${iconChecked}') center center no-repeat;
      background-size: cover;
    }
  }
  .modalExportCont {
    position: fixed;
    max-width: 1100px;
    max-height: 650px;
    background: ${colors.white};
    padding: 30px 35px;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: auto;
    transform: translate(0, 80px);
    opacity: 0;
    border-radius: 10px;
    animation: modalCont 0.4s forwards 0.2s;
    cursor: default;
    display: flex;
    z-index: 101;
    &-body {
      position: relative;
      display: flex;
      flex-direction: column;
      width: 100%;
      .buttonClose {
        position: absolute;
        right: 0;
        top: 0;
        cursor: pointer;
        svg {
          fill: ${colors.darkGray};
        }
      }
      &-title {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 20px;
        h2 {
          text-align: center;
          font-family: Montserrat;
          font-size: 30px;
        }
      }
      &-export {
        display: flex;
        width: 100%;
        height: 100%;
        justify-content: space-between;
        .export-config {
          width: 48%;
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          .groupConfig {
            width: 100%;
            display: flex;
            flex-direction: column;
            h3 {
              text-align: left;
              font-family: Montserrat;
              font-size: 24px;
              margin-bottom: 30px;
            }
            span {
              color: ${colors.darkGray};
              font-size: 14px;
              margin-bottom: 10px;
            }
            .inputs {
              display: flex;
              textarea {
                border-radius: 5px;
                width: 100%;
                border: 1px solid ${colors.darkGray};
                padding: 10px;
                &:focus {
                  outline: none;
                }
              }
              .input {
                margin-right: 60px;
                input {
                  margin-right: 12px;
                  cursor: pointer;
                }
                span {
                  color: ${colors.darkGray};
                }
              }
            }
          }
          .buttonExport {
            display: flex;
            width: 100%;
            margin-top: 29px;
          }
        }
        .line {
          height: 100%;
          width: 0.1px;
          background: ${colors.lightGray};
        }
        .export-preview {
          width: 48%;
          height: 100%;
          display: flex;
          flex-direction: column;
          .grafico {
            padding: 0px;
            display: flex;
            flex-direction: column;
            height: 100%;
            &-title {
              display: flex;
              margin-bottom: 10px;
              h3 {
                font-size: 14px;
              }
            }
            &-container {
              height: 400px;
            }
          }
          .groupConfig {
            width: 100%;
            display: flex;
            flex-direction: column;
            h3 {
              text-align: left;
              font-family: Montserrat;
              font-size: 24px;
              margin-bottom: 30px;
            }
            span {
              color: ${colors.darkGray};
              font-size: 14px;
              margin-bottom: 10px;
            }
            .inputs {
              display: flex;
              textarea {
                border-radius: 5px;
                width: 100%;
                border: 1px solid ${colors.darkGray};
                padding: 10px;
                &:focus {
                  outline: none;
                }
              }
              .input {
                margin-right: 60px;
                input {
                  margin-right: 12px;
                  cursor: pointer;
                }
                span {
                  color: ${colors.darkGray};
                }
              }
            }
          }
          .buttonExport {
            display: flex;
            width: 100%;
            margin-top: 29px;
            button {
              height: 47px;
              width: 100%;
              background: ${colors.torquioseBlue};
              color: black;
              font-weight: bold;
              border: none;
              border-radius: 4px;
              cursor: pointer;
              &:focus {
                outline: none;
              }
            }
          }
        }
      }
    }
  }
  @keyframes modalCont {
    to {
      opacity: 1;
      transform: translate(0, 0);
    }
  }
`
