import styled from 'styled-components'
import { colors } from '../../../variables'

const SearchRowContainer = styled.div`
  display: flex;
  flex-direction: column;
  cursor: pointer;
  transition: ease-in-out 0.2s;
  bottom: 0;
  position: relative;
  &:hover {
    bottom: 3px;
  }
  .menu {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 16%;
    right: 80px;
    button {
      border: none;
      background: none;
      outline: none;
      width: 25px;
      height: 25px;
      border-radius: 4px;
      cursor: pointer;
      svg {
        width: 100%;
        height: 100%;
        color: #748596;
      }
    }
  }
  .options {
    display: flex;
    flex-direction: column;
    z-index: 1;
    position: absolute;
    top: 25px;
    right: -5px;
    left: -168px;
    width: 203px;
    height: 149px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .edit__option {
      height: 49.5px;
      width: 100%;
      padding-left: 17.5px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: #748596;
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 18.17px;
        height: 18.17px;
        margin-right: 17px;
      }
    }
    .delete__option {
      height: 49.5px;
      width: 100%;
      padding-left: 17.5px;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.red};
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 19px;
        height: 22px;
        margin-right: 17px;
        color: ${colors.red};
      }
    }
  }
  .searchCont {
    display: flex;
    height: 50px;
    width: 100%;
    border-radius: 4px;
    margin-bottom: 20px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    transition: ease-in-out 0.3s;
    &:hover {
      background: ${colors.lightGray};
    }
    p {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      padding-left: 2%;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 500;
      &.number {
        width: 7%;
      }
      &.name {
        width: 28%;
      }
      &.source {
        width: 20%;
        text-transform: capitalize;
      }
      &.date {
        width: 13%;
      }
    }
    .creator {
      width: 17%;
      display: flex;
      align-items: center;
      .creator-photo {
        width: 30px;
        height: 30px;
        margin-right: 10px;
        background: ${colors.lightGray};
        border-radius: 50%;
      }
    }
  }
`

export default SearchRowContainer
