import styled from 'styled-components'
import { colors } from '../../variables'

const FaqContainer = styled.div`
  height: calc(100vh - 70px);
  .stycky {
    position: fixed;
    top: 0;
  }
  .menu {
    top: 70px;
    width: 26%;
    border-right: 2px solid ${colors.lightGray};
    overflow: hidden;
    padding-top: 2%;
    height: calc(100vh - 72px);
  }
  .menu h2 {
    font-family: Montserrat;
    font-size: 30px;
    font-weight: 800;
    margin-bottom: 40px;
  }
  .menu li {
    list-style: none;
    padding-bottom: 2ex;
  }
  .menu li button {
    border: none;
    background-color: transparent;
    text-decoration: none;
    font-family: Montserrat;
    font-weight: 600;
    font-size: 18px;
    color: grey;
  }
  .menu li button:hover {
    color: ${colors.lightseaGreen};
  }
  .menu li .activo {
    color: ${colors.lightseaGreen};
  }
  .menu li .pad {
    margin-top: 1rem;
    padding-left: 15px;
  }
  .menu li .pad li button {
    text-align: left;
  }
  .contenido {
    width: 65%;
    margin-left: 35%;
    overflow: auto;
    height: calc(100vh - 72px);
  }
  .contenido p {
    margin-top: 15px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    line-height: 1.5;
    color: ${colors.richBlack};
    text-align: justify;
  }
  .contenido section {
    width: 100%;
  }
  .contenido section h3 {
    padding-top: 20px;
    font-family: Montserrat;
    font-size: 21px;
    color: ${colors.lightseaGreen};
  }
  .contenido section ul li {
    padding-top: 15px;
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.richBlack};
  }
  button:active,
  .button:active,
  button:focus,
  .button:focus,
  button:hover,
  .button:hover {
    border: none !important;
    outline: none !important;
  }
`

export default FaqContainer
