import styled from 'styled-components'
import { colors } from '../../variables'

const DropdownContainer = styled.div`
  border: 1px solid #748596;
  border-radius: 4px;
  margin-bottom: 25px;
  height: 50px;
  padding: 15px;
  position: relative;
  cursor: pointer;
  .dropdown__role {
    display: flex;
    justify-content: space-between;
    .user__select__role {
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .user__role__selected {
      color: ${colors.richBlack};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .arrow {
      margin-left: 19.83px;
    }
    .arrow__rotate {
      transform: rotate(180deg);
    }
  }
  .menu-visible {
    position: absolute;
    top: 49px;
    right: 0;
    width: 319px;
    height: 200px;
    border-top: 1px solid ${colors.lightseaGreen};
    border-radius: 0 0 4px 4px;
    background: ${colors.white};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
  }
  .menu-closed {
    display: none;
  }
  .dropdown-option {
    width: 100%;
    height: 100px;
    padding: 20px;
    border: none;
    border-radius: 0 0 4px 4px;
    outline: none;
    background: none;
    position: relative;
    .dropdown__options__header {
      display: flex;
      flex-direction: row;
      .options__header__title {
        color: ${colors.richBlack};
        font-size: 14px;
        font-weight: 500;
        text-align: left;
        padding-bottom: 5px;
        margin-right: 10px;
      }
    }
    p {
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
      text-align: left;
    }
    &:hover {
      background: ${colors.lightseaGreen};
    }
  }
`

export default DropdownContainer
