import styled from 'styled-components'
import BgNoAccess from '../../../../assets/reports/images/background_reporte_no_disponible.png'

export const NoAccessContainer = styled.div`
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
`

export const BackgroundContainer = styled.div`
  width: 90%;
  height: 100%;
  background-image: url(${BgNoAccess});
  background-size: cover;
`

export const MessageContainer = styled.div`
  height: 80%;
  width: 39%;
  margin-top: 20%;
  margin-left: 50%;
  h3 {
    font-size: 45px;
    margin-bottom: 5%;
  }
  p {
    font-size: 30px;
    margin-bottom: 5%;
  }
`
