import styled from 'styled-components'
import { colors } from '../../variables'

const DeleteWorkspaceContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 632px;
  padding: 30px 40px 50px;
  border-radius: 4px;
  background: ${colors.white};
  .filters--header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    .deleteaccount__headline {
      font-size: 24px;
      font-weight: 800;
      line-height: 29.26px;
      color: ${colors.red};
    }
  }
  .deleteaccount__description {
    width: 100%;
    margin-bottom: 23px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  form {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    .deleteaccount__label {
      align-self: flex-start;
      margin-bottom: 8px;
      font-size: 14px;
      font-weight: 500;
      line-height: ${colors.richBlack};
    }
    input {
      width: 100%;
      height: 50px;
      padding: 0 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 400;
      margin-bottom: 15px;
    }
    .deletewarning__buttons {
      width: 100%;
      display: flex;
      justify-content: flex-end;
      margin-top: 35px;
    }
  }
`

export default DeleteWorkspaceContainer
