import { gql } from '@apollo/client'

// Widget mutations.
export const RESIZE_WIDGET = gql`
  mutation resizeWidget($id: ID, $width: String) {
    resizeWidget(id: $id, width: $width) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
    }
  }
`

export const MOVE_WIDGET = gql`
  mutation moveWidget($id: ID, $dashboardID: ID, $direction: String) {
    moveWidget(id: $id, dashboardID: $dashboardID, direction: $direction)
  }
`

export const EDIT_WIDGET = gql`
  mutation renameWidget($id: ID, $input: String) {
    renameWidget(id: $id, input: $input) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
    }
  }
`

export const DELETE_WIDGET_MUTATION = gql`
  mutation deleteWidget($id: ID, $dashboardID: ID) {
    deleteWidget(id: $id, dashboardID: $dashboardID) {
      id
    }
  }
`

// Dashboard mutations
export const CREATE_DASHBOARD = gql`
  mutation createDashboard($input: DashboardInput!) {
    newDashboard(input: $input) {
      id
      name
      description
      createdBy {
        name
        email
        photo
        role
        birthday
        gender
        occupation
        degree
        onboarding
      }
      createdAt
    }
  }
`

export const SHARE_DASHBOARD = gql`
  mutation shareDashboard($input: ShareInput!) {
    shareDashboard(input: $input)
  }
`

export const CHANGE_PERMIT = gql`
  mutation changePermit($input: ShareInput!) {
    changePermit(input: $input)
  }
`

export const DELETE_PERMIT = gql`
  mutation deletePermit($input: ShareInput!) {
    deletePermit(input: $input)
  }
`

// Report mutations
export const DELETE_REPORT = gql`
  mutation deleteReport($id: ID) {
    deleteReport(id: $id) {
      id
    }
  }
`
export const DELETE_TICKET = gql`
  mutation deleteTicket($id: ID, $deleteAll: Boolean) {
    deleteTicket(id: $id, deleteAll: $deleteAll) {
      id
    }
  }
`

export const STARTSTOP_TICKET = gql`
  mutation startstopTicket($id: ID, $order: Int) {
    startstopTicket(id: $id, order: $order) {
      id
      paused
    }
  }
`

// Search mutations
export const NEW_SEARCH_MUTATION = gql`
  mutation createSearch($input: SearchInput!) {
    newSearch(input: $input) {
      id
    }
  }
`
export const NEW_TESTIGO_REPORT_MUTATION = gql`
  mutation createNewTestigoReport($input: TestigoInput) {
    newTestigoReport(input: $input)
  }
`

export const MODIFY_ONBOARDING = gql`
  mutation modifyOnboarding($flag: Boolean) {
    modifyOnboarding(flag: $flag) {
      name
      email
      photo
      role
      birthday
      gender
      occupation
      degree
      onboarding
    }
  }
`

// Modals mutations
export const CREATE_CONTACT = gql`
  mutation createContactRequest($input: ContactInput) {
    createContactRequest(input: $input) {
      id
    }
  }
`

export const MAKE_DEFAULT_PAYMENT_METHOD = gql`
  mutation makeDefaultPaymentMethod($paymentMethodId: String!) {
    makeDefaultPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`

// Workspace mutations
export const CANCEL_SUBSCRIPTION_MUTATION = gql`
  mutation cancelSubscription($input: AuthenticateInput!) {
    cancelSubscription(input: $input) {
      cancel_at
      cancel_at_period_end
      current_period_end
    }
  }
`

export const DELETE_WORKSPACE = gql`
  mutation deleteWorkspace($input: AuthenticateInput!) {
    deleteWorkspace(input: $input)
  }
`

export const UPDATE_WORKSPACE = gql`
  mutation updateWorkspace($input: UpdateWorkspaceInput) {
    updateWorkspace(input: $input) {
      name
      description
    }
  }
`

export const UPDATE_SEARCH_MUTATION = gql`
  mutation updateSearch($id: ID, $input: UpdateSearchInput!) {
    updateSearch(id: $id, input: $input) {
      id
      query
      name
      source
      filters {
        media
        states
      }
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const DUPLICATE_SEARCH_MUTATION = gql`
  mutation duplicateSearch($id: ID!, $name: String!) {
    duplicateSearch(id: $id, name: $name) {
      id
      query
      name
      source
      filters {
        media
        states
      }
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const DELETE_SEARCH_MUTATION = gql`
  mutation deleteSearch($id: ID) {
    deletedSearch(id: $id) {
      id
    }
  }
`

export const DELETE_DASHBOARD_MUTATION = gql`
  mutation deleteDashboard($id: ID) {
    deletedDashboard(id: $id) {
      id
    }
  }
`

export const CREATE_INVITE = gql`
  mutation createInvite($input: InviteInput!) {
    createInvite(input: $input) {
      id
      email
      role
    }
  }
`

export const ATTACH_PAYMENT_METHOD = gql`
  mutation attachPaymentMethod($paymentMethodId: String!) {
    attachPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`

export const DETACH_PAYMENT_METHOD = gql`
  mutation detachPaymentMethod($paymentMethodId: String!) {
    detachPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`

export const UPDATE_BILL_INFO = gql`
  mutation updateBillInfo($input: BillInput) {
    updateBillInfo(input: $input) {
      billName
      rfc
      billEmail
      street
      exterior
      interior
      cp
      state
      alcMun
      colonia
      required
    }
  }
`

export const UPDATE_BILL_REQUIRED = gql`
  mutation updateBillRequired($flagInvoice: Boolean) {
    updateBillRequired(flagInvoice: $flagInvoice)
  }
`

export const GET_ADRESS_MUTATION = gql`
  mutation adress($cp: String) {
    getAdress(cp: $cp) {
      estado
      municipio
      asentamiento
    }
  }
`

export const FUTURE_INVOICE = gql`
  mutation futureInvoice($planId: String) {
    futureInvoice(planId: $planId)
  }
`

export const RETRIVE_SUBSCRIPTION = gql`
  mutation retriveSubscription {
    retriveSubscription
  }
`

export const DOTHEPLAN_CHANGE = gql`
  mutation updatePlan($planId: String) {
    updatePlan(planId: $planId)
  }
`

export const DOTHEPLAN_CHANGE_ANUALTOMONTH = gql`
  mutation updatePlanAnualToMonth($planId: String, $nextCharge: String) {
    updatePlanAnualToMonth(planId: $planId, nextCharge: $nextCharge)
  }
`

export const REACTIVATE_SUBSCRIPTION_MUTATION = gql`
  mutation reactivateSubscription {
    reactivateSubscription {
      cancel_at
      cancel_at_period_end
      current_period_end
    }
  }
`

export const DELETE_INVITE = gql`
  mutation deleteInvite($id: ID!) {
    deleteInvite(id: $id)
  }
`

export const CHANGE_ROLE = gql`
  mutation changeTeamRoles($userID: ID!) {
    changeTeamRoles(userID: $userID)
  }
`

// PAGES MUTATIONS

// auth
export const CREATE_USER = gql`
  mutation createUser($input: UserInput!) {
    createUser(input: $input) {
      token
    }
  }
`

export const AUTHENTICATE_USER = gql`
  mutation authenticateUser($input: AuthenticateInput!) {
    authenticateUser(input: $input) {
      token
      pS
    }
  }
`

export const CHANGE_USER_PASSWORD = gql`
  mutation recoverPassword($input: RecoverInput!) {
    recoverPassword(input: $input)
  }
`

export const SEND_RECOVERY_EMAIL = gql`
  mutation sendRecoveryEmail($email: String!) {
    sendRecoveryEmail(email: $email)
  }
`

export const CREATE_STRIPE_CUSTOMER = gql`
  mutation createStripeCustomer($input: StripeCustomerInput!) {
    createStripeCustomer(input: $input)
  }
`

export const CREATE_STRIPE_SUBSCRIPTION = gql`
  mutation createStripeSubscription($input: StripeInput!) {
    createStripeSubscription(input: $input)
  }
`

export const CREATE_STRIPE_SUBSCRIPTION_USER_FREE = gql`
  mutation createStripeSubscriptionUserFree($input: StripeUserFreeInput!) {
    createStripeSubscriptionUserFree(input: $input)
  }
`

export const CREATE_WORKSPACE = gql`
  mutation createWorkspace($input: WorkspaceInput!) {
    createWorkspace(input: $input) {
      token
    }
  }
`

// dashboard
export const UPDATE_DASHBOARD_MUTATION = gql`
  mutation updateDashboard($id: ID, $input: DashboardInput) {
    updatedDashboard(id: $id, input: $input) {
      id
      name
      description
      createdBy {
        id
        name
        email
        photo
      }
      editors {
        id
        name
        email
        photo
      }
      viewers {
        id
        name
        email
        photo
      }
    }
  }
`

export const DUPLICATE_DASHBOARD_MUTATION = gql`
  mutation duplicateDashboard($input: DuplicateDashboardInput!) {
    duplicatedDashboard(input: $input) {
      id
      name
      description
      createdBy {
        name
        photo
      }
      widgets {
        id
        kind
        name
      }
      editors {
        id
      }
      viewers {
        id
      }
      createdAt
    }
  }
`

export const NEW_WIDGET_MUTATION = gql`
  mutation createWidget($input: WidgetInput!) {
    newWidget(input: $input) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
    }
  }
`
export const DUPLICATE_WIDGET_MUTATION = gql`
  mutation duplicateWidget($id: ID!) {
    duplicateWidget(id: $id) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
    }
  }
`

export const NEW_DASHBOARD = gql`
  mutation newDashboad($input: DashboardInput!) {
    newDashboard(input: $input) {
      id
      name
      description
    }
  }
`

export const UPDATE_WIDGET_MUTATION = gql`
  mutation updateWidget($id: ID, $searches: [ID], $operation: String) {
    updateWidget(id: $id, searches: $searches, operation: $operation) {
      id
      name
      kind
      searches {
        id
        name
        source
        createdBy {
          name
          photo
        }
        createdAt
      }
      dashboard {
        id
        name
      }
    }
  }
`

// media
export const MEDIUM_REQUEST_MUTATION = gql`
  mutation mediumRequest($input: MediumInput) {
    mediumRequest(input: $input) {
      name
      description
      type
      subtype
    }
  }
`

// profile
export const UPLOAD_PHOTO = gql`
  mutation uploadPhoto($url: String!) {
    uploadPhoto(url: $url) {
      name
      email
      photo
      role
      birthday
      gender
      occupation
      degree
      onboarding
    }
  }
`

export const EDIT_PROFILE = gql`
  mutation editProfile($profile: Profile) {
    editProfile(profile: $profile) {
      name
      email
      photo
      role
      birthday
      age
      gender
      occupation
      degree
      onboarding
    }
  }
`

export const CHANGE_PASSWORD = gql`
  mutation changeUserPassword($input: RecoverInput!) {
    changeUserPassword(input: $input)
  }
`

// report

export const CREATE_REPORT = gql`
  mutation createReport($input: ReportInput!) {
    createReport(input: $input) {
      id
    }
  }
`

export const CREATE_TICKET = gql`
  mutation createTicket($input: TicketInput!) {
    createTicket(input: $input) {
      id
    }
  }
`

export const UPDATE_TICKET = gql`
  mutation updateTicket($input: TicketInput!, $id: ID) {
    updateTicket(input: $input, id: $id) {
      id
    }
  }
`

/* Clipping Mutations */

export const CREATE_I_CLIPPING = gql`
  mutation inmediateClipping($data: String) {
    inmediateClipping(data: $data)
  }
`

export const CREATE_S_CLIPPING = gql`
  mutation scheduledClipping($data: String) {
    scheduledClipping(data: $data)
  }
`

export const DELETE_CLIPPING = gql`
  mutation deleteClipping($id: ID) {
    deleteClipping(id: $id)
  }
`
export const DELETE_CLIPPING_TICKET = gql`
  mutation deleteClippingTicket($id: ID) {
    deleteClippingTicket(id: $id)
  }
`
