import { useMutation, useQuery } from '@apollo/client'
import moment from 'moment'
import React, { useContext } from 'react'
import { IoIosArrowBack } from 'react-icons/io'
import { useParams } from 'react-router'
import ProfileIcon from '../../../assets/icons/icon_profile.svg'
import TrashIcon from '../../../assets/icons/icon_trash.svg'

import { UIContext } from '../../../contexts/UIContext'
import { DELETE_CLIPPING } from '../../../services/mutations'
import { CLIPPINGSBYTICKET_QUERY } from '../../../services/queries'
import { MainContainer, Spinner, Topbar } from '../../../style/components'
import { TableRow, TableHead, Head, Row } from '../../../style/components/search/searches/Clipping.styled'
import { ClippingDetailContainer } from '../../../style/pages/clippings/clippingDetail/ClippingDetail.styled'

const ClippingDetailScheduled = ({ history }) => {
  const { name, id } = useParams()

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const { data, loading, refetch } = useQuery(CLIPPINGSBYTICKET_QUERY, {
    fetchPolicy: 'network-only',
    variables: { ticketID: id }
  })
  const [deleteClipping] = useMutation(DELETE_CLIPPING)

  const headers = ['Fecha', 'Nombre', 'Periodo', 'Notas', 'Archivo', 'Creador', '']

  if (loading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <ClippingDetailContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => history.goBack()}>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </button>
            <h1>Clipping - {name} </h1>
          </div>
        </Topbar>
        <br />
        <hr />
        <TableHead>
          {headers.map((e, i) => (
            <Head key={i}>{e}</Head>
          ))}
        </TableHead>
        <div style={{ overflowY: 'scroll', maxHeight: '80%' }}>
          {data.getClippingsByTicket.map((el, i) => (
            <TableRow
              key={i}
              onClick={e => {
                e.cancelBubble = true
                e.stopPropagation()
                history.push(`/clipping/${el.id}`)
              }}
            >
              <Row>{moment(new Date(Number(el.createdAt))).format('DD/MM/YYYY')}</Row>
              <Row>{el.name}</Row>
              <Row>{`${moment(el.startDate).format('DD/MM/YYYY')} al ${moment(el.endDate).format('DD/MM/YYYY')}`}</Row>
              <Row>{el.noteQty}</Row>
              <Row>{el.exportType}</Row>
              <Row>
                <img src={ProfileIcon} alt='profileIcon' width='35px' height='35px' style={{ marginRight: '5px' }} />{' '}
                {el.createdBy.name}
              </Row>
              <Row
                onClick={e => {
                  e.cancelBubble = true
                  e.stopPropagation()
                  refetch()
                  deleteClipping({ variables: { id: el.id } })
                }}
              >
                <img src={TrashIcon} alt='trashIcon' width='20px' height='20px' style={{ marginRight: '5px' }} />
              </Row>
            </TableRow>
          ))}
        </div>
      </ClippingDetailContainer>
    </MainContainer>
  )
}

export default ClippingDetailScheduled
