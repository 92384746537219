import styled from 'styled-components'
import { colors } from '../../../variables'

export const NoSearchesContainer = styled.div`
  padding-left: ${props => (props.filtersOpen ? '400px' : '0px')};
  height: calc(100vh - 110px);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .no-searches--icon {
    width: 167px;
    height: 167px;
    border-radius: 50%;
    background: ${colors.lightGray};
    margin-bottom: 20px;
    svg {
      width: 100%;
      height: 100%;
    }
  }
  p {
    font-size: 18px;
    font-weight: 600;
    color: ${colors.darkGray};
    margin-bottom: 60px;
  }
  Button {
    cursor: pointer;
  }
`
