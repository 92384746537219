import styled from 'styled-components'
import { colors } from '../../variables'

export const SignupContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  .left-information {
    width: 525px;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    p {
      line-height: 41px;
      font-size: 26px;
      font-weight: 500;
      width: 95%;
      margin-bottom: 37px;
    }
    .privacy-note {
      line-height: 23px;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 40px;
      a {
        line-height: 23px;
        font-size: 15px;
        font-weight: 700;
        text-decoration: underline;
      }
    }
    button {
      width: 322px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    height: 500px;
  }
`

export const SignupForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 530px;
  height: 626px;
  padding: 50px 50px 0;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  margin-left: 100px;
  h2 {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 800;
  }
  p {
    margin-bottom: 25px;
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
  }
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
  }
  .form-error {
    height: 35px;
    width: 100%;
    p {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }
  .submit-button {
    position: absolute;
    width: 400px;
    height: 50px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
`
