import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const ChangeSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  .close-change-button {
    position: absolute;
    top: 50px;
    right: 50px;
    width: 25px;
    height: 25px;
    border: none;
    outline: none;
    background: transparent;
    .close-icon {
      width: 100%;
      height: 100%;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`

export const TwitterSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 60%;
  margin-top: 50px;
  .twitter-or-container {
    width: 100%;
    margin-bottom: 40px;
    & > p {
      margin-bottom: 15px;
      font-size: 14px;
      font-weight: 400;
      color: ${colors.darkGray};
      & > button {
        width: 15px;
        height: 15px;
        border-radius: 50%;
        border: 1px solid ${colors.darkGray};
        outline: none;
        margin-left: 5px;
        color: ${colors.darkGray};
      }
    }
  }
  .and-not-container {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    width: 100%;
    margin-bottom: 40px;
    .and-container,
    .not-container {
      width: 49%;
      & > p {
        margin-bottom: 15px;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkGray};
        & > button {
          width: 15px;
          height: 15px;
          border-radius: 50%;
          border: 1px solid ${colors.darkGray};
          outline: none;
          margin-left: 5px;
          color: ${colors.darkGray};
        }
      }
    }
  }
  .buttons-container {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    height: 50px;
    .search-button {
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
`
