import React, { useContext, useState, useEffect } from 'react'
import { useQuery, useMutation, useLazyQuery } from '@apollo/client'

import SearchesContainer from '../../style/pages/search/Searches.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar, Spinner, Shadow } from '../../style/components'
import { BsArrowUpDown } from 'react-icons/bs'
import { FiFilter } from 'react-icons/fi'

import { SEARCHES_QUERY, GET_USER, GET_WORKSPACE } from '../../services/queries'
import {
  DELETE_SEARCH_MUTATION,
  DUPLICATE_SEARCH_MUTATION,
  MODIFY_ONBOARDING,
  UPDATE_SEARCH_MUTATION
} from '../../services/mutations'
import { SEARCHES_SUBSCRIPTION } from '../../services/subscriptions'

import SearchFilters from '../../components/search/searches/SearchFilters'
import AllSearches from '../../components/search/searches/AllSearches'
import Onboarding from '../../components/search/searches/OnboardingModal'
import DeleteSearch from '../../components/search/searches/DeleteSearch'
import SearchesOrder from '../../components/search/searches/SearchesOrder'
import { MyContext } from '../../context'
import EditSearch from '../../components/search/searches/EditSearch'
import DuplicateSearch from '../../components/search/searches/DuplicateSearch'
import Button from '../../components/ui/Button'
import ReportLimitWarning from '../../components/report/reports/ReportLimitWarning'
import LimitWarning from '../../components/search/new-search/LimitWarning'

const Searches = props => {
  const uiCTX = useContext(UIContext)
  const {
    mainClass,
    topbarClass,
    toolbarClass,
    state: { collapsed }
  } = uiCTX

  const { showNotifications, setNotificationText, ...context } = useContext(MyContext)
  const [limitWarning, setLimitWarning] = useState(null)

  const { data, loading, subscribeToMore, error } = useQuery(SEARCHES_QUERY, { fetchPolicy: 'network-only' })

  const [onboarding] = useMutation(MODIFY_ONBOARDING, {
    update(cache, { data: { modifyOnboarding } }) {
      cache.readQuery({ query: GET_USER })
      cache.writeQuery({
        query: GET_USER,
        data: {
          getUser: modifyOnboarding
        }
      })
    }
  })

  const [deletedSearch, { error: errorDeleteSearch }] = useMutation(DELETE_SEARCH_MUTATION, {
    update(cache, { data: { deletedSearch } }) {
      const { searches } = cache.readQuery({ query: SEARCHES_QUERY })
      cache.writeQuery({
        query: SEARCHES_QUERY,
        data: {
          searches: searches.filter(search => search.id !== deletedSearch.id)
        }
      })
    }
  })

  const [updateSearch] = useMutation(UPDATE_SEARCH_MUTATION, {
    update(cache, { data: { updateSearch } }) {
      const { searches } = cache.readQuery({ query: SEARCHES_QUERY })
      const index = searches.findIndex(search => search.id === updateSearch.id)
      cache.writeQuery({
        query: SEARCHES_QUERY,
        data: {
          searches: [...searches.slice(0, index), updateSearch, ...searches.slice(index + 1)]
        }
      })
    }
  })

  const [duplicateSearch] = useMutation(DUPLICATE_SEARCH_MUTATION, {
    onError: error => {
      if (error.message.includes('límite')) {
        setLimitWarning({ error: true, plan: error.message.split(' ')[0], quantity: error.message.split(' ')[1] })
      }
    }
  })

  const handleDuplicateSearch = async (id, name) => {
    await duplicateSearch({
      variables: {
        id,
        name
      }
    })
    setSearchToDuplicate(null)
  }

  const handleEditSearch = async (id, search) => {
    await updateSearch({
      variables: {
        id,
        input: {
          name: search.name
        }
      }
    })
    setSearchToEdit(null)
  }

  if (errorDeleteSearch) context.handleErrors(errorDeleteSearch)

  const [searchToDelete, setSearchToDelete] = useState(null)
  const [searchToEdit, setSearchToEdit] = useState(null)
  const [searchToDuplicate, setSearchToDuplicate] = useState(null)

  const [nameFilter, setNameFilter] = useState('')
  const [creatorFilter, setCreatorFilter] = useState('')
  const [fromFilter, setFromFilter] = useState('')
  const [toFilter, setToFilter] = useState('')
  const [sourceFilter, setSourceFilter] = useState([])

  const [nameAZOrder, setNameAZOrder] = useState(false)
  const [nameZAOrder, setNameZAOrder] = useState(false)
  const [recentDateOrder, setRecentDateOrder] = useState(true)
  const [oldDateOrder, setOldDateOrder] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [showOrder, setShowOrder] = useState(false)

  const resetFilters = () => {
    setNameFilter('')
    setCreatorFilter('')
    setFromFilter('')
    setToFilter('')
    setSourceFilter([])
  }

  const resetOrder = () => {
    setNameAZOrder(false)
    setNameZAOrder(false)
    setRecentDateOrder(true)
    setOldDateOrder(false)
  }

  const handleSourceFilter = source => {
    let sourceFilterArr = [...sourceFilter]
    if (source === 'medios') {
      if (sourceFilterArr.includes('medios')) {
        sourceFilterArr.splice(sourceFilterArr.indexOf('medios'), 1)
      } else {
        sourceFilterArr.push('medios')
      }
    }
    if (source === 'twitter') {
      if (sourceFilterArr.includes('twitter')) {
        sourceFilterArr.splice(sourceFilterArr.indexOf('twitter'), 1)
      } else {
        sourceFilterArr.push('twitter')
      }
    }
    setSourceFilter([...sourceFilterArr])
  }

  const getOnboarding = async () => {
    await onboarding({
      variables: { flag: false }
    })
  }

  const handleDeleteSearch = async id => {
    await deletedSearch({ variables: { id } })
    setSearchToDelete(null)
    showNotifications()
    setNotificationText(`La búsqueda <span><b>${searchToDelete.name}</b></span> fue eliminada con éxito.`)
  }

  const handleFiltersOrderOpen = type => {
    if (type === 'filter') {
      setShowFilters(!showFilters)
      setShowOrder(false)
    } else {
      setShowOrder(!showOrder)
      setShowFilters(false)
    }
  }

  useEffect(() => {
    subscribeToMore({
      document: SEARCHES_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        let newSearch = subscriptionData.data.searchChanged
        if (newSearch.operation === 'created') {
          return Object.assign({}, prev, {
            searches: [newSearch, ...prev.searches]
          })
        } else if (newSearch.operation === 'removed') {
          return Object.assign({}, prev, {
            searches: prev.searches.filter(search => search.id !== newSearch.id)
          })
        }
      }
    })
  }, [subscribeToMore])

  if (loading) return <Spinner />

  if (error) context.handleErrors(error)

  return (
    <MainContainer className={mainClass()}>
      <SearchesContainer>
        {showFilters && (
          <SearchFilters
            sidebar={collapsed}
            showFilters={setShowFilters}
            resetFilters={resetFilters}
            filters={{
              name: nameFilter,
              setName: setNameFilter,
              creator: creatorFilter,
              setCreator: setCreatorFilter,
              from: fromFilter,
              setFrom: setFromFilter,
              to: toFilter,
              setTo: setToFilter,
              source: sourceFilter,
              setSource: handleSourceFilter
            }}
          />
        )}

        {showOrder && (
          <SearchesOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShowOrder
            }}
          />
        )}

        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <h1>Búsquedas</h1>
            <Button
              size='medium'
              height='40px'
              theme='secondary'
              variant='outline'
              onClick={() => props.history.push('/searches/new-search')}
            >
              Nueva Búsqueda
            </Button>
          </div>
        </Topbar>

        <Toolbar className={toolbarClass()}>
          <div className='left-toolbar'></div>

          <div className='right-toolbar'>
            <button className='toolbar-button' onClick={() => handleFiltersOrderOpen('filter')}>
              <FiFilter className='toolbar-icon' />
              <p>Filtrar</p>
            </button>
            <button className='toolbar-button' onClick={() => handleFiltersOrderOpen('order')}>
              <BsArrowUpDown className='toolbar-icon gray-icon' />
              <p>Ordenar</p>
            </button>
          </div>
        </Toolbar>

        <AllSearches
          searches={error ? [] : data.searches}
          filtersOpen={showFilters}
          ordersOpen={showOrder}
          filters={{
            name: nameFilter,
            creator: creatorFilter,
            from: fromFilter,
            to: toFilter,
            source: sourceFilter
          }}
          order={{
            AtoZ: nameAZOrder,
            ZtoA: nameZAOrder,
            recentDate: recentDateOrder,
            oldDate: oldDateOrder
          }}
          setSearchToDelete={setSearchToDelete}
          setSearchToEdit={setSearchToEdit}
          setSearchToDuplicate={setSearchToDuplicate}
        />

        {props.user.onboarding && (
          <>
            <Shadow onClick={getOnboarding} />
            <Onboarding onboardingChange={getOnboarding} navigation={props.history} />
          </>
        )}

        {searchToDelete && (
          <>
            <Shadow onClick={() => setSearchToDelete(null)} />
            <DeleteSearch
              search={searchToDelete}
              setSearchToDelete={setSearchToDelete}
              deleteSearch={handleDeleteSearch}
            />
          </>
        )}

        {searchToEdit && (
          <>
            <Shadow onClick={() => setSearchToEdit(null)} />
            <EditSearch
              closeModal={() => setSearchToEdit(null)}
              search={searchToEdit}
              updateSearch={handleEditSearch}
            />
          </>
        )}

        {searchToDuplicate && (
          <>
            <Shadow onClick={() => setSearchToDuplicate(null)} />
            <DuplicateSearch
              closeModal={() => setSearchToDuplicate(null)}
              search={searchToDuplicate}
              duplicateSearch={handleDuplicateSearch}
            />
          </>
        )}
        {limitWarning && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <LimitWarning info={limitWarning} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </SearchesContainer>
    </MainContainer>
  )
}

export default Searches
