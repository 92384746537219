import styled from 'styled-components'
import { colors } from '../../../variables'

export const SearchBarContainer = styled.div`
  width: ${props => props.width};
  height: 50px;
  padding: 0 9px;
  border: 1px solid ${colors.darkGray};
  border-radius: 4px;
  background: ${colors.lightGray};
  overflow-y: auto;
  display: flex;
  align-items: center;
  &::-webkit-scrollbar {
    display: none;
  }
  :focus-within {
    .searchbar-placeholder {
      display: none;
    }
  }
  input {
    width: 100px;
    border: none;
    font-size: 15px;
    font-weight: 500;
    line-height: 30px;
    outline: none;
    caret-color: ${colors.darkGray};
    background: ${colors.lightGray};
  }
  .searchbar-placeholder {
    display: inline;
    font-size: 14px;
    font-weight: 400;
  }
`
