import React, { useContext, useState, useEffect } from 'react'

import ReportsContainer from '../../style/pages/report/Reports.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar, Spinner } from '../../style/components'

import { FiFilter } from 'react-icons/fi'
import { BsArrowUpDown } from 'react-icons/bs'
import { useQuery } from '@apollo/client'

import AllReports from '../../components/report/reports/AllReports'
import OnceReports from '../../components/report/reports/OnceReports'
import SheduledReports from '../../components/report/reports/ScheduledReports'
import ReportFilters from '../../components/report/reports/ReportFilters'
import ReportOrder from '../../components/report/reports/ReportOrder'

import { REPORTS_QUERY, TICKETS_QUERY } from '../../services/queries'
import { REPORTS_SUBSCRIPTION } from '../../services/subscriptions'
import { MyContext } from '../../context'
import Button from '../../components/ui/Button'

const Reports = props => {
  const context = useContext(MyContext)

  const uiCTX = useContext(UIContext)
  const {
    mainClass,
    topbarClass,
    toolbarClass,
    state: { collapsed }
  } = uiCTX

  const {
    data: reportsData,
    loading: reportsLoading,
    subscribeToMore: reportsSBS,
    refetch: refetchReports,
    error: reportsDataError
  } = useQuery(REPORTS_QUERY, { fetchPolicy: 'network-only' })

  const {
    data: ticketsData,
    loading: ticketsLoading,
    subscribeToMore: ticketsSBS,
    refetch: refetchTickets,
    error: ticketsDataError
  } = useQuery(TICKETS_QUERY, { fetchPolicy: 'network-only' })

  const [section, setSection] = useState('all')

  const [showAllFilters, setShowAllFilters] = useState(false)
  const [showOnceFilters, setShowOnceFilters] = useState(false)
  const [showScheduledFilters, setShowScheduledFilters] = useState(false)
  const [showAllOrder, setShowAllOrder] = useState(false)
  const [showOnceOrder, setShowOnceOrder] = useState(false)
  const [showScheduledOrder, setShowScheduledOrder] = useState(false)

  const [nameAF, setNameAF] = useState('')
  const [nameOF, setNameOF] = useState('')
  const [nameSF, setNameSF] = useState('')
  const [userAF, setUserAF] = useState('')
  const [userOF, setUserOF] = useState('')
  const [userSF, setUserSF] = useState('')
  const [fromAF, setFromAF] = useState('')
  const [fromOF, setFromOF] = useState('')
  const [toAF, setToAF] = useState('')
  const [toOF, setToOF] = useState('')
  const [typeAF, setTypeAF] = useState('')
  const [frequencySF, setFrecuencySF] = useState('')
  const [activeSF, setActiveSF] = useState('')
  const [pausedSF, setPausedSF] = useState('')

  //order states
  const [nameAZOrder, setNameAZOrder] = useState(false)
  const [nameZAOrder, setNameZAOrder] = useState(false)
  const [recentDateOrder, setRecentDateOrder] = useState(true)
  const [oldDateOrder, setOldDateOrder] = useState(false)

  const setAllSection = () => (section === 'all' ? 'section-button section-button-active' : 'section-button')
  const setOnceSection = () => (section === 'once' ? 'section-button section-button-active' : 'section-button')
  const setScheduledSection = () =>
    section === 'scheduled' ? 'section-button section-button-active' : 'section-button'

  const handleShowFilters = () => {
    if (section === 'all') setShowAllFilters(!showAllFilters)
    if (section === 'once') setShowOnceFilters(!showOnceFilters)
    if (section === 'scheduled') setShowScheduledFilters(!showScheduledFilters)
  }

  const handleShowOrder = () => {
    if (section === 'all') setShowAllOrder(!showAllOrder)
    if (section === 'once') setShowOnceOrder(!showOnceOrder)
    if (section === 'scheduled') setShowScheduledOrder(!showScheduledOrder)
  }

  const resetOrder = () => {
    setNameAZOrder(false)
    setNameZAOrder(false)
    setRecentDateOrder(true)
    setOldDateOrder(false)
  }

  useEffect(() => {
    reportsSBS({
      document: REPORTS_SUBSCRIPTION,
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const newReport = subscriptionData.data.reportCreated
        return Object.assign({}, prev, {
          reports: [newReport, ...prev.reports]
        })
      }
    })
    // ticketsSBS({
    //   document: TICKETS_SUBSCRIPTION,
    //   updateQuery: (prev, { subscriptionData }) => {
    //     console.log(subscriptionData)
    //     if (!subscriptionData.data) return prev
    //     const newTicket = subscriptionData.data.onTicketUpdate
    //     return Object.assign({}, prev, {
    //       reports: [newTicket, ...prev.reports]
    //     })
    //   }
    // })
  }, [reportsSBS, ticketsSBS])

  if (reportsDataError) context.handleErrors(reportsDataError)
  if (ticketsDataError) context.handleErrors(ticketsDataError)

  if (reportsLoading || ticketsLoading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <ReportsContainer>
        {showAllFilters && section === 'all' && (
          <ReportFilters
            sidebar={collapsed}
            showFilters={setShowAllFilters}
            filters={{
              needed: ['name', 'user', 'date', 'type'],
              name: nameAF,
              setName: setNameAF,
              user: userAF,
              setUser: setUserAF,
              type: typeAF,
              setType: setTypeAF,
              from: fromAF,
              setFrom: setFromAF,
              to: toAF,
              setTo: setToAF
            }}
          />
        )}

        {showOnceFilters && section === 'once' && (
          <ReportFilters
            sidebar={collapsed}
            showFilters={setShowOnceFilters}
            filters={{
              needed: ['name', 'user', 'date'],
              name: nameOF,
              setName: setNameOF,
              user: userOF,
              setUser: setUserOF,
              from: fromOF,
              setFrom: setFromOF,
              to: toOF,
              setTo: setToOF
            }}
          />
        )}

        {showScheduledFilters && section === 'scheduled' && (
          <ReportFilters
            sidebar={collapsed}
            showFilters={setShowScheduledFilters}
            filters={{
              needed: ['name', 'frecuency', 'active', 'paused'],
              name: nameSF,
              setName: setNameSF,
              user: userSF,
              setUser: setUserSF,
              frecuency: frequencySF,
              setFrecuency: setFrecuencySF,
              active: activeSF,
              setActive: setActiveSF,
              paused: pausedSF,
              setPaused: setPausedSF
            }}
          />
        )}

        {showAllOrder && section === 'all' && (
          <ReportOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShow: setShowAllOrder
            }}
          />
        )}

        {showOnceOrder && section === 'once' && (
          <ReportOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShow: setShowOnceOrder
            }}
          />
        )}

        {showScheduledOrder && section === 'scheduled' && (
          <ReportOrder
            resetOrder={resetOrder}
            orderStates={{
              nameAtoZ: nameAZOrder,
              setNameAZOrder,
              nameZtoA: nameZAOrder,
              setNameZAOrder,
              recentDate: recentDateOrder,
              setRecentDateOrder,
              oldDate: oldDateOrder,
              setOldDateOrder,
              setShow: setShowScheduledOrder
            }}
          />
        )}

        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <h1>Reportes</h1>
            <Button
              size='medium'
              height='40px'
              theme='secondary'
              variant='outline'
              onClick={() => props.history.push('/reports/new-report')}
            >
              Nuevo Reporte
            </Button>
          </div>
        </Topbar>

        <Toolbar className={toolbarClass()}>
          <div className='left-toolbar'>
            <button className={setAllSection()} onClick={() => setSection('all')}>
              Todos
            </button>
            <button className={setOnceSection()} onClick={() => setSection('once')}>
              Única vez
            </button>
            <button className={setScheduledSection()} onClick={() => setSection('scheduled')}>
              Programados
            </button>
          </div>

          <div className='right-toolbar'>
            <button className='toolbar-button' onClick={() => handleShowFilters()}>
              <FiFilter className='toolbar-icon' />
              <p>Filtrar</p>
            </button>
            <button className='toolbar-button' onClick={handleShowOrder}>
              <BsArrowUpDown className='toolbar-icon gray-icon' />
              <p>Ordenar</p>
            </button>
          </div>
        </Toolbar>

        {section === 'all' && (
          <AllReports
            reports={reportsDataError ? [] : reportsData.reports}
            refetchReports={refetchReports}
            filtersOpen={showAllFilters}
            filters={{
              name: nameAF,
              user: userAF,
              from: fromAF,
              to: toAF,
              type: typeAF
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder,
              recentDate: recentDateOrder,
              oldDate: oldDateOrder
            }}
          />
        )}

        {section === 'once' && (
          <OnceReports
            reports={reportsDataError ? [] : reportsData.reports}
            refetchReports={refetchReports}
            filtersOpen={showOnceFilters}
            filters={{
              name: nameOF,
              user: userOF,
              from: fromOF,
              to: toOF
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder,
              recentDate: recentDateOrder,
              oldDate: oldDateOrder
            }}
          />
        )}

        {section === 'scheduled' && (
          <SheduledReports
            reports={ticketsDataError ? [] : ticketsData.tickets}
            refetchReports={refetchReports}
            refetchTickets={refetchTickets}
            filtersOpen={showScheduledFilters}
            filters={{
              name: nameSF,
              user: userSF,
              frecuency: frequencySF,
              active: activeSF,
              paused: pausedSF
            }}
            order={{
              AtoZ: nameAZOrder,
              ZtoA: nameZAOrder,
              recentDate: recentDateOrder,
              oldDate: oldDateOrder
            }}
          />
        )}
      </ReportsContainer>
    </MainContainer>
  )
}

export default Reports
