import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { NewsContainer, DateButton } from '../../../style/components/search/search/News.styled'
import { useMutation } from '@apollo/client'
import { Spinner, Shadow } from '../../../style/components'
import Note from '../new-search/Note'
import ReportTestigo from './ReportTestigo'
import { AiOutlineCalendar } from 'react-icons/ai'

import { NEW_TESTIGO_REPORT_MUTATION } from '../../../services/mutations'
// import { BsCheckBox, BsSquare } from 'react-icons/bs'

const News = ({
  search,

  fetchData,

  newsQtyData,
  newsQtyLoading,

  data,
  loading,
  error,
  fetchMore,

  startDate,
  setStartDate,
  endDate,
  setEndDate,

  // setShowClippingModal,
  setNotes,
  notesA,
  checkAll,
  setCheckAll
}) => {
  const [page, setPage] = useState(2)
  const [loadingNews, setLoadingNews] = useState(false)
  const [testigo, setTestigo] = useState(null)
  const [motive, setMotive] = useState('')
  const [thanks, setThanks] = useState(false)
  const [newTestigoReport] = useMutation(NEW_TESTIGO_REPORT_MUTATION)

  const [verifiedDates, setVerifiedDate] = useState(true)

  const handleTestigoReport = async () => {
    await newTestigoReport({
      variables: {
        input: {
          id: testigo.id,
          motivo: motive,
          testigo: testigo.testigo,
          ambito: testigo.ambito,
          autor: testigo.autor,
          clasificacion: testigo.clasificacion,
          estado: testigo.estado,
          fecha: testigo.fecha,
          foda: testigo.foda,
          medio: testigo.medio,
          nombre_medio: testigo.nombre_medio,
          participacion: testigo.participacion,
          texto: testigo.texto,
          tipo_medio: testigo.tipo_medio,
          titulo: testigo.titulo
        }
      }
    })
    setThanks(true)
  }

  const handleCloseModal = () => {
    setTestigo(null)
    setMotive('')
    setThanks(false)
  }

  useEffect(() => {
    //Todo: Rethink component because useEffect was not build to use this way, if the component is going to change but you don't care then why have it? Yu know! jiji
    fetchData()
    //eslint-disable-next-line
  }, [])

  // if (checkAll) setNotes(data.news)

  const fetchMoreData = e => {
    const target = e.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (data.news.length === newsQtyData.newsQty) return
      setLoadingNews(true)
      fetchMore({
        variables: {
          input: {
            query: search.query,
            startDate,
            endDate,
            pagina: page
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingNews(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            news: [...prev.news, ...fetchMoreResult.news]
          })
        }
      })
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  const verifyDates = (name, value) => {
    if (name === 'startDate') {
      // moment(value).isBefore(endDate) &&
      if (moment(endDate).isBetween(value, moment(Date.now()), null, '[]')) {
        setStartDate(value)
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    } else if (name === 'endDate') {
      // moment(startDate).isBefore(value) &&
      if (moment(value).isBetween(startDate, moment(Date.now()), null, '[]')) {
        setEndDate(value)
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    }
  }

  const submitDateSearch = () => {
    // moment(startDate).isBefore(endDate) &&
    if (moment(endDate).isBetween(startDate, moment(Date.now()), null, '[]')) {
      fetchData()
    } else {
      console.log('no entro')
    }
  }

  if (checkAll) setNotes(data.news)

  if (newsQtyLoading || loading) return <Spinner />
  if (error) return <p>Hubo un error</p>

  return (
    <NewsContainer>
      {data && newsQtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-articles'>
              {newsQtyData.newsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>

            {/*<div className='results-clipping'>

              <div className='checkboxContainer'>
                {checkAll ?
                  <button
                    className='checkbox-indicator'
                    onClick={() => setCheckAll(false)}
                  >
                    <BsCheckBox className='checked' />
                  </button>
                  :
                  <button
                    className='checkbox-indicator'
                    onClick={() => setCheckAll(true)}
                  >
                    <BsSquare className='not-checked' />
                  </button>
                }
                <p>Todas</p>
              </div>

              <button
                disabled={!(notesA.length > 0)}
                className='exportButton'
                onClick={() => setShowClippingModal(true)}
              >
                Exportar Clipping
              </button>

            </div>
            */}

            <div className='results-period'>
              <AiOutlineCalendar />
              <input
                type='date'
                name='startDate'
                onKeyDown={e => e.preventDefault()}
                className='date-input'
                max={endDate}
                min={moment().subtract(1, 'months').format('YYYY-MM-DD')}
                value={startDate}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
              />
              a
              <input
                type='date'
                name='endDate'
                className='date-input'
                value={endDate}
                min={startDate}
                max={moment(moment()).format('YYYY-MM-DD')}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
              />
              <DateButton className='date-button' disabled={!verifiedDates} onClick={() => submitDateSearch()}>
                Buscar
              </DateButton>
            </div>
          </div>
          <div className='search-results' onScroll={fetchMoreData}>
            {data.news.map((article, idx) => (
              <Note
                key={idx}
                note={article}
                reportTestigo={setTestigo}
                setNotes={setNotes}
                notesA={notesA}
                allFlag={checkAll}
                setFlag={setCheckAll}
              />
            ))}
            {loadingNews && <Spinner />}
          </div>
        </>
      )}
      {testigo && (
        <>
          <Shadow onClick={() => handleCloseModal()} />
          <ReportTestigo
            closeModal={() => handleCloseModal()}
            motive={motive}
            handleMotive={setMotive}
            reportTestigo={handleTestigoReport}
            thanks={thanks}
          />
        </>
      )}
    </NewsContainer>
  )
}

export default News
