import React, { useContext } from 'react'

import NotificationContainer from '../../../style/components/workspace/Modals/NotificationContainer.styled'
import CloseModal from './CloseModal'
import { MyContext } from '../../../context'

const Notification = () => {
  const { showNotification, setShowNotification, notificationText } = useContext(MyContext)
  return (
    <NotificationContainer className={showNotification ? 'open' : ''}>
      <div className='close__modal'>
        <CloseModal
          onClick={() => {
            setShowNotification(false)
          }}
        />
      </div>
      <div className='notification__content'>
        <p className='notification__text' dangerouslySetInnerHTML={{ __html: notificationText }}></p>
      </div>
    </NotificationContainer>
  )
}

export default Notification
