import React from 'react'
import {
  NoAccessContainer,
  BackgroundContainer,
  MessageContainer
} from '../../../style/components/reports/reportDetails/NoAccessReport.styled'

const NoAccessReport = () => {
  return (
    <NoAccessContainer>
      <BackgroundContainer>
        <MessageContainer>
          <h3>Reporte no disponible</h3>
          <p>Lo sentimos pero la vigencia de la liga expiró y el reporte ya no está disponible.</p>
          <p>Te sugerimos solicitar nuevamente una liga de acceso.</p>
        </MessageContainer>
      </BackgroundContainer>
    </NoAccessContainer>
  )
}

export default NoAccessReport
