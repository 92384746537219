import React from 'react'
import { useHistory } from 'react-router-dom'

import { DashboardWarningContainer } from '../../../style/components/dashboard/new-dasboard/DashboardWarning.styled'
import { colors } from '../../../style/variables'
import Button from '../../ui/Button'
import CloseModal from '../../workspace/Modals/CloseModal'

const DashboardWarning = ({ info, closeModal }) => {
  const history = useHistory()

  return (
    <DashboardWarningContainer>
      {info.plan === 'Gratuito' && (
        <div>
          <CloseModal onClick={closeModal} />
          <h3>¡Atención!</h3>
          <p>
            Tu plan <span>{info.plan}</span> solo te permite generar <span>{info.quantity}</span> tablero. Te sugerimos
            actualizar a un plan de pago para contar con mayores beneficios y aprovechar el potencial de la plataforma.
          </p>
          <div>
            <Button theme={'primary'} size={'full'} onClick={() => history.push('/workspace?plans')}>
              Actualizar plan
            </Button>
          </div>
        </div>
      )}
      {info.plan !== 'Gratuito' && (
        <div>
          <h3>Límite de tableros</h3>
          <p>
            Has alcanzado el número máximo de <span>{info.quantity}</span> tableros para tu plan{' '}
            <span>{info.plan}</span> y no podrás guardar este tablero.
          </p>
          <div className='modal-buttons'>
            <Button weight={500} theme={'danger'} onClick={() => history.push('/dashboards')}>
              Eliminar tableros
            </Button>
            <Button weight={500} className='save-button' onClick={() => history.push('/workspace?plans')}>
              Subir de plan
            </Button>
          </div>
        </div>
      )}
    </DashboardWarningContainer>
  )
}

export default DashboardWarning
