import styled from 'styled-components'

const TeamMemberContainer = styled.div`
  .user__container {
    position: relative;
    padding-right: 24px;

    :hover {
      background-color: #eef2fb;
    }
  }
`

export default TeamMemberContainer
