import styled from 'styled-components'
import { colors } from '../../variables'

const NewReportContainer = styled.div`
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        text-decoration: none;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
  .main-report-container {
    display: flex;
    section {
      width: calc(100% / 2);
      height: calc(100vh - 70px);
      &.left-report-section {
        padding: 40px 40px 40px 0;
        border-right: 1px solid ${colors.lightGray};
        overflow-y: scroll;
      }
      &.right-report-section {
        padding: 40px 0 40px 40px;
        overflow-y: scroll;
        button {
          float: right;
        }
      }
    }
  }
`

export default NewReportContainer
