import React, { useEffect, useState } from 'react'
import { DeleteWidgetContainer } from '../../../style/components/dashboard/dashboard/DeleteWidget.styled'

import { GrClose } from 'react-icons/gr'
import Button from '../../ui/Button'

const DeleteWidget = ({ widget, setWidgetToDelete, deleteWidget, dashboard }) => {
  return (
    <DeleteWidgetContainer>
      <div className='filters--header'>
        <h3>¡Eliminar gráfico!</h3>
        <GrClose onClick={() => setWidgetToDelete(null)} />
      </div>
      <p>
        ¡Atención! Estás a punto de eliminar el gráfico <span>{widget.name}</span> vinculado al tablero
        <span> {dashboard.name}</span>, si lo haces perderás todas las propiedades asignadas.
      </p>

      <p>Esta acción no se puede reparar ¿Estás seguro que deseas eliminar el gráfico?</p>
      <div className='modal-buttons'>
        <Button weight={500} variant={'link'} theme={'dark'} onClick={() => setWidgetToDelete(null)}>
          Cancelar
        </Button>
        <Button
          variant={'outline'}
          weight={500}
          theme={'danger'}
          disabled={false}
          onClick={() => deleteWidget(widget.id)}
        >
          Eliminar
        </Button>
      </div>
    </DeleteWidgetContainer>
  )
}

export default DeleteWidget
