import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useMutation } from '@apollo/client'
import CreateBoardModal from '../CreateBoardModal'

import { GrClose } from 'react-icons/gr'

import { colors } from '../../../style/variables'
import { SaveSearchContainer } from '../../../style/components/search/new-serches/SaveSearch.styled'

import { SEARCHES_QUERY } from '../../../services/queries'
import { NEW_SEARCH_MUTATION } from '../../../services/mutations'
import Button from '../../ui/Button'

const SaveSearch = ({ closeSaveSearch, query, media, states, types, userInfo }) => {
  const history = useHistory()
  const [name, setName] = useState('')
  const [limitReached, setLimitReached] = useState({ error: false, plan: null, quantity: null })
  const [tutorialModal, setTutorialModal] = useState(false)

  const handleNameInput = e => setName(e.target.value)

  const [newSearch, { loading }] = useMutation(NEW_SEARCH_MUTATION, {
    update(cache, { data: { newSearch } }) {
      const { searches } = cache.readQuery({ query: SEARCHES_QUERY })
      cache.writeQuery({
        query: SEARCHES_QUERY,
        data: {
          searches: [...searches, newSearch]
        }
      })
    }
  })

  const saveSearch = flag => {
    newSearch({
      variables: {
        input: {
          name,
          source: media,
          query,
          states: states ? states.map(state => state.id) : [],
          media: types ? types : []
        }
      }
    })
      .then(() => {
        flag === false && history.push('/searches')
      })
      .catch(error => {
        if (error.message.includes('límite')) {
          const plan = error.message.split(' ')[0].toLowerCase()
          setLimitReached({
            error: true,
            plan: error.message.split(' ')[1],
            quantity: error.message.split(' ')[2]
          })
        }
      })
  }

  if (limitReached.error)
    return (
      <SaveSearchContainer>
        <h3>Límite de búsquedas</h3>
        <p>
          Has alcanzado el número máximo de <span>{limitReached.quantity}</span> búsquedas para tu plan{' '}
          <span>{limitReached.plan}</span> y no podrás guardar esta búsqueda.
        </p>
        <div className='modal-buttons'>
          <Button weight={500} theme={'dark'} variant={'link'} onClick={closeSaveSearch}>
            Cancelar
          </Button>
          <Button weight={500} theme={'danger'} onClick={() => history.push('/searches')}>
            Eliminar búsquedas
          </Button>
          <Button weight={500} onClick={() => history.push('/workspace?plans')}>
            Subir de plan
          </Button>
        </div>
      </SaveSearchContainer>
    )

  return (
    <SaveSearchContainer saveModal={closeSaveSearch}>
      <div className='filters--header'>
        <h3>Guarda tu búsqueda</h3>
        <GrClose onClick={() => closeSaveSearch(false)} />
      </div>
      <p>
        ¡Último paso! Nombrar tu búsqueda te ayudará a organizarte de manera efectiva para asignarla a gráficos y
        tableros. Podrás cambiar el nombre siempre que lo necesites.
      </p>
      <input placeholder='Nombre de tu búsqueda' value={name} onChange={handleNameInput} />
      <div className='modal-buttons'>
        <Button size={'medium'} height={'50px'} weight={500} variant={'link'} theme={'dark'} onClick={closeSaveSearch}>
          Cancelar
        </Button>
        <Button
          size={'medium'}
          height={'50px'}
          weight={500}
          theme={'secondary'}
          disabled={name && !loading ? false : true}
          onClick={() => {
            if (userInfo.onboarding === false) {
              saveSearch(false)
            } else {
              setTutorialModal(true)
              saveSearch(true)
            }
          }}
        >
          Guardar
        </Button>
      </div>
      {userInfo.onboarding === true && tutorialModal === true && (
        <>
          <CreateBoardModal name={name} query={query} />
        </>
      )}
    </SaveSearchContainer>
  )
}

export default SaveSearch
