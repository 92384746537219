import React, { useContext, useState } from 'react'
import { useHistory } from 'react-router-dom'
import NewMediaContainer from '../../style/pages/media/NewMedia.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Spinner, Topbar } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'

import { useMutation, useQuery } from '@apollo/client'

import { DASHBOARD_WIDGETS_QUERY, REQUESTED_MEDIA_QUERY } from '../../services/queries'
import { MEDIUM_REQUEST_MUTATION } from '../../services/mutations'
import Button from '../../components/ui/Button'

const NewMedia = () => {
  const history = useHistory()

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const [type, setType] = useState(null)
  const [typeError, setTypeError] = useState(false)
  const [subtype, setSubtype] = useState(null)
  const [name, setName] = useState('')
  const [nameError, setNameError] = useState(false)
  const [description, setDescription] = useState('')

  const { data, loading } = useQuery(REQUESTED_MEDIA_QUERY)
  const [mediumRequest] = useMutation(MEDIUM_REQUEST_MUTATION, {
    update(cache, { data: { mediumRequest } }) {
      const { requestedMedia } = cache.readQuery({
        query: REQUESTED_MEDIA_QUERY
      })
      cache.writeQuery({
        query: REQUESTED_MEDIA_QUERY,
        data: {
          requestedMedia: [...requestedMedia, mediumRequest]
        }
      })
    }
  })

  const saveMediumRequest = async () => {
    await mediumRequest({
      variables: {
        input: {
          name,
          description,
          type,
          subtype
        }
      }
    })
    setType(null)
    setSubtype(null)
    setName('')
    setDescription('')
  }

  if (loading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <NewMediaContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <Button
              width={'min'}
              height={'fit-content'}
              theme={'dark'}
              variant={'link'}
              className='back-link'
              onClick={() => history.push('/media-catalogue')}
            >
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </Button>
            <h1>Solicitud de alta de medio</h1>
          </div>
        </Topbar>

        <div className='content'>
          <section className='requested'>
            <div className='info'>
              <p>
                Con MyOblek puedes levantar una solicitud para agregar el medio que necesitas monitorear en caso de que
                no lo tengamos en nuestro catálogo. Sólo necesitas llenar el formulario.
              </p>
            </div>
            <h2>Medios solicitados</h2>
            <p className='requested-copy'>
              Antes de hacer tu petición, puedes revisar en nuestra lista si el medio que buscas ya fue solicitado con
              anterioridad.
            </p>

            <div className='media-table-body'>
              {data && data.requestedMedia.length === 0 ? (
                <div className='media-table-row'>
                  <div className='searchCont'>
                    <p>No hay medios solicitados</p>
                  </div>
                </div>
              ) : (
                <>
                  {data.requestedMedia.map(medium => (
                    <div className='media-table-row' key={medium.id}>
                      <div className='searchCont'>
                        <p>{medium.name}</p>
                        <p>{medium.type === 'traditional' ? 'Tradicional' : 'Digital'}</p>
                      </div>
                    </div>
                  ))}
                </>
              )}
            </div>
          </section>
          <section className='request'>
            <h2>Inicia tu solicitud</h2>
            <p className='request-copy'>Si no encontraste tu medio inicia el registro.</p>
            <h3>Tipo de medio</h3>
            <div className='request-boxes'>
              <label onClick={() => setType('traditional')}>
                <input type='checkbox' checked={type === 'traditional'} readOnly />
                Tradicional
              </label>
              <label
                onClick={() => {
                  setType('digital')
                  setSubtype(null)
                }}
              >
                <input type='checkbox' checked={type === 'digital'} readOnly />
                Digital
              </label>
            </div>
            {type === 'traditional' && (
              <>
                <h3>Subtipo</h3>
                <div className='request-boxes margin'>
                  <label onClick={() => setSubtype('newspaper')}>
                    <input type='checkbox' checked={subtype === 'newspaper'} readOnly />
                    Periódico
                  </label>
                  <label onClick={() => setSubtype('magazine')}>
                    <input type='checkbox' checked={subtype === 'magazine'} readOnly />
                    Revista
                  </label>
                  <label onClick={() => setSubtype('radio')}>
                    <input type='checkbox' checked={subtype === 'radio'} readOnly />
                    Radio
                  </label>
                  <label onClick={() => setSubtype('tv')}>
                    <input type='checkbox' checked={subtype === 'tv'} readOnly />
                    Televisión
                  </label>
                </div>
              </>
            )}
            <div className='request-form'>
              <label>Nombre del medio</label>
              <input
                value={name}
                onChange={e => setName(e.target.value)}
                onBlur={() => (name === '' ? setNameError(true) : setNameError(false))}
                onKeyUp={() => (name === '' ? setNameError(true) : setNameError(false))}
              />
              <div className='name__error'>
                <p className={nameError ? 'name__error__text' : 'name__error__transparent'}>Campo obligatorio</p>
              </div>
              <label>Descripción y/o referencias</label>
              <textarea value={description} onChange={e => setDescription(e.target.value)} />
            </div>
            <Button
              width='100%'
              height='47px'
              disabled={name === '' || type === null || (type === 'traditional' && subtype === null)}
              onClick={() => saveMediumRequest()}
            >
              Enviar solicitud
            </Button>
          </section>
        </div>
      </NewMediaContainer>
    </MainContainer>
  )
}

export default NewMedia
