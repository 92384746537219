import styled from 'styled-components'
import { colors } from '../../../variables'

export const MineDashboardOrderContainer = styled.div`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
  justify-content: space-between;
  h1 {
    font-size: 16px;
    font-weight: 600;
    cursor: pointer;
  }
  justify-content: space-between;
  button {
    border: none;
    background: none;
    outline: none;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
  }
  .order--options {
    z-index: 5;
    position: absolute;
    top: 0;
    right: 0;
    width: 175px;
    height: 200px;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .sub_options {
      height: 50px;
      .size-button {
        display: flex;
        align-items: center;
        width: 100%;
        height: 50px;
        color: ${colors.darkGray};
        :hover {
          background: ${colors.lightGray};
        }
      }
    }
  }
`

export const ButtonDiv = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  color: ${props => (props.selected ? colors.lightseaGreen : colors.darkGray)};
  & svg {
    width: 20px;
    height: 20px;
    margin-left: 5px;
  }
`
