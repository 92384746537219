import React from 'react'
import { useHistory } from 'react-router-dom'
import { NoReportsContainer } from '../../../style/components/reports/reports/NoReports.styled'

import { Report } from '../../../assets/Flows'
import Button from '../../ui/Button'

const NoReports = ({ filtersOpen }) => {
  const history = useHistory()

  return (
    <NoReportsContainer filtersOpen={filtersOpen}>
      <div className='no-reports--icon'>
        <Report />
      </div>
      <p>¡Crea un reporte ahora mismo!</p>
      <Button
        className='butonReports'
        height='40px'
        width={'560px'}
        theme='primary'
        variant='solid'
        onClick={() => history.push('/reports/new-report')}
      >
        Nuevo reporte
      </Button>
    </NoReportsContainer>
  )
}

export default NoReports
