import React, { useContext, useState, useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { useLazyQuery } from '@apollo/client'

import NewSearchContainer from '../../style/pages/search/NewSearch.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Shadow } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'

import InitialSearch from '../../components/search/new-search/InitialSearch'
import TraditionalSearch from '../../components/search/new-search/TraditionalSearch'
import TwitterSearch from '../../components/search/new-search/TwitterSearch'
import LimitWarning from '../../components/search/new-search/LimitWarning'

import { estados } from '../../data/Filters'

import { GET_WORKSPACE, NEWS_QTY_QUERY, NEWS_QUERY, TWEETS_QTY_QUERY, TWEETS_QUERY } from '../../services/queries'
import { MyContext } from '../../context'

const NewSearch = ({ user }) => {
  const history = useHistory()

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const context = useContext(MyContext)

  /* ----- STATE ----- */

  // media
  const [step, setStep] = useState('initial')
  const [media, setMedia] = useState([])

  // parameters
  const [andParams, setAndParams] = useState([])
  const [orParams, setOrParams] = useState([])
  const [notParams, setNotParams] = useState([])
  const [twOrParams, setTwOrParams] = useState([])
  const [twAndParams, setTwAndParams] = useState([])
  const [twNotParams, setTwNotParams] = useState([])

  // inputs
  const [andWord, setAndWord] = useState('')
  const [orWord, setOrWord] = useState('')
  const [notWord, setNotWord] = useState('')
  const [twOrWord, setTwOrWord] = useState('')
  const [twAndWord, setTwAndWord] = useState('')
  const [twNotWord, setTwNotWord] = useState('')
  //date inputs
  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  // filters
  const [coverage, setCoverage] = useState('')
  const [states, setStates] = useState([])
  const [type, setType] = useState([])
  const [genders, setGenders] = useState([])
  const [participation, setParticipation] = useState(['declarante', 'referente', 'circunstancial'])

  // queries
  const [traditionalQuery, setTraditionalQuery] = useState('')
  const [twitterQuery, setTwitterQuery] = useState('')

  // pagination
  const [page, setPage] = useState(2)
  const [loadingNews, setLoadingNews] = useState(false)
  const [tweetsPage, setTweetsPage] = useState(1)
  const [loadingTweets, setLoadingTweets] = useState(false)

  // limits
  const [limitWarning, setLimitWarning] = useState(null)

  /* ----- LOGIC TO LIMIT SEARCHES ----- */

  const [getLimit] = useLazyQuery(GET_WORKSPACE, {
    fetchPolicy: 'network-only',
    onCompleted: ({ getWorkspace: { searches, plan } }) => {
      if (searches.length >= plan.searchesQty) {
        setLimitWarning({ error: true, plan: plan.type, quantity: plan.searchesQty })
      }
    }
  })

  useEffect(() => {
    getLimit()
    // eslint-disable-next-line
  }, [])

  /* ----- LOGIC TO HANDLE QUERIES ----- */

  const [getNews, { data: newsData, loading: newsLoading, error: newsError, fetchMore }] = useLazyQuery(NEWS_QUERY, {
    fetchPolicy: 'network-only'
  })
  const [getNewsQty, { data: newsQtyData, loading: newsQtyLoading }] = useLazyQuery(NEWS_QTY_QUERY)

  if (newsError) {
    if (newsError.message.includes('uniqueSession')) context.handleErrors(newsError)
  }

  const getQuery = () => {
    const orQuery = orParams.join(',')
    const andQuery = andParams.length > 0 ? ':' + andParams.join(':') : ''
    const notQuery = notParams.length > 0 ? ';' + notParams.join(';') : ''
    return orQuery + andQuery + notQuery
  }

  const makeTraditionalSearch = () => {
    const query = getQuery()
    setTraditionalQuery(query)
    getNews({
      variables: {
        input: {
          query,
          startDate,
          endDate,
          medio: type,
          cobertura: states.map(state => state.id),
          genero: genders.map(gender => gender.id),
          pagina: 1
        }
      }
    })
    getNewsQty({
      variables: {
        input: {
          query,
          startDate,
          endDate,
          medio: type,
          cobertura: states.map(state => state.id),
          genero: genders.map(gender => gender.id)
        }
      }
    })
  }

  useEffect(() => {
    makeTraditionalSearch()
    // eslint-disable-next-line
  }, [media, orParams, startDate, endDate])

  const fetchMoreData = e => {
    const query = getQuery()
    setTraditionalQuery(query)
    const target = e.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (newsData.news.length === newsQtyData.newsQty) return
      setLoadingNews(true)
      fetchMore({
        variables: {
          input: {
            query,
            startDate: moment().subtract(1, 'days').format('YYYY-MM-DD'),
            endDate: moment().format('YYYY-MM-DD'),
            medio: type,
            cobertura: states.map(state => state.id),
            genero: genders.map(gender => gender.id),
            pagina: page
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingNews(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            news: [...prev.news, ...fetchMoreResult.news]
          })
        }
      })
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  const [getTweets, { data: tweetsData, loading: tweetsLoading, error: tweetsError, fetchMore: fetchMoreTweets }] =
    useLazyQuery(TWEETS_QUERY)
  const [getTweetsQty, { data: tweetsQtyData, loading: tweetsQtyLoading }] = useLazyQuery(TWEETS_QTY_QUERY)

  if (tweetsError) {
    if (tweetsError.message.includes('uniqueSession')) context.handleErrors(tweetsError)
  }

  const getTwitterQuery = () => {
    const orArr = []
    twOrParams.forEach(param => {
      if (param.split('').includes('@')) {
        orArr.push(param.replace(/\s/, '').replace(/@/, ''))
      } else if (param.split('').includes('#')) {
        orArr.push(`"${param}"`)
      } else {
        orArr.push(`"${param}"`)
      }
    })
    const orQuery = orArr.join(' OR ')

    const andArr = []
    twAndParams.forEach(param => {
      if (param.split('').includes('@')) {
        andArr.push(param.replace(/\s/, '').replace(/@/, ''))
      } else if (param.split('').includes('#')) {
        andArr.push(`"${param}"`)
      } else {
        andArr.push(`"${param}"`)
      }
    })
    const andQuery = andArr.length > 0 ? ' AND ' + andArr.join(' OR ') : ''

    const notArr = []
    twNotParams.forEach(param => {
      if (param.split('').includes('@')) {
        notArr.push(param.replace(/\s/, '').replace(/@/, ''))
      } else if (param.split('').includes('#')) {
        notArr.push(`"${param}"`)
      } else {
        notArr.push(`"${param}"`)
      }
    })
    const notQuery = notArr.length > 0 ? ' AND NOT ' + notArr.join(' OR ') : ''

    return orQuery + andQuery + notQuery
  }

  const makeTwitterSearch = () => {
    const query = getTwitterQuery()
    setTwitterQuery(query)
    getTweets({
      variables: {
        input: {
          query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
    getTweetsQty({
      variables: {
        input: {
          query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
  }

  useEffect(() => {
    makeTwitterSearch()
    // eslint-disable-next-line
  }, [twOrParams, twAndParams, twNotParams, startDate, endDate])

  const fetchMoreTweetsData = e => {
    const target = e.target
    const query = getTwitterQuery()
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (tweetsData.tweets.length === tweetsQtyData.tweetsQty) return
      setLoadingTweets(true)
      fetchMoreTweets({
        variables: {
          input: {
            query,
            startDate: moment(startDate).format('DD-MM-YYYY'),
            endDate: moment(endDate).format('DD-MM-YYYY'),
            pagina: tweetsPage
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingTweets(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            tweets: [...prev.tweets, ...fetchMoreResult.tweets]
          })
        }
      })
      const nextPage = tweetsPage + 1
      setTweetsPage(nextPage)
    }
  }

  /* ----- FUNCTION TO HANDLE MEDIA TYPE ----- */

  const handleMedia = type => {
    if (type === 'twitter') setMedia(['twitter'])
    if (type === 'medios') setMedia(['medios'])
  }

  /* ----- FUNCTIONS TO HANDLE TRADITIONAL SEARCH ----- */

  // Functions to handle inputs
  const handleAndInput = e => setAndWord(e.target.value)
  const handleOrInput = e => setOrWord(e.target.value)
  const handleNotInput = e => setNotWord(e.target.value)

  // Function to add words
  const addAndWord = e => {
    if (e.keyCode === 13 && andWord.trim() !== '') {
      setAndParams([...andParams, andWord.trim()])
      setAndWord('')
    }
  }

  const addOrWord = async e => {
    if (e.keyCode === 13 && orWord.trim() !== '') {
      setOrParams([...orParams, orWord.trim()])
      await setOrWord('')
    }
  }

  const addNotWord = e => {
    if (e.keyCode === 13 && notWord.trim() !== '') {
      setNotParams([...notParams, notWord.trim()])
      setNotWord('')
    }
  }

  // Functions to delete parameters
  const deleteAndParam = index => setAndParams(andParams.filter((element, idx) => element[idx] !== element[index]))
  const deleteOrParam = index => setOrParams(orParams.filter((element, idx) => element[idx] !== element[index]))
  const deleteNotParam = index => setNotParams(notParams.filter((element, idx) => element[idx] !== element[index]))

  // Functions to handle filters

  const resetFilters = () => {
    setNotParams([])
    setAndParams([])
    setCoverage('')
    setStates([])
    setType([])
  }

  const handleCoverage = scope => {
    if (scope === coverage) {
      setCoverage('')
      setStates([])
      return
    }
    if (scope === 'internacional') {
      setCoverage('internacional')
      setStates([{ id: 1, name: 'Países' }])
    } else if (scope === 'nacional') {
      setCoverage('nacional')
      setStates(estados)
    } else if (scope === 'estatal') {
      setCoverage('estatal')
      setStates([])
    }
  }

  const handleCatalogueTypesButtons = media => {
    let catalogueTypesArr = [...type]
    if (media === 1) {
      if (catalogueTypesArr.includes(1)) {
        catalogueTypesArr.splice(catalogueTypesArr.indexOf(1), 1)
      } else {
        catalogueTypesArr.push(1)
      }
    }
    if (media === 2) {
      if (catalogueTypesArr.includes(2)) {
        catalogueTypesArr.splice(catalogueTypesArr.indexOf(2), 1)
      } else {
        catalogueTypesArr.push(2)
      }
    }
    if (media === 3) {
      if (catalogueTypesArr.includes(3)) {
        catalogueTypesArr.splice(catalogueTypesArr.indexOf(3), 1)
      } else {
        catalogueTypesArr.push(3)
      }
    }
    if (media === 4) {
      if (catalogueTypesArr.includes(4)) {
        catalogueTypesArr.splice(catalogueTypesArr.indexOf(4), 1)
      } else {
        catalogueTypesArr.push(4)
      }
    }
    if (media === 8) {
      if (catalogueTypesArr.includes(8)) {
        catalogueTypesArr.splice(catalogueTypesArr.indexOf(8), 1)
      } else {
        catalogueTypesArr.push(8)
      }
    }
    // if (media=== 'todos') {
    //   catalogueTypesArr = ['prensa', 'revistas', 'radio', 'tv', 'web']
    // }
    setType(catalogueTypesArr)
  }

  const handleParticipationCheckbox = checkbox => {
    let participationArr = [...participation]
    if (checkbox === 'declarante') {
      if (participationArr.includes('declarante')) {
        participationArr.splice(participationArr.indexOf('declarante'), 1)
      } else {
        participationArr.push('declarante')
      }
    }
    if (checkbox === 'referente') {
      if (participationArr.includes('referente')) {
        participationArr.splice(participationArr.indexOf('referente'), 1)
      } else {
        participationArr.push('referente')
      }
    }
    if (checkbox === 'circunstancial') {
      if (participationArr.includes('circunstancial')) {
        participationArr.splice(participationArr.indexOf('circunstancial'), 1)
      } else {
        participationArr.push('circunstancial')
      }
    }
    setParticipation(participationArr)
  }

  /* ----- FUNCTIONS TO HANDLE TWITTER SEARCH ----- */

  // Functions to handle inputs
  const handleTwitterOrInput = e => setTwOrWord(e.target.value)
  const handleTwitterAndInput = e => setTwAndWord(e.target.value)
  const handleTwitterNotInput = e => setTwNotWord(e.target.value)

  // Functions to add words
  const addTwitterOrWord = e => {
    if (e.keyCode === 13 && twOrWord.trim() !== '') {
      setTwOrParams([...twOrParams, twOrWord])
      setTwOrWord('')
      setTweetsPage(1)
    }
  }

  const addTwitterAndWord = e => {
    if (e.keyCode === 13 && twAndWord.trim() !== '') {
      setTwAndParams([...twAndParams, twAndWord])
      setTwAndWord('')
      setTweetsPage(1)
    }
  }

  const addTwitterNotWord = e => {
    if (e.keyCode === 13 && twNotWord.trim() !== '') {
      setTwNotParams([...twNotParams, twNotWord])
      setTwNotWord('')
      setTweetsPage(1)
    }
  }

  // Functions to delete parameters
  const deleteTwitterOrParam = index => {
    setTwOrParams(twOrParams.filter((element, idx) => element[idx] !== element[index]))
    setTweetsPage(1)
  }

  const deleteTwitterAndParam = index => {
    setTwAndParams(twAndParams.filter((element, idx) => element[idx] !== element[index]))
    setTweetsPage(1)
  }

  const deleteTwitterNotParam = index => {
    setTwNotParams(twNotParams.filter((element, idx) => element[idx] !== element[index]))
    setTweetsPage(1)
  }

  // Functions to handle dropdown menu
  const setFrom = e => {
    const {
      target: { id }
    } = e
    if (id === 'twOrWord') setTwOrWord('from: @')
    if (id === 'twAndWord') setTwAndWord('from: @')
    if (id === 'twNotWord') setTwNotWord('from: @')
  }

  const setTo = e => {
    const {
      target: { id }
    } = e
    if (id === 'twOrWord') setTwOrWord('to: @')
    if (id === 'twAndWord') setTwAndWord('to: @')
    if (id === 'twNotWord') setTwNotWord('to: @')
  }

  const setHashtag = e => {
    const {
      target: { id }
    } = e
    if (id === 'twOrWord') setTwOrWord('#')
    if (id === 'twAndWord') setTwAndWord('#')
    if (id === 'twNotWord') setTwNotWord('#')
  }

  const resetValuesInitialStep = () => {
    setStep('initial')
    setStartDate(moment().subtract(1, 'months').format('YYYY-MM-DD'))
    setEndDate(moment().format('YYYY-MM-DD'))
  }

  return (
    <MainContainer className={mainClass()}>
      <NewSearchContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <button
              className='back-link'
              onClick={() => (step === 'initial' ? history.push('/searches') : resetValuesInitialStep())}
            >
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </button>
            <h1>Nueva búsqueda</h1>
          </div>
        </Topbar>

        {step === 'initial' && (
          <InitialSearch
            media={media}
            setMedia={handleMedia}
            setStep={setStep}
            orParams={orParams}
            twOrParams={twOrParams}
            twAndParams={twAndParams}
            twNotParams={twNotParams}
            orWord={orWord}
            twOrWord={twOrWord}
            twAndWord={twAndWord}
            twNotWord={twNotWord}
            handleOrInput={handleOrInput}
            handleTwitterOrInput={handleTwitterOrInput}
            handleTwitterAndInput={handleTwitterAndInput}
            handleTwitterNotInput={handleTwitterNotInput}
            addOrWord={addOrWord}
            addTwitterOrWord={addTwitterOrWord}
            addTwitterAndWord={addTwitterAndWord}
            addTwitterNotWord={addTwitterNotWord}
            deleteOrParam={deleteOrParam}
            deleteTwitterOrParam={deleteTwitterOrParam}
            deleteTwitterAndParam={deleteTwitterAndParam}
            deleteTwitterNotParam={deleteTwitterNotParam}
            setFrom={setFrom}
            setTo={setTo}
            setHashtag={setHashtag}
            user={user}
            makeTraditionalSearch={makeTraditionalSearch}
            makeTwitterSearch={makeTwitterSearch}
          />
        )}

        {step === 'medios' && (
          <TraditionalSearch
            andParams={andParams}
            orParams={orParams}
            notParams={notParams}
            andWord={andWord}
            orWord={orWord}
            notWord={notWord}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            handleAndInput={handleAndInput}
            handleOrInput={handleOrInput}
            handleNotInput={handleNotInput}
            addAndWord={addAndWord}
            addOrWord={addOrWord}
            addNotWord={addNotWord}
            deleteAndParam={deleteAndParam}
            deleteOrParam={deleteOrParam}
            deleteNotParam={deleteNotParam}
            coverage={coverage}
            states={states}
            media={media}
            type={type}
            genders={genders}
            startDate={startDate}
            endDate={endDate}
            participation={participation}
            handleCoverage={handleCoverage}
            handleStates={setStates}
            handleType={handleCatalogueTypesButtons}
            handleGenders={setGenders}
            handleParticipation={handleParticipationCheckbox}
            makeTraditionalSearch={makeTraditionalSearch}
            resetFilters={resetFilters}
            data={newsData}
            loading={newsLoading}
            error={newsError}
            qtyData={newsQtyData}
            qtyLoading={newsQtyLoading}
            query={traditionalQuery}
            fetchMoreData={fetchMoreData}
            loadingNews={loadingNews}
            user={user}
          />
        )}

        {step === 'twitter' && (
          <TwitterSearch
            twOrParams={twOrParams}
            twAndParams={twAndParams}
            twNotParams={twNotParams}
            twOrWord={twOrWord}
            twAndWord={twAndWord}
            twNotWord={twNotWord}
            setStartDate={setStartDate}
            setEndDate={setEndDate}
            startDate={startDate}
            endDate={endDate}
            handleTwitterOrInput={handleTwitterOrInput}
            handleTwitterAndInput={handleTwitterAndInput}
            handleTwitterNotInput={handleTwitterNotInput}
            addTwitterOrWord={addTwitterOrWord}
            addTwitterAndWord={addTwitterAndWord}
            addTwitterNotWord={addTwitterNotWord}
            deleteTwitterOrParam={deleteTwitterOrParam}
            deleteTwitterAndParam={deleteTwitterAndParam}
            deleteTwitterNotParam={deleteTwitterNotParam}
            setFrom={setFrom}
            setTo={setTo}
            setHashtag={setHashtag}
            makeTwitterSearch={makeTwitterSearch}
            data={tweetsData}
            loading={tweetsLoading}
            error={tweetsError}
            qtyData={tweetsQtyData}
            qtyLoading={tweetsQtyLoading}
            query={twitterQuery}
            media={media}
            fetchMoreData={fetchMoreTweetsData}
            loadingTweets={loadingTweets}
            user={user}
          />
        )}

        {limitWarning && limitWarning.error && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <LimitWarning info={limitWarning} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </NewSearchContainer>
    </MainContainer>
  )
}

export default NewSearch
