import React from 'react'

const CatalogueIcon = () => {
  return (
    <svg width='22px' height='23px' viewBox='0 0 22 23' id='catalogue'>
      <g stroke='none' strokeWidth='1' fill='none' fillRule='evenodd' strokeLinecap='round' strokeLinejoin='round'>
        <g transform='translate(-1.000000, 0.000000)' stroke='#00AAAB'>
          <path
            d='M8.1,7.1 L20.3,7.1 C21.1,7.1 21.8,7.8 21.8,8.6 L21.8,20.8 C21.8,21.6 21.1,22.3 20.3,22.3 L8.1,22.3 C7.3,22.3 6.6,21.6 6.6,20.8 L6.6,8.7 C6.5,7.8 7.2,7.1 8.1,7.1 Z M19.5,7.1 L19.5,5.6 C19.5,4.8 18.8,4.1 18,4.1 L5.8,4.1 C4.9,4.1 4.2,4.8 4.2,5.6 L4.2,17.8 C4.2,18.6 4.9,19.3 5.7,19.3 L6.5,19.3 L6.5,19.3 M17.2,4.1 L17.2,2.5 C17.2,1.7 16.5,1 15.7,1 L3.5,1 C2.7,1 2,1.7 2,2.5 L2,14.7 C2,15.5 2.7,16.2 3.5,16.2 L4.3,16.2 L4.3,16.2'
            id='icon-catalogo'
          ></path>
        </g>
      </g>
    </svg>
  )
}

export default CatalogueIcon
