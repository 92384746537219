import styled from 'styled-components'
import { colors } from '../../variables'

export const ErrorGMobileModal = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  padding: 30px;
  border-radius: 20px;
  background: ${colors.white};

  h3 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }
  button {
    margin-top: 25px;
  }
`
