import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const DuplicateWidgetContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 711px;
  padding: 30px 40px 50px;
  border-radius: 4px;
  background: ${colors.white};
  .widgetinfo__title {
    margin-bottom: 23px;
    font-size: 24px;
    font-weight: 800;
    line-height: 29.26px;
    color: ${colors.richBlack};
  }   
  }
  .widgetinfo__buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
  p {
    margin-bottom: 30px;
    font-size: 18px;
    font-weight: 400;
    line-height: 26px;
  }
`
