import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const WidgetContainer = styled.div`
  background: ${colors.white};
  width: ${props => props.width};
  height: 450px;
  margin: 0 1% 25px;
  padding: 15px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  .widget--header {
    display: flex;
    justify-content: space-between;
    h1 {
      font-size: 16px;
      font-weight: 600;
      cursor: pointer;
    }
    justify-content: space-between;
    .widget--menu {
      position: relative;
      button {
        border: none;
        background: none;
        outline: none;
        width: 25px;
        height: 25px;
        border-radius: 4px;
        cursor: pointer;
        svg {
          width: 100%;
          height: 100%;
        }
      }
      .widget--options {
        z-index: 5;
        position: absolute;
        top: 0;
        right: 0;
        width: 175px;
        height: 200px;
        background: ${colors.white};
        box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        .full_option {
          height: 50px;
          width: 100%;
          display: flex;
          align-items: center;
          font-size: 14px;
          font-weight: 500;
          color: ${colors.darkGray};
          padding-left: 40px;
          :hover {
            background: ${colors.lightGray};
          }
          svg {
            width: 20px;
            height: 20px;
            margin-right: 10px;
          }
        }
        .delete_button {
          color: ${colors.red};
        }
        .sub_options {
          height: 50px;
          .size-button {
            width: 25%;
            height: 50px;
            color: ${colors.darkGray};
            svg {
              width: 20px;
              height: 20px;
            }
            :hover {
              background: ${colors.lightGray};
            }
          }
        }
      }
    }
    .widget-title {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 250px;
      .edit-icon {
        margin-left: 10px;
        color: ${colors.darkGray};
      }
      .change-name {
        height: 100%;
        width: 180px;
        margin-right: 10px;
        border-radius: 4px;
        padding: 0 10px;
        border: 1px solid ${colors.darkGray};
      }
      .save-name {
        height: 100%;
        padding: 0 5px;
        border: none;
        background: ${colors.purple};
        color: ${colors.white};
        cursor: pointer;
        outline: none;
        border-radius: 4px;
      }
    }
  }
  &:hover {
    h1 {
      border-bottom: 1px solid ${colors.darkGray};
    }
  }
`
