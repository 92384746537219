import React from 'react'

const ProfileIcon = () => (
  <svg>
    <g fillRule='evenodd'>
      <path d='M22,24 C22,24 24,24 24,22 C24,20 22,14 12,14 C2,14 0,20 0,22 C0,24 2,24 2,24 L22,24 Z M2.044,22 L21.956,22 C21.9653333,21.9989333 21.9746667,21.9976 21.984,21.996 L22,21.992 C21.998,21.5 21.692,20.02 20.336,18.664 C19.032,17.36 16.578,16 12,16 C7.42,16 4.968,17.36 3.664,18.664 C2.308,20.02 2.004,21.5 2,21.992 C2.01464,21.9948 2.02930667,21.9974667 2.044,22 Z M12,10 C13.0608,10 14.0782667,9.57853333 14.8284,8.82842667 C15.5785333,8.07828 16,7.06086667 16,6 C16,4.93913333 15.5785333,3.92172 14.8284,3.17157333 C14.0782667,2.42142667 13.0608,2 12,2 C10.9392,2 9.92173333,2.42142667 9.17157333,3.17157333 C8.42142667,3.92172 8,4.93913333 8,6 C8,7.06086667 8.42142667,8.07828 9.17157333,8.82842667 C9.92173333,9.57853333 10.9392,10 12,10 Z M18,6 C18,7.59129333 17.3678667,9.11742667 16.2426667,10.2426667 C15.1174667,11.3678667 13.5913333,12 12,12 C10.4086667,12 8.88257333,11.3678667 7.75736,10.2426667 C6.63214667,9.11742667 6,7.59129333 6,6 C6,4.40870667 6.63214667,2.88257333 7.75736,1.75736 C8.88257333,0.632146667 10.4086667,0 12,0 C13.5913333,0 15.1174667,0.632146667 16.2426667,1.75736 C17.3678667,2.88257333 18,4.40870667 18,6 Z' />
    </g>
  </svg>
)

export default ProfileIcon
