import styled from 'styled-components'
import { colors } from '../../../variables'

export const UserDataContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
  width: 70vw;
  justify-content: space-between;
  .left-information {
    max-width: 525px;
    width: 50%;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 30px;
    }
    p {
      line-height: 41px;
      font-size: 26px;
      font-weight: 500;
      width: 95%;
      margin-bottom: 5px;
    }
    .privacy-note {
      line-height: 23px;
      font-size: 15px;
      font-weight: 400;
      margin-bottom: 40px;
      a {
        line-height: 23px;
        font-size: 15px;
        font-weight: 700;
        text-decoration: underline;
      }
    }
    button {
      max-width: 433px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 50px;
      }
      p {
        line-height: 30px;
        font-size: 20px;
      }
    }
  }
`

export const UserDataForm = styled.div`
  display: flex;
  flex-direction: column;
  width: 679px;
  height: 704px;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  margin-left: 100px;
  .step-one {
    input {
      height: 45px;
    }
    .form-error {
      height: 25px;
    }
  }
  h2 {
    margin-bottom: 20px;
    font-size: 34px;
    font-weight: 800;
  }
  p {
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
    &.subtittle {
      margin-bottom: 50px;
    }
  }

  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 15px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    ::placeholder {
      color: ${colors.darkGray};
    }
  }
  .form-error {
    height: 35px;
    width: 100%;
    p {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    height: 650px;
  }
`
