import React from 'react'
import { InvoiceContainer } from '../../style/components/auth/Invoice/Invoice.styled'
import { Link } from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/es'
import Button from '../ui/Button'
moment.locale('es')

const Invoice = ({ productSelected, subscription, validCode }) => {
  let endDay = ''
  let endMonth = ''
  let endYear = ''
  if (subscription) {
    endDay = moment(subscription.current_period_end * 1000).format('DD')
    endMonth =
      moment(subscription.current_period_end * 1000)
        .format('MMM')
        .slice(0, 1)
        .toUpperCase() +
      moment(subscription.current_period_end * 1000)
        .format('MMM')
        .slice(1, -1)
    endYear = moment(subscription.current_period_end * 1000).format('YYYY')
  }

  return (
    <InvoiceContainer>
      <section className='left-information'>
        <h1>Resumen de tu compra</h1>
        {productSelected.name !== 'Gratuito' && (
          <div>
            <p>¡Felicidades! Tu pago ha sido procesado correctamente, recibirás un correo de confirmación.</p>
            <p className='text-note'>* Puedes cancelar tu suscripción en cualquier momento.</p>
          </div>
        )}
        {productSelected.name === 'Gratuito' && (
          <div>
            <p>¡Felicidades! Te has registrado correctamente a MyOblek. ¡Disfruta tu plan gratuito!</p>
          </div>
        )}
        <Link to='/'>
          <Button size={'full'} weight={500}>
            Ir a tu Espacio de Trabajo
          </Button>
        </Link>
        {productSelected.name !== 'Gratuito' && (
          <div className='support'>
            <p>Si tienes problemas con tu facturación escríbenos</p>
            <a href='mailto:pagos@oblekco.com'>pagos@oblekco.com</a> {/* TODO: what mail goes here? */}
          </div>
        )}
      </section>
      <section className='right-information'>
        <h2>Resumen</h2>
        <div className='row-info'>
          <p>Plan seleccionado:</p>
          <p className='purple-text'>{productSelected.name}</p>
        </div>
        {productSelected.name !== 'Gratuito' && (
          <div className='row-info'>
            <p>Tipo plan:</p>
            <p className='purple-text'>{productSelected.billed}</p>
          </div>
        )}
        <div className='row-info'>
          <p>Usuarios:</p>
          <p className='purple-text'>{productSelected.users}</p>
        </div>
        <div className='row-info'>
          <p>Tableros:</p>
          <p className='purple-text'>{productSelected.dashboards}</p>
        </div>
        <div className='row-info'>
          <p>Búsquedas:</p>
          <p className='purple-text'>{productSelected.searches}</p>
        </div>
        <hr />
        <div className='row-info-purple'>
          <p>Total:</p>
          <p className='bold-text'>
            {validCode ? 0 : productSelected.price}
            {productSelected.name !== 'Gratuito' ? '.00' : ''}MXN
          </p>
        </div>
        {productSelected.name !== 'Gratuito' && (
          <p className='next-payment'>
            Tu siguiente pago se realizará automáticamente en el {endDay}/{endMonth}/{endYear}
          </p>
        )}
      </section>
    </InvoiceContainer>
  )
}

export default Invoice
