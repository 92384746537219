import React, { useContext, useState } from 'react'
import { Link } from 'react-router-dom'
import NewReportContainer from '../../style/pages/report/NewReport.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Shadow, Spinner, Topbar } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'
import ReportNameForm from '../../components/report/ReportNameForm'
import BoardSelection from '../../components/report/BoardSelection'
import { useMutation, useQuery } from '@apollo/client'
import WidgetsSelection from '../../components/report/WidgetsSelection'
import ReportType from '../../components/report/ReportType'
import ReportPeriod from '../../components/report/ReportPeriod'
import SearchHour from '../../components/report/SearchHour'
import ScheduleReport from '../../components/report/ScheduleReport'
import ReportDuration from '../../components/report/ReportDuration'
import ShareReport from '../../components/report/ShareReport'

import moment from 'moment'

import { DASHBOARDS_QUERY, REPORTS_QUERY, TICKETS_QUERY } from '../../services/queries'

import { CREATE_REPORT, CREATE_TICKET } from '../../services/mutations'
import { MyContext } from '../../context'
import ReportLimitWarning from '../../components/report/reports/ReportLimitWarning'
import Button from '../../components/ui/Button'

const NewReport = props => {
  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass } = uiCTX

  const context = useContext(MyContext)

  const [name, setName] = useState('')
  const [description, setDescription] = useState('')
  const [board, setBoard] = useState([])
  const [widgets, setWidgets] = useState([])
  const [comments, setComments] = useState([])
  const [type, setType] = useState('once')
  const [period, setPeriod] = useState([])
  const [date, setDate] = useState(['', ''])
  const [hour, setHour] = useState(['00:00', '23:59'])
  const [schedule, setSchedule] = useState([])
  const [days, setDays] = useState([])
  const [duration, setDuration] = useState(['', ''])
  const [hourDuration, setHourDuration] = useState(moment().hour(0).minute(0))
  const [endless, setEndless] = useState(false)
  const [users, setUsers] = useState([])
  const [limitWarning, setLimitWarning] = useState(null)

  const validateReport = () => {
    // Only yisus and i know how this function works.... and at the time you read this - now only yisus
    if (name !== '' && board.length !== 0 && widgets.length !== 0 && type !== '' && period.length !== 0) {
      if (
        // Case Once Type and Personaliced Period
        type === 'once' &&
        period[0].name === 'Personalizado'
      ) {
        if (hour[0] !== '' && hour[1] !== '' && date[0] !== '' && date[1] !== '') return false
        else return true
      } else if (
        // Case Once Type and Other Period
        type === 'once' &&
        period[0].name !== undefined
      ) {
        if (hour[0] !== '' && hour[1] !== '') return false
        else return true
      } else if (
        // Case Schedule Type and Personaliced Period
        type === 'scheduled' &&
        period[0].name === 'Personalizado'
      ) {
        if (
          hour[0] !== '' &&
          hour[1] !== '' &&
          date[0] !== '' &&
          date[1] !== '' &&
          schedule.length !== 0 &&
          (schedule[0].name !== 'Personalizado' || schedule[0].name === undefined)
        ) {
          if (duration[0] !== '' && duration[1] !== '') return false
          else return true
        } else if (
          hour[0] !== '' &&
          hour[1] !== '' &&
          date[0] !== '' &&
          date[1] !== '' &&
          schedule.length !== 0 &&
          schedule[0].name === 'Personalizado'
        ) {
          if (days.length !== 0 && duration[0] !== '' && duration[1] !== '') return false
          else return true
        } else return true
      } else if (
        // Case Schedule Type and Other Period
        type === 'scheduled' &&
        period[0].name !== 'Personalizado'
      ) {
        if (
          hour[0] !== '' &&
          hour[1] !== '' &&
          schedule.length !== 0 &&
          (schedule[0].name !== 'Personalizado' || schedule[0].name === undefined)
        ) {
          if (duration[0] !== '' && duration[1] !== '') return false
          else return true
        } else if (hour[0] !== '' && hour[1] !== '' && schedule.length !== 0 && schedule[0].name === 'Personalizado') {
          if (days.length !== 0 && duration[0] !== '' && duration[1] !== '') return false
          else return true
        } else return true
      }
    } else return true
  }

  const handleEndless = () => {
    setEndless(!endless)
    const durationArr = [...duration]
    durationArr[1] = 'endless'
    setDuration(durationArr)
  }

  const setAllWidgets = board => {
    const widgetsID = []
    board.widgets.forEach(widget => widgetsID.push(widget.id))

    if (widgets.length === widgetsID.length) return setWidgets([])
    setWidgets(widgetsID)
    setAllComments(widgetsID.length)
  }

  const handleWidget = id => {
    const widgetsArr = [...widgets]
    if (widgetsArr.includes(id)) {
      widgetsArr.splice(widgetsArr.indexOf(id), 1)
    } else {
      widgetsArr.push(id)
    }
    setWidgets(widgetsArr)
  }

  const setAllComments = qty => {
    const allCommentsArr = []
    for (let i = 0; i < qty; i++) {
      allCommentsArr.push('')
    }
    setComments(allCommentsArr)
  }

  const handleComments = (e, idx, clear = false) => {
    const commentsArr = [...comments]
    if (clear) {
      commentsArr[idx] = ''
    } else {
      commentsArr[idx] = e.target.value
    }
    setComments(commentsArr)
  }

  const handleDate = (source, e) => {
    const dateArr = [...date]
    if (source === 'from') {
      dateArr[0] = e.target.value
    } else if (source === 'to') {
      dateArr[1] = e.target.value
    }
    setDate(dateArr)
  }

  const handleHour = (source, e) => {
    const hourArr = [...hour]
    if (source === 'from') {
      hourArr[0] = e.target.value
    } else if (source === 'to') {
      hourArr[1] = e.target.value
    }
    setHour(hourArr)
  }

  const handleDays = day => {
    let daysArr = [...days]
    if (daysArr.includes(day)) {
      daysArr.splice(daysArr.indexOf(day), 1)
    } else {
      daysArr.push(day)
    }
    setDays(daysArr)
  }

  const handleDuration = (source, e) => {
    const durationArr = [...duration]
    if (source === 'from') {
      durationArr[0] = e.target.value
    } else if (source === 'to') {
      durationArr[1] = e.target.value
    }
    setDuration(durationArr)
  }

  const [createReport, { error: errorCreateReport }] = useMutation(CREATE_REPORT, {
    update(cache, { data: { createReport } }) {
      const { reports } = cache.readQuery({ query: REPORTS_QUERY })
      cache.writeQuery({
        query: REPORTS_QUERY,
        data: {
          reports: [...reports, createReport]
        }
      })
    },
    onError: error => {
      if (error.message.includes('límite')) {
        setLimitWarning({
          error: true
        })
      }
    }
  })

  if (errorCreateReport) context.handleErrors(errorCreateReport)

  const [createTicket, { error: errorCreateTicket }] = useMutation(CREATE_TICKET, {
    update(cache, { data: { createTicket } }) {
      const { tickets } = cache.readQuery({ query: TICKETS_QUERY })
      cache.writeQuery({
        query: TICKETS_QUERY,
        data: {
          tickets: [...tickets, createTicket]
        }
      })
    },
    onError: error => {
      if (error.message.includes('límite')) {
        setLimitWarning({
          error: true
        })
      }
    }
  })

  if (errorCreateTicket) context.handleErrors(errorCreateTicket)

  const handleCreateReport = async () => {
    const usersArr = []
    users.map(user => usersArr.push(user.id))

    if (type === 'once') {
      const resultReport = await createReport({
        variables: {
          input: {
            name,
            description,
            dashboard: board[0].id,
            widgets,
            comments,
            type,
            period: period[0].name,
            date,
            hour,
            users: usersArr
          }
        }
      })
      if (resultReport.data) {
        window.open(`/reports/report/${resultReport.data.createReport.id}`, '_blank')
        props.history.push('/reports')
      }
    } else if (type === 'scheduled') {
      const resultTicket = await createTicket({
        variables: {
          input: {
            name,
            description,
            dashboard: board[0].id,
            widgets,
            comments,
            type,
            period: period[0].name,
            date,
            hour,
            schedule: schedule[0].name,
            days,
            duration,
            reportHour: hourDuration.format('HH:mm'),
            users: usersArr
          }
        }
      })
      if (resultTicket.data) {
        props.history.push(`/reports/scheduled/${resultTicket.data.createTicket.id}`)
      }
    }
  }

  const { data, loading, error } = useQuery(DASHBOARDS_QUERY)

  if (error) context.handleErrors(error)

  if (loading) return <Spinner />

  return (
    <MainContainer className={mainClass()}>
      <NewReportContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <Link to='/reports' className='back-link'>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </Link>
            <h1>Nuevo reporte</h1>
          </div>
        </Topbar>
        <div className='main-report-container'>
          <section className='left-report-section'>
            <ReportNameForm name={name} setName={setName} description={description} setDescription={setDescription} />
            <BoardSelection
              dashboards={data !== undefined ? data.dashboards : []}
              board={board}
              setBoard={setBoard}
              setWidgets={setWidgets}
              setAllWidgets={setAllWidgets}
            />
            <WidgetsSelection
              board={board[0]}
              widgets={widgets}
              setAllWidgets={setAllWidgets}
              handleWidget={handleWidget}
              comments={comments}
              handleComments={handleComments}
            />
          </section>
          <section className='right-report-section'>
            <ReportType type={type} setType={setType} />
            <ReportPeriod period={period} setPeriod={setPeriod} date={date} handleDate={handleDate} />
            <SearchHour hour={hour} handleHour={handleHour} setHour={setHour} />
            {type === 'scheduled' && (
              <>
                <ScheduleReport schedule={schedule} setSchedule={setSchedule} days={days} handleDays={handleDays} />
                <ReportDuration
                  duration={duration}
                  handleDuration={handleDuration}
                  hourDuration={hourDuration}
                  setHourDuration={setHourDuration}
                  endless={endless}
                  handleEndless={handleEndless}
                />
              </>
            )}
            {board[0] && <ShareReport board={board} users={users} setUsers={setUsers} />}
            <Button
              weight={500}
              size={'full'}
              className={validateReport() ? 'generate-report-inactive' : 'generate-report'}
              disabled={validateReport()}
              onClick={() => handleCreateReport()}
            >
              Generar reporte
            </Button>
          </section>
        </div>
        {limitWarning && limitWarning.error && (
          <>
            <Shadow onClick={() => setLimitWarning(null)} />
            <ReportLimitWarning reportType={type} closeModal={() => setLimitWarning(null)} />
          </>
        )}
      </NewReportContainer>
    </MainContainer>
  )
}

export default NewReport
