import styled from 'styled-components'

const SearchesContainer = styled.div`
  overflow: hidden;
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
      button {
        cursor: pointer;
      }
    }
  }
  .topbarMobile {
    background: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 56px;
    &-title {
      p {
        color: white;
        font-size: 18px;
      }
    }
  }
`

export default SearchesContainer
