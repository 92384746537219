import styled from 'styled-components'

const SearchContainer = styled.div`
  display: flex;
  height: 100%;
  .topbarMobile {
    justify-content: space-between;
    background: #000;
    height: 56px;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 10px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 40px;
          height: 28px;
          color: white;
        }
        p {
          font-size: 16px;
          font-weight: 600;
          color: white;
        }
      }
      p {
        width: 210px;
        font-size: 22px;
        color: white;
        height: 30px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
      }
      button {
        cursor: pointer;
      }
    }
  }
`

export default SearchContainer
