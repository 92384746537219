import React, { useContext, useState } from 'react'
import { useQuery, useMutation } from '@apollo/client'

import DashboardsContainer from '../../style/pages/dashboardMobile/Dashboards.styled'
import { MainContainer, Topbar, Toolbar, Spinner, Shadow } from '../../style/components'

import MineDashboards from '../../components/dashboard/dashboards/MineDashboards'
import SharedDashboards from '../../components/dashboard/dashboards/SharedDashboards'
import DeleteDashboard from '../../components/dashboard/dashboards/DeleteDashboard'

import { DASHBOARDS_QUERY, SHARED_DASHBOARDS_QUERY } from '../../services/queries'
import { DELETE_DASHBOARD_MUTATION } from '../../services/mutations'
import { MyContext } from '../../context'

const Dashboards = () => {
  const context = useContext(MyContext)

  const {
    data: dashboardsData,
    loading: dashboardsLoading,
    error: errorDashboardQuery
  } = useQuery(DASHBOARDS_QUERY, { fetchPolicy: 'network-only' })

  const {
    data: sharedDashboardsData,
    loading: sharedDashLoading,
    error: errorSharedDashboardQuery
  } = useQuery(SHARED_DASHBOARDS_QUERY, { fetchPolicy: 'network-only' })

  const [section, setSection] = useState('mine')

  const [dashboardToDelete, setDashboardToDelete] = useState(null)

  const setMineSection = () => (section === 'mine' ? 'section-button section-button-active' : 'section-button')
  const setSharedSection = () => (section === 'shared' ? 'section-button section-button-active' : 'section-button')

  const [deletedDashboard, { error: errorDeleteDashboard }] = useMutation(DELETE_DASHBOARD_MUTATION, {
    update(cache, { data: { deletedDashboard } }) {
      const { dashboards } = cache.readQuery({ query: DASHBOARDS_QUERY })
      cache.writeQuery({
        query: DASHBOARDS_QUERY,
        data: {
          dashboards: dashboards.filter(dashboard => dashboard.id !== deletedDashboard.id)
        }
      })
    }
  })

  const handleDeleteDashboard = async id => {
    await deletedDashboard({ variables: { id } })
    setDashboardToDelete(null)
  }

  if (dashboardsLoading || sharedDashLoading) return <Spinner />
  if (errorDashboardQuery) context.handleErrors(errorDashboardQuery)
  if (errorSharedDashboardQuery) context.handleErrors(errorSharedDashboardQuery)
  if (errorDeleteDashboard) context.handleErrors(errorDeleteDashboard)

  return (
    <MainContainer padding='56px 2.5%'>
      <DashboardsContainer>
        <Topbar className='topbarMobile'>
          <div className='left-topbar'>
            <p>Tableros</p>
          </div>
        </Topbar>

        <Toolbar className='toolbarMobile'>
          <div className='left-toolbar'>
            <button className={setMineSection()} onClick={() => setSection('mine')}>
              Mis tableros
            </button>
            <button className={setSharedSection()} onClick={() => setSection('shared')}>
              Compartidos
            </button>
          </div>
        </Toolbar>

        {section === 'mine' && (
          <MineDashboards
            paddingT='10px'
            dashboards={dashboardsData ? dashboardsData.dashboards : []}
            filtersOpen={null}
            filters={{
              name: '',
              creator: '',
              from: '',
              to: ''
            }}
            order={{
              AtoZ: '',
              ZtoA: '',
              recentDate: '',
              oldDate: ''
            }}
            setDashboardToDelete={null}
            nobutton={true}
          />
        )}

        {section === 'shared' && (
          <SharedDashboards
            dashboards={sharedDashboardsData ? sharedDashboardsData.sharedDashboards : []}
            filtersOpen={null}
            filters={{
              name: '',
              creator: '',
              from: '',
              to: ''
            }}
            order={{
              AtoZ: '',
              ZtoA: '',
              recentDate: '',
              oldDate: ''
            }}
          />
        )}

        {dashboardToDelete && (
          <>
            <Shadow />
            <DeleteDashboard
              dashboard={dashboardToDelete}
              setDashboardToDelete={setDashboardToDelete}
              deleteDashboard={handleDeleteDashboard}
            />
          </>
        )}
      </DashboardsContainer>
    </MainContainer>
  )
}

export default Dashboards
