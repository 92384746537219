import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { TweetsContainer, DateButton } from '../../../style/components/search/search/Tweets.styled'
import { useLazyQuery } from '@apollo/client'
import { Spinner } from '../../../style/components'
import Tweet from '../new-search/Tweet'
import { AiOutlineCalendar } from 'react-icons/ai'

import { TWEETS_QTY_QUERY, TWEETS_QUERY } from '../../../services/queries'
// import { BsCheckBox, BsSquare } from 'react-icons/bs'

const Tweets = ({ search, setShowClippingModal }) => {
  const [page, setPage] = useState(1)
  const [loadingTweets, setLoadingTweets] = useState(false)
  // const [checkAll, setCheckAll] = useState(false)

  const [startDate, setStartDate] = useState(moment().subtract(7, 'days').format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))
  const [verifiedDates, setVerifiedDate] = useState(true)

  const [getTweets, { data, loading, error, fetchMore: fetchMoreTweets }] = useLazyQuery(TWEETS_QUERY)
  const [getTweetsQty, { data: tweetsQtyData, loading: tweetsQtyLoading }] = useLazyQuery(TWEETS_QTY_QUERY)

  const fetchData = () => {
    getTweets({
      variables: {
        input: {
          query: search.query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
    getTweetsQty({
      variables: {
        input: {
          query: search.query,
          startDate: moment(startDate).format('DD-MM-YYYY'),
          endDate: moment(endDate).format('DD-MM-YYYY'),
          pagina: 0
        }
      }
    })
  }

  //Todo: max date for input showing one day ahead
  useEffect(() => {
    if (search) fetchData()
  }, [search])

  const verifyDates = (name, value) => {
    if (name === 'startDate') {
      if (moment(value).isBefore(endDate) && moment(endDate).isBetween(value, moment(Date.now()))) {
        setStartDate(value)
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    } else if (name === 'endDate') {
      if (moment(startDate).isBefore(value) && moment(value).isBetween(startDate, moment(Date.now()))) {
        setEndDate(value)
        setVerifiedDate(true)
      } else {
        setVerifiedDate(false)
      }
    }
  }

  const submitDateSearch = () => {
    if (moment(startDate).isBefore(endDate) && moment(endDate).isBetween(startDate, moment(Date.now()))) {
      fetchData()
    }
  }

  const fetchMoreData = e => {
    const target = e.target
    if (target.scrollHeight - target.scrollTop === target.clientHeight) {
      if (data.tweets.length === tweetsQtyData.tweetsQty) return
      setLoadingTweets(true)
      fetchMoreTweets({
        variables: {
          input: {
            query: search.query,
            startDate: moment(startDate).format('DD-MM-YYYY'),
            endDate: moment(endDate).format('DD-MM-YYYY'),
            pagina: page
          }
        },
        updateQuery: (prev, { fetchMoreResult }) => {
          setLoadingTweets(false)
          if (!fetchMoreResult) return prev
          return Object.assign({}, prev, {
            tweets: [...prev.tweets, ...fetchMoreResult.tweets]
          })
        }
      })
      const nextPage = page + 1
      setPage(nextPage)
    }
  }

  if (tweetsQtyLoading || loading) return <Spinner />
  if (error) return <p>Hubo un error</p>

  return (
    <TweetsContainer>
      {data && tweetsQtyData && (
        <>
          <div className='results-header'>
            <h4 className='total-articles'>
              {tweetsQtyData.tweetsQty.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} resultados
            </h4>

            {/* <div className='results-clipping'>

              <div className='checkboxContainer'>
                {checkAll ?
                  <button
                    className='checkbox-indicator'
                    onClick={() => setCheckAll(false)}
                  >
                    <BsCheckBox className='checked' />
                  </button>
                  :
                  <button
                    className='checkbox-indicator'
                    onClick={() => setCheckAll(true)}
                  >
                    <BsSquare className='not-checked' />
                  </button>
                }
                <p>Todos</p>
              </div>

              <button
                className='exportButton'
                onClick={() => setShowClippingModal(true)}
              >
                Exportar Clipping
              </button>
            </div> */}
            <div className='results-period'>
              <AiOutlineCalendar />
              <input
                type='date'
                name='startDate'
                className='date-input'
                max={endDate}
                value={startDate}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
              />
              a
              <input
                type='date'
                name='endDate'
                className='date-input'
                value={endDate}
                min={startDate}
                max={moment(moment()).format('YYYY-MM-DD')}
                onChange={({ target: { name, value } }) => verifyDates(name, value)}
              />
              <DateButton
                className='date-button'
                disabled={!verifiedDates}
                onClick={() => verifiedDates && submitDateSearch()}
              >
                Buscar
              </DateButton>
            </div>
          </div>
          <div className='search-results' onScroll={fetchMoreData}>
            {data.tweets.map((tweet, idx) => (
              <Tweet key={idx} tweet={tweet} />
            ))}
            {loadingTweets && <Spinner />}
          </div>
        </>
      )}
    </TweetsContainer>
  )
}

export default Tweets
