import styled from 'styled-components'
import { colors } from '../../variables'

export const LogoutButton = styled.button`
  height: 55px;
  width: 100%;
  border: none;
  border-radius: 4px;
  outline: none;
  background: transparent;
  color: ${colors.darkGray};
  font-size: 14px;
  font-weight: 500;
  &:hover {
    background: ${colors.lightseaGreen};
    color: ${colors.white};
  }
  svg {
    transform: rotate(180deg);
    margin-right: 20px;
  }
`
