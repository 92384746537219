import styled from 'styled-components'

export const ScheduledReportsContainer = styled.div`
  padding-left: ${props => (props.filtersOpen ? '400px' : '0px')};
  padding-top: 30px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 12.5px;
  }
  section {
    max-height: calc(100vh - 189px);
    padding: 7.5px 20px 7.5px 7.5px;
    overflow-y: scroll;
    display: flex;
    flex-wrap: wrap;
  }
`
