import React, { useCallback } from 'react'
import { colors } from '../../style/variables'
import { MobileCont } from '../../style/components/modules/index.styled'
import Particles from 'react-tsparticles'
import { loadFull } from 'tsparticles'
import { particles } from '../../style/Particles'

const MobileDetection = () => {
  const particlesInit = useCallback(async engine => {
    await loadFull(engine)
  }, [])

  const particlesLoaded = useCallback(async container => {
    await console.log(container)
  }, [])

  return (
    <MobileCont>
      <Particles
        loaded={particlesLoaded}
        init={particlesInit}
        width='100%'
        height='100%'
        params={particles(colors.torquioseBlue, colors.white)}
      />
      <div className='message'>
        <p>¡UPSSS!…</p>
        <p>Este sitio no está optimizado para una experiencia mobile.</p>
        <p>Por favor inicia sesión desde tu compuradora</p>
      </div>
    </MobileCont>
  )
}

export default MobileDetection
