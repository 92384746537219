import React, { useEffect, useRef, useState } from 'react'
import { BsEye, BsThreeDots, BsTrash } from 'react-icons/bs'
import { FiShare2 } from 'react-icons/fi'
import { TableRow, ReportMenu } from '../../../style/components/reports/reports/RowReport.styled'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

export const RowReport = ({ report, createdDate, createdHour, openDeleteModal, openShareModal, openDetailReport }) => {
  const reportCardRef = useRef()

  const [showReportMenu, setShowReportMenu] = useState(false)
  useOutsideClick(reportCardRef, () => setShowReportMenu(false))

  return (
    <div ref={reportCardRef}>
      <TableRow key={report.id}>
        <p className='number'>{report.number}</p>
        <p>{report.name}</p>
        <p>{createdDate}</p>
        <p>{createdHour}</p>
        <div className='dots-icon'>
          <BsThreeDots onClick={() => setShowReportMenu(!showReportMenu)} />
        </div>
      </TableRow>
      <ReportMenu>
        {showReportMenu && (
          <div className='report-options'>
            <button className='full_option option_button' onClick={() => openDetailReport(report.id)}>
              <BsEye /> Ver reporte
            </button>
            <button className='full_option option_button' onClick={() => openShareModal(report.id)}>
              <FiShare2 /> Compartir reporte
            </button>
            <button className='full_option delete_button' onClick={() => openDeleteModal(report.id, report.name)}>
              <BsTrash />
              Eliminar reporte
            </button>
          </div>
        )}
      </ReportMenu>
    </div>
  )
}
