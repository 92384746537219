import { gql, useMutation } from '@apollo/client'
import React from 'react'
import { BsCheckBox, BsSquare } from 'react-icons/bs'
import { FaCcMastercard, FaCcVisa } from 'react-icons/fa'
import styled from 'styled-components'
import { Spinner } from '../../style/components'
import { colors } from '../../style/variables'

const MAKE_DEFAULT_PAYMENT_METHOD = gql`
  mutation makeDefaultPaymentMethod($paymentMethodId: String!) {
    makeDefaultPaymentMethod(paymentMethodId: $paymentMethodId)
  }
`

const RepayModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  border-radius: 4px;
  background: ${colors.white};
  width: 632px;
  /* height: 493px; */

  h3 {
    font-size: 24px;
    font-weight: 800;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
  }

  button {
    width: 100%;
    height: 50px;
    margin-top: 10px;
    border: none;
    border-radius: 4px;
    font-size: 15px;
    font-weight: 500;
    &.button-active {
      background: ${colors.torquioseBlue};
      border: none;
      color: ${colors.richBlack};
      cursor: pointer;
    }
  }

  .contenido {
    width: 100%;
  }
  .contenido p {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    /* margin-bottom:30px; */
  }
  .contenido .cumplimiento {
    display: flex;
  }
  .contenido .pad {
    margin: 10px 0;
  }
  .contenido .cumplimiento .left {
    width: 85%;
  }
  .contenido .cumplimiento .left,
  .contenido .cumplimiento .right {
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    color: ${colors.richBlack};
  }
  .contenido .cumplimiento .right {
    text-align: right;
  }
  .contenido .checkbox .left .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }
  .contenido .checkbox .left .container .icon {
    margin-right: 10px;
  }
  .contenido .checkbox .left .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }
  .contenido .checkbox .left .container .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }
  .contenido .checkbox .left .container:hover input ~ .checkmark {
    background-color: #ccc;
  }
  .contenido .checkbox .left .container input:checked ~ .checkmark {
    background-color: ${colors.lightseaGreen};
  }
  .contenido .checkbox .left .container .checkmark:after {
    content: '';
    position: absolute;
    display: none;
  }
  .contenido .checkbox .left .container input:checked ~ .checkmark:after {
    display: block;
  }
  .contenido .checkbox .left .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
  .contenido .herramientas {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
  }
  .contenido .herramientas div {
    justify-self: center;
    align-self: center;
    display: flex;
    height: 30px;
  }
  .contenido .herramientas div .icon {
    font-size: 30px;
    color: ${colors.silverSand};
    margin-right: 11px;
  }
  .contenido .herramientas div p {
    font-size: 16px;
    font-weight: 400;
    color: ${colors.richBlack};
  }
  .contenido .left .añadir {
    color: ${colors.lightseaGreen};
    font-size: 14px;
    cursor: pointer;
  }
  .contenido .payment-methods {
    margin-bottom: 20px;
    height: 120px;
    overflow: auto;
  }

  .spinnerContainer {
    height: 493px;
  }

  .errorMessage {
    color: ${colors.red};
    margin: 0;
    font-weight: 800;
  }

  .successInfo {
    height: 130px;
    p {
      margin-top: 20px;
      text-align: center;
      &.tyMessage {
        font-size: 22px;
        line-height: 35px;
      }
    }
  }
`

const Card = styled.article`
  display: flex;
  align-items: center;
  .checkbox-indicator {
    width: 40px;
    height: 40px;
    background: none;
    border: none;
    outline: none;
    margin-right: 5px;
    .checked {
      width: 23px;
      height: 23px;
      fill: ${colors.lightseaGreen};
    }
    .not-checked {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
      cursor: pointer;
    }
  }
  .card-info {
    display: flex;
    align-items: center;
    width: 50%;
    svg {
      width: 60px;
      height: 25px;
      fill: ${colors.light};
      margin-right: 10px;
    }
    p {
      font-size: 14px;
      font-weight: 400;
      margin-right: 15px;
    }
  }
  .hover-button {
    width: 70px;
    border: none;
    background: none;
    outline: none;
    display: none;
    font-size: 12px;
    font-weight: 500;
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
    &.default {
      color: ${colors.lightseaGreen};
    }
    &.delete {
      color: ${colors.red};
      cursor: pointer;
    }
  }
  &:hover {
    background: ${colors.lightGray};
    .hover-button {
      display: block;
    }
  }
`

const RepayModal = ({
  loading,
  admin,
  cards,
  handleRetryPaymenth,
  handleUserLogOut,
  handleAddPaymenthMethod,
  refetchPM,
  error,
  success,
  closeModal
}) => {
  const [makeDefaultPaymentMethod] = useMutation(MAKE_DEFAULT_PAYMENT_METHOD)

  const handleDefaultPaymentMethod = async paymentMethodId => {
    await makeDefaultPaymentMethod({ variables: { paymentMethodId } })
    refetchPM()
  }

  if (loading)
    return (
      <RepayModalContainer>
        <div className='spinnerContainer'>
          <Spinner />
        </div>
      </RepayModalContainer>
    )

  if (success)
    return (
      <RepayModalContainer>
        <h3>Operación exitosa</h3>
        <div className='successInfo'>
          <p>El pago se realizo exitosamente.</p>
          <p className='tyMessage'>¡Sigue disfrutando de tu plan!</p>
        </div>
        <button className='button-active' onClick={() => closeModal(false)}>
          Entendido
        </button>
      </RepayModalContainer>
    )

  if (admin)
    return (
      <RepayModalContainer>
        <h3>Ocurrió un error</h3>
        <div className='contenido'>
          <p>
            ¡Ups! Parece que tu último pago no pudo ser procesado. Verifíca si el método de pago que tienes registrado
            funciona correctamente o selecciona uno nuevo. Podrás hacer uso completo de la plataforma una vez se haya
            realizado el pago actual.
          </p>
          <div className='cumplimiento pad'>
            <div className='left'>
              <span>Estos son tus métodos de pago registrados:</span>
            </div>
          </div>
          <div className='payment-methods'>
            {cards !== null &&
              cards.length > 0 &&
              cards.map(card => (
                <Card key={card.id}>
                  {card.default ? (
                    <button className='checkbox-indicator'>
                      <BsCheckBox className='checked' />
                    </button>
                  ) : (
                    <button className='checkbox-indicator' onClick={() => handleDefaultPaymentMethod(card.id)}>
                      <BsSquare className='not-checked' />
                    </button>
                  )}

                  <div className='card-info'>
                    {card.brand === 'visa' && <FaCcVisa />}
                    {card.brand === 'mastercard' && <FaCcMastercard />}
                    <p>•••• •••• •••• {card.last4}</p>
                    <p>
                      {card.month < 10 ? '0' + card.month : card.month} / {card.year - 2000}
                    </p>
                  </div>
                </Card>
              ))}
          </div>

          <div className='cumplimiento pad'>
            <div className='left'>
              <span className='añadir' onClick={() => handleAddPaymenthMethod()}>
                Añadir nuevo método de pago
              </span>
            </div>
            <div className='right'></div>
          </div>
          {error && (
            <small className='errorMessage'>
              Pago rechazado, por favor intenta nuevamente o trata con un método de pago diferente
            </small>
          )}
          <div className='buttonContainer'>
            <button className='button-active' onClick={() => handleRetryPaymenth()}>
              Intentar nuevamente
            </button>
          </div>
        </div>
      </RepayModalContainer>
    )

  return (
    <RepayModalContainer>
      <h3>Ocurrió un error</h3>
      <p>
        ¡Ups! Parece que el último pago no pudo ser procesado. Avisa a un <strong>Administrador</strong> de la cuenta
        para que verifique si el método de pago que tienen registrado funciona correctamente o seleccionar uno nuevo.
        Podrás hacer uso completo de la plataforma una vez se haya realizado el pago actual.
      </p>
      <p>Mientras tanto no podrás modificar, crear, exportar o eliminar nuevos elementos.</p>
      <button className='button-active' onClick={() => handleUserLogOut()}>
        Ok, entiendo.
      </button>
    </RepayModalContainer>
  )
}

export default RepayModal
