import styled from 'styled-components'
import { colors } from '../../../variables'

export const ClippingDetailContainer = styled.div`
  height: 100%;
  width: 100%;

  hr {
    border: 1px solid #aaafbe;
  }

  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 300px;
        font-size: 22px;
        font-weight: 600;
      }
      button {
        cursor: pointer;
      }
    }
    .right-topbar {
      width: 30%;
      button {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 18px;
        width: 212px;
        height: 38px;
        border: 2px solid ${colors.purple};
        color: ${colors.purple};
        border-radius: 4px;
        background: none;
        :hover {
          background: ${colors.purple};
          color: ${colors.white};
        }
      }
    }
  }

  .toolbar {
    justify-content: space-between;
    .left-toolbar {
      height: 100%;
      margin-top: 10px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 18px;
      line-height: 22px;
      .section-button {
        width: 100px;
        height: 100%;
        border: none;
        outline: none;
        margin-right: 30px;
        background: transparent;
        color: ${colors.darkGray};
        font-size: 14px;
        font-weight: 500;
        cursor: pointer;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .section-button-active {
        color: ${colors.purple};
        border-bottom: 2px solid ${colors.purple};
      }
    }
  }
`

export const TableHead = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
`

export const Head = styled.div`
  width: 20%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 19.5px;
`

export const RowContent = styled.div`
  width: 100%;
  height: ${props => props.open};
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.125847);
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 12px;
  align-items: center;
  transition: ease-in-out 0.2s;
  transform: translate(0, 0px);
  :hover {
    transform: translate(0, -3px);
    background: ${colors.lightGray};
  }
`

export const TableRow = styled.div`
  display: flex;
  button {
    width: 127px;
    height: 29px;
    border: 2px solid ${colors.lightseaGreen};
    font-family: Montserrat;
    font-weight: 600;
    font-style: normal;
    line-height: 19px;
    border-radius: 5px;
    background: none;
    color: ${colors.lightseaGreen};
    :hover {
      background: ${colors.lightseaGreen};
      color: ${colors.white};
      cursor: pointer;
    }
  }
`

export const Row = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #748596;
  p {
    cursor: pointer;
    color: ${colors.lightseaGreen};
    text-decoration: underline;
    text-decoration-color: ${colors.lightseaGreen};
    :hover {
      text-decoration-color: ${colors.silverSand};
      color: ${colors.silverSand};
    }
  }
  img {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`

export const NoteContent = styled.div`
  font-family: Montserrat;
  font-style: normal;
  font-weight: 500;
  color: #748596;
  .infoText {
    margin-top: 15px;
    font-size: 12px;
    line-height: 15px;
    margin-bottom: 15px;
  }
  .resumeContainer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    .resume {
      font-size: 14px;
      line-height: 18px;
    }
  }
  .reportContainer {
    display: flex;
    width: 95%;
    justify-content: flex-end;
    margin-top: 15px;
    margin-bottom: 15px;
    button {
      font-weight: 600;
      font-size: 16px;
      line-height: 19.5px;
      border: none;
      color: ${colors.purple};
      background: none;
      :hover {
        cursor: pointer;
        font-weight: bolder;
      }
    }
  }
`
