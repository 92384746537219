import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const DataChangeModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 632px;
  padding: 51px 40px 53px;
  border-radius: 4px;
  background: ${colors.white};
  box-shadow: 0 4px 10px;
  font-family: Montserrat;

  .datachange__close {
    align-self: flex-end;
    cursor: pointer;
  }

  .datachange__header {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 4px 0 30px;

    &__text {
      font-size: 24px;
      font-weight: 800;
      line-height: 29.26px;
    }
  }

  .datachange__content {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: left;
    width: 100%;
  }

  .datachange__understood {
    display: flex;
    justify-content: center;
    width: 100%;
  }
`
