import styled from 'styled-components'
import { colors } from '../../../variables'

export const IndicatorsContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  p {
    font-size: 12px;
    font-weight: 700;
    color: #c5c6c8;
    &.title-active {
      color: ${colors.purple};
    }
  }
  div {
    width: 2px;
    height: 57px;
    background: #c5c6c8;
    margin: 7.5px 0 10.5px;
    &.line-purple {
      background: ${colors.purple};
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    height: 500px;
  }
`
