import styled from 'styled-components'
import { colors } from '../../../variables'

const EditTwitterSearchContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: 40px;
  margin-bottom: -30px;
  flex-direction: column;
  .twitter-search-bar {
    display: flex;
    width: 100%;
    margin-bottom: 30px;
    .twitter-search-button {
      margin-left: 20px;
    }
    .modified-search-button {
      margin-left: 20px;
    }
  }
`

export default EditTwitterSearchContainer
