import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const NoteContainer = styled.article`
  min-width: 300px;
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  margin: 0 20px 20px 0;
  max-width: 450px;
  @media (max-width: 580px) {
    margin: 0 0px 20px 0;
  }
  .note--header {
    display: flex;
    justify-content: space-between;
    border-bottom: 2px solid ${colors.lightGray};
    height: 60px;
    margin-bottom: 10px;
    align-items: center;
    padding: 15px 15px 15px 0;

    p {
      background: #748596;
      padding-left: 15px;
      color: white;
    }
  }
  .note--title {
    width: 225px;
    h6 {
      font-size: 12px;
      font-weight: 500;
      margin-bottom: 5px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }
    p {
      font-size: 12px;
      font-weight: 400;
      margin-bottom: 3px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }
  }
  .note--icon {
    width: 30px;
    height: 30px;
    svg {
      width: 30px;
      height: 30px;
    }
  }
  .note--content {
    margin-bottom: 10px;
    padding: 0 15px;
    p {
      font-size: 14px;
      font-weight: 500;
      margin-bottom: 10px;
      height: 88px;

      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 5;
      -webkit-box-orient: vertical;
    }
    .note--actions {
      display: flex;
      justify-content: space-between;
      height: 30px;
      button {
        border: none;
        background: none;
        font-size: 14px;
        font-weight: 600;
        color: ${colors.lightseaGreen};
        outline: none;
        cursor: pointer;
      }
    }
    a {
      font-size: 14px;
      font-weight: 600;
      color: ${colors.lightseaGreen};
    }
  }
  .note--footer {
    display: flex;
    justify-content: center;
    padding: 15px;
    align-items: center;
    border-top: 2px solid ${colors.lightGray};
    a {
      text-decoration: none;
      color: #00aaab;
    }
    p {
      display: flex;
      align-items: center;
      svg {
        width: 15px;
        height: 15px;
        stroke: ${colors.lightGray};
        margin-right: 3px;
      }
      font-size: 12px;
      font-weight: 400;
    }
  }
`
