import React from 'react'
import { colors } from '../../../style/variables'
import { ReportTestigoContainer } from '../../../style/components/search/search/ReportTestigo.styled'
import Button from '../../ui/Button'

const ReportTestigo = ({ closeModal, motive, handleMotive, reportTestigo, thanks }) => {
  return (
    <ReportTestigoContainer>
      {thanks ? (
        <>
          <h3>¡Muchas gracias!</h3>
          <p>Tu reporte ha sido enviado, estamos trabajando para que ya no tengas este inconveniente.</p>
          <Button weight={500} className='ok-button' onClick={closeModal}>
            Cerrar
          </Button>
        </>
      ) : (
        <>
          <h3>Reportar un problema</h3>
          <p>Escribe las razones por las cuales estás reportando un problema al utilizar este testigo.</p>
          <textarea value={motive} onChange={e => handleMotive(e.target.value)} />
          <div className='modal-buttons'>
            <Button variant={'link'} theme={'dark'} className='cancel-button' onClick={closeModal}>
              Cancelar
            </Button>
            <Button className='save-button' theme={'primary'} variant={'solid'} onClick={() => reportTestigo()}>
              Enviar Reporte
            </Button>
          </div>
        </>
      )}
    </ReportTestigoContainer>
  )
}

export default ReportTestigo
