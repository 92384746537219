import styled from 'styled-components'
import { colors } from '../../variables'

const AutoCompleteContainer = styled.div`
  position: relative;
  div.input-container {
    border-radius: 4px;
    border: 1px solid #748596;
    font-size: 14px;
    background: transparent;
    display: flex;
    width: 100%;
    input {
      font-size: 14px;
      border-radius: 4px;
      border: none;
      padding: 14px;
      height: 100%;
      width: 80%;
      &:focus {
        outline: none;
        background: transparent;
      }
      &::-webkit-calendar-picker-indicator {
        opacity: 100;
      }
      &::-webkit-outer-spin-button,
      &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
      }
      /* Firefox */
      -moz-appearance: textfield;
    }
    .icon-container {
      font-size: 25px;
      align-items: center;
      justify-content: center;
      display: flex;
      width: 20%;
      height: auto;
      color: #738495;
      cursor: pointer;
    }
  }
  .options-container {
    position: absolute;
    top: 100%;
    z-index: 5;
    background: white;
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    box-shadow: 0px 3px 3px 0px #0b0c104d;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    max-height: 200px;
    overflow-y: scroll;
    .option {
      padding: 3px 10px 3px 10px;
      cursor: pointer;
      &:hover {
        background: ${colors.lightGray};
      }
    }
  }
`
export default AutoCompleteContainer
