import styled from 'styled-components'
import { colors } from '../../../variables'

export const BillInfoCoantainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
  width: 70vw;
  justify-content: space-between;
  .left-information {
    max-width: 525px;
    width: 50%;
    display: flex;
    flex-direction: column;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p.required {
      font-size: 15px;
      font-weight: 600;
      margin-bottom: 38px;
    }
    .plan-switch {
      display: flex;
      align-items: center;
      margin-bottom: 40px;
      p {
        width: 150px;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.darkGray};
        &.bold {
          font-weight: 700;
          color: ${colors.darkGray};
        }
      }
    }
    button {
      max-width: 433px;
    }
  }
  @media (max-width: 1366px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 60px;
      }
    }
  }
  @media (max-width: 1024px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 50px;
      }
      p {
        line-height: 30px;
        font-size: 20px;
      }
    }
  }
`

export const BillInfoForm = styled.form`
  display: flex;
  flex-direction: column;
  width: 679px;
  height: 811px;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  margin-left: 100px;
  h2 {
    margin-bottom: 40px;
    font-size: 34px;
    font-weight: 800;
  }
  p {
    margin-bottom: 50px;
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
    line-height: 28px;
  }
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    ::placeholder {
      color: ${colors.darkGray};
    }
    :disabled {
      cursor: not-allowed;
      background: #f8f8f8;
      ::placeholder {
        color: #a9a9a9;
      }
    }
  }
  select {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
    :disabled {
      border: 1px solid #000;
      cursor: not-allowed;
      background: #f5f5f5;
    }
  }
  .form-error {
    height: 35px;
    width: 100%;
    p {
      color: ${colors.red};
      font-size: 10px;
      font-weight: 500;
      margin: 0;
    }
  }
  .three-inputs {
    display: flex;
    justify-content: space-between;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    height: 650px;
    padding: 40px;
  }
`
