import styled from 'styled-components'
import { colors } from '../../../variables'

const ModalContainer = styled.div`
  width: 632px;
  ${'' /* height: 510px; */}
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 30px 40px 49px 30px;
  border-radius: 10px;
  background: ${colors.white};
  font-family: Montserrat;
  border-radius: 10px;

  .modal__close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;
  }

  .changeplan__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 29.26px;
    margin-bottom: 23px;
  }

  .changemodal__description {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;

    .changemodal__price__details {
      display: flex;
      flex-direction: column;
      margin-top: 30px;

      .changemodal__price__detail {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 18px;

        .price__number {
          font-weight: 600;
          line-height: 19.5px;
        }
      }

      .price__detail__last {
        padding-bottom: 10px;
        border-bottom: 1px solid #aaafbe;
      }

      .price__detail__total {
        margin-bottom: 0;
      }
    }

    .plan__next__payment {
      display: flex;
      justify-content: flex-end;
      padding-bottom: 20px;
      border-bottom: 1px solid #aaafbe;
      margin-bottom: 20px;

      .next__payment__text {
        font-size: 12px;
        font-weight: 600;
        line-height: 14.63px;
        color: #748596;
      }
    }
  }

  .account__payment__method {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 14px;

    .payment__method__card {
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.richBlack};
    }
  }

  .payment__method__border {
    margin-top: 30px;
    padding-top: 20px;
    border-top: 1px solid #aaafbe;
  }

  .account__changepayment {
    width: 100%;

    .changepayment__text {
      cursor: pointer;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      color: ${colors.lightseaGreen};
      margin-bottom: 51px;
    }
    .changepayment__text:hover {
      text-decoration: underline;
    }
  }

  .changepayment__buttons {
    display: flex;
    width: 100%;
    justify-content: space-between;

    .continue__actualplan__button,
    .change__actualplan__button {
      width: 250px;
      height: 50px;
    }
  }
`

export default ModalContainer
