import styled from 'styled-components'

export const BoardSelectionContainer = styled.div`
  margin-bottom: 40px;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
`
