import styled from 'styled-components'
import { colors } from '../../../variables'

export const CodeModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 632px;
  padding: 20px 40px 50px;
  border-radius: 4px;
  background: ${colors.white};

  h3 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
  }
  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
  }

  .code__description {
    margin-bottom: 50px;
  }

  .code__input {
    width: 100%;
    padding: 16px 0 16px 11px;
    border: 1px solid ${colors.richBlack};
    border-radius: 4px;
    outline: none;
    font-size: 14px;
    font-weight: 600;
    ::placeholder {
      color: ${colors.richBlack};
    }
  }
  button {
    margin-top: 25px;
    padding: 16px 0 16px 0;
  }
`
