import { NEWS_QUERY, TWEETS_QUERY } from './queries'
import {
  AUTHENTICATE_USER,
  CREATE_REPORT,
  CREATE_STRIPE_CUSTOMER,
  CREATE_TICKET,
  CREATE_USER,
  CREATE_WORKSPACE,
  DELETE_DASHBOARD_MUTATION,
  DELETE_SEARCH_MUTATION,
  DOTHEPLAN_CHANGE,
  DUPLICATE_DASHBOARD_MUTATION,
  NEW_DASHBOARD,
  NEW_SEARCH_MUTATION,
  UPDATE_SEARCH_MUTATION
} from './mutations'

export const sendGoogleAnalyticsEvent = (name, params) => {
  window.gtag('event', name, params)
}

export const getGoogleClientId = async () => {
  if (localStorage.getItem('googleClientId')) return localStorage.getItem('googleClientId')

  return new Promise((resolve, reject) => {
    window.gtag('get', window.googleTagId, 'client_id', clientId => {
      localStorage.setItem('googleClientId', clientId)
      resolve(clientId)
    })
  })
}

export const setUserId = id => {
  window.gtag('config', window.googleTagId, { user_id: id })
}

export const setUserProperties = properties => {
  window.gtag('set', 'user_properties', properties)
}

export const sendAnalyticsError = e => {
  const { message, filename, lineno, colno, error } = e
  const { stack } = error
  const { pathname } = window.location

  const errorData = {
    message,
    filename,
    lineno,
    colno,
    stack,
    pathname
  }

  sendGoogleAnalyticsEvent(EVENTS.ERROR, errorData)
}

export const EVENTS = {
  LOGIN: 'login',
  SIGNUP: 'sign_up',
  SEARCH_NOTES: 'search_notes',
  CREATE_SEARCH: 'create_search',
  UPDATE_SEARCH: 'update_search',
  DELETE_SEARCH: 'delete_search',
  CREATE_DASHBOARD: 'create_dashboard',
  DELETE_DASHBOARD: 'delete_dashboard',
  CREATE_CUSTOMER: 'create_customer',
  CHANGE_PLAN: 'change_plan',
  CREATE_REPORT: 'create_report',
  ERROR: 'error_ocurred'
}

export const saveGraphQLOperation = (operation, data) => {
  const getNameFromQuery = query => {
    return query.definitions[0].name.value
  }

  switch (operation.operationName) {
    case getNameFromQuery(AUTHENTICATE_USER):
      sendGoogleAnalyticsEvent(EVENTS.LOGIN, { method: 'email' })
      break
    case getNameFromQuery(CREATE_WORKSPACE):
      sendGoogleAnalyticsEvent(EVENTS.SIGNUP, { method: 'email' })
      break
    case getNameFromQuery(CREATE_USER):
      sendGoogleAnalyticsEvent(EVENTS.SIGNUP, { method: 'email' })
      break
    case getNameFromQuery(NEWS_QUERY):
      if (operation.variables.pagina === 1) {
        sendGoogleAnalyticsEvent(EVENTS.SEARCH_NOTES, { type: 'medios' })
      }
      break
    case getNameFromQuery(TWEETS_QUERY):
      if (operation.variables.pagina === 1) {
        sendGoogleAnalyticsEvent(EVENTS.SEARCH_NOTES, { type: 'twitter' })
      }
      break
    case getNameFromQuery(NEW_SEARCH_MUTATION):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_SEARCH, {
        type: operation.variables.input.source,
        query: operation.variables.input.query
      })
      break
    case getNameFromQuery(UPDATE_SEARCH_MUTATION):
      sendGoogleAnalyticsEvent(EVENTS.UPDATE_SEARCH, {
        query: operation.variables.input.query
      })
      break
    case getNameFromQuery(DELETE_SEARCH_MUTATION):
      sendGoogleAnalyticsEvent(EVENTS.DELETE_SEARCH)
      break
    case getNameFromQuery(NEW_DASHBOARD):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_DASHBOARD)
      break
    case getNameFromQuery(DUPLICATE_DASHBOARD_MUTATION):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_DASHBOARD)
      break
    case getNameFromQuery(DELETE_DASHBOARD_MUTATION):
      sendGoogleAnalyticsEvent(EVENTS.DELETE_DASHBOARD)
      break
    case getNameFromQuery(CREATE_STRIPE_CUSTOMER):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_CUSTOMER)
      break
    case getNameFromQuery(DOTHEPLAN_CHANGE):
      sendGoogleAnalyticsEvent(EVENTS.CHANGE_PLAN)
      break
    case getNameFromQuery(CREATE_REPORT):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_REPORT, { scheduled: false })
      break
    case getNameFromQuery(CREATE_TICKET):
      sendGoogleAnalyticsEvent(EVENTS.CREATE_REPORT, { scheduled: true })
      break
    default:
      break
  }
}
