import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const ReportNameFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  label {
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 10px;
    color: ${colors.darkGray};
  }
  input {
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    margin-bottom: 30px;
    padding: 0 10px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
  }
  button {
    margin-bottom: 40px;
  }
  textarea {
    height: 75px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    margin-bottom: 40px;
    padding: 10px 10px;
    outline: none;
    font-size: 16px;
    font-weight: 400;
  }
`
