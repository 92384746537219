import React from 'react'

const SearchIcon = () => (
  <svg>
    <g>
      <path d='M12.9079374,17.3721857 C11.9069678,17.7867632 10.8340979,18.0001564 9.75063589,18.0001564 C7.56245493,18.0001564 5.46390416,17.1309314 3.9166276,15.5837528 C2.36935104,14.0365741 1.50009783,11.9381211 1.50009783,9.7500847 C1.50009783,7.56202743 2.36935104,5.46359529 3.9166276,3.9164062 C5.46390416,2.3692171 7.56245493,1.50001303 9.75063589,1.50001303 C10.8340979,1.50001303 11.9069678,1.71340619 12.9079374,2.12801501 C13.9090114,2.54261339 14.8185664,3.15030911 15.5846337,3.9164062 C16.3508055,4.68249285 16.9584625,5.59197553 17.3731678,6.59291988 C17.7877687,7.59386423 18.001174,8.66667355 18.001174,9.7500847 C18.001174,10.8334854 17.7877687,11.9062947 17.3731678,12.9072078 C16.9584625,13.9082252 16.3508055,14.8177287 15.5846337,15.5837528 C14.8185664,16.3498812 13.9090114,16.9575038 12.9079374,17.3721857 Z M17.6134965,15.5161348 C18.8324629,13.8540682 19.5012718,11.8365898 19.5012718,9.7500847 C19.5012718,7.16419789 18.4740048,4.68422504 16.6453986,2.85573263 C14.8167924,1.02723814 12.3367002,0 9.75063589,0 C7.16460289,0 4.68448985,1.02723814 2.85589407,2.85573263 C1.02729621,4.68422504 0,7.16419789 0,9.7500847 C0,12.3360028 1.02729621,14.8159548 2.85589407,16.6444576 C4.68448985,18.4729605 7.16460289,19.5001694 9.75063589,19.5001694 C11.8366328,19.5001694 13.8537035,18.8318158 15.5154466,17.6136487 C15.5603191,17.6745883 15.6097832,17.7321888 15.6636302,17.7859284 L21.4390329,23.5610047 C21.7204774,23.8422245 22.1021023,24 22.5001108,24 C22.898015,24 23.2796399,23.8417028 23.5608757,23.5602742 C23.8421114,23.2787414 24,22.897138 24,22.4992563 C24,22.1012703 23.8415896,21.7197713 23.5601452,21.4384471 L17.7847424,15.6634752 C17.7313129,15.6099443 17.6741265,15.5606917 17.6134965,15.5161348 Z' />
    </g>
  </svg>
)

export default SearchIcon
