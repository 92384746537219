import React, { useState, useEffect, useRef, useContext } from 'react'
import { useHistory } from 'react-router-dom'
import moment from 'moment'
import { DashboardCardContainer } from '../../../style/components/dashboard/dashboards/DashboardCard.styled'
import { BsPencilSquare, BsThreeDots, BsTrash } from 'react-icons/bs'
import { MdWidgets } from 'react-icons/md'
import { AiOutlineUser } from 'react-icons/ai'
import { isMobile } from 'react-device-detect'
import { MyContext } from '../../../context'
import { RiFileCopyLine } from 'react-icons/all'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const DashboardCard = ({ dashboard, setDashboardToDelete, setDashboardToDuplicate }) => {
  const { dataUser: user } = useContext(MyContext)
  const history = useHistory()
  const dashboardOptions = useRef()

  const [showDashboardMenu, setShowDashboardMenu] = useState(false)

  const userIsAllowedToEdit = user && !dashboard.viewers.find(viewer => viewer.id === user.id)

  useOutsideClick(dashboardOptions, () => setShowDashboardMenu(false))

  return (
    <DashboardCardContainer>
      {!isMobile && userIsAllowedToEdit && (
        <div className='dashboard-menu' ref={dashboardOptions}>
          <button onClick={() => setShowDashboardMenu(!showDashboardMenu)}>
            <BsThreeDots />
          </button>
          {showDashboardMenu && (
            <div className='dashboard-options'>
              <button
                className='full_option'
                onClick={() => history.push(`/dashboards/dashboard/${dashboard.id}?editboard`)}
              >
                <BsPencilSquare />
                Editar tablero
              </button>
              <button
                className='full_option'
                onClick={() => history.push(`/dashboards/dashboard/${dashboard.id}?editmembers`)}
              >
                <AiOutlineUser />
                Editar miembros
              </button>
              <button className='full_option' onClick={() => setDashboardToDuplicate(dashboard)}>
                <RiFileCopyLine />
                Duplicar tablero
              </button>
              <button className='full_option delete_button' onClick={() => setDashboardToDelete(dashboard)}>
                <BsTrash />
                Eliminar
              </button>
            </div>
          )}
        </div>
      )}
      <div className='cardCont' onClick={() => history.push(`/dashboards/dashboard/${dashboard.id}`)}>
        <div className='card-header'>
          <div>
            <h2>{dashboard.name}</h2>
            <h6> {dashboard.description} </h6>
            <p>Última vez modificado: {moment(Number(dashboard.createdAt)).format('D MMM YY')}</p>
          </div>
        </div>
        <div className='card-content'>
          <div>
            <img src={dashboard.createdBy.photo} alt='User avatar' />
            <p>{dashboard.createdBy.name}</p>
          </div>
          <div>
            <MdWidgets />
            <p>{dashboard.widgets.length}</p>
            <AiOutlineUser />
            <p>{dashboard.editors.length + dashboard.viewers.length + 1}</p>
          </div>
        </div>
      </div>
    </DashboardCardContainer>
  )
}

export default DashboardCard
