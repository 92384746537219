import React, { useState, useRef, useEffect } from 'react'
import { ShareVoiceContainer, ChartContainer, Placeholder } from '../../style/components/widgets/ShareVolice.styled'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { ButtonSpinner } from '../../style/components'
import { PieChart, Pie, Sector } from 'recharts'

import { SHARE_VOICE_QUERY } from '../../services/queries'

const renderActiveShape = props => {
  const RADIAN = Math.PI / 180
  const { cx, cy, midAngle, innerRadius, outerRadius, startAngle, endAngle, fill, payload, percent, value } = props
  const sin = Math.sin(-RADIAN * midAngle)
  const cos = Math.cos(-RADIAN * midAngle)
  const sx = cx + (outerRadius + 10) * cos
  const sy = cy + (outerRadius + 10) * sin
  const mx = cx + (outerRadius + 30) * cos
  const my = cy + (outerRadius + 30) * sin
  const ex = mx + (cos >= 0 ? 1 : -1) * 22
  const ey = my
  const textAnchor = cos >= 0 ? 'start' : 'end'

  return (
    <g>
      <text x={cx} y={cy} dy={8} textAnchor='middle' fill={fill}>
        {payload.name}
      </text>
      <Sector
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
      <Sector
        cx={cx}
        cy={cy}
        startAngle={startAngle}
        endAngle={endAngle}
        innerRadius={outerRadius + 6}
        outerRadius={outerRadius + 10}
        fill={fill}
      />
      <path d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`} stroke={fill} fill='none' />
      <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} textAnchor={textAnchor} fill='#333'>
        {`Valor: ${value}`}
      </text>
      <text x={ex + (cos >= 0 ? 1 : -1) * 12} y={ey} dy={18} textAnchor={textAnchor} fill='#999'>
        {`(${(percent * 100).toFixed(2)}%)`}
      </text>
    </g>
  )
}

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  const [width, setWidth] = useState()
  const [activeIndex, setActiveIndex] = useState(0)

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const onPieEnter = (_, index) => setActiveIndex(index)

  const info = JSON.parse(data.shareVoice)

  return (
    <ChartContainer ref={container}>
      <PieChart width={width} height={300}>
        <Pie
          innerRadius={60}
          outerRadius={100}
          dataKey='notas'
          data={info}
          activeIndex={activeIndex}
          activeShape={renderActiveShape}
          fill='#8884d8'
          onMouseEnter={onPieEnter}
        />
      </PieChart>
    </ChartContainer>
  )
}

const ShareVoice = ({ widget, report, date }) => {
  const searchesIDs = widget.searches.map(search => search.id)
  const [period, setPeriod] = useState('day')

  const { data, loading, error } = useQuery(SHARE_VOICE_QUERY, {
    variables: {
      period,
      searchesIDs,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }
    return (
      <ShareVoiceContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>

        <Chart data={data} loading={loading} error={error} />
      </ShareVoiceContainer>
    )
  } else {
    return (
      <ShareVoiceContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'day' ? 'period--active' : 'period--inactive'}
            name='day'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Hoy
          </button>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'day' && <p>{moment().format('D MMM YY')}</p>}
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>

        <Chart data={data} loading={loading} error={error} />
      </ShareVoiceContainer>
    )
  }
}

export default ShareVoice
