import React, { useState } from 'react'
import { EditBoardContainer } from '../../../style/components/dashboard/dashboard/EditBoard.styled'

import CloseModal from '../../workspace/Modals/CloseModal'
import Button from '../../ui/Button'

const EditBoard = ({ dashboard, closeModal, handleEditDashboard }) => {
  const [name, setName] = useState(dashboard.name)
  const [description, setDescription] = useState(dashboard.description)

  const handleBoardNameChange = e => {
    setName(e.target.value)
  }

  const handleBoardDescriptionChange = e => {
    setDescription(e.target.value)
  }

  return (
    <EditBoardContainer>
      <CloseModal onClick={closeModal} />
      <h3 className='boardinfo__title'>Información del tablero</h3>
      <div className='boardinfo__form'>
        <label className='boardinfo__text'>Nombre del tablero</label>
        <input
          className='boardinfo__input'
          value={name}
          onChange={handleBoardNameChange}
          placeholder={name !== '' ? name : 'Nombre del tablero'}
        />
        <div className='boardinfo__description__header'>
          <label className='boardinfo__text'>Agrega una descripción (opcional)</label>
          <p className='boardinfo__text text__align--right'>{description.length}/300</p>
        </div>
        <textarea
          className='boardinfo__textarea'
          value={description}
          onChange={handleBoardDescriptionChange}
          placeholder='¿De qué trata este tablero?'
        />
      </div>
      <div className='boardinfo__buttons'>
        <Button variant={'link'} theme={'dark'} className='boardinfo__button' onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          theme={'primary'}
          className={'boardinfo__button'}
          disabled={name !== dashboard.name || description !== dashboard.description ? false : true}
          onClick={() => handleEditDashboard(name, description)}
        >
          Guardar
        </Button>
      </div>
    </EditBoardContainer>
  )
}

export default EditBoard
