import React, { useEffect } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import { useQuery, gql } from '@apollo/client'

import Sidebar from './components/sidebar/Sidebar'
import SidebarM from './components/sidebar/SidebarM'
import User from './components/user/User'
import FaqButton from './components/ui/FaqButton'

import Login from './pages/auth/Login'
import Signup from './pages/auth/Signup'
import Recover from './pages/auth/Recover'
import Password from './pages/auth/Password'
import Join from './pages/auth/Join'

import LoginM from './pages/authMobile/Login'
import RecoverM from './pages/authMobile/Recover'
import PasswordM from './pages/authMobile/Password'

import Searches from './pages/search/Searches'
import Search from './pages/search/Search'
import NewSearch from './pages/search/NewSearch'

import SearchesM from './pages/searchMobile/Searches'
import SearchM from './pages/searchMobile/Search'

import DashboardsM from './pages/dashboardMobile/Dashboards'
import DashboardM from './pages/dashboardMobile/Dashboard'

import Dashboards from './pages/dashboard/Dashboards'
import Dashboard from './pages/dashboard/Dashboard'
import NewDashboard from './pages/dashboard/NewDashboard'
import Widget from './pages/dashboard/Widget'
import OpenDashboard from './pages/dashboard/OpenDashboard'
import MobileDetection from './components/modules'
import { isMobile } from 'react-device-detect'

import Reports from './pages/report/Reports'
import NewReport from './pages/report/NewReport'
import TicketDetail from './pages/report/TicketDetail'
import EditReport from './pages/report/ReportEdit'

import Profile from './pages/profile/index'

import Workspace from './pages/workspace/Workspace'
import Media from './pages/media/Media'
import NewMedia from './pages/media/NewMedia'
import Faq from './pages/faq/Faq'

import ReportDetails from './pages/report/ReportDetails'
import Clipping from './pages/clippings'
import ClippingDetail from './pages/clippings/clippingDetail'
import ClippingDetailScheduled from './pages/clippings/clippingDetailScheduled'
import Notification from './components/workspace/Modals/Notification'
import { sendGoogleAnalyticsEvent, setUserId, setUserProperties } from './services/analytics'

const GET_USER = gql`
  query getUser {
    getUser {
      id
      name
      email
      photo
      role
      birthday
      age
      gender
      occupation
      degree
      onboarding
    }
  }
`

const ProtectedRoute = ({ component: Component, admin, ...rest }) => {
  const { data, loading, error } = useQuery(GET_USER)

  useEffect(() => {
    if (data) {
      const userData = data.getUser
      setUserId(userData.id)
      setUserProperties({
        name: userData.name,
        email: userData.email,
        role: userData.role,
        occupation: userData.occupation,
        degree: userData.degree,
        gender: userData.gender,
        age: userData.age
      })
    }

    //throw new Error('hola que tal')
  }, [data])

  if (loading) return null
  if (error && error.message.includes('Response not successful: Received status code 500')) {
    localStorage.removeItem('token')
    return <Redirect to='/login' />
  }
  if (error && error.message === 'User not logged in.') return <Redirect to='/login' />
  if (admin && data.getUser.role !== 'admin') return <Redirect to='/' />
  return (
    <Route
      {...rest}
      component={props => (
        <>
          {isMobile ? <SidebarM userRole={data.getUser.role} /> : <Sidebar userRole={data.getUser.role} />}
          {!isMobile && <FaqButton />}
          <User photo={data.getUser.photo} />
          <Component {...props} user={data.getUser} />
        </>
      )}
    />
  )
}

const Home = () => <Redirect to='/searches' />

const Router = () => {
  return isMobile ? (
    <>
      <Switch>
        <Route exact path='/login' component={LoginM} />
        <Route exact path='/recover' component={RecoverM} />
        <Route exact path='/recover/:code' component={PasswordM} />

        <ProtectedRoute exact path='/' component={Home} />

        <ProtectedRoute exact path='/searches' component={SearchesM} />
        <ProtectedRoute exact path='/searches/search/:id' component={SearchM} />

        <ProtectedRoute exact path='/dashboards' component={DashboardsM} />
        <ProtectedRoute exact path='/dashboards/dashboard/:id' component={DashboardM} />
        <Route component={MobileDetection} />
      </Switch>
    </>
  ) : (
    <>
      <Switch>
        <Route exact path='/login' component={Login} />
        <Route exact path='/signup' component={Signup} />
        <Route exact path='/recover' component={Recover} />
        <Route exact path='/recover/:code' component={Password} />
        <Route exact path='/join/:wsname/:code' component={Join} />

        <ProtectedRoute exact path='/' component={Home} />

        <ProtectedRoute exact path='/searches' component={Searches} />
        <ProtectedRoute exact path='/searches/search/:id' component={Search} />
        <ProtectedRoute exact path='/searches/new-search' component={NewSearch} />

        <ProtectedRoute exact path='/clipping/:id' component={ClippingDetail} />
        <ProtectedRoute exact path='/clipping-scheduled/:name/:id' component={ClippingDetailScheduled} />
        <Route exact path='/clipping-view/:id' component={Clipping} />

        <ProtectedRoute exact path='/dashboards' component={Dashboards} />
        <ProtectedRoute exact path='/dashboards/dashboard/:id' component={Dashboard} />
        <ProtectedRoute exact path='/dashboards/new-dashboard' component={NewDashboard} />
        <ProtectedRoute exact path='/dashboards/widget/:id' component={Widget} />
        <Route exact path='/open-dashboard' component={OpenDashboard} />

        <ProtectedRoute exact path='/reports' component={Reports} />
        <ProtectedRoute exact path='/reports/new-report' component={NewReport} />
        <ProtectedRoute exact path='/reports/scheduled/:id' component={TicketDetail} />
        <ProtectedRoute exact path='/reports/scheduled/edit/:id' component={EditReport} />

        <Route exact path='/reports/report/:id' component={ReportDetails} />

        <ProtectedRoute exact path='/profile' component={Profile} />
        <ProtectedRoute exact path='/workspace' component={Workspace} admin={true} />
        <ProtectedRoute exact path='/faq' component={Faq} />

        <ProtectedRoute exact path='/media-catalogue' component={Media} />
        <ProtectedRoute exact path='/media-catalogue/request-media' component={NewMedia} />
      </Switch>
      <Notification />
    </>
  )
}

export default Router
