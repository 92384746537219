import React, { useState } from 'react'
import {
  DetailWidget,
  TittleBar,
  IconWidget,
  WidgetInfo,
  WidgetButton,
  WidgetMedia,
  WidgetContainer,
  FooterWidget
} from '../../../style/components/reports/reportDetails/ReportWidget.styled'

import { widgets } from '../../../data/Widgets'

import {
  MencionesTwitter as MencionesTwitterImage,
  TonalidadTwitter as TonalidadTwitterImage,
  PublicadoresVolumen as PublicadoresVolumenImage,
  InfluencersTwitter as InfluencersTwitterImage,
  NubeHashtags as NubeHashtagsImage,
  TonalidadMedios as TonalidadMediosImage,
  NumeroNotas as NumeroNotasImage,
  MediosGanados as MediosGanadosImage,
  TipoMedios as TipoMediosImage,
  TemasMomento as TemasMomentoImage,
  MapaCalor as MapaCalorImage,
  ExposicionMedios as ExposicionMediosImage,
  ShareVoice as ShareVoiceImage
} from '../../../assets/Widgets'

import TonalidadMedios from '../../widgets/TonalidadMedios'
import NumeroNotas from '../../widgets/NumeroNotas'
import MediosGanados from '../../widgets/MediosGandos'
import TipoMedios from '../../widgets/TipoMedios'
import TemasMomento from '../../widgets/TemasMomento'
import MapaCalor from '../../widgets/MapaCalor'
import ExposicionMedios from '../../widgets/ExposicionMedios'
import ShareVoice from '../../widgets/ShareVoice'
import TonalidadTwitter from '../../widgets/TonalidadTwitter'
import NubeHashtagsTwitter from '../../widgets/NubeHashtagsTwitter'
import InfluencersTwitter from '../../widgets/InfluencersTwitter'
import MencionesTwitter from '../../widgets/MencionesTwitter'
import PublicadoresVolumenTwitter from '../../widgets/PublicadoresVolumenTwitter'

import twitterPNG from '../../../assets/reports/images/twitter.png'
import wwwPNG from '../../../assets/reports/images/www.png'

import { IconBlackReport } from '../../../assets/reports/icons/IconBlack'
import Button from '../../ui/Button'

// import { isMacOs } from "react-device-detect";

const iconSize = {
  width: '80px',
  height: '80px'
}

const ReportWidgets = ({ reportSection, comments, date, handleDownloadPDF, width, height, hideButton }) => {
  const [zoomLevel, setZoomLevel] = useState(Math.round(window.devicePixelRatio * 100))

  const getSizes = () => {
    // const screenCssPixelRatio = (window.outerWidth - 8) / window.innerWidth;
    let browserZoomLevel = Math.round(window.devicePixelRatio * 100)
    // setZoomLevel(screenCssPixelRatio)
    setZoomLevel(browserZoomLevel)
  }

  const isRetinaDisplay = () => {
    // Fucku retina display
    if (window.matchMedia) {
      const mq = window.matchMedia(
        'only screen and (min--moz-device-pixel-ratio: 1.3), only screen and (-o-min-device-pixel-ratio: 2.6/2), only screen and (-webkit-min-device-pixel-ratio: 1.3), only screen  and (min-device-pixel-ratio: 1.3), only screen and (min-resolution: 1.3dppx)'
      )
      // eslint-disable-next-line no-mixed-operators
      return (mq && mq.matches) || window.devicePixelRatio > 1
    }
  }

  const validateZoom = () => {
    if (isRetinaDisplay()) return zoomLevel !== 200
    else return zoomLevel !== 100
  }

  window.addEventListener('resize', getSizes, false)

  const typeDictionary = {
    Medios: 'Tradicionales',
    Twitter: 'Digitales'
  }

  const widgetKind = widget => widgets.filter(e => e.componentName === widget.kind)[0].type

  return reportSection.map((widget, i) => (
    <DetailWidget key={i} id={widget.id} style={{ width: `${width}`, height: `${height}` }}>
      <TittleBar>
        <IconWidget>
          {/* Twiiter */}
          {widget.kind === 'MencionesTwitter' && <MencionesTwitterImage {...iconSize} />}
          {widget.kind === 'TonalidadTwitter' && <TonalidadTwitterImage {...iconSize} />}
          {widget.kind === 'PublicadoresVolumen' && <PublicadoresVolumenImage {...iconSize} />}
          {widget.kind === 'InfluencersTwitter' && <InfluencersTwitterImage {...iconSize} />}
          {widget.kind === 'NubeHashtags' && <NubeHashtagsImage {...iconSize} />}
          {/* Medios */}
          {widget.kind === 'TonalidadMedios' && <TonalidadMediosImage {...iconSize} />}
          {widget.kind === 'NumeroNotas' && <NumeroNotasImage {...iconSize} />}
          {widget.kind === 'MediosGanados' && <MediosGanadosImage {...iconSize} />}
          {widget.kind === 'TipoMedios' && <TipoMediosImage {...iconSize} />}
          {widget.kind === 'TemasMomento' && <TemasMomentoImage {...iconSize} />}
          {widget.kind === 'MapaCalor' && <MapaCalorImage {...iconSize} />}
          {widget.kind === 'ExposicionMedios' && <ExposicionMediosImage {...iconSize} />}
          {widget.kind === 'ShareVoice' && <ShareVoiceImage {...iconSize} />}
        </IconWidget>
        <WidgetInfo>
          <h2>{widget.name}</h2>
          <p>{widgets.filter(e => e.componentName === widget.kind)[0].description}</p>
        </WidgetInfo>
        <WidgetButton>
          <Button
            size={'full'}
            weight={500}
            theme={'secondary'}
            hidden={i !== 0 || hideButton}
            disabled={validateZoom()}
            onClick={() => handleDownloadPDF()}
          >
            {validateZoom() ? (
              <>
                Descargar PDF <br /> <small>El zoom debe de ser del 100%</small>
              </>
            ) : (
              'Descargar PDF'
            )}
          </Button>
        </WidgetButton>
      </TittleBar>
      <WidgetMedia>
        <img src={widgetKind(widget) === 'Twitter' ? twitterPNG : wwwPNG} alt={typeDictionary[widgetKind(widget)]} />
        <p>{typeDictionary[widgetKind(widget)]}</p>
      </WidgetMedia>
      <WidgetContainer>
        {(() => {
          switch (widget.kind) {
            // MEDIOS TRADCIONALES
            case 'TonalidadMedios':
              return <TonalidadMedios widget={widget} report={true} date={date} />
            case 'NumeroNotas':
              return <NumeroNotas widget={widget} report={true} date={date} />
            case 'MediosGanados':
              return <MediosGanados widget={widget} report={true} date={date} />
            case 'TipoMedios':
              return <TipoMedios widget={widget} report={true} date={date} />
            case 'TemasMomento':
              return <TemasMomento widget={widget} report={true} date={date} />
            case 'MapaCalor':
              return <MapaCalor widget={widget} report={true} date={date} />
            case 'ExposicionMedios':
              return <ExposicionMedios widget={widget} report={true} date={date} />
            case 'ShareVoice':
              return <ShareVoice widget={widget} report={true} date={date} />
            // MEDIOS DIGITALES
            case 'TonalidadTwitter':
              return <TonalidadTwitter widget={widget} report={true} date={date} />
            case 'NubeHashtags':
              return <NubeHashtagsTwitter widget={widget} report={true} date={date} />
            case 'InfluencersTwitter':
              return <InfluencersTwitter widget={widget} report={true} date={date} />
            case 'MencionesTwitter':
              return <MencionesTwitter widget={widget} report={true} date={date} />
            case 'PublicadoresVolumen':
              return <PublicadoresVolumenTwitter widget={widget} report={true} date={date} />
            default:
              return null
          }
        })()}
      </WidgetContainer>
      <FooterWidget>
        <div className='userNotes'>
          <h2 hidden={comments[i] === ''}>Notas de usuarios</h2>
          <p hidden={comments[i] === ''}>{comments[i]}</p>
        </div>
        <div className='iconBlack'>
          <IconBlackReport />
        </div>
      </FooterWidget>
      <hr />
    </DetailWidget>
  ))
}

export default ReportWidgets
