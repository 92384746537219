import React, { useState } from 'react'
import DropdownContainer from '../../style/components/workspace/Dropdown,styled'

import Arrow from '../../assets/arrow'
import IconCheck from '../../assets/IconCheck'

const Dropdown = ({ inviteRole, setInviteRole }) => {
  const [dropdown, setDropdown] = useState(false)

  const setRole = rol => {
    setDropdown(!dropdown)
    setInviteRole(rol)
  }

  return (
    <DropdownContainer onClick={() => setDropdown(!dropdown)}>
      <div className='dropdown__role'>
        <p
          className={
            inviteRole === 'Administrador' || inviteRole === 'Colaborador'
              ? 'user__role__selected'
              : 'user__select__role'
          }
        >
          {inviteRole}
        </p>
        <div className={`arrow ${dropdown ? 'arrow__rotate' : ''}`}>
          <Arrow fill='#748596' />
        </div>
      </div>
      <div className={dropdown ? 'menu-visible' : 'menu-closed'}>
        <div className='dropdown-option' onClick={() => setRole('Administrador')}>
          <div className='dropdown__options__header'>
            <h5 className='options__header__title'>Administrador</h5>
            {inviteRole === 'Administrador' ? <IconCheck /> : ''}
          </div>
          <p>Usuario con todos los permisos y accesos dentro de la plataforma. Planes, pagos, facturas, etc.</p>
        </div>
        <div className='dropdown-option' onClick={() => setRole('Colaborador')}>
          <div className='dropdown__options__header'>
            <h5 className='options__header__title'>Colaborador</h5>
            {inviteRole === 'Colaborador' ? <IconCheck /> : ''}
          </div>
          <p>Puede crear, agregar, editar y eliminar búsquedas, tableros y usuarios.</p>
        </div>
      </div>
    </DropdownContainer>
  )
}

export default Dropdown
