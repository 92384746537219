import { gql } from '@apollo/client'

export const SEARCHES_QUERY = gql`
  query getSearches {
    searches {
      id
      query
      name
      source
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const DASHBOARD_WIDGETS_QUERY = gql`
  query getDashboardWidgets($dashboardID: ID) {
    dashboardWidgets(dashboardID: $dashboardID) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
    }
  }
`

export const GET_REPORT_USERS = gql`
  query getReportUsers {
    getReportUsers {
      id
      name
      email
      photo
    }
  }
`

export const GET_USER = gql`
  query getUser {
    getUser {
      name
      email
      photo
      role
      birthday
      age
      gender
      occupation
      degree
      onboarding
    }
  }
`

export const NEWS_QTY_QUERY = gql`
  query getNewsQty($input: SearchObject) {
    newsQty(input: $input)
  }
`

export const NEWS_QUERY = gql`
  query getNews($input: SearchObject) {
    news(input: $input) {
      ambito
      clasificacion
      estado
      fecha
      id
      autor
      medio
      nombre_medio
      testigo
      texto
      tipo_medio
      titulo
      tendencia
    }
  }
`

export const TWEETS_QTY_QUERY = gql`
  query getTweetsQty($input: SearchObject) {
    tweetsQty(input: $input)
  }
`

export const TWEETS_QUERY = gql`
  query getTweets($input: SearchObject) {
    tweets(input: $input) {
      date
      location
      profile_image
      text
      url
      user
    }
  }
`

export const MENCIONES_TWITTER_QUERY = gql`
  query getMencionesTwitter($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    mencionesTwitter(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const INFLUENCERS_TWITTER = gql`
  query getInfluencers($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    influencers(searchID: $searchID, period: $period, report: $report, date: $date)
  }
`

export const NUBE_HASHTAGS_TWITTER = gql`
  query getNubeHashtags($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    nubeHashtags(searchID: $searchID, period: $period, report: $report, date: $date)
  }
`

export const PUBLICADORES_TWITTER = gql`
  query getPublicadoresVol($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    publicadoresVolumen(searchID: $searchID, period: $period, report: $report, date: $date)
  }
`

export const EXPOSICION_QUERY = gql`
  query getExposicion($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    exposicion(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const MAPA_CALOR_QUERY = gql`
  query getMapaCalor($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    mapaCalor(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const MEDIOS_GANADOS_QUERY = gql`
  query getMediosGandos($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    mediosGanados(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const NUBE_PALABRAS_MEDIOS = gql`
  query getNubePalabras($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    nubePalabras(searchID: $searchID, period: $period, report: $report, date: $date)
  }
`

export const NUMERO_NOTAS_QUERY = gql`
  query getNumeroNotas($period: String, $searchesIDs: [ID], $report: Boolean, $date: [String]) {
    numeroNotas(period: $period, searchesIDs: $searchesIDs, report: $report, date: $date)
  }
`

export const SHARE_VOICE_QUERY = gql`
  query getShareVoice($period: String, $searchesIDs: [ID], $report: Boolean, $date: [String]) {
    shareVoice(period: $period, searchesIDs: $searchesIDs, report: $report, date: $date)
  }
`

export const TIPO_MEDIOS_QUERY = gql`
  query getTipoMedios($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    tipoMedios(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const TONALIDAD_MEDIOS_QUERY = gql`
  query getTonalidadMedios($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    tonalidadMedios(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const TONALIDAD_TWITTER_QUERY = gql`
  query getTonalidad($period: String, $searchID: ID, $report: Boolean, $date: [String]) {
    tonalidad(period: $period, searchID: $searchID, report: $report, date: $date)
  }
`

export const SUBSCRIPTION_QUERY = gql`
  query getSubscription {
    subscription {
      cancel_at
      cancel_at_period_end
      current_period_end
      billing_cycle_anchor
    }
  }
`

export const GET_WORKSPACE = gql`
  query getWorkspace {
    getWorkspace {
      id
      name
      description
      searches {
        id
        name
        source
        createdBy {
          name
          photo
        }
        updatedAt
      }
      dashboards {
        id
        name
        widgets {
          id
        }
        editors {
          id
        }
        viewers {
          id
        }
        createdBy {
          name
          photo
        }
        updatedAt
      }
      plan {
        type
        searchesQty
        dashboardsQty
        usersQty
      }
    }
  }
`

export const GET_WORKSPACE_USERS = gql`
  query getWorkspaceUsers {
    getWorkspaceUsers {
      id
      name
      email
      role
      photo
    }
  }
`
export const GET_ELEGIBLE_WORKSPACE_USERS = gql`
  query getElegibleWorkspaceUsers {
    getElegibleWorkspaceUsers {
      id
      name
      email
      role
      photo
    }
  }
`

export const GET_WORKSPACE_INVITES = gql`
  query getWorkspaceInvites {
    getWorkspaceInvites {
      id
      email
      role
    }
  }
`

export const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      default
      brand
      month
      year
      last4
    }
  }
`

export const GET_BILL_INFO = gql`
  query getBillInfo {
    getBillInfo {
      billName
      rfc
      billEmail
      street
      exterior
      interior
      cp
      state
      alcMun
      colonia
      required
    }
  }
`

export const INVOICES_QUERY = gql`
  query getInvoices {
    invoices
  }
`

export const GET_CURRENT_PLAN = gql`
  query getCurrentPlan {
    getCurrentPlan
  }
`

export const VALID_CODE_QUERY = gql`
  query isValidCode($code: String) {
    validCode(code: $code) {
      benefit
      value
      duration {
        value
        unit
      }
    }
  }
`

export const GET_ELEGIBLE_USERS = gql`
  query getElegibleUsers($dashboardId: ID!) {
    getElegibleUsers(dashboardId: $dashboardId) {
      id
      name
      email
      photo
    }
  }
`

export const GET_DASHBOARD = gql`
  query getDashboard($id: ID!) {
    getDashboard(id: $id) {
      id
      name
      description
      createdBy {
        id
        name
        email
        photo
      }
      editors {
        id
        name
        email
        photo
      }
      viewers {
        id
        name
        email
        photo
      }
    }
  }
`

export const DASHBOARDS_QUERY = gql`
  query getDashboards {
    dashboards {
      id
      name
      description
      createdBy {
        name
        photo
      }
      widgets {
        id
        kind
        name
      }
      editors {
        id
      }
      viewers {
        id
      }
      createdAt
    }
  }
`

export const SHARED_DASHBOARDS_QUERY = gql`
  query getSharedDashboards {
    sharedDashboards {
      id
      name
      description
      createdBy {
        name
        photo
      }
      widgets {
        id
      }
      editors {
        id
      }
      viewers {
        id
      }
      createdAt
    }
  }
`

export const USER_QUERY = gql`
  query getUser {
    getUser {
      name
      email
      id
      photo
    }
  }
`

export const WIDGET_QUERY = gql`
  query getWidget($id: ID!) {
    widget(id: $id) {
      id
      name
      kind
      searches {
        id
        name
        source
        createdBy {
          name
          photo
        }
        createdAt
      }
      dashboard {
        id
        name
      }
    }
  }
`

export const MEDIA_QUERY = gql`
  query getMedia {
    media {
      clave
      descripcion
      medio
      estado
      cobertura
      subtipo
      activo
    }
  }
`

export const REQUESTED_MEDIA_QUERY = gql`
  query getRequestedMedia {
    requestedMedia {
      id
      name
      description
      type
      subtype
    }
  }
`

export const REPORTS_QUERY = gql`
  query getReports {
    reports {
      id
      name
      number
      period
      description
      type
      reportHour
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const TICKETS_QUERY = gql`
  query getTickets {
    tickets {
      id
      name
      description
      schedule
      reportHour
      active
      paused
      editable
      dashboard {
        name
      }
      createdBy {
        name
        photo
      }
      users {
        id
      }
      createdAt
      counter
    }
  }
`

export const GET_REPORT = gql`
  query getReport($id: ID!) {
    getReport(id: $id) {
      name
      dashboard {
        id
      }
      description
      date
      number
      widgets {
        id
        name
        kind
        searches {
          id
          name
          query
        }
        styleParams {
          width
        }
      }
      comments
      sharedSchedule
    }
  }
`

export const GET_TICKET = gql`
  query getTicket($id: ID!) {
    getTicket(id: $id) {
      id
      name
      editable
      widgets {
        kind
        name
      }
      description
      date
      days
      comments
      widgets {
        kind
        name
        id
      }
      dashboard {
        id
        name
        widgets {
          id
          kind
        }
      }
      type
      period
      hour
      schedule
      duration
      reportHour
      createdBy {
        name
      }
      users {
        id
        name
        email
        photo
      }
      reports {
        id
        name
        number
        reportHour
        createdAt
      }
    }
  }
`

export const SEARCH_QUERY = gql`
  query getSearch($id: ID!) {
    search(id: $id) {
      id
      name
      source
      query
      filters {
        states
        media
      }
    }
  }
`

export const CLIPPING_QUERY = gql`
  query getClippings($searchID: ID) {
    getClippings(searchID: $searchID) {
      id
      name
      email
      exportType
      notes
      noteQty
      startDate
      endDate
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const CLIPPINGTICKETS_QUERY = gql`
  query getTicketClipping($searchID: ID) {
    getTicketClipping(searchID: $searchID) {
      id
      name
      email
      exportType
      startHour
      endHour
      periodName
      periodDays
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const CLIPPINGSBYTICKET_QUERY = gql`
  query getClippingsByTicket($ticketID: ID) {
    getClippingsByTicket(ticketID: $ticketID) {
      id
      name
      email
      exportType
      notes
      noteQty
      startDate
      endDate
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const GET_CLIPPING = gql`
  query getClipping($id: ID) {
    getClipping(id: $id) {
      id
      name
      exportType
      notes
    }
  }
`
