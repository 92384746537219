import { gql } from '@apollo/client'

export const DASHBOARD_WIDGETS_SUBSCRIPTION = gql`
  subscription onWidgetChanged($dashboardID: ID) {
    widgetChanged(dashboardID: $dashboardID) {
      id
      name
      kind
      searches {
        id
        name
        query
      }
      styleParams {
        width
      }
      operation
      createdBy {
        id
      }
    }
  }
`

export const WIDGETS_MOVE_SUBSCRIPTION = gql`
  subscription onWidgetMove($dashboardID: ID) {
    widgetMoved(dashboardID: $dashboardID)
  }
`

export const REPORTS_SUBSCRIPTION = gql`
  subscription onReportCreated {
    reportCreated {
      id
      name
      description
      type
      period
      reportHour
      createdBy {
        name
        photo
      }
      createdAt
    }
  }
`

export const SEARCHES_SUBSCRIPTION = gql`
  subscription onSerchChanged {
    searchChanged {
      id
      query
      name
      source
      createdBy {
        name
        photo
      }
      createdAt
      operation
    }
  }
`
