import React, { useState } from 'react'
import PeriodDropdown from '../../../components/ui/PeriodDropdown'
import { ButtonsSection, Container } from '../../../style/components/search/searches/ClippinModal.styled'

const ContactClippingModal = ({ closeModal }) => {
  const [period, setPeriod] = useState([])

  const periods = [
    {
      id: '0',
      name: '150 notas',
      notes: 150
    },
    {
      id: '1',
      name: '200 notas',
      notes: 200
    },
    {
      id: '2',
      name: '250 notas',
      notes: 250
    },
    {
      id: '3',
      name: '300 notas',
      notes: 300
    },
    {
      id: '4',
      name: 'más de 300 notas',
      notes: 301
    }
  ]

  return (
    <Container>
      <h3>Solicitud aumento de notas para clipping</h3>
      <p style={{ marginBottom: '28px', textAlign: 'justify' }}>
        Para llevar a cabo la solicitud de incluir más de 100 notas en tus clippings programados, elige la opción con el
        número de notas que deseas incluir y en breve el equipo de ventas se pondrá en contacto contigo.
      </p>
      <p style={{ marginBottom: '5px', width: '100%' }}>Elige la opción con el número de notas deseadas</p>
      <PeriodDropdown
        width='100%'
        title='Elige una cantidad'
        items={periods}
        selection={period}
        setSelection={setPeriod}
      />

      <p style={{ marginTop: '21px', marginBottom: '17px', width: '100%' }}>¿Por qué deseas más notas? (opcional)</p>
      <textarea
        placeholder='Escribe aquí tu comentario'
        style={{
          width: '100%',
          height: '88px'
        }}
      />
      <ButtonsSection mt='25px'>
        <button className='cancelButton' onClick={() => closeModal(false)}>
          Cancelar
        </button>
        <button className='exportButton'>Enviar solicitud</button>
      </ButtonsSection>
    </Container>
  )
}

export default ContactClippingModal
