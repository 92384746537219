import React from 'react'
import { NoteContainer } from '../../../style/components/search/new-serches/NoteM.styled'
import { Television, Radio, Internet, Newspaper } from '../../../assets/Media'

const Note = ({ note }) => {
  return (
    <NoteContainer>
      <div className='note--header'>
        <div className='note--title'>
          <p>{note.nombre_medio}</p>
        </div>
        <div className='note--icon'>
          {note.medio === 'Periódicos' ? (
            <Newspaper />
          ) : note.medio === 'Internet' ? (
            <Internet />
          ) : note.medio === 'Radio' ? (
            <Radio />
          ) : note.medio === 'Televisión' ? (
            <Television />
          ) : null}
        </div>
      </div>
      <div className='note--content'>
        <div className='note--actions'>
          <p>{note.estado}</p>
          <p>{note.fecha.split('').slice(0, 10).join('')}</p>
        </div>
        <p>{note.texto}</p>
      </div>
      <div className='note--footer'>
        <div className='note--button'>
          <a href={note.testigo} target='_blank' rel='noopener noreferrer'>
            Ver fuente
          </a>
        </div>
      </div>
    </NoteContainer>
  )
}

export default Note
