import styled from 'styled-components'
import { colors } from '../../../variables'

export const Container = styled.div`
  height: 100%;
  width: 100%;
  hr {
    border: 1px solid #aaafbe;
  }
`

export const TableHead = styled.div`
  width: 100%;
  padding: 12px;
  display: flex;
  justify-content: space-evenly;
  margin-top: 15px;
`

export const Head = styled.div`
  width: 20%;
  font-family: 'Montserrat';
  font-weight: 600;
  font-style: normal;
  font-size: 16px;
  line-height: 19.5px;
`
export const TableRow = styled.div`
  display: flex;
  padding: 12px;
  align-items: center;
  width: 100%;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.125847);
  border-radius: 4px;
  margin-bottom: 10px;
  transition: ease-in-out 0.2s;
  transform: translate(0, 0px);
  :hover {
    transform: translate(0, -3px);
    background: ${colors.lightGray};
  }
`

export const Row = styled.div`
  width: 20%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  font-family: Montserrat;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 20px;
  color: #748596;
  :hover {
    cursor: pointer;
  }
  /* p{
        cursor: pointer;
        color: ${colors.lightseaGreen};
        text-decoration: underline;
        text-decoration-color: ${colors.lightseaGreen};
        :hover{
            text-decoration-color: ${colors.silverSand};
            color: ${colors.silverSand};
        }
    } */
  img {
    cursor: pointer;
  }
  svg {
    cursor: pointer;
  }
`

export const NoData = styled.div`
  width: 100%;
  height: 50vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .container {
    height: 264px;
    width: 500px;
    .icon {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .text {
      p {
        font-family: Montserrat;
        font-style: normal;
        font-weight: 600;
        font-size: 22px;
        line-height: 35px;
        text-align: center;
      }
    }
  }
`
