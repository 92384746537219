import React from 'react'
import { InfoModalContainer } from '../../../style/components/reports/reports/infoModal.styled'
import Button from '../../ui/Button'

export const InfoModal = ({ children, setOpenInfo }) => {
  return (
    <InfoModalContainer>
      {children}
      <div className='modal-buttons'>
        <Button theme={'secondary'} className='ok-button' onClick={() => setOpenInfo(false)}>
          OK
        </Button>
      </div>
    </InfoModalContainer>
  )
}
