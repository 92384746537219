import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const QueEsContainer = styled.div`
  width: 100%;
  h4 {
    padding-top: 41px;

    font-family: Montserrat;
    font-size: 22px;
    color: ${colors.lightseaGreen};
  }
  p {
    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.richBlack};
    text-align: left;
  }
`
