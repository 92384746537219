import styled from 'styled-components'
import { buttonColors, colors } from '../../../variables'

export const TraditionalSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  .advanced-search-bar {
    display: flex;
    justify-content: center;
    margin-bottom: 30px;
    width: 90%;
    .advanced-search-button {
      margin-left: 20px;
    }
    .filter-search-button {
      margin-left: 20px;
    }
  }
  .no-results {
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    & > p {
      width: 100%;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
    }
    & > button {
      border: none;
      outline: none;
      background: none;
      color: ${colors.lightseaGreen};
      font-size: 15px;
      font-weight: 500;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
    }
  }
  .search-results {
    width: 90%;
    height: calc(100vh - 245px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .results-header {
    border-bottom: 2px solid ${colors.lightGray};
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 90%;
    .total-articles {
      margin-bottom: 20px;
      font-size: 21px;
      font-weight: 600;
    }
    .results-period {
      display: flex;
      align-items: center;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .date-input {
    width: 130px;
    margin: 0 10px 0 10px;
  }
  .date-button {
    padding: 0px 15px;
  }
`

export const FiltersContainer = styled.aside`
  z-index: 30;
  position: absolute;
  top: 0;
  right: 0;
  width: 700px;
  height: 100vh;
  padding: 50px;
  border-radius: 10px 0 0 10px;
  background: #ffffff;
`
