import React from 'react'

import { ErrorGMobileModal as Container } from '../../style/components/modals/ErrorGMobileModal.styled'
import Button from '../ui/Button'

const ErrorGModal = () => {
  return (
    <Container>
      <h3>Inicio de sesión detectado</h3>
      <p>Lo sentimos, hemos detectado un nuevo inicio de sesión con esta cuenta, por lo que esta sesión ha expirado</p>
      <Button size={'full'} theme={'secondary'} weight={500} onClick={() => window.open('/login', '_self')}>
        Ok, entiendo
      </Button>
    </Container>
  )
}

export default ErrorGModal
