import React from 'react'
import CloseModal from './CloseModal'
import { InfoContainer, DetailContainer } from '../../../style/components/workspace/Modals/InfoModal.styled'
import { Spinner } from '../../../style/components'
import { GrClose } from 'react-icons/gr'
import moment from 'moment'
import Button from '../../ui/Button'
moment.locale('es')

export const InfoModal = ({
  loading,
  setShowInfoModal,
  successInfo,
  billingCycle,
  nextCharge,
  errorInfo,
  message,
  handleShowContactForm,
  anualToMonthFlag,
  paymenthDetail,
  dataDetailPaymeth,
  dataFutureInvoice,
  handleBackAction
}) => {
  const moveDecimal = n => {
    const v = Math.abs(n) / Math.pow(10, 2)
    return v.toFixed(2)
  }
  console.log(message)

  const formatNumber = n => {
    const number = new Intl.NumberFormat().format(n)
    return number
  }

  if (loading)
    return (
      <InfoContainer>
        <Spinner />
      </InfoContainer>
    )

  if (paymenthDetail)
    return (
      <DetailContainer>
        <h3>Detalle de cobro</h3>
        <p>A continuación verás los detalles de cobro de tu nuevo plan:</p>
        <div className='statusAcount' id='scrollable'>
          {dataDetailPaymeth.data.map((e, i) => (
            <div className='acoutnItem' key={i}>
              <div className='leftContainer'>
                <p>
                  <span>{e.description}</span>
                </p>
              </div>
              <div className='rightContainer'>
                <p>
                  {e.amount < 1
                    ? `- $${formatNumber(moveDecimal(parseInt(e.amount)))}`
                    : `$${formatNumber(moveDecimal(parseInt(e.amount)))}`}
                </p>
              </div>
            </div>
          ))}
        </div>
        <div className='statusAcount'>
          <div className='borderDecoration'>
            <div className='acoutnItem'>
              <div className='leftContainer'>
                <p>
                  <span>Total:</span>
                </p>
              </div>
              <div className='rightContainer'>
                <p>
                  <strong>${formatNumber(moveDecimal(dataFutureInvoice.subAndTotal.total))} MXN</strong>
                </p>
              </div>
            </div>
          </div>
        </div>
        <Button size={'full'} onClick={() => handleBackAction()}>
          ¡Entendido!
        </Button>
      </DetailContainer>
    )
  if (successInfo)
    return (
      <InfoContainer>
        <CloseModal onClick={() => setShowInfoModal(false)} />
        <h3>Operación exitosa</h3>
        <h3 className='messageInfo'>¡Disfruta tu nuevo plan!</h3>
        <div className='rememberInfo'>
          {anualToMonthFlag ? (
            <p>Recuerda que tu plan mensual empezará de forma automática el:</p>
          ) : (
            <p>Recuerda que el siguiente cargo se realizará de forma automática el:</p>
          )}
        </div>
        <div className='next__payment__container'>
          <span className='next__payment__text'>
            {anualToMonthFlag
              ? moment(nextCharge * 1000).format('DD [de] MMMM [del] YYYY')
              : moment(nextCharge * 1000)
                  .add(1, billingCycle)
                  .format('DD [de] MMMM [del] YYYY')}
          </span>
        </div>

        <Button size={'full'} onClick={() => setShowInfoModal(false)}>
          Entendido
        </Button>
      </InfoContainer>
    )

  return (
    <InfoContainer>
      <div className='modal__close'>
        <div className='close__icon'>
          <GrClose onClick={() => setShowInfoModal(false)} />
        </div>
      </div>

      <h3>Solicitud enviada</h3>
      <p>
        Tu solicitud para cambiar de plan a sido enviada. Nuestro equipo de ventas revisará la petición y en breve se
        pondrá en contacto contigo para dar seguimiento a tu caso.
      </p>
      <br />
      <p className='changeplan__request__paragraph'>
        Mientras tanto puedes seguir haciendo uso de tu plan actual y disfrutar de todas las ventajas que te ofrece
        MyOblek.
      </p>
      <Button size={'full'} onClick={() => setShowInfoModal(false)}>
        Entendido
      </Button>
    </InfoContainer>
  )
}
