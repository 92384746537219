import React, { useState } from 'react'

import { TweetContainer } from '../../../style/components/search/new-serches/TweetM.styled'

import { FiTwitter } from 'react-icons/fi'
import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'

const Tweet = ({ tweet }) => {
  const [source, setSource] = useState(tweet.profile_image)
  const onError = () =>
    setSource('https://res.cloudinary.com/tuinforma-com/image/upload/v1603210969/MyOblek/gray_qzw9gv.jpg')

  return (
    <TweetContainer>
      <div className='tweet-card-header'>
        <div className='tc-header-info'>
          <img src={source} onError={onError} alt='twitter avatar' />
          <div className='header-text'>
            <h5>@{tweet.user}</h5>
            <h6>{tweet.location}</h6>
          </div>
        </div>
        <div className='tc-header-icon'>
          <FiTwitter />
        </div>
      </div>
      <div className='tweet-text'>
        <div className='tweet-date-section'>
          <div className='tweet-date'>
            <AiOutlineCalendar />
            <p>{tweet.date.split('').splice(0, 10).join('')}</p>
          </div>
          <div className='tweet-time'>
            <AiOutlineClockCircle />
            <p>{tweet.date.split('').splice(11, 10).join('')} hrs</p>
          </div>
        </div>
        <p className='tweet-content'>{tweet.text}</p>
      </div>
      <div className='footer-button'>
        <a href={tweet.url} target='_blank' rel='noopener noreferrer'>
          Abrir tweet
        </a>
      </div>
    </TweetContainer>
  )
}

export default Tweet
