import React, { useState } from 'react'
import { NoteContainer } from '../../../style/components/search/new-serches/Note.styled'
import { Television, Radio, Internet, Newspaper } from '../../../assets/Media'
import { BsCheckBox, BsSquare } from 'react-icons/bs'
import Button from '../../ui/Button'
// import { AiOutlineCalendar, AiOutlineClockCircle } from 'react-icons/ai'

const Note = ({ note, reportTestigo, setNotes, notesA, allFlag, setFlag }) => {
  const [selected, setSelected] = useState(false)

  const handleSelection = () => {
    setFlag(false)
    setSelected(!selected)
    if (!selected) setNotes([...notesA, note])
    else setNotes(notesA.filter(e => e.titulo !== note.titulo))
  }

  return (
    <NoteContainer>
      <div className='note--header'>
        {false && (
          <div className='note-checkbox'>
            {allFlag || selected ? (
              <button className='checkbox-indicator' onClick={() => handleSelection()}>
                <BsCheckBox className='checked' />
              </button>
            ) : (
              <button className='checkbox-indicator' onClick={() => handleSelection()}>
                <BsSquare className='not-checked' />
              </button>
            )}
          </div>
        )}
        <div className='note--title'>
          <h3>
            {note.nombre_medio.includes('/')
              ? note.nombre_medio.substring(0, note.nombre_medio.indexOf('/'))
              : note.nombre_medio}
          </h3>
          <p>{note.estado}</p>
        </div>
        <div className='note--icon'>
          {note.medio === 'Periódicos' ? (
            <Newspaper />
          ) : note.medio === 'Internet' ? (
            <Internet />
          ) : note.medio === 'Radio' ? (
            <Radio />
          ) : note.medio === 'Televisión' ? (
            <Television />
          ) : null}
        </div>
      </div>
      <div className='note--content'>
        <h3> {note.titulo} </h3>
        <p>{note.texto}</p>
      </div>
      <div className='note--date'>
        <div className='califi'>
          <span
            className={`circle circle${
              note.tendencia === 'Negativa' ? '--negativa' : note.tendencia === 'Neutra' ? '--neutra' : '--positiva'
            }`}
          />
          <p>{note.tendencia}</p>
        </div>
        <div className='date'>
          <p>{note.fecha.split('').slice(0, 10).join('')}</p>
          <p className='vLine'>|</p>
          <p>{note.fecha.split('').slice(10, 16).join('')}</p>
        </div>
      </div>
      <div className='note--footer'>
        <div className='note--actions'>
          <a href={note.testigo} target='_blank' rel='noopener noreferrer'>
            Ver Fuente
          </a>
          <Button weight={500} variant={'link'} onClick={() => reportTestigo(note)}>
            Reportar
          </Button>
        </div>
      </div>
    </NoteContainer>
  )
}

export default Note
