import React, { useState } from 'react'

import CloseModal from '../../workspace/Modals/CloseModal'
import { DuplicateBoardContainer } from '../../../style/components/dashboard/dashboard/DuplicateBoard.styled'
import Button from '../../ui/Button'

const DuplicateBoard = ({ dashboard, closeModal, handleDuplicateDashboard }) => {
  const [name, setName] = useState(dashboard.name)
  const [description, setDescription] = useState(dashboard.description)

  return (
    <DuplicateBoardContainer>
      <CloseModal onClick={closeModal} />
      <h3 className='boardinfo__title'>Duplicar tablero</h3>
      <p>
        Al duplicar el tablero <span>{dashboard.name}</span>, se creará un tablero igual, con los mismos gráficos, y con
        los mismos editores.
      </p>
      <div className='boardinfo__form'>
        <label className='boardinfo__text'>Nombre del duplicado</label>
        <input
          className='boardinfo__input'
          value={name}
          onChange={e => setName(e.target.value)}
          placeholder={name !== '' ? name : 'Nombre del tablero'}
        />
        <div className='boardinfo__description__header'>
          <label className='boardinfo__text'>Descripción del duplicado</label>
          <p className='boardinfo__text text__align--right'>{description.length}/300</p>
        </div>
        <textarea
          className='boardinfo__textarea'
          value={description}
          onChange={e => setDescription(e.target.value)}
          placeholder='¿De qué trata este tablero?'
        />
      </div>
      <div className='boardinfo__buttons'>
        <Button variant={'link'} theme={'dark'} className='boardinfo__button' onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          className={'boardinfo__button'}
          theme={'primary'}
          disabled={!name}
          onClick={() => handleDuplicateDashboard({ ...dashboard, name, description })}
        >
          Guardar
        </Button>
      </div>
    </DuplicateBoardContainer>
  )
}

export default DuplicateBoard
