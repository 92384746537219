import React from 'react'
import { ReportFiltersContainer } from '../../../style/components/reports/reports/ReportFilters.styled'
import { GrClose } from 'react-icons/gr'

const ReportFilters = ({ sidebar, showFilters, filters }) => {
  return (
    <ReportFiltersContainer sidebar={sidebar}>
      <div className='filters--header'>
        <h4>Filtros</h4>
        <GrClose onClick={() => showFilters(false)} />
      </div>

      <div className='filters--content'>
        {filters.needed.includes('name') && (
          <>
            <label>Nombre del reporte</label>
            <input type='text' value={filters.name} onChange={e => filters.setName(e.target.value)} />
          </>
        )}

        {filters.needed.includes('user') && (
          <>
            <label>Autor del reporte</label>
            <input type='text' value={filters.user} onChange={e => filters.setUser(e.target.value)} />
          </>
        )}

        {filters.needed.includes('date') && (
          <>
            <h5>Fecha del reporte</h5>
            <div className='date'>
              <label>Desde</label>
              <input type='date' value={filters.from} onChange={e => filters.setFrom(e.target.value)} />
            </div>
            <div className='date'>
              <label>Hasta</label>
              <input type='date' value={filters.to} onChange={e => filters.setTo(e.target.value)} />
            </div>
          </>
        )}

        {filters.needed.includes('type') && (
          <>
            <h5>Tipo de reporte</h5>
            <div className='type'>
              <div
                className='checkbox'
                onClick={() => (filters.type === 'once' ? filters.setType('') : filters.setType('once'))}
              >
                <input type='checkbox' checked={filters.type === 'once'} />
                <label>Única vez</label>
              </div>
              <div
                className='checkbox'
                onClick={() => (filters.type === 'scheduled' ? filters.setType('') : filters.setType('scheduled'))}
              >
                <input type='checkbox' checked={filters.type === 'scheduled'} />
                <label>Programado</label>
              </div>
            </div>
          </>
        )}

        {filters.needed.includes('frecuency') && (
          <>
            <h5>Frecuencia</h5>
            <div className='frecuency'>
              <div
                className='checkbox'
                onClick={() =>
                  filters.frecuency === 'Diario' ? filters.setFrecuency('') : filters.setFrecuency('Diario')
                }
              >
                <input type='checkbox' checked={filters.frecuency === 'Diario'} />
                <label>Diario</label>
              </div>
              <div
                className='checkbox'
                onClick={() =>
                  filters.frecuency === 'Semanal' ? filters.setFrecuency('') : filters.setFrecuency('Semanal')
                }
              >
                <input type='checkbox' checked={filters.frecuency === 'Semanal'} />
                <label>Semanal</label>
              </div>
              <div
                className='checkbox'
                onClick={() =>
                  filters.frecuency === 'Quincenal' ? filters.setFrecuency('') : filters.setFrecuency('Quincenal')
                }
              >
                <input type='checkbox' checked={filters.frecuency === 'Quincenal'} />
                <label>Quincenal</label>
              </div>
              <div
                className='checkbox'
                onClick={() =>
                  filters.frecuency === 'Mensual' ? filters.setFrecuency('') : filters.setFrecuency('Mensual')
                }
              >
                <input type='checkbox' checked={filters.frecuency === 'Mensual'} />
                <label>Mensual</label>
              </div>
              <div
                className='checkbox'
                onClick={() =>
                  filters.frecuency === 'Personalizado'
                    ? filters.setFrecuency('')
                    : filters.setFrecuency('Personalizado')
                }
              >
                <input type='checkbox' checked={filters.frecuency === 'Personalizado'} />
                <label>Personalizado</label>
              </div>
            </div>
          </>
        )}

        {filters.needed.includes('active') && (
          <>
            <h5>Estatus</h5>
            <div className='status'>
              <div
                className='checkbox'
                onClick={() => (filters.active === 'active' ? filters.setActive('') : filters.setActive('active'))}
              >
                <input type='checkbox' checked={filters.active === 'active'} />
                <label>Activo</label>
              </div>
              <div
                className='checkbox'
                onClick={() => (filters.active === 'inactive' ? filters.setActive('') : filters.setActive('inactive'))}
              >
                <input type='checkbox' checked={filters.active === 'inactive'} />
                <label>Inactivo</label>
              </div>
            </div>
          </>
        )}

        {filters.needed.includes('paused') && (
          <>
            <h5>Estatus</h5>
            <div className='status'>
              <div
                className='checkbox'
                onClick={() => (filters.paused === 'paused' ? filters.setPaused('') : filters.setPaused('paused'))}
              >
                <input type='checkbox' checked={filters.paused === 'paused'} />
                <label>Pausado</label>
              </div>
              <div
                className='checkbox'
                onClick={() => (filters.paused === 'play' ? filters.setPaused('') : filters.setPaused('play'))}
              >
                <input type='checkbox' checked={filters.paused === 'play'} />
                <label>Corriendo</label>
              </div>
            </div>
          </>
        )}
      </div>
    </ReportFiltersContainer>
  )
}

export default ReportFilters
