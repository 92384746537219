import React from 'react'
import { CodeModalContainer } from '../../style/components/auth/CodeModal/CodeModal.styled'
import { Spinner } from '../../style/components'

import CloseModal from '../workspace/Modals/CloseModal'
import Button from '../ui/Button'

const CodeModal = ({
  code,
  handleCode,
  handleCodeValidation,
  codeError,
  closeError,
  loading,
  validCode,
  promotion,
  closeModal
}) => {
  if (loading)
    return (
      <CodeModalContainer>
        <Spinner />
      </CodeModalContainer>
    )

  if (codeError)
    return (
      <CodeModalContainer>
        <CloseModal onClick={closeModal} />
        <h3>Código inválido</h3>
        <p>Lo sentimos, tu código no es válido o ya ha caducado.</p>
        <Button onClick={() => closeError()} className='button-active'>
          Cerrar
        </Button>
      </CodeModalContainer>
    )

  if (validCode && promotion)
    return (
      <CodeModalContainer>
        <CloseModal onClick={closeModal} />
        <h3>¡Felicidades!</h3>
        <p>Tienes {promotion.benefit} para disflutar todos los beneficios de MyOblek.</p>
        <Button weight={500} theme={'secondary'} size={'full'} onClick={() => closeModal()} className='button-active'>
          Cerrar
        </Button>
      </CodeModalContainer>
    )

  return (
    <CodeModalContainer>
      <CloseModal onClick={closeModal} />
      <h3>Ingresa tu código</h3>
      <p className='code__description'>
        MyOblek te ofrece diferentes beneficios dependiendo del tipo de código con el que cuentes.
      </p>
      <input
        className='code__input'
        placeholder='Código de descuento'
        value={code}
        onChange={e => handleCode(e.target.value)}
      />
      <Button
        weight={500}
        theme={'secondary'}
        size={'full'}
        onClick={() => handleCodeValidation()}
        className={code === '' ? 'button-inactive' : 'button-active'}
      >
        Aplicar
      </Button>
    </CodeModalContainer>
  )
}

export default CodeModal
