import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const GraficosContainer = styled.div`
  width: 100%;
  h4 {
    padding-top: 41px;

    font-family: Montserrat;
    font-size: 22px;
    color: ${colors.lightseaGreen};
  }
  h5 {
    padding-top: 20px;

    font-family: Montserrat;
    font-size: 22px;
    color: #6873e5;
  }
  p {
    margin-top: 15px;

    font-family: Montserrat;
    font-weight: 400;
    font-size: 16px;
    color: ${colors.richBlack};
    text-align: left;
  }
  strong {
    margin-top: 15px;

    font-family: Montserrat;
    font-weight: bold 400;
    font-size: 16px;
    color: #fe596f;
    text-align: left;
  }
  .imgUno {
    /* border: 1px solid blue; */
    display: block;
    margin: 32px auto;

    max-width: 508px;
    min-height: 770px;
    max-height: 770px;
  }
  .imgDos {
    display: block;
    margin: 32px auto;

    /* max-width: 742px; */
    min-height: 400px;
    max-height: 400px;
  }
  section {
    /* padding-top: 5rem; */
    padding-bottom: 1.5rem;
  }
`
