import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { SidebarContainer } from '../../style/components/sidebar/sidebar.styled'
import { UIContext } from '../../contexts/UIContext'

import logo from '../../assets/logo.svg'
import SearchIcon from '../../assets/SearchIcon'
import DashboardIcon from '../../assets/DashboardIcon'
import SettingsIcon from '../../assets/icons/SettingsIcon'
import ProfileIcon from '../../assets/ProfileIcon'
import ReportIcon from '../../assets/ReportIcon'
import CatalogueIcon from '../../assets/CatalogueIcon'

const Sidebar = ({ userRole }) => {
  // CORREGIR ESTE CÓDIGO
  const uiCTX = useContext(UIContext)
  const { collapsed } = uiCTX.state
  const { handleSidebar } = uiCTX
  // ---------------

  const isCollapsed = () => {
    return collapsed ? 'sidebar-collapsed' : 'sidebar-not-collapsed'
  }

  return (
    <SidebarContainer className='sidebar-container'>
      <div id={isCollapsed()} className='sidebar'>
        <div className='sidebar-header'>
          <NavLink to='/' className='sidebar-logo'>
            <img src={logo} alt='MyOblek logo' />
          </NavLink>
          <button className='sidebar-burger' onClick={handleSidebar}>
            <div className='burger-line' />
            <div className='burger-line' />
            <div className='burger-line' />
          </button>
        </div>
        <nav className='sidebar-nav'>
          <NavLink to='/searches' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <SearchIcon />
            </div>
            <div className='sidebar-item-name'>Búsquedas</div>
          </NavLink>

          <NavLink to='/dashboards' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <DashboardIcon />
            </div>
            <div className='sidebar-item-name'>Tableros</div>
          </NavLink>
          <NavLink to='/reports' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <ReportIcon className='report-icon' />
            </div>
            <div className='sidebar-item-name'>Reportes</div>
          </NavLink>
          <NavLink to='/media-catalogue' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <CatalogueIcon />
            </div>
            <div className='sidebar-item-name'>Medios</div>
          </NavLink>
          <NavLink to='/profile' className='sidebar-navlink'>
            <div className='sidebar-icon'>
              <ProfileIcon />
            </div>
            <div className='sidebar-item-name'>Perfil</div>
          </NavLink>
          {userRole === 'admin' && (
            <NavLink to='/workspace' className='sidebar-navlink'>
              <div className='sidebar-icon'>
                <SettingsIcon />
              </div>
              <div className='sidebar-item-name'>Workspace</div>
            </NavLink>
          )}
        </nav>
      </div>
    </SidebarContainer>
  )
}

export default Sidebar
