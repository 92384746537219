import React, { useState } from 'react'
import { WidgetsSelectionContainer, WidgetCard } from '../../style/components/reports/WidgetsSecction.styled'
import { FaRegCommentDots } from 'react-icons/fa'
import { colors } from '../../style/variables'
import {
  TonalidadTwitter,
  TonalidadTwitterDisabled,
  PublicadoresVolumen,
  PublicadoresVolumenDisabled,
  NubeHashtags,
  NubeHashtagsDisabled,
  MapaCalor,
  MapaCalorDisabled,
  ExposicionMedios,
  ExposicionMediosDisabled,
  NumeroNotas,
  NumeroNotasDisabled,
  TonalidadMedios,
  TemasMomento,
  TipoMedios,
  TipoMediosDisabled,
  ShareVoice,
  ShareVoiceDisabled,
  MencionesTwitter,
  InfluencersTwitter,
  MediosGanados,
  MencionesTwitterDisbled,
  TemasMomentoDisabled,
  InfluencersTwitterDisabled,
  TonalidadMediosDisabled,
  MediosGanadosDisabled
} from '../../assets/Widgets'
import Button from '../ui/Button'

const WidgetsSelection = ({ board, widgets, setAllWidgets, handleWidget, comments, handleComments }) => {
  const [commentsOpen, setCommentsOpen] = useState([])

  const handleCommentsOpen = id => {
    const commentsArr = [...commentsOpen]
    if (commentsArr.includes(id)) {
      commentsArr.splice(commentsArr.indexOf(id), 1)
    } else {
      commentsArr.push(id)
    }
    setCommentsOpen(commentsArr)
  }

  return (
    <WidgetsSelectionContainer>
      <h2>Selecciona los widgets de tu tablero</h2>
      <p>
        Puedes seleccionar todos o cada uno de los widgets de tu tablero para crear el reporte que necesitas. También
        puedes agregar una nota a cada widget.
      </p>
      {board && (
        <>
          <label htmlFor='all-widgets'>
            <input
              type='checkbox'
              id='all-widgets'
              value='all'
              checked={board.widgets.length === widgets.length ? true : false}
              onChange={() => setAllWidgets(board)}
            />
            Seleccionar todos
          </label>
          {board.widgets.map((widget, idx) => {
            return widgets.includes(widget.id) ? (
              <WidgetCard key={widget.id}>
                <div className='widget-card'>
                  <div className='widget-icon' onClick={() => handleWidget(widget.id)}>
                    {/* Twiiter */}
                    {widget.kind === 'MencionesTwitter' && <MencionesTwitter />}
                    {widget.kind === 'TonalidadTwitter' && <TonalidadTwitter />}
                    {widget.kind === 'PublicadoresVolumen' && <PublicadoresVolumen />}
                    {widget.kind === 'InfluencersTwitter' && <InfluencersTwitter />}
                    {widget.kind === 'NubeHashtags' && <NubeHashtags />}
                    {/* Medios */}
                    {widget.kind === 'TonalidadMedios' && <TonalidadMedios />}
                    {widget.kind === 'NumeroNotas' && <NumeroNotas />}
                    {widget.kind === 'MediosGanados' && <MediosGanados />}
                    {widget.kind === 'TipoMedios' && <TipoMedios />}
                    {widget.kind === 'TemasMomento' && <TemasMomento />}
                    {widget.kind === 'MapaCalor' && <MapaCalor />}
                    {widget.kind === 'ExposicionMedios' && <ExposicionMedios />}
                    {widget.kind === 'ShareVoice' && <ShareVoice />}
                  </div>
                  <div className='widget-content'>
                    <h6>{widget.name}</h6>
                    {comments[idx] !== '' ? (
                      <button className='comment-exists' onClick={() => handleCommentsOpen(widget.id)}>
                        <FaRegCommentDots />1 comentario
                      </button>
                    ) : (
                      <button onClick={() => handleCommentsOpen(widget.id)}>
                        <FaRegCommentDots />
                        Agregar nota
                      </button>
                    )}
                  </div>
                </div>
                {commentsOpen.includes(widget.id) && (
                  <>
                    <textarea value={comments[idx]} onChange={e => handleComments(e, idx)} />
                    <div className='comment-buttons'>
                      <Button
                        variant={'link'}
                        theme={'dark'}
                        size={'small'}
                        height={'40px'}
                        onClick={e => {
                          handleCommentsOpen(widget.id)
                          handleComments(e, idx, true)
                        }}
                      >
                        Cancelar
                      </Button>
                      <Button
                        variant={'outline'}
                        size={'small'}
                        height={'40px'}
                        onClick={() => handleCommentsOpen(widget.id)}
                        disabled={comments[idx] === '' ? true : false}
                        style={
                          comments[idx] === ''
                            ? { borderColor: `${colors.lightGray}`, color: `${colors.lightGray}` }
                            : null
                        }
                      >
                        Guardar
                      </Button>
                    </div>
                  </>
                )}
              </WidgetCard>
            ) : (
              <WidgetCard key={widget.id}>
                <div className='widget-card widget-card-disabled'>
                  <div className='widget-icon' onClick={() => handleWidget(widget.id)}>
                    {/* Twiiter */}
                    {widget.kind === 'MencionesTwitter' && <MencionesTwitterDisbled />}
                    {widget.kind === 'TonalidadTwitter' && <TonalidadTwitterDisabled />}
                    {widget.kind === 'PublicadoresVolumen' && <PublicadoresVolumenDisabled />}
                    {widget.kind === 'InfluencersTwitter' && <InfluencersTwitterDisabled />}
                    {widget.kind === 'NubeHashtags' && <NubeHashtagsDisabled />}
                    {/* Medios */}
                    {widget.kind === 'TonalidadMedios' && <TonalidadMediosDisabled />}
                    {widget.kind === 'NumeroNotas' && <NumeroNotasDisabled />}
                    {widget.kind === 'MediosGanados' && <MediosGanadosDisabled />}
                    {widget.kind === 'TipoMedios' && <TipoMediosDisabled />}
                    {widget.kind === 'TemasMomento' && <TemasMomentoDisabled />}
                    {widget.kind === 'MapaCalor' && <MapaCalorDisabled />}
                    {widget.kind === 'ExposicionMedios' && <ExposicionMediosDisabled />}
                    {widget.kind === 'ShareVoice' && <ShareVoiceDisabled />}
                  </div>
                  <div className='widget-content'>
                    <h6>{widget.kind}</h6>
                    {comments[idx] !== '' ? (
                      <button className='button-disabled' disabled={true}>
                        <FaRegCommentDots />1 comentario
                      </button>
                    ) : (
                      <button className='button-disabled' disabled={true}>
                        <FaRegCommentDots />
                        Agregar nota
                      </button>
                    )}
                  </div>
                </div>
              </WidgetCard>
            )
          })}
        </>
      )}
    </WidgetsSelectionContainer>
  )
}

export default WidgetsSelection
