import { gql, useMutation, useQuery, useSubscription } from '@apollo/client'
import React, { createContext, useEffect, useState } from 'react'
import { withRouter } from 'react-router-dom'
import RepayModal from './components/auth/RepayModal'
import ErrorGModal from './components/modals/ErrorGModal'
import ErrorGMobileModal from './components/modals/ErrorGMobileModal'
import NewPaymentMethod from './components/workspace/NewPaymentMethod'
import { clearCache } from './config/apollo'
import { Shadow, Spinner } from './style/components'

import { isMobile } from 'react-device-detect'

export const MyContext = createContext()

const LOGIN_SUBSCRIPTION = gql`
  subscription onLogin {
    loginCatch {
      uuid
    }
  }
`

const GET_USER = gql`
  query getUser {
    getUser {
      uuid
      role
      email
      id
      name
    }
  }
`

const GET_WS = gql`
  query getWorkspace {
    getWorkspace {
      workspaceStatus {
        active
        paymenthStatus
      }
      stripe {
        subscriptionId
      }
    }
  }
`

const GET_PAYMENT_METHODS = gql`
  query getPaymentMethods {
    getPaymentMethods {
      id
      default
      brand
      month
      year
      last4
    }
  }
`

const RETRY_PAYMET = gql`
  mutation retryInvoicePayment($subscriptionID: String) {
    retryInvoicePayment(subscriptionID: $subscriptionID)
  }
`

const MyProvider = ({ children, history }) => {
  const [skipStatus, setSkipStatus] = useState(true)

  const { data: dataUser, loading: loadingUser, refetch: refetchUser } = useQuery(GET_USER)

  const { data: dataWs, loading: loadingWs, startPolling, stopPolling } = useQuery(GET_WS)

  const { data: paymentMethods, loading: paymentMethodsLoading, refetch: refetchPM } = useQuery(GET_PAYMENT_METHODS)

  const [retryInvoicePayment, { loading: loadingRetryPaymeth }] = useMutation(RETRY_PAYMET)

  const { data } = useSubscription(LOGIN_SUBSCRIPTION, { skip: skipStatus })

  const [showErrorMessage, setShowErrorMessage] = useState(false)
  const [showSuccess, setShowSuccess] = useState(false)
  const [showRepayModal, setShowRepayModal] = useState(false)
  const [showAddPaymethModal, setShowAddPaymethModal] = useState(false)
  const [showNotification, setShowNotification] = useState(false)
  const [notificationText, setNotificationText] = useState('')

  const [showUSWarning, setShowUSWarning] = useState(false)

  useEffect(() => {
    if (dataUser) startPolling(2000)
    return () => stopPolling()
  }, [stopPolling, startPolling, dataUser])

  useEffect(() => {
    // This IF handdle the wsChange (Repayment request)
    if (!loadingWs && dataWs !== undefined) {
      if (!dataWs.getWorkspace.workspaceStatus.active) {
        setShowRepayModal(true)
      } else setShowRepayModal(false)
    }
  }, [dataUser, data, dataWs, loadingWs])

  /* CTX FUNCTIONS */

  const doLogOut = () => {
    setSkipStatus(true)
    stopPolling()
    setShowRepayModal(false)
    setShowAddPaymethModal(false)
  }

  const activeSubscriptions = () => {
    refetchPM()
    refetchUser()
    setSkipStatus(false)
  }

  /* HANDLERS */

  const handleAddPaymenthMethod = () => {
    setShowRepayModal(false)
    setShowAddPaymethModal(true)
  }

  const handleBackEvent = () => {
    setShowAddPaymethModal(false)
    setShowRepayModal(true)
    refetchPM()
  }

  const handleUserLogOut = () => {
    localStorage.removeItem('token')
    clearCache()
    doLogOut()
    history.push('/login')
  }

  const handleRetryPaymenth = async () => {
    try {
      await retryInvoicePayment({
        variables: {
          subscriptionID: dataWs.getWorkspace.stripe.subscriptionId
        }
      })
      setShowRepayModal(false)
      setShowSuccess(true)
    } catch ({ message }) {
      setShowErrorMessage(true)
      setTimeout(() => {
        setShowErrorMessage(false)
      }, 3000)
    }
  }

  const showNotifications = async () => {
    setShowNotification(true)
    setTimeout(() => {
      setShowNotification(false)
    }, 5000)
  }

  const handleErrors = ({ message }) => {
    console.log(`[Error handler]: ${message}`)
    if (message.includes('uniqueSession')) {
      doLogOut()
      clearCache()
      localStorage.removeItem('token')
      setShowUSWarning(true)
    }
    // TODO: Show an alert if error isn't about session
  }

  if (paymentMethodsLoading || loadingUser || loadingWs)
    return (
      <MyContext.Provider>
        <Spinner />
      </MyContext.Provider>
    )

  return (
    <MyContext.Provider
      value={{
        /*Functions*/
        activeSubscriptions,
        doLogOut,
        handleErrors,
        showNotification,
        setShowNotification,
        notificationText,
        setNotificationText,
        showNotifications,
        /*Values*/
        dataUser: dataUser ? dataUser.getUser : null
      }}
    >
      {/* The APP */}
      {children}
      {/* RePay MODALS */}
      {dataUser !== undefined && paymentMethods !== undefined && showRepayModal && (
        <>
          <Shadow />
          <RepayModal
            loading={loadingRetryPaymeth}
            cards={paymentMethods !== undefined ? paymentMethods.getPaymentMethods : []}
            admin={dataUser.getUser.role === 'admin' ? true : false}
            handleAddPaymenthMethod={handleAddPaymenthMethod}
            handleRetryPaymenth={handleRetryPaymenth}
            handleUserLogOut={handleUserLogOut}
            refetchPM={refetchPM}
            error={showErrorMessage}
          />
        </>
      )}
      {showSuccess && (
        <>
          <Shadow onClick={() => setShowSuccess(false)} />
          <RepayModal success closeModal={setShowSuccess} />
        </>
      )}
      {showAddPaymethModal && (
        <>
          <Shadow />
          <NewPaymentMethod setNewCard={e => handleBackEvent()} backEvent={handleBackEvent} />
        </>
      )}
      {/* Error Modal - US */}
      {showUSWarning && !isMobile && (
        <>
          <Shadow />
          <ErrorGModal />
        </>
      )}
      {/* Error Modal Mobile - US */}
      {showUSWarning && isMobile && (
        <>
          <Shadow />
          <ErrorGMobileModal />
        </>
      )}
    </MyContext.Provider>
  )
}

export default withRouter(MyProvider)
