import styled from 'styled-components'
import { colors } from '../../variables'

export const PlanContainer = styled.div`
  display: flex;
  padding-top: 40px;
  font-family: Montserrat;

  .actual-plan {
    width: 40%;
    height: calc(100vh - 110px);
    border-right: 1px solid ${colors.lightGray};
    overflow-y: scroll;
  }

  .plan__section__title {
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
    color: ${colors.richBlack};
    margin: 30px 0 24px;
  }

  .plan__section__text {
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    margin-bottom: 24px;
    color: ${colors.richBlack};
  }

  .cancel-subscription {
    padding-top: 30px;
    border-top: 1px solid ${colors.lightGray};
    position: relative;
    button {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border: none;
      outline: none;
      background: none;
      color: ${colors.red};
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
      &:disabled {
        cursor: not-allowed;
      }
      svg {
        width: 15px;
        height: 15px;
        margin-right: 3px;
      }
    }
    p {
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
    }
  }

  .reactivate-subscription {
    padding-top: 30px;
    border-top: 1px solid ${colors.lightGray};
    position: relative;
    button {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      border: none;
      outline: none;
      background: none;
      color: ${colors.lightseaGreen};
      font-size: 16px;
      font-weight: 500;
      svg {
        width: 15px;
        height: 15px;
        margin-right: 3px;
      }
    }
    p {
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
    }
  }
  .change-plan {
    width: 60%;
    padding: 0 0 74px 30px;
    height: calc(100vh - 56px);
    overflow-y: scroll;
  }

  .plan__resume__container {
    overflow-y: scroll;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .actual-plan {
      padding-bottom: 15px;
      .description__plan__cost {
        .plan__type {
          width: 168px;
        }
      }
    }
  }

  @media (min-width: 1440px) {
    .change-plan {
      .products {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }

  @media (min-width: 2560px) {
    .change-plan {
      .products {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }
  }
`

export const PlanResume = styled.article`
  .plan__resume__title {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    color: ${colors.richBlack};
  }

  .plan__resume__benefits {
    display: flex;
    flex-direction: column;
    margin-top: 37px;
    padding-right: 4%;
  }

  .plan__resume__benefit {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 18px;
    padding-right: 17px;

    .benefit__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${colors.richBlack};
    }

    .benefit__detail {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      color: ${colors.richBlack};
    }
  }

  .benefit__total {
    margin-bottom: 0;
    padding-right: 0;
  }

  .benefits__end__border {
    border-bottom: 1px solid #c6cad5;
    padding-bottom: 10px;
  }

  .plan__next__payment {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 32px;
    padding-right: 4%;

    .next__payment__text {
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      color: #748596;
    }
  }

  .plan-switch {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 30px;
    padding-right: 4%;

    .plan__switch__content {
      display: flex;
      flex-direction: row;

      .switch__plantype {
        margin: 0;
        width: auto;
        font-size: 14px;
        font-weight: 400;
        color: ${colors.richBlack};
        text-align: end;
        &.bold {
          font-weight: 800;
          color: ${colors.richBlack};
        }
      }
    }
  }
`

export const PlanCard = styled.article`
  background: ${colors.white};
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  width: 95%;
  height: 170px;
  margin-bottom: 50px;
  position: relative;

  .no__discount {
    display: flex;
    justify-content: flex-end;

    .discount__text {
      background-color: #fff;
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      padding: 3px 16px 3.36px 13px;
      color: #fff;
    }
  }

  .product__discount {
    display: flex;
    justify-content: flex-end;

    .discount__text {
      background-color: ${colors.torquioseBlue};
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      padding: 3px 16px 3.36px 13px;
      color: ${colors.richBlack};
    }
  }

  .card-header {
    height: 40px;
    border-bottom: 1px solid ${colors.lightGray};
    margin: 0 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .product__name {
      font-size: 22px;
      font-weight: 400;
      line-height: 35px;
      color: ${colors.richBlack};
    }

    .product__price {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      width: 60%;

      .price__text {
        font-size: 24px;
        font-weight: 600;
        line-height: 29.26px;
        color: ${colors.richBlack};
      }

      .not-available {
        color: ${colors.darkGray};
      }
    }
  }

  .card-content {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 10px 0 0 20px;

    .product__description__container {
      width: 500px;

      .product__description {
        font-size: 12px;
        font-weight: 500;
        line-height: 14.63px;
        color: ${colors.richBlack};
        margin-right: 7px;
      }
    }
  }

  .benefits {
    width: 150px;
    height: 60px;
    padding-left: 17px;
    margin-bottom: 30px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    border-left: 1px solid #aaafbe;

    .benefits__description {
      display: flex;
      align-items: center;
      font-size: 12px;
      margin-bottom: 8px;
    }
  }

  .selected__plan__button,
  .change__plan__button {
    position: absolute;
    width: 350px;
    height: 40px;
    padding: 0px 84px;
    top: 150px;
    left: 50%;
    margin-left: -175px;
    z-index: 1;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .card-content {
      .product__description__container {
        width: 370px;
      }
    }
  }

  @media (min-width: 1440px) {
    width: 646px;
  }

  @media (min-width: 2560px) {
    width: 745px;
    .card-content {
      .product__description__container {
        width: 535px;
      }
    }
  }
`

export const ChangePlanInfo = styled.div`
  width: 96%;
  padding-top: 30px;
  border-top: 1px solid #aaafbe;
  font-family: Montserrat;
  color: ${colors.richBlack};

  .changeplaninfo__header {
    font-size: 18px;
    font-weight: 600;
    line-height: 21.94px;
    margin-bottom: 20px;
  }

  .changeplaninfo__text {
    font-size: 12px;
    font-weight: 500;
    line-height: 14.63px;
  }

  .changeplaninfo__description {
    margin-top: 20px;

    .description__plan__cost {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      font-size: 18px;
      font-weight: 600;
      line-height: 21.94px;
      margin-bottom: 11px;
    }

    .next__payment {
      font-size: 12px;
      font-weight: 600;
      line-height: 14.63px;
      color: #748596;
    }
  }
`
