import React, { useEffect } from 'react'
import { DashboardContainer, WidgetsContainer } from '../../style/pages/dashboardMobile/Dashboard.styled'
import { HiArrowLeft } from 'react-icons/hi'

import Widget from '../../components/dashboard/dashboard/WidgetM'
import NoWidgets from '../../components/dashboard/dashboard/NoWidgets'

import { MainContainer, Topbar, Spinner } from '../../style/components'

import { useQuery } from '@apollo/client'

import { GET_ELEGIBLE_USERS, GET_DASHBOARD, DASHBOARD_WIDGETS_QUERY } from '../../services/queries'

import { DASHBOARD_WIDGETS_SUBSCRIPTION, WIDGETS_MOVE_SUBSCRIPTION } from '../../services/subscriptions'

const Dashboard = props => {
  const {
    data: dashboardData,
    loading: dashboardLoading,
    startPolling: dashboardStartPolling,
    stopPolling: dashboardStopPolling
  } = useQuery(GET_DASHBOARD, {
    variables: { id: props.match.params.id }
  })

  const {
    data: dashboardWidgetsData,
    loading: dashboardWidgetsLoading,
    subscribeToMore: suscribeToDashboardWidgets
  } = useQuery(DASHBOARD_WIDGETS_QUERY, {
    variables: {
      dashboardID: props.match.params.id
    }
  })

  useEffect(() => {
    suscribeToDashboardWidgets({
      document: DASHBOARD_WIDGETS_SUBSCRIPTION,
      variables: { dashboardID: props.match.params.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        let newWidget = subscriptionData.data.widgetChanged
        if (newWidget.operation === 'created') {
          if (newWidget.createdBy.id === props.user.id) return prev
          return Object.assign({}, prev, {
            dashboardWidgets: [...prev.dashboardWidgets, newWidget]
          })
        } else if (newWidget.operation === 'removed') {
          return Object.assign({}, prev, {
            dashboardWidgets: prev.dashboardWidgets.filter(dashboard => dashboard.id !== newWidget.id)
          })
        } else if (newWidget.operation === 'updated') {
          console.log('Widget updated!')
        }
      }
    })
  }, [suscribeToDashboardWidgets, props.match.params.id, props.user.id])

  useEffect(() => {
    suscribeToDashboardWidgets({
      document: WIDGETS_MOVE_SUBSCRIPTION,
      variables: { dashboardID: props.match.params.id },
      updateQuery: (prev, { subscriptionData }) => {
        if (!subscriptionData.data) return prev
        const userID = subscriptionData.data.widgetMoved.split(' ')[1]
        if (userID === props.user.id) return prev
        const widgets = [...prev.dashboardWidgets]
        const widgetID = subscriptionData.data.widgetMoved.split(' ')[2]
        const direction = subscriptionData.data.widgetMoved.split(' ')[3]
        const idx = widgets.map(widget => widget.id).indexOf(widgetID)
        if (direction === 'right' && idx + 1 !== widgets.length) {
          const widget = widgets.splice(idx, 1)[0]
          widgets.splice(idx + 1, 0, widget)
        }
        if (direction === 'left' && idx !== 0) {
          const widget = widgets.splice(idx, 1)[0]
          widgets.splice(idx - 1, 0, widget)
        }
        return Object.assign({}, prev, {
          dashboardWidgets: widgets
        })
      }
    })
  }, [suscribeToDashboardWidgets, props.match.params.id, props.user.id])
  const {
    // data: usersData,
    loading: usersLoading,
    startPolling: userStartPolling,
    stopPolling: userStopPolling
  } = useQuery(GET_ELEGIBLE_USERS, {
    variables: { dashboardId: props.match.params.id }
  })

  useEffect(() => {
    dashboardStartPolling(2000)
    userStartPolling(2000)
    return () => {
      dashboardStopPolling()
      userStopPolling()
    }
  }, [dashboardStartPolling, dashboardStopPolling, userStartPolling, userStopPolling])

  if (dashboardLoading || dashboardWidgetsLoading || usersLoading) return <Spinner />

  return (
    <MainContainer padding='56px 2.5%'>
      <DashboardContainer>
        <Topbar className='topbarMobile'>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => props.history.push('/dashboards')}>
              <HiArrowLeft className='back-icon' />
            </button>
            <p className='topbar-title'>{dashboardData.getDashboard.name}</p>
          </div>
          <div className='right-topbar'></div>
        </Topbar>

        {dashboardWidgetsData.dashboardWidgets.length > 0 ? (
          <WidgetsContainer>
            {dashboardWidgetsData.dashboardWidgets.map(widget => (
              <Widget key={widget.id} mobile={true} widget={widget} dashboard={props.match.params.id} />
            ))}
          </WidgetsContainer>
        ) : (
          <NoWidgets nobutton={true} />
        )}
      </DashboardContainer>
    </MainContainer>
  )
}

export default Dashboard
