import React, { useContext, useState } from 'react'
import General from '../../components/workspace/General'
import Team from '../../components/workspace/Team'
import Plan from '../../components/workspace/Plan'
import Payment from '../../components/workspace/Payment'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Toolbar } from '../../style/components'
import { WorkspaceContainer } from '../../style/pages/workspace/WorkspaceContainer.styled'

const Workspace = props => {
  const getSection = () => (props.location.search === '?plans' ? 'plan' : 'general')

  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass, toolbarClass } = uiCTX

  const [section, setSection] = useState(getSection())

  const setGeneralButton = () => (section === 'general' ? 'section-button section-button-active' : 'section-button')
  const setTeamButton = () => (section === 'team' ? 'section-button section-button-active' : 'section-button')
  const setPlanButton = () => (section === 'plan' ? 'section-button section-button-active' : 'section-button')
  const setPaymentButton = () => (section === 'payment' ? 'section-button section-button-active' : 'section-button')

  return (
    <MainContainer className={mainClass()}>
      <WorkspaceContainer>
        <Topbar className={topbarClass()}>
          <h1>Workspace</h1>
        </Topbar>
        <Toolbar className={toolbarClass()}>
          <button className={setGeneralButton()} onClick={() => setSection('general')}>
            General
          </button>
          <button className={setTeamButton()} onClick={() => setSection('team')}>
            Equipo
          </button>
          <button className={setPlanButton()} onClick={() => setSection('plan')}>
            Planes
          </button>
          <button className={setPaymentButton()} onClick={() => setSection('payment')}>
            Pagos
          </button>
        </Toolbar>
        {section === 'general' && <General />}
        {section === 'team' && <Team />}
        {section === 'plan' && <Plan />}
        {section === 'payment' && <Payment />}
      </WorkspaceContainer>
    </MainContainer>
  )
}

export default Workspace
