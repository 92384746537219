import styled from 'styled-components'

export const ReportTypeContainer = styled.div`
  h2 {
    font-size: 24px;
    font-weight: 500;
    margin-bottom: 20px;
  }
  .report-type-checkboxes {
    display: flex;
    label {
      display: flex;
      align-items: center;
      width: 200px;
      font-size: 16px;
      font-weight: 500;
      margin-bottom: 40px;
      cursor: pointer;
      input {
        width: 15px;
        height: 15px;
        margin-right: 10px;
      }
    }
  }
`
