import styled from 'styled-components'
import { colors } from '../../variables'

export const GeneralContainer = styled.div`
  display: flex;
  width: ${props => (props.collapsed ? 'calc(100vw - 68px)' : 'calc(100vw - 222px)')};
  padding-top: 40px;
  margin-left: calc(100vw * -0.025);
  transition: all 0.25s linear;
  section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    width: calc(100% / 3);
    height: calc(100vh - 110px);
    padding: 40px 0 19px 0;
    border-right: 1px solid ${colors.lightGray};
    overflow-y: scroll;
  }
  ${'' /* Styles for Información general */}
  .section__generalinfo {
    padding-left: 16px;
    padding-right: 16px;
  }
  .info-title {
    margin-bottom: 24px;
    color: ${colors.richBlack};
    font-size: 22px;
    font-weight: 600;
    line-height: 35px;
    width: calc(100% - 20px);
  }
  .edit-workspace-form {
    display: flex;
    flex-direction: column;
    width: calc(100% - 20px);
    .workspace__form__title {
      margin-bottom: 8px;
      color: ${colors.richBlack};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .workspace__form__input {
      height: 48px;
      padding: 15px 0 15px 18px;
      margin-bottom: 20px;
      border: 1px solid #748596;
      border-radius: 4px;
      outline: none;
      background: transparent;
      color: ${colors.richBlack};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .workspace__form__textarea {
      height: 100px;
      padding: 15px 23px 0 18px;
      border: 1px solid #748596;
      border-radius: 4px;
      outline: none;
      background: transparent;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      margin-bottom: 10px;
      resize: none;
    }
    .workspace__form__description {
      color: ${colors.richBlack};
    }
    .generalinfo__savebutton_container {
      display: flex;
      justify-content: flex-end;
      .submit-button {
        width: 119px;
        border-radius: 4px;
        padding: 10px 28px;
        font-size: 15px;
        font-weight: 600;
        line-height: 18px;
        cursor: pointer;
      }
      .submit__button__inactive {
        border: 1px solid #aaafbe;
        color: #aaafbe;
        &:disabled {
          cursor: not-allowed;
        }
      }
      .submit__button__active {
        background-color: transparent;
        border: 1px solid #6873e5;
        color: #6873e5;
      }
    }
  }
  .delete-account {
    padding-top: 10px;
    border-top: 1px solid ${colors.lightGray};
    position: relative;
    width: calc(100% - 20px);
    .delete__account__text {
      font-size: 12px;
      font-weight: 500;
      line-height: 14.63px;
      color: #748596;
      text-align: left;
      margin-bottom: 16px;
    }
    button {
      display: flex;
      align-items: center;
      svg {
        width: 19px;
        height: 22px;
        margin-right: 11px;
      }
    }
  }
  ${'' /* Styles for Tus búsquedas */}
  .header-w-info {
    display: flex;
    flex-direction: column;
    width: calc(100% - 30px);
    padding-left: 16px;
    .general__section__header {
      margin-bottom: 24px;
      font-size: 22px;
      font-weight: 600;
      line-height: 35px;
      color: ${colors.richBlack};
    }
    .general__section__quantity {
      margin-bottom: 10px;
      color: ${colors.richBlack};
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
    }
  }
  .general-link {
    display: flex;
    color: ${colors.lightseaGreen};
    font-size: 14px;
    font-weight: 500;
    line-height: 18px;
    margin-bottom: 26px;
    padding-left: 16px;
  }
  .general-searches-container,
  .general-dashboards-container {
    display: flex;
    flex-direction: column;
    height: calc(100vh - 235px);
  }

  .general-searches-container {
    .searches__container___nosearches {
      padding-left: 16px;
    }
  }

  .general-dashboards-container {
    .dashboard__container__nosearches {
      padding-left: 16px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .info-title {
      width: 232px;
    }
  }

  @media (min-width: 2560px) {
    section {
      padding: 40px 35px 20px;
    }
    .section__generalinfo {
      padding: 40px 35px 20px;
    }
    .section__rightborder {
      border-right: none;
    }
  }
`
