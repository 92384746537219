import React, { useEffect, useRef } from 'react'

import UserRolesContainer from '../../../style/components/workspace/Modals/UserRolesMenu.styled'
import IconCheck from '../../../assets/IconCheck'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const UserRolesMenu = ({ userRole, onClick, setDropdown }) => {
  const dropdownOptions = useRef()

  useOutsideClick(dropdownOptions, () => {
    setDropdown(false)
  })

  return (
    <UserRolesContainer>
      <div className='dropdown-option' ref={dropdownOptions} onClick={onClick}>
        <div className='user__role__header'>
          <h5 className={`user__role ${userRole === 'admin' ? 'user__role__selected' : ''}`}>Administrador</h5>
          {userRole === 'admin' ? <IconCheck /> : ''}
        </div>
        <p className='user__description'>
          Usuario con todos los permisos y accesos dentro de la plataforma. Planes, pagos, facturas, etc.
        </p>
      </div>
      <div className='dropdown-option' onClick={onClick}>
        <div className='user__role__header'>
          <h5 className={`user__role ${userRole !== 'admin' ? 'user__role__selected' : ''}`}>Colaborador</h5>
          {userRole !== 'admin' ? <IconCheck /> : ''}
        </div>
        <p className='user__description'>Puede crear, agregar, editar y eliminar búsquedas, tableros y usuarios.</p>
      </div>
    </UserRolesContainer>
  )
}

export default UserRolesMenu
