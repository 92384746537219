import React, { useState } from 'react'
import ReportView from '../../style/pages/report/ReportDetails.styled'
import { Shadow, Spinner } from '../../style/components'

import { useQuery } from '@apollo/client'

import moment from 'moment'

import ReportCoverPage from '../../components/report/reportDetails/ReportCoverPage'
import ReportWidget from '../../components/report/reportDetails/ReportWidget'
import NoAccessReport from '../../components/report/reportDetails/NoAccessReport'
import ModalPDF from '../../components/report/reportDetails/ModalPDF'

import html2canvas from 'html2canvas'
import { jsPDF } from 'jspdf'

import { GET_REPORT } from '../../services/queries'

const ReportDetails = props => {
  const { data, loading } = useQuery(GET_REPORT, {
    variables: { id: props.match.params.id }
  })

  const [creatingPDF, setCreatingPDF] = useState(false)
  const [width, setWidth] = useState('100%')
  const [height, setHeight] = useState('100%')
  const [loader, setLoader] = useState(false)
  const [errorPDF, setErrorPDF] = useState(false)
  const [hideButton, setHideButton] = useState(false)

  if (loading) return <Spinner />

  const { name, date, number, description, widgets, comments, sharedSchedule } = data.getReport

  const today = moment()

  const handleSharedReportVisualization = () => {
    // IMPROVE THIS LOGIC
    if (localStorage.getItem('token')) return true // User of MYOBLEK
    else if (sharedSchedule.length === 0) return false
    else if (today.isBetween(sharedSchedule[0], sharedSchedule[1])) return true // BETWEEN MARKS
    else if (today.isSame(sharedSchedule[0]) || today.isSame(sharedSchedule[1])) return true // SAMES AS MARKS
    else return false
  }

  const handleDownloadPDF = () => {
    setWidth('297mm')
    setHeight('210mm')
    setCreatingPDF(true)
    setLoader(true)
    setHideButton(true)

    const waitAndCreate = async () => {
      const pdf = new jsPDF({ orientation: 'l' })
      const coverPage = await createCoverPage()
      const images = widgets.map(e => {
        return createWidgetImages(e.id)
      })
      images.unshift(coverPage)
      const data = await Promise.all(images)
      data.forEach((e, i) => {
        if (i === 0) pdf.addImage(e, 'JPEG', 0, 0)
        else pdf.addPage().addImage(e, 'JPEG', 0, 0)
      })

      const pdfName = name.split(' ').join('_')
      const pdfStart = date[0].split(' ').join('_')
      const pdfEnd = date[1].split(' ').join('_')
      const pdfNumber = number

      pdf
        .save(`${pdfName}-${pdfStart}-${pdfEnd}-${pdfNumber}.pdf`, { returnPromise: true })
        .then(setLoader(false))
        .catch(e => setErrorPDF(true))
    }

    setTimeout(() => waitAndCreate(), 5000)
  }

  const returnSizeParams = () => {
    setHideButton(false)
    setWidth('100%')
    setHeight('100%')
  }

  const createCoverPage = async () => {
    const coverPage = document.getElementById('reportCover')
    const coverPageCanvas = await html2canvas(coverPage)
    const coverPageImage = coverPageCanvas.toDataURL('image/png')
    return coverPageImage
  }

  const createWidgetImages = async id => {
    const widgetSection = document.getElementById(id)
    const widgetSectionCanvas = await html2canvas(widgetSection)
    const widgetSectionImage = widgetSectionCanvas.toDataURL('image/png')
    return widgetSectionImage
  }

  if (handleSharedReportVisualization()) {
    return (
      <ReportView>
        <div>
          <ReportCoverPage
            name={name}
            number={number}
            date={date}
            description={description}
            width={width}
            height={height}
          />
          <ReportWidget
            reportSection={widgets}
            comments={comments}
            date={date}
            handleDownloadPDF={handleDownloadPDF}
            width={width}
            height={height}
            hideButton={hideButton}
          />
          |
        </div>
        {creatingPDF && (
          <>
            <Shadow
              onClick={() => {
                returnSizeParams()
                setCreatingPDF(false)
              }}
            />
            <ModalPDF
              setCreatingPDF={setCreatingPDF}
              loader={loader}
              errorPDF={errorPDF}
              returnSizeParams={returnSizeParams}
            />
          </>
        )}
      </ReportView>
    )
  } else {
    return <NoAccessReport />
  }
}

export default ReportDetails
