import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const ShareBoardContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 750px;
  padding: 60px;
  border-radius: 4px;
  background: ${colors.white};
  .m-button-editor {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .m-button-viewer {
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  .close-icon {
    position: absolute;
    top: 30px;
    right: 30px;
    width: 25px;
    height: 25px;
    fill: ${colors.darkGray};
    cursor: pointer;
    &:disabled {
      cursor: not-allowed;
    }
  }
  h3 {
    margin-bottom: 40px;
    font-size: 30px;
    font-weight: 700;
  }
  h4 {
    width: 100%;
    margin-bottom: 20px;
    font-size: 24px;
    font-weight: 500;
  }
  .members-section {
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    width: 100%;
    height: 225px;
    overflow-y: scroll;
    margin-bottom: 30px;
  }
  .share-form {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 50px;
    .left-input {
      display: flex;
      flex-direction: column;
      width: 375px;
    }
    .right-input {
      display: flex;
      flex-direction: column;
      width: 225px;
    }
    label {
      margin-bottom: 15px;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
    }
  }
  .space {
    height: 50px;
  }
`

export const Member = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 10px 25px 10px 20px;
  cursor: pointer;
  .member-left {
    display: flex;
    img {
      width: 40px;
      height: 40px;
      margin-right: 10px;
      border-radius: 50%;
    }
    .member-info {
      display: flex;
      flex-direction: column;
      justify-content: center;
      .member-name {
        font-size: 14px;
        font-weight: 500;
        color: ${colors.darkGray};
      }
      .member-email {
        font-size: 12px;
        font-weight: 500;
        color: ${colors.richBlack};
      }
    }
  }
  .permit-buttons {
    display: flex;
    .delete-permit-button {
      display: none;
      border: none;
      outline: none;
      margin-right: 20px;
      background: none;
      color: ${colors.red};
      font-size: 14px;
      font-weight: 500;
      cursor: pointer;
    }
  }
  .member-owner {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 4px;
    background: ${colors.lightGray};
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 500;
  }
  .m-button-editor {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 4px;
    outline: none;
    background: ${colors.purple};
    color: ${colors.white};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  .m-button-viewer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100px;
    height: 30px;
    border: none;
    border-radius: 4px;
    outline: none;
    background: ${colors.lightseaGreen};
    color: ${colors.white};
    font-size: 14px;
    font-weight: 500;
    cursor: pointer;
  }
  &:hover {
    background: ${colors.lightGray};
    .delete-permit-button {
      display: block;
    }
  }
`
