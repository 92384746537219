import styled from 'styled-components'

const NewSearchContainer = styled.div`
  .topbar {
    justify-content: space-between;
    .left-topbar {
      display: flex;
      align-items: center;
      .back-link {
        margin-right: 30px;
        display: flex;
        border: none;
        background: none;
        outline: none;
        cursor: pointer;
        .back-icon {
          width: 20px;
          height: 20px;
        }
        p {
          font-size: 16px;
          font-weight: 600;
        }
      }
      h1 {
        width: 200px;
        font-size: 22px;
        font-weight: 600;
      }
    }
  }
`

export default NewSearchContainer
