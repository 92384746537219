import React from 'react'
import { GraficosContainer } from '../../style/components/faqs/Graficos.styled'

// Imagenes
import Graficos1 from '../../assets/faqs/graficos/graficos1.png'
import Graficos2 from '../../assets/faqs/graficos/graficos2.png'

function Graficos({ graficosRef, diferentesRef, tableroRef }) {
  return (
    <GraficosContainer id='graficos' ref={graficosRef}>
      <h4>¿Qué son los gráficos?</h4>

      <p>
        Son herramientas que te permiten visualizar los datos de tus búsquedas de manera estratégica. Explora dentro de
        todas las posibilidades y elige los que más te convengan.
      </p>

      <section id='diferentes' ref={diferentesRef}>
        <h5>¿Por qué tienen colores diferentes?</h5>

        <p>
          Los gráficos que tienen el fondo celeste son los que corresponden a las búsquedas de medios tradicionales
          mientras que los de color azul oscuro son los que corresponden a Twitter.
        </p>
        <img className='imgUno' src={Graficos1} alt='Graficos 1' />
      </section>

      <section id='tablero' ref={tableroRef}>
        <h5>¿Cómo agrego un gráfico al tablero?</h5>

        <p>
          Da clic en cualquier gráfico que quieras visualizar en tu tablero e indica con cuál de tus búsquedas lo
          quieres relacionar.
        </p>
        <p>
          Si no sabes para qué funciona alguno de los gráficos, solamente dale clic y en la parte derecha de la pantalla
          podrás encontrar la descripción.
        </p>
        <p>Una vez que elijas el gráfico y la búsqueda relacionada lo puedes agregar al tablero.</p>
        <img className='imgDos' src={Graficos2} alt='Graficos 2' />
      </section>
    </GraficosContainer>
  )
}

export default Graficos
