import styled from 'styled-components'
import { colors } from '../../../variables'

export const NewsContainer = styled.section`
  .results-header {
    border-bottom: 2px solid ${colors.lightGray};
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 75px;
    margin-top: 40px;
    .total-articles {
      font-size: 21px;
      font-weight: 600;
    }
    .results-clipping {
      display: flex;
      align-items: center;
      height: 30px;
      justify-content: space-around;
      width: 20%;
      .checkboxContainer {
        display: flex;
        align-items: center;
      }
      .checkbox-indicator {
        width: 40px;
        height: 40px;
        background: none;
        border: none;
        outline: none;
        margin-right: 5px;
        .checked {
          width: 23px;
          height: 23px;
          fill: ${colors.lightseaGreen};
        }
        .not-checked {
          width: 20px;
          height: 20px;
          fill: ${colors.darkGray};
          cursor: pointer;
        }
      }
      .exportButton {
        width: 150px;
        padding: 5px;
        font-weight: 600;
        cursor: pointer;
        background: ${colors.purple};
        color: ${colors.white};
        border: none;
        border-radius: 5px;
        :disabled {
          cursor: not-allowed;
          border: solid 2px ${colors.lightGray};
          /* color: ${colors.lightGray}; */
          background: ${colors.lightGray};
        }
      }
    }
    .results-period {
      display: flex;
      align-items: center;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .search-results {
    height: calc(100vh - 145px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .date-input {
    width: 130px;
    margin: 0 10px 0 10px;
  }
`

export const DateButton = styled.button`
  width: 80px;
  padding: 5px;
  font-weight: bold;
  background: none;
  color: ${colors.purple};
  border: solid 2px ${colors.purple};
  border-radius: 5px;
  cursor: pointer;
  :disabled {
    cursor: not-allowed;
  }
`
