import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { RecoverContainer, RecoverForm } from '../../style/pages/auth/Recover.styled'
import { colors } from '../../style/variables'
import LoginBack from '../../assets/LoginBack.svg'
import Linkedin from '../../assets/icons/blinkedin.svg'
import Instagram from '../../assets/icons/binstagram.svg'
import Facebook from '../../assets/icons/bfacebook.svg'
import Twitter from '../../assets/icons/btwitter.svg'

import { SEND_RECOVERY_EMAIL } from '../../services/mutations'

const Recover = props => {
  const [sendRecoveryEmail] = useMutation(SEND_RECOVERY_EMAIL)

  const formik = useFormik({
    initialValues: {
      email: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().email('El email no se válido')
    }),
    onSubmit: async values => {
      const { email } = values

      try {
        const { data } = await sendRecoveryEmail({
          variables: {
            email
          }
        })

        setMessage(data.sendRecoveryEmail)
        setTimeout(() => {
          setMessage(null)
          props.history.push('/login')
        }, 3000)
      } catch (error) {
        setMessage(error.message)
        setTimeout(() => {
          setMessage(null)
        }, 3000)
      }
    }
  })

  const [message, setMessage] = useState(null)

  return (
    <RecoverContainer>
      <section className='app-welcome'>
        <div className='copy-login'>
          <h1>Bienvenido a MyOblek</h1>
          <p>Ahora podrás tener el poder de la información a tu alcance.</p>
          <div>
            <a href='https://www.linkedin.com/company/myoblek/about/' target='_blank' rel='noopener noreferrer'>
              <img src={Linkedin} alt='Linkedin icon' />
            </a>
            <a href='https://www.instagram.com/myoblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Instagram} alt='Instagram icon' />
            </a>
            <a href='https://www.facebook.com/MyOblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Facebook} alt='Facebook icon' />
            </a>
            <a href='https://twitter.com/myoblek' target='_blank' rel='noopener noreferrer'>
              <img src={Twitter} alt='Twitter icon' />
            </a>
          </div>
        </div>
      </section>
      <section className='form-recover'>
        <img className='img-recover' src={LoginBack} alt='trama' />
        <RecoverForm onSubmit={formik.handleSubmit}>
          <h2>Ingresa tu correo</h2>
          <p>Para recuperar tu contraseña, ingresa tu correo y recibirás un link de confirmación.</p>

          <label htmlFor='email'>Email</label>
          <input
            id='email'
            placeholder='Correo'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className='form-error'>
            {formik.touched.email && formik.errors.email ? <p>{formik.errors.email}</p> : null}
          </div>

          <div className='form-error relative'>{message && <p>{message}</p>}</div>

          <input
            className='submit-button'
            type='submit'
            value='Recuperar contraseña'
            disabled={formik.values.email && !formik.errors.email ? false : true}
            style={
              formik.values.email && !formik.errors.email
                ? { background: `${colors.richBlack}`, color: '#FFFFFF' }
                : { background: '#E7E8EA' }
            }
          />
          <div className='signup-link'>
            <p>¿Eres nuevo en MyOblek?</p>
            <Link to='/signup'>Crea una cuenta</Link>
          </div>
        </RecoverForm>
      </section>
    </RecoverContainer>
  )
}

export default Recover
