import React, { useState } from 'react'
import { DropdownContainer } from '../../style/components/ui/UserDropdown.styled'
import onClickOutside from 'react-onclickoutside'
import { FiCheck } from 'react-icons/fi'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { Tag } from '../../style/components/ui/SearchDropdown.styled'
import CloseIcon from '../../assets/CloseIcon'

function UserDropdown({ title, items = [], selection, setSelection, multiSelect = false }) {
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  UserDropdown.handleClickOutside = () => setOpen(false)

  const handleOnClick = item => {
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id)
      setSelection([...selectionAfterRemoval])
    }
  }

  const isItemInSelection = item => {
    return selection.find(current => current.id === item.id) ? true : false
  }

  const dropdownHeight = items.length > 2 ? 2 : items.length

  return (
    <DropdownContainer height={dropdownHeight}>
      <div
        tabIndex={0}
        className='dd-header'
        role='button'
        onKeyPress={() => toggle(!open)}
        onClick={() => toggle(!open)}
      >
        <div className='dd-header-title'>
          {selection.length === 0 ? (
            <p>{title}</p>
          ) : (
            selection.map(item => (
              <div key={item.id} className={'dd-header-item'}>
                <img className='dd-header-item-info-img' src={item.photo} alt='user avatar' />
                <p>{item.name}</p>
                <span onClick={() => handleOnClick(item)}>
                  <CloseIcon width={'16px'} height={'14.72px'} />
                </span>
              </div>
            ))
          )}
        </div>
        <div className='dd-header-action'>
          <p>{open ? <AiFillCaretUp /> : <AiFillCaretDown />}</p>
        </div>
      </div>
      {open && (
        <ul className='dd-list'>
          {items.map(item => (
            <li key={item.id} className='dd-list-item'>
              <button onClick={() => handleOnClick(item)}>
                <div className='dd-list-item-info'>
                  <img className='dd-list-item-info-img' src={item.photo} alt='user avatar' />
                  <div className='dd-list-item-info-text'>
                    <p>{item.name}</p>
                    <span>{item.email}</span>
                  </div>
                </div>
                <span className='dd-list-item-check'>{isItemInSelection(item) && <FiCheck />}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </DropdownContainer>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => UserDropdown.handleClickOutside
}

export default onClickOutside(UserDropdown, clickOutsideConfig)
