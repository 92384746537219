import styled from 'styled-components'
import { colors } from '../../../variables'

export const PaymentFormContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 100px;
  width: 70vw;
  justify-content: space-between;
  .left-information {
    max-width: 525px;
    width: 50%;
    h1 {
      font-size: 73px;
      font-weight: 700;
      margin-bottom: 20px;
    }
    p {
      color: ${colors.darkGray};
      line-height: 41px;
      font-size: 26px;
      font-weight: 500;
      margin-bottom: 30px;
      width: 90%;
    }
    button {
      max-width: 433px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    width: 80vw;
    .left-information {
      h1 {
        font-size: 50px;
      }
      p {
        line-height: 30px;
        font-size: 20px;
      }
      .button-inactive {
        width: 275px;
      }
      .button-active {
        width: 275px;
      }
    }
  }
`

export const CheckoutFormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 679px;
  height: 100%;
  padding: 50px;
  border-radius: 4px;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  background: ${colors.white};
  margin-left: 100px;
  h2 {
    margin-bottom: 50px;
    font-size: 34px;
    font-weight: 800;
  }
  p {
    color: ${colors.darkGray};
    font-size: 17px;
    font-weight: 500;
  }
  .payment-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .purple-total {
      color: ${colors.purple};
      font-size: 34px;
      font-weight: 800;
    }
  }
  .payment-plan {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .purple-plan {
      color: ${colors.purple};
      font-size: 34px;
      font-weight: 400;
    }
  }
  .promotion-message-container {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 16px;
    .promotion-message {
      text-align: end;
    }
  }
  hr {
    margin-bottom: 30px;
  }
  label {
    display: none;
  }
  input {
    width: 100%;
    height: 50px;
    padding: 0 10px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    font-size: 14px !important;
    font-weight: 400;
    margin-bottom: 30px;
  }
  .card-element {
    width: 100%;
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    padding: 15px 10px 15px 20px;
    margin-bottom: 30px;
  }
  .discount-code {
    display: flex;
    button {
      font-size: 17px;
      margin-left: 5px;
    }
  }

  @media (min-width: 1024px) and (max-width: 1349px) {
    padding: 40px;
    margin-left: 60px;
    .payment-total {
      width: 400px;
    }
    .promotion-message-container {
      .promotion-message {
        width: 293px;
        font-size: 12px;
      }
    }
    .discount-code {
      .discount__code__message {
        width: 287px;
      }
      button {
        width: 130px;
      }
    }
  }
`
