import React, { useState, useEffect, useRef } from 'react'
import { DropdownContainer, Tag } from '../../style/components/ui/Dropdown.styled'
import { FiCheck } from 'react-icons/fi'
import Arrow from '../../assets/arrow'
import CloseIcon from '../../assets/CloseIcon'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Dropdown = ({ options, selection, setSelection, multiSelect, placeholder }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [scroll, setScroll] = useState(false)

  const dropdown = useRef()
  const scrollable = useRef()

  useOutsideClick(dropdown, () => setDropdownOpen(false))

  useEffect(() => {
    if (scroll) {
      scrollable.current.scrollBy(200, 0)
      setScroll(false)
    }
  }, [scroll])

  const handleSelection = item => {
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
        setScroll(true)
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id)
      setSelection([...selectionAfterRemoval])
    }
  }

  const isItemInSelection = item => selection.find(current => current.id === item.id)

  return (
    <DropdownContainer ref={dropdown}>
      <div className='dropdown--area' tabIndex={0} role='button' onClick={() => setDropdownOpen(!dropdownOpen)}>
        <div className='dropdown--content' ref={scrollable}>
          {selection.length === 0 ? (
            <p className='content__placeholder'>{placeholder}</p>
          ) : (
            selection.map(item => (
              <Tag key={item.id}>
                <p>{item.name}</p>
                <span onClick={() => handleSelection(item)}>
                  <CloseIcon />
                </span>
              </Tag>
            ))
          )}
        </div>
        <div className={`dropdown--button ${dropdownOpen ? 'arrow__rotate' : ''}`}>
          <Arrow fill='#00AAAB' />
        </div>
      </div>
      {dropdownOpen && (
        <ul className='dropdown--list'>
          {options.map(item => (
            <li key={item.id}>
              <button onClick={() => handleSelection(item)}>
                <p>{item.name}</p>
                <span>{isItemInSelection(item) && <FiCheck />}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </DropdownContainer>
  )
}

export default Dropdown
