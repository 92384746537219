import styled from 'styled-components'
import { colors } from '../../../style/variables'

export const MobileCont = styled.div`
  position: fixed;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  align-items: center;
  justify-content: center;
  background: ${colors.richBlack};
  #tsparticles {
    position: absolute;
    width: 100%;
    height: 100%;
  }
  .message {
    position: absolute;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    padding: 40px;
    max-width: 500px;
    max-height: 400px;
    margin: auto;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    p {
      color: white;
      font-size: 24px;
      font-weight: 600;
      margin-bottom: 30px;
    }
  }
`
