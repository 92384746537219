import React, { useRef, useContext, useState } from 'react'
import { WidgetContainer, SearchesContainer } from '../../style/pages/Dashboard/Widget.styled'
import { MainContainer, Topbar, Toolbar, Spinner, Shadow } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'
// import { FiSettings } from 'react-icons/fi'
// import { AiOutlineCalendar } from 'react-icons/ai'
import { BiExport } from 'react-icons/bi'
import { UIContext } from '../../contexts/UIContext'
import { useQuery, useMutation } from '@apollo/client'
import { BsTrash } from 'react-icons/bs'
import { widgets } from '../../data/Widgets'
import ModalExport from '../../components/auth/ModalExport'

import TonalidadMedios from '../../components/widgets/TonalidadMedios'
import NumeroNotas from '../../components/widgets/NumeroNotas'
import TipoMedios from '../../components/widgets/TipoMedios'
import TemasMomento from '../../components/widgets/TemasMomento'
import MapaCalor from '../../components/widgets/MapaCalor'
import ExposicionMedios from '../../components/widgets/ExposicionMedios'
import ShareVoice from '../../components/widgets/ShareVoice'
import MediosGanados from '../../components/widgets/MediosGandos'
import TonalidadTwitter from '../../components/widgets/TonalidadTwitter'
import NubeHashtagsTwitter from '../../components/widgets/NubeHashtagsTwitter'
import InfluencersTwitter from '../../components/widgets/InfluencersTwitter'
import MencionesTiwtter from '../../components/widgets/MencionesTwitter'
import PublicadoresVolumenTwitter from '../../components/widgets/PublicadoresVolumenTwitter'
import AddSearch from '../../components/dashboard/widget/AddSearch'
import DeleteSearch from '../../components/search/searches/DeleteSearch'

import { WIDGET_QUERY, SEARCHES_QUERY } from '../../services/queries'
import { UPDATE_WIDGET_MUTATION } from '../../services/mutations'
import Button from '../../components/ui/Button'

const Widget = props => {
  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass, toolbarClass } = uiCTX

  const [showAddSearch, setShowAddSearch] = useState(false)
  const [searchesSelection, setSearchesSelection] = useState([])
  const [searchToDelete, setSearchToDelete] = useState(null)
  const [widgetType, setWidgetType] = useState(widgets[0])

  const [showExportMenu, setShowExportMenu] = useState(false)

  const exportRef = useRef()

  const closeAddSearch = () => {
    setShowAddSearch(false)
    setSearchesSelection([])
  }

  const [updateWidget] = useMutation(UPDATE_WIDGET_MUTATION, {
    update(cache, { data: { updateWidget } }) {
      cache.writeQuery({
        query: WIDGET_QUERY,
        variables: { id: props.match.params.id },
        data: {
          widget: updateWidget
        }
      })
    }
  })

  const addSearches = async () => {
    await updateWidget({
      variables: {
        id: props.match.params.id,
        searches: searchesSelection.map(search => search.id),
        operation: 'add'
      }
    })
    closeAddSearch()
  }

  const deleteSearch = async id => {
    await updateWidget({
      variables: {
        id: props.match.params.id,
        searches: [id],
        operation: 'delete'
      }
    })
    setSearchToDelete(null)
  }

  const { data: widgetData, loading: widgetLoading } = useQuery(WIDGET_QUERY, {
    variables: { id: props.match.params.id },
    onCompleted: ({ widget }) => setWidgetType(widgets.filter(element => element.componentName === widget.kind)[0])
  })

  const { data: searchesData, loading: searchesLoading } = useQuery(SEARCHES_QUERY)

  if (widgetLoading || searchesLoading) return <Spinner />

  const { widget } = widgetData

  return (
    <MainContainer className={mainClass()}>
      <WidgetContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <Button
              width={'min'}
              height={'fit-content'}
              theme={'dark'}
              variant={'link'}
              className='back-link'
              onClick={() => props.history.push(`/dashboards/dashboard/${widget.dashboard.id}`)}
            >
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </Button>
            <h1>{widget.name}</h1>
          </div>
        </Topbar>

        <Toolbar className={toolbarClass()}>
          <button className='toolbar-button' onClick={() => setShowExportMenu(!showExportMenu)}>
            <BiExport className='toolbar-icon gray-icon' /> <p>Exportar</p>
          </button>
          {showExportMenu && (
            <ModalExport
              setState={setShowExportMenu}
              title={widget.name}
              graphic={(() => {
                switch (widget.kind) {
                  case 'TonalidadMedios':
                    return <TonalidadMedios widget={widget} />
                  case 'NumeroNotas':
                    return <NumeroNotas widget={widget} />
                  case 'MediosGanados':
                    return <MediosGanados widget={widget} />
                  case 'TipoMedios':
                    return <TipoMedios widget={widget} />
                  case 'TemasMomento':
                    return <TemasMomento widget={widget} />
                  case 'MapaCalor':
                    return <MapaCalor widget={widget} />
                  case 'ExposicionMedios':
                    return <ExposicionMedios widget={widget} />
                  case 'ShareVoice':
                    return <ShareVoice widget={widget} />
                  case 'TonalidadTwitter':
                    return <TonalidadTwitter widget={widget} />
                  case 'NubeHashtags':
                    return <NubeHashtagsTwitter widget={widget} />
                  case 'InfluencersTwitter':
                    return <InfluencersTwitter widget={widget} />
                  case 'MencionesTwitter':
                    return <MencionesTiwtter widget={widget} />
                  case 'PublicadoresVolumen':
                    return <PublicadoresVolumenTwitter widget={widget} />
                  default:
                    return null
                }
              })()}
            />
          )}
          {/* <button className='toolbar-button'>
            <AiOutlineCalendar className='toolbar-icon gray-icon'/>
            <p>Periodo</p>
          </button>
          <button className='toolbar-button'>
            <FiSettings className='toolbar-icon'/>
            <p>Herramientas</p>
          </button> */}
        </Toolbar>

        <div className='widget-canvas' ref={exportRef}>
          {(() => {
            switch (widget.kind) {
              case 'TonalidadMedios':
                return <TonalidadMedios widget={widget} />
              case 'NumeroNotas':
                return <NumeroNotas widget={widget} />
              case 'MediosGanados':
                return <MediosGanados widget={widget} />
              case 'TipoMedios':
                return <TipoMedios widget={widget} />
              case 'TemasMomento':
                return <TemasMomento widget={widget} />
              case 'MapaCalor':
                return <MapaCalor widget={widget} />
              case 'ExposicionMedios':
                return <ExposicionMedios widget={widget} />
              case 'ShareVoice':
                return <ShareVoice widget={widget} />
              case 'TonalidadTwitter':
                return <TonalidadTwitter widget={widget} />
              case 'NubeHashtags':
                return <NubeHashtagsTwitter widget={widget} />
              case 'InfluencersTwitter':
                return <InfluencersTwitter widget={widget} />
              case 'MencionesTwitter':
                return <MencionesTiwtter widget={widget} />
              case 'PublicadoresVolumen':
                return <PublicadoresVolumenTwitter widget={widget} />
              default:
                return null
            }
          })()}
        </div>

        <SearchesContainer>
          <div className='searches-head'>
            <h3>Busquedas vinculadas</h3>
            <Button variant={'link'} height={'fit-content'} onClick={() => setShowAddSearch(true)}>
              Agregar nueva búsqueda
            </Button>
          </div>
          <div className='searches-table-header'>
            <h3 className='number'>No.</h3>
            <h3 className='name'>Nombre</h3>
            <h3 className='source'>Fuente</h3>
            <h3 className='date'>Fecha</h3>
            <h3 className='creator'>Autor</h3>
            <h3 className='options'>Opciones</h3>
          </div>
          <div className='searches-table-body'>
            {widget.searches.map((search, idx) => {
              let name = search.name
              if (name.split('').length > 35) {
                name = name.split('').slice(0, 35).join('') + '...'
              }
              let date = new Date(Number(search.createdAt))
              date = date.toString().split('').slice(4, 15).join('')
              return (
                <div className='searches-table-row' key={search.id}>
                  <div className='options'>
                    <button className='delete' onClick={() => setSearchToDelete(search)}>
                      <BsTrash />
                    </button>
                  </div>
                  <div className='searchCont' onClick={() => props.history.push(`/searches/search/${search.id}`)}>
                    <p className='number'>{idx + 1 < 10 ? `0${idx + 1}` : idx + 1}</p>
                    <p className='name'>{name}</p>
                    <p className='source'>{search.source}</p>
                    <p className='date'>{date}</p>
                    <p className='creator'>
                      <img src={search.createdBy.photo} className='creator-photo' alt='user avatar' />
                      {search.createdBy.name}
                    </p>
                  </div>
                </div>
              )
            })}
          </div>
        </SearchesContainer>

        {showAddSearch && (
          <>
            <Shadow onClick={() => closeAddSearch()} />
            <AddSearch
              closeModal={() => closeAddSearch()}
              widget={widget}
              widgetType={widgetType}
              searchesData={searchesData}
              searchesLoading={searchesLoading}
              searchesSelection={searchesSelection}
              setSearchesSelection={setSearchesSelection}
              addSearches={addSearches}
            />
          </>
        )}

        {searchToDelete && (
          <>
            <Shadow onClick={() => setSearchToDelete(null)} />
            <DeleteSearch search={searchToDelete} setSearchToDelete={setSearchToDelete} deleteSearch={deleteSearch} />
          </>
        )}
      </WidgetContainer>
    </MainContainer>
  )
}

export default Widget
