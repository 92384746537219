import React, { useState, useRef } from 'react'
import { ModalExportCont } from '../../style/components/auth/ModalExportr/ModalExport.syled'
import { GrClose } from 'react-icons/gr'
import moment from 'moment'
import Pdf from 'react-to-pdf'

import { exportComponentAsJPEG, exportComponentAsPNG } from 'react-component-export-image'
import Button from '../ui/Button'

const ModalExport = ({ state, setState, title, graphic }) => {
  const [comment, setComment] = useState('')
  const [titleState, setTitleState] = useState(true)
  const [tipo, setTipo] = useState('pdf')
  const [size, setSize] = useState('letter')
  const exportRef = useRef()
  const exportConmp = () => {
    switch (tipo) {
      // case "pdf":
      //   return exportComponentAsPDF(
      //     exportRef,
      //     `Widget_${title}_${moment().format("DD-MM-YYYY")}`,
      //     {
      //       width: "100px",
      //       height: "100px",
      //       windowWidth: "150px",
      //       windowHeight: "150px",
      //     }
      //   );
      case 'png':
        return exportComponentAsPNG(exportRef, `Widget_${title}_${moment().format('DD-MM-YYYY')}`, null, {
          width: '100px',
          height: '100px'
        })
      case 'jpg':
        return exportComponentAsJPEG(exportRef, `Widget_${title}_${moment().format('DD-MM-YYYY')}`, {
          width: '100px',
          height: '100px',
          windowWidth: '150px',
          windowHeight: '150px'
        })
      default:
        return null
    }
  }
  const options = {
    orientation: 'portrait',
    unit: 'in',
    format: size
  }
  return (
    <ModalExportCont>
      <div className='backgroundCont' onClick={() => setState(false)} />
      <div className='modalExportCont'>
        <div className='modalExportCont-body'>
          <div className='buttonClose' onClick={() => setState(false)}>
            <GrClose />
          </div>
          <div className='modalExportCont-body-title'>
            <h2>Exportar gráfico</h2>
          </div>
          <div className='modalExportCont-body-export'>
            <div className='export-config'>
              <div className='groupConfig'>
                <h3>Tipo de archivo</h3>
                <div className='inputs'>
                  <div className='input'>
                    <input
                      name='tipo'
                      type='radio'
                      checked={tipo === 'pdf' && true}
                      onClick={() => setTipo('pdf')}
                      className='check-box'
                    />
                    <span>PDF</span>
                  </div>
                  <div className='input'>
                    <input
                      name='tipo'
                      type='radio'
                      checked={tipo === 'png' && true}
                      onClick={() => setTipo('png')}
                      className='check-box'
                    />
                    <span>PNG</span>
                  </div>
                  <div className='input'>
                    <input
                      name='tipo'
                      type='radio'
                      checked={tipo === 'jpg' && true}
                      onClick={() => setTipo('jpg')}
                      className='check-box'
                    />
                    <span>JPG</span>
                  </div>
                </div>
              </div>
              <div className='groupConfig'>
                <h3>Título del gráfico</h3>
                <div className='inputs'>
                  <div className='input'>
                    <input
                      name='show'
                      type='checkbox'
                      checked={titleState}
                      onClick={() => setTitleState(!titleState)}
                      className='check-box'
                    />
                    <span>mostrar</span>
                  </div>
                </div>
              </div>
              <div className='groupConfig'>
                {tipo === 'pdf' && (
                  <>
                    <h3>Tipo de hoja</h3>
                    <div className='inputs'>
                      <div className='input'>
                        <input
                          name='size'
                          type='radio'
                          checked={size === 'letter' && true}
                          onClick={() => setSize('letter')}
                          className='check-box'
                        />
                        <span>Carta</span>
                      </div>
                      <div className='input'>
                        <input
                          name='size'
                          type='radio'
                          checked={size === 'legal' && true}
                          onClick={() => setSize('legal')}
                          className='check-box'
                        />
                        <span>Doble carta</span>
                      </div>
                    </div>
                  </>
                )}
              </div>
              {/* <div className="groupConfig">
                <h3>Título del gráfico</h3>
                <div className="inputs">
                  <div className="input">
                    <input name="tiempo" type="radio" />
                    <span>semanda</span>
                  </div>
                  <div className="input">
                    <input name="tiempo" type="radio" />
                    <span>mes</span>
                  </div>
                  <div className="input">
                    <input name="tiempo" type="radio" />
                    <span>año</span>
                  </div>
                </div>
              </div> */}
              <div className='groupConfig'>
                <span>Agregar comentario</span>
                <div className='inputs'>
                  <textarea
                    name=''
                    id=''
                    cols='30'
                    rows='6'
                    value={comment}
                    onChange={e => setComment(e.target.value)}
                    placeholder='Deja una nota sobre este gráfico…'
                  ></textarea>
                </div>
              </div>
              <div className='buttonExport'>
                {tipo === 'pdf' ? (
                  <Pdf
                    targetRef={exportRef}
                    filename={`Widget_${title}_${moment().format('DD-MM-YYYY')}.pdf`}
                    options={options}
                    x={1}
                    y={0.09}
                    scale={1.3}
                  >
                    {({ toPdf }) => (
                      <Button size={'full'} onClick={toPdf}>
                        Generar pdf
                      </Button>
                    )}
                  </Pdf>
                ) : (
                  <Button size={'full'} onClick={() => exportConmp()}>
                    Exportar imagen
                  </Button>
                )}
              </div>
            </div>
            <span className='line' />
            <div className='export-preview'>
              <div
                className='grafico'
                ref={exportRef}
                // style={{width: 793, height: 1110, background: 'white', padding: 40, position: 'absolute', left: 0}}
              >
                <div className='grafico-title'>{titleState && <h3> {title} </h3>}</div>
                <div className='grafico-container'>{graphic}</div>
                <div className='grafico-comment'>
                  {comment.length > 0 && (
                    <>
                      <p>Comentarios:</p>
                      <p> {comment} </p>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ModalExportCont>
  )
}

export default ModalExport
