import React from 'react'

const DashboardIcon = () => (
  <svg>
    <g fillRule='evenodd'>
      <path d='M9,1.5 L1.5,1.5 L1.5,6 L9,6 L9,1.5 Z M1.5,0 C1.10218,0 0.720644,0.158035 0.43934,0.43934 C0.158035,0.720644 0,1.10218 0,1.5 L0,6 C0,6.39782 0.158035,6.77936 0.43934,7.06066 C0.720644,7.34196 1.10218,7.5 1.5,7.5 L9,7.5 C9.39782,7.5 9.77936,7.34196 10.0607,7.06066 C10.342,6.77936 10.5,6.39782 10.5,6 L10.5,1.5 C10.5,1.10218 10.342,0.720644 10.0607,0.43934 C9.77936,0.158035 9.39782,0 9,0 L1.5,0 Z M22.5,18 L15,18 L15,22.5 L22.5,22.5 L22.5,18 Z M15,16.5 C14.6022,16.5 14.2206,16.658 13.9393,16.9393 C13.658,17.2206 13.5,17.6022 13.5,18 L13.5,22.5 C13.5,22.8978 13.658,23.2794 13.9393,23.5607 C14.2206,23.842 14.6022,24 15,24 L22.5,24 C22.8978,24 23.2794,23.842 23.5607,23.5607 C23.842,23.2794 24,22.8978 24,22.5 L24,18 C24,17.6022 23.842,17.2206 23.5607,16.9393 C23.2794,16.658 22.8978,16.5 22.5,16.5 L15,16.5 Z M9,12 L1.5,12 L1.5,22.5 L9,22.5 L9,12 Z M1.5,10.5 C1.10218,10.5 0.720644,10.658 0.43934,10.9393 C0.158035,11.2206 0,11.6022 0,12 L0,22.5 C0,22.8978 0.158035,23.2794 0.43934,23.5607 C0.720644,23.842 1.10218,24 1.5,24 L9,24 C9.39782,24 9.77936,23.842 10.0607,23.5607 C10.342,23.2794 10.5,22.8978 10.5,22.5 L10.5,12 C10.5,11.6022 10.342,11.2206 10.0607,10.9393 C9.77936,10.658 9.39782,10.5 9,10.5 L1.5,10.5 Z M22.5,1.5 L15,1.5 L15,12 L22.5,12 L22.5,1.5 Z M15,0 C14.6022,0 14.2206,0.158035 13.9393,0.43934 C13.658,0.720644 13.5,1.10218 13.5,1.5 L13.5,12 C13.5,12.3978 13.658,12.7794 13.9393,13.0607 C14.2206,13.342 14.6022,13.5 15,13.5 L22.5,13.5 C22.8978,13.5 23.2794,13.342 23.5607,13.0607 C23.842,12.7794 24,12.3978 24,12 L24,1.5 C24,1.10218 23.842,0.720644 23.5607,0.43934 C23.2794,0.158035 22.8978,0 22.5,0 L15,0 Z' />
    </g>
  </svg>
)

export default DashboardIcon
