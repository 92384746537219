export const widgets = [
  {
    id: 'M01',
    displayName: 'Tonalidad de medios',
    componentName: 'TonalidadMedios',
    type: 'Medios',
    description: `Indica el total de notas que coinciden con la búsqueda, 
      conociendo su tonalidad (Positiva, negativa o neutra).`
  },
  {
    id: 'M02',
    displayName: 'Número de notas',
    componentName: 'NumeroNotas',
    type: 'Medios',
    description: `Indica el número de notas publicadas por día relacionadas 
      con la búsqueda realizada.`
  },
  {
    id: 'M03',
    displayName: 'Medios ganados',
    componentName: 'MediosGanados',
    type: 'Medios',
    description: `Ofrece el alcance total de las notas analizadas en medios 
      y resalta el valor monetario.`
  },
  {
    id: 'M04',
    displayName: 'Tipo de medios',
    componentName: 'TipoMedios',
    type: 'Medios',
    description: `Información sobre cuál medio ha realizado menciones, y 
      cantidad de las mismas, divididas en tipo de medios. 
      (Electrónico vs. Impreso).`
  },
  {
    id: 'M05',
    displayName: 'Temas del momento',
    componentName: 'TemasMomento',
    type: 'Medios',
    description: `Ofrece una nube de palabras relacionadas a la búsqueda 
      realizada, resaltando las más utilizadas.`
  },
  {
    id: 'M06',
    displayName: 'Mapa de calor',
    componentName: 'MapaCalor',
    type: 'Medios',
    description: 'Indica el volumen de noticias por estado.'
  },
  {
    id: 'M07',
    displayName: 'Exposición en medios',
    componentName: 'ExposicionMedios',
    type: 'Medios',
    description: `Ofrece el alcance total de las notas analizadas en medios, 
      de acuerdo al volumen de exposición.`
  },
  {
    id: 'M08',
    displayName: 'Share of voice',
    componentName: 'ShareVoice',
    type: 'Medios',
    description: `Compara diferentes búsquedas para conocer el porcentaje 
      de exposición, en relación con otras búsquedas.`
  },
  {
    id: 'T01',
    displayName: 'Tonalidad en twitter',
    componentName: 'TonalidadTwitter',
    type: 'Twitter',
    description: `Indica total de menciones a la búsqueda, conociendo su 
      tonalidad (Positiva, negativa, neutra).`
  },
  {
    id: 'T02',
    displayName: 'Nube de hasthags',
    componentName: 'NubeHashtags',
    type: 'Twitter',
    description: 'Cuenta los principales temas que acompañan la búsqueda realizada.'
  },
  {
    id: 'T03',
    displayName: 'Influencers en Twitter', // Publicaciones por autoridad
    componentName: 'InfluencersTwitter',
    type: 'Twitter',
    description: `Lista de usuarios de Twitter que son referentes, por el 
      número de seguidores, que realizaron tweets sobre la búsqueda.`
  },
  {
    id: 'T04',
    displayName: 'Menciones en twitter', // Top social post
    componentName: 'MencionesTwitter',
    type: 'Twitter',
    description: `Ofrece los tweets con más alcance del elemento que estamos 
      rastreando, y muestra el cálculo de ese alcance.`
  },
  {
    id: 'T05',
    displayName: 'Publicaciones por volumen',
    componentName: 'PublicadoresVolumen',
    type: 'Twitter',
    description: `Conoce los usuarios de Twitter que han emitido más tweets 
      respecto a la búsqueda realizada.`
  }
]
