import React, { useContext, useEffect, useState } from 'react'
import { useHistory, Link, useParams } from 'react-router-dom'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { useMutation } from '@apollo/client'
import { LoginContainer, LoginForm } from '../../style/pages/auth/Login.styled'
import { colors } from '../../style/variables'
import LoginBack from '../../assets/LoginBack.svg'
import Linkedin from '../../assets/icons/blinkedin.svg'
import Instagram from '../../assets/icons/binstagram.svg'
import Facebook from '../../assets/icons/bfacebook.svg'
import Twitter from '../../assets/icons/btwitter.svg'

import { MyContext } from '../../context'

import { AUTHENTICATE_USER } from '../../services/mutations'

const Login = props => {
  const { showNotifications, setNotificationText, ...context } = useContext(MyContext)
  const history = useHistory()

  useEffect(() => {
    if (props.location.search.includes('token-expired')) {
      setNotificationText('Su sesión ha expirado, ingrese nuevamente')
      showNotifications()
      history.push('/login')
    }
  }, [props.location])

  const [authenticateUser] = useMutation(AUTHENTICATE_USER)

  const formik = useFormik({
    initialValues: {
      email: '',
      password: ''
    },
    validationSchema: Yup.object({
      email: Yup.string().required('El correo es obligatorio').email('El correo no es válido'),
      password: Yup.string()
        .required('La contraseña es obligatoria')
        .min(6, 'La contraseña debe ser de al menos 6 caracteres')
    }),
    onSubmit: async values => {
      const { email, password } = values
      try {
        const { data } = await authenticateUser({
          variables: {
            input: {
              email,
              password
              // uuid
            }
          }
        })
        const { token } = data.authenticateUser
        localStorage.setItem('token', token)
        setTimeout(() => {
          context.activeSubscriptions()
          history.push('/')
        }, 1000)
      } catch (error) {
        setMessage(error.message)
        setTimeout(() => {
          setMessage(null)
        }, 3000)
      }
    }
  })

  const [message, setMessage] = useState(null)

  return (
    <LoginContainer>
      <section className='app-welcome'>
        <div className='copy-login'>
          <h1>Bienvenido a MyOblek</h1>
          <p>Ahora podrás tener el poder de la información a tu alcance.</p>
          <div>
            <a href='https://www.linkedin.com/company/myoblek/about/' target='_blank' rel='noopener noreferrer'>
              <img src={Linkedin} alt='Linkedin icon' />
            </a>
            <a href='https://www.instagram.com/myoblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Instagram} alt='Instagram icon' />
            </a>
            <a href='https://www.facebook.com/MyOblek/' target='_blank' rel='noopener noreferrer'>
              <img src={Facebook} alt='Facebook icon' />
            </a>
            <a href='https://twitter.com/myoblek' target='_blank' rel='noopener noreferrer'>
              <img src={Twitter} alt='Twitter icon' />
            </a>
          </div>
        </div>
      </section>
      <section className='form-login'>
        <img className='img-login' src={LoginBack} alt='trama' />
        <LoginForm onSubmit={formik.handleSubmit}>
          <h2>Iniciar sesión</h2>
          <p>Éste es un sistema seguro, ingresa tus datos de acceso para ingresar a la plataforma.</p>
          <label htmlFor='email'>Email</label>
          <input
            id='email'
            placeholder='Correo'
            value={formik.values.email}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className='form-error'>
            {formik.touched.email && formik.errors.email ? <p>{formik.errors.email}</p> : null}
          </div>
          <label htmlFor='password'>Contraseña</label>
          <input
            id='password'
            type='password'
            placeholder='Contraseña'
            value={formik.values.password}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
          />
          <div className='form-error'>
            {formik.touched.password && formik.errors.password ? <p>{formik.errors.password}</p> : null}
          </div>
          <Link className='forgot-link' to='/recover'>
            Olvidé mi contraseña
          </Link>

          <div className='form-error relative'>{message && <p>{message}</p>}</div>

          <input
            className='submit-button'
            type='submit'
            value='Iniciar sesión'
            disabled={
              formik.values.email && formik.values.password && !formik.errors.email && !formik.errors.password
                ? false
                : true
            }
            style={
              formik.values.email && formik.values.password && !formik.errors.email && !formik.errors.password
                ? { background: `${colors.richBlack}`, color: '#FFFFFF' }
                : { background: '#E7E8EA' }
            }
          />
          <div className='signup-link'>
            <p>¿Eres nuevo en MyOblek?</p>
            <Link to='/signup'>Crea una cuenta</Link>
          </div>
        </LoginForm>
      </section>
    </LoginContainer>
  )
}

export default Login
