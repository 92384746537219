import styled from 'styled-components'
import { colors } from '../../variables'

export const FaqButtonContainer = styled.div`
  position: absolute;
  top: 22.5px;
  right: calc(2.5% + 50px);
  z-index: 10;
  svg {
    font-size: 27px;
    color: ${colors.darkGray};
    cursor: pointer;
  }
  a.active > svg {
    color: ${colors.lightseaGreen};
  }
`
