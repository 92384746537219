import React from 'react'

const Arrow = ({ fill }) => {
  return (
    <svg width='16px' height='8px' viewBox='0 0 16 8'>
      <g id='FAQ´s' stroke='none' strokeWidth='1' fill='none' fillRule='evenodd'>
        <g
          id='FAQ-/-Menu-mobile'
          transform='translate(-338.000000, -169.000000)'
          fill={fill ? fill : '#748596'}
          fillRule='nonzero'
          stroke='#748596'
          strokeWidth='0.4'
        >
          <g id='Group' transform='translate(22.000000, 161.000000)'>
            <g transform='translate(317.000000, 5.000000)' id='icons/nav/arrow-paginator/accent'>
              <g transform='translate(7.000000, 7.000000) rotate(-270.000000) translate(-7.000000, -7.000000) '>
                <path
                  d='M13.8289445,3.40080941 C13.600875,3.17408917 13.2322069,3.17408917 13.0041374,3.40080941 L6.99998462,9.36941473 L0.995859217,3.40080941 C0.767789731,3.17408917 0.3991216,3.17408917 0.171052114,3.40080941 C-0.0570173715,3.62752965 -0.0570173715,3.99401676 0.171052114,4.220737 L6.58759474,10.5993197 C6.70134238,10.7123944 6.8506635,10.7692308 7.00001196,10.7692308 C7.14936043,10.7692308 7.29868155,10.7123944 7.41242919,10.5993197 L13.8289718,4.220737 C14.057014,3.99401676 14.057014,3.62752965 13.8289445,3.40080941 Z'
                  id='Path'
                  transform='translate(7.000000, 7.000000) rotate(-90.000000) translate(-7.000000, -7.000000) '
                ></path>
              </g>
            </g>
          </g>
        </g>
      </g>
    </svg>
  )
}

export default Arrow
