import styled from 'styled-components'
import { colors } from '../../../variables'

export const NewsContainer = styled.section`
  display: flex;
  flex-direction: column;
  .results-header {
    border-bottom: 2px solid ${colors.lightGray};
    display: flex;
    justify-content: space-between;
    align-items: left;
    flex-direction: column;
    padding-top: 20px;
    .total-articles {
      font-size: 21px;
      font-weight: 600;
      width: 100%;
      margin-bottom: 10px;
    }
    .results-period {
      display: flex;
      align-items: center;
      height: 30px;
      width: 100%;
      margin-bottom: 10px;
      svg {
        width: 20px;
        height: 20px;
        margin-right: 5px;
      }
      p {
        font-size: 14px;
        font-weight: 400;
      }
    }
  }
  .search-results {
    height: calc(100vh - 145px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    padding: 20px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }
  .date-input {
    width: 130px;
    margin: 0 10px 0 10px;
  }
`
