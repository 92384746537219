import styled from 'styled-components'
import { colors } from '../../variables'

export const ExposicionMediosContainer = styled.div`
  .widget--period_buttons {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    button {
      height: 25px;
      margin: 0 10px;
      border: none;
      background: none;
      outline: none;
      font-size: 12px;
      font-weight: 400;
      color: ${colors.darkGray};
      cursor: pointer;
    }
    .period--active {
      color: ${colors.purple};
      border-bottom: 1px solid ${colors.purple};
    }
  }
  .widget--period {
    display: flex;
    justify-content: center;
    margin-bottom: 15px;
    p {
      font-size: 12px;
      font-weight: 400;
      color: ${colors.darkGray};
    }
  }
`

export const ChartContainer = styled.div`
  width: '100%';
  .recharts-legend-wrapper {
    padding-left: 10px;
    li {
      margin-bottom: 10px;
    }
  }
  tspan {
    font-size: 10px;
  }
`
export const TooltipText = styled.div`
  background: white;
  padding: 10px;
  border: 1px solid #b0b0b0;
  p {
    font-size: 11px;
    color: ${colors.darkGray};
  }
  p:first-child {
    font-weight: bold;
  }
`

export const Placeholder = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`
