import React, { useContext, useState } from 'react'
import { ShareBoardContainer, Member } from '../../../style/components/dashboard/dashboard/shareBoard.styled'
import UserDropdown from '../../ui/UserDropdown'
import PermitDropdown from '../../ui/PermitDropdown'
import { GrClose } from 'react-icons/gr'
import { useMutation } from '@apollo/client'

import { SHARE_DASHBOARD, CHANGE_PERMIT, DELETE_PERMIT } from '../../../services/mutations'
import Button from '../../ui/Button'
import { MyContext } from '../../../context'
import user from '../../user/User'

const ShareBoard = ({ board, users, setShowShareBoard }) => {
  const roles = [
    {
      id: '1',
      name: 'Editor',
      description: 'Puede editar todas las características del tablero'
    },
    {
      id: '2',
      name: 'Vista',
      description: 'Acceso limitado. Sólo puede ver el tablero'
    }
  ]

  const [userSelection, setUserSelection] = useState([])
  const [roleSelection, setRoleSelection] = useState([roles[0]])

  const [shareDashboard] = useMutation(SHARE_DASHBOARD)
  const [changePermit, { loading: loadingChange }] = useMutation(CHANGE_PERMIT)
  const [deletePermit] = useMutation(DELETE_PERMIT)

  const { showNotifications, setNotificationText } = useContext(MyContext)

  const shareWithUser = async () => {
    try {
      // eslint-disable-next-line
      const { data } = await shareDashboard({
        variables: {
          input: {
            user: userSelection[0].id,
            dashboard: board.id,
            permit: roleSelection[0].name
          }
        }
      })
      setUserSelection([])
      setRoleSelection([roles[0]])
      showNotifications()
      setNotificationText(`Se invitó a <span><b>${userSelection[0].email}</b></span> a colaborar con el tablero.`)
    } catch (error) {
      console.log(error)
    }
  }

  const handlePermit = async (member, rol) => {
    // eslint-disable-next-line
    const { data } = await changePermit({
      variables: {
        input: {
          user: member,
          dashboard: board.id,
          permit: rol
        }
      }
    })
  }

  const removePermit = async (member, rol) => {
    // eslint-disable-next-line
    const { data } = await deletePermit({
      variables: {
        input: {
          user: member,
          dashboard: board.id,
          permit: rol
        }
      }
    })
  }

  return (
    <ShareBoardContainer>
      <GrClose className='close-icon' onClick={() => setShowShareBoard(false)} />
      <h3>Invitar un miembro</h3>
      <h4>Miembros del tablero</h4>
      <div className='members-section'>
        <Member>
          <div className='member-left'>
            <img src={board.createdBy.photo} alt='User avatar' />
            <div className='member-info'>
              <p className='member-name'>{board.createdBy.name}</p>
              <p className='member-email'>{board.createdBy.email}</p>
            </div>
          </div>
          <p className='member-owner'>Propietario</p>
        </Member>
        {board.editors.map(editor => (
          <Member key={editor.id}>
            <div className='member-left'>
              <img src={editor.photo} alt='User avatar' />
              <div className='member-info'>
                <p className='member-name'>{editor.name}</p>
                <p className='member-email'>{editor.email}</p>
              </div>
            </div>
            <div className='permit-buttons'>
              <button className='delete-permit-button' onClick={() => removePermit(editor.id, 'Editor')}>
                Eliminar
              </button>
              <button
                disabled={loadingChange}
                className='m-button-editor'
                onClick={() => handlePermit(editor.id, 'Vista')}
              >
                Editor
              </button>
            </div>
          </Member>
        ))}
        {board.viewers.map(viewer => (
          <Member key={viewer.id}>
            <div className='member-left'>
              <img src={viewer.photo} alt='User avatar' />
              <div className='member-info'>
                <p className='member-name'>{viewer.name}</p>
                <p className='member-email'>{viewer.email}</p>
              </div>
            </div>
            <div className='permit-buttons'>
              <button className='delete-permit-button' onClick={() => removePermit(viewer.id, 'Vista')}>
                Eliminar
              </button>
              <button className='m-button-viewer' onClick={() => handlePermit(viewer.id, 'Editor')}>
                Vista
              </button>
            </div>
          </Member>
        ))}
      </div>
      <h4>Agregar miembros</h4>
      <div className='share-form'>
        <div className='left-input'>
          <label>Usuario</label>
          <UserDropdown
            title={users.length === 0 ? 'No hay usuarios' : 'Selecciona un usuario'}
            items={users}
            selection={userSelection}
            setSelection={setUserSelection}
          />
        </div>
        <div className='right-input'>
          <label>Rol</label>
          <PermitDropdown
            title='Selecciona un rol'
            items={roles}
            selection={roleSelection}
            setSelection={setRoleSelection}
          />
        </div>
      </div>
      <Button
        theme={'primary'}
        disabled={userSelection.length === 0 || roleSelection.length === 0 ? true : false}
        onClick={shareWithUser}
      >
        Invitar
      </Button>
    </ShareBoardContainer>
  )
}

export default ShareBoard
