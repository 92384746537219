import React from 'react'
import { ReportDurationContainer } from '../../style/components/reports/ReportDuration.styled'

import 'rc-time-picker/assets/index.css'
import TimePicker from 'rc-time-picker'
import moment from 'moment'

const ReportDuration = ({ duration, handleDuration, hourDuration, setHourDuration, endless, handleEndless }) => {
  const today = moment().format().split('T')

  return (
    <ReportDurationContainer>
      <h2>Duración del reporte</h2>
      <div className='date-inputs'>
        <label htmlFor='from'>
          Desde:
          <input type='date' id='from' value={duration[0]} min={today[0]} onChange={e => handleDuration('from', e)} />
        </label>
        <label htmlFor='to'>
          Hasta:
          <input
            type='date'
            id='to'
            value={duration[1]}
            disabled={endless ? true : false}
            min={today[0]}
            onChange={e => handleDuration('to', e)}
          />
        </label>
      </div>
      <div className='hour-inputs'>
        <label htmlFor='from-hour'>
          A las:
          <TimePicker
            id='from-hour'
            showSecond={false}
            minuteStep={15}
            defaultValue={moment()}
            value={hourDuration}
            onChange={e => {
              setHourDuration(e)
            }}
          />
        </label>
        <label className='input-checkbox' htmlFor='endless'>
          <input type='checkbox' id='endless' checked={endless} onChange={() => handleEndless()} />
          Sin finalizar
        </label>
      </div>
    </ReportDurationContainer>
  )
}

export default ReportDuration
