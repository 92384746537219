import React from 'react'
import { ReportPeriodContainer } from '../../style/components/reports/ReportPeriod.styled'
import PeriodDropdown from '../ui/PeriodDropdown'

import moment from 'moment'

const periods = [
  {
    id: '0',
    name: 'Personalizado'
  },
  {
    id: '1',
    name: '1 día atrás',
    days: 1
  },
  {
    id: '2',
    name: '7 días atrás',
    days: 7
  },
  {
    id: '3',
    name: '15 días atrás',
    days: 15
  },
  {
    id: '4',
    name: '30 días atrás',
    days: 30
  }
]

const today = moment().format().split('T')

const ReportPeriod = ({ period, setPeriod, date, handleDate }) => {
  return (
    <ReportPeriodContainer>
      <h2>Periódo de la búsqueda</h2>
      <PeriodDropdown
        width='100%'
        title='Elige un periodo para tu búsqueda'
        items={periods}
        selection={period}
        setSelection={setPeriod}
        multiSelect={false}
      />
      {period[0] && period[0].name === 'Personalizado' && (
        <div className='date-inputs'>
          <label htmlFor='from'>
            Desde:
            <input type='date' id='from' value={date[0]} max={today[0]} onChange={e => handleDate('from', e)} />
          </label>
          <label htmlFor='to'>
            Hasta:
            <input type='date' id='to' value={date[1]} max={today[0]} onChange={e => handleDate('to', e)} />
          </label>
        </div>
      )}
    </ReportPeriodContainer>
  )
}

export default ReportPeriod
