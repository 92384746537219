import styled from 'styled-components'
import { colors } from '../../../variables'

const FormContainer = styled.div`
  width: 632px;
  height: 527px;
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  padding: 40px;
  border-radius: 20px;
  background: ${colors.white};
  opacity: 0;
  transform: translate(-50%, -45%);
  animation: enterModalPlan 0.3s forwards 0.15s;
  @keyframes enterModalPlan {
    to {
      opacity: 1;
      transform: translate(-50%, -50%);
    }
  }

  .modal__close {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 4px;

    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;

      path {
        stroke: ${colors.darkGray};
      }
    }
  }

  .changeplan__title {
    font-size: 24px;
    font-weight: 800;
    line-height: 29.26px;
    margin-bottom: 23px;
  }

  .changeplan__message__container {
    text-align: justify;
    margin-bottom: 40px;

    .changeplan__text {
      font-size: 16px;
      font-weight: 400;
      line-height: 24px;
      color: ${colors.richBlack};
    }
  }

  .changeplan__form {
    display: flex;
    flex-direction: column;
    width: 100%;

    .select__plan__title {
      margin-bottom: 8px;
      color: ${colors.richBlack};
      font-size: 14px;
      font-weight: 500;
    }

    input {
      width: 60%;
      height: 50px;
      padding: 0 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      font-size: 14px !important;
      font-weight: 400;
    }

    textarea {
      height: 100px;
      padding: 10px;
      border: 1px solid ${colors.darkGray};
      border-radius: 4px;
      outline: none;
      background: transparent;
      color: ${colors.darkGray};
      font-size: 14px;
      font-weight: 400;
      resize: none;
    }
    .textAreaLabel {
      display: flex;
      justify-content: space-between;
      margin: 20px 0 8px;
    }

    .dropdownContainer {
      .dropdown--area {
        .dropdown--content {
          p {
            margin: 0;
          }
          span {
            transform: translate(0, -2px);
          }
        }
        .dropdown--button {
          p {
            margin: auto;
            display: flex;
            justify-content: center;
            align-items: center;
            transition: ease-in-out 0.4s;
          }
        }

        .arrow__rotate {
          transform: rotate(180deg);
        }
      }

      .dropdown--list {
        li {
          button {
            p {
              margin: auto;
            }
          }
        }
      }
      width: 100%;
    }

    .error-message {
      margin: 0;
      color: ${colors.red};
      font-weight: bold;
    }

    .button-container {
      display: flex;
      justify-content: flex-end;
      button {
        margin-top: 25px;
      }
    }
  }
`

export default FormContainer
