import React, { useState, useEffect, useRef } from 'react'
import { SearchesDropdownContainer, Tag } from '../../style/components/ui/SearchDropdown.styled'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'
import { FiCheck } from 'react-icons/fi'
import CloseIcon from '../../assets/CloseIcon'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const SearchesDropdown = ({ searches, widget, selection, setSelection, multiSelect, placeholder }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false)

  const dropdown = useRef()
  const end = useRef()

  useOutsideClick(dropdown, () => {
    setDropdownOpen(false)
  })

  const handleSelection = item => {
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id)
      setSelection([...selectionAfterRemoval])
    }
    end.current.scrollIntoView({ behavior: 'smooth' })
  }

  const isItemInSelection = item => {
    return selection.find(current => current.id === item.id) ? true : false
  }

  return (
    <SearchesDropdownContainer ref={dropdown}>
      <div className='dropdown--area' tabIndex={0} role='button' onClick={() => setDropdownOpen(!dropdownOpen)}>
        <div className='dropdown--content'>
          {selection.length === 0 ? (
            <p>{placeholder}</p>
          ) : (
            selection.map(item => (
              <Tag key={item.id}>
                <p>{item.name}</p>
                <span onClick={() => handleSelection(item)}>
                  <CloseIcon />
                </span>
              </Tag>
            ))
          )}
          <div ref={end} />
        </div>
        <div className='dropdown--button'>{dropdownOpen ? <AiFillCaretUp /> : <AiFillCaretDown />}</div>
      </div>
      {dropdownOpen && (
        <ul className='dropdown--list'>
          {searches
            .filter(search => {
              if (widget.type === 'Medios') return search.source.includes('medios')
              if (widget.type === 'Twitter') return search.source.includes('twitter')
              return search
            })
            .map(search => (
              <li key={search.id}>
                <button onClick={() => handleSelection(search)}>
                  <p>{search.name}</p>
                  <span>{isItemInSelection(search) && <FiCheck />}</span>
                </button>
              </li>
            ))}
        </ul>
      )}
    </SearchesDropdownContainer>
  )
}

export default SearchesDropdown
