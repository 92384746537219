import { useQuery } from '@apollo/client'
import moment from 'moment'
import { NubeHashtagsContainer, ChartContainer, Placeholder } from '../../style/components/widgets/TemasMomento.styled'
import { ButtonSpinner } from '../../style/components'
import WordCloud from 'react-wordcloud'
import React, { useEffect, useRef, useState } from 'react'

import { NUBE_PALABRAS_MEDIOS } from '../../services/queries'

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  // eslint-disable-next-line
  const [width, setWidth] = useState()

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const info = JSON.parse(data.nubePalabras)
  // data && info.map(() => colors.push(randomColor()))

  const options = {
    enableTooltip: true,
    deterministic: false,
    fontFamily: 'Montserrat',
    fontSizes: [10, 40],
    fontStyle: 'normal',
    fontWeight: 'normal',
    padding: 5,
    rotations: 1,
    rotationAngles: [0],
    scale: 'sqrt',
    spiral: 'archimedean',
    transitionDuration: 1000
  }

  return (
    <ChartContainer ref={container}>
      <WordCloud options={options} words={info} />
    </ChartContainer>
  )
}

const TemasMomento = ({ widget, report, date }) => {
  const searchID = widget.searches.length > 0 ? widget.searches[0].id : ''
  const [period, setPeriod] = useState('week')

  const { data, loading, error } = useQuery(NUBE_PALABRAS_MEDIOS, {
    variables: {
      period,
      searchID,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }

    return (
      <NubeHashtagsContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>
        <Chart data={data} error={error} loading={loading} />
      </NubeHashtagsContainer>
    )
  } else {
    return (
      <NubeHashtagsContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'day' && <p>{moment().format('D MMM YY')}</p>}
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>
        <Chart data={data} error={error} loading={loading} />
      </NubeHashtagsContainer>
    )
  }
}

export default TemasMomento
