import React, { useState } from 'react'
import { DropdownContainer } from '../../style/components/ui/PermitDropdown.styled'
import onClickOutside from 'react-onclickoutside'
import { FiCheck } from 'react-icons/fi'
import { AiFillCaretDown, AiFillCaretUp } from 'react-icons/ai'

function PermitDropdown({ title, items = [], selection, setSelection, multiSelect = false }) {
  const [open, setOpen] = useState(false)

  const toggle = () => setOpen(!open)

  PermitDropdown.handleClickOutside = () => setOpen(false)

  const handleOnClick = item => {
    if (!selection.some(current => current.id === item.id)) {
      if (!multiSelect) {
        setSelection([item])
      } else if (multiSelect) {
        setSelection([...selection, item])
      }
    } else {
      let selectionAfterRemoval = selection
      selectionAfterRemoval = selectionAfterRemoval.filter(current => current.id !== item.id)
      setSelection([...selectionAfterRemoval])
    }
  }

  const isItemInSelection = item => {
    return selection.find(current => current.id === item.id) ? true : false
  }

  return (
    <DropdownContainer>
      <div
        tabIndex={0}
        className='dd-header'
        role='button'
        onKeyPress={() => toggle(!open)}
        onClick={() => toggle(!open)}
      >
        <div className='dd-header-title'>
          {selection.length === 0 ? <p>{title}</p> : selection.map(item => <p key={item.id}>{item.name}</p>)}
        </div>
        <div className='dd-header-action'>
          <p>{open ? <AiFillCaretUp /> : <AiFillCaretDown />}</p>
        </div>
      </div>
      {open && (
        <ul className='dd-list'>
          {items.map(item => (
            <li key={item.id} className='dd-list-item'>
              <button onClick={() => handleOnClick(item)}>
                <div className='dd-list-item-info'>
                  <div className='dd-list-item-info-text'>
                    <p>{item.name}</p>
                    <p className='dd-list-item-description'>{item.description}</p>
                  </div>
                </div>
                <span className='dd-list-item-check'>{isItemInSelection(item) && <FiCheck />}</span>
              </button>
            </li>
          ))}
        </ul>
      )}
    </DropdownContainer>
  )
}

const clickOutsideConfig = {
  handleClickOutside: () => PermitDropdown.handleClickOutside
}

export default onClickOutside(PermitDropdown, clickOutsideConfig)
