import React, { useState, useRef, useEffect } from 'react'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import {
  TonalidadMediosContainer,
  ChartContainer,
  Placeholder,
  TooltipText
} from '../../style/components/widgets/TonalidadMedios.styled'
import { ButtonSpinner } from '../../style/components'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import { TONALIDAD_MEDIOS_QUERY } from '../../services/queries'

const getPercent = (value, total) => {
  const ratio = total > 0 ? value / total : 0
  return toPercent(ratio, 2)
}

const toPercent = (decimal, fixed = 0) => {
  return `${(decimal * 100).toFixed(fixed)}%`
}

const renderTooltipContent = o => {
  const { payload, label } = o
  if (payload.length > 0) {
    const total = payload.reduce((result, entry) => result + entry.value, 0)
    return (
      <TooltipText>
        <p className='total'>{`${label} (Total: ${total})`}</p>
        {payload.map((entry, index) => (
          <div key={`item-${index}`} style={{ color: entry.color }}>
            {`${entry.name}: ${entry.value}(${getPercent(entry.value, total)})`}
          </div>
        ))}
      </TooltipText>
    )
  }
}

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  const [width, setWidth] = useState()

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const info = JSON.parse(data.tonalidadMedios)

  return (
    <ChartContainer ref={container}>
      <AreaChart width={width} height={300} data={info} stackOffset='expand'>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis width={40} tickFormatter={toPercent} />
        <Tooltip content={renderTooltipContent} />
        <Legend layout='vertical' align='right' verticalAlign='middle' iconType='square' />
        <Area type='monotone' dataKey='negativos' stackId='1' stroke='#EF9A9A' fill='#EF9A9A' />
        <Area type='monotone' dataKey='neutros' stackId='1' stroke='#9FA8DA' fill='#9FA8DA' />
        <Area type='monotone' dataKey='positivos' stackId='1' stroke='#80DEEA' fill='#80DEEA' />
      </AreaChart>
    </ChartContainer>
  )
}

const TonalidadMedios = ({ widget, report, date }) => {
  const searchID = widget.searches.length > 0 ? widget.searches[0].id : ''
  const [period, setPeriod] = useState('week')

  const { data, loading, error } = useQuery(TONALIDAD_MEDIOS_QUERY, {
    variables: {
      period,
      searchID,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }

    return (
      <TonalidadMediosContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>

        <Chart data={data} loading={loading} error={error} />
      </TonalidadMediosContainer>
    )
  } else {
    return (
      <TonalidadMediosContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>

        <Chart data={data} loading={loading} error={error} />

        <div className='widget--period'>
          {period === 'week' && <p>Periodo en días</p>}
          {period === 'month' && <p>Periodo en semanas</p>}
          {period === 'year' && <p>Periodo en meses</p>}
        </div>
      </TonalidadMediosContainer>
    )
  }
}

export default TonalidadMedios
