import React, { useContext, useState } from 'react'

import { UserCard } from '../../style/components/workspace/TeamContainer.styled'

import TrashIcon from '../../assets/IconTrash'
import { colors } from '../../style/variables'
import Notification from './Modals/Notification'
import { MyContext } from '../../context'

const MemberInvited = ({ invite, deleteInvite }) => {
  const [isTrashcanVisible, setTrashcanVisible] = useState(false)
  const { setNotificationText, showNotifications } = useContext(MyContext)

  const handleTrashcanVisibility = event => {
    return event.type === 'mouseover'
      ? setTrashcanVisible(true)
      : event.type === 'mouseout'
      ? setTrashcanVisible(false)
      : ''
  }
  const removeInvite = async id => {
    try {
      await deleteInvite({
        variables: {
          id
        }
      })
      showNotifications()
      setNotificationText(`El usuario <span><b>${invite.email}</span></b> se eliminó correctamente.`)
    } catch (e) {
      console.log(e)
    }
  }

  return (
    <UserCard>
      <div className='user-info'>
        <img
          className='user__avatar'
          src='https://res.cloudinary.com/evilvic/image/upload/v1594316301/myoblek_test/user.png'
          alt='User avatar'
        />
        <div>
          <h6 className='user__email'>{invite.email}</h6>
        </div>
      </div>
      <div className='user__invitation' onMouseOver={handleTrashcanVisibility} onMouseOut={handleTrashcanVisibility}>
        <div
          className={`user__trashcan ${isTrashcanVisible ? 'trashcan__visible' : 'trashcan__hidden'}`}
          onClick={() => removeInvite(invite.id)}
        >
          <TrashIcon stroke={colors.red} />
        </div>
        <button className='user__invitation__pending'>Invitación pendiente</button>
      </div>
    </UserCard>
  )
}

export default MemberInvited
