import React from 'react'
import { NavLink } from 'react-router-dom'
import { FaqButtonContainer } from '../../style/components/ui/FaqButton.styled'
import { IoIosHelpCircleOutline } from 'react-icons/io'

const FaqButton = () => {
  return (
    <FaqButtonContainer>
      <NavLink to='/faq'>
        <IoIosHelpCircleOutline />
      </NavLink>
    </FaqButtonContainer>
  )
}

export default FaqButton
