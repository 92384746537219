import React from 'react'

const SettingsIcon = () => {
  return (
    <svg viewBox='0 0 22 22'>
      <path
        className='settings'
        d='M21.25,9.43a1,1,0,0,0-.8-.66c-.47-.06-.94-.11-1.42-.14A1,1,0,0,1,18.2,8h0a1,1,0,0,1,.16-1c.31-.35.61-.72.9-1.09a1,1,0,0,0,.1-1,5.17,5.17,0,0,0-2.22-2.22,1,1,0,0,0-1,.1c-.37.29-.74.59-1.09.9a1,1,0,0,1-1,.16h0A1,1,0,0,1,13.37,3c0-.48-.08-1-.14-1.42a1,1,0,0,0-.66-.8A5,5,0,0,0,11,.5h0A5,5,0,0,0,9.43.75a1,1,0,0,0-.66.8C8.71,2,8.66,2.49,8.63,3A1,1,0,0,1,8,3.8H8a1,1,0,0,1-1-.16c-.35-.31-.72-.61-1.09-.9a1,1,0,0,0-1-.1A5.17,5.17,0,0,0,2.64,4.86a1,1,0,0,0,.1,1c.29.37.59.74.9,1.09A1,1,0,0,1,3.8,8h0A1,1,0,0,1,3,8.63c-.48,0-1,.08-1.42.14a1,1,0,0,0-.8.66,5.05,5.05,0,0,0,0,3.14,1,1,0,0,0,.8.66c.47.06.94.11,1.42.14A1,1,0,0,1,3.8,14h0a1,1,0,0,1-.16,1c-.31.35-.61.72-.9,1.09a1,1,0,0,0-.1,1,5.17,5.17,0,0,0,2.22,2.22,1,1,0,0,0,1-.1c.37-.29.74-.59,1.09-.9a1,1,0,0,1,1-.16H8a1,1,0,0,1,.61.83c0,.48.08.95.14,1.42a1,1,0,0,0,.66.8A5,5,0,0,0,11,21.5h0a5,5,0,0,0,1.57-.25,1,1,0,0,0,.66-.8c.06-.47.11-.94.14-1.42A1,1,0,0,1,14,18.2h0a1,1,0,0,1,1,.16c.35.31.72.61,1.09.9a1,1,0,0,0,1,.1,5.17,5.17,0,0,0,2.22-2.22,1,1,0,0,0-.1-1c-.29-.37-.59-.74-.9-1.09a1,1,0,0,1-.16-1h0a1,1,0,0,1,.83-.61c.47,0,.95-.08,1.42-.14a1,1,0,0,0,.8-.66,5.05,5.05,0,0,0,0-3.14ZM15.64,11A4.64,4.64,0,0,1,11,15.64h0A4.64,4.64,0,0,1,6.36,11h0A4.64,4.64,0,0,1,11,6.36h0A4.64,4.64,0,0,1,15.64,11Z'
      />
    </svg>
  )
}

export default SettingsIcon
