import React, { useState } from 'react'
import EditSearchContainer from '../../../style/components/search/searches/EditSearch.styled'
import CloseModal from '../../workspace/Modals/CloseModal'
import { useHistory } from 'react-router-dom'
import Button from '../../ui/Button'

const EditSearch = ({ search, closeModal, updateSearch }) => {
  const [name, setName] = useState(search.name)
  const history = useHistory()
  return (
    <EditSearchContainer>
      <CloseModal onClick={closeModal} />
      <div className='editsearch__header__container'>
        <h3 className='editsearch__header'>Editar búsqueda</h3>
      </div>
      <div className='editsearch__content'>
        <p className='editsearch__text'>Cambia el nombre o los parámetros de la búsqueda.</p>
        <div className='editsearch__rename'>
          <p className='renamesearch__text'>Renombra tu búsqueda</p>
          <input className='renamesearch__input' value={name} onChange={e => setName(e.target.value)} />
          <Button
            variant={'link'}
            weight={500}
            width={'fit-content'}
            onClick={() => history.push(`/searches/search/${search.id}?edit`)}
            className='renamesearch__button'
          >
            Editar parámetros de la búsqueda
          </Button>
        </div>
      </div>
      <div className='editsearch__buttons'>
        <Button variant={'link'} theme={'dark'} height={'47px'} size={'medium'} onClick={closeModal}>
          Cancelar
        </Button>
        <Button
          size='medium'
          height='47px'
          theme='primary'
          onClick={() =>
            updateSearch(search.id, {
              name: name
            })
          }
        >
          Guardar
        </Button>
      </div>
    </EditSearchContainer>
  )
}

export default EditSearch
