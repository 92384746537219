import React from 'react'
import { DashboardFiltersContainer } from '../../../style/components/dashboard/dashboards/DashboardsFilters.syled'
import { GrClose } from 'react-icons/gr'

import Button from '../../ui/Button'

// const DashboardFiltersContainer = styled.div`
//   width: 400px;
//   height: 100vh;
//   border-right: 1px solid ${colors.lightGray};
//   position: absolute;
//   top: 0;
//   left: ${props => props.sidebar ? '68px' : '222px'};
//   transition: all 0.25s linear;
//   padding: 150px 2.5%;
//   .filters-header {
//     display: flex;
//     justify-content: space-between;
//     align-items: center;
//     margin-bottom: 30px;
//     h4 {
//       font-size: 24px;
//       font-weight: 500;
//     }
//     svg {
//       width: 24px;
//       height: 24px;
//       cursor: pointer;
//       path { stroke: ${colors.darkGray}; }
//     }
//   }
// `

const DashboardFilters = ({ sidebar, showFilters, filters, resetFilters, section }) => {
  return (
    <DashboardFiltersContainer sidebar={sidebar}>
      <div className='filters--header'>
        <h4>Filtros</h4>
        <GrClose onClick={() => showFilters(false)} />
      </div>

      <div className='filters--content'>
        <label>Nombre del tablero</label>
        <input type='text' value={filters.name} onChange={e => filters.setName(e.target.value)} />

        {section === 'shared' && (
          <>
            <label>Autor del tablero</label>
            <input type='text' value={filters.creator} onChange={e => filters.setCreator(e.target.value)} />
          </>
        )}

        <h5>Fecha del tablero</h5>
        <div className='date'>
          <label>Desde</label>
          <input type='date' value={filters.from} onChange={e => filters.setFrom(e.target.value)} />
        </div>
        <div className='date'>
          <label>Hasta</label>
          <input type='date' value={filters.to} onChange={e => filters.setTo(e.target.value)} />
        </div>

        <div className='reset'>
          <Button size={'medium'} variant='outline' theme={'secondary'} onClick={() => resetFilters()}>
            Borrar filtros
          </Button>
        </div>
      </div>
    </DashboardFiltersContainer>
  )
}

export default DashboardFilters
