import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const InfoModalContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 711px;
  height: 339px;
  padding: 50px;
  border-radius: 4px;
  background: ${colors.white};
  h3 {
    margin-bottom: 30px;
    font-size: 30px;
    font-weight: 700;
    color: ${colors.purple};
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    color: ${colors.darkGray};
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`
