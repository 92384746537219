import styled from 'styled-components'
import { colors } from '../../../variables'

export const DeleteWidgetContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 711px;
  padding: 50px;
  border-radius: 4px;
  background: ${colors.white};
  .filters--header {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-content: center;
    align-items: center;
    margin-bottom: 30px;
    h3 {
      font-size: 30px;
      font-weight: 700;
      color: ${colors.red};
    }
    svg {
      width: 24px;
      height: 24px;
      cursor: pointer;
      path {
        stroke: ${colors.darkGray};
      }
    }
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    span {
      font-size: 16px;
      font-weight: 700;
      line-height: 26px;
    }
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
    button {
      margin-left: 10px;
    }
  }
`
