import styled from 'styled-components'
import { colors } from '../../variables'

export const TeamContainer = styled.div`
  display: flex;
  padding-top: 40px;

  .members__section {
    width: 53%;
    padding: 30px 0 40px 0;
    border-right: 1px solid ${colors.lightGray};
    overflow-y: scroll;

    &__title {
      font-size: 22px;
      font-weight: 600;
      line-height: 35px;
      color: ${colors.richBlack};
      margin-bottom: 24px;
    }

    &__text {
      font-size: 16px;
      font-weight: 600;
      line-height: 19.5px;
      color: ${colors.richBlack};
    }

    .team-container {
      height: calc(100vh - 210px);

      .user__container {
        position: relative;
        padding-right: 24px;

        :hover {
          background-color: #eef2fb;
          .user-role {
            background-color: #eef2fb;
            cursor: pointer;
          }
        }
      }
    }
  }

  .invite__section {
    width: 47%;
    padding: 30px 0 40px 26px;
    overflow-y: scroll;
    height: 90vh;
    .invite__headline {
      margin-bottom: 40px;
      color: ${colors.richBlack};
      font-size: 22px;
      font-weight: 600;
      line-height: 35px;
    }
  }

  @media (min-width: 2560px) {
    .members__section {
      padding: 40px 35px 20px;
    }
    .invite__section {
      padding: 40px 35px 20px;
    }
  }
`

export const UserCard = styled.article`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 26px 0 25px 0;
  padding: 10px 0 8px 0;
  border-radius: 5px;

  .user-info {
    display: flex;
    align-items: center;
    .user__avatar {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      margin-right: 20px;
    }
    .user__name {
      color: #748596;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }

    .user__email {
      color: ${colors.richBlack};
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
    }
    .faqsIcon {
      background-color: red;
      height: 100%;
      width: 100%;
    }
  }
  .user__invitation {
    display: flex;
    flex-direction: row;
    margin-right: 24px;
    .user__trashcan {
      display: flex;
      margin-right: 16px;
    }
    .trashcan__visible {
      visibility: visible;
    }
    .trashcan__hidden {
      visibility: hidden;
    }
    svg {
      align-self: center;
      :hover {
        cursor: pointer;
      }
    }
    .user__invitation__pending {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${colors.lightGray};
      border-radius: 4px;
      height: 48px;
      width: 177px;
      border: 1px solid #748596;
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      background-color: ${colors.white};
    }
  }
  .user__options {
    display: flex;
    flex-direction: row;
    .user__trashcan {
      display: flex;
      margin-right: 16px;
    }
    .trashcan__visible {
      visibility: visible;
    }
    .trashcan__hidden {
      visibility: hidden;
    }
    svg {
      align-self: center;
      :hover {
        cursor: pointer;
      }
    }
    .user-role {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid ${colors.lightGray};
      border-radius: 4px;
      height: 48px;
      width: 177px;
      padding: 15px 55px;
      border: 1px solid #748596;
      border-radius: 4px;
      outline: none;
      font-size: 14px;
      font-weight: 500;
      line-height: 18px;
      background-color: ${colors.white};
      .arrow__admin {
        margin-left: 20px;
      }
      .arrow {
        margin-left: 34.83px;
      }

      .arrow__rotate {
        transform: rotate(180deg);
      }
    }
  }
  .option__hidden {
    visibility: hidden;
  }
  .option__visible {
    visibility: visible;
  }
  .delete-button {
    display: none;
  }

  @media (min-width: 1024px) and (max-width: 1439px) {
    .user-info {
      img {
        width: 30px;
        height: 30px;
        border-radius: 50%;
        margin-right: 20px;
      }
    }
    .user__invitation {
      .user__invitation__pending {
        height: 35px;
      }
    }
    .user__options {
      width: 145px;
      .user-role {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid ${colors.lightGray};
        border-radius: 4px;
        height: 35px;
        width: 145px;
        padding: 0 15px 0 15px;
        border: 1px solid #748596;
        border-radius: 4px;
        outline: none;
        font-size: 14px;
        font-weight: 500;
        line-height: 18px;
        background-color: ${colors.white};
      }
    }
    .nonadmin__user__options {
      .admin__user__role {
        padding-left: 1px;
        padding-right: 0;
        .arrow__admin {
          margin-left: 6px;
        }
      }
    }
    .nonadmin__user__options {
      width: 182px;
      .nonadmin__user__role {
        width: 145px;
        padding-right: 0;
        .arrow {
          margin-left: 14px;
          margin-right: 6px;
        }
        .arrow__rotate {
          transform: rotate(180deg);
          margin: 0 6px 0 14px;
        }
      }
    }
  }
`
