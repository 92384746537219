import styled from 'styled-components'
import { colors } from '../../../variables'

export const TwitterSearchContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 55px;
  .twitter-search-bar {
    display: flex;
    width: 90%;
    margin-bottom: 30px;
    .twitter-search-button {
      margin-left: 20px;
    }
    .modified-search-button {
      margin-left: 20px;
    }
  }
  .no-results {
    display: flex;
    flex-direction: column;
    padding-top: 200px;
    & > p {
      width: 100%;
      margin-bottom: 10px;
      font-size: 15px;
      font-weight: 500;
      text-align: center;
    }
  }
  .twitter-search-results {
    width: 90%;
    height: calc(100vh - 245px);
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: flex-start;
    padding: 20px 10px;
    overflow-x: hidden;
    overflow-y: scroll;
    scrollbar-width: thin;
  }

  .results-header {
    border-bottom: 2px solid ${colors.lightGray};
    display: flex;
    justify-content: space-between;
    height: 40px;
    width: 90%;
    .total-tweets {
      margin-bottom: 20px;
      font-size: 21px;
      font-weight: 600;
    }
    .results-period {
      display: flex;
      align-items: center;
      height: 30px;
      svg {
        width: 20px;
        height: 20px;
      }
      p {
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
  .date-input {
    width: 130px;
    margin: 0 10px 0 10px;
  }
  .date-button {
    padding: 0 15px;
  }
`

export const ChangeContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  height: 450px;
  background: ${colors.white};
`
