import React from 'react'
import {
  CoverPage,
  TittleCoverPage,
  IconCoverPage
} from '../../../style/components/reports/reportDetails/ReportCoverPage.styled'

import { IconWhiteReport } from '../../../assets/reports/icons/IconWhite'

import moment from 'moment'
moment.locale('es')

const ReportCoverPage = ({ name, number, date, description, width, height }) => {
  const formatRangeDate = () => {
    const yyA = moment(date[0]).year()
    const yyB = moment(date[1]).year()

    if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
    return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
  }

  return (
    <CoverPage id='reportCover' style={{ width, height }}>
      <TittleCoverPage>
        <h1>{name}</h1>
        <h3>{formatRangeDate()}</h3>
        <h3>No.{number.toString().length === 1 ? `0${number}` : number}</h3>
        <div>
          <p>{description}</p>
        </div>
      </TittleCoverPage>
      <IconCoverPage>
        <IconWhiteReport width='200px' height='50px' />
      </IconCoverPage>
    </CoverPage>
  )
}

export default ReportCoverPage
