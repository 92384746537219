import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const PDFInfo = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 600px;
  padding: 40px;
  border-radius: 20px;
  background: ${colors.white};
  height: 300px;

  h3 {
    margin-bottom: 15px;
    font-size: 30px;
    font-weight: 700;
  }

  p {
    width: 100%;
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
    text-align: justify;
  }

  button {
    margin-top: 25px;
  }
`
