import React from 'react'
import ReactDOM from 'react-dom'
import { BrowserRouter } from 'react-router-dom'
import Router from './Router'
import packageJson from '../package.json'

import UIProvider from './contexts/UIContext'
import MyProvider from './context'

import GlobalStyles from './style/globalStyles'
import * as serviceWorker from './serviceWorker'

import { ApolloProvider } from '@apollo/client'
import client from './config/apollo'
import './assets/base.css'
import { sendAnalyticsError } from './services/analytics'

import Cache from './config/cache'
global.appVersion = packageJson.version

window.addEventListener('activate', function (event) {
  event.waitUntil(
    caches.keys().then(function (names) {
      for (let name of names) caches.delete(name)
      window.location.href = './'
    })
  )
})

const WhitUIContext = () => (
  <UIProvider>
    <Router />
  </UIProvider>
)

const WhitContext = () => (
  <BrowserRouter>
    <MyProvider>
      <WhitUIContext />
    </MyProvider>
  </BrowserRouter>
)

const WhitApollo = () => (
  <React.StrictMode>
    <Cache>
      <ApolloProvider client={client}>
        <GlobalStyles />
        <WhitContext />
      </ApolloProvider>
    </Cache>
  </React.StrictMode>
)

ReactDOM.render(<WhitApollo />, document.getElementById('root'))

window.addEventListener('error', e => {
  sendAnalyticsError(e)
})

serviceWorker.register()
