import React from 'react'
import { Search } from '../../../assets/Flows'
import {
  Container,
  Head,
  NoData,
  Row,
  TableHead,
  TableRow
} from '../../../style/components/search/searches/Clipping.styled'
import TrashIcon from '../../../assets/icons/icon_trash.svg'
import Edit from '../../../assets/icons/IconEdit'
import ProfileIcon from '../../../assets/icons/icon_profile.svg'
import { useMutation, useQuery } from '@apollo/client'
import { CLIPPINGTICKETS_QUERY } from '../../../services/queries'
import { useHistory, useParams } from 'react-router'
import { Spinner } from '../../../style/components'
import moment from 'moment'
import { DELETE_CLIPPING_TICKET } from '../../../services/mutations'

const ClippingScheduled = () => {
  const { id } = useParams()
  const history = useHistory()

  const { data: tickets, loading: loadingTickets } = useQuery(CLIPPINGTICKETS_QUERY, {
    fetchPolicy: 'network-only',
    variables: { searchID: id }
  })
  const [deleteTicket] = useMutation(DELETE_CLIPPING_TICKET)

  const headers = ['Fecha', 'Nombre', 'Periodo', 'Horas', 'Creador', '']

  const dummyRow = [{ fecha: '29/dic/2021', periodo: ' 7 dias atrás', horas: '09:00 a 21:00', creador: 'usuario' }]

  if (loadingTickets) return <Spinner />

  return (
    <Container>
      {dummyRow.length !== 0 ? (
        <>
          <hr />
          <TableHead>
            {headers.map((e, i) => (
              <Head key={i}>{e}</Head>
            ))}
          </TableHead>
          {tickets.getTicketClipping.map((el, ix) => (
            <TableRow
              key={ix}
              onClick={e => {
                e.cancelBubble = true
                e.stopPropagation()
                history.push(`/clipping-scheduled/${el.name}/${el.id}`)
              }}
            >
              <Row>{moment(new Date(Number(el.createdAt))).format('DD/MM/YYYY')}</Row>
              <Row>{el.name}</Row>
              <Row>{el.periodName}</Row>
              <Row>{`${el.startHour} - ${el.endHour}`}</Row>
              <Row>
                <img src={ProfileIcon} alt='hi' width='35px' height='35px' style={{ marginRight: '5px' }} />{' '}
                {el.createdBy.name}
              </Row>
              <Row className='options' style={{ justifyContent: 'space-between' }}>
                <img
                  src={TrashIcon}
                  alt='hi'
                  width='20px'
                  height='20px'
                  style={{ marginRight: '5px' }}
                  onClick={e => {
                    e.cancelBubble = true
                    e.stopPropagation()
                    deleteTicket({ variables: { id: el.id } })
                  }}
                />
                <Edit />
              </Row>
            </TableRow>
          ))}
        </>
      ) : (
        <NoData>
          <div className='container'>
            <div className='icon'>
              <Search />
            </div>
            <div className='text'>
              <p>
                No cuentas con ningún clipping de notas por el momento. Puedes generar uno en la pestaña de Resultados.
              </p>
            </div>
          </div>
        </NoData>
      )}
    </Container>
  )
}

export default ClippingScheduled
