import React, { useEffect, useState } from 'react'
import EditTwitterSearchContainer from '../../../style/components/search/search/EditTwitterSearch.styled'
import TwitterSearchBar from '../new-search/TwitterSearchBar'
import { Shadow } from '../../../style/components'
import { ChangeContainer } from '../../../style/components/search/new-serches/TwitterSearch.styled'
import ChangeSearch from '../new-search/ChangeSearch'
import Button from '../../ui/Button'

const reverseWord = word => {
  if (word.includes(':')) {
    const parts = word.split(':')[0]
    return parts[0] + ': @' + parts[1]
  }

  return word.replace(/"(.+)"/, '$1')
}

const joinWords = (words, replacer) => {
  const mappedArray = words.map(word => {
    if (word.split('').includes('@')) {
      return word.replace(/\s/, '').replace(/@/, '')
    } else {
      return `"${word}"`
    }
  })

  return mappedArray.length > 0 ? mappedArray.join(` ${replacer} `) : ''
}

const getTwitterQuery = parameters => {
  const orQuery = joinWords(parameters.or, 'OR')
  const andQuery = joinWords(parameters.and, 'OR')
  const notQuery = joinWords(parameters.not, 'OR')

  return orQuery + (andQuery ? ' AND ' + andQuery : '') + (notQuery ? ' AND NOT ' + notQuery : '')
}

const getTwitterParamsFromQuery = query => {
  let text = query
  let or = []
  let not = []
  let and = []

  const andNotIndex = text.indexOf(' AND NOT ')
  if (andNotIndex >= 0) {
    not = text.slice(andNotIndex + 9).split(' OR ')
    text = text.slice(0, andNotIndex)
  }

  const andIndex = text.indexOf(' AND ')
  if (andIndex >= 0) {
    and = text.slice(andIndex + 5).split(' OR ')
    text = text.slice(0, andIndex)
  }

  or = text.split(' OR ')

  return {
    or: or.map(word => reverseWord(word)),
    and: and.map(word => reverseWord(word)),
    not: not.map(word => reverseWord(word))
  }
}

const EditTwitterSearch = ({ search, setSearch, saveSearch }) => {
  const query = search.query
  const [parameters, setParameters] = useState(getTwitterParamsFromQuery(query))
  const [words, setWords] = useState({ or: '', not: '', and: '' })
  const [showFilters, setShowFilters] = useState(false)

  useEffect(() => {
    setSearch({
      ...search,
      query: getTwitterQuery(parameters)
    })
  }, [parameters.or])

  const cancel = () => {
    setParameters(getTwitterParamsFromQuery(query))
    setShowFilters(false)
  }

  const handleInput = (value, param) => {
    setWords(words => ({ ...words, [param]: value }))
  }

  const handleAdd = (e, param) => {
    if (e.keyCode === 13 && words[param].trim() !== '') {
      setParameters(parameters => ({
        ...parameters,
        [param]: [...parameters[param], words[param]]
      }))
      setWords(words => ({ ...words, [param]: '' }))
    }
  }

  const deleteParam = (index, param) => {
    setParameters(prev => ({
      ...prev,
      [param]: [...prev[param].slice(0, index), ...prev[param].slice(index + 1)]
    }))
  }

  const mappings = { twOrWord: 'or', twNotWord: 'not', twAndWord: 'and' }

  const setFrom = ({ target: { id } }) => setWords({ ...words, [mappings[id]]: 'from: @' })
  const setTo = ({ target: { id } }) => setWords({ ...words, [mappings[id]]: 'to: @' })
  const setHashtag = ({ target: { id } }) => setWords({ ...words, [mappings[id]]: '#' })

  return (
    <EditTwitterSearchContainer>
      <div className='twitter-search-bar'>
        <TwitterSearchBar
          disabled
          width='calc(100% - 370px)'
          name='twOrWord'
          queryParams={parameters.or}
          inputWord={words.or}
          handleInput={e => handleInput(e.target.value, 'or')}
          addParam={e => {
            handleAdd(e, 'or')
            setSearch(search => ({ ...search, query: getTwitterQuery(parameters) }))
          }}
          deleteParam={index => {
            if (parameters.or.length > 1) deleteParam(index, 'or')
          }}
          setFrom={setFrom}
          setTo={setTo}
          setHashtag={setHashtag}
          placeholder='@cuentas, #hashtags y/o palabras'
        />
        <Button
          theme={'secondary'}
          className='twitter-search-button'
          disabled={parameters.or.length > 0 ? false : true}
          onClick={saveSearch}
        >
          Guardar búsqueda
        </Button>
        <Button
          size='small'
          variant='outline'
          theme='secondary'
          className='modified-search-button'
          onClick={() => setShowFilters(true)}
        >
          Modificar
        </Button>
      </div>

      {showFilters && <Shadow onClick={cancel} />}
      <ChangeContainer id='change' className={showFilters ? 'change-search-not-collapsed' : 'change-search-collpased'}>
        <ChangeSearch
          closeChangeSearch={() => setShowFilters(false)}
          cancelChanges={cancel}
          twOrParams={parameters.or}
          twAndParams={parameters.and}
          twNotParams={parameters.not}
          twOrWord={words.or}
          twAndWord={words.and}
          twNotWord={words.not}
          handleTwitterOrInput={e => handleInput(e.target.value, 'or')}
          handleTwitterAndInput={e => handleInput(e.target.value, 'and')}
          handleTwitterNotInput={e => handleInput(e.target.value, 'not')}
          addTwitterOrWord={e => handleAdd(e, 'or')}
          addTwitterAndWord={e => handleAdd(e, 'and')}
          addTwitterNotWord={e => handleAdd(e, 'not')}
          deleteTwitterOrParam={index => deleteParam(index, 'or')}
          deleteTwitterAndParam={index => deleteParam(index, 'and')}
          deleteTwitterNotParam={index => deleteParam(index, 'not')}
          setFrom={setFrom}
          setTo={setTo}
          setHashtag={setHashtag}
          makeTwitterSearch={() => setSearch(search => ({ ...search, query: getTwitterQuery(parameters) }))}
        />
      </ChangeContainer>
    </EditTwitterSearchContainer>
  )
}

export default EditTwitterSearch
