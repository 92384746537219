import React from 'react'
import AllSearchesContainer from '../../../style/components/search/searches/AllSearches.styled'
import NoSearches from './NoSearches'
import Search from './Search'

const AllSearches = ({
  searches,
  filtersOpen,
  ordersOpen,
  filters,
  order,
  setSearchToDelete,
  setSearchToEdit,
  setSearchToDuplicate
}) => {
  let sorted = [...searches]

  if (searches.length === 0) return <NoSearches filtersOpen={filtersOpen} />

  if (order.AtoZ) {
    sorted = searches.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.ZtoA) {
    sorted = searches.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = -1
      } else if (nameA < nameB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.recentDate) {
    sorted = searches.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = -1
      } else if (dateA < dateB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.oldDate) {
    sorted = searches.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = 1
      } else if (dateA < dateB) {
        comparison = -1
      }
      return comparison
    })
  }

  return (
    <AllSearchesContainer filtersOpen={filtersOpen}>
      <div className='searches-table-header'>
        <h3 className='number'>No.</h3>
        <h3 className='name'>Nombre</h3>
        <h3 className='source'>Fuente</h3>
        <h3 className='date'>Fecha</h3>
        <h3 className='creator'>Autor</h3>
      </div>

      <div className='searches-table-body'>
        {sorted
          .filter(search => search.name.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(search => search.createdBy.name.toLowerCase().includes(filters.creator.toLowerCase()))
          .filter(search => {
            if (filters.from !== '') {
              const year = filters.from.split('').splice(0, 4).join('')
              const month = filters.from.split('').splice(5, 2).join('') - 1
              const day = filters.from.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return search.createdAt > date
            }
            return search
          })
          .filter(search => {
            if (filters.to !== '') {
              const year = filters.to.split('').splice(0, 4).join('')
              const month = filters.to.split('').splice(5, 2).join('') - 1
              const day = filters.to.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return search.createdAt < date
            }
            return search
          })
          // eslint-disable-next-line
        .filter(search => {
            if (filters.source.length === 0) return search
            for (let i = 0; i < search.source.length; i++) {
              if (filters.source.includes(search.source[i])) return search
            }
          })
          .map((search, idx) => (
            <Search
              key={search.id}
              setSearchToDelete={setSearchToDelete}
              setSearchToDuplicate={setSearchToDuplicate}
              search={search}
              setSearchToEdit={setSearchToEdit}
              number={idx + 1 < 10 ? `0${idx + 1}` : idx + 1}
            />
          ))}
      </div>
    </AllSearchesContainer>
  )
}

export default AllSearches
