import React, { useContext, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import SearchContainer from '../../style/pages/search/Search.styled'
import { UIContext } from '../../contexts/UIContext'
import { MainContainer, Topbar, Spinner, Toolbar, Shadow } from '../../style/components'
import { IoIosArrowBack } from 'react-icons/io'
import { useLazyQuery, useMutation, useQuery } from '@apollo/client'
import News from '../../components/search/search/News'
import Tweets from '../../components/search/search/Tweets'

import { SEARCH_QUERY, NEWS_QTY_QUERY, NEWS_QUERY } from '../../services/queries'
import ClippingInmediate from '../../components/search/searches/ClippingInmediate'
import ClippingScheduled from '../../components/search/searches/ClippingScheduled'
import ClippingModal from '../../components/search/searches/ClippingModal'
import ContactClippingModal from '../../components/search/searches/ContactClippingModal'
import PeriodDropdown from '../../components/ui/PeriodDropdown'
import { CREATE_I_CLIPPING, CREATE_S_CLIPPING } from '../../services/mutations'
import moment from 'moment'
import EditFilters from '../../components/search/search/EditFilters'
import Button from '../../components/ui/Button'

const Search = props => {
  const uiCTX = useContext(UIContext)
  const { mainClass, topbarClass, toolbarClass } = uiCTX

  const history = useHistory()

  const [editFilters, setEditFilters] = useState(history.location.search === '?edit')
  const [section, setSection] = useState('resultados')
  const [sectionClipping, setSectionClipping] = useState([{ id: 0, name: 'Inmediatos' }])

  const [showClippingModal, setShowClippingModal] = useState(false)
  const [openContact, setOpenContact] = useState(false)

  const [checkAll, setCheckAll] = useState(false)
  const [notes, setNotes] = useState([])

  const [startDate, setStartDate] = useState(moment().format('YYYY-MM-DD'))
  const [endDate, setEndDate] = useState(moment().format('YYYY-MM-DD'))

  const { data, loading, error } = useQuery(SEARCH_QUERY, {
    variables: {
      id: props.match.params.id
    }
  })

  const [search, setSearch] = useState(null)

  const [getNewsQty, { data: newsQtyData, loading: newsQtyLoading }] = useLazyQuery(NEWS_QTY_QUERY)
  const [getNews, { data: dataNQ, loading: loadingNQ, error: errorNQ, fetchMore }] = useLazyQuery(NEWS_QUERY)

  useEffect(() => {
    if (data) {
      setSearch(data.search)
    }
  }, [data])

  const fetchNewsData = () => {
    getNewsQty({
      variables: {
        input: {
          query: search.query,
          startDate,
          endDate,
          medio: search.filters.media,
          cobertura: data.search.filters.state
        }
      }
    })
    getNews({
      variables: {
        input: {
          query: search.query,
          startDate,
          endDate,
          medio: search.filters.media,
          cobertura: search.filters.state,
          pagina: 1
        }
      }
    })
  }

  useEffect(() => {
    if (search && search.source.includes('medios')) {
      fetchNewsData()
    }
  }, [search])

  const [createIClipping] = useMutation(CREATE_I_CLIPPING)
  const [createSClipping] = useMutation(CREATE_S_CLIPPING)

  if (loading || !search) return <Spinner />

  if (error) return <Redirect to='/searches' />

  const setResultadosSection = () =>
    section === 'resultados' ? 'section-button section-button-active' : 'section-button'
  const setClippingSection = () => (section === 'clipping' ? 'section-button section-button-active' : 'section-button')

  const handleContactClipping = () => {
    setShowClippingModal(false)
    setOpenContact(true)
  }

  const createClipping = async (email, exportType, name) => {
    const data = JSON.stringify({
      notes,
      email,
      exportType,
      searchID: props.match.params.id,
      startDate,
      endDate,
      name
    })
    try {
      await createIClipping({ variables: { data } })
      setShowClippingModal(false)
    } catch ({ message }) {
      console.log(message)
    }
    setNotes([])
  }

  const createClippingTicket = async (email, period, hour, exportType, name) => {
    const dataR = JSON.stringify({
      email,
      period: {
        name: period[0].name,
        days: period[0].days,
        startHour: hour[0],
        endHour: hour[1]
      },
      exportType,
      name: name,
      searchID: props.match.params.id,
      queryDysorda: {
        query: data.search.query,
        startDate,
        endDate,
        medio: data.search.filters.media,
        cobertura: data.search.filters.state,
        pagina: 1
      }
    })
    await createSClipping({ variables: { data: dataR } })
    setShowClippingModal(false)
    setNotes([])
  }

  return (
    <MainContainer className={mainClass()}>
      <SearchContainer>
        <Topbar className={topbarClass()}>
          <div className='left-topbar'>
            <button className='back-link' onClick={() => props.history.push('/searches')}>
              <IoIosArrowBack className='back-icon' />
              <p>Atrás</p>
            </button>
            <h1>{search.name}</h1>
            <Button
              theme={'primary'}
              height={'37px'}
              width='170px'
              className={'edit-filters-button'}
              variant={'outline'}
              onClick={() =>
                setEditFilters(prev => {
                  if (prev) {
                    setSearch(data.search)
                  }
                  return !prev
                })
              }
            >
              {editFilters ? 'Cancelar' : 'Editar parámetros'}
            </Button>
          </div>
        </Topbar>
        {editFilters && (
          <EditFilters
            open={editFilters}
            closeEdition={() => setEditFilters(false)}
            setSearch={setSearch}
            search={search}
          />
        )}
        {false && (
          <Toolbar className={toolbarClass()}>
            <div className='left-toolbar'>
              <button className={setResultadosSection()} onClick={() => setSection('resultados')}>
                Resultados
              </button>
              <button className={setClippingSection()} onClick={() => setSection('clipping')}>
                Clippings
              </button>
            </div>
          </Toolbar>
        )}

        {section === 'resultados' && (
          <>
            {search.source.includes('medios') && (
              <News
                search={search}
                fetchData={fetchNewsData}
                // fetchMoreData={fetchMore}

                newsQtyData={newsQtyData}
                newsQtyLoading={newsQtyLoading}
                data={dataNQ}
                loading={loadingNQ}
                error={errorNQ}
                fetchMore={fetchMore}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
                setShowClippingModal={setShowClippingModal}
                setNotes={setNotes}
                notesA={notes}
                checkAll={checkAll}
                setCheckAll={setCheckAll}
              />
            )}
            {search.source.includes('twitter') && (
              <Tweets search={search} setShowClippingModal={setShowClippingModal} />
            )}
          </>
        )}

        {section === 'clipping' && (
          <div className='clippingMain'>
            <div className='clippingSelector'>
              <PeriodDropdown
                items={[
                  { id: 0, name: 'Inmediatos' },
                  { id: 1, name: 'Programados' }
                ]}
                multiSelect={false}
                selection={sectionClipping}
                setSelection={setSectionClipping}
              />
            </div>
            {sectionClipping[0].id === 0 ? <ClippingInmediate /> : <ClippingScheduled />}
          </div>
        )}
      </SearchContainer>

      {showClippingModal && (
        <>
          <Shadow onClick={() => setShowClippingModal(false)} />
          <ClippingModal
            closeModal={setShowClippingModal}
            openContact={handleContactClipping}
            createClipping={createClipping}
            createClippingTicket={createClippingTicket}
          />
        </>
      )}

      {openContact && (
        <>
          <Shadow onClick={() => setOpenContact(false)} />
          <ContactClippingModal closeModal={setOpenContact} />
        </>
      )}
    </MainContainer>
  )
}

export default Search
