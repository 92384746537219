import React from 'react'
import { useHistory } from 'react-router-dom'
import { AllSearchesContainer } from '../../../style/components/search/searches/AllSearchesM.styled'
import { IoIosGlobe } from 'react-icons/io'
import { FiTwitter } from 'react-icons/fi'
import NoSearches from './NoSearches'

const AllSearches = ({ searches, filtersOpen, ordersOpen, filters, order, setSearchToDelete }) => {
  const history = useHistory()

  if (searches === null) return <NoSearches filtersOpen={filtersOpen} />
  if (searches.length === 0) return <NoSearches filtersOpen={filtersOpen} />

  let sorted = [...searches]

  if (order.AtoZ) {
    sorted = searches.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = 1
      } else if (nameA < nameB) {
        comparison = -1
      }
      return comparison
    })
  } else if (order.ZtoA) {
    sorted = searches.slice().sort((a, b) => {
      const nameA = a.name.toUpperCase()
      const nameB = b.name.toUpperCase()
      let comparison = 0
      if (nameA > nameB) {
        comparison = -1
      } else if (nameA < nameB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.recentDate) {
    sorted = searches.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = -1
      } else if (dateA < dateB) {
        comparison = 1
      }
      return comparison
    })
  } else if (order.oldDate) {
    sorted = searches.slice().sort((a, b) => {
      const dateA = a.createdAt
      const dateB = b.createdAt
      let comparison = 0
      if (dateA > dateB) {
        comparison = 1
      } else if (dateA < dateB) {
        comparison = -1
      }
      return comparison
    })
  }

  return (
    <AllSearchesContainer filtersOpen={filtersOpen}>
      <div className='searches-table-body'>
        {sorted
          .filter(search => search.name.toLowerCase().includes(filters.name.toLowerCase()))
          .filter(search => search.createdBy.name.toLowerCase().includes(filters.creator.toLowerCase()))
          .filter(search => {
            if (filters.from !== '') {
              const year = filters.from.split('').splice(0, 4).join('')
              const month = filters.from.split('').splice(5, 2).join('') - 1
              const day = filters.from.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return search.createdAt > date
            }
            return search
          })
          .filter(search => {
            if (filters.to !== '') {
              const year = filters.to.split('').splice(0, 4).join('')
              const month = filters.to.split('').splice(5, 2).join('') - 1
              const day = filters.to.split('').splice(8, 2).join('')
              let date = new Date(year, month, day)
              date = date.getTime()
              return search.createdAt < date
            }
            return search
          })
          // eslint-disable-next-line
          .filter(search => {
            if (filters.source.length === 0) return search
            for (let i = 0; i < search.source.length; i++) {
              if (filters.source.includes(search.source[i])) return search
            }
          })
          .map((search, idx) => {
            let name = search.name
            if (name.split('').length > 35) {
              name = name.split('').slice(0, 35).join('') + '...'
            }
            // let date = new Date(Number(search.createdAt))
            // date = date.toString().split('').slice(4,15).join('')
            return (
              <div className='searches-table-row' key={search.id}>
                <div className='searchCont' onClick={() => history.push(`/searches/search/${search.id}`)}>
                  <div className='searchCont-header'>
                    <p className='number'>{idx + 1 < 10 ? `0${idx + 1}` : idx + 1}</p>
                    <p className='name'>{name}</p>
                  </div>
                  <div className='searchCont-body'>
                    <div className='creatorCont'>
                      <p className='creator'>
                        <img src={search.createdBy.photo} className='creator-photo' alt='user avatar' />
                        {search.createdBy.name}
                      </p>
                    </div>
                    <div className='sourceCont'>
                      {search.source[0] === 'medios' ? (
                        <IoIosGlobe />
                      ) : (
                        <div className='twitter'>
                          <FiTwitter />
                        </div>
                      )}
                      <p className='source'>{search.source[0]}</p>
                    </div>
                  </div>
                  <div className='searchCont-footer'>
                    {search.source[0] === 'medios' ? <p>Ver notas</p> : <p>Ver tweets</p>}
                  </div>
                </div>
              </div>
            )
          })}
      </div>
    </AllSearchesContainer>
  )
}

export default AllSearches
