import React from 'react'
import { useHistory } from 'react-router-dom'
import { NoSearchesContainer } from '../../../style/components/search/searches/NoSearches.styled'

import { Search } from '../../../assets/Flows'
import Button from '../../ui/Button'

const NoSearches = ({ filtersOpen }) => {
  const history = useHistory()

  return (
    <NoSearchesContainer filtersOpen={filtersOpen}>
      <div className='no-searches--icon'>
        <Search />
      </div>
      <p>¡Crea una búsqueda ahora mismo!</p>
      <Button
        width={'560px'}
        height='40px'
        theme='primary'
        variant='solid'
        onClick={() => history.push('/searches/new-search')}
      >
        Nueva búsqueda
      </Button>
    </NoSearchesContainer>
  )
}

export default NoSearches
