import React from 'react'
import { SelectedWidgetContainer } from '../../../style/components/dashboard/dashboard/SelectedWidget.syled'
import { GrClose } from 'react-icons/gr'
import SearchesDropdown from '../../ui/SearchesDropdown'

import { Internet } from '../../../assets/Media'
import { FiTwitter } from 'react-icons/fi'

import TonalidadMedios from '../../../assets/widgets/images/TonalidadMedios.png'
import NumeroNotas from '../../../assets/widgets/images/NumeroNotas.png'
import MediosGanados from '../../../assets/widgets/images/MediosGanados.png'
import TipoMedios from '../../../assets/widgets/images/TipoMedios.png'
import TemasMomento from '../../../assets/widgets/images/TemasMomento.png'
import MapaCalor from '../../../assets/widgets/images/MapaCalor.png'
import ExposicionMedios from '../../../assets/widgets/images/ExposicionMedios.png'
import ShareVoice from '../../../assets/widgets/images/ShareVoice.png'
import TonalidadTwitter from '../../../assets/widgets/images/TonalidadTwitter.png'
import NubeHashtags from '../../../assets/widgets/images/NubeHashtags.png'
import PublicadoresVolumen from '../../../assets/widgets/images/PublicadoresVolumen.png'
import InfluencersTwitter from '../../../assets/widgets/images/InfluencersTwitter.png'
import Button from '../../ui/Button'

const SelectedWidget = ({
  widget,
  closeWidgetsMenu,
  searchesData,
  searchesLoading,
  searchesSelection,
  setSearchesSelection,
  addWidget
}) => {
  if (widget)
    return (
      <SelectedWidgetContainer>
        <div className='selected-widget--header'>
          <h5>{widget.displayName}</h5>
          <button onClick={() => closeWidgetsMenu()}>
            <GrClose />
          </button>
        </div>
        <div className='selected-widget--graph'>
          {(() => {
            switch (widget.componentName) {
              case 'TonalidadMedios':
                return <img src={TonalidadMedios} alt='widget example' />
              case 'NumeroNotas':
                return <img src={NumeroNotas} alt='widget example' />
              case 'MediosGanados':
                return <img src={MediosGanados} alt='widget example' />
              case 'TipoMedios':
                return <img src={TipoMedios} alt='widget example' />
              case 'TemasMomento':
                return <img src={TemasMomento} alt='widget example' />
              case 'MapaCalor':
                return <img src={MapaCalor} alt='widget example' />
              case 'ExposicionMedios':
                return <img src={ExposicionMedios} alt='widget example' />
              case 'ShareVoice':
                return <img src={ShareVoice} alt='widget example' />
              case 'TonalidadTwitter':
                return <img src={TonalidadTwitter} alt='widget example' />
              case 'NubeHashtags':
                return <img src={NubeHashtags} alt='widget example' />
              case 'InfluencersTwitter':
                return <img src={InfluencersTwitter} alt='widget example' />
              case 'MencionesTwitter':
                return <img src={MediosGanados} alt='widget example' />
              case 'PublicadoresVolumen':
                return <img src={PublicadoresVolumen} alt='widget example' />
              default:
                return <h1>No hay imagen...</h1>
            }
          })()}
        </div>
        <div className='selected-widget--description'>
          <div className='selected-widget--type'>
            {widget.type === 'Medios' && (
              <p>
                <Internet />
                Medios
              </p>
            )}
            {widget.type === 'Twitter' && (
              <p>
                <FiTwitter className='twitter-icon' />
                Twitter
              </p>
            )}
          </div>
          <p>{widget.description}</p>
        </div>
        <div className='select-widget--dropdown'>
          {!searchesLoading && (
            <SearchesDropdown
              searches={searchesData.searches}
              widget={widget}
              selection={searchesSelection}
              setSelection={setSearchesSelection}
              multiSelect={true}
              placeholder='Selecciona tus búsquedas'
            />
          )}
        </div>
        <div className='select-widget--button'>
          <Button size='medium' height='50px' theme='primary' variant='solid' onClick={() => addWidget()}>
            Agregar al tablero
          </Button>
        </div>
      </SelectedWidgetContainer>
    )
  return <p>Loading...</p>
}

export default SelectedWidget
