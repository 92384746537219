import React, { useEffect, useRef, useState } from 'react'
import { BsThreeDots, BsTrash } from 'react-icons/bs'
import { useHistory } from 'react-router-dom'
import SearchRowContainer from '../../../style/components/search/searches/Search.styled'
import IconEdit from '../../../assets/icons/IconEdit'
import { RiFileCopyLine } from 'react-icons/all'

const useOutsideClick = (ref, callback) => {
  const handleClick = e => {
    if (ref.current && !ref.current.contains(e.target)) {
      callback()
    }
  }
  useEffect(() => {
    document.addEventListener('click', handleClick)
    return () => {
      document.removeEventListener('click', handleClick)
    }
  })
}

const Search = ({ search, setSearchToDelete, setSearchToEdit, number, setSearchToDuplicate }) => {
  const history = useHistory()
  const optionsRef = useRef()
  const [showMenu, setShowMenu] = useState(false)

  useOutsideClick(optionsRef, () => setShowMenu(false))

  let name = search.name
  if (name.split('').length > 35) {
    name = name.split('').slice(0, 35).join('') + '...'
  }
  let date = new Date(Number(search.createdAt))
  date = date.toString().split('').slice(4, 15).join('')

  return (
    <SearchRowContainer>
      <div ref={optionsRef} className='menu'>
        <button onClick={() => setShowMenu(!showMenu)}>
          <BsThreeDots />
        </button>
        {showMenu && (
          <div className='options'>
            <button onClick={() => setSearchToEdit(search)} className='edit__option'>
              <IconEdit stroke='#748596' fill='#748596' />
              Editar búsqueda
            </button>
            <button onClick={() => setSearchToDuplicate(search)} className='edit__option'>
              <RiFileCopyLine color={'#748596'} />
              Duplicar búsqueda
            </button>
            <button onClick={() => setSearchToDelete(search)} className='delete__option'>
              <BsTrash />
              Eliminar búsqueda
            </button>
          </div>
        )}
      </div>
      <div className='searchCont' onClick={() => history.push(`/searches/search/${search.id}`)}>
        <p className='number'>{number}</p>
        <p className='name'>{name}</p>
        <p className='source'>{search.source}</p>
        <p className='date'>{date}</p>
        <p className='creator'>
          <img src={search.createdBy.photo} className='creator-photo' alt='user avatar' />
          {search.createdBy.name}
        </p>
      </div>
    </SearchRowContainer>
  )
}

export default Search
