import React from 'react'
import { Search } from '../../../assets/Flows'
import {
  Container,
  Head,
  NoData,
  Row,
  TableHead,
  TableRow
} from '../../../style/components/search/searches/Clipping.styled'
import ProfileIcon from '../../../assets/icons/icon_profile.svg'
import TrashIcon from '../../../assets/icons/icon_trash.svg'
import { useMutation, useQuery } from '@apollo/client'
import { CLIPPING_QUERY } from '../../../services/queries'
import { Spinner } from '../../../style/components'
import moment from 'moment'
import { useHistory, useParams } from 'react-router'
import { DELETE_CLIPPING } from '../../../services/mutations'

const ClippingInmediate = () => {
  const { id } = useParams()

  const history = useHistory()

  const {
    data: clippings,
    loading: loadingClippings,
    refetch
  } = useQuery(CLIPPING_QUERY, { fetchPolicy: 'network-only', variables: { searchID: id } })
  const [deleteClipping] = useMutation(DELETE_CLIPPING)

  const headers = ['Fecha', 'Nombre', 'Periodo', 'Notas', 'Archivo', 'Creador', '']

  if (loadingClippings) return <Spinner />

  return (
    <Container>
      {clippings.getClippings.length !== 0 ? (
        <>
          <hr />
          <TableHead>
            {headers.map((e, i) => (
              <Head key={i}>{e}</Head>
            ))}
          </TableHead>
          <div style={{ overflow: 'auto' }}>
            {clippings.getClippings.map((el, ix) => (
              <TableRow
                key={ix}
                onClick={e => {
                  e.cancelBubble = true
                  e.stopPropagation()
                  history.push(`/clipping/${el.id}`)
                }}
              >
                <Row>{moment(new Date(Number(el.createdAt))).format('DD/MM/YYYY')}</Row>
                <Row>{el.name}</Row>
                <Row>{`${el.startDate} - ${el.endDate}`}</Row>
                <Row>{el.noteQty}</Row>
                <Row>{el.exportType}</Row>
                <Row>
                  <img src={ProfileIcon} alt='profileIcon' width='35px' height='35px' style={{ marginRight: '5px' }} />{' '}
                  {el.createdBy.name}
                </Row>
                <Row
                  className='deleteButton'
                  onClick={e => {
                    e.cancelBubble = true
                    e.stopPropagation()
                    deleteClipping({ variables: { id: el.id } })
                    refetch()
                  }}
                >
                  <img src={TrashIcon} alt='trashIcon' width='20px' height='20px' style={{ marginRight: '5px' }} />
                </Row>
              </TableRow>
            ))}
          </div>
        </>
      ) : (
        <NoData>
          <div className='container'>
            <div className='icon'>
              <Search />
            </div>
            <div className='text'>
              <p>
                No cuentas con ningún clipping de notas por el momento. Puedes generar uno en la pestaña de Resultados.
              </p>
            </div>
          </div>
        </NoData>
      )}
    </Container>
  )
}

export default ClippingInmediate
