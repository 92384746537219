import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const TableRow = styled.div`
  display: flex;
  height: 50px;
  border-radius: 4px;
  margin-bottom: 10px;
  background: ${colors.white};
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
  p {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    width: calc(89% / 3);
    padding: 0 2%;
    color: ${colors.darkGray};
    font-size: 14px;
    font-weight: 500;
    &.number {
      width: 7.5%;
    }
    &.source {
      text-transform: capitalize;
    }
  }
  .creator {
    display: flex;
    align-items: center;
    .creator-photo {
      width: 30px;
      height: 30px;
      margin-right: 10px;
      background: ${colors.lightGray};
      border-radius: 50%;
    }
  }
  .dots-icon {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 45px;
    svg {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
    }
  }
`

export const ReportMenu = styled.div`
  z-index: 5;
  button {
    border: none;
    background: none;
    outline: none;
    width: 25px;
    height: 25px;
    border-radius: 4px;
    cursor: pointer;
    svg {
      width: 20px;
      height: 20px;
      fill: ${colors.darkGray};
    }
  }
  .report-options {
    position: absolute;
    z-index: 6;
    width: 190px;
    height: 126px;
    margin-left: 37vw;
    margin-top: -1%;
    background: ${colors.white};
    box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.15);
    border-radius: 4px;
    .full_option {
      height: 42px;
      width: 100%;
      display: flex;
      align-items: center;
      font-size: 14px;
      font-weight: 500;
      color: ${colors.darkGray};
      padding-left: 20px;
      &:hover {
        background: ${colors.lightGray};
      }
      svg {
        width: 20px;
        height: 20px;
        margin-right: 10px;
      }
    }
    .delete_button {
      color: ${colors.red};
    }
    .option_button {
      color: ${colors.silverSand};
      &:hover {
        color: ${colors.gunMetal};
      }
    }
  }
`
