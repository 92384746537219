import React, { useState } from 'react'
import { ReportNameFormContainer } from '../../style/components/reports/ReportNameForm.styled'
import Button from '../ui/Button'

const ReportNameForm = ({ name, setName, description, setDescription, edit }) => {
  const [descriptionOpen, setDescriptionOpen] = useState(false)

  if (edit) {
    return (
      <ReportNameFormContainer>
        <h2>Editar reporte</h2>
        <label>Renombra tu reporte</label>
        <input type='text' value={name} onChange={e => setName(e.target.value)} />
        {!descriptionOpen && (
          <Button width={'320px'} weight={500} variant={'link'} onClick={() => setDescriptionOpen(true)}>
            Agrega una descripción para tu reporte
          </Button>
        )}
        {descriptionOpen && (
          <>
            <label>Cambiar descripción del reporte</label>
            <textarea value={description} onChange={e => setDescription(e.target.value)} />
          </>
        )}
      </ReportNameFormContainer>
    )
  } else {
    return (
      <ReportNameFormContainer>
        <h2>Crea tu nuevo reporte</h2>
        <label>Nombra tu nuevo reporte</label>
        <input type='text' value={name} onChange={e => setName(e.target.value)} />
        {!descriptionOpen && (
          <Button width={'320px'} weight={500} variant={'link'} onClick={() => setDescriptionOpen(true)}>
            Agrega una descripción para tu reporte
          </Button>
        )}
        {descriptionOpen && (
          <>
            <label>Descripción del reporte</label>
            <textarea value={description} onChange={e => setDescription(e.target.value)} />
          </>
        )}
      </ReportNameFormContainer>
    )
  }
}

export default ReportNameForm
