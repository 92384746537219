import React from 'react'
import { FuncionaContainer } from '../../style/components/faqs/ComoFunciona.styled'

// Imagenes
import Funciona1 from '../../assets/faqs/funciona/funciona1.png'
import Funciona2 from '../../assets/faqs/funciona/funciona2.png'
import Funciona3 from '../../assets/faqs/funciona/funciona3.png'
import Funciona4 from '../../assets/faqs/funciona/funciona4.png'
import Funciona5 from '../../assets/faqs/funciona/funciona5.png'
import Funciona6 from '../../assets/faqs/funciona/funciona6.png'

const ComoFunciona = ({ busquedaRef, traditionalRef, twitterRef }) => {
  return (
    <FuncionaContainer id='funciona'>
      <h4>¿Cómo funciona MyOblek?</h4>

      <p>Si es la primera vez que utilizas la plataforma, así es como se ve la página principal de MyOblek:</p>
      <img src={Funciona1} alt='Funciona 1' />

      <p>
        En la barra lateral izquierda puedes encontrar el menú de todas las herramientas que tiene MyOblek (Búsquedas,
        Tableros, Perfil y Workspace).
      </p>
      <p>
        Si no es la primera vez que utilizas MyOblek y ya tienes búsquedas guardadas, así se ve tu página principal:
      </p>
      <img src={Funciona2} alt='Funciona 2' />

      <p>
        Si necesitas empezar una nueva búsqueda, dale clic en el botón “Nueva búsqueda” que está en la parte superior de
        la pantalla.
      </p>
      <p>
        En la parte inferior derecha siempre podrás encontrar el servicio de mensajería instantánea por si tienes alguna
        duda y necesitas que te ayudemos.
      </p>

      <section id='busqueda' ref={busquedaRef}>
        <h5>Búsquedas</h5>

        <p>
          La herramienta de “Búsquedas” te permite encontrar los resultados que estás buscando, ya sea a través de
          medios tradicionales (prensa, revistas, radio, tv y web) o redes sociales como Twitter.
        </p>
        <p>Para separar palabras o frases debes presionar la tecla enter.</p>
      </section>

      <section id='medios' ref={traditionalRef}>
        <h5>Medios tradicionales</h5>

        <p>
          Coloca las palabras clave en la barra buscadora y presiona ‘enter’ cada vez que quieras buscar una nueva
          palabra.
        </p>
        <img src={Funciona3} alt='Funciona 3' />

        <p>
          Una vez seleccionadas tus palabras clave, MyOblek te mostrará todos los resultados relacionados con tu
          búsqueda, así como el periódo de tu búsqueda. Cada resultado tiene un enlace a la fuente directa si deseas
          consultarla.
        </p>
        <img src={Funciona4} alt='Funciona 4' />

        <p>
          También puedes filtrar tu búsqueda para optimizar los resultados. Solamente dale clic en el botón de “Filtro”
          que está en la esquina superior derecha y refina tu búsqueda agregando palabras de contexto, excluyendo
          palabras, seleccionando una cobertura del medio (internacional, nacional o estatal) y/o eligiendo el tipo de
          medio (prensa, revista, radio, TV y web).
        </p>
        <img src={Funciona5} alt='Funciona 5' />

        <p>
          Una vez completada tu búsqueda puedes darle clic en el botón de “Guardar búsqueda” para guardarla y ponerle un
          título.
        </p>
        <img src={Funciona6} alt='Funciona 6' />

        <p>
          Ya que esté guardada puedes encontrarla fácilmente en la barra lateral izquierda en la pestaña de “Búsquedas”.
          Es importante que estés satisfecho con tus filtros de búsqueda, ya que una vez guardada puedes consultarla o
          eliminarla, pero no puedes editarla.
        </p>
      </section>

      <section ref={twitterRef}>
        <h5 id='tw'>Twitter</h5>

        <p>
          <strong>Contenido pendiente</strong>
        </p>
      </section>
    </FuncionaContainer>
  )
}

export default ComoFunciona
