import styled from 'styled-components'
import { colors } from '../../variables'

export const DropdownContainer = styled.div`
  position: relative;
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  outline: none;
  .dd-header {
    min-height: 60px;
    background: white;
    outline: none;
    border-color: gray;
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;
    width: 100%;
    padding: 12px;
    .dd-header-title {
      width: 100%;
      display: flex;
      align-items: center;
      padding: 0 10px;
      overflow-y: auto;
      flex-wrap: wrap;
      gap: 8px;
      .dd-header-item {
        height: 34px;
        padding: 5px 12px 5px 12px;
        background: ${colors.lightGray};
        display: flex;
        align-items: center;
        border-radius: 100px;
        gap: 11px;
        span {
          width: 16px;
          height: 14.72px;
        }
        .dd-header-item-info-img {
          width: 24px;
          height: 24px;
        }
      }
      p {
        text-align: left;
        font-size: 16px;
        font-weight: 500;
        color: ${colors.richBlack};
      }
    }
    .dd-header-action {
      svg {
        width: 20px;
        height: 20px;
        fill: ${colors.darkGray};
      }
    }
  }
  .dd-list {
    position: absolute;
    transform: translateY(50px);
    border-radius: 0 0 4px 4px;
    border-top: 2px solid ${colors.lightseaGreen};
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.25);
    width: 375px;
    height: ${props => `${props.heigth * 50}px`};
    max-height: 120px;
    overflow-y: scroll;
    background: ${colors.white};
    .dd-list-item {
      width: 100%;
      height: 50px;
      button {
        display: flex;
        justify-content: space-between;
        width: 100%;
        height: 100%;
        padding: 10px;
        border: none;
        outline: none;
        background: none;
        .dd-list-item-info {
          display: flex;
          width: 75%;
          .dd-list-item-info-img {
            width: 30px;
            height: 30px;
            border-radius: 50%;
            margin-right: 10px;
          }
          .dd-list-item-info-text {
            p {
              text-align: left;
              font-size: 12px;
              font-weight: 500;
              color: ${colors.richBlack};
            }
            span {
              font-size: 12px;
              font-weight: 400;
              color: ${colors.darkGray};
            }
          }
        }
        .dd-list-item-check {
          height: 30px;
          width: 30px;
          svg {
            width: 75%;
            height: 75%;
            stroke: ${colors.lightseaGreen};
          }
        }
      }
    }
  }
`
