import React, { useState, useRef, useEffect } from 'react'
import {
  MencionesTwitterContainer,
  ChartContainer,
  Placeholder
} from '../../style/components/widgets/MediosTwitter.styled'
import moment from 'moment'
import { useQuery } from '@apollo/client'
import { ButtonSpinner } from '../../style/components'
import { AreaChart, Area, XAxis, YAxis, CartesianGrid, Tooltip, Legend } from 'recharts'

import { MENCIONES_TWITTER_QUERY } from '../../services/queries'

const Chart = ({ data, loading, error }) => {
  const container = useRef()
  const [width, setWidth] = useState()

  useEffect(() => {
    const updateWidth = () => {
      setWidth(container.current ? container.current.offsetWidth : 0)
    }
    window.addEventListener('resize', updateWidth)
    window.addEventListener('transitionend', updateWidth)
    updateWidth()
    return () => {
      window.removeEventListener('resize', updateWidth)
      window.removeEventListener('transitionend', updateWidth)
    }
  })

  if (loading)
    return (
      <Placeholder>
        <ButtonSpinner />
      </Placeholder>
    )
  if (error && error.message && error.message.includes('búsquedas'))
    return (
      <Placeholder>
        <p>{error.message}</p>
      </Placeholder>
    )
  if (error)
    return (
      <Placeholder>
        <p>Hubo un error.</p>
      </Placeholder>
    )

  const info = JSON.parse(data.mencionesTwitter)

  let search
  for (const key in info[0]) {
    if (key !== 'name') {
      search = key
    }
  }

  return (
    <ChartContainer ref={container}>
      <AreaChart width={width} height={300} data={info}>
        <defs>
          <linearGradient id={'gradient'} x1='0' y1='0' x2='0' y2='1'>
            <stop offset='5%' stopColor='#9FA8DA' stopOpacity={0.75} />
            <stop offset='95%' stopColor='#9FA8DA' stopOpacity={0} />
          </linearGradient>
        </defs>
        <CartesianGrid strokeDasharray='3 3' />
        <XAxis dataKey='name' />
        <YAxis width={40} />
        <Tooltip />
        <Legend layout='vertical' align='right' verticalAlign='middle' iconType='square' />
        <Area type='monotone' dataKey={search} stroke='#9FA8DA' fillOpacity={1} fill='url(#gradient)' />
      </AreaChart>
    </ChartContainer>
  )
}

const MencionesTwitter = ({ widget, report, date }) => {
  const searchID = widget.searches.length > 0 ? widget.searches[0].id : ''
  const [period, setPeriod] = useState('day')

  const { data, loading, error } = useQuery(MENCIONES_TWITTER_QUERY, {
    variables: {
      period,
      searchID,
      report,
      date
    }
  })

  const handlePeriodButton = name => setPeriod(name)

  if (report) {
    const formatRangeDate = () => {
      const yyA = moment(date[0]).year()
      const yyB = moment(date[1]).year()

      if (yyA === yyB) return `${moment(date[0]).format('DD MMM')} - ${moment(date[1]).format('DD MMM YYYY')}`
      return `${moment(date[0]).format('DD MMM YYYY')} - ${moment(date[1]).format('DD MMM YYYY')}`
    }

    return (
      <MencionesTwitterContainer>
        <div className='widget--period'>Periodo: {formatRangeDate()}</div>

        <Chart data={data} loading={loading} error={error} />
      </MencionesTwitterContainer>
    )
  } else {
    return (
      <MencionesTwitterContainer>
        <div className='widget--period_buttons'>
          <button
            className={period === 'day' ? 'period--active' : 'period--inactive'}
            name='day'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Hoy
          </button>
          <button
            className={period === 'week' ? 'period--active' : 'period--inactive'}
            name='week'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Semana
          </button>
          <button
            className={period === 'month' ? 'period--active' : 'period--inactive'}
            name='month'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Mes
          </button>
          <button
            className={period === 'year' ? 'period--active' : 'period--inactive'}
            name='year'
            onClick={({ target: { name } }) => handlePeriodButton(name)}
          >
            Año
          </button>
        </div>

        <div className='widget--period'>
          {period === 'day' && <p>{moment().format('D MMM YY')}</p>}
          {period === 'week' && (
            <p>
              {moment().subtract(6, 'days').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'month' && (
            <p>
              {moment().subtract(1, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
          {period === 'year' && (
            <p>
              {moment().subtract(11, 'months').format('D MMM YY')} - {moment().format('D MMM YY')}
            </p>
          )}
        </div>

        <Chart data={data} loading={loading} error={error} />

        <div className='widget--period'>
          {period === 'day' && <p>Periodo en horas</p>}
          {period === 'week' && <p>Periodo en días</p>}
          {period === 'month' && <p>Periodo en semanas</p>}
          {period === 'year' && <p>Periodo en meses</p>}
        </div>
      </MencionesTwitterContainer>
    )
  }
}

export default MencionesTwitter
