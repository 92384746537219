import React from 'react'
import { QueEsContainer } from '../../style/components/faqs/QueEs.styled'

function QueEs({ queEsRef }) {
  return (
    <QueEsContainer id='queEs' ref={queEsRef}>
      <h4>¿Qué es?</h4>
      <p>
        MyOblek es la plataforma de monitoreo, recopilación y análisis de data mediática más completa de México, en
        donde puedes personalizar y filtrar tus búsquedas, crear tableros para graficar los datos recopilados, medir los
        resultados y automatizar tus reportes de comunicación.
      </p>
      <p>
        MyOblek está diseñada para que la plataforma sea sencilla de utilizar y fácil de entender. De todas formas, te
        compartimos los siguientes consejos para que seas un experto en el uso de la plataforma.
      </p>
      <p>
        Si tienes más dudas puedes contactarnos por mensaje, correo electrónico o también puedes programar una llamada
        con nosotros.
      </p>
    </QueEsContainer>
  )
}

export default QueEs
