import styled from 'styled-components'
import { colors } from '../../../../style/variables'

export const ReportLimitWarningContainer = styled.div`
  z-index: 30;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 740px;
  padding: 40px;
  border-radius: 4px;
  background: ${colors.white};
  h3 {
    margin-bottom: 30px;
    font-size: 40px;
    font-weight: 700;
  }
  p {
    margin-bottom: 30px;
    font-size: 16px;
    font-weight: 400;
    line-height: 26px;
    span {
      font-size: 16px;
      font-weight: 600;
    }
  }
  input {
    width: 100%;
    height: 50px;
    border: 1px solid ${colors.darkGray};
    border-radius: 4px;
    outline: none;
    background: ${colors.lightGray};
    font-size: 15px;
    font-weight: 400;
    padding-left: 10px;
    margin-bottom: 45px;
  }
  .modal-buttons {
    display: flex;
    justify-content: flex-end;
    width: 100%;
  }
`
